import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { sanitize } from 'dompurify';
import classnames from 'classnames';
import _ from 'lodash';
import { useStores } from '@src/stores';
import TitleSection from '@src/components/TitleSection';
import { NEWS_CONFIGS } from 'src/constants/news';
import { gtagEvent } from '@utils/common';
import { GOOGLE_ANALYTICS } from '@src/constants/googleAnalyticsEvents';
import NewIcon1 from '@assets/News/new_1.jpeg';
import NewIcon2 from '@assets/News/new_2.jpeg';
import NewIcon3 from '@assets/News/new_3.jpeg';
import NewIcon4 from '@assets/News/new_4.jpeg';
import NewIcon5 from '@assets/News/new_5.jpeg';
import NewIcon6 from '@assets/News/new_6.jpeg';
import NewIcon7 from '@assets/News/new_7.jpeg';
import NewIcon8 from '@assets/News/new_8.jpeg';
import NewIcon9 from '@assets/News/new_9.jpeg';
import NewIcon10 from '@assets/News/new_10.jpeg';
import NewIcon11 from '@assets/News/news_11.jpg';

import Telegram from '@assets/socials/telegram.svg';
import TelegramHover from '@assets/socials/telegram_hover.svg';

import Twitter from '@assets/socials/twitter.svg';
import TwitterHover from '@assets/socials/twitter_hover.svg';

import Linkedin from '@assets/socials/linkedin.svg';
import LinkedinHover from '@assets/socials/linkedin_hover.svg';

import Medium from '@assets/socials/medium.svg';
import MediumHover from '@assets/socials/medium_hover.svg';

// winter -
// import SledIcon from '@assets/Christmas/sled_and_light_mobile.svg';

import Logger from '@utils/logger';
import styles from './news.module.scss';

const COUNT_NEWS = 3;
const GENERAL_COUNT_NEWS = NEWS_CONFIGS.length;

const SOCIAL_NAMES = {
  twitter: 'twitter',
  linkedin: 'linkedin',
  telegram: 'telegram',
  medium: 'medium',
  youtube: 'youtube',
  facebook: 'facebook',
  instagram: 'instagram',
};

const NewsItem = ({
  description = 'description',
  icon = {},
  socials = [
    { name: 'telegram', link: '' },
    { name: 'twitter', link: '' },
  ],
}) => {
  const stores = useStores();
  const [hoverSocialIcon, setHoverSocialIcon] = useState(null);

  const getSocialIcon = (socialName) => {
    switch (socialName) {
      case SOCIAL_NAMES.linkedin:
        return hoverSocialIcon === SOCIAL_NAMES.linkedin ? LinkedinHover : Linkedin;
      case SOCIAL_NAMES.telegram:
        return hoverSocialIcon === SOCIAL_NAMES.telegram ? TelegramHover : Telegram;
      case SOCIAL_NAMES.twitter:
        return hoverSocialIcon === SOCIAL_NAMES.twitter ? TwitterHover : Twitter;
      default:
        return hoverSocialIcon === SOCIAL_NAMES.medium ? MediumHover : Medium;
    }
  };

  return (
    <div className={styles.NewsItem}>
      <div className={styles.NewsItemImageContainer}>
        <img
          onLoad={() => stores.increaseDownloadedImageCounter(icon)}
          onError={() => Logger.error('Error with image loading')}
          src={icon}
          alt="news"
          className={classnames(styles.NewsItemImage)}
        />
        <div className={classnames(styles.NewsItemImageHover)}>
          {socials &&
            socials.map((social) => {
              return (
                <a
                  key={`social_new_${social.name}`}
                  target="_blank"
                  rel="noreferrer"
                  href={social.link}
                  className={styles.NewsItemSocialImage}
                  onMouseEnter={() =>
                    setHoverSocialIcon(
                      !stores.browserInfo.isSensor && !stores.browserInfo.isMobile && social.name,
                    )
                  }
                  onMouseLeave={() => setHoverSocialIcon(null)}
                  onClick={() => {
                    gtagEvent(GOOGLE_ANALYTICS.CLICK_SOCIAL, social.name);
                  }}
                >
                  <img
                    src={getSocialIcon(social.name)}
                    alt={`social_new_${social.name}`}
                    className={styles.MobileSocialIcon}
                  />
                </a>
              );
            })}
        </div>
      </div>
      <p
        className={styles.NewsItemText}
        dangerouslySetInnerHTML={{ __html: sanitize(description, { ADD_ATTR: ['target'] }) }}
      />
    </div>
  );
};

const KeyNewsSection = observer(() => {
  const stores = useStores();
  const __ = stores.language;
  const [news, setNews] = useState([
    {
      number: -1,
      icon: '',
      description: '',
      link: '',
      socials: [
        { name: 'telegram', link: '' },
        { name: 'twitter', link: '' },
      ],
      enabled: true,
    },
  ]);

  const getIcon = (number) => {
    switch (number) {
      case 1:
        return NewIcon1;
      case 2:
        return NewIcon2;
      case 3:
        return NewIcon3;
      case 4:
        return NewIcon4;
      case 5:
        return NewIcon5;
      case 6:
        return NewIcon6;
      case 7:
        return NewIcon7;
      case 8:
        return NewIcon8;
      case 9:
        return NewIcon9;
      case 10:
        return NewIcon10;
      case 11:
        return NewIcon11;
      default:
        return NewIcon1;
    }
  };
  const getRandomArrayNews = (lastNews = []) => {
    const numbersNews = [];
    while (numbersNews.length < COUNT_NEWS) {
      const numberNew = Math.floor(Math.random() * GENERAL_COUNT_NEWS) + 1;
      if (
        !_.find(numbersNews, { number: numberNew }) &&
        !_.find(lastNews, { number: numberNew }) &&
        NEWS_CONFIGS[numberNew - 1].enabled
      ) {
        numbersNews.push({
          number: numberNew,
          icon: getIcon(numberNew),
          description: NEWS_CONFIGS[numberNew - 1].description,
          link: NEWS_CONFIGS[numberNew - 1].link,
          socials: NEWS_CONFIGS[numberNew - 1].socials,
          enabled: NEWS_CONFIGS[numberNew - 1].enabled,
        });
      }
    }
    return _.sortBy(numbersNews, 'number');
  };

  useEffect(() => {
    const newsRandom = getRandomArrayNews();
    setNews(newsRandom);
  }, []);

  return (
    <div className={styles.NewsSection} id="key_news">
      <TitleSection
        title={stores.translate('key_news')}
        classNames={{
          Container: styles.KeyNewsContainer,
        }}
      />
      <div className={styles.NewsItems}>
        {news.length > 1 &&
          news.map((newItem) => {
            return (
              <NewsItem
                icon={newItem.icon}
                description={newItem.description}
                socials={newItem.socials}
                key={`newItem_${newItem.icon}`}
              />
            );
          })}
      </div>
      {/* <img src={SledIcon} alt='sled' className={styles.SledIcon} /> */}
    </div>
  );
});

export default KeyNewsSection;
