import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { observer } from 'mobx-react';

const HeadTags = observer(
  ({
    title = 'HoneyWood | Free to Play Game on Blockchain',
    description = 'Play just 5 minute a day and earn! HoneyWood is a blockchain game where you can fight in match3 with other players, create your own farm and get a profit!',
    keywords = 'HoneyWood, Blockchain, Crypto, Play and Earn, blockchain gaming, blockchain games, Free to Play, Pvp, match-3, crypto gaming, 3D online PC game, decentralized game, Cosmos, ecosystem, browser game, P2E, Play to earn, game earn, crypto game',
  }) => {
    return (
      <HelmetProvider>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta name="keywords" content={keywords} />
        </Helmet>
      </HelmetProvider>
    );
  },
);

export default HeadTags;
