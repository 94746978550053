import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { sanitize } from 'dompurify';
import TextH1 from '@components_new/TextH1';
import TextH2 from '@components_new/TextH2';
import TextH3 from '@components_new/TextH3';
import TextH4 from '@components_new/TextH4';
import TextH5 from '@components_new/TextH5';

import styles from './titleSection.module.scss';

const TitleSection = observer(
  ({
    title = 'Title',
    textHtml = '',
    classNames = {},
    isH1TitleTag = false,
    isH2TitleTag = false,
    isH4TitleTag = false,
    isH5TitleTag = false,
  }) => {
    return (
      <div className={classnames(styles.Container, classNames.Container)}>
        {isH1TitleTag ? (
          <TextH1 classNames={classnames(styles.Title, classNames.Title)} text={title} />
        ) : isH2TitleTag ? (
          <TextH2 classNames={classnames(styles.Title, classNames.Title)} text={title} />
        ) : isH4TitleTag ? (
          <TextH4 classNames={classnames(styles.Title, classNames.Title)} text={title} />
        ) : isH5TitleTag ? (
          <TextH5 classNames={classnames(styles.Title, classNames.Title)} text={title} />
        ) : (
          <TextH3 classNames={classnames(styles.Title, classNames.Title)} text={title} />
        )}
        {!!textHtml && (
          <p
            className={classnames(styles.Text, classNames.Text)}
            dangerouslySetInnerHTML={{ __html: sanitize(textHtml) }}
          />
        )}
      </div>
    );
  },
);

export default TitleSection;
