import React, { useEffect } from 'react';
import HeadTags from '@components_new/HeadTags';
import { useMediaQuery } from '@src/helpers/media';
import MobileTokenomicsPage from '@src/pages/TokenomicsPage/MobileTokenomicsPage';
import DesktopTokenomicsPage from '@src/pages/TokenomicsPage/DesktopTokenomicsPage';
import BeeSpinner from '@components/BeeSpinner';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import styles from './tokenomics.module.scss';

const HomePage = observer(() => {
  const stores = useStores();
  const isMobileSize = useMediaQuery('(max-width: 999.99px)'); // change to 1000px

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HeadTags description="Tokenomics | What about the tokenomics? We strive to create a system of a self-organized system. Earn by playing 5 minutes a day." />
      {stores.browserInfo.isMobile || (isMobileSize && !stores.browserInfo.isMobile) ? (
        <MobileTokenomicsPage />
      ) : (
        <>
          {stores.isInitialTokenomicsPageLoading && (
            <BeeSpinner classNames={{ Container: styles.Container }} />
          )}
          <DesktopTokenomicsPage isInitialLoading={stores.isInitialTokenomicsPageLoading} />
        </>
      )}
    </>
  );
});

export default HomePage;
