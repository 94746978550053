import React from 'react';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { GOOGLE_ANALYTICS } from '@src/constants/googleAnalyticsEvents';
import { gtagEvent } from '@utils/common';
import { ENUM_MODAL } from '@components_new/Modal/enumModal';
import AvatarIcon from '@components/AvatarIcon';
import HamburgerMenu from '@assets/Mobile/control/hamburger.png';
import ArrowLeftIcon from '@assets/Mobile/control/arrow_left_icon.svg';
import LogoutIcon from '@assets/Mobile/control/logout_icon.png';

import { useStores } from 'src/stores';
import Logger from '@utils/logger';
import styles from './mobileHeader.module.scss';

const MobileHeader = observer(({ withAvatar, states, methods, isAccount = false, isLeadsBack = false }) => {
  const navigate = useNavigate();
  const stores = useStores();
  const _ = stores.language;

  const openMenu = () => {
    stores.setOpenMenuMobile(true);
  };
  const accountClick = () => {
    navigate('/account');
  };

  const joinClick = () => {
    stores.setOpenModal(ENUM_MODAL.JOIN);
  };

  return (
    <>
      <div
        className={classnames(
          styles.MobileHeaderContainer,
          window.location.pathname === '/' && styles.MobileHeaderContainer__justifyContent_flexEnd,
        )}
      >
        <div
          className={classnames(
            styles.MainPageLink,
            window.location.pathname === '/' && styles.MainPageLink__hide,
          )}
          onClick={() => (isLeadsBack ? navigate(-1) : navigate('/'))}
          role="button"
          onKeyDown={() => (isLeadsBack ? navigate(-1) : navigate('/'))}
          tabIndex="0"
        >
          <img
            onLoad={() => stores.increaseDownloadedImageCounter(ArrowLeftIcon)}
            onError={() => Logger.error('Error with image loading')}
            src={ArrowLeftIcon}
            alt="arrow_left_icon"
            className={styles.ArrowLeftIcon}
          />
          <p className={styles.MainPageText}>
            {isLeadsBack ? stores.translate('back') : stores.translate('main_page')}
          </p>
        </div>
        {isAccount ? (
          <div
            className={styles.LogoutButtonContainer}
            onClick={() => {
              gtagEvent(GOOGLE_ANALYTICS.CLICK_LOGOUT);
              methods.onLogoutClick();
            }}
            role="button"
            tabIndex={0}
            onKeyDown={() => {
              gtagEvent(GOOGLE_ANALYTICS.CLICK_LOGOUT);
              methods.onLogoutClick();
            }}
          >
            <p className={styles.LogoutText}>Logout</p>
            <img
              onLoad={() => stores.increaseDownloadedImageCounter(LogoutIcon)}
              onError={() => Logger.error('Error with image loading')}
              src={LogoutIcon}
              alt="logout_icon"
              className={styles.LogoutIconImage}
            />
          </div>
        ) : (
          <div
            className={classnames(
              styles.AvatarImage,
              window.location.pathname === '/transactions' && styles.AvatarImage__hide,
            )}
            onClick={() => {
              gtagEvent(GOOGLE_ANALYTICS.CLICK_LOGIN);
              if (stores.isAuthorized) {
                accountClick();
              } else {
                joinClick();
              }
            }}
            role="button"
            tabIndex={0}
            onKeyDown={() => {
              gtagEvent(GOOGLE_ANALYTICS.CLICK_LOGIN);
              if (stores.isAuthorized) {
                accountClick();
              } else {
                joinClick();
              }
            }}
          >
            <AvatarIcon />
          </div>
        )}
      </div>
      <div
        className={styles.HamburgerMenuContainer}
        onClick={() => openMenu(true)}
        role="button"
        tabIndex={0}
        onKeyDown={() => openMenu(true)}
      >
        <img
          onLoad={() => stores.increaseDownloadedImageCounter(HamburgerMenu)}
          onError={() => Logger.error('Error with image loading')}
          src={HamburgerMenu}
          alt="hamburger_menu"
          className={styles.HamburgerMenuImage}
        />
      </div>
    </>
  );
});

export default MobileHeader;
