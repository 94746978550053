import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import payments from 'src/api/server/payments';
import { useMediaQuery } from '@src/helpers/media';
import BeeSpinner from '@components/BeeSpinner';
import MobileTransactionsPage from '@src/pages/TransactionsPage/MobileTransactionsPage';
import styles from './transactions.module.scss';
// import DesktopTransactionsPage from '@src/pages/TransactionsPage/DesktopTransactionsPage';

const TransactionsPage = observer(() => {
  const stores = useStores();
  const _ = stores.language;
  const isMobileSize = useMediaQuery('(max-width: 999.99px)');

  const [isLoadingTransactions, setLoadingTransactions] = useState(true);
  const [paidPacks, setPaidPacks] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (stores.browserInfo.isMobile) {
      document.body.style.backgroundColor = '#9ED427';
    }
  }, []);

  useEffect(() => {
    const getPacksWithPAID = (listsInvoices) => {
      if (listsInvoices && !listsInvoices.err && listsInvoices.length > 0) {
        const packsPaidLists = listsInvoices.filter((transaction) => {
          return transaction.payment_status === 'PAID' || transaction.payment_status === 'finished';
        });
        return packsPaidLists || [];
      }
      return [];
    };

    (async () => {
      if (stores.isAccountAccess) {
        const listsBinance = await payments.getBinanceInvoiceList({
          address: stores.profileAvailable.address,
        });
        const listsNowPayment = await payments.getNowPaymentInvoiceList({
          address: stores.profileAvailable.address,
        });
        const packsPaidBinance = getPacksWithPAID(listsBinance);
        const packsPaidNowPayment = getPacksWithPAID(listsNowPayment);
        setPaidPacks(packsPaidBinance.concat(packsPaidNowPayment).reverse());
        setLoadingTransactions(false);
      }
    })();
  }, [stores.isAccountAccess]);

  return (
    <>
      {stores.browserInfo.isMobile || (isMobileSize && !stores.browserInfo.isMobile) ? (
        <MobileTransactionsPage
          stores={stores}
          states={{
            paidPacks,
          }}
          loading={{ isLoadingTransactions }}
          isInitialLoading={stores.isInitialTransactionsPageLoading}
        />
      ) : (
        // ! Need change to DesktopTransactionsPage, when it'll be done, DESKTOP !!!
        <>
          {stores.isInitialTransactionsPageLoading && (
            <BeeSpinner classNames={{ Container: styles.Container }} />
          )}
          <MobileTransactionsPage
            stores={stores}
            states={{
              paidPacks,
            }}
            loading={{ isLoadingTransactions }}
            isInitialLoading={stores.isInitialTransactionsPageLoading}
          />
        </>
      )}
    </>
  );
});

export default TransactionsPage;
