import React from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import TitleSection from '@src/components/TitleSection';
import { Button, ColorType, SizeButton } from 'src/components_new/Button';
import { GOOGLE_ANALYTICS } from '@src/constants/googleAnalyticsEvents';
import { gtagEvent } from '@utils/common';
import VestingImage from '@assets/Mobile/TokenomicsPage/VestingSection/vesting_image.svg';
import Logger from '@utils/logger';
import URLS from 'src/constants/urls';
import styles from './mobileVesting.module.scss';

const VestingSection = observer(() => {
  const stores = useStores();

  // const scrollToTokenomicsSection = () => {
  //   const targetId = document.getElementById('tokenomics-section');
  //   const y = targetId?.getBoundingClientRect().top + window.pageYOffset;
  //   window.scrollTo({ top: y, behavior: 'smooth' });
  // };

  const openVestingRounds = () => {
    window.open(URLS.VESTING, '_blank', 'noopener,noreferrer')
  }

  return (
    <div className={styles.VestingSectionContainer}>
      <div className={styles.Container}>
        <div className={styles.TitleContainer}>
          <TitleSection
            title={stores.translate('vesting_section_title')}
            classNames={{ Title: styles.Title }}
          />
        </div>
        <img
          onLoad={() => stores.increaseDownloadedImageCounter(VestingImage)}
          onError={() => Logger.error('Error with image loading')}
          src={VestingImage}
          alt="vesting_image"
          className={styles.WatchButtonImage}
        />
        <div className={styles.ButtonWrapper}>
          <Button
            colorType={ColorType.ORANGE}
            size={SizeButton.SMALL}
            title={stores.translate('watch_information')}
            classNames={{
              Container: classnames(styles.ButtonContainer),
              Button: classnames(styles.WatchButton),
              Title: classnames(
                styles.TitleButton,
                stores.browserInfo.isAndroid
                  ? styles.TitleOrangeButton__android
                  : styles.TitleOrangeButton,
              ),
            }}
            onClick={() => {
              gtagEvent(GOOGLE_ANALYTICS.CLICK_WATCH_INFO);
              openVestingRounds();
            }}
          />
        </div>
      </div>
    </div>
  );
});
export default VestingSection;
