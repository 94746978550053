import React, { useEffect, useState } from 'react';
import snsWebSdk from '@sumsub/websdk';
import { observer } from 'mobx-react';
import { useStores } from 'src/stores';
import { gtagEvent } from '@utils/common';
import { GOOGLE_ANALYTICS } from '@src/constants/googleAnalyticsEvents';
import classnames from 'classnames';
import CloseMobile from '@src/assets/control/close_new_icon.svg';
import Logger from '@utils/logger';
import styles from './kycScreen.module.scss';

const KYCScreen = observer(({ close }) => {
  const stores = useStores();
  const [isStartKYC, setStartKYC] = useState(false);
  useEffect(() => {
    (async () => {
      if (!stores.tokenNowPayments) return;
      const token = stores.tokenNowPayments; // "_act-sbx-6c2acd6a-351c-4047-8ba7-8721f7dfc5d6";
      const snsWebSdkInstance = snsWebSdk
        .init(token, () => token)
        .withBaseUrl('https://api.sumsub.com')
        .withConf({ lang: 'en' })
        .on('onError', (error) => {
          Logger.error(error);
        })
        .onMessage((type, payload) => {
          Logger.infoMainWithKey('..........................');
          Logger.infoMainWithKey('..........................');
          Logger.infoMainWithKey('onMessage ', type, payload);
          if (type.includes('idCheck.onStepInitiated')) {
            setStartKYC(true);
          }
          // console.log('onMessage type', type, payload);
          // console.log('onMessage payload', payload);
          // console.log('onMessage payload JSON', JSON.stringify(payload));

          // console.log('payload.answer >> ', payload.answer)
          // console.log('payload.applicantActionId >> ', payload.applicantActionId)
          // console.log('payload.applicantId >> ', payload.applicantId)
          // console.log('payload.videoRequired >> ', payload.videoRequired)
          // console.log('payload.reviewStatus >> ', payload.reviewStatus)
          // console.log('payload.types >> ', payload.types)
          // console.log('payload.levelName >> ', payload.levelName);
          // console.log('payload.idDocSetType >> ', payload.idDocSetType);
          // console.log('payload.reviewResult >> ', payload.reviewResult);
          // console.log('payload.reviewResult?.reviewAnswer >> ', payload.reviewResult?.reviewAnswer);
          // console.log('payload.reprocessing >> ', payload.reprocessing);
          // console.log('payload.autoChecked >> ', payload.autoChecked);
          // console.log('payload.code >> ', payload.code);
          // console.log('payload.height >> ', payload.height);
          // console.log('payload.error >> ', payload.error);
          // console.log('payload.error >> ', payload);
          // console.log('payload.error >> ', payload);

          if (
            payload.reviewStatus === 'completed' &&
            payload.reviewResult?.reviewAnswer === 'GREEN'
          ) {
            // console.log('update status >> completed and GREEN >>>');
            stores.setFinishKYCVerified(true);
          }
          // if (payload.reviewStatus === 'completed') {
          //   console.log('update status >> completed >>>');
          //   // stores.setFinishKYCVerified(true);
          // }
          // if (payload.answer === 'GREEN') {
          //   console.log('update status >> GREEN >>>');
          //   // stores.setFinishKYCVerified(true);
          // }

          // if (payload.reviewResult?.reviewAnswer === 'GREEN') {
          //   console.log('update status reviewAnswer >> GREEN >>>');
          //   // stores.setFinishKYCVerified(true);
          // }
          // if (payload.reviewResult === 'GREEN') {
          //   console.log('update status >> GREEN >>>');
          //   // stores.setFinishKYCVerified(true);
          // }
          // if (payload.idDocSetType === 'IDENTITY') {
          //   console.log('update status >>>');
          //   stores.setFinishKYCVerified(true);
          //   // close(true);
          // }
          // console.log('onMessage reviewStatus >> ', payload, ' >>> ', JSON.parse(payload.reviewStatus))
          // payload.answer
          // console.log('onMessage type >> ', payload.reviewResult, payload.reviewResult?.reviewAnswer === 'GREEN');
          // console.log('onMessage reviewStatus >> ', payload, payload.idDocSetType === 'IDENTITY');
        })
        .build();

      snsWebSdkInstance.launch('#sumsub-websdk-container');
    })();
  }, []);

  return (
    <div className={styles.Container}>
      <button
        type="button"
        className={classnames(styles.ModalClose, isStartKYC && styles.ModalClose_stepper)}
        onClick={() => {
          close(true);
          // gtagEvent(`${GOOGLE_ANALYTICS.CLICK_CLOSE} KYC`);
          gtagEvent(`${GOOGLE_ANALYTICS.CLICK_CLOSE}`);
        }}
      >
        <img
          onLoad={() => stores.increaseDownloadedImageCounter()}
          onError={() => Logger.error('Error with image loading')}
          src={CloseMobile}
          alt="Close"
          loading="lazy"
        />
      </button>
      <div id="sumsub-websdk-container" />
    </div>
  );
});

export default KYCScreen;
