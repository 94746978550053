import { STORAGE_NAMESPACE } from '@constants/system';
import CryptoJS from 'crypto-js';

const encrypt = ({ data, secretKey }) => {
  return CryptoJS.AES.encrypt(data, secretKey).toString();
}

const decrypt = ({ data, secretKey }) => {
  const bytesProfile = CryptoJS.AES.decrypt(data, secretKey);
  return bytesProfile.toString(CryptoJS.enc.Utf8);
}

export const setSecureStorage = (name, data, secret = 'secure') => {
  try {
    const secretKey = `secret_HoneyWood${secret}`;
    let strData = data;
    if (typeof data !== 'string') {
      strData = JSON.stringify(data);
    }
    const encryptedValue = encrypt({ data: strData, secretKey });
    localStorage.setItem(`${STORAGE_NAMESPACE}.${name}`, encryptedValue);
    return { result: 'success' }
  } catch (err) {
    return { result: 'error', err }
  }
}

export const getSecureStorage = (name, secret = 'secure') => {
  const secretKey = `secret_HoneyWood${secret}`;
  const encryptedValue = localStorage.getItem(`${STORAGE_NAMESPACE}.${name}`);
  if (encryptedValue === null) {
    return null;
  }
  const decryptedValue = decrypt({ data: encryptedValue, secretKey });
  try {
    return JSON.parse(decryptedValue);
  } catch (err) {
    return decryptedValue;
  }
};

export const removeSecureStorage = (name) => {
  try {
    localStorage.removeItem(`${STORAGE_NAMESPACE}.${name}`);
    return { result: 'success' }
  } catch (err) {
    return { result: 'error', err }
  }
}

export default {
  setSecureStorage,
  getSecureStorage,
  removeSecureStorage,
}