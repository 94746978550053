import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _ from 'lodash';
import Input from '@components/Input';
import { Button, ColorType, UNIQ_ID_BUTTON } from '@components/Button';
import { ENUM_MODAL } from '@components_new/Modal/enumModal';
import { ENUM_POPUP } from '@components_new/PopUp/enumPopup';
import { INFO_ACCOUNT, COST_COMMISSION } from '@constants/system';
import { BEARS } from 'src/api';
import HoneyIcon from '@assets/AccountPage/honey_icon.png';
import { useStores } from 'src/stores';
import Logger from '@utils/logger';
import styles from './changeNickname.module.scss';

const ChangeNicknameModal = observer(({ costChangeName, close }) => {
  const stores = useStores();
  const [isLoading, setLoading] = useState(false);
  const enoughHoneyForChanged = stores.profileAvailable.honey >= 100.01;

  const regExMnemonic = /^[a-zA-Z0-9]+$/g;
  const schemaModal = yup
    .object()
    .shape({
      nickname: yup
        .string()
        .matches(
          regExMnemonic,
          'You have entered invalid characters for the name, use: a-z, A-Z, 0-9',
        )
        .min(5, stores.translate('nickname_input_validation'))
        .required(),
    })
    .required();

  const ModalForm = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schemaModal),
    defaultValues: {
      nickname: '',
    },
  });
  const modalFields = ModalForm.watch();

  const onApplyChangeNicknameClick = async () => {
    setLoading(true);
    try {
      try {
        const resultBearName = await BEARS.getBearName({ name: modalFields.nickname });
        Logger.infoMainWithKey('getBearName result >> ', resultBearName);
        if (resultBearName && resultBearName.err) {
          /* nickname yet no use */
          const bearInfo = await BEARS.getBearsId({ address: stores.profileAvailable.address });
          Logger.infoMainWithKey('bearInfo >> ', bearInfo);
          if (bearInfo && bearInfo.bears && bearInfo.bears.length === 1) {
            /* bear is exist */
            await BEARS.msgSetName({ bearId: bearInfo.bears[0], name: modalFields.nickname });
          } else {
            /* INIT Game with Set Name */
            const resultInitGame = await BEARS.msgInitGameAndSetName({
              name: modalFields.nickname,
            });
            Logger.infoMainWithKey('msgInitGameAndSetName resultInitGame >> ', resultInitGame);
            if (resultInitGame && resultInitGame.code > 0) {
              ModalForm.setError('nickname', {
                type: 'manual',
                message: `method init game with set name finished with error code, ${resultInitGame.code}`,
              });
              return;
            }
          }
          const profile = { ...stores.profileAvailable, nickName: modalFields.nickname };
          localStorage.setItem(
            INFO_ACCOUNT,
            JSON.stringify({
              address: stores.profileAvailable.address,
              nickName: modalFields.nickname,
            }),
          );
          stores.setProfileAvailable(profile);
          stores.setProfile(profile);
          stores.setExecuteModal({
            name: ENUM_MODAL.CHANGE_NICKNAME,
            state: { name: modalFields.nickname },
          });
          close();
        } else {
          ModalForm.setError('nickname', {
            type: 'manual',
            message: stores.translate('nickname_is_already_taken'),
          });
        }
      } catch (err) {
        Logger.error(err);
        ModalForm.setError('nickname', {
          type: 'manual',
          message: stores.translate('error_changed_nickname'),
        });
      } finally {
        setLoading(false);
      }
    } catch (err) {
      Logger.error(err);
      setLoading(false);
    } finally {
      stores.setExecutePopup({});
    }
  };

  useEffect(() => {
    if (stores.executePopup && stores.executePopup.name === ENUM_POPUP.CHANGE_APPROVE_NICKNAME) {
      onApplyChangeNicknameClick();
    }
  }, [stores.executePopup]);

  const onChangeNicknameClick = async () => {
    if (!enoughHoneyForChanged) {
      stores.setOpenPopup(ENUM_POPUP.CHANGE_NICKNAME_WARNING);
      return;
    }
    stores.setOpenPopup(ENUM_POPUP.CHANGE_APPROVE_NICKNAME);
  };

  const handleKeypress = (e) => {
    if (e.key && e.key.toLowerCase() === 'enter') {
      ModalForm.handleSubmit(onChangeNicknameClick)();
    }
  };

  return (
    <>
      <Input
        placeholder={stores.translate('enter_nickname')}
        canError
        label={stores.translate('enter_nickname')}
        error={ModalForm.formState.errors?.nickname}
        name="nickname"
        register={ModalForm.register}
        autoComplete="no"
        onKeyDown={handleKeypress}
        classNames={{
          Label: styles.LabelInput,
          InputContainer: styles.Input_InputContainer,
          Container: styles.Input_Container,
          Input: styles.Input_Input,
          InputButtonShow: styles.Input_InputButtonShow,
        }}
      />
      <div className={styles.NicknameCostContainer}>
        <p className={styles.NicknameCostText}>{stores.translate('nickname_change_cost')}</p>
        <img
          onLoad={() => stores.increaseDownloadedImageCounter(HoneyIcon)}
          onError={() => Logger.error('Error with image loading')}
          src={HoneyIcon}
          alt="honey_icon"
          className={styles.HoneyIcon}
        />
        <p className={styles.NicknameCostText}>{String(costChangeName + COST_COMMISSION)}</p>
      </div>
      <div className={styles.ButtonsContainer}>
        <Button
          width={260}
          colorType={ColorType.GREEN}
          uniqID={UNIQ_ID_BUTTON.Account.ConfirmNicknameModal}
          onClick={() => {
            ModalForm.handleSubmit(onChangeNicknameClick)();
          }}
          title={stores.translate('confirm')}
          classNames={{
            Container: classnames(styles.ButtonContainer, styles.ButtonContainer__android),
            Title: stores.browserInfo.isAndroid
              ? styles.TitleGreenButton__android
              : styles.TitleGreenButton,
          }}
          isLoading={isLoading}
          valueScaleAnimation={0.95}
        />
      </div>
      <div className={styles.ChangeNicknameAttentionContainer}>
        <p className={styles.ChangeNicknameAttention}>
          {stores.translate('nickname_censorship_rules')}
        </p>
      </div>
    </>
  );
});

export default ChangeNicknameModal;
