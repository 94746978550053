import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import TitleSection from '@src/components/TitleSection';
import { ENUM_POPUP } from '@components/PopUp/enumPopup';
import QuestionForm from 'src/components_new/QuestionForm';
import styles from './mobileQuestion.module.scss';

const QuestionSection = observer(() => {
  const stores = useStores();
  const _ = stores.language;

  const openPopUp = () => {
    stores.setPropsForPopup({ timeout: 5000 });
    stores.setOpenPopup(ENUM_POPUP.QUESTION_SENDED);
  };

  const onSubmitMail = () => {
    openPopUp();
  };
  return (
    <div className={styles.Container} id="feedback">
      <TitleSection
        title={stores.translate('have_any_questions')}
        classNames={{ Container: styles.TitleContainer }}
        isH4TitleTag
      />
      <div className={styles.SubscribeContainer}>
        <QuestionForm onSubmit={onSubmitMail} />
      </div>
      <TitleSection
        title={stores.translate('ask_questions')}
        classNames={{ Title: styles.SecondTitleContainer }}
        isH4TitleTag
      />
    </div>
  );
});

export default QuestionSection;
