import React from 'react';
import classnames from 'classnames';
import { sanitize } from 'dompurify';
import { useStores } from '@src/stores';
import TextH5 from '@components_new/TextH5';
import BeeHouseIcon from '@assets/SpecialPresaleSection/bee_house_icon.svg';
import SmallBeeIcon from '@assets/SpecialPresaleSection/small_bee_icon.svg';
import ApiaryIcon from '@assets/SpecialPresaleSection/apiary_icon.svg';
import SkilledBeeIcon from '@assets/SpecialPresaleSection/skilled_bee_icon.svg';
import FlowerBarrelIcon from '@assets/SpecialPresaleSection/flower_barrel_icon.svg';
import AlvearyIcon from '@assets/SpecialPresaleSection/alveary_icon.svg';
import DiamondBeeIcon from '@assets/SpecialPresaleSection/diamond_bee_icon.svg';
import FountainIcon from '@assets/SpecialPresaleSection/fountain_icon.svg';
import LandIcon from '@assets/SpecialPresaleSection/land_icon.svg';
import HoneyBeeIcon from '@assets/SpecialPresaleSection/honey_bee_icon.svg';
import HoneyHunterIcon from '@assets/SpecialPresaleSection/honey_hunter_icon.svg';
import HoneyExplorerIcon from '@assets/SpecialPresaleSection/honey_explorer_icon.svg';
import HoneyIcon6 from '@assets/SpecialPresaleSection/honey_6.png';
import HoneyIcon5 from '@assets/SpecialPresaleSection/honey_5.png';
import HoneyIcon4 from '@assets/SpecialPresaleSection/honey_4.png';
import HoneyIcon3 from '@assets/SpecialPresaleSection/honey_3.png';
import HoneyIcon2 from '@assets/SpecialPresaleSection/honey_2.png';
import HoneyIcon1 from '@assets/SpecialPresaleSection/honey_1.png';

import BabyBeeIcon from '@assets/SpecialPresaleSection/baby_bee_icon.svg';
import StumpIcon from '@assets/SpecialPresaleSection/stump_icon.svg';
// import TetherUsdtWhiteIcon from '@assets/SpecialPresaleSection/tether_usdt_white_icon.svg';
import TetherUsdtIcon from '@assets/common/tether_usdt_icon.svg';
// BEE
import ForestBeeIcon from '@assets/SpecialPresaleSection/forest_bee.svg';
import ForestExplorerIcon from '@assets/SpecialPresaleSection/forest_explorer.svg';
import ForestAceIcon from '@assets/SpecialPresaleSection/forest_ace.svg';
// APIARY
import ForestBeehiveIcon from '@assets/SpecialPresaleSection/forest_beehive.svg';
// DECORATION
import BeeTheSaviorIcon from '@assets/SpecialPresaleSection/bee_the_savior.svg';
// Halloween Day
import ScaryaryIcon from '@assets/Halloween/Aplary.png';
import PumPumIcon from '@assets/Halloween/pumpkin.png';
import HoneyBatIcon from '@assets/Halloween/bee.png';
// Christmas Day
import SnowBeeIcon from '@assets/SpecialPresaleSection/snow_bee_icon.svg';
import CozyHouseIcon from '@assets/SpecialPresaleSection/cozy_house_icon.svg';
// import ChristmasBoxIcon from '@assets/SpecialPresaleSection/christmas_box_icon.svg';
import ChristmasBoxIcon from '@assets/Christmas/christmas_box.png';

// import SnowBeeIcon from '@assets/Christmas/snow_bee.png';
// import CozyHouseIcon from '@assets/Christmas/cozy_house.png';
// import ChristmasBoxIcon from '@assets/Christmas/christmas_box.png';

import {
  ALVEARY,
  APIARY,
  BABY_BEE,
  BEE,
  BEE_HOUSE,
  BEE_THE_SAVIOR,
  CHRISTMAS_BOX,
  COINS,
  COZY_HOUSE,
  DECORATION,
  DIAMOND_STING,
  FIELD_EXTENDING,
  FLOWER_BARREL,
  FOREST_ACE,
  FOREST_BEE,
  FOREST_BEEHIVE,
  FOREST_EXPLORER,
  FOUNTAIN,
  HONEY_BAT,
  HONEY_BEE,
  HONEY_EXPLORER,
  HONEY_HUNTER,
  PUM_PUM,
  SCARYARY,
  SKILLED_BEE,
  SMALL_BEE,
  SNOW_BEE,
  STUMP,
} from '@constants/blockchain';

import Logger from '@utils/logger';
import styles from './packItemMobile.module.scss';

const PackItemMobile = ({
  packID = '',
  type = '',
  title = '',
  itemsDescription = {},
  price = '',
  params = {},
  classNames = {},
  noPrice = false,
}) => {
  const stores = useStores();
  const getInfoByType = (kind, paramsKind) => {
    const beeInfo = (typeItem) => {
      switch (typeItem) {
        case BABY_BEE:
          return [BabyBeeIcon, itemsDescription?.babyBeeDescription];
        case DIAMOND_STING:
          return [DiamondBeeIcon, itemsDescription?.diamondBeeDescription];
        case BEE_HOUSE:
          return [BeeHouseIcon, itemsDescription?.beeHouseDescription];
        case SMALL_BEE:
          return [SmallBeeIcon, itemsDescription?.smallBeeDescription];
        case SKILLED_BEE:
          return [SkilledBeeIcon, itemsDescription?.skilledBeeDescription];
        case BEE:
          return [SmallBeeIcon, itemsDescription?.beeDescription];
        case HONEY_BEE:
          return [HoneyBeeIcon, itemsDescription?.honeyBeeDescription];
        case HONEY_HUNTER:
          return [HoneyHunterIcon, itemsDescription?.honeyHunterDescription];
        case HONEY_EXPLORER:
          return [HoneyExplorerIcon, itemsDescription?.honeyExplorerDescription];
        case FOREST_BEE:
          return [ForestBeeIcon, itemsDescription?.honeyBeeForestDescription];
        case FOREST_EXPLORER:
          return [ForestExplorerIcon, itemsDescription?.honeyBeeForestExplorerDescription];
        case FOREST_ACE:
          return [ForestAceIcon, itemsDescription?.honeyBeeForestAceDescription];
        case HONEY_BAT:
          return [HoneyBatIcon, itemsDescription?.honeyBatDescription];
        case SNOW_BEE:
          return [SnowBeeIcon, itemsDescription?.snowBeeDescription];
        default:
          return [SmallBeeIcon, itemsDescription?.beeDescription];
      }
    };
    const apiaryInfo = (typeItem) => {
      switch (typeItem) {
        case STUMP:
          return [StumpIcon, itemsDescription?.stumpDescription];
        case BEE_HOUSE:
          return [BeeHouseIcon, itemsDescription?.beeHouseDescription];
        case APIARY:
          return [ApiaryIcon, itemsDescription?.apiaryDescription];
        case ALVEARY:
          return [AlvearyIcon, itemsDescription?.alvearyDescription];
        case FOREST_BEEHIVE:
          return [ForestBeehiveIcon, itemsDescription?.forestBeehiveDescription];
        case SCARYARY:
          return [ScaryaryIcon, itemsDescription?.scaryaryDescription];
        case COZY_HOUSE:
          return [CozyHouseIcon, itemsDescription?.cozyHouseDescription];
        default:
          return [ApiaryIcon, itemsDescription?.apiaryDescription];
      }
    };
    const decorationInfo = (typeItem) => {
      switch (typeItem) {
        case FLOWER_BARREL:
          return [FlowerBarrelIcon, itemsDescription?.flowerBarrelDescription];
        case FOUNTAIN:
          return [FountainIcon, itemsDescription?.fountainDescription];
        case BEE_THE_SAVIOR:
          return [BeeTheSaviorIcon, itemsDescription?.beeTheSaviorDescription];
        case PUM_PUM:
          return [PumPumIcon, itemsDescription?.pumPumDescription];
        case CHRISTMAS_BOX:
          return [ChristmasBoxIcon, itemsDescription?.ChristmasBoxDescription];
        default:
          return [FountainIcon, itemsDescription?.fountainDescription];
      }
    };
    const honeyInfo = () => {
      let varHoneyInfo = [HoneyIcon1, ''];
      if (packID.includes('_1')) {
        varHoneyInfo = [HoneyIcon1, ''];
      } else if (packID.includes('_2')) {
        varHoneyInfo = [HoneyIcon2, ''];
      } else if (packID.includes('_3')) {
        varHoneyInfo = [HoneyIcon3, ''];
      } else if (packID.includes('_4')) {
        varHoneyInfo = [HoneyIcon4, ''];
      } else if (packID.includes('_5')) {
        varHoneyInfo = [HoneyIcon5, ''];
      } else if (packID.includes('_6')) {
        varHoneyInfo = [HoneyIcon6, ''];
      }
      return varHoneyInfo;
    };

    switch (kind) {
      case BEE:
        return beeInfo(paramsKind.beeType);
      case APIARY:
        return apiaryInfo(paramsKind.apiaryType);
      case DECORATION:
        return decorationInfo(paramsKind.decorationType);
      case FIELD_EXTENDING:
        return [LandIcon, ''];
      case COINS:
        return honeyInfo();
      default:
        return [SmallBeeIcon, itemsDescription?.beeDescription];
    }
  };

  return (
    <div className={classnames(styles.ItemContainer, classNames.ItemContainer)}>
      <div className={classnames(styles.IconContainer, classNames.IconContainer)}>
        <img
          onLoad={() => stores.increaseDownloadedImageCounter()}
          onError={() => Logger.error('Error with image loading')}
          src={getInfoByType(type, params)[0]}
          alt={`${title}_icon`}
          className={classnames(styles.Icon, classNames.Icon)}
        />
      </div>
      <div
        className={classnames(
          styles.ItemTextContainer,
          !getInfoByType(type, params)[1] && styles.ItemTextContainer__height,
          classNames.ItemTextContainer,
        )}
      >
        <div className={classnames(styles.ItemTitleContainer, classNames.ItemTitleContainer)}>
          <TextH5 classNames={classnames(styles.ItemTitle, classNames.ItemTitle)} text={title} />
        </div>
        <div
          className={classnames(
            styles.ItemDescriptionContainer,
            !getInfoByType(type, params)[1] && styles.ItemDescriptionContainer__noHeight,
            classNames.ItemDescriptionContainer,
          )}
        >
          <p
            className={classnames(styles.ItemDescription, classNames.ItemDescription)}
            dangerouslySetInnerHTML={{ __html: sanitize(getInfoByType(type, params)[1]) }}
          />
        </div>
        {!noPrice && (
          <div className={classnames(styles.PriceContainer, classNames.PriceContainer)}>
            <img
              onLoad={() => stores.increaseDownloadedImageCounter(TetherUsdtIcon)}
              onError={() => Logger.error('Error with image loading')}
              src={TetherUsdtIcon}
              alt="tether_usdt_icon"
              className={classnames(
                price ? styles.TetherUsdtIcon : styles.TetherUsdtIcon__Display_none,
                classNames.TetherUsdtIcon,
              )}
            />
            <p
              className={classnames(
                styles.ItemPrice,
                classNames.ItemPrice,
                !price && styles.ItemPrice__Free,
              )}
            >
              {price ? `${sanitize(price)} USDT` : 'Free bonus'}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PackItemMobile;
