import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { useStores } from '@src/stores';
import TitleSection from '@components/TitleSection';
// import { Button, ColorType, SizeButton } from 'src/components_new/Button';
// import { gtagEvent } from '@utils/common';
// import { GOOGLE_ANALYTICS } from '@src/constants/googleAnalyticsEvents';
import Web3PartnersLogo from '@assets/Mobile/HomePage/PartnersSection/PartnersLogos/web3_partners_logo.png';
import KartinKeeLogo from '@assets/Mobile/HomePage/PartnersSection/PartnersLogos/kartin_kee_logo.png';
import SecondAmbassadorsSchool from '@assets/Mobile/HomePage/PartnersSection/PartnersLogos/second_ambassadors_school.png';
import TegroLogo from '@assets/Mobile/HomePage/PartnersSection/PartnersLogos/tegro_logo.png';
import AmbassadorsSchoolLogo from '@assets/Mobile/HomePage/PartnersSection/PartnersLogos/ambassadors_school_logo.png';
import DaoStarterLogo from '@assets/Mobile/HomePage/PartnersSection/PartnersLogos/dao_starter_logo.png';
import OkcLogo from '@assets/Mobile/HomePage/PartnersSection/PartnersLogos/okc_logo.png';
import NovaGuildGamesLogo from '@assets/Mobile/HomePage/PartnersSection/PartnersLogos/nova_guild_games_logo.png';
import CherrySwap from '@assets/Mobile/HomePage/PartnersSection/PartnersLogos/cherry_swap.png';
import CosmosLogo from '@assets/Mobile/HomePage/PartnersSection/PartnersLogos/cosmos_logo.png';
import BitmartLogo from '@assets/Mobile/HomePage/PartnersSection/PartnersLogos/bitmart_logo.png';
import GagarinLogo from '@assets/Mobile/HomePage/PartnersSection/PartnersLogos/gagarin_logo.png';
import KommunitasLogo from '@assets/Mobile/HomePage/PartnersSection/PartnersLogos/kommunitas_logo.png';
import MonetaTodayLogo from '@assets/Mobile/HomePage/PartnersSection/PartnersLogos/moneta_today_logo.svg';

//  winter -
// import LeftSnowIcon from '@assets/Christmas/left_snow_2.png';
// import RightSnowIcon from '@assets/Christmas/right_snow_2.png';

import Logger from '@utils/logger';
import styles from './mobilePartners.module.scss';

const PartnersSection = observer(({ classNames = {} }) => {
  const stores = useStores();
  const partners = [
    OkcLogo,
    BitmartLogo,
    KommunitasLogo,
    DaoStarterLogo,
    GagarinLogo,
    CherrySwap,
    Web3PartnersLogo,
    TegroLogo,
    NovaGuildGamesLogo,
    CosmosLogo,
    SecondAmbassadorsSchool,
    KartinKeeLogo,
    AmbassadorsSchoolLogo,
    MonetaTodayLogo,
  ];

  return (
    <div className={styles.PartnersSectionContainer}>
      <div className={classnames(styles.Container, classNames.Container)} id="our-partners">
        <TitleSection
          title={stores.translate('our_partners')}
          classNames={{ Container: styles.TitleSectionInnerContainer }}
        />
        <div className={styles.PartnersContainer}>
          {partners.map((partner) => (
            <img
              onLoad={() => stores.increaseDownloadedImageCounter(partner)}
              onError={() => Logger.error('Error with image loading')}
              src={partner}
              alt={`${partner}_image`}
              className={classnames(styles.PartnerLogoImage, partner === KommunitasLogo && styles.PartnerLogoImage__additionalOption)}
              key={partner}
            />
          ))}
        </div>
        {/* winter */}
        {/* <img src={LeftSnowIcon} alt='left_snow' className={styles.LeftSnowIcon} /> */}
        {/* <img src={RightSnowIcon} alt='right_snow' className={styles.RightSnowIcon} /> */}
        {/* //! Temporary */}
        {/* <div className={styles.BecomeButtonContainer}>
        <Button
          colorType={ColorType.ORANGE}
          size={SizeButton.SMALL}
          title={stores.translate('become_a_partner')}
          classNames={{
            Title: classnames(
              styles.TitleButton,
              stores.browserInfo.isAndroid
                ? styles.TitleOrangeButton__android
                : styles.TitleOrangeButton,
            ),
            Button: styles.BecomeButton,
          }}
          onClick={() => {
            gtagEvent(GOOGLE_ANALYTICS.CLICK_BECOME_PARTNER);
            // window.open(URLS.YIELD_CALCULATOR, '_blank', 'noopener,noreferrer');
          }}
        />
      </div> */}
      </div>
    </div>
  );
});

export default PartnersSection;
