import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import classnames from 'classnames';
// import { createSnow, showSnow } from "pure-snow.js";

import { GOOGLE_ANALYTICS } from 'src/constants/googleAnalyticsEvents';
import URLS from 'src/constants/urls';
import { useStores } from 'src/stores';
import Header from '@layout/Header';
import Footer from '@layout/Footer';
import { copyText } from '@utils/common';
import Logger from '@utils/logger';
import { useCopied } from '@hooks/custom';
import { updateNicknameInfo, updateProfileInfo } from '@utils/methodsBears';
import { ENUM_MODAL } from '@components_new/Modal/enumModal';
import ArrowUpButton from 'src/components/ArrowUpButton';
import CookiesPopUp from '@components/CookiesPopUp';
import CookiesManageModal from '@components/CookiesManageModal';
import MobileMenu from './MobileMenu';
// import { createSnow, removeSnow, showSnow } from '../components/SnowAnimation/pure-snow';

import styles from './layout.module.scss';

const Layout = observer(
  ({
    isMobile = false,
    isAccount = false,
    isLeadsBack = false,
    isTransactionsPage = false,
    children,
    isInitialLoading = true,
    withAvatar = true,
    isLoadingFinish = false,
  }) => {
    const stores = useStores();
    const _ = stores.language;
    const navigate = useNavigate();
    const { copied, setCopied } = useCopied();

    const [isOpenCookiesPopUp, setOpenCookiesPopUp] = useState(false);
    const [userName, setUserName] = useState('﹡﹡﹡');
    const [walletAddress, setWalletAddress] = useState('﹡﹡﹡');
    const [cones, setCones] = useState(0);
    const [honeyCoins, setHoneyCoins] = useState(0);
    const [isLoading, setLoading] = useState(false);
    // for animation after resize height
    const [heightScreen, setHeightScreen] = useState(window.innerHeight);

    // useEffect(() => {
    //   createSnow(); // creates snowflakes and generate css for them
    //   showSnow(true); // snow can be disabled using showSnow function
    // }, [])
    useEffect(() => {
      setOpenCookiesPopUp(stores.isOpenCookiesPopup);
    }, [stores.isOpenCookiesPopup]);

    useEffect(() => {
      (async () => {
        if (stores.isAuthorized) {
          setWalletAddress(stores.profileAvailable.address);
        } else {
          setUserName('');
          setWalletAddress('');
          return;
        }

        setLoading(true);
        await updateProfileInfo();
        setCones(stores.profileAvailable.cones);
        setHoneyCoins(stores.profileAvailable.honey);
        setLoading(false);
      })();
    }, [stores.isAccountAccess, stores.isAuthorized]);

    useEffect(() => {
      (async () => {
        if (stores.isAuthorized) {
          setUserName(stores.profileAvailable.nickName);
          await updateNicknameInfo();
          setUserName(stores.profileAvailable.nickName);
        } else {
          setUserName('');
        }
      })();
    }, [stores.isAccountAccess]);

    const gameClick = () => {
      navigate('/game');
    };

    const onLogoutClick = () => {
      stores.removeAuthorized();
      Logger.infoNotify('onLogoutClick, Layout', { type: stores.typeAuthClient });
      navigate('/');
    };

    const onQuitGameClick = () => {
      stores.removeAuthorized();
      Logger.infoNotify('onQuitGameClick, Layout', { type: stores.typeAuthClient });
    };

    const onCopyAddress = () => {
      copyText({ text: walletAddress });
      setCopied();
    };

    const accountClick = () => {
      navigate('/account');
    };

    const joinClick = () => {
      stores.setOpenModal(ENUM_MODAL.JOIN);
    };

    const menu = [
      { name: 'listing', translate: stores.translate('listing'), isLink: true },
      { name: 'faq', translate: stores.translate('faq') },
      { name: 'about-us', translate: stores.translate('about_us') },
      { name: 'partnership', translate: stores.translate('partnership') },
      { name: 'tokenomics', translate: stores.translate('tokenomics') },
      { name: 'explorer', translate: stores.translate('explorer'), isLink: true },
      { name: 'community', translate: stores.translate('community'), isLink: true },
      { name: 'main-page', translate: stores.translate('main') },
    ];

    const getHeaderLinkInfo = (menuItemName) => {
      switch (menuItemName) {
        case 'explorer':
          return [URLS.EXPLORER_LANDING, GOOGLE_ANALYTICS.CLICK_EXPLORER];
        case 'community':
          return [URLS.COMMUNITY_COUNTRIES, GOOGLE_ANALYTICS.CLICK_COMMUNITY];
        case 'listing':
          return [URLS.LISTING_HONEYCOIN, GOOGLE_ANALYTICS.CLICK_LISTING];
        case 'faq':
          return [URLS.FAQ, GOOGLE_ANALYTICS.CLICK_FAQ];
        default:
          return false;
      }
    };

    const getHeaderLinkRoute = (menuItemName) => {
      switch (menuItemName) {
        case 'faq':
          return '/faq';
        case 'about-us':
          return '/about';
        case 'partnership':
          return '/partnership';
        case 'tokenomics':
          return '/tokenomics';
        case 'main-page':
          return '/';
        default:
          return '/';
      }
    };

    const pageScrollUp = () => {
      const layoutElement = document.getElementById('Layout');
      layoutElement.scrollIntoView({
        behavior: 'smooth',
      });
    };
    /* eslint-disable */

    // disable winter animation
    // useEffect(() => {
    //   if (isLoadingFinish) {
    //     removeSnow();
    //     createSnow();
    //     showSnow(true);
    //   }

    //   const updateWindowDimensions = () => {
    //     const newHeight = window.innerHeight;
    //     if (newHeight !== heightScreen && isLoadingFinish) {
    //       setHeightScreen(newHeight);
    //       removeSnow();
    //       createSnow();
    //       showSnow(true);
    //     }
    //   };
  
    //   window.addEventListener("resize", updateWindowDimensions);
  
    //   return () => window.removeEventListener("resize", updateWindowDimensions)   
    // }, [isLoadingFinish]);

    return (
      <div
        className={classnames(
          styles.LayoutComponent,
          !isMobile && isInitialLoading && styles.LayoutComponent__hide,
          stores.isOpenMenuMobile && styles.LayoutComponent_position__fixed,
        )}
        id="Layout"
      >
        <Header
          isMobile={isMobile}
          isAccount={isAccount}
          isLeadsBack={isLeadsBack}
          withAvatar={withAvatar}
          states={{
            menu,
            userName,
            walletAddress,
            cones,
            honeyCoins,
            isLoading,
            copied,
          }}
          methods={{
            joinClick,
            accountClick,
            onCopyAddress,
            onQuitGameClick,
            gameClick,
            onLogoutClick,
            getHeaderLinkInfo,
            getHeaderLinkRoute,
          }}
        />

        <div
          className={classnames(
            styles.ChildrenContainer,
            isMobile && styles.ChildrenContainer__mobile,
          )}
        >
          {children}
        </div>
        <Footer
          isMobile={isMobile}
          isAccount={isAccount}
          isTransactionsPage={isTransactionsPage}
          states={{
            menu,
            userName,
            walletAddress,
            cones,
            honeyCoins,
            isLoading,
            copied,
          }}
          methods={{
            joinClick,
            accountClick,
            onCopyAddress,
            onQuitGameClick,
            gameClick,
            onLogoutClick,
            getHeaderLinkInfo,
            getHeaderLinkRoute,
          }}
        />
        <div
          className={classnames(
            styles.CookiesPopUpBlock,
            isMobile && styles.CookiesPopUpBlock__mobile,
          )}
        >
          {isOpenCookiesPopUp && <CookiesPopUp />}
          {stores.isShowPageUpButton && (
            <div
              className={classnames(
                styles.ArrowUpButtonContainer,
                isMobile && styles.ArrowUpButtonContainer__mobile,
              )}
            >
              <ArrowUpButton
                onClick={() => pageScrollUp()}
                width={isMobile ? 50 : 113}
                height={isMobile ? 50 : 119}
              />
            </div>
          )}
        </div>
        {stores.isOpenCookiesManageModal && (
          <div className={styles.CookiesManageModalBlock}>
            <CookiesManageModal />
          </div>
        )}
        {isMobile && (
          <MobileMenu
            states={{
              menu,
              userName,
              walletAddress,
              cones,
              honeyCoins,
              isLoading,
              copied,
            }}
            methods={{
              joinClick,
              accountClick,
              onCopyAddress,
              onQuitGameClick,
              gameClick,
              getHeaderLinkInfo,
              getHeaderLinkRoute,
            }}
          />
        )}
        {/* ANIMATION-WINTER */}
        {/* {isLoadingFinish && (
          <div
            id="snow"
            className={classnames(styles.SnowContainer)}
            count={100}
          />
        )} */}
      </div>
    );
  },
);

export default Layout;
