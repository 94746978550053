import React from 'react';
import BeeSpinnerSVG from '@assets/Layout/bee_spinner.svg';
import classnames from 'classnames';
import Logger from '@utils/logger';
import styles from './beeSpinner.module.scss';

const BeeSpinner = ({ classNames = {} }) => {
  return (
    <div className={classNames.Container}>
      <img
        src={BeeSpinnerSVG}
        alt="BeeSpinnerSVG"
        className={classnames(styles.BeeSpinner, classNames.Spinner)}
      />
    </div>
  );
};

export default BeeSpinner;
