import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _ from 'lodash';

import { useStores } from 'src/stores';
import { BEARS } from '@api';
import { getShortAddress, gtagEvent } from '@utils/common';
import authorization from '@utils/authorization';
import Logger from '@utils/logger';
import { GOOGLE_ANALYTICS } from '@src/constants/googleAnalyticsEvents';
import { HONEY_CAMEL_CASE, CONE, DENOM_UHONEY } from '@constants/blockchain';
import { MILLION } from '@constants/system';
import Input from '@components/Input';
import { Button, ColorType, SizeButton } from '@components_new/Button';
import { ENUM_MODAL } from '@components_new/Modal/enumModal';

import ConeIconMobile from '@assets/Mobile/AccountPage/cone_icon_mobile.png';
import HoneyIconMobile from '@assets/Mobile/AccountPage/honey_icon_mobile.png';

import styles from './approveSendTokenModal.module.scss';

const ApproveSendTokenModal = observer(({ close }) => {
  const navigate = useNavigate();
  const stores = useStores();
  const [isLoading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [address, setAddress] = useState('bears*');
  const [addressRecipientFull, setAddressRecipientFull] = useState('');
  const [amount, setAmount] = useState('-');
  const [feeCost, setFeeCost] = useState({ amount: 0.1, tokenName: HONEY_CAMEL_CASE });

  const schemaModal = yup
    .object()
    .shape({
      password: yup.string().min(5, stores.translate('password_input_validation')).required(),
    })
    .required();

  const ModalForm = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schemaModal),
    defaultValues: {
      password: '',
    },
  });
  const modalFields = ModalForm.watch();

  useEffect(() => {
    const { amount: amountCONE, recipient, fee: feeObject } = stores.propsForModal;
    if (feeObject && feeObject.amount) {
      const tokenName = feeObject.amount[0].denom === DENOM_UHONEY ? HONEY_CAMEL_CASE : CONE;
      const amountFee = tokenName === HONEY_CAMEL_CASE ? feeObject.amount[0].amount / MILLION : feeObject.amount[0].amount;
      setFeeCost({ amount: amountFee, tokenName });
    }
    setAmount(amountCONE);
    setAddressRecipientFull(recipient);
    setAddress(getShortAddress(recipient, null, true));
  }, []);

  useEffect(() =>{
    ModalForm.setError('password', {
      type: 'manual',
      message: errorText,
    });
  }, [errorText]);

  const sendTokens = async () => {
    try {
      const nameTokens = 'cone';
      const recipient = addressRecipientFull;
      const resultBears = await BEARS.sendTokens({ address: stores.profileAvailable.address, amount, recipient, nameTokens });
        // console.log('resultBears send >> ', resultBears);
      if (resultBears && resultBears.error) {
        if (resultBears.error && resultBears.message) {
          setErrorText(resultBears.message);
        } else {
          setErrorText('Error with transfer CONE');
        }
        Logger.infoMainNotify({ nameNotify: 'send CONE', section: 'send CONE', meta: { resultBears }, useInProduction: true });
      } else
      if (resultBears.code > 0) {
        if (resultBears.rawLog && resultBears.rawLog.split(':')) {
          const logs = resultBears.rawLog.split(':');
          setErrorText(`Error: ${logs[logs.length - 1]}`);
        } else {
          setErrorText(`Error occurred while sending coins with code ${resultBears.code}`);
        }
      } else {
        stores.setExecuteModal({
          name: ENUM_MODAL.SEND_TOKEN,
          state: { isVerify: true },
        });
        close(false);
      }
    } catch (err) {
      console.log(err);
      Logger.error(err);
      setErrorText('Error with transfer CONE');
    }
  }
  
  const finishPasswordClicked = async (successState) => {
    if (!successState) {
      ModalForm.setError('password', {
        type: 'manual',
        message: stores.translate('password_incorrect'),
      });
    } else {
      // nothing 
    }
  };

  const onCheckPasswordClick = async () => {
    setLoading(true);
    const isAccess = await authorization.checkPasswordAuth({
      password: modalFields.password,
      finish: finishPasswordClicked,
    });

    if (isAccess) {
      await sendTokens();
    }
    setLoading(false);
  };

  const handleKeypress = (e) => {
    if (e.key && e.key.toLowerCase() === 'enter') {
      ModalForm.handleSubmit(onCheckPasswordClick)();
    }
  };


  return (
    <>
      <p className={styles.SendTokenTitle}>Send Token</p>
      <div className={styles.DescriptionContainer}>
        <p className={styles.AddressText}>{address}</p>
        <p className={styles.ReceiveText}>&nbsp;will receive&nbsp;</p>
        <p className={styles.AmountText}>{amount} CONE</p>
      </div>
      <div className={styles.FeeContainer}>
        <p className={styles.FeeText}>Fee {feeCost.amount} {feeCost.tokenName}</p>
        <img
          onLoad={() => stores.increaseDownloadedImageCounter(feeCost.tokenName === CONE ? ConeIconMobile : HoneyIconMobile)}
          onError={() => Logger.error('Error with image loading')}
          src={feeCost.tokenName === CONE ? ConeIconMobile : HoneyIconMobile}
          alt="fee_token_icon"
          className={styles.FeeTokenIcon}
        />
      </div>
      <div className={styles.InputContainerWrapper}>
        <Input
          placeholder={stores.translate('password_input_placeholder')}
          canError
          label={stores.translate('password')}
          error={ModalForm.formState.errors?.password}
          name="password"
          register={ModalForm.register}
          autoComplete="no"
          isButtonSecretShow
          onKeyDown={handleKeypress}
          classNames={{
            Label: styles.LabelInput,
            InputContainer: styles.Input_InputContainer,
            Container: styles.Input_Container,
            Input: styles.Input_Input,
            InputButtonShow: styles.Input_InputButtonShow,
          }}
          isFocus
        />
      </div>
      <div className={styles.ButtonsContainer}>
        <Button
          colorType={ColorType.ORANGE}
          size={SizeButton.SMALL}
          onClick={() => {
            close(true);
          }}
          title={stores.translate('reject')}
          classNames={{
            Container: styles.ButtonContainer,
            Button: styles.Button,
            Title: classnames(styles.ButtonTitle, styles.ButtonTitle__Orange),
          }}
          isLoading={isLoading}
          disabled={isLoading}
          valueScaleAnimation={0.95}
        />
        <Button
          colorType={ColorType.GREEN}
          size={SizeButton.SMALL}
          onClick={() => {
            ModalForm.handleSubmit(onCheckPasswordClick)();
          }}
          title={stores.translate('approve')}
          classNames={{
            Container: styles.ButtonContainer,
            Button: styles.Button,
            Title: classnames(styles.ButtonTitle, styles.ButtonTitle__Green),
          }}
          isLoading={isLoading}
          disabled={isLoading}
          valueScaleAnimation={0.95}
        />
      </div>
    </>
  );
});

export default ApproveSendTokenModal;
