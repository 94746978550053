import React, { useState, useEffect } from 'react';
import { sanitize } from 'dompurify';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import _ from 'lodash';
import Logger from '@utils/logger';
import { gtagEvent } from '@utils/common';
import { GOOGLE_ANALYTICS } from '@src/constants/googleAnalyticsEvents';
import CloseNew from '@src/assets/control/close_new_icon.svg';
import HoneyIcon from '@assets/AccountPage/honey_icon.png';
import { UNIQ_ID_BUTTON } from '@components/Button'; // todo: need remove
import { Button, ColorType, SizeButton } from 'src/components_new/Button';
import { COST_CHANGE_NICKNAME, COST_COMMISSION } from '@constants/system';
import { ENUM_MODAL } from '@components_new/Modal/enumModal'; // todo: need remove
import { useStores } from 'src/stores';
import { useMediaQuery } from 'src/helpers/media';
import { ENUM_POPUP } from './enumPopup';
import styles from './popUp.module.scss';
import actionsPopup from './actionsPopup';

// const SizeTitleModal = {
//   Large: 'Large',
//   Medium: 'Medium',
//   Small: 'Small',
// };

const PopUp = observer(() => {
  const stores = useStores();
  const width1000 = useMediaQuery('(max-width: 1000px)');
  const [isPopupOpen, setPopupOpen] = useState(false);
  // const [namePopup, setNamePopup] = useState();

  const [infoButtons, setInfoButtons] = useState();
  const [titleTextHtml, setTitleTextHtml] = useState();
  //! IF there is a subtitle, BUT this is not implemented in code, ALTHOUGH the layout has
  // const [subtitleTextHtml, setSubtitleTextHtml] = useState();
  const [title, setTitle] = useState();
  const [classNames, setClassNames] = useState({});

  const closePopUp = () => {
    stores.setOpenPopup(false);
    stores.setPropsForPopup();
  };

  const updatePropsPopup = (name) => {
    switch (name) {
      case ENUM_POPUP.FOOTER_SUBSCRIBED:
        setInfoButtons([
          { uniqIDButton: UNIQ_ID_BUTTON.Footer.PopupClose, colorType: ColorType.GREEN },
        ]);
        setTitleTextHtml(stores.translate('we_are_sent_email_subscribed_html'));
        setTitle(stores.translate('subscribed!'));
        setClassNames({});
        break;
      case ENUM_POPUP.QUESTION_SENDED:
        setInfoButtons([
          { uniqIDButton: UNIQ_ID_BUTTON.Footer.PopupClose, colorType: ColorType.ORANGE },
        ]);
        setTitleTextHtml(stores.translate('your_question_sended'));
        setTitle(stores.translate('sended!'));
        setClassNames({});
        break;
      case ENUM_POPUP.CHANGE_APPROVE_NICKNAME: {
        setInfoButtons([
          { uniqIDButton: UNIQ_ID_BUTTON.Account.NicknamePopupClose, colorType: ColorType.ORANGE },
          {
            uniqIDButton: UNIQ_ID_BUTTON.Account.ConfirmNicknamePopup,
            title: String(Number(COST_CHANGE_NICKNAME+COST_COMMISSION)),
            colorType: ColorType.GREEN,
            actionButton: () => {
              actionsPopup.approveChangeNickname();
            },
            icon: HoneyIcon,
            styles: { IconButton: styles.HoneyIcon },
          },
        ]);
        setTitleTextHtml(stores.translate('your_wallet_will_be_debited_with_100_dot_01_HoneyCoin'));
        setTitle(stores.translate('confirm_your_nickname_choice'));
        setClassNames({});
        break;
      }

      case ENUM_POPUP.CHANGE_APPROVE_AVATAR: {
        const { honey } = stores.propsForPopup;
        setInfoButtons([
          { uniqIDButton: UNIQ_ID_BUTTON.Account.AvatarPopupClose, colorType: ColorType.ORANGE },
          {
            uniqIDButton: UNIQ_ID_BUTTON.Account.ApplyPopupAvatar,
            title: String(honey),
            colorType: ColorType.GREEN,
            actionButton: () => {
              actionsPopup.approveChangeAvatar({ honey });
            },
            icon: HoneyIcon,
            styles: { IconButton: styles.HoneyIcon },
          },
        ]);
        setTitleTextHtml(`Your wallet will be debited with ${honey} HoneyCoin`);
        setTitle(stores.translate('confirm_your_avatar_choice'));
        setClassNames({});
        break;
      }
      case ENUM_POPUP.CHANGE_AVATAR_WARNING: {
        const { honey } = stores.propsForPopup;
        setInfoButtons([{ uniqIDButton: UNIQ_ID_BUTTON.Account.WarningNicknameClose }]);
        setTitleTextHtml(
          `There is insufficient HoneyCoin to pay for the bear avatar change, need ${honey} HoneyCoin`,
        );
        setTitle(stores.translate('warning'));
        setClassNames({});
        break;
      }
      case ENUM_POPUP.CHANGE_NICKNAME_WARNING:
        setInfoButtons([{ uniqIDButton: UNIQ_ID_BUTTON.Account.WarningNicknameClose }]);
        setTitleTextHtml(stores.translate('no_enough_honey_for_change_nickname'));
        setTitle(stores.translate('warning'));
        setClassNames({});
        break;
      case ENUM_POPUP.CHANGE_NAME_NO_ACCESS_ACCOUNT:
        setInfoButtons([{ uniqIDButton: UNIQ_ID_BUTTON.Account.WarningNicknameClose }]);
        setTitleTextHtml(stores.translate('user_no_access_Change_nickname'));
        setTitle(stores.translate('warning'));
        setClassNames({});
        break;
      case ENUM_POPUP.CONTACT_US_SUBMIT:
        setInfoButtons([{ uniqIDButton: UNIQ_ID_BUTTON.ContactUsHeader.Close }]);
        setTitleTextHtml(stores.translate('popup_after_send'));
        setTitle(stores.translate('thank_you_for_submit'));
        setClassNames({
          TitlePopup: styles.TitlePopup__contactUs,
          PopUpText: styles.PopUpText__contactUs,
        });
        break;
      case ENUM_POPUP.SEED_PHRASE_SAVED:
        setInfoButtons([
          {
            uniqIDButton: UNIQ_ID_BUTTON.SeedPhrase.OK,
            title: stores.translate('ok'),
            colorType: ColorType.GREEN,
            actionButton: () => {
              stores.setOpenModal(ENUM_MODAL.LOGIN);
              gtagEvent(
                GOOGLE_ANALYTICS.CLICK_REGISTRATION,
                undefined,
                stores.propsForPopup.address,
              );
            },
          },
          {
            uniqIDButton: UNIQ_ID_BUTTON.SeedPhrase.Cancel,
            colorType: ColorType.ORANGE,
            title: stores.translate('cancel'),
          },
        ]);
        setTitleTextHtml(stores.translate('are_you_sure_saved_mnemonic'));
        setTitle(stores.translate('attention'));
        setClassNames({});
        break;
      case ENUM_POPUP.SELLING_PACK_TRANSFERRED:
        setInfoButtons([{ uniqIDButton: UNIQ_ID_BUTTON.Payment.Close }]);
        setTitleTextHtml(stores.translate('selling_pack_successfully_transferred'));
        setTitle(stores.translate('congratulation'));
        setClassNames({});
        break;
      case ENUM_POPUP.SELLING_PACK_HAS_BEEN_PURCHASED:
        setInfoButtons([{ uniqIDButton: UNIQ_ID_BUTTON.Payment.Close }]);
        setTitleTextHtml(stores.translate('selling_pack_has_been_purchased'));
        setTitle(stores.translate('warning'));
        setClassNames({});
        break;
      case ENUM_POPUP.SELLING_PACK_NO_PURCHASED_INIT:
        setInfoButtons([{ uniqIDButton: UNIQ_ID_BUTTON.Payment.Close }]);
        setTitleTextHtml(stores.translate('buy_starter_selling_pack'));
        setTitle(stores.translate('warning'));
        setClassNames({});
        break;
      case ENUM_POPUP.KEPLER_ERROR:
        setInfoButtons([{ uniqIDButton: UNIQ_ID_BUTTON.Kepler.Close }]);
        setTitleTextHtml(stores.propsForPopup.textErrorKeplr);
        setTitle(stores.translate('warning'));
        setClassNames({});
        break;
      case ENUM_POPUP.AIRDROP_SUCCESS:
        setInfoButtons([{ uniqIDButton: UNIQ_ID_BUTTON.Payment.Close }]);
        setTitleTextHtml(stores.translate('airdrop_success'));
        setTitle(stores.translate('congratulation'));
        setClassNames({});
        break;
      case ENUM_POPUP.CONFIRM_BURN: {
        const { cones } = stores.propsForPopup;
        setInfoButtons([
          { uniqIDButton: UNIQ_ID_BUTTON.Payment.Close, colorType: ColorType.ORANGE },
          {
            uniqIDButton: UNIQ_ID_BUTTON.Payment.Confirm,
            title: 'Confirm',
            colorType: ColorType.GREEN,
            actionButton: () => {
              actionsPopup.confirmBurnCones({ cones });
            },
            // styles: { IconButton: styles.HoneyIcon },
          },
        ]);
        setTitleTextHtml(`${stores.translate('are_you_sure_burn')} ${cones} CONEs?`);
        setTitle(stores.translate('attention'));
        setClassNames({});
        break;
      }
      case ENUM_POPUP.NO_ENOUGH_CONES_FOR_BURN: {
        const { cones } = stores.propsForPopup;
        setInfoButtons([{ uniqIDButton: UNIQ_ID_BUTTON.Payment.Close }]);
        setTitleTextHtml(`${stores.translate('not_enough_cones')} ${cones} CONEs.`);
        setTitle(stores.translate('warning'));
        setClassNames({});
        break;
      }
      case ENUM_POPUP.HONEY_PACK_TRANSFERRED:
        setInfoButtons([{ uniqIDButton: UNIQ_ID_BUTTON.Payment.Close }]);
        setTitleTextHtml(stores.translate('honey_pack_successfully_transferred'));
        setTitle(stores.translate('congratulation'));
        setClassNames({});
        break;
      case ENUM_POPUP.SEND_TOKEN:
        setInfoButtons([
          { uniqIDButton: UNIQ_ID_BUTTON.Payment.Close, title: 'Reject', colorType: ColorType.ORANGE },
          {
            uniqIDButton: UNIQ_ID_BUTTON.Payment.Confirm,
            title: 'Approve',
            colorType: ColorType.GREEN,
            actionButton: () => {
              // actionsPopup.confirmBurnCones({ cones });
            },
            // styles: { IconButton: styles.HoneyIcon },
          },
        ]);
        setTitleTextHtml(stores.translate('will_receive_tokens'));
        setTitle(stores.translate('attention'));
        setClassNames({});
        break;
      default:
        break;
    }
  };

  /*  const updateSizeTitleModal = (name) => {
    switch (name) {
      case ENUM_POPUP.CHANGE_NAME_NO_ACCESS_ACCOUNT:
      case ENUM_POPUP.CHANGE_NICKNAME_WARNING:
        return width1000 ? SizeTitleModal.Small : SizeTitleModal.Large;
      case ENUM_POPUP.CONTACT_US_SUBMIT:
        return SizeTitleModal.Large;
      case ENUM_POPUP.FOOTER_SUBSCRIBED:
        return width1000 ? SizeTitleModal.Small : SizeTitleModal.Large;
      case ENUM_POPUP.QUESTION_SENDED:
        return width1000 ? SizeTitleModal.Small : SizeTitleModal.Large;
      case ENUM_POPUP.SEED_PHRASE_SAVED:
        return width1000 ? SizeTitleModal.Small : SizeTitleModal.Large;
      case ENUM_POPUP.SELLING_PACK_TRANSFERRED:
        return width1000 ? SizeTitleModal.Small : SizeTitleModal.Large;
      case ENUM_POPUP.SELLING_PACK_HAS_BEEN_PURCHASED:
        return width1000 ? SizeTitleModal.Small : SizeTitleModal.Large;
      case ENUM_POPUP.SELLING_PACK_NO_PURCHASED_INIT:
        return width1000 ? SizeTitleModal.Small : SizeTitleModal.Large;
      default:
        return SizeTitleModal.Large;
    }
  }; */

  useEffect(() => {
    if (stores.openPopup && ENUM_POPUP[stores.openPopup]) {
      updatePropsPopup(stores.openPopup);
      // setNamePopup(stores.openPopup);
      // console.log(stores.openPopup);
      /* Dynamically lock scrolling background */
      setTimeout(() => {
        document.body.style.overflowY = 'hidden';
      }, 100);
      setPopupOpen(true);
    } else {
      /* Dynamically unlock scrolling background */
      document.body.style.overflowY = 'initial';
      setPopupOpen(false);
    }
  }, [stores.openPopup]);

  useEffect(() => {
    if (stores.propsForPopup && stores.propsForPopup.timeout > 0) {
      setTimeout(closePopUp, stores.propsForPopup.timeout);
    }
  }, [stores.propsForPopup]);

  const clickScreenOutside = (e) => {
    if (
      e &&
      e.target &&
      e.target.className &&
      _.isString(e.target.className) &&
      e.target.className.includes('popUp_PopUpContainer')
    ) {
      closePopUp();
    }
  };

  return (
    isPopupOpen && (
      <div
        className={styles.PopUpContainer}
        role="button"
        tabIndex={0}
        onClick={(e) => clickScreenOutside(e)}
        onKeyDown={() => {}}
      >
        <div
          className={styles.PopUp}
          style={{ paddingBottom: width1000 && (infoButtons.length - 1) * 120 }}
        >
          <p className={styles.Title} dangerouslySetInnerHTML={{ __html: sanitize(title) }} />
          <button
            type="button"
            className={styles.PopUpClose}
            onClick={() => {
              closePopUp();
            }}
          >
            <img
              onLoad={() => stores.increaseDownloadedImageCounter(CloseNew)}
              onError={() => Logger.error('Error with image loading')}
              src={CloseNew}
              alt="close_new_icon"
            />
          </button>
          <div className={classnames(styles.PopUpTextContainer, classNames.PopUpTextContainer)}>
            <p
              className={classnames(
                styles.PopUpTitleTextHtmlText,
                classNames.PopUpTitleTextHtmlText,
              )}
              dangerouslySetInnerHTML={{ __html: sanitize(titleTextHtml) }}
            />
            <div className={styles.ButtonsContainer}>
              {infoButtons &&
                infoButtons.map((infoButton) => {
                  return (
                    <Button
                      size={SizeButton.SMALL}
                      key={`uniq_id_button_${infoButton.uniqIDButton}`}
                      uniqID={infoButton.uniqIDButton}
                      colorType={infoButton.colorType}
                      onClick={() => {
                        if (infoButton.actionButton) {
                          infoButton.actionButton();
                        }
                        closePopUp();
                      }}
                      title={infoButton.title || stores.translate('close')}
                      classNames={{
                        Container: styles.ButtonContainer,
                        Title: stores.browserInfo.isAndroid
                          ? styles[`TitleButton__${infoButton.colorType}__android`]
                          : styles[`TitleButton__${infoButton.colorType}`],
                        IconButton: infoButton.styles && infoButton.styles.IconButton,
                      }}
                      icon={infoButton.icon}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    )
  );
});

export default PopUp;
