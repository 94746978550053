import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'src/stores';
import { sanitize } from 'dompurify';
import { Button, ColorType, SizeButton } from 'src/components_new/Button';
import { GOOGLE_ANALYTICS } from '@src/constants/googleAnalyticsEvents';
import { gtagEvent } from '@utils/common';
import URLS from '@constants/urls';
import TitleSection from '@src/components/TitleSection';
import Background from '@assets/Mobile/TokenomicsPage/CapitalSection/capital_section_image_mobile.jpg';
import BackgroundTablet from '@assets/Mobile/TokenomicsPage/CapitalSection/capital_section_image_tablet.jpg';
import BackgroundDesktop from '@assets/Mobile/TokenomicsPage/CapitalSection/capital_section_image_desktop.jpg';
import classnames from 'classnames';
import Logger from '@utils/logger';
import styles from './mobileCapital.module.scss';

const CapitalSection = observer(() => {
  const stores = useStores();
  const _ = stores.language;

  return (
    <div className={styles.Container}>
      <div className={styles.BackgroundImagesContainer}>
        <img
          onLoad={() => stores.increaseDownloadedImageCounter(Background)}
          onError={() => Logger.error('Error with image loading')}
          src={Background}
          alt="capital_section_image_mobile"
          className={styles.BackgroundImage}
        />
        <img
          onLoad={() => stores.increaseDownloadedImageCounter(BackgroundTablet)}
          onError={() => Logger.error('Error with image loading')}
          src={BackgroundTablet}
          alt="capital_section_image_tablet"
          className={styles.BackgroundTabletImage}
        />
        <img
          onLoad={() => stores.increaseDownloadedImageCounter(BackgroundDesktop)}
          onError={() => Logger.error('Error with image loading')}
          src={BackgroundDesktop}
          alt="capital_section_image_desktop"
          className={styles.BackgroundDesktopImage}
        />
      </div>
      <div className={styles.InfoContainer}>
        <div className={styles.TitleContainer}>
          <TitleSection
            title={stores.translate('tokenomics_title')}
            textHtml={stores.translate('tokenomics_subtitle')}
            classNames={{
              Container: styles.CaptionTitleContainer,
              Title: styles.CaptionTitle,
              Text: styles.CaptionSubtitle,
            }}
            isH1TitleTag
          />
          <p
            className={styles.CaptionSubtitle}
            dangerouslySetInnerHTML={{
              __html: sanitize(stores.translate('tokenomics_second_subtitle')),
            }}
          />
          <Button
            colorType={ColorType.ORANGE}
            size={SizeButton.MEDIUM}
            title={stores.translate('white_paper')}
            classNames={{
              Container: styles.ButtonContainer,
              Button: styles.WhitepaperButton,
              Title: classnames(
                styles.TitleButton,
                stores.browserInfo.isAndroid
                  ? styles.TitleOrangeButton__android
                  : styles.TitleOrangeButton,
              ),
            }}
            onClick={() => {
              gtagEvent(GOOGLE_ANALYTICS.CLICK_YIELD_CALCULATOR);
              window.open(URLS.WHITE_PAPER, '_blank', 'noopener,noreferrer');
            }}
          />
        </div>
      </div>
    </div>
  );
});

export default CapitalSection;
