/* eslint-disable no-unused-vars */
/* eslint-disable */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from 'src/stores';
import { ENUM_MODAL } from '@components_new/Modal/enumModal';
import { gtagEvent } from '@utils/common';
import { GOOGLE_ANALYTICS } from '@src/constants/googleAnalyticsEvents';
// import { debounce } from 'lodash';

import { Button, ColorType, UNIQ_ID_BUTTON } from '@components/Button';

import { connectKeplr } from 'src/api/KeplrWallet';
import styles from './modal.module.scss';

// const debounced = debounce(() => {
//   gtagEvent(GOOGLE_ANALYTICS.SLIDE_INVEST);
// }, 1000);

const JoinModal = observer(({ close }) => {
  const navigate = useNavigate();
  const stores = useStores();
  const isConnectKeplr = (stores.browserInfo.isChrome || stores.browserInfo.isFirefox) && !stores.browserInfo.isMobile;

  const connectKeplrPress = async () => {
    const stateConnect = await connectKeplr();
    if (stateConnect) {
      navigate('/account');
    }
    close();
  };

  return (
    <>
      <p className={styles.InfoTitle}>{stores.translate('login_if_have_account')}</p>
      <div className={styles.ButtonsContainer}>
        <Button
          colorType={ColorType.ORANGE}
          uniqID={UNIQ_ID_BUTTON.JoinHeader.Login}
          onClick={() => {
            stores.setOpenModal(ENUM_MODAL.LOGIN);
          }}
          title={stores.translate('login')}
          classNames={{
            Container: styles.ButtonContainer,
            Title: stores.browserInfo.isAndroid
              ? styles.TitleOrangeButton__android
              : styles.TitleOrangeButton,
          }}
          valueScaleAnimation={0.95}
        />
        <Button
          colorType={ColorType.GREEN}
          uniqID={UNIQ_ID_BUTTON.JoinHeader.SignUp}
          onClick={() => {
            stores.setOpenModal(ENUM_MODAL.SEED_PHRASE);
          }}
          title={stores.translate('sign_up')}
          classNames={{
            Container: styles.ButtonContainer,
            Title: stores.browserInfo.isAndroid
              ? styles.TitleGreenButton__android
              : styles.TitleGreenButton,
          }}
          valueScaleAnimation={0.95}
        />
      </div>
      <p
        className={styles.ModalText}
        dangerouslySetInnerHTML={{ __html: stores.translate('proposal_agreement_sign_up') }}
      />
      {isConnectKeplr && (
        <>
          <p className={styles.KeplerTitle}>{stores.translate('if_you_have_kepler_wallet')}</p>
          <div className={styles.ButtonsContainer}>
            <Button
              colorType={ColorType.BLUE}
              uniqID={UNIQ_ID_BUTTON.JoinHeader.Kepler}
              isKepler
              onClick={() => {
                connectKeplrPress();
                // stores.setOpenModal(ENUM_MODAL.SEED_PHRASE);
              }}
              width={363}
              title={stores.translate('connect')}
              classNames={{
                Container: styles.ButtonContainer,
                Title: stores.browserInfo.isAndroid
                  ? styles.TitleBlueButton__android
                  : styles.TitleBlueButton,
              }}
              valueScaleAnimation={0.95}
            />
          </div>
        </>
      )}
    </>
  );
});

export default JoinModal;
