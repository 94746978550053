import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { sanitize } from 'dompurify';
import { gtagEvent, copyText } from '@utils/common';
import { useCopied } from '@hooks/custom';
import { GOOGLE_ANALYTICS } from '@src/constants/googleAnalyticsEvents';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Close from '@src/assets/control/close_new_icon.svg';
import DiagonalLine from '@assets/common/diagonal_line.svg';
import BinanceIcon from '@assets/common/binance_icon.png';
import { Button, ColorType, SizeButton } from '@components_new/Button';
import InformationPopup from '@components/InformationPopup';
import KYCScreen from '@components/KYCScreen';
import CheckBox from '@components/CheckBox';
import payments from 'src/api/server/payments';
import { QRCodeSVG } from 'qrcode.react';
import { observer } from 'mobx-react';
import { useStores } from 'src/stores';
import PackItemMobile from '@components_new/PackItemMobile';
import { ENUM_POPUP } from '@components/PopUp/enumPopup';
import BeeSpinner from '@components/BeeSpinner';
import URLS from 'src/constants/urls';
import InputWord from '@components/InputWord';
import Logger from '@utils/logger';
import styles from './paymentsMobile.module.scss';

const SELLING_PACKS_STATUSES = {
  WAITING: 'WAITING',
  TRANSFERRING: 'TRANSFERRING',
  TRANSFERED: 'TRANSFERED',
  ERROR: 'ERROR',
  ERROR_PAYMENT: 'ERROR_PAYMENT',
};

const PREPARE_STEP = 'prepare';
const PROCESS_STEP = 'process';
const FINISH_STEP = 'finish';

const PaymentsModalMobile = observer(({ close }) => {
  const stores = useStores();
  const [isErrorPrivacy, setErrorPrivacy] = useState(false);
  const [isLoadingNowPayment, setLoadingNowPayment] = useState(false);
  const [isLoadingBinance, setLoadingBinance] = useState(false);
  const [selectedPack, setSelectedPack] = useState('');
  const [selectedAgree, setSelectedAgree] = useState(false);
  const [packInfo, setPackInfo] = useState();
  const [oldCostSellingPack, setOldCostSellingPack] = useState(0);
  const [packHasBonus, setPackHasBonus] = useState(false);
  const [responseBinance, setResponseBinance] = useState();
  const [statusSellingPack, setStatusSellingPack] = useState(SELLING_PACKS_STATUSES.WAITING);
  const [errorTextSellingPack, setErrorTextSellingPack] = useState('');
  const [responseNowPayment, setResponseNowPayment] = useState();
  const [isShowKYC, setShowKYC] = useState(false);
  const [processPayment, setProcessPayment] = useState(PREPARE_STEP);
  const { setCopied, copied } = useCopied();

  const schemaModal = yup
    .object()
    .shape({
      referralCode: yup.string(),
    })
    .required();

  const ModalForm = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schemaModal),
    defaultValues: {
      referralCode: '',
    },
  });
  const modalFields = ModalForm.watch();

  useEffect(() => {
    /* Dynamically lock scrolling background */
    document.body.style.overflowY = 'hidden';
    setPackInfo(stores.stateDisplayedPaymentModal.packInfo);
    // console.log(stores.stateDisplayedPaymentModal.packInfo);
    if (
      stores.stateDisplayedPaymentModal.packInfo.ITEMS &&
      stores.stateDisplayedPaymentModal.packInfo.ITEMS.length > 0
    ) {
      let oldTotal = 0;
      stores.stateDisplayedPaymentModal.packInfo.ITEMS.forEach((item) => {
        oldTotal += item.PRICE;
      });
      setOldCostSellingPack(oldTotal);
    } else {
      setOldCostSellingPack(stores.stateDisplayedPaymentModal.packInfo.PRICE);
    }
    setSelectedPack(stores.stateDisplayedPaymentModal.packInfo.ID);
    if (
      stores.stateDisplayedPaymentModal.packInfo.ITEMS &&
      stores.stateDisplayedPaymentModal.packInfo.ITEMS.length === 1
    ) {
      if (
        stores.stateDisplayedPaymentModal.packInfo.PRICE ===
        stores.stateDisplayedPaymentModal.packInfo.ITEMS[0].PRICE
      ) {
        setPackHasBonus(true);
      }
    }
    return () => {
      /* Dynamically unlock scrolling background */
      document.body.style.overflowY = 'initial';
    };
  }, []);

  const finishNowPaymentStep = async () => {
    try {
      const paymentResponse = await payments.paymentNowPayment({
        pack: selectedPack,
        referralCode: modalFields.referralCode,
      });
      // console.log('paymentResponse >> ', paymentResponse);
      if (paymentResponse.err) {
        setStatusSellingPack(SELLING_PACKS_STATUSES.ERROR_PAYMENT);
        setErrorTextSellingPack(paymentResponse.msg);
      } else {
        setResponseNowPayment(paymentResponse);
      }
      setProcessPayment(FINISH_STEP);
    } catch (err) {
      // eslint-disable-next-line no-console
      Logger.error(err);
    }
  };

  useEffect(() => {
    (async () => {
      // console.log('isFinishKYCVerified >>>> ', stores.isFinishKYCVerified);
      if (stores.isFinishKYCVerified) {
        await finishNowPaymentStep();
        setShowKYC(false);
      }
    })();
  }, [stores.isFinishKYCVerified]);

  useEffect(() => {
    let intervalId;
    (async () => {
      // console.log('isFinishKYCVerified >>>> ', stores.isFinishKYCVerified);
      if (processPayment === FINISH_STEP && responseBinance) {
        intervalId = setInterval(async () => {
          const binanceInfo = await payments.getBinanceInfo({ id: responseBinance._id });
          // console.log('response binanceInfo >> ', binanceInfo);
          if (binanceInfo.selling_pack_status !== SELLING_PACKS_STATUSES.WAITING) {
            setStatusSellingPack(binanceInfo.selling_pack_status);
            if (binanceInfo.selling_pack_status === SELLING_PACKS_STATUSES.ERROR) {
              setErrorTextSellingPack(binanceInfo.selling_pack_error);
            }
          }
          if (binanceInfo.selling_pack_status === SELLING_PACKS_STATUSES.TRANSFERED) {
            stores.setUpdateStateBearCreated(true);
          }
        }, 10000);
      } else if (processPayment === FINISH_STEP && responseNowPayment) {
        intervalId = setInterval(async () => {
          const nowPaymentInfo = await payments.getNowPaymentInfo({ id: responseNowPayment._id });
          // console.log('response nowPaymentInfo >> ', nowPaymentInfo);
          if (nowPaymentInfo.selling_pack_status !== SELLING_PACKS_STATUSES.WAITING) {
            setStatusSellingPack(nowPaymentInfo.selling_pack_status);
            if (nowPaymentInfo.selling_pack_status === SELLING_PACKS_STATUSES.ERROR) {
              setErrorTextSellingPack(nowPaymentInfo.selling_pack_error);
            }
          }
          if (nowPaymentInfo.selling_pack_status === SELLING_PACKS_STATUSES.TRANSFERED) {
            stores.setUpdateStateBearCreated(true);
          }
        }, 10000);
      }
    })();
    return () => {
      clearInterval(intervalId);
    };
  }, [processPayment]);

  useEffect(() => {
    if (statusSellingPack === SELLING_PACKS_STATUSES.TRANSFERED) { // пак был отправлен на аккаунт.
      stores.setOpenPopup(ENUM_POPUP.SELLING_PACK_TRANSFERRED);
      close(true);
      stores.setFinishKYCVerified(false);
    }
  }, [statusSellingPack]);

  const clickScreenOutside = (e) => {
    if (
      e &&
      e.target &&
      e.target.className &&
      _.isString(e.target.className) &&
      e.target.className.includes('paymentsMobile_ModalContainer')
    ) {
      close(true);
      stores.setFinishKYCVerified(false);
    }
  };

  const copyLink = () => {
    if (responseBinance) {
      copyText({ text: responseBinance.payment_links.checkoutUrl });
    } else if (responseNowPayment) {
      copyText({ text: responseNowPayment.pay_address });
    }
    setCopied();
  };

  const binancePaymentClick = async () => {
    if (!processPayment === PREPARE_STEP || isLoadingNowPayment) return;
    setLoadingBinance(true);
    setProcessPayment(PROCESS_STEP);
    const paymentResponse = await payments.paymentBinance({
      pack: selectedPack,
      referralCode: modalFields.referralCode,
    });
    // console.log('paymentResponse binance >> ', paymentResponse);
    if (paymentResponse.err) {
      setStatusSellingPack(SELLING_PACKS_STATUSES.ERROR_PAYMENT);
      setErrorTextSellingPack(paymentResponse.msg);
      // console.log('>>>>>')
    } else {
      setResponseBinance(paymentResponse);
    }

    setProcessPayment(FINISH_STEP);
    setLoadingBinance(false);
  };

  const nowPaymentClick = async () => {
    if (!processPayment === PREPARE_STEP || isLoadingBinance) return;
    setLoadingNowPayment(true);
    setProcessPayment(PROCESS_STEP);
    const isVerified = await payments.getVerifiedKYC();
    // console.log('isVerified nowPayment >> ', isVerified);
    if (isVerified) {
      await finishNowPaymentStep();
    } else {
      const result = await payments.getTokenNowPayment();
      stores.setTokenNowPayments(result.token);
      setShowKYC(true);
    }
    setLoadingNowPayment(false);
  };

  const closeKYC = (status) => {
    if (status) {
      setShowKYC(false);
      setProcessPayment(PREPARE_STEP);
    }
  };
  // console.log(packInfo);

  return (
    <div
      className={styles.ModalContainer}
      role="button"
      tabIndex={0}
      onClick={(e) => clickScreenOutside(e)}
      onKeyDown={() => {}}
    >
      <div className={styles.ModalWrapper}>
        <div className={styles.TitleContainer} style={{ display: isShowKYC ? 'none' : 'flex' }}>
          <p
            className={styles.Title}
            dangerouslySetInnerHTML={{ __html: sanitize(`${packInfo?.TITLE} Pack`) }}
          />
        </div>
        <div className={styles.Modal} style={{ display: isShowKYC ? 'none' : 'block' }}>
          <button
            type="button"
            className={styles.ModalClose}
            onClick={() => {
              close(true);
              stores.setFinishKYCVerified(false);
              gtagEvent(GOOGLE_ANALYTICS.CLICK_CLOSE);
            }}
          >
            <img
              onLoad={() => stores.increaseDownloadedImageCounter(Close)}
              onError={() => Logger.error('Error with image loading')}
              src={Close}
              alt="close_new_icon"
              loading="lazy"
            />
          </button>
          {selectedPack && packInfo && processPayment !== FINISH_STEP && (
            <>
              <p className={styles.TitleText}>
                {stores.translate('chosen_selling_pack')}, «{packInfo.TITLE}»
              </p>
              <div className={styles.PackItemsContainer}>
                {packInfo.ITEMS.map((item, index) => {
                  if (!packHasBonus && item.BONUS) {
                    setPackHasBonus(true);
                  }
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className={classnames(styles.ItemsBlock)} key={index}>
                      <PackItemMobile
                        type={item.KIND}
                        title={item.TITLE}
                        price={item.BONUS ? '' : item.PRICE}
                        itemsDescription={stores.paramsForObjectsBlockchain}
                        params={item.PARAMS}
                      />
                    </div>
                  );
                })}
              </div>
              <div className={styles.CostContainer}>
                <p className={styles.TitleNewTotalText}>
                  {stores.translate('total')}: {packInfo.PRICE} USDT
                </p>
                {/* // ! not tested */}
                {packHasBonus && (
                  <div className={styles.TitleOldTotalTextContainer}>
                    <p className={styles.TitleOldTotalText}>{oldCostSellingPack} USDT</p>
                    <img
                      onLoad={() => stores.increaseDownloadedImageCounter(DiagonalLine)}
                      onError={() => Logger.error('Error with image loading')}
                      src={DiagonalLine}
                      alt="line_old_cost_selling_pack"
                      className={styles.TitleOldTotalLine}
                    />
                  </div>
                )}
              </div>
              <div className={styles.ReferralContainer}>
                <p className={styles.TitleEnterReferral}>
                  {stores.translate('enter_referral_code')}
                </p>
                <InputWord
                  placeholder={stores.translate('code')}
                  name="referralCode"
                  register={ModalForm.register}
                  autoComplete="no"
                  classNames={{
                    Label: styles.LabelInput,
                    InputContainer: styles.Input_InputContainer,
                    Container: styles.Input_Container,
                    Input: styles.Input_Input,
                    InputButtonShow: styles.Input_InputButtonShow,
                  }}
                  maxLength={10}
                />
              </div>
              <div className={styles.CheckBoxContainer}>
                <CheckBox
                  selected={selectedAgree}
                  onChange={() => {
                    setErrorPrivacy(false);
                    setSelectedAgree(!selectedAgree);
                  }}
                  classNames={{
                    ContainerImage: styles.CheckBoxImage,
                    Container: styles.CheckBox,
                    Title: styles.CheckBoxTitle,
                  }}
                  isError={isErrorPrivacy}
                />
                <div className={styles.CheckBoxTextContainer}>
                  <p
                    className={styles.RightsText}
                    dangerouslySetInnerHTML={{
                      __html: sanitize(stores.translate('i_agree_to_the')),
                    }}
                  />
                  &nbsp;
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={URLS.TERMS_OF_USE}
                    className={classnames(styles.RightsText, styles.TermsLink)}
                    onClick={() => {
                      gtagEvent(GOOGLE_ANALYTICS.CLICK_TERMS);
                    }}
                  >
                    {stores.translate('terms_of_use_capital_letter')},
                  </a>
                  &nbsp;
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={URLS.PRIVACY_POLICY}
                    className={classnames(styles.RightsText, styles.PrivacyLink)}
                    onClick={() => {
                      gtagEvent(GOOGLE_ANALYTICS.CLICK_PRIVACY);
                    }}
                  >
                    {stores.translate('privacy_policy')},
                  </a>
                  &nbsp;
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={URLS.COIN_SALE}
                    className={classnames(styles.RightsText, styles.CoinSaleLink)}
                    onClick={() => {
                      gtagEvent(GOOGLE_ANALYTICS.CLICK_PRIVACY);
                    }}
                  >
                    {stores.translate('coin_sale_agreement')}
                  </a>
                </div>
              </div>
              <div className={styles.PaymentsButtonContainer}>
                <Button
                  size={SizeButton.MEDIUM}
                  colorType={ColorType.ORANGE}
                  icon={BinanceIcon}
                  onClick={() => {
                    if (selectedAgree) {
                      binancePaymentClick();
                      gtagEvent(GOOGLE_ANALYTICS.CLICK_BINANCE_PAYMENT);
                    } else {
                      setErrorPrivacy(true);
                    }
                  }}
                  title={stores.translate('binance_payment_mobile')}
                  classNames={{
                    Container: styles.ButtonContainer,
                    Button: classnames(styles.Button, styles.Button__Binance),
                    Title: stores.browserInfo.isAndroid
                      ? classnames(
                          styles.TitleOrangeButton,
                          styles.TitleOrangeButton__android,
                          styles.TitleOrangeButton__Payments,
                        )
                      : classnames(
                          styles.TitleOrangeButton,
                          styles.TitleOrangeButton__Payments,
                          styles.TitleOrangeButton__Payments_Binance,
                        ),
                  }}
                  isLoading={isLoadingBinance}
                  disabled={processPayment !== PREPARE_STEP}
                />
                <Button
                  size={SizeButton.MEDIUM}
                  colorType={ColorType.ORANGE}
                  onClick={() => {
                    if (selectedAgree) {
                      nowPaymentClick();
                      gtagEvent(GOOGLE_ANALYTICS.CLICK_NOW_PAYMENT);
                    } else {
                      setErrorPrivacy(true);
                    }
                  }}
                  title={stores.translate('now_payment')}
                  classNames={{
                    Container: styles.ButtonContainer,
                    Button: styles.Button,
                    Title: stores.browserInfo.isAndroid
                      ? classnames(
                          styles.TitleOrangeButton,
                          styles.TitleOrangeButton__android,
                          styles.TitleOrangeButton__Payments,
                        )
                      : classnames(styles.TitleOrangeButton, styles.TitleOrangeButton__Payments),
                  }}
                  isLoading={isLoadingNowPayment}
                  disabled={processPayment !== PREPARE_STEP}
                />
              </div>
            </>
          )}
          {processPayment === FINISH_STEP && (
            <>
              {responseBinance && (
                <div className={styles.PaymentInfoContainer}>
                  {statusSellingPack === SELLING_PACKS_STATUSES.WAITING ? (
                    <>
                      <p className={styles.PaymentInfoTitle}>
                        {stores.translate('link_for_paying_package')}, {selectedPack}:
                      </p>
                      {responseBinance.payment_links && responseBinance.payment_links.checkoutUrl && (
                        <a
                          href={
                            stores.browserInfo.isMobile
                              ? responseBinance.payment_links?.deeplink
                              : responseBinance.payment_links?.checkoutUrl
                          }
                          className={styles.PaymentInfoLink}
                        >
                          {stores.browserInfo.isMobile
                            ? responseBinance.payment_links?.deeplink
                            : responseBinance.payment_links?.checkoutUrl}
                        </a>
                      )}
                      <div className={styles.ButtonsContainer}>
                        <Button
                          size={SizeButton.MEDIUM}
                          colorType={ColorType.ORANGE}
                          onClick={() => {
                            copyLink();
                            gtagEvent(
                              `${GOOGLE_ANALYTICS.CLICK_COPY_LINK} ${
                                isShowKYC ? 'KYC' : 'Binance'
                              }`,
                            );
                          }}
                          title={stores.translate('copy_link')}
                          classNames={{
                            Container: styles.CopyLinkButtonContainer,
                            Button: styles.Button,
                            Title: stores.browserInfo.isAndroid
                              ? classnames(
                                  styles.TitleOrangeButton,
                                  styles.TitleOrangeButton__android,
                                )
                              : styles.TitleOrangeButton,
                          }}
                          isLoading={isLoadingNowPayment}
                          valueScaleAnimation={0.95}
                        />
                        {copied && (
                          <InformationPopup
                            info={stores.translate('copied')}
                            classNames={{ Container: styles.InformationPopupCopied }}
                          />
                        )}
                      </div>

                      {responseBinance.payment_links &&
                        responseBinance.payment_links.qrcodeLink && (
                          <img
                            onLoad={() =>
                              stores.increaseDownloadedImageCounter(
                                responseBinance.payment_links.qrcodeLink,
                              )
                            }
                            onError={() => Logger.error('Error with image loading')}
                            src={responseBinance.payment_links.qrcodeLink}
                            alt="binance_qr_code"
                            className={styles.BinanceQrCode}
                          />
                        )}
                      <div className={styles.PaymentInfoAttentionContainer}>
                        <p className={styles.PaymentInfoAttention}>
                          {stores.translate('notes_one_purchase_payment')}
                        </p>
                      </div>
                    </>
                  ) : statusSellingPack === SELLING_PACKS_STATUSES.TRANSFERRING ? (
                    <>
                      <p className={styles.PaymentInfoTitle}>
                        {stores.translate('your_invoice_processed')}
                      </p>
                      <BeeSpinner
                        classNames={{ Spinner: styles.LoadingTransferringPackContainer }}
                      />
                    </>
                  ) : (
                    <>
                      <p className={styles.PaymentInfoTitle}>
                        {stores.translate('error_occurred_receiving_selling_pack')}:
                      </p>
                      <div className={styles.PaymentInfoAttentionContainer}>
                        <p className={styles.PaymentInfoAttention}>{errorTextSellingPack}</p>
                      </div>
                    </>
                  )}
                </div>
              )}
              {responseNowPayment && (
                <div className={styles.PaymentInfoContainer}>
                  {statusSellingPack === SELLING_PACKS_STATUSES.WAITING ? (
                    <>
                      <p className={styles.PaymentInfoTitle}>
                        {stores.translate('wallet_address_for_payment')}, {selectedPack}:
                      </p>
                      <p className={styles.PaymentInfoAddress}>{responseNowPayment.pay_address}</p>
                      <div className={styles.ButtonsContainer}>
                        <Button
                          size={SizeButton.MEDIUM}
                          colorType={ColorType.ORANGE}
                          onClick={() => {
                            copyLink();
                            gtagEvent(
                              `${GOOGLE_ANALYTICS.CLICK_COPY_LINK} ${
                                isShowKYC ? 'KYC' : 'Binance'
                              }`,
                            );
                          }}
                          title={stores.translate('copy_address')}
                          classNames={{
                            Button: styles.Button,
                            Title: stores.browserInfo.isAndroid
                              ? classnames(
                                  styles.TitleOrangeButton,
                                  styles.TitleOrangeButton__android,
                                )
                              : styles.TitleOrangeButton,
                          }}
                          isLoading={isLoadingNowPayment}
                          valueScaleAnimation={0.95}
                        />
                        {copied && (
                          <InformationPopup
                            info={stores.translate('copied')}
                            classNames={{ Container: styles.InformationPopupCopied }}
                          />
                        )}
                      </div>
                      <QRCodeSVG
                        value={responseNowPayment.pay_address}
                        className={styles.NowPaymentQrCode}
                      />
                      <p className={styles.TitleTotalNowPaymentText}>
                        {stores.translate('total')}: {packInfo.PRICE} USDT
                      </p>
                      <div className={styles.PaymentInfoAttentionContainer}>
                        <p className={styles.PaymentInfoAttention}>
                          {stores.translate('notes_purchase_now_payment')}
                        </p>
                      </div>
                    </>
                  ) : statusSellingPack === SELLING_PACKS_STATUSES.TRANSFERRING ? (
                    <>
                      <p className={styles.PaymentInfoTitle}>
                        {stores.translate('your_invoice_processed')}
                      </p>
                      <BeeSpinner
                        classNames={{ Spinner: styles.LoadingTransferringPackContainer }}
                      />
                    </>
                  ) : (
                    <>
                      <p className={styles.PaymentInfoTitle}>
                        {stores.translate('error_occurred_receiving_selling_pack')}:
                      </p>
                      <div className={styles.PaymentInfoAttentionContainer}>
                        <p className={styles.PaymentInfoAttention}>{errorTextSellingPack}</p>
                      </div>
                    </>
                  )}
                </div>
              )}
              {statusSellingPack === SELLING_PACKS_STATUSES.ERROR_PAYMENT && (
                <>
                  <p className={styles.PaymentInfoTitle}>
                    {stores.translate('chosen_selling_pack')}, «{packInfo.TITLE}»:
                  </p>
                  <div className={styles.PaymentInfoAttentionContainer}>
                    <p className={styles.PaymentInfoAttention}>Error: {errorTextSellingPack}</p>
                  </div>
                </>
              )}
            </>
          )}
        </div>
        {isShowKYC && <KYCScreen close={closeKYC} />}
      </div>
    </div>
  );
});

export default PaymentsModalMobile;
