import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import classNames from 'classnames';
import { sanitize } from 'dompurify';
import TitleSection from 'src/components/TitleSection';
import { ROADMAP_MOBILE_STEP_IS_DONE } from '@constants/system';
import TextH5 from '@components_new/TextH5';
import { Button, ColorType, SizeButton } from 'src/components_new/Button';
import { gtagEvent } from '@utils/common';
import { GOOGLE_ANALYTICS } from '@src/constants/googleAnalyticsEvents';
import URLS from '@constants/urls';
import { useMediaQuery } from 'src/helpers/media';
import Logger from '@utils/logger';

import ConeCoins from '@assets/RoadMapSection/cone_coins.png';
import ActiveConeCoins from '@assets/RoadMapSection/active_cone_coins.png';
import Contracts from '@assets/RoadMapSection/contracts.png';
import Exchange from '@assets/RoadMapSection/exchange.png';
import Flag from '@assets/RoadMapSection/flag.png';
import Gameplay from '@assets/RoadMapSection/gameplay.png';
import NewGame from '@assets/RoadMapSection/new_game.png';
import GameBlack from '@assets/Mobile/AboutPage/RoadMapSection/game_black.svg';
import DevelopmentBlack from '@assets/Mobile/AboutPage/RoadMapSection/development_black.svg';
import WebsiteBlack from '@assets/Mobile/AboutPage/RoadMapSection/website_black.svg';
import PrePrivateBlack from '@assets/Mobile/AboutPage/RoadMapSection/pre_private_black.svg';
import PrivateYellow from '@assets/Mobile/AboutPage/RoadMapSection/private_yellow.svg';
import Audit from '@assets/Mobile/AboutPage/RoadMapSection/audit.svg';
import Ambassador from '@assets/Mobile/AboutPage/RoadMapSection/ambassador.svg';
import Decentralization from '@assets/Mobile/AboutPage/RoadMapSection/decentralization.svg';
import Game from '@assets/Mobile/AboutPage/RoadMapSection/game.svg';
import GreenLine from '@assets/Mobile/AboutPage/RoadMapSection/green_line.svg';
import Igo from '@assets/Mobile/AboutPage/RoadMapSection/igo.svg';
import Marketplace from '@assets/Mobile/AboutPage/RoadMapSection/marketplace.svg';
import PrivateRound from '@assets/Mobile/AboutPage/RoadMapSection/private_round.svg';
import Voting from '@assets/Mobile/AboutPage/RoadMapSection/voting.svg';
import Done from '@assets/Mobile/AboutPage/RoadMapSection/done.svg';

import styles from './mobileRoadmap.module.scss';

const getStepIcon = (iconId) => {
  switch (ROADMAP_MOBILE_STEP_IS_DONE.find((item) => item.id === iconId).icon) {
    case 'ConeCoins':
      return ConeCoins;
    case 'ActiveConeCoins':
      return ActiveConeCoins;
    case 'Contracts':
      return Contracts;
    case 'Decentralization':
      return Decentralization;
    case 'Done':
      return Done;
    case 'Exchange':
      return Exchange;
    case 'Flag':
      return Flag;
    case 'Game':
      return Game;
    case 'Gameplay':
      return Gameplay;
    case 'Marketplace':
      return Marketplace;
    case 'NewGame':
      return NewGame;
    case 'Voting':
      return Voting;
    case 'DevelopmentBlack':
      return DevelopmentBlack;
    case 'WebsiteBlack':
      return WebsiteBlack;
    case 'PrePrivateBlack':
      return PrePrivateBlack;
    case 'GameBlack':
      return GameBlack;
    case 'PrivateYellow':
      return PrivateYellow;
    case 'Igo':
      return Igo;
    case 'Audit':
      return Audit;
    case 'Ambassador':
      return Ambassador;
    case 'PrivateRound':
      return PrivateRound;
    default:
      return Decentralization;
  }
};

const RoadMapSection = observer(() => {
  const stores = useStores();
  const _ = stores.language;
  const phases = stores.translate('phases');
  const isMoreThan360pxAndLessThan999px = useMediaQuery(
    '(min-width: 360px) and (max-width: 999px)',
  );
  return (
    <div className={styles.GreenWrapperContainer}>
      <div className={styles.Container} id="road-map">
        <TitleSection
          title={stores.translate('road-map')}
          classNames={{
            Title: styles.Title,
          }}
          isH4TitleTag
        />
        <div className={styles.PhasesBlock}>
          {phases.map((phase, index) => {
            return (
              <div className={styles.PhaseContainer} key={phase[index].description}>
                <div className={styles.PhaseTitleBlock}>
                  <TextH5 classNames={styles.PhaseTitle} text={`Phase&nbsp;#${index + 1}`} />
                  <img
                    onLoad={() => stores.increaseDownloadedImageCounter(GreenLine)}
                    onError={() => Logger.error('Error with image loading')}
                    src={GreenLine}
                    alt="green_line_image"
                    className={styles.PhaseTitleGreenLine}
                  />
                </div>
                {phase.map((phaseStep) => {
                  return (
                    <div className={styles.PhaseStep} key={phaseStep.description}>
                      <img
                        onLoad={() =>
                          stores.increaseDownloadedImageCounter(getStepIcon(phaseStep.id))
                        }
                        onError={() => Logger.error('Error with image loading')}
                        src={getStepIcon(phaseStep.id)}
                        alt="phase_icon"
                        className={styles.PhaseStepIcon}
                      />
                      <div className={styles.PhaseStepInfo}>
                        <div className={styles.PhaseStepDateWrapper}>
                          <p
                            className={classNames(
                              styles.PhaseStepDate,
                              phaseStep.date === 'May 2022' && styles.PhaseStepDate_color__red,
                            )}
                            dangerouslySetInnerHTML={{ __html: sanitize(phaseStep.date) }}
                          />
                          {phaseStep.isDone === 'true' && !isMoreThan360pxAndLessThan999px && (
                            <div className={styles.DoneIconWrapper}>
                              <img
                                onLoad={() => stores.increaseDownloadedImageCounter(Done)}
                                onError={() => Logger.error('Error with image loading')}
                                src={Done}
                                alt="phase_icon"
                                className={styles.DoneIcon}
                              />
                            </div>
                          )}
                        </div>
                        <div className={styles.PhaseStepDescriptionWrapper}>
                          <div
                            className={
                              phaseStep.isDone === 'true'
                                ? styles.PhaseStepDescriptionTextWrapper
                                : undefined
                            }
                          >
                            <p
                              className={classNames(
                                styles.PhaseStepDescription,
                                phaseStep.isDone === 'true' &&
                                  styles.PhaseStepDescription__crossedOut,
                                phaseStep.isActive === 'true' &&
                                  styles.PhaseStepDescription__isActive,
                              )}
                              dangerouslySetInnerHTML={{ __html: sanitize(phaseStep.description) }}
                            />
                          </div>
                          {phaseStep.isDone === 'true' && isMoreThan360pxAndLessThan999px && (
                            <div className={styles.DoneIconWrapper}>
                              <img
                                onLoad={() => stores.increaseDownloadedImageCounter(Done)}
                                onError={() => Logger.error('Error with image loading')}
                                src={Done}
                                alt="phase_icon"
                                className={styles.DoneIcon}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <Button
          colorType={ColorType.ORANGE}
          size={SizeButton.MEDIUM}
          title={stores.translate('white_paper')}
          classNames={{
            Container: classNames(styles.ButtonContainer),
            Title: classNames(
              styles.TitleButton,
              stores.browserInfo.isAndroid
                ? styles.TitleOrangeButton__android
                : styles.TitleOrangeButton,
            ),
          }}
          onClick={() => {
            gtagEvent(GOOGLE_ANALYTICS.CLICK_YIELD_CALCULATOR);
            window.open(URLS.WHITE_PAPER, '_blank', 'noopener,noreferrer');
          }}
        />
      </div>
    </div>
  );
});

export default RoadMapSection;
