export const ENUM_POPUP = {
  FOOTER_SUBSCRIBED: 'FOOTER_SUBSCRIBED',
  QUESTION_SENDED: 'QUESTION_SENDED',
  CHANGE_AVATAR_WARNING: 'CHANGE_AVATAR_WARNING',
  CHANGE_APPROVE_AVATAR: 'CHANGE_APPROVE_AVATAR',
  CHANGE_APPROVE_NICKNAME: 'CHANGE_APPROVE_NICKNAME',
  CHANGE_NICKNAME_WARNING: 'CHANGE_NICKNAME_WARNING',
  CHANGE_NICKNAME_NO_ACCESS_ACCOUNT: 'CHANGE_NICKNAME_NO_ACCESS_ACCOUNT',
  CONTACT_US_SUBMIT: 'CONTACT_US_SUBMIT',
  SEED_PHRASE_SAVED: 'SEED_PHRASE_SAVED',
  SELLING_PACK_TRANSFERRED: 'SELLING_PACK_TRANSFERRED',
  SELLING_PACK_HAS_BEEN_PURCHASED: 'SELLING_PACK_HAS_BEEN_PURCHASED',
  SELLING_PACK_NO_PURCHASED_INIT: 'SELLING_PACK_NO_PURCHASED_INIT',

  ERROR: 'ERROR',
  // Kepler wallet
  KEPLER_ERROR: 'KEPLER_ERROR',
  KEPLER_ERROR_INSTALL_EXTENSION: 'KEPLER_ERROR_INSTALL_EXTENSION',
  KEPLER_FAILED_CHAIN: 'KEPLER_FAILED_CHAIN',
  KEPLER_ERROR_USE_RECENT_EXTENSION: 'KEPLER_ERROR_USE_RECENT_EXTENSION',
}

export default {
  ENUM_POPUP
}
