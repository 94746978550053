import { DEFAULT_HINT, GameMethodNames, GameObjectNames } from 'src/stores/unity';
import { ALVEARY, APIARY, BEE, BEE_HOUSE, DIAMOND_STING, HONEY_BEE, HONEY_EXPLORER, HONEY_HUNTER, SKILLED_BEE, SMALL_BEE } from '@constants/blockchain';
import { StoreInstance } from 'src/stores';
import { BEARS } from '@api';
import settings from 'src/api/server/settings';
import payments from 'src/api/server/payments';
import { DEFAULT_LANGUAGE, MILLION, URL_SERVER_MAIN } from '@constants/system';
import { floorRounding, stringify, toFloatApproximation } from '@utils/common';

import { getFee } from '@utils/methodsBears';
import { coins } from '@cosmjs/stargate';
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx';
import { toHex } from '@cosmjs/encoding';
import axios from 'axios';
import { makeSignature } from 'src/api/server/common';

import { handleMainError } from '../HandleError';

const stores = StoreInstance;

const checkBearAuthorization = () => {
  stores.unityStore.send({
    objectName: GameObjectNames.LobbyReact,
    methodName: GameMethodNames.BearAuthorized,
  });
};

const signData = async ({ id, type, data }) => {
  const pubkeyDraft = stores.profileAvailable.account.pubkey;
  const pubkey = toHex(pubkeyDraft);
  const { address } = stores.profileAvailable;
  let tx = '';
  console.log('data >> ', data);
  const parseData = JSON.parse(data);
  console.log('JSON.parse(data) >> ', parseData);

  const balances = await BEARS.getBalance({ address });
  if (type === 'msgSend') {
    if (parseData  && parseData.amount) {
      balances.honey -= data.amount;
    } else {
      console.log('[Error] for signData >> ', type, parseData);
      tx = '';
    }
    console.log('signData with type=`msgSend` not realized');
    tx = '';
  } else if (type === 'msgBurnCoins') {
    if (parseData  && parseData.amount && parseData.denom) {
      try {
        const coinsValue = parseData.denom === 'cone' ? coins(parseData.amount, 'cone') : coins(parseData.amount * MILLION, 'uhoney');
        const msg = await stores.authorizedClient.bearsTxs.msgBurnCoins({ creator: address, coins: coinsValue });
        const fee = getFee({ coefficient: 1.0, balances });
        const txDraft = await stores.queue.push({ address, msg: [msg], fee, justSign: true }, 1);
        tx = txDraft.tx;
      } catch (err) {
        tx = '';
      }
    } else {
      console.log('[Error] for signData >> ', type, parseData);
      tx = '';
    }
  } else if (type === 'custom') {
    if (parseData) {
      try {
        const txDraft = await makeSignature({ nonce: parseData });
        tx = toHex(txDraft.signature);    
      } catch (err) {
        tx = '';
      }
    } else {
      console.log('[Error] for signData >> ', type, parseData);
      tx = '';
    }
  } else {
    console.log('[Error] for signData, type undefined >> ', type);
  }

  stores.unityStore.send({
    objectName: GameObjectNames.LobbyReact,
    methodName: GameMethodNames.ResponseSignData,
    values: stringify({ id, pubkey, tx }),
  });
};

const requestHints = async ({ address }) => {
  if (stores.isNativeApp) {
    const abbreviationLocale = stores.languageGame;
    stores.unityStore.send({
      objectName: GameObjectNames.LobbyReact,
      methodName: GameMethodNames.UpdateHints,
      values: stringify({ textHint: DEFAULT_HINT.MATCH3[abbreviationLocale] }),
    });
    return;
  }

  const hints = await settings.getHints({ address });
  if (hints.err) {
    stores.unityStore.send({
      objectName: GameObjectNames.LobbyReact,
      methodName: GameMethodNames.UpdateHints,
      values: stringify({ textHint: null }),
    });
    handleMainError({ code: 113, useProduction: false });
  } else {
    // const abbreviationLocale = DEFAULT_LANGUAGE;
    const abbreviationLocale = stores.languageGame;
    stores.unityStore.send({
      objectName: GameObjectNames.LobbyReact,
      methodName: GameMethodNames.UpdateHints,
      values: stringify({ textHint: hints.locale[abbreviationLocale] }),
    });
  }
}

const getApiaryParams = ({ apiaryType, params }) => {
  const beeHouse = params.apiaryTypes.find((item) => item.apiaryType === BEE_HOUSE);
  const apiary = params.apiaryTypes.find((item) => item.apiaryType === APIARY);
  const alveary = params.apiaryTypes.find((item) => item.apiaryType === ALVEARY);

  switch (apiaryType) {
    case BEE_HOUSE:
      return {
        spaceAvailable: beeHouse.spaceAvailable,
        maxHoney: toFloatApproximation({
          value: beeHouse.maxHoney,
        }),
      };
    case APIARY:
      return {
        spaceAvailable: apiary.spaceAvailable,
        maxHoney: toFloatApproximation({
          value: apiary.maxHoney,
        }),
      };
    case ALVEARY:
      return {
        spaceAvailable: alveary.spaceAvailable,
        maxHoney: toFloatApproximation({
          value: alveary.maxHoney,
        }),
      };
    default:
      return { spaceAvailable: 0.0, maxHoney: 0 };
  }
};

const getBeeParams = ({ beeType, params }) => {
  const smallBee = params.beeTypes.find((item) => item.beeType === SMALL_BEE);
  const skilledBee = params.beeTypes.find((item) => item.beeType === SKILLED_BEE);
  const diamondSting = params.beeTypes.find((item) => item.beeType === DIAMOND_STING);
  const bee = params.beeTypes.find((item) => item.beeType === BEE);
  const honeyBee = params.beeTypes.find((item) => item.beeType === HONEY_BEE);
  const honeyHunter = params.beeTypes.find((item) => item.beeType === HONEY_HUNTER);
  const honeyExplorer = params.beeTypes.find((item) => item.beeType === HONEY_EXPLORER);

  switch (beeType) {
    case BEE:
      return {
        honeySpeed: floorRounding(toFloatApproximation({ value: bee.honeyPerBlock, coef: params.blocksPerHour }), 2),
        airConsume: toFloatApproximation({ value: bee.airConsume, coef: MILLION }),
      };
    case HONEY_BEE:
      return {
        honeySpeed: floorRounding(toFloatApproximation({ value: honeyBee.honeyPerBlock, coef: params.blocksPerHour }), 2),
        airConsume: toFloatApproximation({ value: honeyBee.airConsume, coef: MILLION }),
      };
    case HONEY_HUNTER:
      return {
        honeySpeed: floorRounding(toFloatApproximation({ value: honeyHunter.honeyPerBlock, coef: params.blocksPerHour }), 2),
        airConsume: toFloatApproximation({ value: honeyHunter.airConsume, coef: MILLION }),
      };
    case HONEY_EXPLORER:
      return {
        honeySpeed: floorRounding(toFloatApproximation({ value: honeyExplorer.honeyPerBlock, coef: params.blocksPerHour }), 2),
        airConsume: toFloatApproximation({ value: honeyExplorer.airConsume, coef: MILLION }),
      };
    case DIAMOND_STING:
      return {
        honeySpeed: floorRounding(toFloatApproximation({ value: diamondSting.honeyPerBlock, coef: params.blocksPerHour }), 2),
        airConsume: toFloatApproximation({ value: diamondSting.airConsume, coef: MILLION }),
      };
    case SMALL_BEE:
      return {
        honeySpeed: floorRounding(toFloatApproximation({ value: smallBee.honeyPerBlock, coef: params.blocksPerHour }), 2),
        airConsume: toFloatApproximation({ value: smallBee.airConsume, coef: MILLION }),
      };
    case SKILLED_BEE:
      return {
        honeySpeed: floorRounding(toFloatApproximation({ value: skilledBee.honeyPerBlock, coef: params.blocksPerHour }), 2),
        airConsume: toFloatApproximation({ value: skilledBee.airConsume, coef: MILLION }),
      };
    default:
      return { honeySpeed: 0.0, airConsume: 0 };
  }
};

const getInvoicePayment = async ({ pack }) => {
  //! delete after updated server payment for WELCOME packs
  const packDraft = pack.split('WELCOME_')[1];
  const paymentResponse = await payments.paymentBinance({ pack: packDraft });
  console.log('paymentResponse >> ', paymentResponse);
  if (paymentResponse.err || !paymentResponse.payment_links || !paymentResponse.payment_links.checkoutUrl) {
    handleMainError({ code: 115, useProduction: false });
  } else {
    const qrCode = paymentResponse.payment_links.qrcodeLink;
    const link = stores.browserInfo.isMobile ? paymentResponse.payment_links.deeplink : paymentResponse.payment_links.checkoutUrl;
    console.log('links >>> ', { qrCode, link });

    stores.unityStore.send({
      objectName: GameObjectNames.LobbyReact,
      methodName: GameMethodNames.SetInvoicePayment,
      values: stringify({ qrCode, link }),
    });
  }
};

const getStarterOffers = async () => {
  const responseAll = await Promise.all([
    payments.getSellingPacks({}),
    BEARS.getParams(),
  ]);
  // const sellingPacks = await payments.getSellingPacks({});
  // const params = await BEARS.getParams();
  const sellingPacks = responseAll[0];
  const params = responseAll[1];
  console.log('sellingPacks >> ', sellingPacks);
  if (sellingPacks.err || !params || !params.apiaryTypes || !params.beeTypes) {
    handleMainError({ code: 114, useProduction: false });
  } else {
    const packArrayFirstPurchased = [];
    Object.values(sellingPacks).forEach((pack) => {
      if (pack.INIT_SET && pack.ID.includes('WELCOME')) {
        packArrayFirstPurchased.push(pack);
      }
    });
    console.log('packArrayFirstPurchased >> ', packArrayFirstPurchased);

    const StarterPackData = packArrayFirstPurchased.map((pack) => {
      let oldPrice = 0;
      const packItems = pack.ITEMS.map((item) => {
        const type = item.KIND === BEE ? item.PARAMS.beeType : item.KIND === APIARY ? item.PARAMS.apiaryType : 'Non';
        const paramsBee = item.KIND === BEE ? getBeeParams({ beeType: item.PARAMS.beeType, params }) : null;
        const paramsApiary = item.KIND === APIARY ? getApiaryParams({ apiaryType: item.PARAMS.apiaryType, params }) : null;
        oldPrice += item.PRICE;
        return { kind: item.KIND, type, title: item.TITLE, price: item.PRICE, paramsBee, paramsApiary };
      });
      return { id: pack.ID, newPrice: pack.PRICE, oldPrice, title: pack.TITLE, description: pack.DESCRIPTION, packItems };
    });
    console.log('StarterPackData >> ', StarterPackData);
    stores.unityStore.send({
      objectName: GameObjectNames.LobbyReact,
      methodName: GameMethodNames.SetStarterOffers,
      values: stringify({ StarterPackData }),
    });
  }
};

export default {
  checkBearAuthorization,
  signData,
  requestHints,
  getStarterOffers,
  getInvoicePayment,
};
