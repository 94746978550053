import React from 'react';
import styles from './changelog.module.scss';

const ChangelogPage = () => {
  return (
    <div className={styles.Container}>
      <p className={styles.Title}>Changelog</p>
      <zero-md src="/CHANGELOG.md" />
    </div>
  )
};

export default ChangelogPage;
