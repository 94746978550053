import { StoreInstance } from 'src/stores';
import { ENUM_POPUP } from './enumPopup';

const stores = StoreInstance;

const approveChangeAvatar = async ({ honey }) => {
  stores.setExecutePopup({
    name: ENUM_POPUP.CHANGE_APPROVE_AVATAR,
    state: { honey },
  });
};

const approveChangeNickname = async () => {
  stores.setExecutePopup({
    name: ENUM_POPUP.CHANGE_APPROVE_NICKNAME,
  });
};

const confirmBurnCones = async ({ cones }) => {
  stores.setExecutePopup({
    name: ENUM_POPUP.CONFIRM_BURN,
    state: { cones },
  });
};

export default {
  approveChangeAvatar,
  approveChangeNickname,
  confirmBurnCones,
};
