import React from 'react';
import Layout from '@layout/Layout';
import CapitalSection from './components/CapitalSection';
import FaqSection from './components/FaqSection';
import QuestionSection from './components/QuestionSection';

const MobileTokenomicsPage = () => {
  return (
    <Layout isMobile isLoadingFinish>
      <CapitalSection />
      <FaqSection />
      <QuestionSection />
    </Layout>
  );
};

export default MobileTokenomicsPage;
