import React, { useEffect } from 'react';
import HeadTags from '@components_new/HeadTags';
import { useMediaQuery } from '@src/helpers/media';
import MobileHomePage from '@src/pages/HomePage/MobileHomePage';
import DesktopHomePage from '@src/pages/HomePage/DesktopHomePage';
import BeeSpinner from '@components/BeeSpinner';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import styles from './mobileHome.module.scss';

const HomePage = observer(() => {
  const stores = useStores();
  const isMobileSize = useMediaQuery('(max-width: 999.99px)');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeadTags description="Play just 5 minute a day and earn! HoneyWood is a blockchain game where you can fight in match3 with other players, create your own farm and get a profit!" />
      {stores.browserInfo.isMobile || (isMobileSize && !stores.browserInfo.isMobile) ? (
        <MobileHomePage />
      ) : (
        <>
          {stores.isInitialHomePageLoading && (
            <BeeSpinner classNames={{ Container: styles.Container }} />
          )}
          <DesktopHomePage isInitialLoading={stores.isInitialHomePageLoading} />
        </>
      )}
    </>
  );
});

export default HomePage;
