import { SignDoc, TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx';
import { calculateFee, coins, makeMultisignedTx, SigningStargateClient } from '@cosmjs/stargate';
import { fromBase64, toBase64, toHex } from '@cosmjs/encoding';
import { ExtendedSecp256k1Signature, Secp256k1, Secp256k1Signature, sha256 } from "@cosmjs/crypto";
import axios from 'axios';
// import { isOfflineDirectSigner, OfflineSigner } from '@cosmjs/proto-signing';
import Logger from '@utils/logger';

import { StoreInstance } from 'src/stores';
import { DENOM_UHONEY, GAS_WANTED, URL_MATCH3_SEND } from '@constants/blockchain';
import { stringify } from '@utils/common';
import { getFee } from '@utils/methodsBears';
import _ from 'lodash';
import { MILLION, URL_SERVER_MAIN } from '@constants/system';
import { GasPrice, serializeSignDoc } from '@cosmjs/launchpad';
import { Decimal } from '@cosmjs/math';
import { makeSignature } from './common';
import BEARS, { getHoney } from '../BEARS';

let countRequest = 0;
const MAX_COUNT_REQUEST = 15;

/* eslint-disable */

export const signLobby = async ({ nonce }) => {
  if (!StoreInstance.isAuthorizedClientConfigured) {
    throw Error('no exist client of blockchain');
  }
  try {
    const tx = await makeSignature({ nonce });
    const sendingMessage = {
      pubkey: toHex(StoreInstance.profileAvailable.account.pubkey),
      tx: toHex(tx.signature)
    };

    return sendingMessage;
  } catch (err) {
    Logger.error(err);
    return { err, code: 109 }
  }
};

export const signTransaction = async ({ amount, toAddress }) => {
  if (!StoreInstance.isAuthorizedClientConfigured) {
    throw Error('no exist client of blockchain');
  }
  const fromAddress = StoreInstance.profileAvailable.address;
  let balances = await BEARS.getBalance({ address: fromAddress });
  balances.honey -= amount;
  const fee = getFee({ coefficient: 1.0, balances });

  const msg = {
    typeUrl: URL_MATCH3_SEND,
    value: {
      fromAddress,
      toAddress,
      amount: coins(amount * MILLION, DENOM_UHONEY),
    },
  };
  try {
    const sendingMessage = await BEARS.customSignOnly({ address: fromAddress, msg: [msg], fee, cosmos: true });
    return sendingMessage;
  } catch (err) {
    Logger.error(err);
    return { err, code: 109 }
  }
};

const getTournamentCurrent = async ({ address }) => {
  try {
    console.log('getTournamentCurrent >> start');
    const result = await axios.get(`${URL_SERVER_MAIN}/api/match3/tournament/current?address=${address}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.log('getTournamentCurrent >> result= ', result);
    if (result.status === 400) {
      countRequest++;
      if (countRequest < MAX_COUNT_REQUEST) {
        return await getTournamentCurrent({ address });
      } else {
        countRequest = 0;
        const objError = await result.text();
        const textError = objError.split(',')[1].split(':')[1];
        return { err: true, msg: textError }
      }
    }
    if (result.status === 200) {
      Logger.infoMainWithKey('getTournamentCurrent >> ', JSON.stringify(result.data));
      return result.data;
    }
    return { err: true, msg: 'getTournamentCurrent error' }
  } catch (err) {
    Logger.error(err);
    if (err.response && err.response.data && err.response.data.message) {
      return { err: true, msg: err.response.data.message }
    }
    return { err: true, msg: String(err) }
  }
}

const getTournamentNext = async ({ address }) => {
  try {
    // console.log('getTournamentNext >> start');
    const result = await axios.get(`${URL_SERVER_MAIN}/api/match3/tournament/next?address=${address}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    // console.log('getTournamentNext >> result= ', result);
    if (result.status === 400) {
      countRequest++;
      if (countRequest < MAX_COUNT_REQUEST) {
        return await getTournamentNext({ address });
      } else {
        countRequest = 0;
        const objError = await result.text();
        const textError = objError.split(',')[1].split(':')[1];
        return { err: true, msg: textError }
      }
    }
    if (result.status === 200) {
      Logger.infoMainWithKey('getTournamentNext >> ', JSON.stringify(result.data));
      return result.data;
    }
    return { err: true, msg: 'getTournamentNext error' }
  } catch (err) {
    Logger.error(err);
    if (err.response && err.response.data && err.response.data.message) {
      return { err: true, msg: err.response.data.message }
    }
    return { err: true, msg: String(err) }
  }
}

const getTournamentPrev = async ({ address }) => {
  try {
    // console.log('getTournamentPrev >> start');
    const result = await axios.get(`${URL_SERVER_MAIN}/api/match3/tournament/prev?address=${address}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    // console.log('getTournamentPrev >> result= ', result);
    if (result.status === 400) {
      countRequest++;
      if (countRequest < MAX_COUNT_REQUEST) {
        return await getTournamentPrev({ address });
      } else {
        countRequest = 0;
        const objError = await result.text();
        const textError = objError.split(',')[1].split(':')[1];
        return { err: true, msg: textError }
      }
    }
    if (result.status === 200) {
      Logger.infoMainWithKey('getTournamentPrev >> ', JSON.stringify(result.data));
      return result.data;
    }
    return { err: true, msg: 'getTournamentPrev error' }
  } catch (err) {
    Logger.error(err);
    if (err.response && err.response.data && err.response.data.message) {
      return { err: true, msg: err.response.data.message }
    }
    return { err: true, msg: String(err) }
  }
}

const getEmojiList = async () => {
  try {
    const result = await axios.get(`${URL_SERVER_MAIN}/api/match3/reactions`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (result.status === 400) {
      countRequest++;
      if (countRequest < MAX_COUNT_REQUEST) {
        return await getEmojiList({ address });
      } else {
        countRequest = 0;
        const objError = await result.text();
        const textError = objError.split(',')[1].split(':')[1];
        return { err: true, msg: textError }
      }
    }
    if (result.status === 200) {
      Logger.infoMainWithKey('getEmojiList >> ', JSON.stringify(result.data));
      return result.data;
    }
    return { err: true, msg: 'getEmojiList error' }
  } catch (err) {
    Logger.error(err);
    if (err.response && err.response.data && err.response.data.message) {
      return { err: true, msg: err.response.data.message }
    }
    return { err: true, msg: String(err) }
  }
}

export default {
  signLobby,
  signTransaction,
  getTournamentCurrent,
  getTournamentNext,
  getTournamentPrev,
  getEmojiList
};
