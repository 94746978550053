import React, { useEffect } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { sanitize } from 'dompurify';
import Layout from '@layout/Layout';
import { StoreInstance } from 'src/stores';
import { getShortAddress, gtagEvent, toFixedNumberWithoutRound } from '@utils/common';
import { useMediaQuery } from 'src/helpers/media';
import { CONE, TYPE_AUTH_CLIENT, USDT } from '@constants/blockchain';
import { GOOGLE_ANALYTICS } from 'src/constants/googleAnalyticsEvents';
import AvatarIcon from '@components/AvatarIcon';
import InputWord from '@components/InputWord';
import LogoutIcon from '@assets/Mobile/control/logout_icon.png';
import AccountEditMobile from '@assets/Mobile/control/account_edit_mobile.png';
import ConeIconMobile from '@assets/Mobile/AccountPage/cone_icon_mobile.png';
import HoneyIconMobile from '@assets/Mobile/AccountPage/honey_icon_mobile.png';
import GradientBottom from '@assets/Mobile/AccountPage/gradient_bottom.svg';
import WithdrawIconMobile from '@assets/Mobile/control/withdraw_icon.png';
import CopyIconMobile from '@assets/Mobile/control/copy_icon_mobile.png';
import { Button, ColorType, SizeButton } from 'src/components_new/Button';
import InformationPopup from '@components/InformationPopup';
import Logger from '@utils/logger';

import SpecialPresaleSection from '../../HomePage/MobileHomePage/components/SpecialPresaleSection';
import styles from './mobileAccount.module.scss';

const MobileAccountPage = ({
  stores = StoreInstance,
  states,
  methods,
  loading,
  isInitialLoading = true,
}) => {
  const isLessThan500px = useMediaQuery('(max-width: 500px)');
  const isLessThan645px = useMediaQuery('(max-width: 645px)');
  const isLessThan1000px = useMediaQuery('(max-width: 1000px)');
  const isKepler = stores.typeAuthClient === TYPE_AUTH_CLIENT.KEPLR;

  const sellingPacksClick = () => {
    gtagEvent(GOOGLE_ANALYTICS.CLICK_BUY);
    const { isMobile } = stores.browserInfo;
    const targetId = document.getElementById('selling-pack');
    const yOffset = isMobile ? 0 : -111;
    const y = targetId?.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  return (
    <Layout
      isMobile={isLessThan1000px}
      isAccount
      isInitialLoading={isInitialLoading}
      withAvatar={false}
      isLoadingFinish={
        !loading.isLoadingNickName && !loading.isLoading && !loading.isLoadingTransactions
      }
    >
      <div className={styles.AccountPage}>
        <div className={styles.WalletContainer}>
          <div className={styles.Profile}>
            <div
              className={classnames(
                styles.ProfileInfoWrapper,
                !isKepler && styles.ProfileInfoWrapper__noKepler,
              )}
            >
              <div className={styles.ProfileInfoContainer}>
                <div
                  role="button"
                  className={styles.ProfileAvatarContainer}
                  onClick={() => {
                    methods.showChangeAvatar();
                  }}
                  onKeyPress={() => {
                    methods.showChangeAvatar();
                  }}
                  tabIndex={0}
                >
                  <AvatarIcon />
                </div>
                {loading.isLoadingNickName ? (
                  <div className={styles.ProfileInfoIndicatorContainer}>
                    <div className={styles.ProfileInfoProgressIndicator} />
                  </div>
                ) : (
                  <div className={styles.ProfileInfo}>
                    <p className={styles.ProfileInfoTitle}>{getShortAddress(states.userName)}</p>
                  </div>
                )}
                <div
                  role="button"
                  className={styles.ProfileAccountEditContainer}
                  onClick={() => {
                    methods.showChangeNickname();
                  }}
                  onKeyPress={() => {
                    methods.showChangeNickname();
                  }}
                  tabIndex={0}
                >
                  <img
                    onLoad={() => stores.increaseDownloadedImageCounter(AccountEditMobile)}
                    onError={() => Logger.error('Error with image loading')}
                    src={AccountEditMobile}
                    alt="AccountEdit"
                    className={styles.ProfileAccountEdit}
                  />
                </div>
              </div>
              {!isLessThan500px && (
                <div
                  className={classnames(
                    styles.ButtonsProfileContainer,
                    !isKepler && styles.ButtonsProfileContainer__noKepler,
                  )}
                >
                  <Button
                    title={stores.translate('play')}
                    colorType={ColorType.GREEN}
                    size={SizeButton.SMALL}
                    onClick={() => {
                      gtagEvent(GOOGLE_ANALYTICS.CLICK_PLAY);
                      methods.gameClick();
                    }}
                    classNames={{
                      Container: styles.PlayButtonContainer,
                      Button: styles.PlayButton,
                      Title: styles.PLayButtonTitle,
                    }}
                  />
                  {!isKepler && (
                    <Button
                      title={stores.translate('private_username')}
                      colorType={ColorType.ORANGE}
                      size={SizeButton.SMALL}
                      onClick={() => {
                        gtagEvent(GOOGLE_ANALYTICS.CLICK_PRIVATE_USERNAME);
                        methods.privateUsernameClick();
                      }}
                      classNames={{
                        Container: styles.PrivateUsernameButtonContainer,
                        Button: styles.PrivateUsernameButton,
                        Title: styles.PrivateUsernameButtonTitle,
                      }}
                    />
                  )}
                </div>
              )}
            </div>

            {!isLessThan1000px && (
              <div
                className={styles.LogoutButtonContainer}
                onClick={() => {
                  gtagEvent(GOOGLE_ANALYTICS.CLICK_LOGOUT);
                  methods.onLogoutClick();
                }}
                role="button"
                tabIndex={0}
                onKeyDown={() => {
                  gtagEvent(GOOGLE_ANALYTICS.CLICK_LOGOUT);
                  methods.onLogoutClick();
                }}
              >
                <p className={styles.LogoutText}>Logout</p>
                <img
                  onLoad={() => stores.increaseDownloadedImageCounter(LogoutIcon)}
                  onError={() => Logger.error('Error with image loading')}
                  src={LogoutIcon}
                  alt="logout_icon"
                  className={styles.LogoutIconImage}
                />
              </div>
            )}
          </div>

          <div className={styles.OverviewContainerWrapper}>
            <div className={styles.OverviewInfoContainer}>
              <div className={styles.OverviewContainer}>
                <p className={styles.OverviewTitle}>{stores.translate('wallet_address')}</p>
                <div
                  className={styles.OverviewWalletAddress}
                  role="button"
                  onClick={() => {
                    methods.onCopyAddress();
                    gtagEvent(GOOGLE_ANALYTICS.CLICK_COPY);
                  }}
                  onKeyPress={() => {
                    methods.onCopyAddress();
                    gtagEvent(GOOGLE_ANALYTICS.CLICK_COPY);
                  }}
                  tabIndex={0}
                >
                  <p className={styles.OverviewWalletAddressText}>
                    {isLessThan500px
                      ? getShortAddress(states.walletAddress, null, true)
                      : states.walletAddress}
                  </p>
                  <div className={styles.OverviewWalletAddress_ButtonContainer}>
                    <img
                      onLoad={() => stores.increaseDownloadedImageCounter(CopyIconMobile)}
                      onError={() => Logger.error('Error with image loading')}
                      src={CopyIconMobile}
                      alt="copy_icon_mobile"
                      className={styles.CopyIconMobile}
                    />
                  </div>
                  {states.copied && (
                    <InformationPopup
                      info={stores.translate('copied')}
                      classNames={{ Container: styles.OverviewWalletAddress_InfoCopyContainer }}
                    />
                  )}
                </div>
              </div>

              <div className={styles.NFTPolygonContainer}>
                <p className={styles.NFTPolygonTitle}>{stores.translate('polygon_network')}</p>
                <div className={styles.NFTPolygonAddress}>
                  <div className={styles.NFTPolygonAddressTitleContainer}>
                    <p className={styles.NFTPolygonText}>{stores.translate('address')}:</p>
                    <div
                      className={styles.NFTPolygonAddressTextWrapper}
                      role="button"
                      onClick={() => {
                        if (states.polygonAddress) {
                          gtagEvent(GOOGLE_ANALYTICS.CLICK_COPY);
                          methods.onCopyPolygonAddress();
                        }
                      }}
                      onKeyPress={() => {
                        if (states.polygonAddress) {
                          gtagEvent(GOOGLE_ANALYTICS.CLICK_COPY);
                          methods.onCopyPolygonAddress();
                        }
                      }}
                      tabIndex={0}
                    >
                      <p className={styles.NFTPolygonAddressText}>
                        {isLessThan500px
                          ? getShortAddress(states.polygonAddress, null, true)
                          : states.polygonAddress}
                      </p>

                      {states.polygonAddress !== 'null' && (
                        <div className={styles.NFTPolygonAddress_ButtonContainer}>
                          <img
                            onLoad={() => stores.increaseDownloadedImageCounter(CopyIconMobile)}
                            onError={() => Logger.error('Error with image loading')}
                            src={CopyIconMobile}
                            alt="copy_icon_mobile"
                            className={styles.CopyIconMobile}
                          />
                          {states.copiedPolygonAddress && (
                            <InformationPopup
                              info={stores.translate('copied')}
                              classNames={{
                                Container: styles.OverviewWalletAddress_ReferralContainer,
                              }}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.DescriptionNFTPolygonContainer}>
                  <p
                    className={styles.NFTPolygonDescription}
                    dangerouslySetInnerHTML={{
                      __html: sanitize(stores.translate('description_nft_polygon_mobile'), {
                        ADD_ATTR: 'target',
                      }),
                    }}
                  />
                </div>
              </div>

              <div className={styles.Assets}>
                <div className={styles.AssetsContainer}>
                  <p className={styles.AssetsTitle}>{stores.translate('my_assets')}</p>

                  <div className={styles.AssetsCoinContainer}>
                    <div className={styles.AssetsCoinTitleWrapper}>
                      <p className={styles.AssetsCoinTitle}>{stores.translate('honey_coin')}</p>
                      <img
                        onLoad={() => stores.increaseDownloadedImageCounter(HoneyIconMobile)}
                        onError={() => Logger.error('Error with image loading')}
                        src={HoneyIconMobile}
                        alt="honey_icon_mobile"
                        className={styles.AssetsHoneyIcon}
                      />
                    </div>
                    {loading.isLoading ? (
                      <div className={styles.AssetsProgressIndicator} />
                    ) : (
                      <div className={styles.AssetsCoinWrapper}>
                        <p
                          className={classnames(
                            styles.AssetsCoinQuantity,
                            styles.AssetsCoinQuantity__MarginBottom_5px,
                          )}
                          id="AssetsHoneyQuantity"
                        >
                          {states.honeyCoins}
                        </p>
                        <p className={styles.HoneyPerBlockText}>
                          {states.honeyCoinsPerBlock && states.honeyCoinsPerBlock !== '﹡'
                            ? `+${states.honeyCoinsPerBlock} ${stores.translate('per_block')}`
                            : `${states.honeyCoinsPerBlock} ${stores.translate('per_block')}`}
                        </p>
                      </div>
                    )}
                  </div>

                  <div className={styles.AssetsCoinContainer}>
                    <div className={styles.AssetsCoinTitleWrapper}>
                      <p className={styles.AssetsCoinTitle}>{stores.translate('cone')}</p>
                      <img
                        onLoad={() => stores.increaseDownloadedImageCounter(ConeIconMobile)}
                        onError={() => Logger.error('Error with image loading')}
                        src={ConeIconMobile}
                        alt="cone_icon_mobile"
                        className={styles.AssetsHoneyIcon}
                      />
                    </div>
                    {loading.isLoading ? (
                      <div className={styles.AssetsProgressIndicator} />
                    ) : (
                      <p className={styles.AssetsCoinQuantity} id="AssetsConeQuantity">
                        {states.cones}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              {loading.isLoadingTransactions ? (
                <div className={styles.ProfileInfoIndicatorWrapper}>
                  <div
                    className={classnames(
                      styles.ProfileInfoIndicatorContainer,
                      styles.ProfileInfoIndicatorContainer_paidPacks,
                    )}
                  >
                    <div className={styles.ProfileInfoProgressIndicator} />
                  </div>
                </div>
              ) : (
                states.paidPacks &&
                states.paidPacks.length > 0 && (
                  <div className={styles.Transactions}>
                    <div className={styles.TransactionsTitleWrapper}>
                      <p className={styles.TransactionsTitleMain}>
                        {stores.translate('recent_transactions')}
                      </p>
                      <div
                        role="button"
                        onClick={() => {
                          methods.onSeeAllTransactions();
                        }}
                        onKeyPress={() => {
                          methods.onSeeAllTransactions();
                        }}
                        tabIndex={0}
                        className={styles.SeeAllButton}
                      >
                        <p className={styles.SeeAllButtonText}>{stores.translate('see_all')}</p>
                      </div>
                    </div>
                    {states.paidPacks.map((paidPack, index) => {
                      if (index <= 2) {
                        return (
                          <div
                            className={styles.TransactionsItemContainer}
                            key={`transaction_item_${paidPack.updatedAt}`}
                          >
                            <div className={styles.TransactionsLeftBlock}>
                              <img
                                onLoad={() =>
                                  stores.increaseDownloadedImageCounter(WithdrawIconMobile)
                                }
                                onError={() => Logger.error('Error with image loading')}
                                src={WithdrawIconMobile}
                                alt="withdraw_icon"
                                className={styles.WithdrawIcon}
                              />
                              <div className={styles.TransactionsItemColumnContainer}>
                                <p className={styles.TransactionsTitle}>
                                  {stores.translate('buy')}: {paidPack.selling_pack.TITLE}
                                </p>
                                <p className={styles.TransactionsPackStateTitle}>
                                  {stores.translate('status')}:{' '}
                                  {paidPack.selling_pack_status === 'TRANSFERED'
                                    ? 'TRANSFERRED'
                                    : paidPack.selling_pack_status}
                                </p>
                                {paidPack.selling_pack_status === 'ERROR' && (
                                  <p className={styles.TransactionsPackErrorTitle}>
                                    {paidPack.selling_pack_error}
                                  </p>
                                )}
                                <p className={styles.TransactionsDateText}>{paidPack.updatedAt}</p>
                              </div>
                            </div>
                            <div
                              className={classnames(
                                styles.TransactionsItemColumnContainer,
                                styles.TransactionsItemRightAlign,
                              )}
                            >
                              <p className={styles.TransactionsTitle}>
                                -&nbsp;{paidPack.selling_pack.PRICE}&nbsp;
                                {USDT}
                              </p>
                              <p className={styles.TransactionsStatusText}>
                                {stores.translate('paid')}
                              </p>
                            </div>
                          </div>
                        );
                      }
                      return false;
                    })}
                  </div>
                )
              )}
            </div>
            <div className={styles.SecondContainer}>
              {/* <p className={styles.TransferTitle}>{stores.translate('send_cone')}</p>
              <div className={styles.SendContainer}>
                <p className={styles.LabelInput}>{stores.translate('recipient')}:</p>
                <InputWord
                  placeholder={stores.translate('enter_address')}
                  name="recipient"
                  register={states.ModalForm.register}
                  autoComplete="no"
                  classNames={{
                    Label: styles.LabelInput,
                    InputContainer: classnames(
                      styles.Input_InputContainer,
                      styles.Input_InputContainer__Recipient,
                    ),
                    Container: styles.Input_Container,
                    Input: classnames(styles.Input_Input, styles.Input_Input__Recipient),
                  }}
                  maxLength={50}
                />
                {!_.isEmpty(states.ModalForm.formState.errors?.recipient?.message) && (
                  <p className={styles.InputError}>
                    {states.ModalForm.formState.errors?.recipient?.message}
                  </p>
                )}
                <div className={styles.TokenChooseSendContainer}>
                  <p className={styles.TokenText}>{stores.translate('token')}: CONE</p>
                  <img
                    onLoad={() => stores.increaseDownloadedImageCounter(ConeIconMobile)}
                    onError={() => Logger.error('Error with image loading')}
                    src={ConeIconMobile}
                    alt="honey_icon_mobile"
                    className={styles.AssetsHoneyIcon}
                  />
                </div>
                <p className={styles.LabelInput}>{stores.translate('amount')}:</p>
                <InputWord
                  placeholder={stores.translate('amount_cone')}
                  name="amount"
                  register={states.ModalForm.register}
                  autoComplete="no"
                  classNames={{
                    Label: styles.LabelInput,
                    InputContainer: styles.Input_InputContainer,
                    Container: styles.Input_Container,
                    Input: styles.Input_Input,
                  }}
                  maxLength={20}
                />
                {!_.isEmpty(states.ModalForm.formState.errors?.amount?.message) && (
                  <p className={styles.InputError}>{states.ModalForm.formState.errors?.amount?.message}</p>
                )}
                <div className={styles.TokenChooseSendContainer}>
                  <p className={styles.TokenText}>{`Fee: ${states.feeCost.amount} ${states.feeCost.tokenName}`}</p>
                  <img
                    onLoad={() => stores.increaseDownloadedImageCounter(states.feeCost.tokenName === CONE ? ConeIconMobile : HoneyIconMobile)}
                    onError={() => Logger.error('Error with image loading')}
                    src={states.feeCost.tokenName === CONE ? ConeIconMobile : HoneyIconMobile}
                    alt="honey_icon_mobile"
                    className={styles.AssetsHoneyIcon}
                  />
                </div>
                <Button
                  title={stores.translate('send')}
                  colorType={ColorType.GREEN}
                  size={SizeButton.SMALL}
                  onClick={() => {
                    // gtagEvent(GOOGLE_ANALYTICS.CLICK_PLAY);
                    states.ModalForm.handleSubmit(methods.sendTokenClick)();
                  }}
                  classNames={{
                    Container: styles.SendButtonContainer,
                    Button: styles.SendButton,
                    Title: styles.PLayButtonTitle,
                  }}
                  disabled={states.isSendTokenButton}
                />
              </div> */}

              <div className={styles.ReferralContainer}>
                <p className={styles.ReferralTitle}>{stores.translate('referral_program')}</p>
                <div className={styles.ReferralCode}>
                  <div className={styles.ReferralCodeTitleContainer}>
                    <p className={styles.ReferralText}>
                      {stores.translate('personal_referral_code')}:
                    </p>
                    <div className={styles.ReferralCodeTextWrapper}>
                      <p className={styles.ReferralCodeText}>
                        {states.referralCode !== 'null' ? states.referralCode : '*********'}
                      </p>

                      {states.referralCode !== 'null' && (
                        <div
                          className={styles.ReferralCode_ButtonContainer}
                          role="button"
                          onClick={() => {
                            gtagEvent(GOOGLE_ANALYTICS.CLICK_COPY);
                            methods.onCopyReferralCode();
                          }}
                          onKeyPress={() => {
                            gtagEvent(GOOGLE_ANALYTICS.CLICK_COPY);
                            methods.onCopyReferralCode();
                          }}
                          tabIndex={0}
                        >
                          <img
                            onLoad={() => stores.increaseDownloadedImageCounter(CopyIconMobile)}
                            onError={() => Logger.error('Error with image loading')}
                            src={CopyIconMobile}
                            alt="copy_icon_mobile"
                            className={styles.CopyIconMobile}
                          />
                          {states.copiedReferralCode && (
                            <InformationPopup
                              info={stores.translate('copied')}
                              classNames={{
                                Container: styles.OverviewWalletAddress_ReferralContainer,
                              }}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {states.referralCode === 'null' && (
                  <div className={styles.ReferralNeedToBuyPackContainer}>
                    <p
                      className={styles.ReferralNeedToBuyPackText}
                      dangerouslySetInnerHTML={{
                        __html: sanitize(stores.translate('buy_any_game_pack')),
                      }}
                    />
                    <Button
                      title={stores.translate('buy')}
                      colorType={ColorType.ORANGE}
                      size={SizeButton.SMALL}
                      onClick={() => {
                        sellingPacksClick();
                      }}
                      classNames={{
                        Container: styles.BuyButtonContainer,
                        Button: styles.BuyButton,
                        Title: styles.BuyButtonTitle,
                      }}
                    />
                  </div>
                )}

                {states.referralCode !== 'null' && (
                  <div className={styles.ReferralCodeTotalTitleContainer}>
                    <p className={styles.ReferralText}>
                      {stores.translate('total_user_used_referral_code')}:
                    </p>
                    <p className={styles.ReferralCodeText}>{states.totalUsedReferralCode}</p>
                  </div>
                )}
                <div className={styles.ReceivedHoneyContainer}>
                  <p
                    className={classnames(
                      styles.ReferralText,
                      styles.ReferralText__MarginRight_10px,
                    )}
                    dangerouslySetInnerHTML={{
                      __html: `${sanitize(stores.translate('received_from_referral'))}:`,
                    }}
                  />
                  <div className={styles.AssetsCoinPolygonBlock}>
                    <div className={styles.AssetsHoneyQuantityBlock}>
                      <img
                        onLoad={() => stores.increaseDownloadedImageCounter(HoneyIconMobile)}
                        onError={() => Logger.error('Error with image loading')}
                        src={HoneyIconMobile}
                        alt="honey_icon_mobile"
                        className={styles.AssetsHoneyIcon}
                      />
                      {loading.isLoading ? (
                        <div className={styles.AssetsProgressIndicator} />
                      ) : (
                        <p className={styles.AssetsCoinQuantity} id="AssetsReceivedHoneyQuantity">
                          {states.receivedReferralHoney}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.DescriptionReceivedHoneyContainer}>
                  <p className={styles.ReferralDescription}>
                    {stores.translate('description_received_referral_honey')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <SpecialPresaleSection classNames={{ Container: styles.SpecialPresaleSectionContainer }} /> */}

        {isLessThan500px && (
          <>
            <img
              onLoad={() => stores.increaseDownloadedImageCounter(GradientBottom)}
              onError={() => Logger.error('Error with image loading')}
              src={GradientBottom}
              alt="gradient_bottom"
              className={styles.GradientBottomPlayButton}
            />

            <div className={styles.PlayButtonBlock}>
              <Button
                title={stores.translate('play')}
                colorType={ColorType.GREEN}
                size={SizeButton.SMALL}
                onClick={() => {
                  gtagEvent(GOOGLE_ANALYTICS.CLICK_PLAY);
                  methods.gameClick();
                }}
                classNames={{
                  Container: styles.PlayButtonContainer,
                  Button: styles.PlayButton,
                  Title: styles.PLayButtonTitle,
                }}
              />
              {!isKepler && (
                <Button
                  title={stores.translate('private_username')}
                  colorType={ColorType.ORANGE}
                  size={SizeButton.SMALL}
                  onClick={() => {
                    gtagEvent(GOOGLE_ANALYTICS.CLICK_PRIVATE_USERNAME);
                    methods.privateUsernameClick();
                  }}
                  classNames={{
                    Container: styles.PrivateUsernameButtonContainer,
                    Button: styles.PrivateUsernameButton,
                    Title: styles.PrivateUsernameButtonTitle,
                  }}
                />
              )}
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default MobileAccountPage;
