import React from 'react';
import Layout from '@layout/Layout';
import CapitalSection from './components/CapitalSection';
import PartnersSection from '../../HomePage/MobileHomePage/components/PartnersSection';
import ArticlesSection from './components/ArticlesSection';

const MobilePartnershipPage = () => {
  return (
    <Layout isMobile isLoadingFinish>
      <CapitalSection />
      <PartnersSection />
      <ArticlesSection />
    </Layout>
  );
};

export default MobilePartnershipPage;
