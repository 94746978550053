import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { useStores } from 'src/stores';
import settings from 'src/api/server/settings';
import { gtagEvent } from '@utils/common';
import { GOOGLE_ANALYTICS } from '@src/constants/googleAnalyticsEvents';
import { ENUM_POPUP } from '@components_new/PopUp/enumPopup';
import Bear1Icon from '@assets/AccountPage/Avatars/bear_1.png';
import Bear2Icon from '@assets/AccountPage/Avatars/bear_2.png';
import Bear3Icon from '@assets/AccountPage/Avatars/bear_3.png';
import Bear4Icon from '@assets/AccountPage/Avatars/bear_4.png';
import Bear5Icon from '@assets/AccountPage/Avatars/bear_5.png';
import Bear6Icon from '@assets/AccountPage/Avatars/bear_6.png';
import Bear7Icon from '@assets/AccountPage/Avatars/bear_7.png';
import Bear8Icon from '@assets/AccountPage/Avatars/bear_8.png';
import Bear9Icon from '@assets/AccountPage/Avatars/bear_9.png';
import Bear10Icon from '@assets/AccountPage/Avatars/bear_10.png';
import HoneyIcon from '@assets/AccountPage/honey_icon.png';
import { Button, ColorType, SizeButton } from '@components_new/Button';
import { ENUM_MODAL } from '@components_new/Modal/enumModal';
import Logger from '@utils/logger';
import styles from './modal.module.scss';

const COST_COMMISSION = 0.01;

const AvatarItem = ({ name, price, isLoading, icon, isSelected, setSelectedAvatar, isCurrent }) => {
  const [isHover, setHover] = useState(false);
  return (
    <div
      className={styles.AvatarItemContainer}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {!isLoading && (
        <div
          className={classnames(
            isSelected
              ? styles.AvatarOrangeCircle
              : isHover
              ? styles.AvatarIconHover
              : styles.AvatarGrayCircle,
          )}
        />
      )}
      {isLoading ? (
        <div className={styles.AvatarIconDefault}>
          <div className={styles.AvatarIconLoading} />
        </div>
      ) : (
        <div className={classnames(styles.AvatarIconPriceContainer)}>
          <div
            role="button"
            className={classnames(styles.AvatarIconContainer)}
            onClick={() => {
              setSelectedAvatar({ name, price });
            }}
            onKeyPress={() => {
              setSelectedAvatar({ name, price });
            }}
            tabIndex={0}
          >
            <img src={icon} className={classnames(styles.AvatarIcon)} alt="avatar_image" />
          </div>
          <div className={styles.AvatarPriceContainer}>
            {price === 0 ? (
              <p
                className={classnames(
                  styles.AvatarPriceText,
                  price === 0 && styles.AvatarPriceText__stock,
                  isCurrent && styles.AvatarPriceText__inUse,
                )}
              >
                {isCurrent ? 'In use' : price === 0 ? 'Stock' : ''}
              </p>
            ) : (
              <>
                <img src={HoneyIcon} className={styles.HoneyIcon} alt="honey" />
                <p className={styles.AvatarPriceText}>{price}</p>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const ChangeAvatarModal = observer(({ close }) => {
  const navigate = useNavigate();
  const stores = useStores();
  const [listAvatars, setListAvatars] = useState([{ name: 'bear1', price: 0, icon: Bear1Icon }]);
  const [isLoading, setLoading] = useState(true);
  const [isLoadingButton, setLoadingButton] = useState(false);
  const [selectedBear, setSelectedBear] = useState({});
  const [currentBear, setCurrentBear] = useState({});
  const [errorText, setErrorText] = useState('');
  const [costChangeAvatar, setCostChangeAvatar] = useState(0);
  const [enoughHoneyForChanged, setEnoughHoneyForChanged] = useState(true);

  const getIcon = (index) => {
    switch (index) {
      case 0:
        return Bear1Icon;
      case 1:
        return Bear2Icon;
      case 2:
        return Bear3Icon;
      case 3:
        return Bear4Icon;
      case 4:
        return Bear5Icon;
      case 5:
        return Bear6Icon;
      case 6:
        return Bear7Icon;
      case 7:
        return Bear8Icon;
      case 8:
        return Bear9Icon;
      case 9:
        return Bear10Icon;
      default:
        return Bear1Icon;
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const avatars = await settings.listAvatars();
        const { address } = stores.profileAvailable;
        const profile = await settings.profile({ address });
        if (!avatars.err && !profile.err) {
          const newAvatars = [];
          avatars.avatars.forEach((id, index) => {
            if (_.indexOf(profile.availableAvatars, id.name) !== -1) {
              newAvatars.push({ name: id.name, price: 0, icon: getIcon(index) });
            } else {
              newAvatars.push({ name: id.name, price: id.price, icon: getIcon(index) });
            }
          });
          setListAvatars(newAvatars);
          setCurrentBear({ name: profile.avatar, price: 0 });
          setSelectedBear({ name: profile.avatar, price: 0 });
        } else {
          setErrorText(avatars.msg || profile.msg);
        }
      } catch (err) {
        Logger.error(err);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const changeAvatarAlgorithm = async () => {
    try {
      setLoadingButton(true);
      const serverSettings = await settings.getAddressServer();
      if (serverSettings.err) {
        setErrorText(serverSettings.msg);
        return;
      }
      if (selectedBear.price > 0) {
        const buyAvatarResult = await settings.buyAvatar({
          bearAvatar: selectedBear,
          toAddress: serverSettings.address,
        });
        if (buyAvatarResult.err) {
          setErrorText(buyAvatarResult.msg);
          return;
        }
      } else {
        const changeAvatarResult = await settings.changeAvatar({
          bearAvatar: selectedBear,
        });
        if (changeAvatarResult.err) {
          setErrorText(changeAvatarResult.msg);
          return;
        }
      }

      stores.setProfileAvailable({ ...stores.profileAvailable, avatar: selectedBear.name });
      stores.setExecuteModal({
        name: ENUM_MODAL.CHANGE_AVATAR,
        state: { avatar: selectedBear.name },
      });
      gtagEvent(GOOGLE_ANALYTICS.CLICK_CHANGE_AVATAR, `${currentBear.name}`);
      close();
    } catch (err) {
      Logger.error(err);
    } finally {
      stores.setExecutePopup();
      setLoadingButton(false);
    }
  };
  useEffect(() => {
    (async () => {
      if (stores.executePopup && stores.executePopup.name === ENUM_POPUP.CHANGE_APPROVE_AVATAR) {
        await changeAvatarAlgorithm();
      }
    })();
  }, [stores.executePopup]);

  const changeAvatarClick = async () => {
    if (!enoughHoneyForChanged) {
      stores.setPropsForPopup({ honey: selectedBear.price + COST_COMMISSION });
      stores.setOpenPopup(ENUM_POPUP.CHANGE_AVATAR_WARNING);
      return;
    }

    if (selectedBear && currentBear.name !== selectedBear.name) {
      if (selectedBear.price !== 0) {
        const honeyAvatar = selectedBear.price ? selectedBear.price + COST_COMMISSION : 0;
        stores.setPropsForPopup({ honey: honeyAvatar });
        stores.setOpenPopup(ENUM_POPUP.CHANGE_APPROVE_AVATAR);
      } else {
        await changeAvatarAlgorithm();
      }
    } else {
      close();
    }
  };

  const setSelectedAvatar = ({ name, price }) => {
    if (selectedBear && selectedBear.name !== name) {
      let costChange = 0;
      if (price !== 0) {
        costChange = price + COST_COMMISSION;
      }
      if (currentBear.name === name) {
        costChange = 0;
      }
      setEnoughHoneyForChanged(stores.profileAvailable.honey >= costChange);
      setCostChangeAvatar(costChange);
      setSelectedBear({ name, price });
    }
  };

  return (
    <>
      <p className={styles.ModalText}>{stores.translate('choose_avatar_description')}</p>
      <div className={styles.AvatarIconsContainer}>
        {listAvatars &&
          listAvatars.map((avatar, index) => {
            const isSelected = selectedBear && selectedBear.name === avatar.name;
            const isCurrent = currentBear && currentBear.name === avatar.name;
            return (
              <AvatarItem
                name={avatar.name}
                price={avatar.price}
                icon={avatar.icon}
                isSelected={isSelected}
                isLoading={isLoading}
                isCurrent={isCurrent}
                setSelectedAvatar={setSelectedAvatar}
                // eslint-disable-next-line react/no-array-index-key
                key={`avatar_${index}`}
              />
            );
          })}
      </div>
      {errorText && <p className={styles.ErrorText}>Error: {errorText}</p>}
      {costChangeAvatar !== 0 && (
        <div className={styles.AvatarCostContainer}>
          <p className={styles.AvatarCostText}>{stores.translate('avatar_change_cost')}</p>
          <img src={HoneyIcon} alt="honey_icon" className={styles.HoneyIconCost} />
          <p className={styles.AvatarCostText}>{costChangeAvatar}</p>
        </div>
      )}
      <div className={styles.ButtonsContainer}>
        <Button
          colorType={ColorType.ORANGE}
          size={SizeButton.SMALL}
          onClick={() => {
            changeAvatarClick();
          }}
          title={
            selectedBear && currentBear && currentBear.name === selectedBear.name
              ? stores.translate('ok')
              : stores.translate('apply')
          }
          classNames={{
            Container: classnames(),
            Title: stores.browserInfo.isAndroid
              ? styles.TitleOrangeButton__android
              : styles.TitleOrangeButton,
          }}
          isLoading={isLoadingButton}
          disabled={isLoadingButton}
        />
      </div>
    </>
  );
});

export default ChangeAvatarModal;
