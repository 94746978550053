import React from 'react';
import {
  APIARY_TYPE,
  TREE_TYPE,
  TYPE_AUTH_CLIENT,
} from '@constants/blockchain';
import _ from 'lodash';

import { GameMethodNames, GameObjectNames, UnityMethodsName } from 'src/stores/unity';
import { ENUM_MODAL } from '@components/Modal/enumModal';
import bonus from 'src/api/server/bonus';
import { copyText, gtagEvent } from '@utils/common';
import Logger from '@utils/logger';
import KeplrWallet from 'src/api/KeplrWallet';
import { StoreInstance } from 'src/stores';

import BlockchainMediator from './Mediators/BlockchainMediator';
import Match3Mediator from './Mediators/Match3Mediator';
import StackingMediator from './Mediators/StackingMediator';
import DynamicStatesMediator from './Mediators/DynamicStatesMediator';
import InitFarmingMediator from './Mediators/InitFarmingMediator';
import BonusMediator from './Mediators/BonusMediator';
import LeaderBoardMediator from './Mediators/LeaderBoardMediator';
import StartUnityMediator from './Mediators/StartUnityMediator';
import MainGeneralMediator from './Mediators/MainGeneralMediator';
import { handleMainError } from './HandleError';

const handleAnalyticsEvent = ({ key, value }) => {
  gtagEvent(key, value);
}

const allEvents = ({
  stores = StoreInstance,
  unityListenersResponse = {
    name: '',
    properties: {},
    noPassword: false,
    isStacking: false,
  },
  password,
  setPassword,
  openFullscreen,
}) => {

  const GetStatusOnboarding = async () => {
    await StartUnityMediator.getStatusOnboarding();
  }

  const SetStatusOnboarding = async () => {
    await StartUnityMediator.setStatusOnboarding();
  }

  const GetStarterOffers = async () => {
    await MainGeneralMediator.getStarterOffers();
  }

  const GetInvoicePayment = async ({ pack }) => {
    await MainGeneralMediator.getInvoicePayment({ pack });
  }

  const InitializeFarm = async () => {
    await InitFarmingMediator.initializeFarming();
  };

  // eslint-disable-next-line camelcase
  const BuyAndReplaceProduct = async ({ type, kind, rowId, columnId }) => {
    await BlockchainMediator.buyAndSetObject({ type, kind, rowId, columnId });
    if (type === APIARY_TYPE) {
      await DynamicStatesMediator.updateHoneyBar();
    } else if (type === TREE_TYPE) {
      await DynamicStatesMediator.updateOxygen();
      await DynamicStatesMediator.updateCONE();
    }
  };

  const SetBeeFromWarehouseInApiary = async ({ beeId, apiaryId, kind }) => {
    await BlockchainMediator.setBeeInApiary({ apiaryId, beeId });
    await DynamicStatesMediator.updateHoneyBar();
  };

  const BuyBeeAndPlaceInApiary = async ({ apiaryId, kind }) => {
    await BlockchainMediator.createAndSetBeeInApiary({ apiaryId, kind });
    await DynamicStatesMediator.updateOxygen();
    await DynamicStatesMediator.updateHoneyBar();
  };

  const BuyBeeAndPlaceInWarehouse = async ({ kind }) => {
    await BlockchainMediator.buyBee({ kind });
  };

  const RemoveBeeFromApiary = async ({ apiaryId, beeId, kind }) => {
    await BlockchainMediator.removeBeeFromApiary({ apiaryId, beeId });
    await DynamicStatesMediator.updateHoneyBar();
  };

  const DeleteFromField = async ({ id, type, kind }) => {
    await BlockchainMediator.deleteFromField({ id, type });
    if (type === APIARY_TYPE) {
      await DynamicStatesMediator.updateHoneyBar();
    }
  };

  const SetObjOnField = async ({ id, rowId, columnId }) => {
    await BlockchainMediator.setObjOnField({ id, rowId, columnId });
  };
  // eslint-disable-next-line  camelcase
  const ReplaceProduct = async ({ id, rowId, columnId, newRowId, newColumnId }) => {
    await BlockchainMediator.moveObject({
      id,
      rowId,
      columnId,
      newRowId,
      newColumnId,
    });
  };
  const CollectHoney = async ({ apiaryId }) => {
    await BlockchainMediator.collectHoney({ apiaryId });
  };

  const OpenApiary = async ({ apiaryId }) => {
    await BlockchainMediator.openApiary({ apiaryId });
  };

  const OpenStore = async () => {
    await BlockchainMediator.openStore();
  };

  const ExpandLand = async () => {
    await BlockchainMediator.expandLand();
  };

  const BuySellingPack = async ({ packID }) => {
    await BlockchainMediator.buySellingPack({ packID });
  };

  // Stacking
  const OpenScreenStaking = async () => {
    const stackingResult = await StackingMediator.openScreenStaking();
  };

  const StakeCones = async ({ cone }) => {
    const resultStake = await StackingMediator.stakeCones({ cones: cone });
  };

  const UnstakeCones = async ({ cone, validatorAddress }) => {
    const resultUnstake = await StackingMediator.unstakeCones({ cones: cone, validatorAddress });
  };

  const CollectStakingHoney = async () => {
    await StackingMediator.collectStakingHoney();
  };

  const SigningTransaction = async ({ toAddress, amount }) => {
    await Match3Mediator.signingTransaction({ toAddress, amount });
  };

  const RequestLeaderBoard = async ({ type, index }) => {
    await LeaderBoardMediator.requestLeaderBoard({ type, index });
  };

  const RequestAvatarPlayers = async ({ playersData }) => {
    await LeaderBoardMediator.requestAvatarPlayers({ playersData });
  }

  const ClickButtonFullScreen = async () => {
    const element = document.getElementById('GameContainer');
    openFullscreen(element);
  };

  const CopyTextToClipboard = async ({ text }) => {
    if (!stores.browserInfo.isIPhone) {
      navigator.clipboard.writeText(text);
    } else {
      await copyText({ text });
    }
  };

  const CheckBearAuthorization = async () => {
    MainGeneralMediator.checkBearAuthorization();
  };

  const AuthConnectLobby = async ({ nonce }) => {
    await Match3Mediator.authConnectLobby({ nonce });
  };

  const ReceiveBonus = async () => {
    const resultTargets = await bonus.targets();
    const resultArchivedTargets = await bonus.achievedTargets();
    const resultCheckin = await bonus.checkIn();

    if (resultCheckin && (resultCheckin.err || resultCheckin.code > 0)) {
      handleMainError({ code: 112 });
    } else {
      BonusMediator.successfulReceiveBonus();
    }
  };

  const RequestProfileOpponent = async ({ address }) => {
    await Match3Mediator.requestProfileOpponent({ address });
  };

  const TournamentCurrent = async () => {
    await Match3Mediator.tournamentCurrent();
  };

  const TournamentNext = async () => {
    await Match3Mediator.tournamentNext();
  };

  const TournamentPrev = async () => {
    await Match3Mediator.tournamentPrev();
  };

  const GetEmojiList = async () => {
    await Match3Mediator.getEmojiList();
  }

  // ***** useEffect: handle unity events ***** //
  React.useEffect(async () => {
    if (
      (!_.isEmpty(unityListenersResponse.name) && unityListenersResponse.noPassword) ||
      (!_.isEmpty(unityListenersResponse.name) && !!password && !!stores.profileAvailable.address)
    ) {
      const { properties } = unityListenersResponse;
      Logger.infoMainWithKey('properties', properties);
      try {
        switch (unityListenersResponse.name) {
          case UnityMethodsName.GetStatusOnboarding:
            await GetStatusOnboarding();
            break;
          case UnityMethodsName.SetStatusOnboarding:
            await SetStatusOnboarding();
            break;
          case UnityMethodsName.GetStarterOffers:
            await GetStarterOffers();
            break;
          case UnityMethodsName.GetInvoicePayment:
            await GetInvoicePayment({ pack: properties.pack });
            break;
          case UnityMethodsName.InitializeFarm:
            await InitializeFarm();
            break;
          case UnityMethodsName.BuyAndReplaceProduct:
            await BuyAndReplaceProduct({
              type: properties.type,
              kind: properties.kind,
              rowId: properties.rowId,
              columnId: properties.columnId,
            });
            break;
          case UnityMethodsName.SetBeeFromWarehouseInApiary:
            await SetBeeFromWarehouseInApiary({
              apiaryId: properties.apiaryId,
              beeId: properties.beeId,
              kind: properties.kind,
            });
            break;
          case UnityMethodsName.BuyBeeAndPlaceInApiary:
            await BuyBeeAndPlaceInApiary({
              apiaryId: properties.apiaryId,
              kind: properties.kind,
            });
            break;
          case UnityMethodsName.BuyBeeAndPlaceInWarehouse:
            await BuyBeeAndPlaceInWarehouse({ kind: properties.kind });
            break;
          case UnityMethodsName.RemoveBeeFromApiary:
            await RemoveBeeFromApiary({
              apiaryId: properties.apiaryId,
              beeId: properties.beeId,
              kind: properties.kind,
            });
            break;
          case UnityMethodsName.DeleteFromField:
            await DeleteFromField({
              id: properties.id,
              type: properties.type,
              kind: properties.kind,
            });
            break;
          case UnityMethodsName.SetObjOnField:
            await SetObjOnField({
              id: properties.id,
              rowId: properties.rowId,
              columnId: properties.columnId,
            });
            break;
          case UnityMethodsName.ReplaceProduct:
            await ReplaceProduct({
              id: properties.id,
              rowId: properties.rowId,
              columnId: properties.columnId,
              newRowId: properties.newRowId,
              newColumnId: properties.newColumnId,
            });
            break;
          case UnityMethodsName.CollectHoney:
            await CollectHoney({ apiaryId: properties.apiaryId });
            break;
          case UnityMethodsName.OpenApiary:
            await OpenApiary({ apiaryId: properties.apiaryId });
            break;
          case UnityMethodsName.OpenStore:
            await OpenStore();
            break;
          case UnityMethodsName.ExpandLand:
            await ExpandLand();
            break;
          case UnityMethodsName.BuySellingPack:
            await BuySellingPack({ packID: properties.packID });
            break;
          case UnityMethodsName.ClickButtonFullScreen:
            await ClickButtonFullScreen();
            break;
          case UnityMethodsName.CopyTextToClipboard:
            await CopyTextToClipboard({ text: properties.text });
            break;
          // Staking
          case UnityMethodsName.OpenScreenStaking:
            await OpenScreenStaking();
            break;
          case UnityMethodsName.StakeCones:
            await StakeCones({ cone: properties.cone });
            break;
          case UnityMethodsName.UnstakeCones:
            await UnstakeCones({ cone: properties.cone, validatorAddress: properties.validatorAddress });
            break;
          case UnityMethodsName.CollectStakingHoney:
            await CollectStakingHoney();
            break;
          case UnityMethodsName.SigningTransaction:
            await SigningTransaction({
              toAddress: properties.toAddress,
              amount: properties.amount,
            });
            break;
          case UnityMethodsName.RequestLeaderBoard:
            await RequestLeaderBoard({ type: properties.type, index: properties.index });
            break;
          case UnityMethodsName.RequestAvatarPlayers:
            await RequestAvatarPlayers({ playersData: properties.playersData });
            break;
          case UnityMethodsName.CheckBearAuthorization:
            await CheckBearAuthorization();
            break;
          case UnityMethodsName.AuthConnectLobby:
            await AuthConnectLobby({ nonce: properties.nonce });
            break;
          case 'ReceiveBonus':
            await ReceiveBonus();
            break;
          case UnityMethodsName.RequestProfileOpponent:
            await RequestProfileOpponent({ address: properties.address });
            break;
          case UnityMethodsName.TournamentCurrent:
            await TournamentCurrent();
            break;
          case UnityMethodsName.TournamentNext:
            await TournamentNext();
            break;
          case UnityMethodsName.TournamentPrev:
            await TournamentPrev();
            break;
          case UnityMethodsName.GetEmojiList:
            await GetEmojiList();
            break;
          default:
            break;
        }
        await DynamicStatesMediator.updateHoney();
        await DynamicStatesMediator.updateCONE();
      } catch (err) {
        handleMainError({ code: 111 }, err);
        if (stores.typeAuthClient === TYPE_AUTH_CLIENT.KEPLR) {
          // reject transaction, nothing happen
          // console.log('1 KEPLR>>>')
          // stores.unityStore.send({
          //   objectName: GameObjectNames.FarmReact,
          //   methodName: GameMethodNames.FinishTransaction,
          //   isLogger: false,
          // });
        } else if (stores.typeAuthClient === TYPE_AUTH_CLIENT.MNEMONIC) {
          console.log('1>>>');
          // handleMainError({ code: 111, useProduction: false });
        }
      } finally {
        if (
          unityListenersResponse.name === UnityMethodsName.CheckBearAuthorization ||
          unityListenersResponse.name === UnityMethodsName.AuthConnectLobby ||
          unityListenersResponse.name === 'ReceiveBonus'
        ) {
          if (stores.typeAuthClient === TYPE_AUTH_CLIENT.KEPLR) {
            stores.unityStore.send({
              objectName: GameObjectNames.FarmReact,
              methodName: GameMethodNames.FinishTransaction,
            });
          }
          console.log('2>>>');
        } else 
        if (
          unityListenersResponse.isStacking ||
          unityListenersResponse.name === UnityMethodsName.OpenStore ||
          (unityListenersResponse.name !== UnityMethodsName.SigningTransaction &&
            unityListenersResponse.name !== UnityMethodsName.RequestLeaderBoard)
        ) {
          console.log('3>>>');
          if (unityListenersResponse.name === UnityMethodsName.OpenStore) {
            Logger.infoMainWithKey('RemovePopupStore');
          } else if (unityListenersResponse.isStacking) {
            Logger.infoMainWithKey('RemoveSpinnerStackingPopup');
          } else if (
            unityListenersResponse.name !== UnityMethodsName.SigningTransaction &&
            unityListenersResponse.name !== UnityMethodsName.RequestLeaderBoard
          ) {
            Logger.infoMainWithKey('FinishTransaction');
          }

          if (unityListenersResponse.name === UnityMethodsName.CollectHoney 
            || unityListenersResponse.name === UnityMethodsName.ReplaceProduct 
            || unityListenersResponse.name === UnityMethodsName.BuyAndReplaceProduct
            || unityListenersResponse.name === UnityMethodsName.RemoveBeeFromApiary
            || unityListenersResponse.name === UnityMethodsName.DeleteFromField
            || unityListenersResponse.name === UnityMethodsName.ExpandLand
            || unityListenersResponse.name === UnityMethodsName.SetBeeFromWarehouseInApiary
            || unityListenersResponse.name === UnityMethodsName.SetObjOnField) { // logic for FinishTransaction
            Logger.infoMainWithKey(`${unityListenersResponse.name} without FinishTransaction`);
          } else {
            stores.unityStore.send({
              objectName: GameObjectNames.FarmReact,
              methodName: GameMethodNames.FinishTransaction,
              isLogger: false,
            });
          }

        }
      }
    } else if (!password && !_.isEmpty(unityListenersResponse.name)) {
      // // Keplr
      // if (stores.typeAuthClient === TYPE_AUTH_CLIENT.KEPLR) {
      //   const accessKeplr = await KeplrWallet.accessAndInitKeplrClient();
      //   console.log('[Keplr] accessKeplr >> ', accessKeplr);
      //   if (accessKeplr) {
      //     console.log('[Keplr] setPassword >> ');
      //     setPassword(`wallet_${TYPE_AUTH_CLIENT.KEPLR}`);
      //   } 
      //   // else {
      //   stores.unityStore.send({
      //     objectName: GameObjectNames.FarmReact,
      //     methodName: GameMethodNames.FinishTransaction,
      //     isLogger: false,
      //   });
      //   // }
      //   return;
      // }

      // Mnemoic
      if (
        unityListenersResponse.name === UnityMethodsName.CheckBearAuthorization ||
        unityListenersResponse.name === UnityMethodsName.AuthConnectLobby
      ) {
        stores.setOpenModal(ENUM_MODAL.UNITY_VERIFY_YOURSELF);
      } else if (unityListenersResponse.name === 'ReceiveBonus') {
        stores.setOpenModal(ENUM_MODAL.UNITY_CHECK_IN_DAILY_BONUS);
      } else {
        stores.setOpenModal(ENUM_MODAL.UNITY_APPROVE_TRANSACTION);
      }
    }
  }, [unityListenersResponse, password]);
};

export default {
  allEvents,
  handleAnalyticsEvent,
};
