/* eslint-disable no-unused-vars */
/* eslint-disable */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react';
import * as yup from 'yup';
import _ from 'lodash';
import { useMediaQuery } from 'src/helpers/media';
import { checkPasswordAuth } from '@utils/authorization';

import Input from '@components/Input';
import { Button, ColorType, SizeButton, UNIQ_ID_BUTTON } from '@components/Button';

import { useStores } from 'src/stores';

import styles from './approveTransaction.module.scss';

// const debounced = debounce(() => {
//   gtagEvent(GOOGLE_ANALYTICS.SLIDE_INVEST);
// }, 1000);

const ApproveTransactionModal = observer(({ close }) => {
  const width360 = useMediaQuery('(max-width: 360px)');
  // const width300 = useMediaQuery('(max-width: 300px)');
  const width1000 = useMediaQuery('(max-width: 1000px)');

  const stores = useStores();
  const [isLoading, setLoading] = useState(false);

  const schemaModal = yup
    .object()
    .shape({
      password: yup.string().min(5, stores.translate('password_input_validation')).required(),
    })
    .required();

  const ModalForm = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schemaModal),
    defaultValues: {
      password: '',
    },
  });
  const modalFields = ModalForm.watch();

  const handleKeypress = (e) => {
    if (e.key && e.key.toLowerCase() === 'enter') {
      ModalForm.handleSubmit(onCheckPasswordClick)();
    }
  };

  const finishPasswordClicked = (successState) => {
    if (!successState) {
      ModalForm.setError('password', {
        type: 'manual',
        message: stores.translate('password_incorrect'),
      });
    } else {
      stores.unityStore.setApproveTransaction(modalFields.password);
      close(false);
    }
  };

  const onCheckPasswordClick = async () => {
    setLoading(true);
    await checkPasswordAuth({
      password: modalFields.password,
      finish: finishPasswordClicked,
    });
    setLoading(false);
  };

  return (
    <>
      <Input
        placeholder={stores.translate('password_input_placeholder')}
        canError
        label={stores.translate('password')}
        error={ModalForm.formState.errors?.password}
        name="password"
        register={ModalForm.register}
        autoComplete="no"
        isButtonSecretShow
        onKeyDown={handleKeypress}
        classNames={{
          Label: styles.LabelInput,
          InputContainer: styles.Input_InputContainer,
          Container: styles.Input_Container,
          Input: styles.Input_Input,
          InputButtonShow: styles.Input_InputButtonShow,
        }}
        isFocus
      />
      <div className={styles.ButtonsContainer}>
        <Button
          width={240}
          colorType={ColorType.GREEN}
          uniqID={UNIQ_ID_BUTTON.ApproveTransaction.Approve}
          height={width360 ? 92 : undefined}
          size={width1000 ? SizeButton.SMALL : SizeButton.MEDIUM}
          onClick={() => {
            ModalForm.handleSubmit(onCheckPasswordClick)();
          }}
          title={stores.translate('approve')}
          classNames={{
            Container: styles.ButtonContainer,
            Title: stores.browserInfo.isAndroid
              ? styles.TitleGreenButton__android
              : styles.TitleGreenButton,
          }}
          isLoading={isLoading}
          disabled={isLoading}
          valueScaleAnimation={0.95}
        />
      </div>
    </>
  );
});

export default ApproveTransactionModal;
