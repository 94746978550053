import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { useMediaQuery } from 'src/helpers/media';
import { gtagEvent, copyText } from '@utils/common';
import { useCopied } from '@hooks/custom';
import { GOOGLE_ANALYTICS } from '@src/constants/googleAnalyticsEvents';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Close from '@src/assets/control/close.svg';
import DiagonalLine from '@assets/common/diagonal_line.svg';
import WindowModal from '@assets/Christmas/window.png';
import WindowDecorModal from '@assets/Christmas/window_decor.png';

// import Input from '@components/Input';
import { Button, ColorType, SizeButton, UNIQ_ID_BUTTON } from '@components/Button';
import InformationPopup from '@components/InformationPopup';

import { SELLING_PACKS } from '@constants/blockchain';

import payments from 'src/api/server/payments';
import { QRCodeSVG } from 'qrcode.react';

import { observer } from 'mobx-react';
import { useStores } from 'src/stores';
import { ENUM_POPUP } from '@components/PopUp/enumPopup';
import BeeSpinner from '@components/BeeSpinner';
import { SizeTitleModal } from '@components/Modal';

import Logger from '@utils/logger';
import TextSVG from '@components/TextSVG';
import URLS from 'src/constants/urls';
import CheckBox from '@components/CheckBox';
import { SKIP_EVENT_PACK } from '@constants/system';

import PackItem from './PackItem';
import TitleModal from './TitleModal';
import styles from './christmas.module.scss';

const SELLING_PACKS_STATUSES = {
  WAITING: 'WAITING',
  TRANSFERRING: 'TRANSFERRING',
  TRANSFERED: 'TRANSFERED',
  ERROR: 'ERROR',
  ERROR_PAYMENT: 'ERROR_PAYMENT',
};

// const packs = Object.values(SELLING_PACKS);
const PREPARE_STEP = 'prepare';
const PROCESS_STEP = 'process';
const FINISH_STEP = 'finish';

const ChristmasModal = observer(({ close }) => {
  const width1000 = useMediaQuery('(max-width: 1000px)');
  const width650 = useMediaQuery('(max-width: 650px)');
  const width375 = useMediaQuery('(max-width: 375px');
  const stores = useStores();
  const [isLoadingNowPayment, setLoadingNowPayment] = useState(false);
  const [isLoadingBinance, setLoadingBinance] = useState(false);
  // const width360 = useMediaQuery('(max-width: 360px');
  // const width300 = useMediaQuery('(max-width: 300px');
  const [selectedPack, setSelectedPack] = useState('');
  const [selectedAgree, setSelectedAgree] = useState(false);
  const [packInfo, setPackInfo] = useState();
  const [oldCostSellingPack, setOldCostSellingPack] = useState(0);
  const [packHasBonus, setPackHasBonus] = useState(false);
  const [responseBinance, setResponseBinance] = useState();
  const [statusSellingPack, setStatusSellingPack] = useState(SELLING_PACKS_STATUSES.WAITING);
  const [errorTextSellingPack, setErrorTextSellingPack] = useState('');
  const [responseNowPayment, setResponseNowPayment] = useState();
  const [processPayment, setProcessPayment] = useState(PREPARE_STEP);
  const { setCopied, copied } = useCopied();

  const schemaModal = yup
    .object()
    .shape({
      referralCode: yup.string(),
    })
    .required();

  const ModalForm = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schemaModal),
    defaultValues: {
      referralCode: '',
    },
  });
  const modalFields = ModalForm.watch();

  useEffect(() => {
    /* Dynamically lock scrolling background */
    document.body.style.overflowY = 'hidden';
    setPackInfo(stores.stateDisplayedPaymentModal.packInfo);
    // console.log(stores.stateDisplayedPaymentModal.packInfo);
    if (
      stores.stateDisplayedPaymentModal.packInfo.ITEMS &&
      stores.stateDisplayedPaymentModal.packInfo.ITEMS.length > 0
    ) {
      let oldTotal = 0;
      stores.stateDisplayedPaymentModal.packInfo.ITEMS.forEach((item) => {
        oldTotal += item.PRICE;
      });
      setOldCostSellingPack(oldTotal);
    } else {
      setOldCostSellingPack(stores.stateDisplayedPaymentModal.packInfo.PRICE);
    }
    setSelectedPack(stores.stateDisplayedPaymentModal.packInfo.ID);
    if (
      stores.stateDisplayedPaymentModal.packInfo.ITEMS &&
      stores.stateDisplayedPaymentModal.packInfo.ITEMS.length === 1
    ) {
      if (
        stores.stateDisplayedPaymentModal.packInfo.PRICE ===
        stores.stateDisplayedPaymentModal.packInfo.ITEMS[0].PRICE
      ) {
        setPackHasBonus(true);
      }
    }
    return () => {
      /* Dynamically unlock scrolling background */
      document.body.style.overflowY = 'initial';
    };
  }, []);

  const finishNowPaymentStep = async () => {
    try {
      const paymentResponse = await payments.paymentNowPayment({
        pack: selectedPack,
        referralCode: modalFields.referralCode,
      });
      // console.log('paymentResponse >> ', paymentResponse);
      if (paymentResponse.err) {
        setStatusSellingPack(SELLING_PACKS_STATUSES.ERROR_PAYMENT);
        setErrorTextSellingPack(paymentResponse.msg);
      } else {
        setResponseNowPayment(paymentResponse);
      }
      setProcessPayment(FINISH_STEP);
    } catch (err) {
      // eslint-disable-next-line no-console
      Logger.error(err);
    }
  };

  useEffect(() => {
    (async () => {
      // console.log('isFinishKYCVerified >>>> ', stores.isFinishKYCVerified);
      if (stores.isFinishKYCVerified) {
        await finishNowPaymentStep();
      }
    })();
  }, [stores.isFinishKYCVerified]);

  useEffect(() => {
    let intervalId;
    (async () => {
      // console.log('isFinishKYCVerified >>>> ', stores.isFinishKYCVerified);
      if (processPayment === FINISH_STEP && responseBinance) {
        intervalId = setInterval(async () => {
          const binanceInfo = await payments.getBinanceInfo({ id: responseBinance._id });
          // console.log('response binanceInfo >> ', binanceInfo);
          // setStatusSellingPack(binanceInfo.selling_pack_status)
          if (binanceInfo.selling_pack_status !== SELLING_PACKS_STATUSES.WAITING) {
            setStatusSellingPack(binanceInfo.selling_pack_status);
            if (binanceInfo.selling_pack_status === SELLING_PACKS_STATUSES.ERROR) {
              setErrorTextSellingPack(binanceInfo.selling_pack_error);
            }
          }
          if (binanceInfo.selling_pack_status === SELLING_PACKS_STATUSES.TRANSFERED) {
            stores.setUpdateStateBearCreated(true);
          }
        }, 10000);
      } else if (processPayment === FINISH_STEP && responseNowPayment) {
        intervalId = setInterval(async () => {
          const nowPaymentInfo = await payments.getNowPaymentInfo({ id: responseNowPayment._id });
          // console.log('response nowPaymentInfo >> ', nowPaymentInfo);
          // setStatusSellingPack(binanceInfo.selling_pack_status)
          if (nowPaymentInfo.selling_pack_status !== SELLING_PACKS_STATUSES.WAITING) {
            setStatusSellingPack(nowPaymentInfo.selling_pack_status);
            if (nowPaymentInfo.selling_pack_status === SELLING_PACKS_STATUSES.ERROR) {
              setErrorTextSellingPack(nowPaymentInfo.selling_pack_error);
            }
          }
          if (nowPaymentInfo.selling_pack_status === SELLING_PACKS_STATUSES.TRANSFERED) {
            stores.setUpdateStateBearCreated(true);
          }
        }, 10000);
      }
    })();
    return () => {
      clearInterval(intervalId);
    };
  }, [processPayment]);

  useEffect(() => {
    if (statusSellingPack === SELLING_PACKS_STATUSES.TRANSFERED) {
      stores.setOpenPopup(ENUM_POPUP.SELLING_PACK_TRANSFERRED);
      close(true);
      stores.setFinishKYCVerified(false);
    }
  }, [statusSellingPack]);

  const clickScreenOutside = (e) => {
    if (
      e?.target?.className &&
      _.isString(e.target.className) &&
      e.target.className.includes('payments_ModalContainer')
    ) {
      close(true);
      stores.setFinishKYCVerified(false);
    }
  };

  const onChangePack = (value) => {
    setSelectedPack(value);
  };

  const copyLink = () => {
    if (responseBinance) {
      copyText({ text: responseBinance.payment_links.checkoutUrl });
    } else if (responseNowPayment) {
      copyText({ text: responseNowPayment.pay_address });
    }
    setCopied();
  };

  const binancePaymentClick = async () => {
    if (!processPayment === PREPARE_STEP || isLoadingNowPayment) return;
    try {
      setLoadingBinance(true);
      setProcessPayment(PROCESS_STEP);
      const paymentResponse = await payments.paymentBinance({
        pack: selectedPack,
        referralCode: modalFields.referralCode,
      });

      if (paymentResponse.err) {
        setStatusSellingPack(SELLING_PACKS_STATUSES.ERROR_PAYMENT);
        setErrorTextSellingPack(paymentResponse.msg);
        // console.log('>>>>>')
      } else {
        setResponseBinance(paymentResponse);
      }

      setProcessPayment(FINISH_STEP);
    } catch (err) {
      stores.setPropsForPopup({ textError: err });
      stores.setOpenPopup(ENUM_POPUP.ERROR);
      Logger.error(err);
    } finally {
      setLoadingBinance(false);
    }
  };

  const nowPaymentClick = async () => {
    if (!processPayment === PREPARE_STEP || isLoadingBinance) return;
    try {
      setLoadingNowPayment(true);
      setProcessPayment(PROCESS_STEP);
      const isVerified = await payments.getVerifiedKYC();
      if (isVerified && isVerified.err) {
        setStatusSellingPack(SELLING_PACKS_STATUSES.ERROR_PAYMENT);
        console.log(isVerified);
        setErrorTextSellingPack(isVerified.msg);
      } else if (isVerified) {
        await finishNowPaymentStep();
      } else {
        const result = await payments.getTokenNowPayment();
        if (result.err) {
          setStatusSellingPack(SELLING_PACKS_STATUSES.ERROR_PAYMENT);
          setErrorTextSellingPack(result.msg);
        } else {
          stores.setTokenNowPayments(result.token);
        }
      }
      setProcessPayment(FINISH_STEP);
    } catch (err) {
      stores.setPropsForPopup({ textError: err });
      stores.setOpenPopup(ENUM_POPUP.ERROR);
      Logger.error(err);
    } finally {
      setLoadingNowPayment(false);
    }
  };

  const sizeTitleModal = () => {
    return width1000 ? SizeTitleModal.Small : SizeTitleModal.Large;
  };

  const skipPack = () => {
    localStorage.setItem(SKIP_EVENT_PACK, 'true');
    close(true);
  }

  const title = <TextSVG text={stores.translate('halloween')} color="#2C2C2C" textColor="#FFA40A" />;
  return (
    <div
      className={styles.ModalContainer}
      role="button"
      tabIndex={0}
      onClick={(e) => clickScreenOutside(e)}
      onKeyDown={() => {}}
    >
      <div className={styles.ModalContainerInner}>
        <div
          className={classnames(
            styles.Modal,
            processPayment === FINISH_STEP && styles.Modal__Christmas,
          )}
        >
          {processPayment !== FINISH_STEP && (
            <>
              <TitleModal
                title={stores.translate('christmas_sale')}
                classNames={{ Title: styles.Title, TitleModal: styles.TitleModal }}
                size={sizeTitleModal()}
              />
              <img src={WindowModal} className={styles.WindowModal} alt="window_modal" />
            </>
          )}
          <button
            type="button"
            className={classnames(
              styles.ModalClose,
              processPayment === FINISH_STEP && styles.ModalClose__isChristmas,
            )}
            onClick={() => {
              close(true);
              stores.setFinishKYCVerified(false);
              // gtagEvent(
              //   `${GOOGLE_ANALYTICS.CLICK_CLOSE} ${
              //     isShowKYC ? 'KYC' : processPayment !== 'prepare' ? 'Binance' : ''
              //   } ${processPayment}`,
              // );
              gtagEvent(GOOGLE_ANALYTICS.CLICK_CLOSE);
            }}
          >
            <img
              onLoad={() => stores.increaseDownloadedImageCounter(Close)}
              onError={() => Logger.error('Error with image loading')}
              className={styles.CloseIcon}
              src={Close}
              alt="Close"
              loading="lazy"
            />
          </button>
          {selectedPack && packInfo && processPayment !== FINISH_STEP && (
            <>
              <div className={styles.PackItemsContainer}>
                {packInfo.ITEMS.map((item) => {
                  if (!packHasBonus && item.BONUS) {
                    setPackHasBonus(true);
                  }
                  return (
                    <PackItem
                      type={item.KIND}
                      title={item.TITLE}
                      key={item.TITLE}
                      className={classnames(styles.ItemsBlock)}
                      price={item.BONUS ? '' : item.PRICE}
                      itemsDescription={stores.paramsForObjectsBlockchain}
                      params={item.PARAMS}
                    />
                  );
                })}
              </div>
              <img
                src={WindowDecorModal}
                className={styles.WindowDecorModal}
                alt="window_decoration"
              />
              <div className={styles.PaymentsButtonContainer}>
                <Button
                  width={width375 ? 220 : width650 ? 280 : 340}
                  size={width1000 ? 'MEDIUM' : 'MEDIUM'}
                  colorType={ColorType.ORANGE}
                  uniqID={UNIQ_ID_BUTTON.Payment.Binance}
                  height={width1000 ? 92 : undefined}
                  onClick={() => {
                    binancePaymentClick();
                    gtagEvent(GOOGLE_ANALYTICS.CLICK_BINANCE_PAYMENT);
                  }}
                  // title={stores.translate('binance_payment')}
                  title={`${packInfo.PRICE}`}
                  classNames={{
                    Container: styles.ButtonContainer,
                    Title: stores.browserInfo.isAndroid
                      ? styles.TitleOrangeButton__android
                      : styles.TitleOrangeButton,
                    TitleContainer: width650 ? styles.SmallTitleContainer : styles.TitleContainer,
                    TitleOldTotalUSDTText:
                      (width375 && styles.SmallTitleOldTotalUSDTText_375) ||
                      (width650 && styles.SmallTitleOldTotalUSDTText_650),
                    PriceDiscountUSDTText:
                      (width375 && styles.SmallPriceDiscountUSDTText_375) ||
                      (width650 && styles.SmallPriceDiscountUSDTText_650),
                  }}
                  isLoading={isLoadingBinance}
                  valueScaleAnimation={0.95}
                  isPresalePackButton
                  isUSDTButton
                  isSmallUSDTText
                  sumItemsPrices={68}
                />
              </div>
            </>
          )}
          {processPayment === FINISH_STEP && (
            <>
              {/* <div style={{ marginTop: 16 }} /> */}
              {responseBinance && (
                <div className={styles.PaymentInfoContainer}>
                  {statusSellingPack === SELLING_PACKS_STATUSES.WAITING ? (
                    <>
                      <p className={styles.PaymentInfoTitle}>
                        {stores.translate('link_for_paying_package')}, {selectedPack}:
                      </p>
                      {responseBinance.payment_links && responseBinance.payment_links.checkoutUrl && (
                        <a
                          href={
                            stores.browserInfo.isMobile
                              ? responseBinance.payment_links?.deeplink
                              : responseBinance.payment_links?.checkoutUrl
                          }
                          className={styles.PaymentInfoLink}
                        >
                          {stores.browserInfo.isMobile
                            ? responseBinance.payment_links?.deeplink
                            : responseBinance.payment_links?.checkoutUrl}
                        </a>
                      )}
                      {responseBinance.payment_links &&
                        responseBinance.payment_links.qrcodeLink && (
                          <img
                            onLoad={() =>
                              stores.increaseDownloadedImageCounter(
                                responseBinance.payment_links.qrcodeLink,
                              )
                            }
                            onError={() => Logger.error('Error with image loading')}
                            src={responseBinance.payment_links.qrcodeLink}
                            alt="binance_qr_code"
                            className={styles.BinanceQrCode}
                          />
                        )}
                      <div className={styles.PaymentInfoAttentionContainer}>
                        <p className={styles.PaymentInfoAttention}>
                          {stores.translate('notes_one_purchase_payment')}
                        </p>
                      </div>
                    </>
                  ) : statusSellingPack === SELLING_PACKS_STATUSES.TRANSFERRING ? (
                    <>
                      <p className={styles.PaymentInfoTitle}>
                        {stores.translate('your_invoice_processed')}
                      </p>
                      <BeeSpinner
                        classNames={{ Spinner: styles.LoadingTransferringPackContainer }}
                      />
                    </>
                  ) : (
                    <>
                      <p className={styles.PaymentInfoTitle}>
                        {stores.translate('error_occurred_receiving_selling_pack')}:
                      </p>
                      <div className={styles.PaymentInfoAttentionContainer}>
                        <p className={styles.PaymentInfoAttention}>{errorTextSellingPack}</p>
                      </div>
                    </>
                  )}
                </div>
              )}
              {responseNowPayment && (
                <div className={styles.PaymentInfoContainer}>
                  {statusSellingPack === SELLING_PACKS_STATUSES.WAITING ? (
                    <>
                      <p className={styles.PaymentInfoTitle}>
                        {stores.translate('wallet_address_for_payment')}, {selectedPack}:
                      </p>
                      <p className={styles.PaymentInfoAddress}>{responseNowPayment.pay_address}</p>
                      <QRCodeSVG
                        value={responseNowPayment.pay_address}
                        className={styles.NowPaymentQrCode}
                      />
                      <p className={styles.TitleTotalNowPaymentText}>
                        {stores.translate('total')}: {packInfo.PRICE} USDT
                      </p>
                      <div className={styles.PaymentInfoAttentionContainer}>
                        <p className={styles.PaymentInfoAttention}>
                          {stores.translate('notes_purchase_now_payment')}
                        </p>
                      </div>
                    </>
                  ) : statusSellingPack === SELLING_PACKS_STATUSES.TRANSFERRING ? (
                    <>
                      <p className={styles.PaymentInfoTitle}>
                        {stores.translate('your_invoice_processed')}
                      </p>
                      <BeeSpinner
                        classNames={{ Spinner: styles.LoadingTransferringPackContainer }}
                      />
                    </>
                  ) : (
                    <>
                      <p className={styles.PaymentInfoTitle}>
                        {stores.translate('error_occurred_receiving_selling_pack')}:
                      </p>
                      <div className={styles.PaymentInfoAttentionContainer}>
                        <p className={styles.PaymentInfoAttention}>{errorTextSellingPack}</p>
                      </div>
                    </>
                  )}
                </div>
              )}
              {statusSellingPack === SELLING_PACKS_STATUSES.ERROR_PAYMENT && (
                <>
                  <p className={styles.PaymentInfoTitle}>
                    {stores.translate('chosen_selling_pack')}, «{packInfo.TITLE}»:
                  </p>
                  <div className={styles.PaymentInfoAttentionContainer}>
                    <p className={styles.PaymentInfoAttention}>Error: {errorTextSellingPack}</p>
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <div onClick={() => skipPack()} role="button" tabIndex="0" onKeyPress={() => skipPack()}>
          <p className={styles.ModalDescription}>{stores.translate('description_skip_pack')}</p>
        </div>
      </div>
    </div>
  );
});

export default ChristmasModal;
