import { ENUM_MODAL } from '@components/Modal/enumModal';
import { TYPE_AUTH_CLIENT } from '@constants/blockchain';
// import { ENUM_MODAL as ENUM_MODAL_LANDING } from '@components_new/Modal/enumModal';
import Logger from '@utils/logger';
import { StoreInstance } from 'src/stores';
import { UnityMethodsName } from 'src/stores/unity';
import HandleUnityEvents from './HandleUnityEvents';
import DynamicStatesMediator from './Mediators/DynamicStatesMediator';
import MainGeneralMediator from './Mediators/MainGeneralMediator';
import StartUnityMediator from './Mediators/StartUnityMediator';

const stores = StoreInstance;
/* eslint-disable max-len */

function allStorage() {
  const values = [];
  const keys = Object.keys(localStorage);
  let i = keys.length;

  while (i >= 0) {
    i -= 1;
    values.push({ key: keys[i], value: localStorage.getItem(keys[i]) });
  }

  return {
    keys,
    values
  };
}

/**
 * `List main events`
 */
const events = ({ addEventListener, removeEventListener, setUnityListenersResponse }) => {
  const callbackError = (error) => {
    Logger.infoMainNotify({ nameNotify: 'Error Unity events', section: 'callbackError', meta: error, useInProduction: true });
  }

  const callbackUpdateHoney = async () => {
    Logger.infoMainWithKey('UpdateHoney');
    await DynamicStatesMediator.updateHoney();
  }
  
  const callbackRequestHints = async (address) => {
    Logger.infoMainWithKey('RequestHints', address);
    await MainGeneralMediator.requestHints({ address });
  }
  
  const callbackRequestProfilePlayer = async () => {
    Logger.infoMainWithKey('RequestProfilePlayer');
    if (stores.typeAuthClient === TYPE_AUTH_CLIENT.KEPLR) {
      await StartUnityMediator.initializeUnityGameWithStart();
    } else if (stores.password) {
      stores.unityStore.setApproveTransaction(stores.password);
    } else {
      stores.setOpenModal(ENUM_MODAL.UNITY_VERIFY_YOURSELF);
    }
  }

  const callbackSignData = async (id, type, data) => {
    Logger.infoMainWithKey(UnityMethodsName.SignData, id, type, data);
    await MainGeneralMediator.signData({ id, type, data });
  }
  
  const callbackSendUnityAnalyticsEvent = async (key, value) => {
    Logger.infoMainWithKey('SendUnityAnalyticsEvent', key, value);
    HandleUnityEvents.handleAnalyticsEvent({ key, value });
  }
  
  const callbackGetStatusOnboarding = async () => {
    Logger.infoMainWithKey('GetStatusOnboarding');
    setUnityListenersResponse({
      name: UnityMethodsName.GetStatusOnboarding,
      noPassword: true,
      isStacking: false,
    });
  }
  
  const callbackSetStatusOnboarding = async () => {
    Logger.infoMainWithKey('SetStatusOnboarding');
    setUnityListenersResponse({
      name: UnityMethodsName.SetStatusOnboarding,
      noPassword: false,
      isStacking: false,
    });
  }

  const callbackGetStarterOffers = async (text) => {
    Logger.infoMainWithKey('GetStarterOffers');
    setUnityListenersResponse({
      name: UnityMethodsName.GetStarterOffers,
      noPassword: true,
      isStacking: false,
    });
  }

  const callbackGetInvoice = async (pack) => {
    Logger.infoMainWithKey('GetInvoicePayment');
    setUnityListenersResponse({
      name: UnityMethodsName.GetInvoicePayment,
      properties: {
        pack,
      },
      noPassword: false,
      isStacking: false,
    });
  }
  
  const callbackCheckBearAuthorization = async () => {
    Logger.infoMainWithKey('CheckBearAuthorization');
    setUnityListenersResponse({
      name: UnityMethodsName.CheckBearAuthorization,
      noPassword: false,
      isStacking: false,
    });
  }
  
  const callbackClickButtonFullScreen = async () => {
    Logger.infoMainWithKey('ClickButtonFullScreen');
    setUnityListenersResponse({
      name: UnityMethodsName.ClickButtonFullScreen,
      noPassword: true,
      isStacking: false,
    });
  }
  
  const callbackCopyTextToClipboard = async (text) => {
    Logger.infoMainWithKey('CopyTextToClipboard');
    setUnityListenersResponse({
      name: UnityMethodsName.CopyTextToClipboard,
      properties: {
        text,
      },
      noPassword: true,
      isStacking: false,
    });
  }

  const callbackQuitGame = () => {
    Logger.infoMainWithKey('QuitGame');
    //! change Modal components (critical)
    stores.setGame(false);

    if (window && window.isNativeApp) {
      if (window.ReactNativeWebView)  {
        window.ReactNativeWebView.postMessage(JSON.stringify({ message: 'quit game', quitGame: true, storage: allStorage() }));
      }
      if (window.FlutterWebView)  {
        window.FlutterWebView.postMessage(JSON.stringify({ message: 'quit game', quitGame: true, storage: allStorage() }));
      }
    } else {
      window.open('/', '_self', 'noopener,noreferrer');
    }
  }

  const addEvents = () => {
    addEventListener('error', callbackError);
    addEventListener(UnityMethodsName.UpdateHoney, callbackUpdateHoney);
    addEventListener(UnityMethodsName.SignData, callbackSignData);
    addEventListener(UnityMethodsName.RequestHints, callbackRequestHints);
    addEventListener(UnityMethodsName.RequestProfilePlayer, callbackRequestProfilePlayer);
    addEventListener(UnityMethodsName.SendUnityAnalyticsEvent, callbackSendUnityAnalyticsEvent);
    addEventListener(UnityMethodsName.GetStatusOnboarding, callbackGetStatusOnboarding);
    addEventListener(UnityMethodsName.SetStatusOnboarding, callbackSetStatusOnboarding);
    addEventListener(UnityMethodsName.GetStarterOffers, callbackGetStarterOffers);
    addEventListener(UnityMethodsName.CheckBearAuthorization, callbackCheckBearAuthorization);
    addEventListener(UnityMethodsName.ClickButtonFullScreen, callbackClickButtonFullScreen);
    addEventListener(UnityMethodsName.CopyTextToClipboard, callbackCopyTextToClipboard);
    addEventListener(UnityMethodsName.QuitGame, callbackQuitGame);
  }

  const removeEvents = () => {
    removeEventListener('error', callbackError);
    removeEventListener(UnityMethodsName.UpdateHoney, callbackUpdateHoney);
    removeEventListener(UnityMethodsName.SignData, callbackSignData);
    removeEventListener(UnityMethodsName.RequestHints, callbackRequestHints);
    removeEventListener(UnityMethodsName.RequestProfilePlayer, callbackRequestProfilePlayer);
    removeEventListener(UnityMethodsName.SendUnityAnalyticsEvent, callbackSendUnityAnalyticsEvent);
    removeEventListener(UnityMethodsName.GetStatusOnboarding, callbackGetStatusOnboarding);
    removeEventListener(UnityMethodsName.SetStatusOnboarding, callbackSetStatusOnboarding);
    removeEventListener(UnityMethodsName.GetStarterOffers, callbackGetStarterOffers);
    removeEventListener(UnityMethodsName.CheckBearAuthorization, callbackCheckBearAuthorization);
    removeEventListener(UnityMethodsName.ClickButtonFullScreen, callbackClickButtonFullScreen);
    removeEventListener(UnityMethodsName.CopyTextToClipboard, callbackCopyTextToClipboard);
    removeEventListener(UnityMethodsName.QuitGame, callbackQuitGame);
  }

  return {
    addEvents,
    removeEvents
  }
};

/**
 * `List events for Farming Screen`
 */
const eventsFarming = ({ addEventListener, removeEventListener, setUnityListenersResponse }) => {
  const callbackInitializeFarm = async () => {
    Logger.infoMainWithKey('InitializeFarm');
    setUnityListenersResponse({
      name: UnityMethodsName.InitializeFarm,
      noPassword: true,
      isStacking: false,
    });
  }

  const callbackBuyAndReplaceProduct = async (type, kind, rowId, columnId) => {
    Logger.infoMainWithKey('BuyAndReplaceProduct');
    setUnityListenersResponse({
      name: UnityMethodsName.BuyAndReplaceProduct,
      properties: {
        type,
        kind,
        rowId,
        columnId,
      },
      noPassword: false,
      isStacking: false,
    });
  }

  const callbackSetBeeFromWarehouseInApiary = async (apiaryId, beeId, kind) => {
    Logger.infoMainWithKey('SetBeeFromWarehouseInApiary');
    setUnityListenersResponse({
      name: UnityMethodsName.SetBeeFromWarehouseInApiary,
      properties: {
        apiaryId,
        beeId,
        kind,
      },
      noPassword: false,
      isStacking: false,
    });
  }

  const callbackBuyBeeAndPlaceInApiary = async (apiaryId, kind) => {
    Logger.infoMainWithKey('BuyBeeAndPlaceInApiary');
    setUnityListenersResponse({
      name: UnityMethodsName.BuyBeeAndPlaceInApiary,
      properties: {
        apiaryId,
        kind,
      },
      noPassword: false,
      isStacking: false,
    });
  }

  const callbackBuyBeeAndPlaceInWarehouse = async (kind) => {
    Logger.infoMainWithKey('BuyBeeAndPlaceInWarehouse');
    setUnityListenersResponse({
      name: UnityMethodsName.BuyBeeAndPlaceInWarehouse,
      properties: {
        kind,
      },
      noPassword: false,
      isStacking: false,
    });
  }

  const callbackRemoveBeeFromApiary = async (apiaryId, beeId, kind) => {
    Logger.infoMainWithKey('RemoveBeeFromApiary');
    setUnityListenersResponse({
      name: UnityMethodsName.RemoveBeeFromApiary,
      properties: {
        apiaryId,
        beeId,
        kind,
      },
      noPassword: false,
      isStacking: false,
    });
  }

  const callbackDeleteFromField = async (id, type, kind) => {
    Logger.infoMainWithKey('DeleteFromField');
    setUnityListenersResponse({
      name: UnityMethodsName.DeleteFromField,
      properties: {
        id,
        type,
        kind,
      },
      noPassword: false,
      isStacking: false,
    });
  }

  const callbackSetObjOnField = async (id, rowId, columnId) => {
    Logger.infoMainWithKey('SetObjOnField');
    setUnityListenersResponse({
      name: UnityMethodsName.SetObjOnField,
      properties: {
        id,
        rowId,
        columnId,
      },
      noPassword: false,
      isStacking: false,
    });
  }

  const callbackReplaceProduct = async (id, rowId, columnId, newRowId, newColumnId) => {
    Logger.infoMainWithKey('ReplaceProduct');
    setUnityListenersResponse({
      name: UnityMethodsName.ReplaceProduct,
      properties: {
        id,
        rowId,
        columnId,
        newRowId,
        newColumnId,
      },
      noPassword: false,
      isStacking: false,
    });
  }

  const callbackCollectHoney = async (apiaryId) => {
    Logger.infoMainWithKey('CollectHoney');
    setUnityListenersResponse({
      name: UnityMethodsName.CollectHoney,
      properties: {
        apiaryId,
      },
      noPassword: false,
      isStacking: false,
    });
  }

  const callbackOpenApiary = async (apiaryId) => {
    Logger.infoMainWithKey('OpenApiary');
    setUnityListenersResponse({
      name: UnityMethodsName.OpenApiary,
      properties: {
        apiaryId,
      },
      noPassword: true,
      isStacking: false,
    });
  }

  const callbackOpenStore = async () => {
    Logger.infoMainWithKey('OpenStore');
    setUnityListenersResponse({
      name: UnityMethodsName.OpenStore,
      noPassword: true,
      isStacking: false,
    });
  }

  const callbackExpandLand = async () => {
    Logger.infoMainWithKey('ExpandLand');
    setUnityListenersResponse({
      name: UnityMethodsName.ExpandLand,
      noPassword: false,
      isStacking: false,
    });
  }

  const callbackBuySellingPack = async (packID) => {
    Logger.infoMainWithKey('BuySellingPack');
    setUnityListenersResponse({
      name: UnityMethodsName.BuySellingPack,
      properties: {
        packID,
      },
      noPassword: false,
      isStacking: false,
    });
  }

  const addEvents = () => {
    addEventListener(UnityMethodsName.InitializeFarm, callbackInitializeFarm);
    addEventListener(UnityMethodsName.BuyAndReplaceProduct, callbackBuyAndReplaceProduct);
    addEventListener(UnityMethodsName.SetBeeFromWarehouseInApiary, callbackSetBeeFromWarehouseInApiary);
    addEventListener(UnityMethodsName.BuyBeeAndPlaceInApiary, callbackBuyBeeAndPlaceInApiary);
    addEventListener(UnityMethodsName.BuyBeeAndPlaceInWarehouse, callbackBuyBeeAndPlaceInWarehouse);
    addEventListener(UnityMethodsName.RemoveBeeFromApiary, callbackRemoveBeeFromApiary);
    addEventListener(UnityMethodsName.DeleteFromField, callbackDeleteFromField);
    addEventListener(UnityMethodsName.SetObjOnField, callbackSetObjOnField);
    addEventListener(UnityMethodsName.ReplaceProduct, callbackReplaceProduct);
    addEventListener(UnityMethodsName.CollectHoney, callbackCollectHoney);
    addEventListener(UnityMethodsName.OpenApiary, callbackOpenApiary);
    addEventListener(UnityMethodsName.OpenStore, callbackOpenStore);
    addEventListener(UnityMethodsName.ExpandLand, callbackExpandLand);
    addEventListener(UnityMethodsName.BuySellingPack, callbackBuySellingPack);
  }

  const removeEvents = () => {
    removeEventListener(UnityMethodsName.InitializeFarm, callbackInitializeFarm);
    removeEventListener(UnityMethodsName.BuyAndReplaceProduct, callbackBuyAndReplaceProduct);
    removeEventListener(UnityMethodsName.SetBeeFromWarehouseInApiary, callbackSetBeeFromWarehouseInApiary);
    removeEventListener(UnityMethodsName.BuyBeeAndPlaceInApiary, callbackBuyBeeAndPlaceInApiary);
    removeEventListener(UnityMethodsName.BuyBeeAndPlaceInWarehouse, callbackBuyBeeAndPlaceInWarehouse);
    removeEventListener(UnityMethodsName.RemoveBeeFromApiary, callbackRemoveBeeFromApiary);
    removeEventListener(UnityMethodsName.DeleteFromField, callbackDeleteFromField);
    removeEventListener(UnityMethodsName.SetObjOnField, callbackSetObjOnField);
    removeEventListener(UnityMethodsName.ReplaceProduct, callbackReplaceProduct);
    removeEventListener(UnityMethodsName.CollectHoney, callbackCollectHoney);
    removeEventListener(UnityMethodsName.OpenApiary, callbackOpenApiary);
    removeEventListener(UnityMethodsName.OpenStore, callbackOpenStore);
    removeEventListener(UnityMethodsName.ExpandLand, callbackExpandLand);
    removeEventListener(UnityMethodsName.BuySellingPack, callbackBuySellingPack);
  }

  return {
    addEvents,
    removeEvents
  }
};

/**
 * `List events for Stacking/Unstacking Screen`
 */
const eventsStacking = ({ addEventListener, removeEventListener, setUnityListenersResponse }) => {
  const callbackOpenScreenStaking = async () => {
    Logger.infoMainWithKey('OpenScreenStaking');
    setUnityListenersResponse({
      name: UnityMethodsName.OpenScreenStaking,
      noPassword: true,
      isStacking: true,
    });
  }

  const callbackStakeCones = async (cone) => {
    Logger.infoMainWithKey('StakeCones');
    setUnityListenersResponse({
      name: 'StakeCones',
      properties: {
        cone,
      },
      noPassword: false,
      isStacking: true,
    });
  }

  const callbackCollectStakingHoney = async (honey) => {
    Logger.infoMainWithKey('CollectStakingHoney');
    setUnityListenersResponse({
      name: UnityMethodsName.CollectStakingHoney,
      properties: {
        honey,
      },
      noPassword: false,
      isStacking: true,
    });
  }

  const callbackUnstakeCones = async (cone, validatorAddress) => {
    Logger.infoMainWithKey('UnstakeCones');
    setUnityListenersResponse({
      name: 'UnstakeCones',
      properties: {
        cone,
        validatorAddress
      },
      noPassword: false,
      isStacking: true,
    });
  }

  const callbackShowStakingPopup = async () => {
    Logger.infoMainWithKey('ShowStakingPopup');
    stores.setOpenModal(ENUM_MODAL.UNITY_STACKING);
    stores.setPropsForModal({ isUnstake: false });
  }

  const callbackShowUnstakingPopup = async () => {
    Logger.infoMainWithKey('ShowUnstakingPopup');
    stores.setPropsForModal({ isUnstake: true });
    stores.setOpenModal(ENUM_MODAL.UNITY_UNSTACKING);
  }

  const addEvents = () => {
    addEventListener(UnityMethodsName.OpenScreenStaking, callbackOpenScreenStaking);
    addEventListener(UnityMethodsName.StakeCones, callbackStakeCones);
    addEventListener(UnityMethodsName.CollectStakingHoney, callbackCollectStakingHoney);
    addEventListener(UnityMethodsName.UnstakeCones, callbackUnstakeCones);
    addEventListener(UnityMethodsName.ShowStakingPopup, callbackShowStakingPopup);
    addEventListener(UnityMethodsName.ShowUnstakingPopup, callbackShowUnstakingPopup);
  }

  const removeEvents = () => {
    removeEventListener(UnityMethodsName.OpenScreenStaking, callbackOpenScreenStaking);
    removeEventListener(UnityMethodsName.StakeCones, callbackStakeCones);
    removeEventListener(UnityMethodsName.CollectStakingHoney, callbackCollectStakingHoney);
    removeEventListener(UnityMethodsName.UnstakeCones, callbackUnstakeCones);
    removeEventListener(UnityMethodsName.ShowStakingPopup, callbackShowStakingPopup);
    removeEventListener(UnityMethodsName.ShowUnstakingPopup, callbackShowUnstakingPopup);
  }

  return {
    addEvents,
    removeEvents
  }
};

/**
 * `List events for Leader Board Screen`
 */
const eventsLeaderBoard = ({ addEventListener, removeEventListener, setUnityListenersResponse }) => {
  const callbackRequestLeaderBoard = async (type, index) => {
    Logger.infoMainWithKey('RequestLeaderBoard');
    setUnityListenersResponse({
      name: UnityMethodsName.RequestLeaderBoard,
      properties: {
        type,
        index,
      },
      noPassword: true,
      isStacking: false,
    });
  }

  const callbackRequestAvatarPlayers = async (playersData) => {
    Logger.infoMainWithKey('RequestAvatarPlayers', playersData);
    setUnityListenersResponse({
      name: UnityMethodsName.RequestAvatarPlayers,
      properties: {
        playersData,
      },
      noPassword: true,
      isStacking: false,
    });
  }

  const addEvents = () => {
    addEventListener(UnityMethodsName.RequestLeaderBoard, callbackRequestLeaderBoard);
    addEventListener(UnityMethodsName.RequestAvatarPlayers, callbackRequestAvatarPlayers);
  }

  const removeEvents = () => {
    removeEventListener(UnityMethodsName.RequestLeaderBoard, callbackRequestLeaderBoard);
    removeEventListener(UnityMethodsName.RequestAvatarPlayers, callbackRequestAvatarPlayers);
  }

  return {
    addEvents,
    removeEvents
  }
};

/**
 * `List events for Daily Bonus`
 */
const eventsBonus = ({ addEventListener, removeEventListener, setUnityListenersResponse }) => {
  const callbackReceiveBonus = async (nonce) => {
    Logger.infoMainWithKey('ReceiveBonus', nonce);
    setUnityListenersResponse({
      name: 'ReceiveBonus',
      noPassword: false,
      isStacking: false,
    });
  }

  const addEvents = () => {
    addEventListener('ReceiveBonus', callbackReceiveBonus);
  }

  const removeEvents = () => {
    removeEventListener('ReceiveBonus', callbackReceiveBonus);
  }

  return {
    addEvents,
    removeEvents
  }
};

/**
 * `List events for Battle Match3`
 */
const eventsMatch3 = ({ addEventListener, removeEventListener, setUnityListenersResponse }) => {
  const callbackTournamentCurrent = async () => {
    Logger.infoMainWithKey('TournamentCurrent');
    setUnityListenersResponse({
      name: UnityMethodsName.TournamentCurrent,
      noPassword: true,
      isStacking: false,
    });
  }

  const callbackTournamentNext = async () => {
    Logger.infoMainWithKey('TournamentNext');
    setUnityListenersResponse({
      name: UnityMethodsName.TournamentNext,
      noPassword: true,
      isStacking: false,
    });
  }

  const callbackTournamentPrev = async (nonce) => {
    Logger.infoMainWithKey('TournamentPrev');
    setUnityListenersResponse({
      name: UnityMethodsName.TournamentPrev,
      noPassword: true,
      isStacking: false,
    });
  }

  const callbackAuthConnectLobby = async (nonce) => {
    Logger.infoMainWithKey('AuthConnectLobby', nonce);
    setUnityListenersResponse({
      name: UnityMethodsName.AuthConnectLobby,
      noPassword: false,
      properties: {
        nonce,
      },
      isStacking: false,
    });
  }

  const callbackSigningTransaction = async (toAddress, amount) => {
    Logger.infoMainWithKey('SigningTransaction with data', toAddress, ', ', amount);
    setUnityListenersResponse({
      name: UnityMethodsName.SigningTransaction,
      properties: {
        toAddress,
        amount,
      },
      noPassword: false,
      isStacking: false,
    });
  }

  const callbackRequestProfileOpponent = async (address) => {
    Logger.infoMainWithKey('RequestProfileOpponent', address);
    setUnityListenersResponse({
      name: UnityMethodsName.RequestProfileOpponent,
      properties: {
        address,
      },
      noPassword: true,
      isStacking: false,
    });
  }

  const callbackGetEmojiList = async () => {
    Logger.infoMainWithKey('GetEmojiList');
    setUnityListenersResponse({
      name: UnityMethodsName.GetEmojiList,
      noPassword: true,
      isStacking: false,
    });
  }

  const callbackPlayMatch3 = async () => {
    Logger.infoMainWithKey('PlayMatch3');
    stores.unityStore.setPlayMatch3(true);
  }

  const callbackExitMatch3 = async () => {
    Logger.infoMainWithKey('ExitMatch3');
    stores.unityStore.setPlayMatch3(false);
  }

  const addEvents = () => {
    addEventListener(UnityMethodsName.TournamentCurrent, callbackTournamentCurrent);
    addEventListener(UnityMethodsName.TournamentNext, callbackTournamentNext);
    addEventListener(UnityMethodsName.TournamentPrev, callbackTournamentPrev);
    addEventListener(UnityMethodsName.AuthConnectLobby, callbackAuthConnectLobby);
    addEventListener(UnityMethodsName.SigningTransaction, callbackSigningTransaction);
    addEventListener(UnityMethodsName.RequestProfileOpponent, callbackRequestProfileOpponent);
    addEventListener(UnityMethodsName.GetEmojiList, callbackGetEmojiList);
    addEventListener(UnityMethodsName.PlayMatch3, callbackPlayMatch3);
    addEventListener(UnityMethodsName.ExitMatch3, callbackExitMatch3);
  }

  const removeEvents = () => {
    removeEventListener(UnityMethodsName.TournamentCurrent, callbackTournamentCurrent);
    removeEventListener(UnityMethodsName.TournamentNext, callbackTournamentNext);
    removeEventListener(UnityMethodsName.TournamentPrev, callbackTournamentPrev);
    removeEventListener(UnityMethodsName.AuthConnectLobby, callbackAuthConnectLobby);
    removeEventListener(UnityMethodsName.SigningTransaction, callbackSigningTransaction);
    removeEventListener(UnityMethodsName.RequestProfileOpponent, callbackRequestProfileOpponent);
    removeEventListener(UnityMethodsName.GetEmojiList, callbackGetEmojiList);
    removeEventListener(UnityMethodsName.PlayMatch3, callbackPlayMatch3);
    removeEventListener(UnityMethodsName.ExitMatch3, callbackExitMatch3);
  }

  return {
    addEvents,
    removeEvents
  }
};

export default {
  events,
  eventsFarming,
  eventsStacking,
  eventsLeaderBoard,
  eventsBonus,
  eventsMatch3,
};
