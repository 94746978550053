import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'src/stores';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import SocialNetwork from '@components/SocialNetwork';
import { useMediaQuery } from 'src/helpers/media';
import { Button, ColorType, SizeButton } from 'src/components_new/Button';
import { ENUM_MODAL } from '@components_new/Modal/enumModal';
import { GOOGLE_ANALYTICS } from '@src/constants/googleAnalyticsEvents';
import { gtagEvent } from '@utils/common';
import TitleSection from '@src/components/TitleSection';
import HoneyWoodMonetaLogo from '@assets/common/honeywood_moneta_logo.png';
import BackgroundMobile from '@assets/Mobile/HomePage/CapitalSection/capital_section_image_mobile.jpg';
import BackgroundTablet from '@assets/Mobile/HomePage/CapitalSection/capital_section_image_tablet.jpg';
import BackgroundDesktop from '@assets/Mobile/HomePage/CapitalSection/capital_section_image_desktop.jpg';
import Logger from '@utils/logger';
import styles from './mobileCapital.module.scss';

const CapitalSection = observer(() => {
  const navigate = useNavigate();
  const stores = useStores();
  const _ = stores.language;
  const isLessThan1300px = useMediaQuery('(max-width: 1300px)');
  
  const gameClick = () => {
    navigate('/game');
  };

  const joinClick = () => {
    stores.setOpenModal(ENUM_MODAL.JOIN);
  };

  return (
    <div className={styles.Container}>
      <div className={styles.BackgroundImagesContainer}>
        <img
          onLoad={() => stores.increaseDownloadedImageCounter(BackgroundMobile)}
          onError={() => Logger.error('Error with image loading')}
          src={BackgroundMobile}
          alt="capital_section_image_mobile"
          className={styles.BackgroundMobileImage}
        />
        <img
          onLoad={() => stores.increaseDownloadedImageCounter(BackgroundTablet)}
          onError={() => Logger.error('Error with image loading')}
          src={BackgroundTablet}
          alt="capital_section_image_tablet"
          className={styles.BackgroundTabletImage}
        />
        <img
          onLoad={() => stores.increaseDownloadedImageCounter(BackgroundDesktop)}
          onError={() => Logger.error('Error with image loading')}
          src={BackgroundDesktop}
          alt="capital_section_image_desktop"
          className={styles.BackgroundDesktopImage}
        />
      </div>
      <div className={styles.InfoContainer}>
        <div className={styles.HoneyWoodLogoContainer}>
          <img
            onLoad={() => stores.increaseDownloadedImageCounter(HoneyWoodMonetaLogo)}
            onError={() => Logger.error('Error with image loading')}
            src={HoneyWoodMonetaLogo}
            alt="honeywood_moneta_logo"
            className={styles.HoneyWoodLogo}
          />
        </div>
        <div className={styles.TitleContainer}>
          <TitleSection
            title={stores.translate('blockchain_game')}
            textHtml={stores.translate('release_description_mobile')}
            classNames={{ Title: isLessThan1300px ? undefined : styles.Title }}
          />
          <SocialNetwork classNames={{ Container: styles.SocialIconsContainer }} capitalSection />
          <Button
            colorType={ColorType.GREEN}
            size={isLessThan1300px ? SizeButton.MEDIUM : SizeButton.LARGE}
            title={stores.translate('play')}
            classNames={{
              Container: styles.ButtonContainer,
              Title: classnames(
                stores.browserInfo.isAndroid
                  ? styles.TitleGreenButton__android
                  : styles.TitleGreenButton,
              ),
            }}
            onClick={() => {
              gtagEvent(GOOGLE_ANALYTICS.CLICK_PLAY);
              if (stores.isAuthorized) {
                gameClick();
              } else {
                joinClick();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
});

export default CapitalSection;
