import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { sanitize } from 'dompurify';
import { useStores } from 'src/stores';
import { observer } from 'mobx-react';
import { Button, ColorType, SizeButton } from '@components_new/Button';
import CheckBox from '@components/CheckBox';
import CloseNew from '@src/assets/control/close_new_icon.svg';
import Logger from '@utils/logger';
import styles from './cookiesManageModal.module.scss';

const CookiesManageModal = observer(() => {
  const stores = useStores();
  const [selectedAnalyticsCookies, setSelectedAnalyticsCookies] = useState(true);
  const [selectedFunctionalityCookies, setSelectedFunctionalityCookies] = useState(true);
  const [selectedAdvertisementCookies, setSelectedAdvertisementCookies] = useState(true);

  useEffect(() => {
    if (stores.isOpenCookiesManageModal) {
      /* Dynamically lock scrolling background */
      setTimeout(() => {
        document.body.style.overflowY = 'hidden';
      }, 100);
    }
    return () => {
      /* Dynamically unlock scrolling background when close CookiesManageModal */
      document.body.style.overflowY = 'initial';
    };
  }, [stores.isOpenCookiesManageModal]);

  return (
    <div className={styles.CookiesManageModalWrapper}>
      <div className={styles.CookiesManageModalContainer}>
        <button
          type="button"
          className={styles.CookiesManageModalClose}
          onClick={() => {
            stores.setOpenCookiesManageModal(false);
            stores.setOpenCookiesPopup(true);
          }}
        >
          <img
            onLoad={() => stores.increaseDownloadedImageCounter(CloseNew)}
            onError={() => Logger.error('Error with image loading')}
            src={CloseNew}
            alt="close_new_icon"
          />
        </button>

        <div className={styles.CookiesManageModalItem}>
          <CheckBox
            enabled
            title={stores.translate('necessary_cookies')}
            classNames={{
              Container: styles.CheckBoxContainer,
              Title: styles.CheckBoxTitle,
            }}
          />
          <p
            className={styles.CookiesItemText}
            dangerouslySetInnerHTML={{
              __html: sanitize(stores.translate('necessary_cookies_text')),
            }}
          />
        </div>

        <div className={styles.CookiesManageModalItem}>
          <CheckBox
            selected={selectedAnalyticsCookies}
            title={stores.translate('analytics_cookies')}
            onChange={() => {
              setSelectedAnalyticsCookies(!selectedAnalyticsCookies);
            }}
            classNames={{
              Container: styles.CheckBoxContainer,
              Title: styles.CheckBoxTitle,
            }}
          />
          <p
            className={styles.CookiesItemText}
            dangerouslySetInnerHTML={{
              __html: sanitize(stores.translate('analytics_cookies_text')),
            }}
          />
        </div>

        <div className={styles.CookiesManageModalItem}>
          <CheckBox
            selected={selectedFunctionalityCookies}
            title={stores.translate('performance_and_functionality_cookies')}
            onChange={() => {
              setSelectedFunctionalityCookies(!selectedFunctionalityCookies);
            }}
            classNames={{
              Container: styles.CheckBoxContainer,
              Title: styles.CheckBoxTitle,
            }}
          />
          <p
            className={styles.CookiesItemText}
            dangerouslySetInnerHTML={{
              __html: sanitize(stores.translate('performance_and_functionality_cookies_text')),
            }}
          />
        </div>

        <div className={styles.CookiesManageModalItem}>
          <CheckBox
            selected={selectedAdvertisementCookies}
            title={stores.translate('advertisement_cookies')}
            onChange={() => {
              setSelectedAdvertisementCookies(!selectedAdvertisementCookies);
            }}
            classNames={{
              Container: styles.CheckBoxContainer,
              Title: styles.CheckBoxTitle,
            }}
          />
          <p
            className={styles.CookiesItemText}
            dangerouslySetInnerHTML={{
              __html: sanitize(stores.translate('advertisement_cookies_text')),
            }}
          />
        </div>

        <div className={styles.ButtonsContainer}>
          <Button
            colorType={ColorType.GREEN}
            size={SizeButton.SMALL}
            onClick={() => {
              stores.setOpenCookiesManageModal(false);
              window.localStorage.setItem('isCookiesAccepted', true);
            }}
            title={stores.translate('confirm')}
            classNames={{
              Container: styles.ButtonContainer,
              Button: styles.AcceptButton,
              Title: classnames(styles.ButtonTitle, styles.ButtonTitle__Green),
            }}
          />
        </div>
      </div>
    </div>
  );
});

export default CookiesManageModal;
