import React from 'react';
import { observer } from 'mobx-react';
import Layout from 'src/layout/Layout';
import CapitalSection from '../MobilePartnershipPage/components/CapitalSection';
import ArticlesSection from '../MobilePartnershipPage/components/ArticlesSection';
import PartnersSection from '../../HomePage/MobileHomePage/components/PartnersSection';
import styles from './partnershipDesktop.module.scss';

const DesktopPartnershipPage = observer(({ isInitialLoading = true }) => {
  return (
    <Layout isInitialLoading={isInitialLoading} isLoadingFinish={!isInitialLoading}>
      <CapitalSection />
      <PartnersSection classNames={{ Container: styles.Container }} />
      <ArticlesSection />
    </Layout>
  );
});

export default DesktopPartnershipPage;
