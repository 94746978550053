import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'src/stores';

import { checkPasswordAuth } from '@utils/authorization';
import { TYPE_AUTH_CLIENT } from '@constants/blockchain';
import MainHeader from './components/MainHeader';

const POSITION_Y_SHOW_PAGE_UP = window.screen.availHeight - 200;

const DesktopHeader = observer(({ withAvatar, states, methods, isAccount = false }) => {
  const stores = useStores();
  const _ = stores.language;

  const [isVerificationAccountAccessPopUpOpen, setVerificationAccountAccessPopUpOpen] = useState(
    false,
  );

  useEffect(() => {
    const myScrollFunc = () => {
      const y = window.scrollY;

      if (y >= POSITION_Y_SHOW_PAGE_UP) {
        stores.setShowPageUpButton(true);
      } else {
        stores.setShowPageUpButton(false);
      }
    };

    window.addEventListener('scroll', myScrollFunc);

    return () => {
      window.removeEventListener('scroll', myScrollFunc);
    };
  }, []);

  const finishUnlockAccount = () => {
    setVerificationAccountAccessPopUpOpen(false);
    methods.setPlayerMenuOpen(true);
  };

  /* useEffect: unlock account if use Keplr */
  useEffect(() => {
    (async () => {
      if (stores.isAuthorized && stores.typeAuthClient === TYPE_AUTH_CLIENT.KEPLR) {
        await checkPasswordAuth({
          finish: (isSuccess) => {
            if (isSuccess) {
              setVerificationAccountAccessPopUpOpen(false);
            }
          },
        });
      }
    })();
  }, [stores.isAuthorized]);

  return (
    <MainHeader
      withAvatar={withAvatar}
      isAccount={isAccount}
      stores={stores}
      states={{
        ...states,
        isVerificationAccountAccessPopUpOpen,
      }}
      methods={{
        ...methods,
        setVerificationAccountAccessPopUpOpen,
        finishUnlockAccount,
      }}
    />
  );
});

export default DesktopHeader;
