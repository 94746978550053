import React from 'react';
import { types } from 'mobx-state-tree';
// import { UnityContext } from 'react-unity-webgl';

import _ from 'lodash';
import { stringify } from '@utils/common';
import Logger from '@utils/logger';

export const DEFAULT_HINT = {
  START: {
    "ru": "Каждую неделю мы проводим PvP турнир о котором объявляем в чатах комьюнити",
    "en": "Every week we hold PvP tournaments that are announced in the community chats"
  },
  MATCH3: {
    "ru": "Собирая 4 фрукта или больше одного цвета, ты получаешь дополнительный ход",
    "en": "Matching 4 or more fruits of the same color you get extra move"
  }
}

export const GameObjectNames = {
  DataManager: 'DataManager',
  TransactionManager: 'TransactionManager',
  MainMenu: 'MainMenu',
  GameManager: 'GameManager',
  FarmInitialization: 'FarmInitialization',
  AuthorizationObject: 'AuthorizationObject',
  LobbyReact: 'LobbyReact',
  LeaderBoard: 'LeaderBoard',
  DailyBonus: 'DailyBonus',
  ReactHandler: 'ReactHandler',

  FarmReact: 'FarmReact',
  BattleReact: 'BattleReact',
};

export const GameMethodNames = {
  // Main
  InitialObj: 'InitialObj',
  InitialProduct: 'InitialProduct',
  UpdateHoney: 'UpdateHoney',
  UpdateHoneyInApiary: 'UpdateHoneyInApiary',
  UpdateHoneyBar: 'UpdateHoneyBar',
  UpdateCone: 'UpdateCone',
  UpdateOxy: 'UpdateOxy',
  UpdateStatusOnboarding: 'UpdateStatusOnboarding',
  ShowExceptionMessage: 'ShowExceptionMessage',
  LaunchFarm: 'LaunchFarm',
  BearAuthorized: 'BearAuthorized',
  ResponseSignData: 'ResponseSignData',
  // Apiary
  UpdateCountBeeOnApiary: 'UpdateCountBeeOnApiary',
  SuccessfullySetBeeApiary: 'SuccessfullySetBeeApiary',
  InitialWarehouse: 'InitialWarehouse',
  SuccessfullyRemoveBee: 'SuccessfullyRemoveBee',
  // SuccessfullyDelivered: 'SuccessfullyDelivered',
  SuccessfullyReplace: 'SuccessfullyReplace', // SuccessfullyDelivered
  AddBeeApiary: 'AddBeeApiary',
  UpdateApiaryInfo:'UpdateApiaryInfo',
  SuccessfullyCollectHoney: 'SuccessfullyCollectHoney',
  InitializeGrounds: 'InitializeGrounds',
  RemoveObjectFromField: 'RemoveObjectFromField',
  UpdateBeeData: 'UpdateBeeData',
  UpdateApiaryData: 'UpdateApiaryData',
  UpdateTreeData: 'UpdateTreeData',
  ShowFullScreenButton: 'ShowFullScreenButton',
  InitProfilePlayer: 'InitProfilePlayer',

  InitProfileOpponent: 'InitProfileOpponent',

  UpdateScreenStacking: 'UpdateScreenStacking',
  RemovePopupStacking: 'RemovePopupStacking',
  RemovePopupStore: 'RemovePopupStore',
  FinishTransaction: 'FinishTransaction',
  ShowPopupStacking: 'ShowPopupStacking',
  ProblemSigningTransaction: 'ProblemSigningTransaction',
  SuccessfulSigning: 'SuccessfulSigning',
  SuccessfulAuthLobby: 'SuccessfulAuthLobby',
  SuccessfulReceiveBonus: 'SuccessfulReceiveBonus',

  UpdateLeaderBoard: 'UpdateLeaderBoard',
  UpdateAvatarPlayers: 'UpdateAvatarPlayers',
  PlayerRatingOnLeaderBoard: 'PlayerRatingOnLeaderBoard',
  UpdateHints: 'UpdateHints',
  TournamentCurrentInfo: 'TournamentCurrentInfo',
  TournamentNextInfo: 'TournamentNextInfo',
  TournamentPrevInfo: 'TournamentPrevInfo',
  SetEmojiList: 'SetEmojiList',
  SetStarterOffers: 'SetStarterOffers',
  SetInvoicePayment: 'SetInvoicePayment',

  // Exceptions
  ExceptionCollectHoney: 'ExceptionCollectHoney',
  ExceptionReplace: 'ExceptionReplace',
  ExceptionCreateBee: 'ExceptionCreateBee',
  ExceptionSetBeeApiary: 'ExceptionSetBeeApiary',
  ExceptionRemoveBee: 'ExceptionRemoveBee',
  // ExceptionAddBeeApiary: 'ExceptionAddBeeApiary',
  ExceptionExpandField: 'ExceptionExpandField',
  ExceptionRemoveObjectFromField: 'ExceptionRemoveObjectFromField',
};

export const UnityMethodsName = {
  RequestProfilePlayer: 'RequestProfilePlayer',
  GetStatusOnboarding: 'GetStatusOnboarding',
  SetStatusOnboarding: 'SetStatusOnboarding',
  SendUnityAnalyticsEvent: 'SendUnityAnalyticsEvent',
  RequestHints: 'RequestHints',
  UpdateHoney: 'UpdateHoney',
  SignData: 'SignData',
  // Match3
  SigningTransaction: 'SigningTransaction',
  RequestProfileOpponent: 'RequestProfileOpponent',
  CheckBearAuthorization: 'CheckBearAuthorization',
  AuthConnectLobby: 'AuthConnectLobby',
  PlayMatch3: 'PlayMatch3',
  ExitMatch3: 'ExitMatch3',
  // Farming
  InitializeFarm: 'InitializeFarm',
  // BuyAndDeliver_Product: 'BuyAndDeliver_Product',
  BuyAndReplaceProduct: 'BuyAndReplaceProduct', // BuyAndDeliver_Product
  // SetBeeInApiary: 'SetBeeInApiary',
  SetBeeFromWarehouseInApiary: 'SetBeeFromWarehouseInApiary', // SetBeeInApiary
  // CreateAndSetBeeInApiary: 'CreateAndSetBeeInApiary',
  BuyBeeAndPlaceInApiary: 'BuyBeeAndPlaceInApiary', // CreateAndSetBeeInApiary
  // BuyBee: 'BuyBee',
  BuyBeeAndPlaceInWarehouse: 'BuyBeeAndPlaceInWarehouse', // BuyBee
  RemoveBeeFromApiary: 'RemoveBeeFromApiary',
  DeleteFromField: 'DeleteFromField',
  SetObjOnField: 'SetObjOnField',
  // Delivery_Product: 'Delivery_Product',
  ReplaceProduct: 'ReplaceProduct', // Delivery_Product
  CollectHoney: 'CollectHoney',
  OpenApiary: 'OpenApiary',
  OpenStore: 'OpenStore',
  ExpandLand: 'ExpandLand',
  BuySellingPack: 'BuySellingPack',
  // Stacking
  OpenScreenStaking: 'OpenScreenStaking',
  StakeCones: 'StakeCones',
  UnstakeCones: 'UnstakeCones',
  CollectStakingHoney: 'CollectStakingHoney',
  ShowStakingPopup: 'ShowStakingPopup',
  ShowUnstakingPopup: 'ShowUnstakingPopup',

  RequestLeaderBoard: 'RequestLeaderBoard',
  RequestAvatarPlayers: 'RequestAvatarPlayers',
  ClickButtonFullScreen: 'ClickButtonFullScreen',
  CopyTextToClipboard: 'CopyTextToClipboard',
  QuitGame: 'QuitGame',
  TournamentCurrent: 'TournamentCurrent',
  TournamentNext: 'TournamentNext',
  TournamentPrev: 'TournamentPrev',
  GetEmojiList: 'GetEmojiList',
  GetStarterOffers: 'GetStarterOffers',
  GetInvoicePayment: 'GetInvoicePayment',
}

export const UnityStore = types
  .model({
    id: types.identifier,
    bearId: types.optional(types.number, -1),
    fieldId: types.optional(types.number, -1),
    blocksPerHour: types.optional(types.number, 0),
    powerMaxBee: types.optional(types.number, 0),
    address: types.optional(types.frozen(null)),
    nickName: types.optional(types.frozen(null)),
    isStartGame: types.optional(types.boolean, false),
    isPlayMatch3: types.optional(types.boolean, false),
    isEnteringFarming: types.optional(types.boolean, false),
    availableFullScreen: types.optional(types.boolean, false),
    stakedEntriesMax: types.optional(types.number, 0),
    countConesForStacking: types.optional(types.number, 0),
    countConesForUnstacking: types.optional(types.number, 0),
    arrayConesForUnstacking: types.optional(types.frozen(null)),
    // after closed ModalScreen
    approveTransaction: types.optional(types.frozen(null)),
    dailyBonus: types.optional(types.frozen(null)),
    conesStacking: types.optional(types.number, 0),
    objectUnstacking: types.optional(types.frozen(null)),
    isSuccessBurnCoins: types.optional(types.boolean, false),
  })
  .volatile((self) => ({
    unityContext: null
  }))
  .actions((self) => ({
    createContext() {
    //   const unityContext = new UnityContext({
    //     streamingAssetsUrl: 'StreamingAssets',
    //     companyName: 'MonetaCompany',
    //     productName: 'ReactAndUnityMoneta',
    //     productVersion: '1.0',
        
    //     loaderUrl: '/Build/demo.loader.js',
    //     dataUrl: '/Build/demo.data.unityweb',
    //     frameworkUrl: '/Build/demo.framework.js.unityweb',
    //     codeUrl: '/Build/demo.wasm.unityweb',
    //     webGLContextAttributes: {
    //       alpha: true,
    //       antialias: true,
    //       depth: true,
    //       failIfMajorPerformanceCaveat: true,
    //       powerPreference: "high-performance",
    //       premultipliedAlpha: true,
    //       preserveDrawingBuffer: true,
    //       stencil: true,
    //       desynchronized: true,
    //       xrCompatible: true,
    //     },
    //   });
    //   self.setUnityContext(unityContext)
    },
    setUnityContext(unityContext) {
      self.unityContext = unityContext;
    },
    setStartGame(isStartGame) {
      self.isStartGame = isStartGame;
    },
    setPlayMatch3(isPlayMatch3) {
      self.isPlayMatch3 = isPlayMatch3;
    },
    setBearId(bearId) {
      self.bearId = bearId;
    },
    setFieldId(fieldId) {
      self.fieldId = fieldId;
    },
    setBlocksPerHour(blocksPerHour) {
      self.blocksPerHour = blocksPerHour;
    },
    setPowerMaxBee(powerMaxBee) {
      self.powerMaxBee = powerMaxBee;
    },
    setAddress(address) {
      self.address = address;
    },
    setNickname(nickName) {
      self.nickName = nickName
    },
    setAvailableFullScreen(availableFullScreen) {
      self.availableFullScreen = availableFullScreen;
    },
    setEnteringFarming(isEnteringFarming) {
      self.isEnteringFarming = isEnteringFarming;
    },
    setStakedEntriesMax(stakedEntriesMax) {
      self.stakedEntriesMax = stakedEntriesMax;
    },
    setCountConesForStacking(countConesForStacking) {
      self.countConesForStacking = countConesForStacking;
    },
    setCountConesForUnstacking(countConesForUnstacking) {
      self.countConesForUnstacking = countConesForUnstacking
    },
    setArrayConesForUnstacking(arrayConesForUnstacking) {
      self.arrayConesForUnstacking = arrayConesForUnstacking
    },
    setApproveTransaction(approveTransaction) {
      self.approveTransaction = approveTransaction;
    },
    setDailyBonus(dailyBonus) {
      self.dailyBonus = dailyBonus;
    },
    setConesStacking(conesStacking) {
      self.conesStacking = conesStacking;
    },
    setObjectUnstacking(objectUnstacking) {
      self.objectUnstacking = objectUnstacking;
    },
    setSuccessBurnCoins(isSuccessBurnCoins) {
      self.isSuccessBurnCoins = isSuccessBurnCoins;
    },
    /**
     * 
     * @param {*} objectName - Object Name in Unity 
     * @param {*} methodName - Method Name in Unity 
     * @param {*} values - values wrapped in stringify, example `stringify({ key1: value1,.. })`
     * @param {*} isLogger - flag to enable/disable values logging
     */
    send({ objectName, methodName, values = undefined, isLogger = true, isLoggerInProduction = false }) {
      if (isLogger) {
        if (isLoggerInProduction) { // production
          Logger.infoMainWithKey(`${methodName}`, values, { useInProduction: true });
        } else { // development
          Logger.infoMainWithKey(`${methodName}`, values);
        }
      }
      if (self.unityContext) {
        self.unityContext.sendMessage(objectName, methodName, values);
      } else {
        Logger.error(new Error('unityContext no exist'));
      }
    },
    sendExceptionError({ code, message }) {
      if (self.unityContext) {
        self.unityContext.sendMessage(
          GameObjectNames.LobbyReact,
          GameMethodNames.ShowExceptionMessage,
          stringify({ code, message }),
        );
      } else {
        Logger.error(new Error('unityContext no exist'));
      }
    },
  }))
  .views((self) => ({
  }));

export default {
  DEFAULT_HINT,
  GameObjectNames,
  GameMethodNames,
  UnityMethodsName,
  UnityStore,
}