/* eslint-disable */
import { DirectSecp256k1HdWallet } from "@cosmjs/proto-signing";
const tx_2 = require("cosmjs-types/cosmos/staking/v1beta1/tx");
const tx_1 = require("cosmjs-types/cosmos/distribution/v1beta1/tx");
import { SigningStargateClient, GasPrice, calculateFee, coins, coin, QueryClient } from "@cosmjs/stargate";
import { stringToPath } from "@cosmjs/crypto";
import { Decimal } from "@cosmjs/math";
import { TxRaw } from "cosmjs-types/cosmos/tx/v1beta1/tx";
import { toHex } from "@cosmjs/encoding";
import async from "async";
import _ from "lodash";
import axios from "axios";
import Logger from "@utils/logger";

import { StoreInstance } from 'src/stores';
import methodsBears, { getFee } from "@utils/methodsBears";
import { MILLION, URL_SERVER_MAIN } from "@constants/system";
import { URL_MATCH3_SEND } from '@constants/blockchain';

// let queue = undefined;

const checkClientExist = (isCLient) => {
  if (!isCLient) {
    throw new Error('no exist client of blockchain');
  }
}
// from NativeApp `MonetaToday`
const asyncNativeAppSign = async (startTime) => {
  return new Promise((resolve, reject) => {
    const intervalId = setInterval(() => {
      const tx = window.signTx;
      // console.log('signTx >> ', { window, signTx });
      if (tx) {
        // window.ReactNativeWebView.postMessage(JSON.stringify({ message: 'signTx='+tx}));
        clearInterval(intervalId);
        window.signTx = '';
        resolve(tx);
        // resolve(signTx);
      } else if (Date.now() - startTime > 4000) {
        clearInterval(intervalId);
        // console.log("Превышено время ожидания")
        resolve(undefined);
      }
    }, 50);
  });
};

export const customSignOnly = async ({ address, msg = [], fee, cosmos = false }) => {
  const { data: signerData } = await axios.get(`${URL_SERVER_MAIN}/api/proxy/transactions/signerData?address=${address}`);

  let txString;
  let enabledNativeSign = window.enabledNativeSign;
  if (!StoreInstance.isNativeApp || !enabledNativeSign) {
    let client;
    if (cosmos) {
      client = StoreInstance.authorizedClient.cosmos;
    } else {
      client = StoreInstance.authorizedClient.bearsTxs.client;
    }
    const tx = await client.sign(address, msg, fee, '', signerData);
    const txEncoded = TxRaw.encode(tx).finish();
    txString = toHex(txEncoded);
  } else { // NativeApp `MonetaToday`
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ address, msg, fee, signerData, cosmos }));
      let startTime = Date.now();
      txString = await asyncNativeAppSign(startTime);
    }
  }

  return {
    tx: txString,
    pubkey: toHex(StoreInstance.profileAvailable.account.pubkey),
  }
}

export const createQueue = () => {
  const queue = async.priorityQueue(async.asyncify(async ({ address, msg = [], fee, justSign = false }) => {
    try {
      // console.log('params in queue >> ', { address, msg, fee, justSign })
      const { data: signerData } = await axios.get(`${URL_SERVER_MAIN}/api/proxy/transactions/signerData?address=${address}`);
      // const { data: signerData } = await axios.get(`/api/proxy/transactions/signerData?address=${address}`);
      // console.log('transactions/signerData >> ', signerData);
      if (!signerData) {
        Logger.infoMainNotify({ nameNotify: 'Server', section: 'signerData', meta: { signerData }, useInProduction: true });
      }
      let txString;
      let enabledNativeSign = window.enabledNativeSign;
      if (!StoreInstance.isNativeApp || !enabledNativeSign) {
        // console.log('[Web] >>> ');
        const signTx = await StoreInstance.authorizedClient.bearsTxs.client.sign(address, msg, fee, '', signerData);
        const txEncoded = TxRaw.encode(signTx).finish();
        txString = toHex(txEncoded);
      } else { // NativeApp `MonetaToday`
        if (window.ReactNativeWebView) {
          // console.log('[NativeApp] >>> ');
          // window.ReactNativeWebView.postMessage(JSON.stringify({ message: '[NativeApp] signTx >>>'}));
          window.ReactNativeWebView.postMessage(JSON.stringify({ address, msg, fee, signerData, cosmos: false }));
          let startTime = Date.now();
          txString = await asyncNativeAppSign(startTime);
        }
      }
      // console.log('continue signTx >>> ');
      const tx = {
        tx: txString,
      };
      // console.log('queue tx >> ', tx);

      if (justSign) {
        return tx;
      }
  
      const resultPushTransaction = await axios.post(
        `${URL_SERVER_MAIN}/api/proxy/transactions/push`, 
        JSON.stringify(tx),
        {
          headers: {
            'Content-Type': 'application/json',
          },
      });
      if (!resultPushTransaction.data || !resultPushTransaction.data.hash) {
        Logger.infoMainNotify({ nameNotify: 'Server', section: 'push', meta: { resultPushTransaction }, useInProduction: true });
      }
      // const resultPushTransaction = await axios.post(
      //   `/api/proxy/transactions/push`,
      //   JSON.stringify(tx),
      //   {
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //   },
      // );
      // console.log('transactions/push >> ', resultPushTransaction);

      return resultPushTransaction;
    } catch (err) {
      // console.log('queue err >> ', err);
      Logger.infoMainNotify({ nameNotify: 'Server', section: 'customSign', meta: { err, message: err.message }, useInProduction: true });
      let nameMethods = '';
      if (err.config && err.config.url) {
        nameMethods = err.config.url.split('/');
        nameMethods = nameMethods[nameMethods.length - 1];
      }
      // throw new Error(`${err.message} in ${nameMethods}`);
      return { code: 201, message: `${err.message} in ${nameMethods}` };
    }
  }), 1);

  return queue;
};

const asyncQueue = async ({ address, msg, fee }) => {
  return new Promise((resolve) => queue.push({ address, msg, fee }, 1, (err, result) => resolve(result)));
}

const customSign = async ({ msg = [], fee }) => {
  const { address } = StoreInstance.profileAvailable;
  try {
    // const resultPushTransaction = queue.push({ address, msg, fee }, 1, (err, result) => {
    //   console.log('push callback >> ', { err, result });
    // });
    if (!StoreInstance.queue) {
      Logger.infoMainNotify({ nameNotify: 'Server', section: 'customQueue', meta: { queue: StoreInstance.queue }, useInProduction: true });
      return { code: 200, message: 'The request queue was not created' };
    }
    const resultPushTransaction = await StoreInstance.queue.push({ address, msg, fee }, 1);
    // const resultPushTransaction = await asyncQueue({ address, msg, fee });
    // console.log('after queue >> ', resultPushTransaction);

    if (resultPushTransaction && resultPushTransaction.data && resultPushTransaction.data.hash) {
      const { hash } = resultPushTransaction.data;
      const result = await axios.get(`${URL_SERVER_MAIN}/api/proxy/transactions/result?hash=${hash}`);
      // const result = await axios.get(`/api/proxy/transactions/result?hash=${hash}`);
      // console.log('transactions/result >> ', result);

      if (result.data && result.data.result) {
        return result.data.result;
      }
      return result;
    } else {
      // throw new Error('Method transactions/push of server not have field hash');
      return { code: 200, message: 'Method transactions/push of server not have field hash' };
    }
    
    return resultPushTransaction;
  } catch (err) {
    // console.log('customSign err >> ', err);
    Logger.infoMainWithKey(' customSign', err, { useInProduction: true });
    let nameMethods = '';
    if (err.config && err.config.url) {
      nameMethods = err.config.url.split('/');
      nameMethods = nameMethods[nameMethods.length - 1];
    }
    // throw new Error(`${err.message} in ${nameMethods}`);
    return { code: 201, message: `${err.message} in ${nameMethods}` };
  }
};

export const getAccount = async ({ mnemonic, isPrivKeys = false }) => {
  const wallet = await DirectSecp256k1HdWallet.fromMnemonic(
    mnemonic,
    {
      hdPaths: [stringToPath("m/44'/118'/0'/0/0")],
      prefix: "bears"
    }
  );
  let account;
  if (isPrivKeys) {
    [account] = await wallet.getAccountsWithPrivkeys();
  } else {
    [account] = await wallet.getAccounts();
  }
  return { wallet, account };
}

// **************************** //
// Blockchain QUERY transaction //

export const getBalance = async ({ address }) => {
  checkClientExist(StoreInstance.isAnonymousClientConfigured);
  const response = await StoreInstance.anonymousClient.cosmos.queryClient.bank.balance(address, 'uhoney');
  const responseSecond = await StoreInstance.anonymousClient.cosmos.queryClient.bank.balance(address, 'cone');
  return {
    honey: response.amount / MILLION,
    cone: responseSecond.amount
  }
};

export const getHoney = async ({ address }) => {
  checkClientExist(StoreInstance.isAnonymousClientConfigured);
  const response = await StoreInstance.anonymousClient.cosmos.queryClient.bank.balance(address, 'uhoney');
  return response.amount / MILLION;
};

export const getCONE = async ({ address }) => {
  checkClientExist(StoreInstance.isAnonymousClientConfigured);
  const response = await StoreInstance.anonymousClient.cosmos.queryClient.bank.balance(address, 'cone');
  return response.amount;
};

export const getLastAirInfo = async () => {
  try {
    checkClientExist(StoreInstance.isAnonymousClientConfigured);
    const response = await StoreInstance.anonymousClient.bearsQueries.ShowLastAirInfo({});
    return {
      consume: Decimal.fromAtomics(response.consume, 18).toFloatApproximation(),
      supply: Decimal.fromAtomics(response.supply, 18).toFloatApproximation(),
      count: Decimal.fromAtomics(response.count, 18).toFloatApproximation(),
      purity: Decimal.fromAtomics(response.purity, 18).toFloatApproximation(),
    }
  } catch (err) {
    return { err };
  }
};

export const getHoneyPowerBear = async ({ bearId }) => {
  checkClientExist(StoreInstance.isAnonymousClientConfigured);
  const response = await StoreInstance.anonymousClient.bearsQueries.ShowHoneyPowerByBearId({ bearId });
  return { 
    result: Decimal.fromAtomics(response.honeyPower, 18).toFloatApproximation() / MILLION,
    code: response.code
  }
};

export const getHoneyMaxPowerBear = async ({ bearId, powerMaxBee }) => {
  checkClientExist(StoreInstance.isAnonymousClientConfigured);
  const response = await StoreInstance.anonymousClient.bearsQueries.ShowApiariesInfoByBearId({ bearId });
  let countSpaceAvailable = 0;
  response.apiariesInfo.forEach((apiaryInfo) => {
    countSpaceAvailable += apiaryInfo.params.spaceAvailable;
  });
  return {
    result: powerMaxBee * countSpaceAvailable,
    code: 0
  }
};

export const getFarmingHoneyApiary = async ({ apiaryId }) => {
  checkClientExist(StoreInstance.isAnonymousClientConfigured);
  const response = await StoreInstance.anonymousClient.bearsQueries.ShowHoneyPowerByApiaryId({ apiaryId });
  return {
    result: Decimal.fromAtomics(response.honeyPower, 18).toFloatApproximation() / MILLION,
    code: 0 // response.code
  }
};

export const getCountHoneyApiary = async ({ bearId, apiaryId }) => {
  checkClientExist(StoreInstance.isAnonymousClientConfigured);
  const response = await StoreInstance.anonymousClient.bearsQueries.ShowApiariesInfoByBearId({ bearId });
  const apiaryInfo = response.apiariesInfo.find((apiaryInfoObj) => apiaryInfoObj.id === apiaryId);
  return {
    result: Decimal.fromAtomics(apiaryInfo.countHoney, 18).toFloatApproximation() / MILLION,
    code: 0 // response.code
  }
};

export const getHoneyPowerBeePerBlock = async ({ beeType }) => {
  checkClientExist(StoreInstance.isAnonymousClientConfigured);
  const response = await StoreInstance.anonymousClient.bearsQueries.ShowHoneyPowerByBeeType({ beeType });
  return { 
    result: Decimal.fromAtomics(response.honeyPower, 18).toFloatApproximation() / MILLION,
    code: 0 // response.code
  }
};

export const getBearsId = async ({ address }) => {
  try {
    checkClientExist(StoreInstance.isAnonymousClientConfigured);
    const response = await StoreInstance.anonymousClient.bearsQueries.AddressBears({ address });
    return response.addressBears;
  } catch (err) {
    return { err };
  }
};

export const getBearName = async ({ name }) => {
  try {
    checkClientExist(StoreInstance.isAnonymousClientConfigured);
    const response = await StoreInstance.anonymousClient.bearsQueries.BearNames({ name });
    return response;
  } catch (err) {
    return { err };
  }
};

export const getBearsInfo = async ({ ids = [] }) => {
  if (!ids) return undefined;
  try {
    checkClientExist(StoreInstance.isAnonymousClientConfigured);
    const response = await StoreInstance.anonymousClient.bearsQueries.BearsAll({});
    const bearObj = response.Bears.filter((bear) => {
      if (ids.includes(bear.id)) return bear;
    });
    return bearObj;
  } catch (err) {
    return { err };
  }
};

export const getBearInfo = async ({ id }) => {
  if (!_.isNumber(id)) return undefined;
  try {
    checkClientExist(StoreInstance.isAnonymousClientConfigured);
    const response = await StoreInstance.anonymousClient.bearsQueries.Bears({ id });
    return response.Bears
  } catch (err) {
    return { err };
  }
};

export const getNickNameByAddress = async ({ address }) => {
  const bearAddress =  await getBearsId({ address });
  let nickName = address;
  if (bearAddress && bearAddress.bears && bearAddress.bears.length === 1) {
    const bearInfo = await getBearInfo({ id: bearAddress.bears[0] });
    nickName = bearInfo.name;
  }
  return nickName;
}

export const getNickNameByBearId = async ({ bearId }) => {
  const bearInfo = await getBearInfo({ id: bearId });
  return bearInfo.name;
}

export const getFields = async ({ ids = [] }) => {
  if (!ids || ids.length < 1) return undefined;
  try {
    checkClientExist(StoreInstance.isAnonymousClientConfigured);
    const response = await StoreInstance.anonymousClient.bearsQueries.FieldsAll({});

    return response.Fields.filter((field) => {
      if (ids.includes(field.id)) return field;
    });
  } catch (err) {
    return { err };
  }
};

export const getFieldById = async ({ id }) => {
  if (!_.isNumber(id)) return undefined;
  try {
    checkClientExist(StoreInstance.isAnonymousClientConfigured);
    const response = await StoreInstance.anonymousClient.bearsQueries.Fields({ id });
    return response.Fields;
  } catch (err) {
    return { err };
  }
};

export const getTrees = async ({ ids = [] }) => {
  if (!ids || ids.length < 1) return undefined;
  try {
    checkClientExist(StoreInstance.isAnonymousClientConfigured);
    const response = await StoreInstance.anonymousClient.bearsQueries.TreesAll({});

    return response.Trees.filter((tree) => {
      if (ids.includes(tree.id)) return tree;
    });
  } catch (err) {
    return { err };
  }
};

export const getApiaries = async ({ ids = [] }) => {
  if (!ids || ids.length < 1) return undefined;
  try {
    checkClientExist(StoreInstance.isAnonymousClientConfigured);
    const response = await StoreInstance.anonymousClient.bearsQueries.ApiariesAll({});

    return response.Apiaries.filter((apiary) => {
      if (ids.includes(apiary.id)) return apiary;
    });
  } catch (err) {
    return { err };
  }
};

export const getDecorations = async ({ ids = [] }) => {
  if (!ids || ids.length < 1) return undefined;
  try {
    checkClientExist(StoreInstance.isAnonymousClientConfigured);
    const response = await StoreInstance.anonymousClient.bearsQueries.DecorationsAll({});

    return response.Decorations.filter((decoration) => {
      if (ids.includes(decoration.id)) return decoration;
    });
  } catch (err) {
    return { err };
  }
};

export const getBee = async ({ id }) => {
  if (!id) return undefined;
  try {
    checkClientExist(StoreInstance.isAnonymousClientConfigured);
    const response = await StoreInstance.anonymousClient.bearsQueries.Bees({ id });
    return { bee: response.Bees, code: 0 }
  } catch (err) {
    return { err };
  }
};

export const getBees = async ({ ids = [] }) => {
  if (!ids || ids.length < 1) return undefined;
  try {
    checkClientExist(StoreInstance.isAnonymousClientConfigured);
    const response = await StoreInstance.anonymousClient.bearsQueries.BeesAll({});

    return response.Bees.filter((bee) => {
      if (ids.includes(bee.id)) return bee;
    });
  } catch (err) {
    return { err };
  }
};

export const getBearsAll = async () => {
  try {
    checkClientExist(StoreInstance.isAnonymousClientConfigured);
    const response = await StoreInstance.anonymousClient.bearsQueries.BearsAll({});
    return response.Bears;
  } catch (err) {
    return { err };
  }
};

export const getApiariesInfoByBearId = async ({ bearId }) => {
  checkClientExist(StoreInstance.isAnonymousClientConfigured);
  const response = await StoreInstance.anonymousClient.bearsQueries.ShowApiariesInfoByBearId({ bearId });
  return {
    result: response.apiariesInfo,
    code: 0 // response.code
  }
}

export const getBeesInfoByBearId = async ({ bearId }) => {
  checkClientExist(StoreInstance.isAnonymousClientConfigured);
  const response = await StoreInstance.anonymousClient.bearsQueries.ShowBeesInfoByBearId({ bearId });
  return {
    result: response.beesInfo,
    code: 0 // response.code
  }
}

export const getDecorationsInfoByBearId = async ({ bearId }) => {
  checkClientExist(StoreInstance.isAnonymousClientConfigured);
  const response = await StoreInstance.anonymousClient.bearsQueries.ShowDecorationsInfoByBearId({ bearId });
  return {
    result: response.decorationsInfo,
    code: 0 // response.code
  }
}

export const getTreesInfoByBearId = async ({ bearId }) => {
  checkClientExist(StoreInstance.isAnonymousClientConfigured);
  const response = await StoreInstance.anonymousClient.bearsQueries.ShowTreesInfoByBearId({ bearId });
  return {
    result: response.treesInfo,
    code: 0 // response.code
  }
}

export const getParams = async () => {
  try {
    checkClientExist(StoreInstance.isAnonymousClientConfigured);
    const response = await StoreInstance.anonymousClient.bearsQueries.Params({});
    return response.params;
  } catch (err) {
    return { err };
  }
}
// ************************** //
// Blockchain MSG transaction //
export const msgCreateBee = async ({ bearId, beeType, beeName }) => {
  checkClientExist(StoreInstance.isAuthorizedClientConfigured);
  const balances = await getBalance({ address: StoreInstance.profileAvailable.address });
  const msg = await StoreInstance.authorizedClient.bearsTxs.msgCreateBee({ creator: StoreInstance.profileAvailable.address, receiver: StoreInstance.profileAvailable.address, bearId, beeType, beeName })
  const result = await customSign({ msg: [msg], fee: getFee({ balances }) });
  // const result = await StoreInstance.authorizedClient.bearsTxs.signAndBroadcast([msg], { fee: getFee({ balances }), memo: "" });
  return result;
};

export const msgCollectHoneyFromApiary = async ({ apiaryId }) => {
  checkClientExist(StoreInstance.isAuthorizedClientConfigured);
  const balances = await getBalance({ address: StoreInstance.profileAvailable.address });
  const msg = await StoreInstance.authorizedClient.bearsTxs.msgCollectHoneyFromApiary({ creator: StoreInstance.profileAvailable.address, apiaryId })
  // const result = await StoreInstance.authorizedClient.bearsTxs.signAndBroadcast([msg], { fee: getFee({ balances }), memo: "" });
  const result = await customSign({ msg: [msg], fee: getFee({ balances }) });
  return result;
};

export const msgInitGameAndSetName = async ({ name }) => {
  checkClientExist(StoreInstance.isAuthorizedClientConfigured);
  const balances = await getBalance({ address: StoreInstance.profileAvailable.address });
  const msg = await StoreInstance.authorizedClient.bearsTxs.msgInitGameAndSetName({ creator: StoreInstance.profileAvailable.address, name })
  // const result = await StoreInstance.authorizedClient.bearsTxs.signAndBroadcast([msg], { fee: getFee({ coefficient: 1.0, balances }), memo: "" });
  const result = await customSign({ msg: [msg], fee: getFee({ coefficient: 1.0, balances }) });
  return result;
};

export const msgInitGameAndCreateApiary = async ({ apiaryType }) => {
  // apiaryType: BEE_HOUSE, APIARY, ALVEARY
  checkClientExist(StoreInstance.isAuthorizedClientConfigured);
  const balances = await getBalance({ address: StoreInstance.profileAvailable.address });
  const msg = await StoreInstance.authorizedClient.bearsTxs.msgInitGameAndCreateApiary({ creator: StoreInstance.profileAvailable.address, receiver: StoreInstance.profileAvailable.address, apiaryType })
  // const result = await StoreInstance.authorizedClient.bearsTxs.signAndBroadcast([msg], { fee: getFee({ balances }), memo: "" });
  const result = await customSign({ msg: [msg], fee: getFee({ balances }) });
  return result;
};

export const msgSetDecorationPosition = async ({ decorationId, fieldId, rowId, columnId }) => {
  checkClientExist(StoreInstance.isAuthorizedClientConfigured);
  const balances = await getBalance({ address: StoreInstance.profileAvailable.address });
  const msg = await StoreInstance.authorizedClient.bearsTxs.msgSetDecorationPosition({ creator: StoreInstance.profileAvailable.address, decorationId, fieldId, rowId, columnId })
  // const result = await StoreInstance.authorizedClient.bearsTxs.signAndBroadcast([msg], { fee: getFee({ balances }), memo: "" });
  const result = await customSign({ msg: [msg], fee: getFee({ balances }) });
  return result;
};

export const msgUnsetDecorationPosition = async ({ decorationId }) => {
  checkClientExist(StoreInstance.isAuthorizedClientConfigured);
  const balances = await getBalance({ address: StoreInstance.profileAvailable.address });
  const msg = await StoreInstance.authorizedClient.bearsTxs.msgUnsetDecorationPosition({ creator: StoreInstance.profileAvailable.address, decorationId })
  // const result = await StoreInstance.authorizedClient.bearsTxs.signAndBroadcast([msg], { fee: getFee({ balances }), memo: "" });
  const result = await customSign({ msg: [msg], fee: getFee({ balances }) });
  return result;
};

export const msgSetName = async ({ bearId, name }) => {
  checkClientExist(StoreInstance.isAuthorizedClientConfigured);
  const balances = await getBalance({ address: StoreInstance.profileAvailable.address });
  const msg = await StoreInstance.authorizedClient.bearsTxs.msgSetName({ creator: StoreInstance.profileAvailable.address, bearId, name })
  // const result = await StoreInstance.authorizedClient.bearsTxs.signAndBroadcast([msg], { fee: getFee({ coefficient: 1.0, balances }), memo: "" });
  const result = await customSign({ msg: [msg], fee: getFee({ coefficient: 1.0, balances }) });
  return result;
};

export const msgInitGameAndCreateBee = async ({ beeType, beeName }) => {
  // beeType: 
  //  SMALL_BEE, BEE, LUCKY_BEE, HONEY_BEE, 
  //  SKILLED_BEE, CURIOUS_BEE, HONEY_EXPLORER, 
  //  HONEY_HUNTER, WONDER_BEE, DIAMOND_STING
  checkClientExist(StoreInstance.isAuthorizedClientConfigured);
  const balances = await getBalance({ address: StoreInstance.profileAvailable.address });
  const msg = await StoreInstance.authorizedClient.bearsTxs.msgInitGameAndCreateBee({ creator: StoreInstance.profileAvailable.address, receiver: StoreInstance.profileAvailable.address, beeType, beeName })
  // const result = await StoreInstance.authorizedClient.bearsTxs.signAndBroadcast([msg], { fee: getFee({ balances }), memo: "" });
  const result = await customSign({ msg: [msg], fee: getFee({ balances }) });
  return result;
};

export const msgCreateDecoration = async ({ bearId, decorationType }) => {
  // decorationType: FLOWERS, FLAG, LAMP, GREEN_BEE, FOUNTAIN
  checkClientExist(StoreInstance.isAuthorizedClientConfigured);
  const balances = await getBalance({ address: StoreInstance.profileAvailable.address });
  const msg = await StoreInstance.authorizedClient.bearsTxs.msgCreateDecoration({ creator: StoreInstance.profileAvailable.address, receiver: StoreInstance.profileAvailable.address, bearId, decorationType })
  // const result = await StoreInstance.authorizedClient.bearsTxs.signAndBroadcast([msg], { fee: getFee({ balances }), memo: "" });
  const result = await customSign({ msg: [msg], fee: getFee({ balances }) });
  return result;
};

export const msgCreateApiary = async ({ bearId, fieldId, rowId, columnId, apiaryType }) => {
  // apiaryType: BEE_HOUSE, APIARY, ALVEARY
  checkClientExist(StoreInstance.isAuthorizedClientConfigured);
  const balances = await getBalance({ address: StoreInstance.profileAvailable.address });
  const msg = await StoreInstance.authorizedClient.bearsTxs.msgCreateApiary({ creator: StoreInstance.profileAvailable.address, receiver: StoreInstance.profileAvailable.address, bearId, fieldId, rowId, columnId, apiaryType })
  // const result = await StoreInstance.authorizedClient.bearsTxs.signAndBroadcast([msg], { fee: getFee({ balances }), memo: "" });
  const result = await customSign({ msg: [msg], fee: getFee({ balances }) });
  return result;
};

export const msgDeleteApiary = async ({ apiaryId }) => {
  checkClientExist(StoreInstance.isAuthorizedClientConfigured);
  const balances = await getBalance({ address: StoreInstance.profileAvailable.address });
  const msg = await StoreInstance.authorizedClient.bearsTxs.msgDeleteApiary({ creator: StoreInstance.profileAvailable.address, apiaryId })
  // const result = await StoreInstance.authorizedClient.bearsTxs.signAndBroadcast([msg], { fee: getFee({ balances }), memo: "" });
  const result = await customSign({ msg: [msg], fee: getFee({ balances }) });
  return result;
};

export const msgInitGameAndCreateTree = async ({ treeType }) => {
  // treeType: OAK, SPRUCE, APPLE_TREE, WILLOW
  checkClientExist(StoreInstance.isAuthorizedClientConfigured);
  const balances = await getBalance({ address: StoreInstance.profileAvailable.address });
  const msg = await StoreInstance.authorizedClient.bearsTxs.msgInitGameAndCreateTree({ creator: StoreInstance.profileAvailable.address, treeType })
  // const result = await StoreInstance.authorizedClient.bearsTxs.signAndBroadcast([msg], { fee: getFee({ balances }), memo: "" });
  const result = await customSign({ msg: [msg], fee: getFee({ balances }) });
  return result;
};

export const msgExtendField = async ({ fieldId }) => {
  checkClientExist(StoreInstance.isAuthorizedClientConfigured);
  const balances = await getBalance({ address: StoreInstance.profileAvailable.address });
  const msg = await StoreInstance.authorizedClient.bearsTxs.msgExtendField({ creator: StoreInstance.profileAvailable.address, receiver: StoreInstance.profileAvailable.address, id: fieldId })
  // const result = await StoreInstance.authorizedClient.bearsTxs.signAndBroadcast([msg], { fee: getFee({ balances }), memo: "" });
  const result = await customSign({ msg: [msg], fee: getFee({ balances }) });
  return result;
};

export const msgSetApiaryHouseForBee = async ({ beeId, apiaryId }) => {
  checkClientExist(StoreInstance.isAuthorizedClientConfigured);
  const balances = await getBalance({ address: StoreInstance.profileAvailable.address });
  const msg = await StoreInstance.authorizedClient.bearsTxs.msgSetApiaryHouseForBee({ creator: StoreInstance.profileAvailable.address, beeId, apiaryId })
  // const result = await StoreInstance.authorizedClient.bearsTxs.signAndBroadcast([msg], { fee: getFee({ balances }), memo: "" });
  const result = await customSign({ msg: [msg], fee: getFee({ balances }) });
  return result;
};

export const msgCreateTree = async ({ bearId, fieldId, rowId, columnId, treeType }) => {
  // treeType: OAK, SPRUCE, APPLE_TREE, WILLOW
  checkClientExist(StoreInstance.isAuthorizedClientConfigured);
  const msg = await StoreInstance.authorizedClient.bearsTxs.msgCreateTree({ creator: StoreInstance.profileAvailable.address, bearId, fieldId, rowId, columnId, treeType })
  // auto stacking with no fee
  const params = await getParams();
  // console.log('params >> ', params);
  let rewardCone = 0;
  if (params && params.treeTypes) {
    params.treeTypes.forEach((treeInfo) => {
      if (treeInfo.treeType === treeType && treeInfo.reward) {
        rewardCone = treeInfo.reward[0].amount;
      }
    })
  }

  const { address } = StoreInstance.unityStore;
  const validatorAddress = await methodsBears.getValidatorAddress({ address });
  const delegateMsg = {
    typeUrl: "/cosmos.staking.v1beta1.MsgDelegate",
    value: tx_2.MsgDelegate.fromPartial({
        delegatorAddress: address,
        validatorAddress: validatorAddress,
        amount: coin(rewardCone, 'cone'),
    }),
  };
  const balances = await getBalance({ address: StoreInstance.profileAvailable.address });
  // const result = await StoreInstance.authorizedClient.bearsTxs.signAndBroadcast([msg, delegateMsg], { fee: getFee({ balances }), memo: "" });
  const result = await customSign({ msg: [msg, delegateMsg], fee: getFee({ balances }) });
  // const result = await customSign({ msg: [msg], fee: getFee({ balances }) });
  // const result = await StoreInstance.authorizedClient.bearsTxs.signAndBroadcast([msg], { fee: getFee({}), memo: "" });
  return result;
};

export const msgMoveItemOnField = async ({ fieldId, rowId, columnId, newRowId, newColumnId }) => {
  checkClientExist(StoreInstance.isAuthorizedClientConfigured);
  const balances = await getBalance({ address: StoreInstance.profileAvailable.address });
  const msg = await StoreInstance.authorizedClient.bearsTxs.msgMoveItemOnField({ creator: StoreInstance.profileAvailable.address, fieldId, rowId, columnId, newRowId, newColumnId })
  // const result = await StoreInstance.authorizedClient.bearsTxs.signAndBroadcast([msg], { fee: getFee({ balances }), memo: "" });
  const result = await customSign({ msg: [msg], fee: getFee({ balances }) });
  return result;
};

export const msgUnsetApiaryHouseForBee = async ({ beeId }) => {
  checkClientExist(StoreInstance.isAuthorizedClientConfigured);
  const balances = await getBalance({ address: StoreInstance.profileAvailable.address });
  const msg = await StoreInstance.authorizedClient.bearsTxs.msgUnsetApiaryHouseForBee({ creator: StoreInstance.profileAvailable.address, beeId });
  // const result = await StoreInstance.authorizedClient.bearsTxs.signAndBroadcast([msg], { fee: getFee({ balances }), memo: '' });
  const result = await customSign({ msg: [msg], fee: getFee({ balances }) });
  return result;
};

export const msgInitGameAndCreateDecoration = async ({ decorationType }) => {
  checkClientExist(StoreInstance.isAuthorizedClientConfigured);
  const balances = await getBalance({ address: StoreInstance.profileAvailable.address });
  const msg = await StoreInstance.authorizedClient.bearsTxs.msgInitGameAndCreateDecoration({ creator: StoreInstance.profileAvailable.address, receiver: StoreInstance.profileAvailable.address, decorationType });
  // const result = await StoreInstance.authorizedClient.bearsTxs.signAndBroadcast([msg], { fee: getFee({ balances }), memo: '' });
  const result = await customSign({ msg: [msg], fee: getFee({ balances }) });
  return result;
};

export const msgInitGameAndExtendField = async () => {
  checkClientExist(StoreInstance.isAuthorizedClientConfigured);
  const balances = await getBalance({ address: StoreInstance.profileAvailable.address });
  const msg = await StoreInstance.authorizedClient.bearsTxs.msgInitGameAndExtendField({ creator: StoreInstance.profileAvailable.address, receiver: StoreInstance.profileAvailable.address, });
  // const result = await StoreInstance.authorizedClient.bearsTxs.signAndBroadcast([msg], { fee: getFee({ balances }), memo: '' });
  const result = await customSign({ msg: [msg], fee: getFee({ balances }) });
  return result;
};

export const msgClearApiaryFromBees = async ({ apiaryId }) => {
  checkClientExist(StoreInstance.isAuthorizedClientConfigured);
  const balances = await getBalance({ address: StoreInstance.profileAvailable.address });
  const msg = await StoreInstance.authorizedClient.bearsTxs.msgClearApiaryFromBees({ creator: StoreInstance.profileAvailable.address, apiaryId });
  // const result = await StoreInstance.authorizedClient.bearsTxs.signAndBroadcast([msg], { fee: getFee({ balances }), memo: '' });
  const result = await customSign({ msg: [msg], fee: getFee({ balances }) });
  return result;
};

export const msgDeleteApiaryWithBees = async ({ apiaryId }) => {
  checkClientExist(StoreInstance.isAuthorizedClientConfigured);
  const balances = await getBalance({ address: StoreInstance.profileAvailable.address });
  const msg = await StoreInstance.authorizedClient.bearsTxs.msgClearApiaryFromBees({ creator: StoreInstance.profileAvailable.address, apiaryId });
  const msg2 = await StoreInstance.authorizedClient.bearsTxs.msgCollectHoneyFromApiary({ creator: StoreInstance.profileAvailable.address, apiaryId });
  const msg3 = await StoreInstance.authorizedClient.bearsTxs.msgDeleteApiary({ creator: StoreInstance.profileAvailable.address, apiaryId });
  // const result = await StoreInstance.authorizedClient.bearsTxs.signAndBroadcast([msg, msg2, msg3], { fee: getFee({ balances }), memo: '' });
  const result = await customSign({ msg: [msg, msg2, msg3], fee: getFee({ balances }) });
  return result;
};

export const msgDeleteApiaryWithCollectHoney = async ({ apiaryId }) => {
  checkClientExist(StoreInstance.isAuthorizedClientConfigured);
  const balances = await getBalance({ address: StoreInstance.profileAvailable.address });
  const msg = await StoreInstance.authorizedClient.bearsTxs.msgCollectHoneyFromApiary({ creator: StoreInstance.profileAvailable.address, apiaryId });
  const msg2 = await StoreInstance.authorizedClient.bearsTxs.msgDeleteApiary({ creator: StoreInstance.profileAvailable.address, apiaryId });
  // const result = await StoreInstance.authorizedClient.bearsTxs.signAndBroadcast([msg, msg2], { fee: getFee({ balances }), memo: '' });
  const result = await customSign({ msg: [msg, msg2], fee: getFee({ balances }) });
  return result;
};

// ******************************* //
// Blockchain Stacking transaction //
export const getAddressValidators = async ({ address }) => {
  checkClientExist(StoreInstance.isAnonymousClientConfigured);
  const addresses = await StoreInstance.anonymousClient.cosmos.queryClient.staking.delegatorValidators(address);
  return addresses;
}

export const getStakedCones = async ({ address, useSum = true }) => {
  checkClientExist(StoreInstance.isAnonymousClientConfigured);
  try {
    const response = await StoreInstance.anonymousClient.cosmos.queryClient.staking.delegatorDelegations(address);
    console.log('getStakedCones api >> ', response);
    if (!useSum) {
      return response.delegationResponses;
    } 
    return response.delegationResponses.reduce((sum, val) => {
      return sum + Number(val.balance.amount)
    }, 0);
  } catch (err) {
    throw err;
  }
}

export const getTotalStakingRewards = async ({ address }) => {
  try {
    checkClientExist(StoreInstance.isAnonymousClientConfigured);
    const response = await StoreInstance.anonymousClient.cosmos.queryClient.distribution.delegationTotalRewards(address);
    console.log('getTotalStakingRewards api >> ', response);
    return response.total.map((value) => {
      return {
        amount: Decimal.fromAtomics(value.amount, 18).toFloatApproximation(), 
        denom: value.denom
      }
    });
  } catch (err) {
    throw err;
  }
}

export const getUnstakingCones = async ({ address }) => {
  try {
    checkClientExist(StoreInstance.isAnonymousClientConfigured);
    const response = await StoreInstance.anonymousClient.cosmos.queryClient.staking.delegatorUnbondingDelegations(address);
    console.log('getUnstakingCones api >> ', response)
    return response.unbondingResponses.reduce((sum, val) => {
      const currentSum = val.entries.reduce((sumObjects, valueObject) => {
        return sumObjects + Number(valueObject.balance);
      }, 0);

      return sum + currentSum;
    }, 0);
  } catch (err) {
    throw err;
  }
}

export const getValidators = async ({ status }) => {
  checkClientExist(StoreInstance.isAnonymousClientConfigured);
  const validators = await StoreInstance.anonymousClient.cosmos.queryClient.staking.validators(status);
  return validators
}

export const stakeCones = async ({ address, validatorAddress, amount }) => {
  checkClientExist(StoreInstance.isAuthorizedClientConfigured);
  const delegateMsg = {
    typeUrl: "/cosmos.staking.v1beta1.MsgDelegate",
    value: tx_2.MsgDelegate.fromPartial({
        delegatorAddress: address,
        validatorAddress: validatorAddress,
        amount: coin(amount, 'cone'),
    }),
  };

  const balances = await getBalance({ address: StoreInstance.profileAvailable.address });
  const result = await customSign({ msg: [delegateMsg], fee: getFee({ balances }) });
  // const stackedCones = await StoreInstance.authorizedClient.cosmos.delegateTokens(address, validatorAddress, coin(amount, 'cone'), getFee({ coefficient: 1.0, balances }));
  
  return result;
}

export const unstakeCones = async ({ address, validatorAddress, amount }) => {
  checkClientExist(StoreInstance.isAuthorizedClientConfigured);
  const unDelegateMsg = {
    typeUrl: "/cosmos.staking.v1beta1.MsgUndelegate",
    value: tx_2.MsgDelegate.fromPartial({
        delegatorAddress: address,
        validatorAddress: validatorAddress,
        amount: coin(amount, 'cone'),
    }),
  };

  Logger.infoMainNotify({ nameNotify: 'unstakeCones', section: 'unstakeCones', meta: unDelegateMsg, useInProduction: true });

  const balances = await getBalance({ address });
  const result = await customSign({ msg: [unDelegateMsg], fee: getFee({ balances }) });
  // const unstackedCones = await StoreInstance.authorizedClient.cosmos.undelegateTokens(StoreInstance.profileAvailable.address, validatorAddress, coin(amount, 'cone'), getFee({ coefficient: 1.0, balances }));
  return result;
}

export const withdrawRewards = async ({ validatorAddress }) => {
  checkClientExist(StoreInstance.isAuthorizedClientConfigured);
  const { address } = StoreInstance.profileAvailable;
  const withdrawMsg = {
      typeUrl: "/cosmos.distribution.v1beta1.MsgWithdrawDelegatorReward",
      value: tx_1.MsgWithdrawDelegatorReward.fromPartial({
          delegatorAddress: address,
          validatorAddress: validatorAddress,
      }),
  };

  const balances = await getBalance({ address });
  const result = await customSign({ msg: [withdrawMsg], fee: getFee({ balances }) });
  // const rewards = await StoreInstance.authorizedClient.cosmos.withdrawRewards(StoreInstance.profileAvailable.address, validatorAddress, getFee({ coefficient: 1.0, balances }));
  return result;
}

export const getUnstakingEntries = async ({ address }) => {
  checkClientExist(StoreInstance.isAnonymousClientConfigured);
  const response = await StoreInstance.anonymousClient.cosmos.queryClient.staking.delegatorUnbondingDelegations(address);
  if (response) {
    if (response.unbondingResponses.length > 0) {
      return {
        entries: response.unbondingResponses[0].entries,
        code: 0
      }
    }
    return {
      code: 0,
      entries: []
    }
  }
  return {
    code: 105,
    entries: undefined
  }
}

export const getStakingParams = async () => {
  checkClientExist(StoreInstance.isAnonymousClientConfigured);
  const response = await StoreInstance.anonymousClient.cosmos.queryClient.staking.params();
  return response.params;
}

export const sendTokens = async ({ address, amount, recipient, nameTokens = 'uhoney', courseCurrency = { CONE: 0.117 } }) => { // courseCurrency = { HONEY: 1, RUB: 70, USD: 0.02 }
  try {
    checkClientExist(StoreInstance.isAuthorizedClientConfigured);
    const client = StoreInstance.authorizedClient.cosmos;
    const amountCoins = coins(amount, nameTokens);
    // let memo = ''
    // Object.entries(courseCurrency).forEach((courseItem) => {
    //   if (courseItem.length === 2 && courseItem[0] !== 'HONEY' && courseItem[0] !== 'CONE') {
    //     if (memo) {
    //       memo += '&';
    //     }
    //     memo += `${courseItem[0]}=${courseItem[1]}`;
    //   }
    // });
    const balances = await getBalance({ address });
    const fee = getFee({ coefficient: 1.0, balances });

    const fromAddress = address;
    const toAddress = recipient;
    const messages = [{
      typeUrl: URL_MATCH3_SEND,
      value: {
        fromAddress,
        toAddress,
        amount: amountCoins,
      },
    }];
    const gasEstimated = await client.simulate(fromAddress, messages);

    const result = await client.sendTokens(address, recipient, amountCoins, fee);
    return result;
  } catch (error) {
    const errs = error.message.split(':');
    if (errs && errs.length > 0 && errs[0] === 'Query failed with (18)') {
      return { error: true, message: 'Error: send CONE transactions are disabled' };
    }
    throw error;
  }
};

export default {
  createQueue,
  getAccount,
  getBalance,
  getHoney,
  getCONE,
  getHoneyPowerBear,
  getHoneyMaxPowerBear,
  getFarmingHoneyApiary,
  getCountHoneyApiary,
  getHoneyPowerBeePerBlock,
  getLastAirInfo,
  getBearsId,
  getBearsInfo,
  getBearInfo,
  getNickNameByAddress,
  getNickNameByBearId,
  getBearsAll,
  getBearName,
  getFields,
  getFieldById,
  getTrees,
  getApiaries,
  getDecorations,
  getBee,
  getBees,
  getParams,
  getApiariesInfoByBearId,
  getBeesInfoByBearId,
  getDecorationsInfoByBearId,
  getTreesInfoByBearId,

  msgCreateBee,
  msgCollectHoneyFromApiary,
  msgInitGameAndSetName,
  msgInitGameAndCreateApiary,
  msgSetDecorationPosition,
  msgUnsetDecorationPosition,
  msgSetName,
  msgInitGameAndCreateBee,
  msgCreateDecoration,
  msgCreateApiary,
  msgDeleteApiary,
  msgInitGameAndCreateTree,
  msgExtendField,
  msgSetApiaryHouseForBee,
  msgCreateTree,
  msgMoveItemOnField,
  msgUnsetApiaryHouseForBee,
  msgInitGameAndCreateDecoration,
  msgInitGameAndExtendField,
  msgClearApiaryFromBees,
  msgDeleteApiaryWithBees,
  msgDeleteApiaryWithCollectHoney,

  getAddressValidators,
  getStakedCones,
  getTotalStakingRewards,
  getUnstakingCones,
  getValidators,
  stakeCones,
  unstakeCones,
  withdrawRewards,
  getUnstakingEntries,
  getStakingParams,

  sendTokens,
  customSignOnly
}
