import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { observer } from 'mobx-react';
import { useStores } from 'src/stores';
// import { debounce } from 'lodash';
import { APP_STAGE, DEVELOPMENT } from '@constants/system';
import URLS from '@src/constants/urls';

import Input from '@components/Input';
import { Button, ColorType, UNIQ_ID_BUTTON } from '@components/Button';
import CheckBox from '@components/CheckBox';
import { ENUM_POPUP } from '@components/PopUp/enumPopup';

import styles from './modal.module.scss';

// const debounced = debounce(() => {
//   gtagEvent(GOOGLE_ANALYTICS.SLIDE_INVEST);
// }, 1000);
const MIN_INVEST = 100;
/* eslint-disable */

const InvestInput = ({ value, onInput, stores }) => {
  // console.log('InvestInput rerender');
  const min = MIN_INVEST;
  const max = 10000;

  return (
    <>
      <p className={styles.ModalInvestText}>{stores.translate('how_much_invest')}</p>
      <Input
        /* В Сафари при вводе букв в инпут с type="number" НЕ происходит событие onChange !!!
        Поэтому оставил text, чтобы обрабатывать буквы */
        type="text"
        placeholder={stores.translate('how_much')}
        name="investInput"
        id="investInputRange"
        autoComplete="no"
        classNames={{
          Label: styles.LabelInput,
          InputContainer: styles.Input_InputContainer,
          Container: styles.Input_InvestContainer,
          Input: styles.Input_Input,
        }}
        min={min}
        max={max}
        value={value}
        onChange={(event) => {
          const clearValue = event.target.value.replace(/[^\d]/g, '');
          onInput(clearValue);
          document.getElementById('investSlideRange').style.backgroundSize = `${
            (clearValue * 100) / max
          }% 100%`;
          // debounced();
        }}
        step={min}
      />
      <input
        className={styles.ModalInvestSlider}
        type="range"
        id="investSlideRange"
        min={min}
        max={max}
        value={value || 0}
        onChange={(event) => {
          onInput(event.target.value);
          document.getElementById('investSlideRange').style.backgroundSize = `${
            (event.target.value * 100) / max
          }% 100%`;
          // debounced();
        }}
        step={min}
      />
    </>
  );
};
// todo: not update input style
const ContactUsModal = observer(({ close = () => {} }) => {
  const stores = useStores();
  // console.log('ContactUsModal: rerender');
  const initInvestValue = MIN_INVEST;
  const [usd, setUsd] = useState(initInvestValue);
  // console.log('usd: ', usd);
  const [isLoading, setLoading] = useState(false);
  const [isLikeToInvest, setLikeToInvest] = useState(false);

  /* Logic for ModalForm */
  const schemaModal = yup
    .object()
    .shape({
      fullName: yup
        .string()
        .required(stores.translate('full_name_input_required'))
        .matches(
          /^[a-zA-ZёЁа-яА-Я0-9\s']+$/,
          stores.translate('full_name_input_special_characters_validation'),
        )
        .min(2, stores.translate('full_name_input_validation')),
      email: yup
        .string()
        .email(stores.translate('email_input_validation'))
        .required(stores.translate('email_input_required')),
    })
    .required();

  const ModalForm = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schemaModal),
    defaultValues: {
      fullName: '',
      email: '',
    },
  });
  const modalFields = ModalForm.watch();

  let host = 'https://moneta.today';
  // eslint-disable-next-line no-restricted-globals
  if (APP_STAGE === DEVELOPMENT) {
    host = 'https://dev.moneta.today';
  }

  const sendRequest = () => {
    setLoading(true);
    axios
      .post(
        host + URLS.SEND_INTENTION_REQUEST,
        JSON.stringify({
          fullName: modalFields.fullName.trim(),
          email: modalFields.email.trim(),
          amount: isLikeToInvest ? Number(usd) : 0,
          language: stores.language,
          agreed: true,
          meta: 'gameHoneyWood',
        }),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then(() => {
        ModalForm.reset({
          fullName: '',
          email: '',
        });
        /* Set Usd to default value */
        setUsd(initInvestValue);
        close();
        // gtagEvent(GOOGLE_ANALYTICS.APPLICATION_SEND);
        setLoading(false);
        stores.setOpenPopup(ENUM_POPUP.CONTACT_US_SUBMIT);
      })
      .catch((error) => {
        alert(error);
        console.error(error);
        setLoading(false);
        close();
      });
  };

  return (
    <>
      <Input
        placeholder={stores.translate('enter_your_full_name')}
        canError
        label={stores.translate('name_and_surname')}
        name="fullName"
        register={ModalForm.register}
        error={ModalForm.formState.errors?.fullName}
        autoComplete="no"
        classNames={{
          Label: styles.LabelInput,
          InputContainer: styles.Input_InputContainer,
          Container: styles.Input_Container,
          Input: styles.Input_Input,
        }}
      />
      <Input
        type="email"
        placeholder={stores.translate('enter_your_email')}
        canError
        label={stores.translate('e-mail')}
        name="email"
        register={ModalForm.register}
        error={ModalForm.formState.errors?.email}
        autoComplete="no"
        classNames={{
          Label: styles.LabelInput,
          InputContainer: styles.Input_InputContainer,
          Container: styles.Input_Container,
          Input: styles.Input_Input,
        }}
      />
      <div className={styles.CheckBoxContainer}>
        <CheckBox
          selected={isLikeToInvest}
          title={stores.translate('would_like_to_invest')}
          onChange={() => {
            setLikeToInvest(!isLikeToInvest);
            setUsd(initInvestValue);
          }}
          classNames={{
            ContainerImage: styles.CheckBoxImage,
            Container: styles.CheckBox,
          }}
        />
      </div>
      {isLikeToInvest && (
        <InvestInput value={usd} onInput={setUsd} stores={stores} ModalForm={ModalForm} />
      )}
      <div className={styles.SendButton}>
        <Button
          colorType={ColorType.GREEN}
          uniqID={UNIQ_ID_BUTTON.ContactUsHeader.Submit}
          onClick={() => {
            // gtagEvent(GOOGLE_ANALYTICS.CLICK_SEND);
            ModalForm.handleSubmit(sendRequest)();
          }}
          title={stores.translate('submit')}
          classNames={{
            Title: stores.browserInfo.isAndroid
              ? styles.TitleGreenButton__android
              : styles.TitleGreenButton,
          }}
          isLoading={isLoading}
          valueScaleAnimation={0.95}
        />
      </div>
      <p
        className={styles.ModalText}
        dangerouslySetInnerHTML={{
          __html: stores.translate('proposal_agreement_submit'),
        }}
      />
    </>
  );
});

export default ContactUsModal;
