import { StoreInstance } from "src/stores";

export const NEWS_CONFIGS = [
  {
    number: 1,
    title: '',
    description: StoreInstance.translate('news_description_1'),
    twitter: 'https://twitter.com/honeywood_game/status/1550046307158474752?s=21&t=2zzNDcKR9kvVbliGY4qMzw',
    telegram: 'https://t.me/honeywood_official/178',
    socials: [
      { name: 'twitter', link: 'https://twitter.com/honeywood_game/status/1550046307158474752?s=21&t=2zzNDcKR9kvVbliGY4qMzw' },
      { name: 'telegram', link: 'https://t.me/honeywood_official/178' }
    ],
    enabled: false,
  },
  {
    number: 2,
    title: '',
    description: StoreInstance.translate('news_description_2'),
    twitter: 'https://twitter.com/honeywood_game/status/1559302299104804867?s=21&t=2zzNDcKR9kvVbliGY4qMzw',
    telegram: 'https://t.me/honeywood_official/211',
    socials: [
      { name: 'twitter', link: 'https://twitter.com/honeywood_game/status/1559302299104804867?s=21&t=2zzNDcKR9kvVbliGY4qMzw' },
      { name: 'telegram', link: 'https://t.me/honeywood_official/211' }
    ],
    enabled: true,
  },
  {
    number: 3,
    title: '',
    description: StoreInstance.translate('news_description_3'),
    twitter: 'https://twitter.com/honeywood_game/status/1538878724082438144?s=21&t=GzJZHDOOGtphzTy31l10Rw',
    telegram: 'https://t.me/honeywood_official/150',
    socials: [
      { name: 'twitter', link: 'https://twitter.com/honeywood_game/status/1538878724082438144?s=21&t=GzJZHDOOGtphzTy31l10Rw' },
      { name: 'telegram', link: 'https://t.me/honeywood_official/150' }
    ],
    enabled: false,
  },
  {
    number: 4,
    title: '',
    description: StoreInstance.translate('news_description_4'),
    twitter: 'https://twitter.com/honeywood_game/status/1558562014477312004?s=21&t=2zzNDcKR9kvVbliGY4qMzw',
    telegram: 'https://t.me/honeywood_official/210',
    socials: [
      { name: 'twitter', link: 'https://twitter.com/honeywood_game/status/1558562014477312004?s=21&t=2zzNDcKR9kvVbliGY4qMzw' },
      { name: 'telegram', link: 'https://t.me/honeywood_official/210' }
    ],
    enabled: true,
  },
  {
    number: 5,
    title: '',
    description: StoreInstance.translate('news_description_5'),
    twitter: 'https://twitter.com/honeywood_game/status/1557345744654319618?s=21&t=2zzNDcKR9kvVbliGY4qMzw',
    telegram: 'https://t.me/honeywood_official/208',
    socials: [
      { name: 'twitter', link: 'https://twitter.com/honeywood_game/status/1557345744654319618?s=21&t=2zzNDcKR9kvVbliGY4qMzw' },
      { name: 'telegram', link: 'https://t.me/honeywood_official/208' }
    ],
    enabled: false,
  },
  {
    number: 6,
    title: '',
    description: StoreInstance.translate('news_description_6'),
    twitter: 'https://twitter.com/honeywood_game/status/1554856015992078344?s=21&t=2zzNDcKR9kvVbliGY4qMzw',
    telegram: 'https://t.me/honeywood_official/194',
    socials: [
      { name: 'twitter', link: 'https://twitter.com/honeywood_game/status/1557345744654319618?s=21&t=2zzNDcKR9kvVbliGY4qMzw' },
      { name: 'telegram', link: 'https://t.me/honeywood_official/194' }
    ],
    enabled: true,
  },
  {
    number: 7,
    title: '',
    description: StoreInstance.translate('news_description_7'),
    twitter: 'https://twitter.com/honeywood_game/status/1550118615155847168?s=21&t=2zzNDcKR9kvVbliGY4qMzw',
    telegram: 'https://t.me/honeywood_official/179',
    socials: [
      { name: 'twitter', link: 'https://twitter.com/honeywood_game/status/1550118615155847168?s=21&t=2zzNDcKR9kvVbliGY4qMzw' },
      { name: 'telegram', link: 'https://t.me/honeywood_official/179' }
    ],
    enabled: false,
  },
  {
    number: 8,
    title: '',
    description: StoreInstance.translate('news_description_8'),
    twitter: 'https://twitter.com/honeywood_game/status/1539231427262394369?s=21&t=2zzNDcKR9kvVbliGY4qMzw',
    telegram: 'https://t.me/honeywood_official/153',
    socials: [
      { name: 'twitter', link: 'https://twitter.com/honeywood_game/status/1539231427262394369?s=21&t=2zzNDcKR9kvVbliGY4qMzw' },
      { name: 'telegram', link: 'https://t.me/honeywood_official/153' }
    ],
    enabled: false,
  },
  {
    number: 9,
    title: '',
    description: StoreInstance.translate('news_description_9'),
    twitter: 'https://twitter.com/honeywood_game/status/1529693664141877248?s=21&t=2zzNDcKR9kvVbliGY4qMzw',
    telegram: 'https://t.me/honeywood_official/115',
    socials: [
      { name: 'twitter', link: 'https://twitter.com/honeywood_game/status/1529693664141877248?s=21&t=2zzNDcKR9kvVbliGY4qMzw' },
      { name: 'telegram', link: 'https://t.me/honeywood_official/115' }
    ],
    enabled: false,
  },
  {
    number: 10,
    title: '',
    description: StoreInstance.translate('news_description_10'),
    twitter: 'https://twitter.com/honeywood_game/status/1544698375928430594?s=21&t=GzJZHDOOGtphzTy31l10Rw',
    telegram: 'https://t.me/honeywood_official/166',
    socials: [
      { name: 'twitter', link: 'https://twitter.com/honeywood_game/status/1544698375928430594?s=21&t=GzJZHDOOGtphzTy31l10Rw' },
      { name: 'telegram', link: 'https://t.me/honeywood_official/166' }
    ],
    enabled: false,
  },
  {
    number: 11,
    title: '',
    description: StoreInstance.translate('news_description_11'),
    twitter: 'https://twitter.com/honeywood_game/status/1570750852515917825?s=21&t=XIX9TtfT_Y4mj3FnfJIL2Q',
    telegram: 'https://t.me/honeywood_official/250',
    socials: [
      { name: 'twitter', link: 'https://twitter.com/honeywood_game/status/1570750852515917825?s=21&t=XIX9TtfT_Y4mj3FnfJIL2Q' },
      { name: 'telegram', link: 'https://t.me/honeywood_official/250' }
    ],
    enabled: false,
  }
];

export default {
  NEWS_CONFIGS,
}