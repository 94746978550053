import _ from 'lodash';
import { BEARS } from 'src/api';
import { BEE_TYPE, TREE_TYPE, APIARY_TYPE, HALLOWEEN_DAY, END_DATE_CHRISTMAS, START_DATE_CHRISTMAS, CHRISTMAS_DAY } from '@constants/blockchain';
import { DEFAULT_AVATAR, INFO_ACCOUNT, MILLION, SKIP_EVENT_PACK } from '@constants/system';
import { stringify, toFloatApproximation } from '@utils/common';
import { whiteListBlockchainInfo } from '@utils/mainBears';
import Logger from '@utils/logger';
import { GameMethodNames, GameObjectNames } from 'src/stores/unity';
import { StoreInstance } from 'src/stores';
import settings from 'src/api/server/settings';

import { ENUM_MODAL } from 'src/components/Modal/enumModal';
import { getPacksAndBlockchainObjects, savePacks } from '@utils/getPacksAndBlockchainObjects';
import { handleMainError } from '../HandleError';
import DynamicStatesMediator from './DynamicStatesMediator';
import Match3Mediator from './Match3Mediator';

const stores = StoreInstance;
/* eslint-disable max-len */

const initializeUnityGameWithStart = async () => {
  let { address } = stores.unityStore;
  let avatar = DEFAULT_AVATAR;
  let nickName = address;

  if (stores.unityStore.availableFullScreen) {
    stores.unityStore.send({
      objectName: GameObjectNames.LobbyReact,
      methodName: GameMethodNames.ShowFullScreenButton,
    });
  }

  try {
    const responseAll = await Promise.all([
      BEARS.getParams(),
      BEARS.getNickNameByAddress({ address }),
      settings.profile({ address }),
      Match3Mediator.tournamentCurrent(),
      DynamicStatesMediator.updateHoney(),
      DynamicStatesMediator.updateCONE(),
    ]);

    // const params = await BEARS.getParams();
    const params = responseAll[0];
    Logger.infoMainWithKey('params', params);
    if (_.isEmpty(params)) {
      handleMainError(undefined, new Error('dont get params from blockchain..'));
      return;
    }
    const blocksPerHour = Number(params.blocksPerHour);
    stores.unityStore.setBlocksPerHour(blocksPerHour);

    // let nickName = await BEARS.getNickNameByAddress({ address });
    [, nickName] = responseAll;
    // nickName = responseAll[1];
    // let { address } = stores.unityStore;
    if (localStorage.getItem(INFO_ACCOUNT)) {
      const info = JSON.parse(localStorage.getItem(INFO_ACCOUNT));
      localStorage.setItem(INFO_ACCOUNT, JSON.stringify({ date: info && info.date, address, nickName }));
    }

    // const profile = await settings.profile({ address });
    const profile = responseAll[2];
    if (!profile.err) {
      avatar = profile.avatar;
    }

    // await Match3Mediator.tournamentCurrent();
    // await DynamicStatesMediator.updateHoney();
    // await DynamicStatesMediator.updateCONE();
  } catch (err) {
    nickName = stores.unityStore.nickName;
    address = stores.unityStore.address;
    handleMainError(undefined, err);
  } finally {
    stores.setProfileAvailable({ ...stores.profileAvailable, nickName, avatar });
    stores.unityStore.send({
      objectName: GameObjectNames.LobbyReact,
      methodName: GameMethodNames.InitProfilePlayer,
      values: stringify({ nickName, address, avatar }),
      isLoggerInProduction: true,
    });
    // stores.unityStore.send({
    //   objectName: GameObjectNames.LobbyReact,
    //   methodName: 'SetNickNamePlayer',
    //   values: stringify({ nickName, address, avatar }),
    // });
    stores.unityStore.setStartGame(true);
    //! for change Modal components (critical)
    stores.setGame(true);
    const localDate = new Date().valueOf();
    //! skipped pack in start unity game
    // const isSkipPack = JSON.parse(localStorage.getItem(SKIP_EVENT_PACK)) || false;
    const isSkipPack = true;
    if (!isSkipPack && END_DATE_CHRISTMAS >= localDate && localDate >= START_DATE_CHRISTMAS) {
      await getPacksAndBlockchainObjects({
        afterHandler: (result) => {
          const packs = savePacks({ result, event: CHRISTMAS_DAY });
          if (packs && packs.packArrayEvent && packs.packArrayEvent.length > 0) {
            const eventPack = packs.packArrayEvent[0];
            Logger.infoMainWithKey('eventPack', eventPack);
            stores.setStateDisplayedPaymentModal({ packInfo: eventPack });
            stores.showPaymentsUnity(true);
          }
        }
      });
    }
  }
};

const getStatusOnboarding = async () => {
  const { address } = stores.unityStore;
  const profile = await settings.profile({ address });
  if (profile.err) {
    handleMainError({ code: 103 }, profile);
  } else {
    stores.unityStore.send({
      objectName: GameObjectNames.LobbyReact,
      methodName: GameMethodNames.UpdateStatusOnboarding,
      values: stringify({ isCompleted: profile.isOnbordingCompleted }),
    })
  }
}

const setStatusOnboarding = async () => {
  const result = await settings.onboarding();
  if (result.err) {
    handleMainError({ code: 103 }, result);
  } else {
    Logger.infoMainWithKey('onboarding setStatusOnboarding', result);
  }
}

export default {
  initializeUnityGameWithStart,
  getStatusOnboarding,
  setStatusOnboarding
};
