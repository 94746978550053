/* eslint-disable no-unused-vars */
/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
// import classnames from 'classnames';
import { observer } from 'mobx-react';
import { gtagEvent } from '@utils/common';
import { GOOGLE_ANALYTICS } from '@src/constants/googleAnalyticsEvents';
import { useStores } from 'src/stores';

import { Button, ColorType, SizeButton, UNIQ_ID_BUTTON } from '@components/Button';
import InputWord from '@components/InputWord';
import InformationPopup from '@components/InformationPopup';

import { CRYPTO } from 'src/api/crypto';
import { COUNT_WORD_SEED_PHRASE } from '@constants/system';
import { copyText } from '@utils/common';
import { useCopied } from '@hooks/custom';
import Logger from '@utils/logger';
import styles from './modal.module.scss';
import { ENUM_POPUP } from '@components/PopUp/enumPopup';
import { BEARS } from '@api';
// import { Bears } from 'honeywood-protobuf-js/types/bears/bears';

// const debounced = debounce(() => {
//   gtagEvent(GOOGLE_ANALYTICS.SLIDE_INVEST);
// }, 1000);
// openPopUp = ()=>{}
const CreateSeedPhraseModal = observer(({ close }) => {
  const stores = useStores();
  const [mnemonic, setMnemonic] = useState([
    'word1',
    'word2',
    'word3',
    'word4',
    'word5',
    'word6',
    'word7',
    'word8',
    'word9',
    'word10',
    'word11',
    'word12',
  ]);
  const [address, setAddress] = useState('');
  // const arrValues = new Array(COUNT_WORD_SEED_PHRASE).fill(undefined)
  const inputsRef = new Array(COUNT_WORD_SEED_PHRASE).fill(undefined).map(() => useRef());
  const { copied, setCopied } = useCopied();

  useEffect(() => {
    const seedPhrase = CRYPTO.generateMnemonic();
    setMnemonic(seedPhrase.split(' '));
    (async () => {
      try {
        const { account } = await BEARS.getAccount({ mnemonic: seedPhrase, isPrivKeys: true });
        setAddress(account.address);
      } catch (err) {
        Logger.error(err);
      }
    })();
  }, []);

  const onCopyClick = async () => {
    await copyText({ text: mnemonic.join(' ') });
    setCopied();
  };

  const onNextClick = () => {
    stores.setPropsForPopup({ address });
    stores.setOpenPopup(ENUM_POPUP.SEED_PHRASE_SAVED);
  };

  return (
    <>
      <p className={styles.TitleSeedPhrase}>{stores.translate('this_is_seed_phrase')}</p>
      <div className={styles.SeedPraseContainer}>
        {mnemonic.map((word, index) => {
          return (
            <InputWord
              key={`generate_seed_phrase_${index}`}
              name={`generated_seed_phrase_word${index}`}
              value={word}
              readOnly
              ref={inputsRef[index]}
            />
          );
        })}
      </div>
      <p className={styles.ImportantTitle}>
        {stores.translate('important')}&nbsp;
        <span className={styles.ImportantTitle__description}>
          {stores.translate('wont_login_without_seed_phrase')}
        </span>
      </p>
      <div className={styles.ButtonsContainer}>
        <div className={styles.CopyButtonContainer}>
          <Button
            colorType={ColorType.ORANGE}
            size={SizeButton.SMALL}
            width={281}
            uniqID={UNIQ_ID_BUTTON.SeedPhrase.Copy}
            onClick={() => {
              // gtagEvent(GOOGLE_ANALYTICS.CLICK_SEND);
              onCopyClick();
            }}
            title={stores.translate('copy_seed_phrase')}
            classNames={{
              Container: styles.CopyButton,
              Title: stores.browserInfo.isAndroid
                ? styles.TitleOrangeButton__android
                : styles.TitleOrangeButton,
            }}
            valueScaleAnimation={0.95}
          />
          {copied && (
            <InformationPopup
              info={stores.translate('copied')}
              classNames={{ Container: styles.CopiedContainer }}
            />
          )}
        </div>
        <Button
          colorType={ColorType.GREEN}
          uniqID={UNIQ_ID_BUTTON.SeedPhrase.Next}
          onClick={() => {
            onNextClick();
          }}
          title={stores.translate('next')}
          classNames={{
            Container: styles.NextButton,
            Title: stores.browserInfo.isAndroid
              ? styles.TitleGreenButton__android
              : styles.TitleGreenButton,
          }}
          valueScaleAnimation={0.95}
        />
      </div>
    </>
  );
});

export default CreateSeedPhraseModal;
