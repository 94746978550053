import React from 'react';
import {
  ALVEARY,
  APIARY,
  APIARY_TYPE,
  APPLE_TREE,
  BABY_BEE,
  BEE,
  BEE_DAY,
  BEE_HOUSE,
  BEE_THE_SAVIOR,
  BEE_TYPE,
  BUSH,
  CHRISTMAS_BOX,
  CHRISTMAS_TREE,
  COZY_HOUSE,
  CURIOUS_BEE,
  DECORATION_TYPE,
  DEFAULT,
  DIAMOND_STING,
  EXPIRATION_DATE_BEE_FEST,
  FIELD_TYPE,
  FIRST_BEEHIVE,
  FLAG,
  FLOWERS,
  FLOWER_BARREL,
  FOREST_ACE,
  FOREST_BEE,
  FOREST_BEEHIVE,
  FOREST_EXPLORER,
  FOUNTAIN,
  GAS_WANTED,
  HONEY_BAT,
  HONEY_BEE,
  HONEY_EXPLORER,
  HONEY_HUNTER,
  ITEMS_BEE_FEST,
  LAMP,
  LUCKY_BEE,
  OAK,
  PUM_PUM,
  SCARYARY,
  SKILLED_BEE,
  SMALL_BEE,
  SNOW_BEE,
  SPRUCE,
  STUMP,
  TREE,
  TREE_TYPE,
  WILLOW,
  WONDER_BEE,
} from '@constants/blockchain';
import { BEAR_CREATED_STATE, PRODUCTION, BETA, DEVELOPMENT } from '@constants/system';
import { StoreInstance } from 'src/stores';
import { BEARS } from '@api';

export const whiteListBlockchain = ({ type, kind }) => {
  const apiaryWhiteList = ({ productType }) => {
    switch (productType) {
      case BEE_HOUSE:
      case APIARY:
      case ALVEARY:
      case STUMP:
        return true;
      default:
        return false;
    }
  };
  const decorationWhiteList = ({ productType }) => {
    switch (productType) {
      case FLOWERS:
      case FLAG:
      case LAMP:
      case FLOWER_BARREL:
      case FOUNTAIN:
        return true;
      default:
        return false;
    }
  };
  const treeWhiteList = ({ productType }) => {
    switch (productType) {
      case OAK:
      case SPRUCE:
      case APPLE_TREE:
      case WILLOW:
      case BUSH:
      case CHRISTMAS_TREE: // christmas, time to left 14 january 2023
        return true;
      default:
        return false;
    }
  };
  const beeWhiteList = ({ productType }) => {
    switch (productType) {
      case BABY_BEE:
      case SMALL_BEE:
      case BEE:
      case LUCKY_BEE:
      case HONEY_BEE:
      case SKILLED_BEE:
      case CURIOUS_BEE:
      case HONEY_EXPLORER:
      case HONEY_HUNTER:
      case WONDER_BEE:
      case DIAMOND_STING:
        return true;
      default:
        return false;
    }
  };
  const fieldWhiteList = ({ productType }) => {
    switch (productType) {
      case DEFAULT:
        return true;
      default:
        return false;
    }
  };

  switch (type) {
    case APIARY_TYPE:
      return apiaryWhiteList({ productType: kind });
    case TREE_TYPE:
      return treeWhiteList({ productType: kind });
    case DECORATION_TYPE:
      return decorationWhiteList({ productType: kind });
    case BEE_TYPE:
      return beeWhiteList({ productType: kind });
    case FIELD_TYPE:
      return fieldWhiteList({ productType: kind });
    default:
      return false;
  }
};
// ! TODO: update get info object from params in process initialize bear info (start farming)
export const whiteListBlockchainInfo = ({ type, kind }) => {
  if (type === DECORATION_TYPE) {
    if (kind === BEE_THE_SAVIOR) { // bee fest
      return true;
    }
    if (kind === PUM_PUM) { // halloween
      return true;
    }
    if (kind === CHRISTMAS_BOX) { // christmas
      return true;
    }
    return whiteListBlockchain({ type, kind });
  }
  if (type === APIARY_TYPE) {
    if (kind === FOREST_BEEHIVE) { // bee fest
      return true;
    }
    if (kind === SCARYARY) { // halloween
      return true;
    }
    if (kind === COZY_HOUSE) { // christmas
      return true;
    }
    return whiteListBlockchain({ type, kind });
  }
  if (type === BEE_TYPE) {
    if (kind === FOREST_BEE || kind === FOREST_ACE || kind === FOREST_EXPLORER) { // bee fest
      return true;
    }
    if (kind === HONEY_BAT) { // halloween
      return true;
    }
    if (kind === SNOW_BEE) { // christmas
      return true;
    }
    return whiteListBlockchain({ type, kind });
  }
  if (type === TREE_TYPE) {
    if (kind === CHRISTMAS_TREE) { // christmas
      return true;
    }
    return whiteListBlockchain({ type, kind });
  }
  return whiteListBlockchain({ type, kind });
};

const validatorsAddressWhitelistProd = [
  'bearsvaloper1fq95guv9n2me2y4qd2udqpknlect5222vsh9tx',
  'bearsvaloper1k2hhnm4dj6klkcgglzmaqpqtypllg4rf7uhws2',
  'bearsvaloper1ktszane2hq4rc3khgj9pkrxhszeetqpxxfg952'
];
const validatorsAddressWhitelistDev = [
  'bearsvaloper149y3a8r4n7hjuyq6e0vr48n82xcenj34mgmpqd'
];
export const getValidatorsAddressWhiteList = () => {
  if (process.env && process.env.REACT_APP_STAGE === PRODUCTION) {
    return validatorsAddressWhitelistProd
  }

  return validatorsAddressWhitelistDev;
}

export const propertiesProductStoreGame = ({ kind, nameEvent = BEE_DAY }) => {
  const productBelongToEvent = (arrItems, kindProduct) => {
    return arrItems.includes(kindProduct);
  };

  if (nameEvent === BEE_DAY) {
    const productInEvent = productBelongToEvent(ITEMS_BEE_FEST, kind);
    const productEndDate = productInEvent ? EXPIRATION_DATE_BEE_FEST : null;
    const eventName = productInEvent ? BEE_DAY : null;
    return { productEndDate, eventName };
  }
  return {
    productEndDate: null,
    nameEvent: null,
  };
};

export const isBearCreated = async () => {
  const address = StoreInstance.profileAvailable && StoreInstance.profileAvailable.address; // : undefined;
  // console.log(address);
  if (!address) {
    return BEAR_CREATED_STATE.UNAUTHORIZED;
  }

  try {
    const bearsIds = await BEARS.getBearsId({ address });
    if (bearsIds && bearsIds.err) {
      return BEAR_CREATED_STATE.NO_CREATED;
    }
    if (bearsIds && bearsIds.bears && bearsIds.bears.length > 0) {
      const bearsInfo = await BEARS.getBearInfo({ id: bearsIds.bears && bearsIds.bears[0] });
      if (bearsInfo && bearsInfo.fields && bearsInfo.fields.length > 0) {
        const fieldInfo = await BEARS.getFieldById({ id: bearsInfo.fields && bearsInfo.fields[0] });
        // console.log('fieldInfo >> ', fieldInfo);
        // console.log('bearsInfo >> ', bearsInfo);
        if (
          bearsInfo &&
          ((bearsInfo.apiaries && bearsInfo.apiaries.length > 0) ||
            (fieldInfo && fieldInfo.countTiles > 1))
        ) {
          return BEAR_CREATED_STATE.CREATED;
        }
      }
    }
    return BEAR_CREATED_STATE.NO_CREATED;
  } catch (err) {
    return BEAR_CREATED_STATE.NO_CREATED;
  }
};

export default {
  whiteListBlockchain,
  whiteListBlockchainInfo,
  getValidatorsAddressWhiteList,
  propertiesProductStoreGame,
  isBearCreated,
}
