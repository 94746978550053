import { DEVELOPMENT, PRODUCTION } from "./System";

export const ITEM_TYPES = {
  0: 'APIARY_TYPE',
  1: 'TREE_TYPE',
  2: 'DECORATION_TYPE',
};
export const BEE_TYPE = 'BEE_TYPE';
export const APIARY_TYPE = 'APIARY_TYPE';
export const TREE_TYPE = 'TREE_TYPE';
export const FIELD_TYPE = 'FIELD_TYPE';
export const DECORATION_TYPE = 'DECORATION_TYPE';
export const HONEY_TYPE = 'HONEY_TYPE';

export const APIARY = 'APIARY';
export const TREE = 'TREE';
export const DECORATION = 'DECORATION';
export const COINS = 'COINS';
export const BEE = 'BEE';
export const USDT = 'USDT';
export const USDT_TRC20 = 'usdttrc20';
export const TAX = 0.1;

export const PACKS = {
  EASY_START: 'EASY_START',
  START_SET: 'START_SET',
  PRO_SET: 'PRO_SET',
  DOUBLE_BEE: 'DOUBLE_BEE',
  HONEY_FAMILY: 'HONEY_FAMILY',
  SQUAD_B: 'SQUAD_B',
  TEST_PACK: 'TEST_PACK',
};

export const EASY_START = 'EASY_START';
export const START_SET = 'START_SET';
export const PRO_SET = 'PRO_SET';
export const DOUBLE_BEE = 'DOUBLE_BEE';
export const HONEY_FAMILY = 'HONEY_FAMILY';
export const SQUAD_B = 'SQUAD_B';
export const TEST_PACK = 'TEST_PACK';
export const FIELD_EXTENDING = 'FIELD_EXTENDING';

export const SELLING_PACKS = {
  EASY_START: {
    ID: 'EASY_START',
    DISPLAY_PRICE: 32,
    PRICE: 30,
  },
  START_SET: {
    ID: 'START_SET',
    DISPLAY_PRICE: 48,
    PRICE: 45,
  },
  PRO_SET: {
    ID: 'PRO_SET',
    DISPLAY_PRICE: 77,
    PRICE: 70,
  },
  DOUBLE_BEE: {
    ID: 'DOUBLE_BEE',
    DISPLAY_PRICE: 48,
    PRICE: 45,
  },
  HONEY_FAMILY: {
    ID: 'HONEY_FAMILY',
    DISPLAY_PRICE: 85,
    PRICE: 80,
  },
  SQUAD_B: {
    ID: 'SQUAD_B',
    DISPLAY_PRICE: 106.5,
    PRICE: 99,
  },
  TEST_PACK: {
    ID: 'TEST_PACK',
    DISPLAY_PRICE: 1,
    PRICE: 1,
  },
};

export const PAYMENTS_AVAILABLE = {
  BINANCE: 'Binance',
  NOW_PAYMENTS: 'Now Payments',
};

export const BINANCE = 'Binance';
export const NOW_PAYMENTS = 'Now Payments';
export const DENOM_UHONEY = 'uhoney';
export const HONEY_CAMEL_CASE = 'Honey';
export const CONE = 'CONE';
export const GAS_WANTED = 2000000;
export const GAS_WANTED_CONE = 200000;
export const MIN_BALANCE_HONEY = 0.2;
export const URL_MATCH3_SEND = '/cosmos.bank.v1beta1.MsgSend';
export const URL_BURN_COINS = '/MonetaToday.honeywood.bears.MsgBurnCoins';

// WHITE LIST BLOCKCHAIN
// Bee (WL)
// export const BEE = 'BEE'
export const BABY_BEE = 'BABY_BEE';
export const SMALL_BEE = 'SMALL_BEE';
export const LUCKY_BEE = 'LUCKY_BEE';
export const HONEY_BEE = 'HONEY_BEE';
export const SKILLED_BEE = 'SKILLED_BEE';
export const CURIOUS_BEE = 'CURIOUS_BEE';
export const HONEY_EXPLORER = 'HONEY_EXPLORER';
export const HONEY_HUNTER = 'HONEY_HUNTER';
export const WONDER_BEE = 'WONDER_BEE';
export const DIAMOND_STING = 'DIAMOND_STING';
export const FOREST_BEE = 'FOREST_BEE';
export const FOREST_EXPLORER = 'FOREST_EXPLORER';
export const FOREST_ACE = 'FOREST_ACE';
export const HONEY_BAT = 'HONEY_BAT'; // halloween
export const SNOW_BEE = 'SNOW_BEE'; // christmas
// Apiary (WL)
export const BEE_HOUSE = 'BEE_HOUSE';
export const ALVEARY = 'ALVEARY';
// export const APIARY = 'APIARY';
export const FOREST_BEEHIVE = 'FOREST_BEEHIVE';
export const SCARYARY = 'SCARYARY'; // halloween 
export const COZY_HOUSE = 'COZY_HOUSE'; // christmas
// Decoration (WL)
export const STUMP = 'STUMP';
export const FLOWERS = 'FLOWERS';
export const FLAG = 'FLAG';
export const LAMP = 'LAMP';
export const FLOWER_BARREL = 'FLOWER_BARREL';
export const FOUNTAIN = 'FOUNTAIN';
export const FIRST_BEEHIVE = 'FIRST_BEEHIVE';
export const BEE_THE_SAVIOR = 'BEE_THE_SAVIOR';
export const PUM_PUM = 'PUM_PUM'; // halloween
export const CHRISTMAS_BOX = 'CHRISTMAS_BOX'; // christmas

// Tree (WL)
export const OAK = 'OAK';
export const SPRUCE = 'SPRUCE';
export const APPLE_TREE = 'APPLE_TREE';
export const WILLOW = 'WILLOW';
export const BUSH = 'BUSH';
export const CHRISTMAS_TREE = 'CHRISTMAS_TREE'; // christmas
// Field (WL)
export const DEFAULT = 'DEFAULT';

export const GOLDEN_BEE = 'GOLDEN_BEE';
export const BREAK_APPROVE_TRANSACTION = -1;
export const EXPIRATION_DATE_BEE_FEST = '2022-06-10T16:00:00.000Z';

export const START_DATE_HALLOWEEN = Date.UTC(2022, 9, 28, 21); // 30 октября 00:00:00 2022 года по МСК
export const END_DATE_HALLOWEEN = Date.UTC(2022, 10, 6, 21); // 7 ноября 00:00:00 2022 года по МСК

export const START_DATE_CHRISTMAS = Date.UTC(2022, 11, 19, 21); // 20 декабря 00:00:00 2022 года по МСК
export const END_DATE_CHRISTMAS = Date.UTC(2023, 0, 13, 21); // 14 января 00:00:00 2023 года по МСК

export const ITEMS_BEE_FEST = [
  "FIRST_BEEHIVE",
  "BEE_THE_SAVIOR",
  "FOREST_BEEHIVE",
  "FOREST_BEE",
  "FOREST_EXPLORER",
  "FOREST_ACE",
];

export const ITEMS_HALLOWEEN = [
  SCARYARY,
  HONEY_BAT,
  PUM_PUM
];

export const OBJECTS_BEE_DAY_FEST = {
  [DECORATION_TYPE]: [
    FIRST_BEEHIVE,
    BEE_THE_SAVIOR,
  ],
  [BEE_TYPE]: [
    FOREST_BEE,
    FOREST_EXPLORER,
    FOREST_ACE,
  ],
  [APIARY_TYPE]: [
    FOREST_BEEHIVE,
  ],
};

export const OBJECTS_HALLOWEEN = {
  [DECORATION_TYPE]: [
    PUM_PUM,
  ],
  [BEE_TYPE]: [
    HONEY_BAT
  ],
  [APIARY_TYPE]: [
    SCARYARY,
  ],
};

export const OBJECTS_GOLDEN_CLUB = {
  [BEE_TYPE]: [
    GOLDEN_BEE,
  ],
};

export const BEE_DAY = 'BEE_DAY';
export const HALLOWEEN_DAY = 'HALLOWEEN_DAY';
export const CHRISTMAS_DAY = 'CHRISTMAS_DAY';

export const CHAIN_ID_HONEYWOOD = 'HoneyWood';

export const CONFIG_HONEYWOOD_BLOCKCHAIN = {
  [PRODUCTION]: {
    chainName: "HoneyWood",
    rpc: "https://honeywood.io/node",
    rest: "https://honeywood.io/node-rest/",
  },
  [DEVELOPMENT]: {
    chainName: "HoneyWood Testnet",
    rpc: "https://dev.honeywood.io/node",
    rest: "https://dev.honeywood.io/node-rest/",
  }, 
}

export const TYPE_AUTH_CLIENT = {
  MNEMONIC: 'MNEMONIC',
  KEPLR: 'KEPLR',
}

export const NO_CHAIN_ERR_MSG = "There is no chain info for HoneyWood";
export const COST_CHANGE_AVAILABLE_AVATAR = 0.01;

export default {
  ITEM_TYPES,
  BEE_TYPE,
  APIARY_TYPE,
  TREE_TYPE,
  FIELD_TYPE,
  DECORATION_TYPE,
  APIARY,
  TREE,
  DECORATION,
  BEE,
  PACKS,
  SELLING_PACKS,
  EASY_START,
  START_SET,
  PRO_SET,
  DOUBLE_BEE,
  HONEY_FAMILY,
  SQUAD_B,
  TEST_PACK,
  USDT,
  USDT_TRC20,
  PAYMENTS_AVAILABLE,
  BINANCE,
  NOW_PAYMENTS,
  GAS_WANTED,
  URL_MATCH3_SEND,
  CHAIN_ID_HONEYWOOD,
  CONFIG_HONEYWOOD_BLOCKCHAIN,
  TYPE_AUTH_CLIENT,
  NO_CHAIN_ERR_MSG,
  COST_CHANGE_AVAILABLE_AVATAR
};
