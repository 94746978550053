/* eslint-disable no-unused-vars */
/* eslint-disable */
import React, { useState, useEffect } from 'react';
// import { useForm } from 'react-hook-form';
import classnames from 'classnames';
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as yup from 'yup';
import _ from 'lodash';
import { useMediaQuery } from 'src/helpers/media';
// import { debounce } from 'lodash';

import Input from '@components/Input';
import { Button, ColorType, SizeButton, UNIQ_ID_BUTTON } from '@components/Button';
import UnstakePolygon from '@src/assets/Unity/polygon.png';
import ConeIcon from '@src/assets/control/thumb_pine.png';

import Logger from '@utils/logger';
import styles from './stackingUnity.module.scss';
import { sanitize } from 'dompurify';
import { useStores } from 'src/stores';
import { observer } from 'mobx-react';
import RadioButton from '@components/RadioButton';

// const debounced = debounce(() => {
//   gtagEvent(GOOGLE_ANALYTICS.SLIDE_INVEST);
// }, 1000);
const MIN_CONES = 0;

const StackingUnityModal = observer(({ close }) => {
  const stores = useStores();
  const [isUnstake, setUnstake] = useState(false);
  const [uniqIDButton, setUniqIDButton] = useState(UNIQ_ID_BUTTON.StackingBearUnity.Stake);
  const [titleButton, setTitleButton] = useState(stores.translate('stake'));
  const [max, setMax] = useState(MIN_CONES);
  const [validatorsUnStake, setValidatorsUnStake] = useState([]);
  const [selectedValidator, setSelectedValidator] = useState(0);

  const width360 = useMediaQuery('(max-width: 360px)');
  const width1000 = useMediaQuery('(max-width: 1000px)');
  const isMobile = stores.browserInfo.isMobile;
  const width300 = useMediaQuery('(max-width: 300px)');
  const [cones, setCones] = useState(0);
  const isEnableStake = max > 0;
  const min = MIN_CONES;

  useEffect(() => {
    if (stores.propsForModal) {
      // console.log('stores.unityStore.CountConesForStacking >> ', stores.unityStore);
      if (stores.propsForModal.isUnstake) {
        setTitleButton(stores.translate('unstake'));
        setUniqIDButton(UNIQ_ID_BUTTON.StackingBearUnity.Unstake);
        setUnstake(stores.propsForModal.isUnstake);
        const validatorCones = stores.unityStore.arrayConesForUnstacking.find((validator) => validator.balance && validator.balance.amount > 0);
        setMax(Number(validatorCones.balance.amount));
        setValidatorsUnStake(stores.unityStore.arrayConesForUnstacking);
        console.log('stores.unityStore.countConesForUnstacking >> ', stores.unityStore.countConesForUnstacking);
        console.log('stores.unityStore.arrayConesForUnstacking >> ', stores.unityStore.arrayConesForUnstacking);
      } else {
        setMax(stores.unityStore.countConesForStacking);
        console.log('stores.unityStore.countConesForStacking >> ', stores.unityStore.countConesForStacking);
      }
    }
  }, [stores.propsForModal]);

  useEffect(() => {
    document.getElementById('stakeSlideRange').style.backgroundSize = `${
      (cones * 100) / max
    }% 100%`;
  }, [cones]);

  const handleKeypress = (e) => {
    if (e.key && e.key.toLowerCase() === 'enter') {
      onStakeClick();
    }
  };

  const onStakeClick = () => {
    const intCones = Number(cones);
    if (isUnstake) {
      stores.unityStore.setObjectUnstacking({ cone: intCones, validatorAddress: validatorsUnStake[selectedValidator].delegation.validatorAddress });
    } else {
      stores.unityStore.setConesStacking(intCones);
    }
    close(false);
  };

  const onChangeValidator = (value) => {
    setSelectedValidator(value);
    setMax(Number(validatorsUnStake[value].balance.amount));
  }

  return (
    <>
      {!isUnstake && <div className={styles.StakeNoInfoContainer} />}
      {isUnstake && (
        <>
          <div className={styles.UnstakeInfoFillContainer} />
          <div className={styles.UnstakeInfoOuterContainer}>
            <div className={styles.UnstakeInfoContainer}>
              <img
                onLoad={() => stores.increaseDownloadedImageCounter(UnstakePolygon)}
                onError={() => Logger.error('Error with image loading')}
                src={UnstakePolygon}
                className={styles.UnstakePolygon}
                alt="unstake_polygon"
              />
              <p
                className={styles.UnstakeInfoTitle}
                dangerouslySetInnerHTML={{
                  __html: sanitize(stores.translate('it_will_taken_20_30_days')),
                }}
              />
            </div>
          </div>
        </>
      )}
      <div className={styles.InnerContainer}>
        {isUnstake && validatorsUnStake && validatorsUnStake.length > 1 && (
          <div className={styles.ValidatorsContainer}>
            <p className={styles.ValidatorsTitle}>{`${stores.translate('validators')}:`}</p>
            {validatorsUnStake.map((validator, index) => {
              if (validator && validator.balance.amount === '0') return <></>;
              const selected = selectedValidator === index;
              return (
                <RadioButton
                  key={validator.delegation.validatorAddress}
                  selected={selectedValidator}
                  value={index}
                  onChange={onChangeValidator}
                  classNames={{
                    Title: selected ? styles.RadioButtonTitle_selected : styles.RadioButtonTitle,
                    InnerCircle: selected
                      ? styles.RadioButtonInnerCircle_selected
                      : styles.RadioButtonInnerCircle,
                    OuterCircle: selected
                      ? styles.RadioButtonOuterCircle_selected
                      : styles.RadioButtonOuterCircle,
                  }}
                >
                  {`${stores.translate('validator')} ${index + 1} (${validator.balance.amount}`}
                  <img src={ConeIcon} className={styles.ValidatorConeIcon} alt="cone" />
                  {')'}
                </RadioButton>
              );
            })}
          </div>
        )}
        <Input
          /* В Сафари при вводе букв в инпут с type="number" НЕ происходит событие onChange !!!
          Поэтому оставил text, чтобы обрабатывать буквы */
          type="text"
          placeholder={stores.translate('enter_value')}
          name="investInput"
          id="investInputRange"
          autoComplete="no"
          classNames={{
            Label: styles.LabelInput,
            InputContainer: styles.Input_InputContainer,
            Container: styles.Input_InvestContainer,
            Input: styles.Input_Input,
          }}
          min={min}
          max={max}
          value={cones}
          onKeyDown={handleKeypress}
          onChange={(event) => {
            const clearValue = event.target.value.replace(/[^\d]/g, '').replace(/^0+/, '');

            let newCones = Number(clearValue);
            if (newCones < min) {
              newCones = Number(min);
            } else if (newCones > max) {
              newCones = Number(max);
            }
            if (!_.isNumber(newCones) || (_.isNumber(newCones) && newCones <= 0)) {
              newCones = 0;
            }
            setCones(newCones);
          }}
          step={min}
          readOnly={max === min}
          isFocus
        />
        <div className={styles.StepValueSliderContainer}>
          <p className={styles.StepValueSlider}>{min}</p>
          <p className={styles.StepValueSlider}>{max}</p>
        </div>
        <input
          className={styles.ModalInvestSlider}
          type="range"
          id="stakeSlideRange"
          min={min}
          max={max}
          value={cones || 0}
          onKeyDown={handleKeypress}
          onChange={(event) => {
            setCones(event.target.value);
            // debounced();
          }}
          step={min}
        />
        <div className={styles.ButtonsContainer}>
          <Button
            width={240}
            colorType={ColorType.ORANGE}
            uniqID={uniqIDButton}
            disabled={!isEnableStake}
            size={isMobile || width1000 ? SizeButton.SMALL : SizeButton.MEDIUM}
            // height={width360 || isMobile ? 92 : undefined}
            onClick={() => {
              // gtagEvent(GOOGLE_ANALYTICS.CLICK_SEND);
              onStakeClick();
            }}
            title={titleButton}
            classNames={{
              Container: styles.ButtonContainer,
              Title: stores.browserInfo.isAndroid
                ? styles.TitleOrangeButton__android
                : styles.TitleOrangeButton,
            }}
            valueScaleAnimation={0.95}
          />
        </div>
      </div>
    </>
  );
});

export default StackingUnityModal;
