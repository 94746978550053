import React from 'react';
import { types, flow } from 'mobx-state-tree';
import i18n from '@src/localization';

import { SigningStargateClient } from '@cosmjs/stargate';
import { QueryClientImpl } from 'honeywood-protobuf-js/types/bears/query';
import { txClient } from 'honeywood-protobuf-js';

import {
  DEFAULT_LANGUAGE,
  QUANTITY_DOWNLOADED_RESOURCES,
  COUNT_MILLISECONDS_LIFE_TIME_PASSWORD,
  ENCRYPT_SEED_PHRASE,
  LANGUAGE,
  INFO_ACCOUNT,
  PRODUCTION,
  BETA,
  ENCRYPT_PROFILE,
  RPC_URL,
  MILLION,
  BEAR_CREATED_STATE,
  ENCRYPT_WALLET,
  DEFAULT_AVATAR,
  COUNT_MILLISECONDS_LIFE_TIME_PASSWORD_STORE,
} from '@constants/system';
import { TYPE_AUTH_CLIENT } from '@constants/blockchain';
import clientBears from 'src/api/clientBears';
import _ from 'lodash';
import { toFixedNumberWithoutRound } from '@utils/common';
import { BEARS } from 'src/api';
import Logger from '@utils/logger';
import { removeSecureStorage } from './system';
import { UnityStore } from './unity';

const AppStore = types
  .model({
    isDevelop: types.optional(types.boolean, true),
    isBeta: types.optional(types.boolean, false),
    isGame: types.optional(types.boolean, false),
    localStackLogger: types.optional(types.boolean, false),
    isMaintenanceMode: types.optional(types.boolean, false),
    isNativeApp: types.optional(types.boolean, false),
    isInitialHomePageLoading: types.optional(types.boolean, true),
    isInitialAboutPageLoading: types.optional(types.boolean, true),
    isInitialFaqPageLoading: types.optional(types.boolean, true),
    isInitialPartnershipPageLoading: types.optional(types.boolean, true),
    isInitialTokenomicsPageLoading: types.optional(types.boolean, true),
    isInitialAccountPagePageLoading: types.optional(types.boolean, true),
    isInitialTransactionsPageLoading: types.optional(types.boolean, true),
    passwordStartDate: types.optional(types.Date, 0),
    downloadedImageCounter: types.optional(types.number, 0),
    bearCreated: types.optional(types.number, 0),
    updateStateBearCreated: types.optional(types.boolean, false),

    language: types.optional(types.string, DEFAULT_LANGUAGE),
    languageGame: types.optional(types.string, DEFAULT_LANGUAGE),
    browserInfo: types.optional(types.frozen(null)),
    isAuthorized: types.optional(types.boolean, false), // param for user authorize or no
    isAccountAccess: types.optional(types.number, 0), // param for handle PIN CODE
    isChangedAccountInWallet: types.optional(types.boolean, false),
    isShowPayments: types.optional(types.boolean, false),
    isShowPaymentsUnity: types.optional(types.boolean, false),
    isShowPaymentsHoneyUnity: types.optional(types.boolean, false),
    stateDisplayedPaymentModal: types.optional(types.frozen(null)),
    paramsForObjectsBlockchain: types.optional(types.frozen(null)),
    isFinishKYCVerified: types.optional(types.boolean, false),
    password: types.optional(types.string, ''),

    isOpenMenuMobile: types.optional(types.boolean, false),
    openModal: types.optional(types.frozen(null)),
    propsForModal: types.optional(types.frozen(null)),
    executeModal: types.optional(types.frozen(null)),
    openPopup: types.optional(types.frozen(null)),
    propsForPopup: types.optional(types.frozen(null)),
    executePopup: types.optional(types.frozen(null)),
    isOpenCookiesPopup: types.optional(types.boolean, false),
    isOpenCookiesManageModal: types.optional(types.boolean, false),
    isShowPageUpButton: types.optional(types.boolean, false),

    unityStore: types.maybeNull(UnityStore),
  })
  .volatile((self) => ({
    anonymousClient: {
      cosmos: SigningStargateClient.prototype,
      bearsQueries: QueryClientImpl.prototype,
    },
    authorizedClient: {
      cosmos: SigningStargateClient.prototype,
      bearsTxs: null,
      bearsQueries: QueryClientImpl.prototype,
    },
    profileAvailable: null, // param for user authorized available info
    profile: null, // param for user authorized info
    tokenNowPayments: null,
    typeAuthClient: null,
    signatureTransaction: null,
    queue: null,
  }))
  .actions((self) => {
    let timeIntervalId;
    const configAnonymousClient = flow(function* (url) {
      self.anonymousClient = yield clientBears.createAnonymousClient({ url });
    });
    const configAuthorizedClient = flow(function* (wallet, url) {
      self.authorizedClient = yield clientBears.createAuthorizedClient({ wallet, url });
      self.typeAuthClient = TYPE_AUTH_CLIENT.MNEMONIC;
    });
    const configAuthorizedKeplrClient = flow(function* (url) {
      self.authorizedClient = yield clientBears.createAuthorizedKeplrClient({ url });
      self.typeAuthClient = TYPE_AUTH_CLIENT.KEPLR;
    });

    return {
      refreshAccessAccount() {
        if (!self.isAuthorized) return;

        if (!self.passwordStartDate) {
          self.isAccountAccess = 0;
        } else {
          const dateStart = self.passwordStartDate;
          const dateNow = new Date();
          if (dateNow - new Date(dateStart) >= COUNT_MILLISECONDS_LIFE_TIME_PASSWORD) {
            self.isAccountAccess = 0;
            this.cancelTimeInterval();
            // } else {
            //   self.isAccountAccess = true;
            // }
          } else if (self.isAccountAccess === 0) {
            self.isAccountAccess = 1;
          }
        }
      },
      cancelTimeInterval() {
        if (timeIntervalId) {
          clearInterval(timeIntervalId);
        }
        // self.autoRefreshAccessAccount = false;
        timeIntervalId = null;
      },
      setupTimeInterval() {
        self.refreshAccessAccount();
        // self.autoRefreshAccessAccount = true;
        timeIntervalId = setInterval(() => {
          self.refreshAccessAccount();
        }, 30000);
      },
      configAnonymousClient,
      configAuthorizedClient,
      configAuthorizedKeplrClient,
    };
  })
  .actions((self) => ({
    afterCreate() {
      // isDevelop
      const isDevelop =
        window.location.host.includes('dev.honeywood.io') ||
        window.location.host.includes('beta.honeywood.io') ||
        window.location.host.includes('localhost') ||
        window.location.host.includes('192.168.');
      self.isDevelop = isDevelop;
      // isBeta
      const isBeta = window.location.host.includes('beta.honeywood.io');
      self.isBeta = isBeta;

      // info browser
      const sayswho = (() => {
        let tem;
        const ua = navigator.userAgent;
        let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

        if (/trident/i.test(M[1])) {
          tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
          return ['IE ', tem[1] || ''];
        }
        if (M[1] === 'Chrome') {
          tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
          if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        tem = ua.match(/version\/(\d+)/i);
        if (tem != null) M.splice(1, 1, tem[1]);
        return M;
      })();

      const isMobile = /iPhone|Android/i.test(navigator.userAgent);
      const isAndroid = /Android/i.test(navigator.userAgent);
      const isIPhone = /iPhone/i.test(navigator.userAgent);
      const isSensor = /iPhone|iPad|Android/i.test(navigator.userAgent);
      let isSafari = false;
      let isChrome = false;
      let isFirefox = false;

      const browserUse = sayswho;
      if (browserUse && browserUse.length === 2) {
        const browserName = browserUse[0];
        let isOldBrowser = true;
        const majorNumberVersion = Number(browserUse[1].split('.')[0] || 1);
        if (
          (browserName.toLowerCase() === 'safari' && majorNumberVersion >= 15) ||
          (browserName.toLowerCase() === 'firefox' && majorNumberVersion >= 55) ||
          (browserName.toLowerCase() === 'chrome' && majorNumberVersion >= 54) ||
          (browserName.toLowerCase() === 'opera' && majorNumberVersion >= 41)
        ) {
          isOldBrowser = false;
        }
        if (browserName.toLowerCase() === 'safari') {
          isSafari = true;
        }
        if (browserName.toLowerCase() === 'chrome') {
          isChrome = true;
        }
        if (browserName.toLowerCase() === 'firefox') {
          isFirefox = true;
        }
        self.browserInfo = {
          name: browserUse[0],
          version: browserUse[1],
          isOldBrowser,
          isMobile,
          isAndroid,
          isSensor,
          isIPhone,
          isSafari,
          isChrome,
          isFirefox,
        };
      } else {
        self.browserInfo = { isMobile, isSensor, isIPhone, isAndroid, isSafari, isChrome, isFirefox };
      }

      // @prop - language
      const languageBrowserFormat = navigator.language || navigator.userLanguage;
      const languageBrowser = languageBrowserFormat.split('-')[0];
      const language = window.localStorage.getItem(LANGUAGE);
      if (language) {
        self.language = language;
      } else if (languageBrowser && i18n.languages.indexOf(languageBrowser) > -1) {
        self.language = languageBrowser;
      } else {
        self.language = DEFAULT_LANGUAGE;
      }
      self.languageGame = languageBrowser;

      // TODO: hot fix before get data profile from SERVER (Blockchain)
      const keySeedPhrase =
        process.env.REACT_APP_STAGE === PRODUCTION
          ? `account.${ENCRYPT_SEED_PHRASE}`
          : `account.${BETA}.${ENCRYPT_SEED_PHRASE}`;
      const keyProfile =
        process.env.REACT_APP_STAGE === PRODUCTION
          ? `account.${ENCRYPT_PROFILE}`
          : `account.${BETA}.${ENCRYPT_PROFILE}`;
      const keyWallet =
        process.env.REACT_APP_STAGE === PRODUCTION
          ? `account.${ENCRYPT_WALLET}`
          : `account.${BETA}.${ENCRYPT_WALLET}`;

      const seedNewDB = localStorage.getItem(keySeedPhrase);
      const profileDB = localStorage.getItem(keyProfile);
      const walletDB = localStorage.getItem(keyWallet);
      const profileInfo = localStorage.getItem(INFO_ACCOUNT);
      self.isAccountAccess = 0;
      self.configAnonymousClient(RPC_URL);

      // console.log('seedNewDB >> ', seedNewDB);
      // console.log('profileDB >> ', profileDB);
      // console.log('profileInfo >> ', profileInfo);

      self.isAuthorized =
        (!_.isEmpty(seedNewDB) || !_.isEmpty(walletDB)) &&
        !_.isEmpty(profileDB) &&
        !_.isEmpty(profileInfo);
      if (self.isAuthorized) {
        const profileAvailable = JSON.parse(profileInfo);
        if (!profileAvailable.avatar) {
          profileAvailable.avatar = DEFAULT_AVATAR;
        }
        self.profileAvailable = profileAvailable;

        if (!_.isEmpty(seedNewDB)) {
          self.typeAuthClient = TYPE_AUTH_CLIENT.MNEMONIC;
        } else if (!_.isEmpty(walletDB)) {
          self.typeAuthClient = TYPE_AUTH_CLIENT.KEPLR;
        }
        this.setQueue(BEARS.createQueue());
      } else {
        this.removeAuthorized();
      }
      /* CookiesPopUp logic */
      if (!window.localStorage.getItem('isCookiesAccepted')) {
        self.isOpenCookiesPopup = true;
      }
    },
    checkKeplrConnect: flow(function* () {
      yield window.keplr.enable('HoneyWood');
    }),
    setChangedAccountInWallet(isChangedAccountInWallet) {
      self.isChangedAccountInWallet = isChangedAccountInWallet;
      // automatic update state 'isChangedAccountInWallet' -> false
      if (isChangedAccountInWallet) {
        setTimeout(() => self.setChangedAccountInWallet(false), 100);
      }
    },
    beforeDestroy() {
      self.cancelTimeInterval();
    },
    translate(text) {
      // console.log(self.language);
      return i18n.t(text, { locale: self.language });
    },
    setLanguage(language) {
      self.language = language;
    },
    setLanguageGame(languageGame) {
      self.languageGame = languageGame;
    },
    setGame(isGame) {
      self.isGame = isGame;
    },
    setProfile(profile) {
      self.profile = profile;
    },
    setNativeApp(isNativeApp) {
      self.isNativeApp = isNativeApp;
    },
    setQueue(queue) {
      self.queue = queue;
    },
    setPassword(password) {
      self.password = password;

      if (password) {
        setTimeout(() => {
          this.setPassword('');
        }, COUNT_MILLISECONDS_LIFE_TIME_PASSWORD_STORE)
      }
    },
    setProfileAvailable(availableProfile) {
      self.profileAvailable = availableProfile;
    },
    setBearCreated(bearCreated) {
      self.bearCreated = bearCreated;
    },
    setUpdateStateBearCreated(updateStateBearCreated) {
      self.updateStateBearCreated = updateStateBearCreated;
    },
    setOpenMenuMobile(isOpenMenuMobile) {
      self.isOpenMenuMobile = isOpenMenuMobile;
    },
    setOpenModal(openModal) {
      self.openModal = openModal;
    },
    setPropsForModal(propsForModal) {
      self.propsForModal = propsForModal;
    },
    setExecuteModal(executeModal) {
      self.executeModal = executeModal;
    },
    setOpenPopup(openPopup) {
      self.openPopup = openPopup;
    },
    setPropsForPopup(propsForPopup) {
      self.propsForPopup = propsForPopup;
    },
    setExecutePopup(executePopup) {
      self.executePopup = executePopup;
    },

    setOpenCookiesPopup(isOpenCookiesPopup) {
      self.isOpenCookiesPopup = isOpenCookiesPopup;
    },
    setOpenCookiesManageModal(isOpenCookiesManageModal) {
      self.isOpenCookiesManageModal = isOpenCookiesManageModal;
    },
    setShowPageUpButton(isShowPageUpButton) {
      self.isShowPageUpButton = isShowPageUpButton;
    },
    setTypeAuthClient(typeAuthClient) {
      self.typeAuthClient = typeAuthClient;
    },
    setAuthorized(isAuthorized) {
      self.isAuthorized = isAuthorized;
      if (isAuthorized) {
        // self.configAnonymousClient(RPC_URL);
      }
    },
    setAccountAccess(isAccountAccess, isExpirationPassword = true) {
      if (typeof isAccountAccess === 'boolean') {
        if (isAccountAccess) {
          self.isAccountAccess += 1;
          // self.isAccountAccess = true;
        } else {
          self.isAccountAccess = 0;
        }
      }
      if (isAccountAccess && isExpirationPassword) {
        const date = new Date();
        self.passwordStartDate = date;
        const info = {
          address: self.profileAvailable.address,
          nickName: self.profileAvailable.nickName,
          date,
        };
        localStorage.setItem(INFO_ACCOUNT, JSON.stringify(info));
        self.setupTimeInterval();
      }
    },
    showPayments(isShowPayments) {
      self.isShowPayments = isShowPayments;
    },
    showPaymentsHoneyUnity(isShowPaymentsHoneyUnity) {
      self.isShowPaymentsHoneyUnity = isShowPaymentsHoneyUnity;
    },
    showPaymentsUnity(isShowPaymentsUnity) {
      self.isShowPaymentsUnity = isShowPaymentsUnity;
    },
    setStateDisplayedPaymentModal(statePaymentModal) {
      self.stateDisplayedPaymentModal = statePaymentModal;
    },
    setParamsForObjectsBlockchain(paramsForObjectsBlockchain) {
      self.paramsForObjectsBlockchain = paramsForObjectsBlockchain;
    },
    setTokenNowPayments(tokenNowPayments) {
      self.tokenNowPayments = tokenNowPayments;
    },
    setFinishKYCVerified(isFinishKYCVerified) {
      self.isFinishKYCVerified = isFinishKYCVerified;
    },
    setSignatureTransaction(signatureTransaction) {
      self.signatureTransaction = signatureTransaction;
    },
    removeAuthorized() {
      // resetSecureLocaleStorage(); // clear old Data Base;
      Logger.infoNotify('removeAuthorized', {
        type: self.typeAuthClient,
        isAuthorized: self.isAuthorized,
      }, true);
      localStorage.removeItem(INFO_ACCOUNT);
      removeSecureStorage(ENCRYPT_PROFILE);
      removeSecureStorage(ENCRYPT_SEED_PHRASE);
      removeSecureStorage(ENCRYPT_WALLET);

      self.isAuthorized = false;
      self.isAccountAccess = 0;
      self.profile = null;
      self.profileAvailable = null;
      self.cancelTimeInterval();
      self.anonymousClient = null;
      self.authorizedClient = null;
      self.typeAuthClient = null;
      self.bearCreated = BEAR_CREATED_STATE.UNAUTHORIZED;
      self.signatureTransaction = null;
      self.queue = null;
    },
    /* isInitialLoadingImages logic */
    increaseDownloadedImageCounter(src = {}) {
      // console.log(self.downloadedImageCounter, src);
      self.downloadedImageCounter += 1;
      switch (self.downloadedImageCounter) {
        case QUANTITY_DOWNLOADED_RESOURCES.HOME_PAGE:
          self.isInitialHomePageLoading = false;
          break;
        case QUANTITY_DOWNLOADED_RESOURCES.ABOUT_PAGE:
          self.isInitialAboutPageLoading = false;
          break;
        case QUANTITY_DOWNLOADED_RESOURCES.FAQ_PAGE:
          self.isInitialFaqPageLoading = false;
          break;
        case QUANTITY_DOWNLOADED_RESOURCES.PARTNERSHIP_PAGE:
          self.isInitialPartnershipPageLoading = false;
          break;
        case QUANTITY_DOWNLOADED_RESOURCES.TOKENOMICS_PAGE:
          self.isInitialTokenomicsPageLoading = false;
          break;
        case QUANTITY_DOWNLOADED_RESOURCES.ACCOUNT_PAGE:
          self.isInitialAccountPagePageLoading = false;
          break;
        case QUANTITY_DOWNLOADED_RESOURCES.TRANSACTIONS_PAGE:
          self.isInitialTransactionsPageLoading = false;
          break;
        default:
          break;
      }
    },
    reloadAllSpinners() {
      self.isInitialHomePageLoading = true;
      self.isInitialAboutPageLoading = true;
      self.isInitialFaqPageLoading = true;
      self.isInitialPartnershipPageLoading = true;
      self.isInitialTokenomicsPageLoading = true;
      self.isInitialAccountPagePageLoading = true;
      self.isInitialTransactionsPageLoading = true;
      self.downloadedImageCounter = 0;
    },
  }))
  .views((self) => ({
    get isAnonymousClientConfigured() {
      return !!self.anonymousClient;
    },
    get isAuthorizedClientConfigured() {
      return !!self.authorizedClient;
    },
  }));

export const UnityInstance = UnityStore.create({ id: 'unity' });
export const StoreInstance = AppStore.create({ unityStore: UnityInstance });
export const ContextStore = React.createContext(StoreInstance);
export const ProviderStore = ContextStore.Provider;

export const useStores = () => {
  return React.useContext(ContextStore);
};
