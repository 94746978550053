import React from 'react';
import MobileHeader from './MobileHeader';
import DesktopHeader from './DesktopHeader';

const Header = ({
  withAvatar,
  isMobile = false,
  isAccount = false,
  isLeadsBack = false,
  states,
  methods,
}) => {
  return (
    <>
      {isMobile ? (
        <MobileHeader
          states={states}
          methods={methods}
          isAccount={isAccount}
          isLeadsBack={isLeadsBack}
          withAvatar={withAvatar}
        />
      ) : (
        <DesktopHeader
          states={states}
          methods={methods}
          isAccount={isAccount}
          isLeadsBack={isLeadsBack}
          withAvatar={withAvatar}
        />
      )}
    </>
  );
};

export default Header;
