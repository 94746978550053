import React from 'react';
import PropTypes from 'prop-types';
import { useStores } from '@src/stores';
import classnames from 'classnames';
import DiagonalLineGreen from '@assets/SpecialPresaleSection/diagonal_line_green.svg';
import TetherUsdtGreenIcon from '@assets/SpecialPresaleSection/tether_usdt_green_icon.svg';
import TetherUsdtWhiteIcon from '@assets/SpecialPresaleSection/tether_usdt_white_icon.svg';
import Logger from '@utils/logger';
import styles from './button.module.scss';


export const ColorType = {
  GREEN: 'GREEN',
  ORANGE: 'ORANGE',
  PURPLE: 'PURPLE',
  RED: 'RED',
  BLUE: 'BLUE',
  GRAY: 'GRAY',
};

export const SizeButton = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
};

export const Button = ({
  width,
  height,
  title,
  size,
  onClick,
  colorType,
  classNames,
  disabled,
  isLoading,
  isPresalePackButton = false,
  isDiscount = false,
  sumItemsPrices = '',
  icon,
}) => {
  const stores = useStores();

  const presaleDiscountHtml = (
    <div className={styles.CostContainer}>
      <div className={styles.PriceDiscountContainer}>
        <img
          onLoad={() => stores.increaseDownloadedImageCounter(TetherUsdtWhiteIcon)}
          onError={() => Logger.error('Error with image loading')}
          src={TetherUsdtWhiteIcon}
          alt="tether_usdt_green_icon"
          className={styles.TetherUsdtIcon}
        />
        <p className={styles.PriceDiscountText}>&nbsp;{title}</p>
      </div>
      <div className={styles.TitleOldTotalTextContainer}>
        <img
          onLoad={() => stores.increaseDownloadedImageCounter(TetherUsdtGreenIcon)}
          onError={() => Logger.error('Error with image loading')}
          src={TetherUsdtGreenIcon}
          alt="tether_usdt_green_icon"
          className={styles.TetherUsdtIcon_Crossed}
        />
        <p className={styles.TitleOldTotalText}>&nbsp;{sumItemsPrices}</p>
        <img
          onLoad={() => stores.increaseDownloadedImageCounter(DiagonalLineGreen)}
          onError={() => Logger.error('Error with image loading')}
          src={DiagonalLineGreen}
          alt="diagonal_line_green"
          className={styles.TitleOldTotalLine}
        />
      </div>
    </div>
  );

  const presaleHtml = (
    <div className={styles.PriceContainer}>
      <img
        onLoad={() => stores.increaseDownloadedImageCounter(TetherUsdtGreenIcon)}
        onError={() => Logger.error('Error with image loading')}
        src={TetherUsdtGreenIcon}
        alt="tether_usdt_green_icon"
        className={styles.TetherUsdtIcon}
      />
      <p className={styles.PriceText}>&nbsp;{title}</p>
    </div>
  );

  return (
    <div className={classnames(styles.Container, classNames.Container)}>
      <button
        type="button"
        className={classnames(
          styles.Button,
          styles[`Button__${size}`],
          styles[`Button__${colorType}`],
          classNames.Button,
        )}
        style={{ height, width }}
        onClick={onClick}
        disabled={disabled}
      >
        {isLoading && (
          <div
            className={classnames(
              styles.ProgressIndicator,
              styles[`ProgressIndicator__${size}`],
              stores.browserInfo.isAndroid &&
                size !== SizeButton.SMALL &&
                styles.ProgressIndicator__android,
              classNames.ProgressIndicator,
            )}
          />
        )}
        {!isLoading && (
          <div className={classnames(styles.TitleContainer, styles[`TitleContainer__${size}`])}>
            {isPresalePackButton ? (
              <div
                className={classnames(
                  styles.TitleStyle,
                  styles.Title,
                  styles[`Title__${size}`],
                  classNames.Title,
                )}
              >
                {isDiscount ? presaleDiscountHtml : presaleHtml}
              </div>
            ) : (
              <>
                {icon && (
                  <img
                    onLoad={() => stores.increaseDownloadedImageCounter(icon)}
                    onError={() => Logger.error('Error with image loading')}
                    src={icon}
                    className={classnames(styles.IconButton, classNames.IconButton)}
                    alt="button_icon"
                  />
                )}
                <p
                  className={classnames(
                    styles.TitleStyle,
                    styles.Title,
                    styles[`Title__${size}`],
                    classNames.Title,
                  )}
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              </>
            )}
          </div>
        )}
      </button>
    </div>
  );
};

Button.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(SizeButton)),
  colorType: PropTypes.oneOf(Object.keys(ColorType)),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  height: PropTypes.number,
  sumItemsPrices: PropTypes.number,
  isPresalePackButton: PropTypes.bool,
  icon: PropTypes.string,
};

Button.defaultProps = {
  colorType: ColorType.GREEN,
  title: 'I love SVG',
  size: SizeButton.MEDIUM,
  onClick: () => {},
  disabled: false,
  isLoading: false,
  height: undefined,
  sumItemsPrices: undefined,
  isPresalePackButton: false,
  icon: undefined,
};

export default {
  Button,
  ColorType,
  SizeButton,
};
