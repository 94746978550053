import _ from 'lodash';
import { BEARS } from 'src/api';
import {
  BEE_TYPE,
  DECORATION_TYPE,
  TREE_TYPE,
  APIARY_TYPE,
  HONEY_TYPE,
  ITEM_TYPES,
  FIELD_TYPE,
  TAX,
  DIAMOND_STING,
  ITEMS_BEE_FEST,
  OBJECTS_BEE_DAY_FEST,
  OBJECTS_GOLDEN_CLUB,
} from '@constants/blockchain';
import { getPacksAndBlockchainObjects, savePacks } from '@utils/getPacksAndBlockchainObjects';
import { stringify, toFloatApproximation } from '@utils/common';
import { propertiesProductStoreGame, whiteListBlockchain, whiteListBlockchainInfo } from '@utils/mainBears';
import Logger from '@utils/logger';
import { GameMethodNames, GameObjectNames } from 'src/stores/unity';
import { MILLION } from '@constants/system';
import { StoreInstance } from 'src/stores';

import DynamicStatesMediator from './DynamicStatesMediator';
import { handleMainError } from '../HandleError';

const stores = StoreInstance;
/* eslint-disable max-len */

// ********************** //
// *** private methods *** //

const getStatesBearFarming = async ({ address }) => {
  const bearsIds = await BEARS.getBearsId({ address });
  Logger.infoMainWithKey('bearsIds', bearsIds);
  if (bearsIds && bearsIds.bears && bearsIds.bears.length > 0) {
    const bearsInfo = await BEARS.getBearInfo({ id: bearsIds.bears && bearsIds.bears[0] });
    if (bearsInfo && bearsInfo.fields && bearsInfo.fields.length > 0) {
      const fieldBear = await BEARS.getFieldById({ id: bearsInfo.fields && bearsInfo.fields[0] });
      if (fieldBear && bearsInfo && bearsInfo.id >= 0) {
        // Bear is exist (bear has id)
        Logger.infoMainWithKey('bearsIds', bearsIds);
        Logger.infoMainWithKey('bearsInfo', bearsInfo);
        return { bearsInfo, fieldBear };
      }
    }
  }
  return { bearsInfo: undefined, fieldBear: undefined };
};

const updateApiaryData = ({ apiary }) => {
  Logger.infoMainWithKey('Update apiary data', apiary);
  const maxHoney = toFloatApproximation({ value: apiary.maxHoney });
  const { spaceAvailable } = apiary;
  Logger.infoMainWithKey('maxHoney', maxHoney);
  stores.unityStore.send({
    objectName: GameObjectNames.LobbyReact,
    methodName: GameMethodNames.UpdateApiaryData,
    values: stringify({ kind: apiary.apiaryType, maxHoney, spaceAvailable }),
  });
};

const updateBeeData = ({ bee, blocksPerHour = stores.unityStore.blocksPerHour }) => {
  Logger.infoMainWithKey('Update bee data', bee);
  const airConsume = toFloatApproximation({ value: bee.airConsume, coef: MILLION });
  const airCountDependency = toFloatApproximation({
    value: bee.airCountDependency,
    coef: MILLION,
  });
  const honeyPerHour = toFloatApproximation({
    value: bee.honeyPerBlock,
    coef: blocksPerHour,
  });
  stores.unityStore.send({
    objectName: GameObjectNames.LobbyReact,
    methodName: GameMethodNames.UpdateBeeData,
    values: stringify({ kind: bee.beeType, airConsume, airCountDependency, honeyPerHour }),
  });
};

const updateTreeData = ({ tree }) => {
  Logger.infoMainWithKey('Update tree data', tree);
  let reward = 0;
  if (tree.reward.length > 0) {
    reward = tree.reward[0].amount;
  }
  const airSupply = toFloatApproximation({ value: tree.airSupply, coef: MILLION });
  stores.unityStore.send({
    objectName: GameObjectNames.LobbyReact,
    methodName: GameMethodNames.UpdateTreeData,
    values: stringify({ kind: tree.treeType, airSupply, reward }),
  });
};

const updateInfoObjects = async () => {
  const params = await BEARS.getParams();
  //! TODO: delete log
  Logger.infoMainWithKey('params', params);
  if (_.isEmpty(params)) {
    handleMainError(undefined, new Error('dont get params from blockchain..'));
    return;
  }
  const blocksPerHour = Number(params.blocksPerHour);
  stores.unityStore.setBlocksPerHour(blocksPerHour);
  if (!_.isEmpty(params.beeTypes) && params.beeTypes.length > 0) {
    Logger.infoMainWithKey('>> bee types', params.beeTypes);
    params.beeTypes.forEach((bee) => {
      if (whiteListBlockchainInfo({ type: BEE_TYPE, kind: bee.beeType })) {
        updateBeeData({ bee, blocksPerHour });
      }
    });
  }
  if (!_.isEmpty(params.apiaryTypes) && params.apiaryTypes.length > 0) {
    Logger.infoMainWithKey('>> apiary types', params.apiaryTypes);
    params.apiaryTypes.forEach((apiary) => {
      if (whiteListBlockchainInfo({ type: APIARY_TYPE, kind: apiary.apiaryType })) {
        updateApiaryData({ apiary });
      }
    });
  }
  if (!_.isEmpty(params.treeTypes) && params.treeTypes.length > 0) {
    Logger.infoMainWithKey('>> tree types', params.treeTypes);
    params.treeTypes.forEach((tree) => {
      if (whiteListBlockchainInfo({ type: TREE_TYPE, kind: tree.treeType })) {
        updateTreeData({ tree });
      }
    });
  }
};

export const getHoneyPacks = async () => {
  let packsHoney = [];
  await getPacksAndBlockchainObjects({
    afterHandler: (result) => {
      const packs = savePacks({ result });
      packsHoney = packs.packArrayHoney;
    }
  });

  return packsHoney;
}

// ********************** //
// *** public methods *** //

const initializeFarming = async () => {
  const { address } = stores.unityStore;
  stores.unityStore.setEnteringFarming(true);
  Logger.infoMainWithKey('>> INIT');
  let bearId = -1;
  let powerMaxBeeInit = 0;
  let fieldId = -1;
  const objectsFromBeeDay = { [DECORATION_TYPE]: [], [APIARY_TYPE]: [], [BEE_TYPE]: [] };
  const objectsFromGoldenClub = { [BEE_TYPE]: [] };
  try {
    // update info about objects (tree, apiary, bee)
    await updateInfoObjects();

    const { bearsInfo, fieldBear } = await getStatesBearFarming({ address });
    if (bearsInfo && fieldBear) {
      bearId = bearsInfo.id;
      fieldId = fieldBear.id;
      // todo: use IN game
      stores.unityStore.setBearId(bearId);
      stores.unityStore.setFieldId(fieldId);

      const responseAll = await Promise.all([
        BEARS.getApiariesInfoByBearId({ bearId }),
        BEARS.getBeesInfoByBearId({ bearId }),
        BEARS.getTreesInfoByBearId({ bearId }),
        BEARS.getDecorationsInfoByBearId({ bearId })
      ])
      // const apiaries = await BEARS.getApiariesInfoByBearId({ bearId });
      // const bees = await BEARS.getBeesInfoByBearId({ bearId });
      // const trees = await BEARS.getTreesInfoByBearId({ bearId });
      // const decorations = await BEARS.getDecorationsInfoByBearId({ bearId });
      const apiaries = responseAll[0];
      const bees = responseAll[1];
      const trees = responseAll[2];
      const decorations = responseAll[3];
      Logger.infoMainWithKey('apiaries', apiaries);
      Logger.infoMainWithKey('bees', bees);
      Logger.infoMainWithKey('trees', trees);
      Logger.infoMainWithKey('decorations', decorations);

      // update data for Bee Fest and Club
      bees.result.forEach((bee) => {
        if (OBJECTS_BEE_DAY_FEST.BEE_TYPE.includes(bee.params.beeType)) {
          if (objectsFromBeeDay.BEE_TYPE.findIndex((objBee) => objBee.beeType === bee.params.beeType) === -1) {
            objectsFromBeeDay.BEE_TYPE.push(bee.params);
            updateBeeData({ bee: bee.params });
          }
        }
        if (OBJECTS_GOLDEN_CLUB.BEE_TYPE.includes(bee.params.beeType)) {
          if (objectsFromGoldenClub.BEE_TYPE.findIndex((objBee) => objBee.beeType === bee.params.beeType) === -1) {
            objectsFromGoldenClub.BEE_TYPE.push(bee.params);
            updateBeeData({ bee: bee.params });
          }
        }
      });

      apiaries.result.forEach((apiary) => {
        if (OBJECTS_BEE_DAY_FEST.APIARY_TYPE.includes(apiary.params.apiaryType)) {
          if (objectsFromBeeDay.APIARY_TYPE.findIndex((objApiary) => objApiary.apiaryType === apiary.params.apiaryType) === -1) {
            objectsFromBeeDay.APIARY_TYPE.push(apiary.params);
            updateApiaryData({ apiary: apiary.params });
          }
        }
      });
      Logger.infoMainWithKey('items from Bee Day Fest', objectsFromBeeDay);
      Logger.infoMainWithKey('items from Golden Club', objectsFromGoldenClub);

      const countBuyFields = fieldBear.rows.length;
      stores.unityStore.send({
        objectName: GameObjectNames.FarmReact,
        methodName: GameMethodNames.InitializeGrounds,
        values: stringify({ length: countBuyFields }),
      });

      fieldBear.rows.forEach((rowObj, rowId) => {
        rowObj.columns.forEach((columnObj, columnId) => {
          if (!_.isEmpty(columnObj)) {
            // object exist on Field
            if (columnObj.item && ITEM_TYPES[columnObj.item.itemType] === APIARY_TYPE) {
              // apiary type
              const apiaryItem = apiaries.result.find((apiary) => {
                return apiary.id === columnObj.item.itemId;
              });
              stores.unityStore.send({
                objectName: GameObjectNames.FarmReact,
                methodName: GameMethodNames.InitialObj,
                values: stringify({ id: apiaryItem.id, type: APIARY_TYPE, kind: apiaryItem.params.apiaryType, rowId, columnId }),
              });
              stores.unityStore.send({
                objectName: GameObjectNames.FarmReact,
                methodName: GameMethodNames.UpdateCountBeeOnApiary,
                values: stringify({ id: apiaryItem.id, countBees: apiaryItem.bees.length }),
              });
            } else if (columnObj.item && ITEM_TYPES[columnObj.item.itemType] === TREE_TYPE) {
              // tree type
              const treeItem = trees.result.find((tree) => {
                return tree.id === columnObj.item.itemId;
              });
              stores.unityStore.send({
                objectName: GameObjectNames.FarmReact,
                methodName: GameMethodNames.InitialObj,
                values: stringify({ id: treeItem.id, type: TREE_TYPE, kind: treeItem.params.treeType, rowId, columnId }),
              });
            } else if (columnObj.item && ITEM_TYPES[columnObj.item.itemType] === DECORATION_TYPE) {
              // decoration type
              const decorationItem = decorations.result.find((decoration) => {
                return decoration.id === columnObj.item.itemId;
              });
              stores.unityStore.send({
                objectName: GameObjectNames.FarmReact,
                methodName: GameMethodNames.InitialObj,
                values: stringify({ id: decorationItem.id, type: DECORATION_TYPE, kind: decorationItem.params.decorationType, rowId, columnId }),
              });
            }
          }
        });
      });
    } else {
      stores.unityStore.send({
        objectName: GameObjectNames.FarmReact,
        methodName: GameMethodNames.InitializeGrounds,
        values: stringify({ length: 1 }),
      });
    }

    //! TODO: fix for testing interactive unity and react
    Logger.infoMainWithKey('>> InitializedGrounds');
    // initialize store and properties object
    const params = await BEARS.getParams();
    Logger.infoMainWithKey('all params', params);
    if (!_.isEmpty(params.beeTypes) && params.beeTypes.length > 0) {
      Logger.infoMainWithKey('bee types', params.beeTypes);
      params.beeTypes.forEach((bee) => {
        if (whiteListBlockchain({ type: BEE_TYPE, kind: bee.beeType })) {
          const price = bee.price[0].amount / MILLION;
          const { productEndDate, eventName } = propertiesProductStoreGame({ kind: bee.beeType });
          stores.unityStore.send({
            objectName: GameObjectNames.FarmReact,
            methodName: GameMethodNames.InitialProduct,
            values: stringify({ type: BEE_TYPE, kind: bee.beeType, price, productEndDate, eventName }),
          });
          const honeyPerHour = toFloatApproximation({ value: bee.honeyPerBlock, coef: params.blocksPerHour });
          if (bee.beeType === DIAMOND_STING) {
            powerMaxBeeInit = honeyPerHour;
            stores.unityStore.setPowerMaxBee(Number(powerMaxBeeInit));
          }
        }
      });
    }
    if (!_.isEmpty(params.apiaryTypes) && params.apiaryTypes.length > 0) {
      Logger.infoMainWithKey('apiary types', params.apiaryTypes);
      params.apiaryTypes.forEach((apiary) => {
        if (whiteListBlockchain({ type: APIARY_TYPE, kind: apiary.apiaryType })) {
          const price = apiary.price[0].amount / MILLION;
          const { productEndDate, eventName } = propertiesProductStoreGame({ kind: apiary.apiaryType });
          stores.unityStore.send({
            objectName: GameObjectNames.FarmReact,
            methodName: GameMethodNames.InitialProduct,
            values: stringify({ type: APIARY_TYPE, kind: apiary.apiaryType, price, productEndDate, eventName }),
          });
        }
      });
    }
    if (!_.isEmpty(params.decorationTypes) && params.decorationTypes.length > 0) {
      Logger.infoMainWithKey('decoration types', params.decorationTypes);
      params.decorationTypes.forEach((decoration) => {
        if (whiteListBlockchain({ type: DECORATION_TYPE, kind: decoration.decorationType })) {
          const price = decoration.price[0].amount / MILLION;
          const { productEndDate, eventName } = propertiesProductStoreGame({ kind: decoration.decorationType });
          stores.unityStore.send({
            objectName: GameObjectNames.FarmReact,
            methodName: GameMethodNames.InitialProduct,
            values: stringify({ type: DECORATION_TYPE, kind: decoration.decorationType, price, productEndDate, eventName }),
          });
        }
      });
    }
    if (!_.isEmpty(params.treeTypes) && params.treeTypes.length > 0) {
      Logger.infoMainWithKey('tree types', params.treeTypes);
      params.treeTypes.forEach((tree) => {
        if (whiteListBlockchain({ type: TREE_TYPE, kind: tree.treeType })) {
          const price = tree.price[0].amount / MILLION;
          const { productEndDate, eventName } = propertiesProductStoreGame({ kind: tree.treeType });
          stores.unityStore.send({
            objectName: GameObjectNames.FarmReact,
            methodName: GameMethodNames.InitialProduct,
            values: stringify({ type: TREE_TYPE, kind: tree.treeType, price, productEndDate, eventName }),
          });
        }
      });
    }
    if (!_.isEmpty(params.fieldTypes) && params.fieldTypes.length > 0) {
      Logger.infoMainWithKey('field types', params.fieldTypes);
      params.fieldTypes.forEach((field) => {
        if (whiteListBlockchain({ type: FIELD_TYPE, kind: field.fieldType })) {
          const price = field.priceTile[0].amount / MILLION;
          stores.unityStore.send({
            objectName: GameObjectNames.FarmReact,
            methodName: GameMethodNames.InitialProduct,
            values: stringify({ type: FIELD_TYPE, kind: field.fieldType, price }),
          });
        }
      });
    }

    const responseAll = await Promise.all([
      getHoneyPacks(),
      BEARS.getHoney({ address }),
      bearId !== -1 && DynamicStatesMediator.updateHoneyBar(),
      bearId !== -1 && DynamicStatesMediator.updateHoneyInApiaries(),
      DynamicStatesMediator.updateOxygen(),
      DynamicStatesMediator.updateCONE(),
    ]);
    // const packsHoneyStore = await getHoneyPacks();
    const packsHoneyStore = responseAll[0];
    Logger.infoMainWithKey('packsHoneyStore', packsHoneyStore);
    packsHoneyStore.forEach((packInfo) => {
      const sellingPackHoneyCount = packInfo.COUNT_HONEY;
      Logger.infoMainWithKey('pack info for honey', stringify({ type: HONEY_TYPE, kind: packInfo.ID, price: packInfo.PRICE_CONE, sellingPackHoneyCount }));
      stores.unityStore.send({
        objectName: GameObjectNames.FarmReact,
        methodName:GameMethodNames.InitialProduct,
        values: stringify({ type: HONEY_TYPE, kind: packInfo.ID, price: packInfo.PRICE_CONE, sellingPackHoneyCount })
      });
    })

    // const resultHoney = await BEARS.getHoney({ address });
    const resultHoney = responseAll[1];
    stores.unityStore.send({
      objectName: GameObjectNames.FarmReact,
      methodName: GameMethodNames.UpdateHoney,
      values: stringify({ honey: resultHoney }),
    });
    // if (bearId !== -1) {
    //   await DynamicStatesMediator.updateHoneyBar();
    //   await DynamicStatesMediator.updateHoneyInApiaries();
    // }
    // await DynamicStatesMediator.updateOxygen();
    // await DynamicStatesMediator.updateCONE();
    stores.unityStore.send({
      objectName: GameObjectNames.LobbyReact,
      methodName: GameMethodNames.LaunchFarm,
    });
  } catch (err) {
    stores.unityStore.send({
      objectName: GameObjectNames.LobbyReact,
      methodName: GameMethodNames.LaunchFarm,
    });
    handleMainError({ code: 100 }, err);
  }
};

export default {
  initializeFarming,
};
