import React from 'react';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { useStores } from 'src/stores';
import { gtagEvent } from '@utils/common';
import { GOOGLE_ANALYTICS } from '@src/constants/googleAnalyticsEvents';
import { Button, ColorType, SizeButton } from '@components_new/Button';
import { ENUM_MODAL } from '@components_new/Modal/enumModal';
import { connectKeplr } from 'src/api/KeplrWallet';
import KeplrIcon from '@assets/common/keplr_icon.svg';
import styles from './joinModalMobile.module.scss';

const JoinModalMobile = observer(({ close }) => {
  const navigate = useNavigate();
  const stores = useStores();
  const isConnectKeplr = (stores.browserInfo.isChrome || stores.browserInfo.isFirefox) && !stores.browserInfo.isMobile;

  const connectKeplrPress = async () => {
    const stateConnect = await connectKeplr();
    if (stateConnect) {
      navigate('/account');
    }
    close();
  };
  return (
    <>
      <p className={styles.InfoTitle}>{stores.translate('login_if_have_account')}</p>
      <div className={styles.ButtonsContainer}>
        <Button
          colorType={ColorType.ORANGE}
          size={SizeButton.MEDIUM}
          // uniqID={UNIQ_ID_BUTTON.JoinHeader.SignUp}
          onClick={() => {
            gtagEvent(GOOGLE_ANALYTICS.CLICK_LOGIN);
            stores.setOpenModal(ENUM_MODAL.LOGIN);
          }}
          title={stores.translate('login')}
          classNames={{
            Container: styles.ButtonContainer,
            Button: styles.Button,
            Title: classnames(styles.ButtonTitle, styles.ButtonTitle__Orange),
          }}
        />
        <Button
          colorType={ColorType.GREEN}
          size={SizeButton.MEDIUM}
          onClick={() => {
            gtagEvent(GOOGLE_ANALYTICS.CLICK_SIGN_UP);
            stores.setOpenModal(ENUM_MODAL.SEED_PHRASE);
          }}
          title={stores.translate('sign_up')}
          classNames={{
            Container: styles.ButtonContainer,
            Button: styles.Button,
            Title: classnames(styles.ButtonTitle, styles.ButtonTitle__Green),
          }}
        />
      </div>
      <p
        className={styles.ModalText}
        dangerouslySetInnerHTML={{ __html: stores.translate('proposal_agreement_sign_up') }}
      />
      {isConnectKeplr && (
        <>
          <p className={styles.KeplerTitle}>{stores.translate('if_you_have_kepler_wallet')}</p>
          <div className={styles.ButtonsContainer}>
            <Button
              colorType={ColorType.BLUE}
              isKepler
              onClick={() => {
                connectKeplrPress();
                // stores.setOpenModal(ENUM_MODAL.SEED_PHRASE);
              }}
              title={stores.translate('connect')}
              classNames={{
                Container: styles.ButtonContainer,
                Button: styles.Button,
                Title: classnames(styles.ButtonTitle, styles.ButtonTitle__Blue),
                IconButton: styles.KeplrButton,
              }}
              icon={KeplrIcon}
            />
          </div>
        </>
      )}
    </>
  );
});

export default JoinModalMobile;
