import { ENUM_POPUP } from '@components/PopUp/enumPopup';
import { BEAR_CREATED_STATE, DEFAULT_AVATAR, ENCRYPT_PROFILE, ENCRYPT_SEED_PHRASE, ENCRYPT_WALLET, RPC_URL } from '@constants/system';
import { isBearCreated } from '@utils/mainBears';
import _ from 'lodash';
import { BEARS } from '@api';
import { getSecureStorage, setSecureStorage } from 'src/stores/system';
import { StoreInstance } from 'src/stores';
import { GOOGLE_ANALYTICS } from 'src/constants/googleAnalyticsEvents';
import { CHRISTMAS_DAY, TYPE_AUTH_CLIENT } from '@constants/blockchain';
import KeplrWallet, { accessAndInitKeplrClient } from 'src/api/KeplrWallet';
import Logger from '@utils/logger';
import { CRYPTO } from 'src/api/crypto';
import { gtagEvent } from './common';
import { isSpecialEventDayPacks } from './getPacksAndBlockchainObjects';

const stores = StoreInstance;

const logicPaymentsModal = async () => {
  // logic when open selling pack in Section Payment
  if (stores.stateDisplayedPaymentModal) {
    const bearCreated = await isBearCreated();
    stores.setBearCreated(bearCreated);
    if (isSpecialEventDayPacks({ events: [CHRISTMAS_DAY] , packID: stores.stateDisplayedPaymentModal.packInfo.ID })) {
      stores.showPayments(true);
      return;
    }

    if (stores.stateDisplayedPaymentModal.packInfo.INIT_SET === false && bearCreated === BEAR_CREATED_STATE.NO_CREATED) {
      stores.setOpenPopup(ENUM_POPUP.SELLING_PACK_NO_PURCHASED_INIT);
    } else if (stores.stateDisplayedPaymentModal.packInfo.INIT_SET && bearCreated === BEAR_CREATED_STATE.CREATED) {
      stores.setOpenPopup(ENUM_POPUP.SELLING_PACK_HAS_BEEN_PURCHASED);
    } else {
      stores.showPayments(true);
    }
  }
}

export const checkPasswordAuth = async ({ password = undefined, finish = ()=>{} }) => {
  try {
    const profileSecureNew = getSecureStorage(ENCRYPT_PROFILE, password);
    if (!_.isEmpty(profileSecureNew) && profileSecureNew.result !== 'error') {
      let wallet = {};
      let account;
      if (stores.typeAuthClient === TYPE_AUTH_CLIENT.MNEMONIC) {
        const mnemonic = getSecureStorage(ENCRYPT_SEED_PHRASE, password);
        ({ wallet, account } = await BEARS.getAccount({ mnemonic, isPrivKeys: true }));
        await stores.configAuthorizedClient(wallet, RPC_URL);
        stores.setAccountAccess(true);
        stores.setProfile({ ...stores.profile, wallet, account });
        stores.setProfileAvailable({ ...stores.profileAvailable, wallet, account });
        stores.setPassword(password);
      } else if (stores.typeAuthClient === TYPE_AUTH_CLIENT.KEPLR) {
        await KeplrWallet.accessAndInitKeplrClient(); // TODO: not handled that method return true/false
      }
      await logicPaymentsModal();
      finish(true);
      return true;
    }
    finish(false);
    return false;
  } catch (err) {
    Logger.error(err);
    finish(false);
    return false;
  }
};

export const login = async ({ mnemonic, password, code = undefined, setErrorText, closeModal, navigate }) => {
  const isValidPhrase = CRYPTO.isValidMnemonic({ mnemonic });
  if (!isValidPhrase) {
    setErrorText(stores.translate('not_valid_mnemonic'));
    return;
  }
  const { wallet, account } = await BEARS.getAccount({ mnemonic, isPrivKeys: true });
  if (!_.isEmpty(wallet)) {
    const nickName = account.address;
    try {
      await stores.configAnonymousClient(RPC_URL);
      await stores.configAuthorizedClient(wallet, RPC_URL);
    } catch (err) {
      Logger.error(err);
    } finally {
      const profile = {
        address: account.address,
        nickName,
        email: null,
        wallet,
        account,
        cones: 0,
        honey: 0,
        avatar: DEFAULT_AVATAR,
      };
      setSecureStorage(ENCRYPT_PROFILE, profile, password);
      setSecureStorage(ENCRYPT_SEED_PHRASE, mnemonic, password);
      stores.setProfile(profile);
      stores.setProfileAvailable(profile);
      stores.setAuthorized(true);
      stores.setAccountAccess(true);
      stores.setTypeAuthClient(TYPE_AUTH_CLIENT.MNEMONIC);
      stores.setPassword(password);
      stores.setQueue(BEARS.createQueue());

      if (stores.stateDisplayedPaymentModal) {
        const bearCreated = await isBearCreated();
        if (
          stores.stateDisplayedPaymentModal.packInfo.INIT_SET &&
          bearCreated === BEAR_CREATED_STATE.CREATED
        ) {
          stores.setOpenPopup(ENUM_POPUP.SELLING_PACK_HAS_BEEN_PURCHASED);
        } else if (
          !stores.stateDisplayedPaymentModal.packInfo.INIT_SET &&
          bearCreated === BEAR_CREATED_STATE.NO_CREATED
        ) {
          stores.setOpenPopup(ENUM_POPUP.SELLING_PACK_NO_PURCHASED_INIT);
        } else {
          stores.showPayments(true);
        }

        closeModal();
      } else {
        gtagEvent(GOOGLE_ANALYTICS.CLICK_LOGIN);
        closeModal();
        navigate('/account', { state: { code } });
      }
    }
  } else {
    setErrorText('Don`t create wallet');
  }
};

export default {
  checkPasswordAuth,
  login,
};