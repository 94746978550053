import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _ from 'lodash';
import { useMediaQuery } from 'src/helpers/media';
import { observer } from 'mobx-react';
import { useStores } from 'src/stores';
import { gtagEvent } from '@utils/common';
import { checkPasswordAuth } from '@utils/authorization';
import { GOOGLE_ANALYTICS } from '@src/constants/googleAnalyticsEvents';

import Input from '@components/Input';
import { Button, ColorType, SizeButton } from '@components_new/Button';

import styles from './verificationWalletAccessModalMobile.module.scss';

const VerificationWalletAccessModalMobile = observer(({ close }) => {
  const stores = useStores();
  const [isLoading, setLoading] = useState(false);
  const width300 = useMediaQuery('(max-width: 300px)');

  const schemaModal = yup
    .object()
    .shape({
      password: yup.string().min(5, stores.translate('password_input_validation')).required(),
    })
    .required();

  const ModalForm = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schemaModal),
    defaultValues: {
      password: '',
    },
  });
  const modalFields = ModalForm.watch();

  const finishPasswordClicked = (success) => {
    if (!success) {
      ModalForm.setError('password', {
        type: 'manual',
        message: stores.translate('password_incorrect'),
      });
    } else {
      close();
    }
  };

  const onCheckPasswordClick = async () => {
    setLoading(true);
    await checkPasswordAuth({
      password: modalFields.password,
      finish: finishPasswordClicked,
    });
    setLoading(false);
  };

  const handleKeypress = (e) => {
    if (e.key && e.key.toLowerCase() === 'enter') {
      ModalForm.handleSubmit(onCheckPasswordClick)();
    }
  };

  const onQuitAccount = () => {
    stores.removeAuthorized();
    ModalForm.reset();
    close();
  };

  return (
    <>
      <Input
        placeholder={stores.translate('password_input_placeholder')}
        canError
        label={stores.translate('password')}
        error={ModalForm.formState.errors?.password}
        name="password"
        register={ModalForm.register}
        autoComplete="no"
        isButtonSecretShow
        onKeyDown={handleKeypress}
        classNames={{
          Label: styles.LabelInput,
          InputContainer: styles.Input_InputContainer,
          Container: styles.Input_Container,
          Input: styles.Input_Input,
          InputButtonShow: styles.Input_InputButtonShow,
        }}
      />
      <div className={styles.ButtonsContainer}>
        <Button
          colorType={ColorType.GREEN}
          onClick={() => {
            gtagEvent(GOOGLE_ANALYTICS.CLICK_UNLOCK);
            ModalForm.handleSubmit(onCheckPasswordClick)();
          }}
          title={stores.translate('unlock')}
          classNames={{
            Container: styles.ButtonContainer,
            Button: styles.Button,
            Title: classnames(styles.ButtonTitle, styles.ButtonTitle__Green),
          }}
          isLoading={isLoading}
        />
        <Button
          colorType={ColorType.ORANGE}
          onClick={() => {
            gtagEvent(GOOGLE_ANALYTICS.CLICK_QUIT);
            onQuitAccount();
          }}
          title={width300 ? stores.translate('quit') : stores.translate('quit_account')}
          classNames={{
            Container: styles.ButtonContainer,
            Button: styles.Button,
            Title: classnames(styles.ButtonTitle, styles.ButtonTitle__Orange),
          }}
          isLoading={isLoading}
        />
      </div>
    </>
  );
});

export default VerificationWalletAccessModalMobile;
