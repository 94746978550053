import { StoreInstance } from "src/stores";
import { ENUM_POPUP } from "./enumPopup";

const stores = StoreInstance;

const approveChangeAvatar = async ({ honey }) => {
  stores.setExecutePopup({
    name: ENUM_POPUP.CHANGE_APPROVE_AVATAR,
    state: { honey },
  });
};

const approveChangeNickname = async () => {
  stores.setExecutePopup({
    name: ENUM_POPUP.CHANGE_APPROVE_NICKNAME,
  });
};

export default {
  approveChangeAvatar,
  approveChangeNickname,
};
