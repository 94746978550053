import React from 'react';
import classnames from 'classnames';
import { sanitize } from 'dompurify';
import { useStores } from 'src/stores';
import { observer } from 'mobx-react';
import { Button, ColorType, SizeButton } from '@components_new/Button';
import URLS from '@src/constants/urls';
import styles from './cookiesPopUp.module.scss';

const CookiesPopUp = observer(() => {
  const stores = useStores();
  return (
    <div className={styles.CookiesPopUpContainer}>
      <div className={styles.CookiesPopUpText}>
        <p>
          {stores.translate('cookies_popup_text')}
          <a
            target="_blank"
            rel="noreferrer"
            href={URLS.COOKIE_POLICY}
            className={styles.PrivacyAndTermsLink}
          >
            {stores.translate('cookie_policy')}
          </a>
        </p>
      </div>
      <div className={styles.ButtonsContainer}>
        <Button
          colorType={ColorType.GREEN}
          size={SizeButton.SMALL}
          onClick={() => {
            window.localStorage.setItem('isCookiesAccepted', true);
            stores.setOpenCookiesPopup(false);
          }}
          title={stores.translate('accept')}
          classNames={{
            Container: styles.ButtonContainer,
            Button: styles.AcceptButton,
            Title: classnames(styles.ButtonTitle, styles.ButtonTitle__Green),
          }}
        />
        <Button
          colorType={ColorType.ORANGE}
          size={SizeButton.SMALL}
          onClick={() => {
            stores.setOpenCookiesPopup(false);
            stores.setOpenCookiesManageModal(true);
          }}
          title={stores.translate('manage_cookies')}
          classNames={{
            Container: styles.ButtonContainer,
            Button: styles.ManageButton,
            Title: classnames(styles.ButtonTitle, styles.ButtonTitle__Orange),
          }}
        />
        <div className={styles.DenyButtonWrapper}>
          <div
            onClick={() => {
              window.localStorage.setItem('isCookiesAccepted', true);
              stores.setOpenCookiesPopup(false);
            }}
            onKeyPress={() => {
              window.localStorage.setItem('isCookiesAccepted', true);
              stores.setOpenCookiesPopup(false);
            }}
            type="button"
            role="button"
            tabIndex="0"
          >
            <p
              className={styles.DenyButtonText}
              dangerouslySetInnerHTML={{ __html: sanitize(stores.translate('deny_cookies')) }}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default CookiesPopUp;
