import React from 'react';
import Layout from '@layout/Layout';
import CapitalSection from './components/CapitalSection';
import TokenomicsSection from './components/TokenomicsSection';
import VestingSection from './components/VestingSection';
import ParticipateSection from './components/ParticipateSection';

const MobileTokenomicsPage = () => {
  return (
    <Layout isMobile isLoadingFinish>
      <CapitalSection />
      <TokenomicsSection />
      <VestingSection />
      <ParticipateSection />
    </Layout>
  );
};

export default MobileTokenomicsPage;
