import React, { useState } from 'react';
import classnames from 'classnames';
import { useStores } from 'src/stores';
import CheckBoxSelectedMobile from '@src/assets/control/checkbox_selected_mobile.svg';
import CheckBoxDefaultMobile from '@src/assets/control/checkbox_mobile.svg';
import CheckBoxHoverMobile from '@src/assets/control/checkbox_hover_mobile.svg';
import CheckBoxSelectedHoverMobile from '@src/assets/control/checkbox_selected_hover_mobile.svg';
import CheckBoxEnabledMobile from '@src/assets/control/checkbox_enabled_mobile.svg';
import CheckBoxErrorMobile from '@src/assets/control/checkbox_error_mobile.svg';

import Logger from '@utils/logger';
import styles from './checkbox.module.scss';

const CheckBox = ({
  selected = false,
  onChange = () => {},
  title = '',
  classNames = {},
  disabled = false,
  enabled = false,
  isError = false,
}) => {
  const stores = useStores();
  const [hover, setHover] = useState(false);
  return (
    <div
      className={classnames(
        styles.CheckBoxComponent__Container,
        (disabled || enabled) && styles.CheckBoxComponent__Container_disabled,
        classNames.Container,
      )}
      onClick={() => {
        if (!(disabled || enabled)) {
          onChange(!selected);
        }
      }}
      role="checkbox"
      aria-checked="false"
      tabIndex="0"
      onKeyPress={() => {
        if (!(disabled || enabled)) onChange(!selected);
      }}
    >
      <div
        onMouseLeave={() => setHover(false)}
        onMouseEnter={() => setHover(!stores.browserInfo.isSensor && true)}
        className={classnames(styles.CheckBox__ContainerImage)}
      >
        {isError ? (
          <img
            onLoad={() => stores.increaseDownloadedImageCounter()}
            onError={() => Logger.error('Error with image loading')}
            src={CheckBoxErrorMobile}
            alt="checkbox_enabled_mobile"
            className={classNames.ContainerImage}
          />
        ) : enabled ? (
          <img
            onLoad={() => stores.increaseDownloadedImageCounter()}
            onError={() => Logger.error('Error with image loading')}
            src={CheckBoxEnabledMobile}
            alt="checkbox_enabled_mobile"
            className={classNames.ContainerImage}
          />
        ) : hover ? (
          <img
            onLoad={() => stores.increaseDownloadedImageCounter()}
            onError={() => Logger.error('Error with image loading')}
            src={selected ? CheckBoxSelectedHoverMobile : CheckBoxHoverMobile}
            alt="CheckBox_hover"
            className={classNames.ContainerImage}
          />
        ) : selected ? (
          <img
            onLoad={() => stores.increaseDownloadedImageCounter()}
            onError={() => Logger.error('Error with image loading')}
            src={CheckBoxSelectedMobile}
            alt="CheckBox_selected"
            className={classNames.ContainerImage}
          />
        ) : (
          <img
            onLoad={() => stores.increaseDownloadedImageCounter()}
            onError={() => Logger.error('Error with image loading')}
            src={CheckBoxDefaultMobile}
            alt="CheckBox_default"
            className={classNames.ContainerImage}
          />
        )}
      </div>

      <p
        className={classnames(
          styles.CheckBoxComponent__Title,
          selected && styles.CheckBoxComponent__Title_selected,
          (disabled || enabled) && styles.CheckBoxComponent__Title_disabled,
          classNames.Title,
        )}
      >
        {title}
      </p>
    </div>
  );
};

export default CheckBox;
