import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { useStores } from '@src/stores';
import { useNavigate } from 'react-router-dom';
import URLS from '@src/constants/urls';
import SocialNetwork from '@src/components/SocialNetwork';
import { GOOGLE_ANALYTICS } from '@src/constants/googleAnalyticsEvents';
import { gtagEvent } from '@utils/common';
import LeftBottomImageMobile from '@assets/Mobile/Footer/left_bottom_image_mobile.png';
import LeftBottomImageTablet from '@assets/Mobile/Footer/left_bottom_image_tablet.png';
import LeftBottomImageDesktop from '@assets/Mobile/Footer/left_bottom_image_desktop.png';
import RightBottomImageMobile from '@assets/Mobile/Footer/right_bottom_image_mobile.png';
import RightBottomImageTablet from '@assets/Mobile/Footer/right_bottom_image_tablet.png';
import RightBottomImageDesktop from '@assets/Mobile/Footer/right_bottom_image_desktop.png';
import HoneyWoodLogo from '@assets/common/honeywood_moneta_logo.png';
import Logger from '@utils/logger';
import styles from './footer.module.scss';

const DesktopFooter = observer(
  ({ isAccount = false, isTransactionsPage = false, states, methods }) => {
    const stores = useStores();
    const _ = stores.language;
    const navigate = useNavigate();

    return (
      <>
        <div
          className={classnames(
            styles.FooterContainer,
            (isAccount && styles.FooterContainer_isAccount) ||
              (isTransactionsPage && styles.FooterContainer_isAccount),
          )}
        >
          <img
            onLoad={() => stores.increaseDownloadedImageCounter(LeftBottomImageMobile)}
            onError={() => Logger.error('Error with image loading')}
            src={LeftBottomImageMobile}
            alt="left_bottom_image_mobile"
            className={styles.LeftBottomImageMobile}
          />
          <img
            onLoad={() => stores.increaseDownloadedImageCounter(LeftBottomImageTablet)}
            onError={() => Logger.error('Error with image loading')}
            src={LeftBottomImageTablet}
            alt="left_bottom_image_tablet"
            className={styles.LeftBottomImageTablet}
          />
          <img
            onLoad={() => stores.increaseDownloadedImageCounter(LeftBottomImageDesktop)}
            onError={() => Logger.error('Error with image loading')}
            src={LeftBottomImageDesktop}
            alt="left_bottom_image_desktop"
            className={styles.LeftBottomImageDesktop}
          />
          <img
            onLoad={() => stores.increaseDownloadedImageCounter(RightBottomImageMobile)}
            onError={() => Logger.error('Error with image loading')}
            src={RightBottomImageMobile}
            alt="right_bottom_image_mobile"
            className={styles.RightBottomImageMobile}
          />
          <img
            onLoad={() => stores.increaseDownloadedImageCounter(RightBottomImageTablet)}
            onError={() => Logger.error('Error with image loading')}
            src={RightBottomImageTablet}
            alt="right_bottom_image_tablet"
            className={styles.RightBottomImageTablet}
          />
          <img
            onLoad={() => stores.increaseDownloadedImageCounter(RightBottomImageDesktop)}
            onError={() => Logger.error('Error with image loading')}
            src={RightBottomImageDesktop}
            alt="right_bottom_image_desktop"
            className={styles.RightBottomImageDesktop}
          />
          <div className={styles.ButtonLinksAndSocialsContainerWrapper}>
            <div className={styles.ButtonLinksAndSocialsContainer}>
              <div className={styles.ButtonLinks}>
                {states.menu.map((menuItem, index) => {
                  return menuItem.isLink ? (
                    <a
                      href={methods.getHeaderLinkInfo(menuItem.name)[0]}
                      target="_blank"
                      rel="noreferrer"
                      role="button"
                      className={styles.MenuContainer__item}
                      key={`header_${menuItem.name}`}
                      tabIndex={index}
                      onClick={() => {
                        gtagEvent(methods.getHeaderLinkInfo(menuItem.name)[1]);
                      }}
                    >
                      <p
                        className={styles.MenuContainer__title}
                        id={`anchor_header_${menuItem.name}`}
                      >
                        {menuItem.translate}
                      </p>
                    </a>
                  ) : (
                    <div
                      role="button"
                      className={classnames(
                        styles.MenuContainer__item,
                        menuItem.name === 'selling-pack' && styles.MenuContainer__item_SellingPack,
                      )}
                      key={`header_${menuItem.name}`}
                      onClick={() => {
                        if (
                          window.location.pathname !== methods.getHeaderLinkRoute(menuItem.name)
                        ) {
                          stores.reloadAllSpinners();
                          navigate(methods.getHeaderLinkRoute(menuItem.name));
                        } else {
                          window.scrollTo(0, 0);
                        }
                      }}
                      onKeyDown={() => {
                        if (
                          window.location.pathname !== methods.getHeaderLinkRoute(menuItem.name)
                        ) {
                          stores.reloadAllSpinners();
                          navigate(methods.getHeaderLinkRoute(menuItem.name));
                        } else {
                          window.scrollTo(0, 0);
                        }
                      }}
                      tabIndex={index}
                    >
                      <p
                        className={classnames(
                          styles.MenuContainer__title,
                          menuItem.name === 'selling-pack' &&
                            styles.MenuContainer__item_SellingPack,
                        )}
                        id={`anchor_header_${menuItem.name}`}
                      >
                        {menuItem.translate}
                      </p>
                    </div>
                  );
                })}
              </div>
              <div className={styles.SocialContainer}>
                <SocialNetwork classNames={{ Container: styles.SocialInnerContainer }} />
                <div className={styles.MailContainer}>
                  <a
                    href="mailto:media@honeywood.io"
                    className={styles.MailLink}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      gtagEvent(GOOGLE_ANALYTICS.CLICK_MAIL);
                    }}
                  >
                    <p className={styles.MailText}>media@honeywood.io</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.InfoContainer}>
            <div className={styles.ContactUsContainer}>
              <a href="/">
                <img
                  onLoad={() => stores.increaseDownloadedImageCounter(HoneyWoodLogo)}
                  onError={() => Logger.error('Error with image loading')}
                  src={HoneyWoodLogo}
                  alt="honeywood_moneta_logo"
                  className={styles.HoneyWoodLogo}
                />
              </a>
              <div
                role="button"
                onClick={() => window.open(URLS.MEDIA_KIT, '_blank', 'noopener,noreferrer')}
                onKeyDown={() => window.open(URLS.MEDIA_KIT, '_blank', 'noopener,noreferrer')}
                tabIndex={0}
              >
                <p className={styles.ContactUsText}>{stores.translate('media_kit')}</p>
              </div>
            </div>
            <div className={styles.RightsContainer}>
              <p className={styles.RightsText}>{stores.translate('2022_mt_all_rights_reserved')}</p>
              <div className={styles.PrivacyAndTermsContainer}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={URLS.PRIVACY_POLICY}
                  className={classnames(styles.RightsText, styles.PrivacyLink)}
                >
                  {stores.translate('privacy_policy')}
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={URLS.TERMS_OF_USE}
                  className={classnames(styles.RightsText, styles.TermsLink)}
                >
                  {stores.translate('terms_of_use_capital_letter')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  },
);

export default DesktopFooter;
