import React, { useEffect } from 'react';
import HeadTags from '@components_new/HeadTags';
import { useMediaQuery } from '@src/helpers/media';
import MobileFaqPage from '@src/pages/FaqPage/MobileFaqPage';
import DesktopFaqPage from '@src/pages/FaqPage/DesktopFaqPage';
import BeeSpinner from '@components/BeeSpinner';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import styles from './faq.module.scss';

const FaqPage = observer(() => {
  const stores = useStores();
  const isMobileSize = useMediaQuery('(max-width: 999.99px)');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HeadTags description="FAQ | We will tell you how to play. Time to build your sweet home here! Fight with other players in match3 and Earn by playing 5 minutes a day." />
      {stores.browserInfo.isMobile || (isMobileSize && !stores.browserInfo.isMobile) ? (
        <MobileFaqPage />
      ) : (
        <>
          {stores.isInitialFaqPageLoading && (
            <BeeSpinner classNames={{ Container: styles.Container }} />
          )}
          <DesktopFaqPage isInitialLoading={stores.isInitialFaqPageLoading} />
        </>
      )}
    </>
  );
});

export default FaqPage;
