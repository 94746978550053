/* eslint-disable */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'src/stores';
import { Button, ColorType, UNIQ_ID_BUTTON } from '@components/Button';
import { useMediaQuery } from 'src/helpers/media';
import { ENUM_MODAL } from '@components_new/Modal/enumModal';

import HomePage from '../HomePage';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';

const SignUpPage = observer(() => {
  const stores = useStores();
  const isMobileSize = useMediaQuery('(max-width: 999.99px)');

  const calculateAirdropCode = () => {
    const authResult = new URLSearchParams(window.location.search); 
    const code = authResult.get('code');
    // console.log('code = ', code);
    return code;
  }

  useEffect(() => {
    if (
      stores.browserInfo.isMobile ||
      (isMobileSize && !stores.browserInfo.isMobile) ||
      !stores.isInitialHomePageLoading
    ) {
      stores.setPropsForModal({ code: calculateAirdropCode() });
      stores.setOpenModal(ENUM_MODAL.SEED_PHRASE);
    }
  }, [stores.isInitialHomePageLoading]);

  return stores.isAuthorized ? (
    <Navigate to="/account" state={{ code: calculateAirdropCode() }} />
  ) : (
    <HomePage />
  );
});

export default SignUpPage;
