import React from 'react';
import { observer } from 'mobx-react';
import { sanitize } from 'dompurify';
import classnames from 'classnames';
import { useStores } from '@src/stores';
import { useMediaQuery } from 'src/helpers/media';
import HoneywoodBannerTablet from '@assets/PlayToEarnBanner/honeywood_banner_tablet.png';
import HoneywoodBannerDesktop from '@assets/PlayToEarnBanner/honeywood_banner_desktop.png';
import PlayEarnLogo from '@assets/Mobile/HomePage/PlayToEarnBanner/play_earn_logo.png';
import HoneywoodLogo from '@assets/common/honeywood_logo.png';
import Logger from '@utils/logger';
import styles from './mobilePlayToEarnBanner.module.scss';

const PlayToEarnBanner = observer(({ classNames = {} }) => {
  const stores = useStores();
  const _ = stores.language;
  const isLessThan1260px = useMediaQuery('(max-width: 1260px)');
  return (
    <div
      className={classnames(styles.PlayToEarnBannerContainer, classNames.Container)}
      id="play-to-earn-banner"
    >
      <img
        onLoad={() => stores.increaseDownloadedImageCounter(HoneywoodBannerTablet)}
        onError={() => Logger.error('Error with image loading')}
        src={HoneywoodBannerTablet}
        alt="honeywood_banner_small"
        className={styles.HoneywoodBannerTablet}
      />
      <img
        onLoad={() => stores.increaseDownloadedImageCounter(HoneywoodBannerDesktop)}
        onError={() => Logger.error('Error with image loading')}
        src={HoneywoodBannerDesktop}
        alt="honeywood_banner_small"
        className={styles.HoneywoodBannerDesktop}
      />
      <div className={styles.InfoContainer}>
        <a
          href="https://playtoearn.net/blockchaingame/honeywood"
          target="_blank"
          rel="noreferrer"
          className={styles.TitleLink}
        >
          <p
            className={styles.Title}
            dangerouslySetInnerHTML={{
              __html: sanitize(
                isLessThan1260px
                  ? stores.translate('we_are_on_playtoearn_net')
                  : stores.translate('we_are_on_playtoearn_net_desktop'),
              ),
            }}
          />
        </a>
        <div className={styles.LogoContainer}>
          <img
            onLoad={() => stores.increaseDownloadedImageCounter(HoneywoodLogo)}
            onError={() => Logger.error('Error with image loading')}
            src={HoneywoodLogo}
            alt="honeywood_logo"
            className={styles.HoneywoodLogo}
          />
          <p className={styles.Plus}>+</p>
          <a
            href="https://playtoearn.net/blockchaingame/honeywood"
            target="_blank"
            rel="noreferrer"
            className={styles.PlayEarnLogoLink}
          >
            <img
              onLoad={() => stores.increaseDownloadedImageCounter(PlayEarnLogo)}
              onError={() => Logger.error('Error with image loading')}
              src={PlayEarnLogo}
              alt="play_earn_logo"
              className={styles.PlayEarnLogo}
            />
          </a>
        </div>
      </div>
    </div>
  );
});

export default PlayToEarnBanner;
