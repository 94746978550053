import React, { forwardRef, useRef, useEffect } from 'react';
import classnames from 'classnames';
import { useMediaQuery } from 'src/helpers/media';
import styles from './inputWord.module.scss';

const InputWord = (
  {
    register,
    name,
    value,
    placeholder = ' ',
    type = 'text',
    isError = false,
    readOnly = false,
    classNames = {},
    autoCapitalize = 'none',
    onChange = () => {},
    onMnemonicPhrase = () => {},
    isUpdateInput = false,
    ...props
  },
  ref,
) => {
  const isLessThan500px = useMediaQuery('(max-width: 500px)');
  const textRef = useRef();

  useEffect(() => {
    if (!ref || !ref.current) return () => {};

    const updateTextValue = (text) => {
      if (text) {
        const arrWords = text.target.value.trim().split(' ');
        if (arrWords && arrWords.length === 12 && name === 'word1') {
          [textRef.current.innerText] = arrWords;
          [ref.current.value] = arrWords;
          onMnemonicPhrase(arrWords);
        } else {
          textRef.current.innerText = text.target.value;
        }
      } else if (ref.current.value) {
        textRef.current.innerText = ref.current.value;
      } else {
        textRef.current.innerText = value;
      }
    };

    ref.current.addEventListener('input', updateTextValue);
    if (!!value || isUpdateInput) {
      updateTextValue();
    }
    return () => {
      ref.current.removeEventListener('input', updateTextValue);
    };
  }, [ref, value, isUpdateInput]);

  let inputRef;
  let registerInput;
  if (ref && register && name && register(name)) {
    ({ ref: inputRef, ...registerInput } = register(name));
  }

  return (
    <div
      className={classnames(
        styles.InputContainer,
        `InputContainer_${name}`,
        classNames.InputContainer,
        isError && styles.InputContainer__error,
      )}
    >
      <input
        ref={(e) => {
          if (ref && !ref.current) {
            ref.current = e;
          }
          if (inputRef) {
            inputRef(e);
          }
        }}
        className={classnames(
          styles.Input,
          `Input_${name}`,
          classNames.Input,
          isError && styles.Input__error,
        )}
        placeholder={placeholder}
        value={value}
        readOnly={readOnly}
        onChange={onChange}
        autoCapitalize={autoCapitalize}
        style={{ width: isLessThan500px ? 60 : 80 }}
        {...registerInput}
        {...(!ref && register(name))}
        {...props}
      />
      <span ref={textRef} className={classnames(styles.AbsoluteText, `AbsoluteText_${name}`)} />
    </div>
  );
};

export default forwardRef(InputWord);
