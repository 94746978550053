export const ENUM_MODAL = {
  // CONTACT_US: 'CONTACT_US',
  // JOIN: 'JOIN',
  // SEED_PHRASE: 'SEED_PHRASE',
  // LOGIN: 'LOGIN',
  // VERIFICATION_ACCESS_WALLET: 'VERIFICATION_ACCESS_WALLET',
  // CHANGE_NICKNAME: 'CHANGE_NICKNAME',
  // CHANGE_AVATAR: 'CHANGE_AVATAR',
  // PAYMENTS: 'payments',

  // UNITY_STACKING: 'unity-stake',
  UNITY_APPROVE_TRANSACTION: 'UNITY_APPROVE_TRANSACTION',
  UNITY_VERIFY_YOURSELF: 'UNITY_VERIFY_YOURSELF',
  UNITY_CHECK_IN_DAILY_BONUS: 'UNITY_CHECK_IN_DAILY_BONUS',
  UNITY_STACKING: 'UNITY_STACKING',
  UNITY_UNSTACKING: 'UNITY_UNSTACKING',
  UNITY_HALLOWEEN: 'UNITY_HALLOWEEN',
}

export default {
  ENUM_MODAL,
}