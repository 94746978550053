import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';

import Telegram from '@assets/socials/telegram.svg';
import TelegramHover from '@assets/socials/telegram_hover.svg';

import Instagram from '@assets/socials/instagram.svg';
import InstagramHover from '@assets/socials/instagram_hover.svg';

import Facebook from '@assets/socials/facebook.svg';
import FacebookHover from '@assets/socials/facebook_hover.svg';

import Twitter from '@assets/socials/twitter.svg';
import TwitterHover from '@assets/socials/twitter_hover.svg';

import Linkedin from '@assets/socials/linkedin.svg';
import LinkedinHover from '@assets/socials/linkedin_hover.svg';

import Youtube from '@assets/socials/youtube.svg';
import YoutubeHover from '@assets/socials/youtube_hover.svg';

import Discord from '@assets/socials/discord.svg';
import DiscordHover from '@assets/socials/discord_hover.svg';

import Medium from '@assets/socials/medium.svg';
import MediumHover from '@assets/socials/medium_hover.svg';

import Logger from '@utils/logger';
import styles from './socialsPersonal.module.scss';

const SocialsPersonal = observer(({ socialLinks }) => {
  const stores = useStores();
  const [hoverSocialImage, setHoverSocialImage] = useState(null);

  const getSocialImage = (socialImage) => {
    switch (socialImage) {
      case 'telegram':
        return hoverSocialImage === 'telegram' ? TelegramHover : Telegram;
      case 'instagram':
        return hoverSocialImage === 'instagram' ? InstagramHover : Instagram;
      case 'facebook':
        return hoverSocialImage === 'facebook' ? FacebookHover : Facebook;
      case 'twitter':
        return hoverSocialImage === 'twitter' ? TwitterHover : Twitter;
      case 'linkedin':
        return hoverSocialImage === 'linkedin' ? LinkedinHover : Linkedin;
      case 'youtube':
        return hoverSocialImage === 'youtube' ? YoutubeHover : Youtube;
      case 'discord':
        return hoverSocialImage === 'discord' ? DiscordHover : Discord;
      case 'medium':
        return hoverSocialImage === 'medium' ? MediumHover : Medium;
      default:
        return Telegram;
    }
  };

  return (
    <div className={styles.SocialContainer}>
      {socialLinks.map((social) => {
        return (
          <a
            key={`social_${social.name}`}
            target="_blank"
            rel="noreferrer"
            href={social.link}
            className={styles.SocialLink}
            onMouseEnter={() =>
              setHoverSocialImage(
                !stores.browserInfo.isSensor && !stores.browserInfo.isMobile && social.name,
              )
            }
            onMouseLeave={() => setHoverSocialImage(undefined)}
          >
            <img
              onLoad={() => stores.increaseDownloadedImageCounter(getSocialImage(social.name))}
              onError={() => Logger.error('Error with image loading')}
              src={getSocialImage(social.name)}
              alt={`social_${social.name}`}
              width="24px"
              height="24px"
            />
          </a>
        );
      })}
    </div>
  );
});

export default SocialsPersonal;
