import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import styles from './titleModal.module.scss';

// size = Large, Medium, Small;
const TitleModal = observer(
  ({ title = 'Title', classNames = {}, size = 'Large', isUnityGame = false }) => {
    return (
      <div
        className={classnames(
          styles.TitleModalContainer,
          styles[`TitleModalContainer__is${size}`],
          classNames.TitleModalContainer,
          isUnityGame && styles[`TitleModalContainer__isUnityGame__is${size}`],
        )}
      >
        <div
          className={classnames(
            styles.TitleModal,
            isUnityGame && styles.TitleModal__isUnityGame,
            classNames.Container,
          )}
        >
          <p
            className={classnames(
              styles.Title,
              styles[`Title__is${size}`],
              classNames.Title,
              isUnityGame && styles[`Title__isUnityGame__is${size}`],
            )}
          >
            {title}
          </p>
        </div>
      </div>
    );
  },
);

export default TitleModal;
