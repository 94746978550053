import React from 'react';
import { observer } from 'mobx-react';
import { sanitize } from 'dompurify';
import { useStores } from '@src/stores';
// import TitleSection from '@src/components/TitleSection';
import TextH5 from '@components_new/TextH5';
import M3IconBlue from '@assets/Mobile/HomePage/BenefitsSection/m3_element_blue.png';
import M3IconGreen from '@assets/Mobile/HomePage/BenefitsSection/m3_element_green.png';
import M3IconPurple from '@assets/Mobile/HomePage/BenefitsSection/m3_element_purple.png';
import M3IconRed from '@assets/Mobile/HomePage/BenefitsSection/m3_element_red.png';
import M3IconYellow from '@assets/Mobile/HomePage/BenefitsSection/m3_element_yellow.png';

// winter -
// import LightLeft from '@assets/Christmas/light_left.png';
// import LightRight from '@assets/Christmas/light_right.png';
// import LightLeftMobile from '@assets/Christmas/light_left_mobile.png';
// import LightRightMobile from '@assets/Christmas/light_right_mobile.png';
// import SnowLine from '@assets/Christmas/snow_line.png';
// import SnowTabletLine from '@assets/Christmas/snow_line_tablet.png';
// import { useMediaQuery } from 'src/helpers/media';
import Logger from '@utils/logger';
import { useMediaQuery } from 'src/helpers/media';

import styles from './mobileBenefits.module.scss';

const BenefitItem = ({ title = 'Title', textHtml = 'SubTitle', icon = {} }) => {
  const stores = useStores();
  return (
    <div className={styles.BenefitItem}>
      <img
        onLoad={() => stores.increaseDownloadedImageCounter(icon)}
        onError={() => Logger.error('Error with image loading')}
        src={icon}
        alt="benefit_item_icon"
        className={styles.BenefitItemImage}
      />
      <TextH5 classNames={styles.BenefitTitle} text={title} />
      <p className={styles.BenefitText} dangerouslySetInnerHTML={{ __html: sanitize(textHtml) }} />
    </div>
  );
};

const BenefitsSection = observer(() => {
  const stores = useStores();
  const _ = stores.language;

  // const width1000 = useMediaQuery('(max-width: 1000px)');
  // const isLessThan1300px = useMediaQuery('(max-width: 1299px)');
  return (
    <div className={styles.BenefitsSectionContainer}>
      {/* <img src={SnowLine} className={styles.SnowLine} alt='snow_line' /> */}
      {/* <img src={SnowTabletLine} className={styles.SnowTabletLine} alt='snow_line' /> */}
      {/* <img src={LightLeft} className={styles.LightLeft} alt='light_left' /> */}
      {/* <img src={LightRight} className={styles.LightRight} alt='right_left' /> */}
      {/* <img src={LightLeftMobile} className={styles.LightLeftMobile} alt='light_left_mobile' /> */}
      {/* <img src={LightRightMobile} className={styles.LightRightMobile} alt='right_left_mobile' /> */}
      <div className={styles.BenefitsSection} id="benefits">
        {/* {isLessThan1300px ? (
          <TitleSection title={stores.translate('benefits')} />
        ) : (
          <TitleSection
            title={stores.translate('benefits')}
            textHtml={stores.translate('release_description')}
            classNames={{ Container: styles.SubtitleContainer, Text: styles.Subtitle }}
          />
        )} */}
        <div className={styles.BenefitsItems}>
          <BenefitItem
            title={stores.translate('benefits_blockchain_title')}
            textHtml={stores.translate('benefit_blockchain_text')}
            icon={M3IconBlue}
          />
          <BenefitItem
            title={stores.translate('benefits_models_title')}
            textHtml={stores.translate('benefit_models_text')}
            icon={M3IconGreen}
          />
          <BenefitItem
            title={stores.translate('benefits_gameplay_title')}
            textHtml={stores.translate('benefit_gameplay_text')}
            icon={M3IconPurple}
          />
          <BenefitItem
            title={stores.translate('benefits_ecosystem_title')}
            textHtml={stores.translate('benefit_ecosystem_text')}
            icon={M3IconRed}
          />
          <BenefitItem
            title={stores.translate('benefits_economic_title')}
            textHtml={stores.translate('benefit_economic_text')}
            icon={M3IconYellow}
          />
        </div>
      </div>
    </div>
  );
});

export default BenefitsSection;
