import React from 'react';
import DesktopFooter from './DesktopFooter';
import MobileFooter from './MobileFooter';

const Footer = ({
  isMobile = false,
  isAccount = false,
  isTransactionsPage = false,
  states,
  methods,
}) => {
  return (
    <>
      {isMobile ? (
        <MobileFooter
          isAccount={isAccount}
          isTransactionsPage={isTransactionsPage}
          states={states}
          methods={methods}
        />
      ) : (
        <DesktopFooter
          isAccount={isAccount}
          isTransactionsPage={isTransactionsPage}
          states={states}
          methods={methods}
        />
      )}
    </>
  );
};

export default Footer;
