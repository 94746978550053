import React from 'react';
import { observer } from 'mobx-react';
import Layout from 'src/layout/Layout';
import CapitalSection from '../MobileAboutPage/components/CapitalSection';
import RoadMapSection from '../MobileAboutPage/components/RoadMapSection';
import OurTeamSection from '../MobileAboutPage/components/OurTeamSection';

const DesktopAboutPage = observer(({ isInitialLoading = true }) => {
  return (
    <Layout isInitialLoading={isInitialLoading} isLoadingFinish={!isInitialLoading}>
      <CapitalSection />
      {/* <RoadMapSection /> */}
      <OurTeamSection />
    </Layout>
  );
});

export default DesktopAboutPage;
