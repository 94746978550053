import React, { useCallback, useEffect, useState, Fragment, useRef } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { Unity, useUnityContext } from 'react-unity-webgl';

import BeeSpinnerSVG from '@assets/Layout/bee_spinner.svg';
import RotateLandscapeSVG from '@assets/control/phone_rotate_landscape.svg';

import BearHint1 from '@assets/Unity/bear_pic_1.png';
import BearHint2 from '@assets/Unity/bear_pic_2.png';
import BearHint3 from '@assets/Unity/bear_pic_3.png';
import BearHint4 from '@assets/Unity/bear_pic_4.png';
import BearHint5 from '@assets/Unity/bear_pic_5.png';
import BearHint6 from '@assets/Unity/bear_pic_6.png';
import BearHint7 from '@assets/Unity/bear_pic_7.png';
import LoadingUnity from '@assets/Unity/loading_unity.png';

import BearHint1Mobile from '@assets/Unity/bear_pic_1_mobile.png';
import BearHint2Mobile from '@assets/Unity/bear_pic_2_mobile.png';
import BearHint3Mobile from '@assets/Unity/bear_pic_3_mobile.png';
import BearHint4Mobile from '@assets/Unity/bear_pic_4_mobile.png';
import BearHint5Mobile from '@assets/Unity/bear_pic_5_mobile.png';
import BearHint6Mobile from '@assets/Unity/bear_pic_6_mobile.png';
import BearHint7Mobile from '@assets/Unity/bear_pic_7_mobile.png';
import LoadingUnityMobile from '@assets/Unity/loading_unity_mobile.jpg';

import { BREAK_APPROVE_TRANSACTION, TYPE_AUTH_CLIENT } from '@constants/blockchain';
import _ from 'lodash';
import { DEFAULT_LANGUAGE, ENCRYPT_SEED_PHRASE, RPC_URL } from '@constants/system';
import { getSecureStorage } from 'src/stores/system';
import { useStores } from 'src/stores';

import KeplrWallet from 'src/api/KeplrWallet';
import settings from 'src/api/server/settings';
import { getAccount } from 'src/api/BEARS';
import { DEFAULT_HINT, GameMethodNames, GameObjectNames, UnityMethodsName } from 'src/stores/unity';
import { Modal } from '@components/Modal';
import PopUp from '@components/PopUp';
import PopUpNew from '@components_new/PopUp';
import { ENUM_POPUP } from '@components/PopUp/enumPopup';
import HalloweenModal from '@layout/components/HalloweenModal';
import ChristmasModal from 'src/layout/components/ChristmasModal';
import PaymentsHoneyUnity from 'src/layout/components/mobile/PaymentsHoneyUnity';

import { stringify } from '@utils/common';
import Logger from '@utils/logger';

import DynamicStatesMediator from './components/Mediators/DynamicStatesMediator';
import { handleMainError } from './components/HandleError';
import UnityEvents from './components/UnityEvents';
import HandleUnityEvents from './components/HandleUnityEvents';
 
import styles from './game.module.scss';
import MainGeneralMediator from './components/Mediators/MainGeneralMediator';
import StartUnityMediator from './components/Mediators/StartUnityMediator';

let timerHoney;
let timerFarming;
let countRotationsIter = 0;

const COUNT_IMAGE_BEAR_HINT = 7;

const UnityGame = observer(() => {
  const stores = useStores();
  const { isMobile, isAndroid } = stores.browserInfo;

  const [password, setPassword] = useState('');
  const [unityListenersResponse, setUnityListenersResponse] = useState({
    name: '',
    properties: {},
    noPassword: false,
    isStacking: false,
  });

  const [errorMessageUnity, setErrorMessageUnity] = useState('');
  const [screenSize, setScreenSize] = useState({
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  });
  const [textHint, setTextHint] = useState(DEFAULT_HINT.START[stores.languageGame]);
  const [imageBearHint, setImageBearHint] = useState(isMobile ? BearHint1Mobile : BearHint1);
  const [numberBearHint, setNumberBearHint] = useState(1);
  const canvasRef = useRef(null);

  // const webGLContextAttributes = {
  //   alpha: !isMobile,
  //   antialias: !isMobile,
  //   depth: !isMobile,
  //   failIfMajorPerformanceCaveat: !isMobile,
  //   powerPreference: isMobile ? 'high-performance' : 'default',
  //   premultipliedAlpha: !isMobile,
  //   preserveDrawingBuffer: !isMobile,
  //   stencil: !isMobile,
  //   desynchronized: true,
  //   xrCompatible: !isMobile,
  // };

  const {
    isLoaded,
    loadingProgression,
    addEventListener,
    removeEventListener,
    sendMessage,
    unityProvider,
    initialisationError,
    unload
  } = useUnityContext({
    streamingAssetsUrl: 'StreamingAssets',
    companyName: 'MonetaCompany',
    productName: 'ReactAndUnityMoneta',
    productVersion: '1.0',

    loaderUrl: '/Build/demo.loader.js',
    dataUrl: '/Build/demo.data.unityweb',
    frameworkUrl: '/Build/demo.framework.js.unityweb',
    codeUrl: '/Build/demo.wasm.unityweb',
    webGLContextAttributes: {
      alpha: !isMobile,
      antialias: !isMobile,
      depth: !isMobile,
      failIfMajorPerformanceCaveat: !isMobile,
      powerPreference: isMobile ? 'high-performance' : 'default',
      premultipliedAlpha: !isMobile,
      preserveDrawingBuffer: !isMobile,
      stencil: !isMobile,
      desynchronized: true,
      xrCompatible: !isMobile,
    },
  });
  const [isShowPaymentsUnity, setShowPaymentsUnity] = useState(false);
  const [isShowPaymentsHoneyUnity, setShowPaymentsHoneyUnity] = useState(false);

  useEffect(() => {
    const availableFullScreen =
      (!stores.browserInfo.isIPhone && stores.browserInfo.isMobile) ||
      (!stores.browserInfo.isSafari && !stores.browserInfo.isMobile);
    stores.unityStore.setAvailableFullScreen(availableFullScreen);
    stores.unityStore.setNickname(stores.profileAvailable.nickName);
    stores.unityStore.setAddress(stores.profileAvailable.address); 
  }, []);

  // useEffect(() => {
  //   if (window.ReactNativeWebView) {
  //     window.ReactNativeWebView.postMessage(JSON.stringify({ message: `HW WEB CONFIGS: ${JSON.stringify(stores.browserInfo)}` }));
  //     window.ReactNativeWebView.postMessage(JSON.stringify({ message: `UNITY CONFIGS: ${JSON.stringify(webGLContextAttributes)}` }));
  //   }
  // }, []);

  useEffect(() => {
    if (sendMessage || unityProvider) {
      stores.unityStore.setUnityContext({ unityProvider, sendMessage });
    }
  }, [unityProvider, sendMessage]);

  const progression = Math.round(loadingProgression * 100);

  const getImageBearHint = (numberImage) => {
    switch (numberImage) {
      case 1: return isMobile ? BearHint1Mobile : BearHint1;
      case 2: return isMobile ? BearHint2Mobile : BearHint2;
      case 3: return isMobile ? BearHint3Mobile : BearHint3;
      case 4: return isMobile ? BearHint4Mobile : BearHint4;
      case 5: return isMobile ? BearHint5Mobile : BearHint5;
      case 6: return isMobile ? BearHint6Mobile : BearHint6;
      case 7: return isMobile ? BearHint7Mobile : BearHint7;
      default: return isMobile ? BearHint1Mobile : BearHint1;
    }
  }

  const changeHints = async () => {
    if (stores.isNativeApp) {
      return;
    }
    if (stores.profileAvailable) {
      // console.log(stores.profileAvailable);
      // const abbreviationLocale = DEFAULT_LANGUAGE;
      const abbreviationLocale = stores.languageGame;
      const hints = await settings.getHints({ address: stores.profileAvailable.address });
      setTextHint(hints.locale[abbreviationLocale]);
      let numberImage = numberBearHint + 1;
      if (numberImage > COUNT_IMAGE_BEAR_HINT) {
        numberImage = 1;
      }
      setNumberBearHint(numberImage);
      setImageBearHint(getImageBearHint(numberImage));
    }
  }
  // ***** useEffect: show Halloween Modal ****** //
  useEffect(() => {
    if (stores.isShowPaymentsUnity && stores.stateDisplayedPaymentModal) {
      /* Dynamically unlock scrolling background */
      document.body.style.overflowY = 'hidden';
      setShowPaymentsUnity(true);
    } else {
      /* Dynamically unlock scrolling background */
      document.body.style.overflowY = 'initial';
      setShowPaymentsUnity(false);
      stores.setStateDisplayedPaymentModal(null);
    }
  }, [stores.isShowPaymentsUnity]);
  // ***** useEffect: show Honey Pack Modal in Store ****** //
  useEffect(() => {
    if (stores.isShowPaymentsHoneyUnity && stores.stateDisplayedPaymentModal) {
      /* Dynamically unlock scrolling background */
      document.body.style.overflowY = 'hidden';
      setShowPaymentsHoneyUnity(true);
    } else {
      /* Dynamically unlock scrolling background */
      document.body.style.overflowY = 'initial';
      setShowPaymentsHoneyUnity(false);
      stores.setStateDisplayedPaymentModal(null);
    }
  }, [stores.isShowPaymentsHoneyUnity]);
  // ***** useEffect: update game started or no ****** //
  useEffect(() => {
    //! for change Modal and Popup components (critical)
    stores.setGame(true);
    (async () => {
      setTimeout(async() => { 
        await changeHints();
      }, 2000);
    })();
  }, [stores.profileAvailable])

  // ***** useEffect: change account in Keplr wallet ***** //
  useEffect(() => {
    if (stores.isChangedAccountInWallet) {
      window.location.reload(true);
    }
  }, [stores.isChangedAccountInWallet]);

  // ***** useEffect: update Oxygen and HoneyBar every 20sec ***** //
  useEffect(() => {
    if (!stores.unityStore.isEnteringFarming || stores.unityStore.isPlayMatch3 || timerFarming) {
      return () => {};
    }
    Logger.infoMainWithKey('[timer] start farming');
    timerFarming = setInterval(async () => {
      await DynamicStatesMediator.updateStatesTimerInterval();
    }, 20000);

    return () => {
      clearInterval(timerFarming);
    };
  }, [
    // stores.unityStore.bearId,
    // stores.unityStore.powerMaxBee,
    stores.unityStore.isEnteringFarming,
    stores.unityStore.isPlayMatch3,
  ]);

  // ***** useEffect: update Honey every 20sec ***** //
  useEffect(() => {
    if (!stores.unityStore.isEnteringFarming && !stores.unityStore.isStartGame || stores.unityStore.isPlayMatch3 || timerHoney) {
      return () => {};
    }
    Logger.infoMainWithKey('[timer] start honey');
    timerHoney = setInterval(async () => {
      await DynamicStatesMediator.updateHoneyTimerInterval();
    }, 20000);

    return () => {
      clearInterval(timerHoney);
    };
  }, [stores.unityStore.isStartGame, stores.unityStore.isPlayMatch3, stores.unityStore.isEnteringFarming]);

  // ***** useEffect: clear timers ***** //
  useEffect(() => {
    if (stores.unityStore.isPlayMatch3) {
      Logger.infoMainWithKey('[timer] clear');
      clearInterval(timerHoney);
      clearInterval(timerFarming);
      timerFarming = null;
      timerHoney = null;
    }
  }, [stores.unityStore.isPlayMatch3]);

  // ***** useEffect: error in Unity ***** //
  useEffect(() => {
    if (errorMessageUnity) {
      stores.setPropsForPopup({ textError: errorMessageUnity });
      stores.setOpenPopup(ENUM_POPUP.ERROR);
    }
  }, [errorMessageUnity]);


  // ***** useEffect: listener unity events ***** //
  useEffect(() => {
    if (initialisationError) {
      Logger.infoNotify('info error unity', initialisationError, true);
      // setErrorMessageUnity(initialisationError);
    }
  }, [initialisationError]) 
  useEffect(() => {
//     stores.unityStore.unityContext.on('error', (message) => {
//       Logger.infoNotify('info error unity', message, true);
//       setErrorMessageUnity(message);
//     });

    UnityEvents.events({ addEventListener, removeEventListener, setUnityListenersResponse }).addEvents();
    // UnityEvents.eventsBonus({ setUnityListenersResponse });
    UnityEvents.eventsLeaderBoard({ addEventListener, removeEventListener, setUnityListenersResponse }).addEvents();
    UnityEvents.eventsMatch3({ addEventListener, removeEventListener, setUnityListenersResponse }).addEvents();
    UnityEvents.eventsFarming({ addEventListener, removeEventListener, setUnityListenersResponse }).addEvents();
    UnityEvents.eventsStacking({ addEventListener, removeEventListener, setUnityListenersResponse }).addEvents();
    return () => {
      stores.unityStore.setUnityContext(null);
      UnityEvents.events({ addEventListener, removeEventListener, setUnityListenersResponse }).removeEvents();
      UnityEvents.eventsLeaderBoard({ addEventListener, removeEventListener, setUnityListenersResponse }).removeEvents();
      UnityEvents.eventsMatch3({ addEventListener, removeEventListener, setUnityListenersResponse }).removeEvents();
      UnityEvents.eventsFarming({ addEventListener, removeEventListener, setUnityListenersResponse }).removeEvents();
      UnityEvents.eventsStacking({ addEventListener, removeEventListener, setUnityListenersResponse }).removeEvents();
      window.open('/', '_self', 'noopener,noreferrer');
    }
  }, [addEventListener, removeEventListener]);

  // ***** useEffect: approve first transaction ***** //
  // logic in start Game from few variants (main function in start game, with setPassword)
  // - callbackRequestProfilePlayer with exist `password`
  // - after checked password in VerifyYourself
  useEffect(() => {
    (async () => {
      if (stores.unityStore.approveTransaction === BREAK_APPROVE_TRANSACTION) {
        stores.unityStore.send({
          objectName: GameObjectNames.FarmReact,
          methodName: GameMethodNames.FinishTransaction,
        });
        // stores.unityStore.sendExceptionError({ code: 112, message: 'User authentication failed' });
        stores.unityStore.setApproveTransaction(null);
        Logger.infoMainWithKey('QuitGame, Verify yourself');
        //! change Modal components (critical)
        stores.setGame(false);
        window.open('/', '_self', 'noopener,noreferrer');
      } else if (stores.unityStore.approveTransaction) {
        const passwordValue = stores.unityStore.approveTransaction;
        try {
          // if (window.ReactNativeWebView) {
            // window.ReactNativeWebView.postMessage(`window.isNativeApp in Game >> ${  window.isNativeApp}`);
            // window.ReactNativeWebView.postMessage(JSON.stringify({ message: 'window.isNativeApp in Game'}));
            // window.ReactNativeWebView.postMessage(`stores.password in Game >> ${  stores.password}`);
          // }
          // window.ReactNativeWebView.postMessage('window.isNativeApp in Game >> ' + window.isNativeApp);

          if (!stores.isNativeApp) {
            const mnemonic = getSecureStorage(ENCRYPT_SEED_PHRASE, passwordValue);
            const { wallet, account } = await getAccount({ mnemonic, isPrivKeys: true });
            await stores.configAuthorizedClient(wallet, RPC_URL);
            stores.setProfile({ ...stores.profile, wallet, account });
            stores.setProfileAvailable({ ...stores.profileAvailable, wallet, account });
            setPassword(passwordValue);
          } else { // NativeApp `MonetaToday`
            if (window.ReactNativeWebView) {
              window.ReactNativeWebView.postMessage(JSON.stringify({ message: 'unity init game'}));
            }
            if (window.FlutterWebView) {
              window.FlutterWebView.postMessage(JSON.stringify({ message: 'unity init game'}));
            }
            // window.ReactNativeWebView.postMessage(`stores.profile >> ${  JSON.stringify(stores.profile)}`);
            setPassword(stores.password);
          }
          await StartUnityMediator.initializeUnityGameWithStart();
        } catch (err) {
          handleMainError(undefined, err);
        } finally {
          stores.unityStore.setApproveTransaction(null);
          // MainGeneralMediator.checkBearAuthorization();
        }
      }
    })();
  }, [stores.unityStore.approveTransaction]);

  // ****** useEffect: stacking ****** //
  useEffect(() => {
    const cone = stores.unityStore.conesStacking;
    Logger.infoMainWithKey('stacking with cone', cone);
    if (cone > 0) {
      stores.unityStore.send({
        objectName: GameObjectNames.FarmReact,
        methodName: GameMethodNames.ShowPopupStacking,
        values: stringify({ state: 'stacking' }),
      });
      setUnityListenersResponse({
        name: UnityMethodsName.StakeCones,
        properties: { cone },
        noPassword: false,
        isStacking: true,
      });
      stores.unityStore.setConesStacking(0);
    }
  }, [stores.unityStore.conesStacking]);
  useEffect(() => {
    const { objectUnstacking } = stores.unityStore;
    const cone = objectUnstacking && objectUnstacking.cone ? objectUnstacking.cone : 0;
    const validatorAddress = objectUnstacking && objectUnstacking.validatorAddress ? objectUnstacking.validatorAddress : '';
    Logger.infoMainWithKey('unstacking with cone', cone);
    if (cone > 0) {
      stores.unityStore.send({
        objectName: GameObjectNames.FarmReact,
        methodName: GameMethodNames.ShowPopupStacking,
        values: stringify({ state: 'unstacking' }),
      });
      setUnityListenersResponse({
        name: UnityMethodsName.UnstakeCones,
        properties: { cone, validatorAddress },
        noPassword: false,
        isStacking: true,
      });
      stores.unityStore.setObjectUnstacking({ cone: 0, validatorAddress: '' });
    }
  }, [stores.unityStore.objectUnstacking]);

  // ***** useEffect: update coins after burn CONEs ***** //
  useEffect(async () => {
    if (stores.unityStore.isSuccessBurnCoins) {
      await DynamicStatesMediator.updateCONE();
      await DynamicStatesMediator.updateHoney();
      stores.unityStore.setSuccessBurnCoins(false);
    }
  }, [stores.unityStore.isSuccessBurnCoins]);

  // ***** useEffect: resize and fullscreen ***** //
  useEffect(() => {
    const onChangeClientSize = () => {
      const newWidth = window.innerWidth;
      const rootElement = document.getElementById('GameContainer');
      const heightScreen = isMobile
        ? window.innerHeight
        : rootElement.clientHeight;
      if  (isAndroid) {
        Logger.infoNotify('rootElement.clientHeight', rootElement.clientHeight);
        Logger.infoNotify('rootElement.clientWidth', rootElement.clientWidth);
        Logger.infoNotify('rootElement.offsetHeight', rootElement.offsetHeight);
        Logger.infoNotify('rootElement.offsetWidth', rootElement.offsetWidth);
  
        Logger.infoNotify('window.innerHeight', window.innerHeight);
        Logger.infoNotify('window.innerWidth', window.innerWidth);
        Logger.infoNotify('window.outerHeight', window.outerHeight);
        Logger.infoNotify('window.outerWidth', window.outerWidth);
      }

      // console.log('rootElement.clientHeight >> ', rootElement.clientHeight);
      // console.log('window.innerHeight >> ', window.innerHeight);
      // console.log('window.outerHeight >> ', window.outerHeight);
      // console.log('rootElement.offsetHeight >> ', rootElement.offsetHeight);
      // console.log('window.visualViewport', window.visualViewport);

      setScreenSize({ width: rootElement.clientWidth, height: heightScreen });
    };
    const onFullScreenChange = () => {
      const fullscreenElement =
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement;
      if (!fullscreenElement) {
        stores.unityStore.send({
          objectName: GameObjectNames.LobbyReact,
          methodName: GameMethodNames.ShowFullScreenButton,
          isLogger: false,
        });
        onChangeClientSize();
      }
    };

    const errorListener = (event) => {
      console.log('[errorListener] event >>> ', event);
      Logger.infoMainNotify({ nameNotify: 'Error Unity', section: 'errorListener', meta: event, useInProduction: true });
    }

    window.addEventListener('resize', onChangeClientSize);
    document.addEventListener('fullscreenchange', onFullScreenChange, false);
    window.addEventListener("error", errorListener);
    window.addEventListener("unhandledrejection", errorListener);
    window.onerror = function(message, source, lineno, colno, error) {
      errorListener(error)
    };

    onChangeClientSize();
    // INIT Keplr, set password in started game
    (async () => {
      if (stores.typeAuthClient === TYPE_AUTH_CLIENT.KEPLR) {
        const accessKeplr = await KeplrWallet.accessAndInitKeplrClient();
        if (accessKeplr) {
          setPassword(`wallet_${TYPE_AUTH_CLIENT.KEPLR}`);
        }
      }
    })();
    return () => {
      window.removeEventListener('resize', onChangeClientSize);
      document.removeEventListener('fullscreenchange', onFullScreenChange, false);
      window.removeEventListener('error', errorListener);
      window.removeEventListener('unhandledrejection', errorListener);
    };
  }, []);

  const openFullscreen = (element) => {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) {
      /* Safari */
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      /* IE11 */
      element.msRequestFullscreen();
    }
  };

  useEffect(() => {
    if (!stores.isAccountAccess) {
      setUnityListenersResponse({
        name: '',
        noPassword: false,
        isStacking: false,
      });
      setPassword('');
    }
  }, [stores.isAccountAccess]);

  HandleUnityEvents.allEvents({
    stores,
    unityListenersResponse,
    password,
    setPassword,
    openFullscreen,
  });

  const closeModal = (name) => {
    /* Dynamically unlock scrolling background */
    document.body.style.overflowY = 'initial';
    switch (name) {
      case 'payments_unity':
        stores.showPaymentsUnity(false);
        break;
      case 'payments_honey_payments':
        stores.showPaymentsHoneyUnity(false);
        break;
      default:
        break;
    }
  };

  return (
    <>
      {stores.unityStore.unityContext && (
        <>
          <Unity
            unityProvider={unityProvider}
            style={{
              visibility: isLoaded ? 'visible' : 'hidden',
              height: screenSize.height,
              width: screenSize.width,
              flexDirection: 'column',
              position: isMobile ? 'fixed' : 'absolute',
              zIndex: 1,
            }}
            height="-webkit-fill-available"
            width="-webkit-fill-available"
            ref={canvasRef}
          />
        </>
      )}
      {!isLoaded && (
        <div className={styles.ProgressAbsolute}>
          <div className={styles.ProgressContainerRelative}>
            <div className={styles.ProgressContainer}>
              <img
                onLoad={() => stores.increaseDownloadedImageCounter(BeeSpinnerSVG)}
                onError={() => handleMainError(undefined, new Error('Error with image loading'))}
                src={BeeSpinnerSVG}
                alt="loading_unity_game"
                className={styles.BeeSpinner}
              />
              <p className={styles.ProgressTitle}>{progression.toFixed(1)}%</p>
            </div>
          </div>
          <div
            className={styles.HintsContainer}
            role="button"
            onClick={() => changeHints()}
            onKeyPress={() => changeHints()}
            tabIndex={0}
          >
            {textHint && <p className={styles.TextHint}>{textHint}</p>}
            <img src={imageBearHint} alt="bear_hint" className={styles.ImageBearHint} />
          </div>
        </div>
      )}
      <Modal />
      {isShowPaymentsUnity && <ChristmasModal close={() => closeModal('payments_unity')} />}
      {isShowPaymentsHoneyUnity && <PaymentsHoneyUnity close={() => closeModal('payments_honey_payments')} />}
      <PopUp />
      <PopUpNew />
    </>
  );
});

const GamePage = observer(() => {
  const stores = useStores();
  const { isMobile, isAndroid } = stores.browserInfo;
  const mql = window.matchMedia('(orientation: portrait)').matches;
  const [isPortrait, setPortrait] = useState(isMobile ? mql : false);
  const [countRotations, setCountRotations] = useState(0);

  // TODO: hot fix for mobile device
  useEffect(() => {
    // const screenWidth = document.documentElement.clientWidth;
    if (isMobile) {
      // document.body.style.minWidth = `${screenWidth}px`;
      document.body.style.overflow = 'hidden';
    }
  }, []);

  useEffect(() => {
    const onChangeClientSize = () => {
      if (isMobile) {
        const isPortraitMode = window.innerHeight > window.innerWidth;
        setPortrait(isPortraitMode);

        if (countRotations > 1) {
          const screenWidth = document.documentElement.clientWidth;
          const rootElement = document.getElementById('GameContainer');
          // rootElement.style.minHeight = `${window.innerHeight}px`;
          // rootElement.style.minWidth = `${screenWidth}px`;
        }
      }
    };

    const changeOrientationNew2 = () => {
      if (isMobile) {
        countRotationsIter += 1;
        setCountRotations(countRotationsIter);
      }
    };
    const isFullScreen = () => {
      if (typeof document.fullscreenElement === 'undefined') {
        if (document.webkitCurrentFullScreenElement == null) return false;
      }
      if (document.fullscreenElement == null) return false;
      return true;
    };
    const fullscreenchanged = (event) => {
      if (isAndroid) {
        if (isFullScreen()) {
          window.screen.orientation.lock('landscape');
        } else {
          window.screen.orientation.unlock();
        }
      }
    };

    window.addEventListener('fullscreenchange', fullscreenchanged);
    window.addEventListener('orientationchange', changeOrientationNew2);
    window.addEventListener('resize', onChangeClientSize, false);
    return () => {
      window.removeEventListener('resize', onChangeClientSize, false);
      window.removeEventListener('fullscreenchange', fullscreenchanged);
      window.removeEventListener('orientationchange', changeOrientationNew2);
    };
  }, []);

  return (
    <div
      className={classnames(
        styles.Container,
        isMobile && styles.Container__mobile,
      )}
      id="GameContainer"
    >
      <img src={isMobile ? LoadingUnityMobile : LoadingUnity} alt="loading_unity" className={styles.LoadingUnityImage} />
      {countRotations === 0 && isPortrait ? (
        <div className={styles.PortraitInfoContainer}>
          <p className={classnames(styles.PortraitInfoText, styles.PortraitInfoTitle)}>
            Portrait orientation
          </p>
          <p className={styles.PortraitInfoText}>Please change your device to landscape mode</p>
          <img
            onLoad={() => stores.increaseDownloadedImageCounter(RotateLandscapeSVG)}
            onError={() => handleMainError(undefined, new Error('Error with image loading'))}
            src={RotateLandscapeSVG}
            alt="rotate_landscape_orientation"
            className={styles.RotateLandscape}
          />
        </div>
      ) : (
        <UnityGame />
      )}
      {/* {isPortrait ? (
        (countRotations > 0) ? (
          <div className={styles.PortraitInfoContainer}>
            <p className={classnames(styles.PortraitInfoText, styles.PortraitInfoTitle)}>
              Please restart page
            </p>
            <p className={styles.PortraitInfoText}>and run games in landscape mode</p>
          </div>
        ) : (
          <div className={styles.PortraitInfoContainer}>
            <p className={classnames(styles.PortraitInfoText, styles.PortraitInfoTitle)}>
              Portrait orientation
            </p>
            <p className={styles.PortraitInfoText}>Please change your device to landscape mode</p>
            <img 
              src={RotateLandscapeSVG} 
              alt='rotate_landscape_orientation' 
              className={styles.RotateLandscape} />
          </div>
        )
      ) : (countRotations > 1) ? (
        <div className={styles.PortraitInfoContainer}>
          <p className={classnames(styles.PortraitInfoText, styles.PortraitInfoTitle)}>
            Please restart page
          </p>
          <p className={styles.PortraitInfoText}>and run games in landscape mode</p>
        </div>
      ) : (
        <UnityGame />
      )} */}
    </div>
  );
});

export default GamePage;
