/* eslint-disable */
import { DirectSecp256k1HdWallet } from "@cosmjs/proto-signing";
import { stringToPath, Secp256k1, sha256 } from "@cosmjs/crypto";
import { toHex } from "@cosmjs/encoding";
import { EASY_START, USDT, USDT_TRC20 } from "@constants/blockchain";
import { URL_SERVER } from "@constants/system";
import { StoreInstance } from "src/stores";
import Logger from '@utils/logger';
import axios from "axios";

/**
 * List of sorted bears in descending order from blockchain.
 * 
 * @param page - page number for getting bears starting from the leaderboard position we need, also take into account the limit parameter
 * @param limit - maximum number of bears in response
 * @param sortType - parameter by which bears are sorted, default = [uhoneyBalance] (uhoneyBalance, uhoneyPower, coneBalance)
 */
export const listBearsLeaderBoard = async ({ page, limit, sortType = 'uhoneyBalance' }) => {
  try {
    const result = await axios.get(`${URL_SERVER}api/addresses/list?page=${page}&sort[${sortType}]=-1&limit=${limit}`, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
    return result.data;
  } catch (err) {
    Logger.error(err);
    return { err }
  }
}

/**
 * Place of the bear in the leaderBoard.
 * 
 * @param type - type of leaderboard in which the position of the player is determined, default = [uhoneyBalance] (uhoneyBalance, uhoneyPower, coneBalance)
 */
export const playerRatingOnLeaderBoard = async ({ type = 'uhoneyBalance' }) => {
  const address = StoreInstance.profileAvailable.address;
  if (!address) {
    return -1;
  }

  try {
    const result = await axios.get(`${URL_SERVER}api/addresses/position?address=${address}&field=${type}`, {
      headers: {
        'Content-Type': 'application/json',
      }
    });

    return result.data;
  } catch (err) {
    Logger.error(err);
    return { err };
  }
}

export default {
  listBearsLeaderBoard,
  playerRatingOnLeaderBoard
};