import { fromBase64, toBase64, toHex } from '@cosmjs/encoding';
import { ExtendedSecp256k1Signature, Secp256k1, Secp256k1Signature, sha256 } from "@cosmjs/crypto";

import { StoreInstance } from 'src/stores';
import { serializeSignDoc } from '@cosmjs/launchpad';
import { CHAIN_ID_HONEYWOOD, TYPE_AUTH_CLIENT } from '@constants/blockchain';
/* eslint-disable */

const appendBuffer = function(buffer1, buffer2) {
  var tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
  tmp.set(new Uint8Array(buffer1), 0);
  tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
  return tmp;
};

const makeSignaturePrivate = async ({ body, ts, nonce }) => {
  const signature = await Secp256k1.createSignature(
    sha256(nonce), 
    StoreInstance.profileAvailable.account.privkey
  );
  return {
    body,
    timestamp: ts,
    signature: signature.toFixedLength(),
  }
};
// makeSignature, use sign of Keplr
const makeSignatureKeplr = async ({ body , ts, nonce }) => {
  const { address } = StoreInstance.profileAvailable.account;
  await window.keplr.enable(CHAIN_ID_HONEYWOOD);
  const resultArbitrary = await window.keplr.signArbitrary(CHAIN_ID_HONEYWOOD, address, sha256(nonce));
  // console.log('resultArbitrary >> ', resultArbitrary);
  const recovery = 3;
  const signatureKeplr = appendBuffer(
    fromBase64(resultArbitrary.signature),
    new Uint8Array(1).fill(recovery),
  );
  return { signature: signatureKeplr, body, timestamp: ts };
};

// standard makeSignature, use privKey from mnemonic
const makeSignatureMnemonic = async ({ body, ts, nonce }) => {
  const { address } = StoreInstance.profileAvailable.account;
  const signDoc = {
    chain_id: '',
    account_number: '0',
    sequence: '0',
    fee: {
      gas: '0',
      amount: [],
    },
    msgs: [
      {
        type: 'sign/MsgSignData',
        value: {
          signer: address,
          data: Buffer.from(sha256(nonce)).toString('base64'),
        },
      },
    ],
    memo: '',
  };
  return await makeSignaturePrivate({ body, ts, nonce: serializeSignDoc(signDoc) });
}

export const makeSignature = async ({ body = {}, ts = "", nonce = {} }) => {
  if (StoreInstance.typeAuthClient === TYPE_AUTH_CLIENT.MNEMONIC) {
    // console.log('makeSignatureMnemonic');
    return await makeSignatureMnemonic({ body, ts, nonce });
  } else if (StoreInstance.typeAuthClient === TYPE_AUTH_CLIENT.KEPLR) {
    // console.log('makeSignatureKeplr');
    return await makeSignatureKeplr({ body, ts, nonce });
  }
}

export default {
  makeSignature,
}