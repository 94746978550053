import { stringify } from '@utils/common';
import { StoreInstance } from 'src/stores';
import { GameMethodNames, GameObjectNames } from 'src/stores/unity';
import match3 from 'src/api/server/match3';
import settings from 'src/api/server/settings';
import { BEARS } from '@api';
import Logger from '@utils/logger';

import { handleMainError } from '../HandleError';

const stores = StoreInstance;

const signingTransaction = async ({ toAddress, amount }) => {
  const response = await match3.signTransaction({ toAddress, amount });
  if (response.code > 0) {
    handleMainError(response);
  } else {
    stores.unityStore.send({
      objectName: GameObjectNames.BattleReact,
      methodName: GameMethodNames.SuccessfulSigning,
      values: stringify({ tx: response.tx, pubkey: response.pubkey }),
      isLogger: false,
    });
  }
};

const authConnectLobby = async ({ nonce }) => {
  const response = await match3.signLobby({ nonce });
  if (response.code > 0) {
    handleMainError(response);
  } else {
    stores.unityStore.send({
      objectName: GameObjectNames.BattleReact,
      methodName: GameMethodNames.SuccessfulAuthLobby,
      values: stringify({ tx: response.tx, pubkey: response.pubkey }),
      isLogger: false,
    });
  }
};

const requestProfileOpponent = async ({ address }) => {
  if (address.includes('bears') && address.indexOf('bears') === 0) {
    const responseName = await BEARS.getNickNameByAddress({ address });
    const profile = await settings.profile({ address });
    if (profile.err) {
      handleMainError({ code: 113, useProduction: false });
    }
    if (responseName.code > 0) {
      handleMainError(responseName);
    } else {
      stores.unityStore.send({
        objectName: GameObjectNames.BattleReact,
        methodName: GameMethodNames.InitProfileOpponent,
        values: stringify({ namePlayer: responseName, avatar: profile.avatar }),
      });
    }
  } else {
    Logger.infoMainWithKey('opponentBattleName with bot', stringify({ namePlayer: address }));
  }
};

const tournamentCurrent = async () => {
  const { address } = stores.unityStore;
  const tournamentResp = await match3.getTournamentCurrent({ address });
  if (tournamentResp.err) {
    handleMainError({ code: 113, useProduction: false });
  } else {
    const tournamentInfo = {
      id: tournamentResp.id,
      dateStart: tournamentResp.dateStart,
      dateEnd: tournamentResp.dateEnd,
      deposit: tournamentResp.deposit,
      tickets: tournamentResp.tickets,
      games: tournamentResp.games,
      attempts: tournamentResp.attempts,
      info: tournamentResp.info,
      prizeFund: tournamentResp.prizeFund,
    }
    stores.unityStore.send({
      objectName: GameObjectNames.LobbyReact,
      methodName: GameMethodNames.TournamentCurrentInfo,
      values: stringify(tournamentInfo),
    });
  }
};

const tournamentNext = async () => {
  const { address } = stores.unityStore;
  const tournamentResp = await match3.getTournamentNext({ address });
  if (tournamentResp.err) {
    handleMainError({ code: 113, useProduction: false });
  } else {
    const tournamentInfo = {
      id: tournamentResp.id,
      dateStart: tournamentResp.dateStart,
      dateEnd: tournamentResp.dateEnd,
      deposit: tournamentResp.deposit,
      tickets: tournamentResp.tickets,
      games: tournamentResp.games,
      attempts: tournamentResp.attempts,
      info: tournamentResp.info,
      prizeFund: tournamentResp.prizeFund,
    }
    stores.unityStore.send({
      objectName: GameObjectNames.LobbyReact,
      methodName: GameMethodNames.TournamentNextInfo,
      values: stringify(tournamentInfo),
    });
  }
};

const tournamentPrev = async () => {
  const { address } = stores.unityStore;
  const tournamentResp = await match3.getTournamentPrev({ address });
  if (tournamentResp.err) {
    handleMainError({ code: 113, useProduction: false });
  } else {
    const tournamentInfo = {
      id: tournamentResp.id,
      dateStart: tournamentResp.dateStart,
      dateEnd: tournamentResp.dateEnd,
      deposit: tournamentResp.deposit,
      tickets: tournamentResp.tickets,
      games: tournamentResp.games,
      attempts: tournamentResp.attempts,
      info: tournamentResp.info,
      prizeFund: tournamentResp.prizeFund,
    }
    stores.unityStore.send({
      objectName: GameObjectNames.LobbyReact,
      methodName: GameMethodNames.TournamentPrevInfo,
      values: stringify(tournamentInfo),
    });
  }
};

const getEmojiList = async () => {
  const reactionsResp = await match3.getEmojiList();
  if (reactionsResp.err) {
    handleMainError({ code: 113, useProduction: false });
  } else {
    stores.unityStore.send({
      objectName: GameObjectNames.BattleReact,
      methodName: GameMethodNames.SetEmojiList,
      values: stringify({ reactions: reactionsResp }),
    });
  }
};


export default {
  signingTransaction,
  authConnectLobby,
  requestProfileOpponent,
  tournamentCurrent,
  tournamentNext,
  tournamentPrev,
  getEmojiList,
};
