import React from 'react';
import { observer } from 'mobx-react';

import Layout from 'src/layout/Layout';
import CapitalSection from '../MobileTokenomicsPage/components/CapitalSection';
import TokenomicsSection from '../MobileTokenomicsPage/components/TokenomicsSection';
import VestingSection from '../MobileTokenomicsPage/components/VestingSection';
import ParticipateSection from '../MobileTokenomicsPage/components/ParticipateSection';

const DesktopTokenomicsPage = observer(({ isInitialLoading = true }) => {
  return (
    <Layout isInitialLoading={isInitialLoading} isLoadingFinish={!isInitialLoading}>
      <CapitalSection />
      <TokenomicsSection />
      <VestingSection />
      <ParticipateSection />
    </Layout>
  );
});

export default DesktopTokenomicsPage;
