import { useState, useEffect } from 'react';
import { useStores } from '../stores';

export function useMediaQuery(query) {
  const stores = useStores();
  const [matches, setMatches] = useState(false);
  
  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };

    if (
      stores.browserInfo &&
      stores.browserInfo.name &&
      stores.browserInfo.version &&
      !stores.browserInfo.isOldBrowser
    ) {
      media.addEventListener('change', listener);
      return () => {
        media.removeEventListener('change', listener);
      };
    // eslint-disable-next-line no-else-return
    } else {
      media.addListener(listener);
      return () => {
        media.removeListener(listener);
      };
    }
    
  }, [matches, query]);

  return matches;
}

export default {
  useMediaQuery,
};
