import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { copyText } from '@utils/common';
import { useStores } from 'src/stores';
import { Button, ColorType, SizeButton } from '@components_new/Button';
import { ENUM_MODAL } from '@components_new/Modal/enumModal';
import InputWord from '@components/InputWord';
import Input from '@components/Input';
import { CRYPTO } from 'src/api/crypto';
import { COUNT_WORD_SEED_PHRASE } from '@constants/system';
import { useCopied } from '@hooks/custom';
import { BEARS } from '@api';
import Logger from '@utils/logger';
import ShowGreenButtonDefaultIcon from '@assets/common/show_green_button_default_icon.png';
import ShowGreenButtonPressedIcon from '@assets/common/show_green_button_pressed_icon.png';
import styles from './privateUsernameModal.module.scss';

const DEFAULT_MNEMONIC = [
  'word1',
  'word2',
  'word3',
  'word4',
  'word5',
  'word6',
  'word7',
  'word8',
  'word9',
  'word10',
  'word11',
  'word12',
];

const PrivateUsernameModal = observer(({ close }) => {
  const stores = useStores();
  const [isSecretText, setSecretText] = useState(true);
  const [isVerifyYourself, setVerifyYourself] = useState(false);

  const [mnemonic, setMnemonic] = useState(DEFAULT_MNEMONIC);
  const [address, setAddress] = useState('');

  const schemaNeedFieldLoginModal = yup.object().shape({
    password: yup.string('').min(5, stores.translate('password_input_validation')),
  });

  const { register, handleSubmit, watch, formState, setValue } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schemaNeedFieldLoginModal),
  });

  const { password } = watch();

  const inputsRef = new Array(COUNT_WORD_SEED_PHRASE).fill(undefined).map(() => useRef());
  const { copied, setCopied } = useCopied();

  useEffect(() => {
    if (
      stores.executeModal &&
      stores.executeModal.name === ENUM_MODAL.VERIFY_YOURSELF &&
      stores.executeModal.state.isVerify
    ) {
      setVerifyYourself(true);
      setSecretText(false);
      stores.setExecuteModal(null);
      if (
        stores.profileAvailable &&
        stores.profileAvailable.wallet &&
        stores.profileAvailable.wallet.secret.data
      ) {
        const secretsArray = stores.profileAvailable.wallet.secret.data.split(' ');
        setMnemonic(secretsArray);
      }
    }
  }, [stores.executeModal]);

  useEffect(() => {
    if (!isVerifyYourself) return () => {};
    const timer = setTimeout(() => {
      setMnemonic(DEFAULT_MNEMONIC);
      setVerifyYourself(false);
      setSecretText(true);
    }, 30 * 1000); // 30 sec

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isVerifyYourself]);

  const onShowSecretText = () => {
    if (isVerifyYourself) {
      setSecretText(!isSecretText);
    } else if (isSecretText) {
      stores.setOpenModal(ENUM_MODAL.VERIFY_YOURSELF)
    } else {
      setSecretText(true);
    }
  };

  const onCopyClick = async () => {
    await copyText({ text: mnemonic.join(' ') });
    setCopied();
  };

  const onNeedFieldsClick = () => {
    const countOpened = stores.propsForModal ? stores.propsForModal.countOpened + 1 : 1;
    stores.setPropsForModal({ mnemonic, password, countOpened });
    stores.setOpenModal(ENUM_MODAL.APPROVE_REGISTRATION);
  };

  const handleKeypress = (e) => {
    if (e.key && e.key.toLowerCase() === 'enter') {
      handleSubmit(onNeedFieldsClick)();
    }
  };

  return (
    <>
      <p className={styles.SubTitle}>{stores.translate('save_your_private_username')}</p>
      <div className={styles.SeedPraseContainerWrapper}>
        <div className={styles.SeedPraseContainer}>
          {mnemonic.map((word, index) => {
            return (
              <InputWord
                // eslint-disable-next-line react/no-array-index-key
                key={`generate_seed_phrase_${index}`}
                name={`generated_seed_phrase_word${index}`}
                value={word}
                readOnly
                ref={inputsRef[index]}
                classNames={{
                  InputContainer: classnames(
                    styles.WordInput_InputContainer,
                    isSecretText && styles.WordInput_InputContainer_isSecret,
                  ),
                  Container: styles.Input_Container,
                  Input: classnames(
                    styles.WordInput_Input,
                    (index + 1) % 4 === 0 && styles.WordInput_Input__noRightBorder,
                  ),
                }}
              />
            );
          })}
        </div>
      </div>
      <div className={styles.PaddingInnerContainer}>
        <div className={styles.ButtonContainer}>
          <Button
            colorType={ColorType.ORANGE}
            size={SizeButton.SMALL}
            onClick={() => {
              onCopyClick();
            }}
            title={copied ? stores.translate('copied') : stores.translate('copy')}
            classNames={{
              Container: styles.CopyButtonContainer,
              Button: styles.CopyButton,
              Title: classnames(
                styles.ButtonTitle,
                styles.ButtonTitle__Orange,
                styles.ButtonTitle__CopyButton,
              ),
            }}
          />
          <button type="button" className={styles.GreenButtonShow} onClick={onShowSecretText}>
            {isSecretText ? (
              <img
                onLoad={() => stores.increaseDownloadedImageCounter(ShowGreenButtonDefaultIcon)}
                onError={() => Logger.error('Error with image loading')}
                src={ShowGreenButtonDefaultIcon}
                alt="show_green_button_default_icon"
                className={styles.ShowButtonDefaultIcon}
              />
            ) : (
              <img
                onLoad={() => stores.increaseDownloadedImageCounter(ShowGreenButtonPressedIcon)}
                onError={() => Logger.error('Error with image loading')}
                src={ShowGreenButtonPressedIcon}
                alt="show_green_button_pressed_icon"
                className={styles.ShowButtonDefaultIcon}
              />
            )}
          </button>
        </div>
        <p className={styles.ImportantTitle}>
          {stores.translate('important')}&nbsp;
          <span className={styles.ImportantTitle__description}>
            {stores.translate('no_one_must_see_your_login')}
          </span>
        </p>
      </div>
    </>
  );
});

export default PrivateUsernameModal;
