export const GOOGLE_ANALYTICS = {
  CLICK_PRIVACY: 'CLICK_PRIVACY',
  CLICK_TERMS: 'CLICK_TERMS',
  CLICK_MAIL: 'CLICK_MAIL',
  CLICK_SUBSCRIBE: 'CLICK_SUBSCRIBE',

  CLOSE_MODAL: 'CLOSE_MODAL',

  CLICK_CONTACT_US: 'CLICK_CONTACT_US',
  CLICK_EVENT_SALE: 'CLICK_EVENT_SALE',
  CLICK_PLAY: 'CLICK_PLAY',
  CLICK_WHITE_PAPER: 'CLICK_WHITE_PAPER',
  CLICK_SOCIAL: 'CLICK_SOCIAL',
  CLICK_MONETA_TODAY: 'CLICK_MONETA_TODAY',
  CLICK_EXPLORER: 'CLICK_EXPLORER',
  CLICK_COMMUNITY: 'CLICK_COMMUNITY',
  CLICK_LISTING: 'CLICK_LISTING',
  CLICK_FAQ: 'CLICK_FAQ',
  CLICK_LOGIN: 'CLICK_LOGIN',
  CLICK_REGISTRATION: 'CLICK_REGISTRATION',
  CLICK_AUTHORIZE: 'CLICK_AUTHORIZE',
  CLICK_SIGN_UP: 'CLICK_SIGN_UP',
  CLICK_PROFILE: 'CLICK_PROFILE',
  CLICK_UNLOCK: 'CLICK_UNLOCK',
  CLICK_QUIT: 'CLICK_QUIT',
  CLICK_ENTER_ACCOUNT: 'CLICK_ENTER_ACCOUNT',
  CLICK_COPY: 'CLICK_COPY',
  CLICK_COPY_LINK: 'CLICK_COPY_LINK',
  CLICK_BUY: 'CLICK_BUY',
  CLICK_CLOSE: 'CLICK_CLOSE',
  CLICK_BINANCE_PAYMENT: 'CLICK_BINANCE_PAYMENT',
  CLICK_NOW_PAYMENT: 'CLICK_NOW_PAYMENT',
  CLICK_BURN_CONE: 'CLICK_BURN_CONE',
  CLICK_YIELD_CALCULATOR: 'CLICK_YIELD_CALCULATOR',
  CLICK_BECOME_PARTNER: 'CLICK_BECOME_PARTNER',
  CLICK_SHOW_MORE: 'CLICK_SHOW_MORE',
  CLICK_HIDE: 'CLICK_HIDE',
  CLICK_WATCH_INFO: 'CLICK_WATCH_INFO',
  CLICK_PARTICIPATE: 'CLICK_PARTICIPATE',
  CLICK_HOW_TO_PLAY: 'CLICK_HOW_TO_PLAY',
  CLICK_FEEDBACK_SEND: 'CLICK_FEEDBACK_SEND',
  CLICK_LOGOUT: 'CLICK_LOGOUT',
  CLICK_CHANGE_AVATAR: 'CLICK_CHANGE_AVATAR',
  CLICK_PRIVATE_USERNAME: 'CLICK_PRIVATE_USERNAME',
};

export default { GOOGLE_ANALYTICS };
