import React from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { getShortAddress, gtagEvent } from '@utils/common';
import { useStores } from 'src/stores';
import { GOOGLE_ANALYTICS } from 'src/constants/googleAnalyticsEvents';
import SocialNetwork from '@components/SocialNetwork';
import AvatarIcon from '@components/AvatarIcon';
import Close from '@src/assets/Mobile/control/close.png';
import Logger from '@utils/logger';
import styles from './mobileMenu.module.scss';

const MobileMenu = observer(({ states, methods }) => {
  const stores = useStores();
  const navigate = useNavigate();
  const _ = stores.language;

  const openMenu = (isOpen) => {
    stores.setOpenMenuMobile(isOpen);
  };

  return (
    stores.isOpenMenuMobile && (
      <div className={styles.MenuContainerShadow}>
        <div className={styles.MenuContainer}>
          <div
            className={styles.ModalClose}
            onClick={() => openMenu(false)}
            role="button"
            tabIndex={0}
            onKeyDown={() => openMenu(false)}
          >
            <img
              onLoad={() => stores.increaseDownloadedImageCounter(Close)}
              onError={() => Logger.error('Error with image loading')}
              src={Close}
              alt="Close"
            />
          </div>

          <div
            className={styles.PlayerMenuProfileBlock}
            role="button"
            onKeyDown={() => {}}
            tabIndex="0"
            onClick={() => {
              gtagEvent(GOOGLE_ANALYTICS.CLICK_PROFILE);
              if (!stores.isAuthorized) {
                methods.joinClick();
              }
              if (window.location.pathname !== '/account') {
                navigate('/account');
              }
              openMenu(false);
            }}
          >
            <AvatarIcon classNames={{ AvatarIconContainer: styles.PlayerImage }} width={40} />
            <div className={styles.PlayerMenuButton}>
              <p className={styles.MenuContainer__title}>
                {stores.isAuthorized
                  ? getShortAddress(states.userName) || getShortAddress(states.walletAddress)
                  : 'Login'}
              </p>
            </div>
          </div>

          <div className={styles.LinksContainer}>
            {states.menu.map((menuItem, index) => {
              return menuItem.isLink ? (
                <a
                  href={methods.getHeaderLinkInfo(menuItem.name)[0]}
                  target="_blank"
                  rel="noreferrer"
                  role="button"
                  className={styles.MenuContainer__item}
                  key={`header_${menuItem.name}`}
                  tabIndex={index}
                  onClick={() => {
                    gtagEvent(methods.getHeaderLinkInfo(menuItem.name)[1]);
                  }}
                >
                  <p className={styles.MenuContainer__title} id={`anchor_header_${menuItem.name}`}>
                    {menuItem.translate}
                  </p>
                </a>
              ) : (
                <div
                  role="button"
                  className={styles.MenuContainer__item}
                  key={`header_${menuItem.name}`}
                  onClick={() => {
                    if (window.location.pathname !== methods.getHeaderLinkRoute(menuItem.name)) {
                      navigate(methods.getHeaderLinkRoute(menuItem.name));
                    } else {
                      window.scrollTo(0, 0);
                    }
                    openMenu(false);
                  }}
                  onKeyDown={() => {
                    if (window.location.pathname !== methods.getHeaderLinkRoute(menuItem.name)) {
                      navigate(methods.getHeaderLinkRoute(menuItem.name));
                    } else {
                      window.scrollTo(0, 0);
                    }
                    openMenu(false);
                  }}
                  tabIndex={index}
                >
                  <p
                    className={classnames(styles.MenuContainer__title)}
                    id={`anchor_header_${menuItem.name}`}
                  >
                    {menuItem.translate}
                  </p>
                </div>
              );
            })}
          </div>

          <SocialNetwork classNames={{ Container: styles.SocialIconsContainer }} />
        </div>
      </div>
    )
  );
});

export default MobileMenu;
