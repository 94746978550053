import { BEARS } from '@src/api';
import {
    ALVEARY,
    APIARY,
    BABY_BEE,
    BEE,
    BEE_HOUSE,
    CHRISTMAS_DAY,
    COZY_HOUSE,
    DIAMOND_STING,
    FOREST_ACE,
    FOREST_BEE,
    FOREST_BEEHIVE,
    FOREST_EXPLORER, HALLOWEEN_DAY, HONEY_BAT,
    HONEY_BEE,
    HONEY_EXPLORER,
    HONEY_HUNTER, SCARYARY,
    SKILLED_BEE,
    SMALL_BEE,
    SNOW_BEE,
    STUMP
} from '@constants/blockchain';
import { floorRounding, toFloatApproximation } from '@utils/common';
import Logger from '@utils/logger';
import payments from 'src/api/server/payments';
import { StoreInstance } from '@src/stores';

const EXCLUDE_PACKS = ['THE_LEGEND', 'SAVIOR', 'BEE_PARTY', 'HALLOWEEN', 'CHRISTMAS'];

async function getPacksAndBlockchainObjects({
                                                stores = StoreInstance,
                                                controllerObject = undefined,
                                                beforeHandler = () => {},
                                                afterHandler = () => {},
                                                finallyHandler = () => {},
                                            } = {}) {
    if (!controllerObject) {
        controllerObject = { controller: new AbortController() };
    }

    try {
        beforeHandler();
        const result = await payments.getSellingPacks({ controller: controllerObject.controller });
        // console.log('result special packs >> ', result);
        const params = await BEARS.getParams();
        Logger.infoMainWithKey('params', params);
        if (params && params.apiaryTypes && params.beeTypes) {
            const stump = params.apiaryTypes.find((item) => item.apiaryType === STUMP);
            const beeHouse = params.apiaryTypes.find((item) => item.apiaryType === BEE_HOUSE);
            const apiary = params.apiaryTypes.find((item) => item.apiaryType === APIARY);
            const alveary = params.apiaryTypes.find((item) => item.apiaryType === ALVEARY);
            const smallBee = params.beeTypes.find((item) => item.beeType === SMALL_BEE);
            const skilledBee = params.beeTypes.find((item) => item.beeType === SKILLED_BEE);
            const diamondSting = params.beeTypes.find((item) => item.beeType === DIAMOND_STING);
            const bee = params.beeTypes.find((item) => item.beeType === BEE);
            const honeyBee = params.beeTypes.find((item) => item.beeType === HONEY_BEE);
            const honeyHunter = params.beeTypes.find((item) => item.beeType === HONEY_HUNTER);
            const honeyExplorer = params.beeTypes.find((item) => item.beeType === HONEY_EXPLORER);
            const babyBee = params.beeTypes.find((item) => item.beeType === BABY_BEE);
            // DAY Bee Fest
            const beeForest = params.beeTypes.find((item) => item.beeType === FOREST_BEE);
            const beeForestExplorer = params.beeTypes.find(
                (item) => item.beeType === FOREST_EXPLORER,
            );
            const beeForestAce = params.beeTypes.find((item) => item.beeType === FOREST_ACE);
            const forestBeehive = params.apiaryTypes.find(
                (item) => item.apiaryType === FOREST_BEEHIVE,
            );
            // Halloween
            const beeHoneyBat = params.beeTypes.find((item) => item.beeType === HONEY_BAT);
            const scaryary = params.apiaryTypes.find((item) => item.apiaryType === SCARYARY);
            // Christmas
            const snowBee = params.beeTypes.find((item) => item.beeType === SNOW_BEE);
            const cozyHouse= params.apiaryTypes.find((item) => item.apiaryType === COZY_HOUSE);

            const honeyPerHourText = stores.translate('honey_per_hour');
            const honeyStorageText = stores.translate('honey_storage');
            const beeSlotsText = stores.translate('bee_slots');

            stores.setParamsForObjectsBlockchain({
                stumpDescription: `${
                    stump && stump.spaceAvailable
                } ${beeSlotsText}<br />${toFloatApproximation({
                    value: stump && stump.maxHoney,
                })} ${honeyStorageText}`,
                beeHouseDescription: `${
                    beeHouse && beeHouse.spaceAvailable
                } ${beeSlotsText}<br />${toFloatApproximation({
                    value: beeHouse && beeHouse.maxHoney,
                })} ${honeyStorageText}`,
                apiaryDescription: `${
                    apiary && apiary.spaceAvailable
                } ${beeSlotsText}<br />${toFloatApproximation({
                    value: apiary && apiary.maxHoney,
                })} ${honeyStorageText}`,
                alvearyDescription: `${
                    alveary && alveary.spaceAvailable
                } ${beeSlotsText}<br />${toFloatApproximation({
                    value: alveary && alveary.maxHoney,
                })} ${honeyStorageText}`,
                forestBeehiveDescription: `${
                    forestBeehive && forestBeehive.spaceAvailable
                } ${beeSlotsText}<br />${toFloatApproximation({
                    value: forestBeehive && forestBeehive.maxHoney,
                })} ${honeyStorageText}`,
                babyBeeDescription: `${floorRounding(
                    toFloatApproximation({
                        value: babyBee && babyBee.honeyPerBlock,
                        coef: params.blocksPerHour,
                    }),
                    2,
                )} ${honeyPerHourText}`,
                smallBeeDescription: `${floorRounding(
                    toFloatApproximation({
                        value: smallBee && smallBee.honeyPerBlock,
                        coef: params.blocksPerHour,
                    }),
                    2,
                )} ${honeyPerHourText}`,
                skilledBeeDescription: `${floorRounding(
                    toFloatApproximation({
                        value: skilledBee && skilledBee.honeyPerBlock,
                        coef: params.blocksPerHour,
                    }),
                    2,
                )} ${honeyPerHourText}`,
                diamondBeeDescription: `${floorRounding(
                    toFloatApproximation({
                        value: diamondSting && diamondSting.honeyPerBlock,
                        coef: params.blocksPerHour,
                    }),
                    2,
                )} ${honeyPerHourText}`,
                beeDescription: `${floorRounding(
                    toFloatApproximation({
                        value: bee && bee.honeyPerBlock,
                        coef: params.blocksPerHour,
                    }),
                    2,
                )} ${honeyPerHourText}`,
                honeyBeeDescription: `${floorRounding(
                    toFloatApproximation({
                        value: honeyBee && bee.honeyPerBlock,
                        coef: params.blocksPerHour,
                    }),
                    2,
                )} ${honeyPerHourText}`,
                honeyHunterDescription: `${floorRounding(
                    toFloatApproximation({
                        value: honeyHunter && honeyHunter.honeyPerBlock,
                        coef: params.blocksPerHour,
                    }),
                    2,
                )} ${honeyPerHourText}`,
                honeyExplorerDescription: `${floorRounding(
                    toFloatApproximation({
                        value: honeyExplorer && honeyExplorer.honeyPerBlock,
                        coef: params.blocksPerHour,
                    }),
                    2,
                )} ${honeyPerHourText}`,
                honeyBeeForestDescription: `${floorRounding(
                    toFloatApproximation({
                        value: beeForest && beeForest.honeyPerBlock,
                        coef: params.blocksPerHour,
                    }),
                    2,
                )} ${honeyPerHourText}`,
                honeyBeeForestExplorerDescription: `${floorRounding(
                    toFloatApproximation({
                        value: beeForestExplorer && beeForestExplorer.honeyPerBlock,
                        coef: params.blocksPerHour,
                    }),
                    2,
                )} ${honeyPerHourText}`,
                honeyBeeForestAceDescription: `${floorRounding(
                    toFloatApproximation({
                        value: beeForestAce && beeForestAce.honeyPerBlock,
                        coef: params.blocksPerHour,
                    }),
                    2,
                )} ${honeyPerHourText}`,
                scaryaryDescription: `${honeyStorageText}: ${
                    toFloatApproximation({ value: scaryary && scaryary.maxHoney })
                    }<br />${beeSlotsText}: ${
                    scaryary && scaryary.spaceAvailable
                    }`,
                honeyBatDescription: `${stores.translate('honey_speed')}: ${floorRounding(
                    toFloatApproximation({
                        value: beeHoneyBat && beeHoneyBat.honeyPerBlock,
                        coef: params.blocksPerHour,
                    }),
                    2,
                )}<br />${stores.translate('air_consume')}: ${floorRounding(
                    (beeHoneyBat && beeHoneyBat.airConsume) /(beeHoneyBat && beeHoneyBat.airCountDependency),
                    2,
                )}`,
                cozyHouseDescription: `${honeyStorageText}: ${
                    toFloatApproximation({ value: cozyHouse && cozyHouse.maxHoney })
                    }<br />${beeSlotsText}: ${
                    scaryary && cozyHouse.spaceAvailable
                    }`,
                snowBeeDescription: `${stores.translate('honey_speed')}: ${floorRounding(
                    toFloatApproximation({
                        value: snowBee && snowBee.honeyPerBlock,
                        coef: params.blocksPerHour,
                    }),
                    2,
                )}<br />${stores.translate('air_consume')}: ${floorRounding(
                    (snowBee && snowBee.airConsume) /(snowBee && snowBee.airCountDependency),
                    2,
                )}`,
                flowerBarrelDescription: `Decoration`,
                fountainDescription: `Decoration`,
                beeTheSaviorDescription: `Decoration`,
                pumPumDescription: `Traditional<br />Decoration`,
                christmasBoxDescription: `Christmas<br />Decoration`,
            });
        }
        controllerObject.controller = null;
        afterHandler(result);
    } catch (err) {
        // eslint-disable-next-line no-console
        Logger.error(err);
    } finally {
        if (!controllerObject.controller) finallyHandler();
        // console.log('stores.paramsForObjectsBlockchain: ', stores.paramsForObjectsBlockchain);
    }
}

const isSpecialEventDayPacks = ({ events = [], packID = '', multiple = true }) => {
    // return events.includes(HALLOWEEN_DAY) && packID.includes('HALLOWEEN') && multiple;
    // return events.includes(CHRISTMAS_DAY) && packID.includes('CHRISTMAS') && multiple;
    return false;
}

const savePacks = ({ result, event = '' }) => {
    const packArrayFirstPurchased = [];
    const packArraySecondPurchased = [];
    const packArrayEvent = [];
    const packArrayHoney = [];

    if (result) {
        Object.values(result).forEach((pack) => {
            if (pack.ID.includes('HONEYCOIN_PACK')) {
                packArrayHoney.push(pack);
            } else if (pack.INIT_SET && pack.ID && !pack.ID.includes('WELCOME') && !pack.ID.includes('CHRISTMAS') && !pack.ID.includes('HALLOWEEN')) {
                if (isSpecialEventDayPacks({ events: [event], packID: pack.ID })) {
                    packArrayEvent.push(pack);
                } else {
                    packArrayFirstPurchased.push(pack);
                }
            } else if (!EXCLUDE_PACKS.some((elem) => String(pack.ID).indexOf(elem) !== -1) && pack.ID && !pack.ID.includes('WELCOME')) {
                if (isSpecialEventDayPacks({ events: [event], packID: pack.ID })) {
                    packArrayEvent.push(pack);
                } else {
                    packArraySecondPurchased.push(pack);
                }
            } else if (isSpecialEventDayPacks({ events: [event], packID: pack.ID })) {
                packArrayEvent.push(pack);
            }
        });
    }
    return {
        packArrayFirstPurchased,
        packArraySecondPurchased,
        packArrayEvent,
        packArrayHoney,
    }
}

export { getPacksAndBlockchainObjects, savePacks, isSpecialEventDayPacks };
