/* eslint-disable */
import Logger from '@utils/logger';
import styles from './arrowUp.module.scss';

const ArrowUpButton = ({ onClick = ()=>{}, width = 113, height = 119 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 113 119" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id='ArrowUpButtonSVG' onClick={onClick}>
        <g filter="url(#filter0_dii_697_2956)">
          <path d="M66.5 91.2265C60.312 94.7992 52.688 94.7992 46.5 91.2265L25.3638 79.0235C19.1758 75.4508 15.3638 68.8483 15.3638 61.703L15.3638 37.297C15.3638 30.1517 19.1758 23.5492 25.3638 19.9765L46.5 7.77351C52.688 4.20085 60.312 4.20085 66.5 7.7735L87.6362 19.9765C93.8242 23.5492 97.6362 30.1517 97.6362 37.297L97.6362 61.703C97.6362 68.8483 93.8242 75.4508 87.6362 79.0235L66.5 91.2265Z" fill="white"/>
          <path d="M66 90.3605C60.1214 93.7545 52.8786 93.7545 47 90.3605L25.8638 78.1575C19.9852 74.7634 16.3638 68.491 16.3638 61.703L16.3638 37.297C16.3638 30.509 19.9852 24.2365 25.8638 20.8425L47 8.63954C52.8786 5.2455 60.1214 5.2455 66 8.63953L87.1362 20.8425C93.0148 24.2365 96.6362 30.509 96.6362 37.297L96.6362 61.703C96.6362 68.491 93.0148 74.7635 87.1362 78.1575L66 90.3605Z" stroke="#A3C2D0" strokeWidth="2"/>
        </g>
        <g filter="url(#filter1_ddii_697_2956)">
          <path d="M64 80.6699C59.359 83.3494 53.641 83.3494 49 80.6699L33.2561 71.5801C28.6151 68.9006 25.7561 63.9487 25.7561 58.5897L25.7561 40.4103C25.7561 35.0513 28.6151 30.0994 33.2561 27.4199L49 18.3301C53.641 15.6506 59.359 15.6506 64 18.3301L79.7439 27.4199C84.3849 30.0994 87.2439 35.0513 87.2439 40.4103L87.2439 58.5897C87.2439 63.9487 84.3849 68.9006 79.7439 71.5801L64 80.6699Z" fill="url(#paint0_linear_697_2956)"/>
          <path d="M63.5 79.8038C59.1684 82.3047 53.8316 82.3047 49.5 79.8038L33.7561 70.7141C29.4245 68.2132 26.7561 63.5915 26.7561 58.5897L26.7561 40.4103C26.7561 35.4085 29.4245 30.7868 33.7561 28.2859L49.5 19.1962C53.8316 16.6953 59.1684 16.6953 63.5 19.1962L79.2439 28.2859C83.5755 30.7868 86.2439 35.4085 86.2439 40.4103L86.2439 58.5897C86.2439 63.5915 83.5755 68.2132 79.2439 70.7141L63.5 79.8038Z" stroke="#CD1E33" strokeWidth="2"/>
        </g>
        <g filter="url(#filter2_dii_697_2956)">
          <path d="M42.0609 46.6705L53.9426 35.0433C54.5989 34.3478 55.4929 34 56.4774 34C57.4619 34 58.3445 34.3478 59.0121 35.0433L70.9391 46.6705C72.3536 48.05 72.3536 50.299 70.9391 51.6784C69.5246 53.0579 67.2388 53.0579 65.8243 51.6784L60.1211 46.0909L60.1211 60.4527C60.1211 62.4119 58.5029 64 56.5 64C54.4971 64 52.8789 62.4119 52.8789 60.4527L52.8789 46.0909L47.1757 51.6784C45.7612 53.0579 43.4754 53.0579 42.0609 51.6784C40.6464 50.2989 40.6464 48.0616 42.0609 46.6705Z" fill="white"/>
        </g>

        <g id='ArrowUpButtonHover'>
          <g filter="url(#filter1_ddii_352_1136)">
            <path d="M64 84.6699C59.359 87.3494 53.641 87.3494 49 84.6699L33.2561 75.5801C28.6151 72.9006 25.7561 67.9487 25.7561 62.5897L25.7561 44.4103C25.7561 39.0513 28.6151 34.0994 33.2561 31.4199L49 22.3301C53.641 19.6506 59.359 19.6506 64 22.3301L79.7439 31.4199C84.3849 34.0994 87.2439 39.0513 87.2439 44.4103L87.2439 62.5897C87.2439 67.9487 84.3849 72.9006 79.7439 75.5801L64 84.6699Z" fill="url(#paint0_linear_352_1136)"/>
            <path d="M63.5 83.8038C59.1684 86.3047 53.8316 86.3047 49.5 83.8038L33.7561 74.7141C29.4245 72.2132 26.7561 67.5915 26.7561 62.5897L26.7561 44.4103C26.7561 39.4085 29.4245 34.7868 33.7561 32.2859L49.5 23.1962C53.8316 20.6953 59.1684 20.6953 63.5 23.1962L79.2439 32.2859C83.5755 34.7868 86.2439 39.4085 86.2439 44.4103L86.2439 62.5897C86.2439 67.5915 83.5755 72.2132 79.2439 74.7141L63.5 83.8038Z" stroke="#CD1E33" strokeWidth="2"/>
          </g>
          <g opacity="0.6" filter="url(#filter2_f_352_1136)">
            <path d="M64 84.6699C59.359 87.3494 53.641 87.3494 49 84.6699L33.2561 75.5801C28.6151 72.9006 25.7561 67.9487 25.7561 62.5897L25.7561 44.4103C25.7561 39.0513 28.6151 34.0994 33.2561 31.4199L49 22.3301C53.641 19.6506 59.359 19.6506 64 22.3301L79.7439 31.4199C84.3849 34.0994 87.2439 39.0513 87.2439 44.4103L87.2439 62.5897C87.2439 67.9487 84.3849 72.9006 79.7439 75.5801L64 84.6699Z" fill="#FAFF00"/>
          </g>
        </g>

        <g id='ArrowUpButtonActive'>
          <g filter="url(#filter1_ddii_352_1138)">
            <path d="M64 80.6699C59.359 83.3494 53.641 83.3494 49 80.6699L33.2561 71.5801C28.6151 68.9006 25.7561 63.9487 25.7561 58.5897L25.7561 40.4103C25.7561 35.0513 28.6151 30.0994 33.2561 27.4199L49 18.3301C53.641 15.6506 59.359 15.6506 64 18.3301L79.7439 27.4199C84.3849 30.0994 87.2439 35.0513 87.2439 40.4103L87.2439 58.5897C87.2439 63.9487 84.3849 68.9006 79.7439 71.5801L64 80.6699Z" fill="url(#paint0_linear_352_1138)"/>
            <path d="M63.5 79.8038C59.1684 82.3047 53.8316 82.3047 49.5 79.8038L33.7561 70.7141C29.4245 68.2132 26.7561 63.5915 26.7561 58.5897L26.7561 40.4103C26.7561 35.4085 29.4245 30.7868 33.7561 28.2859L49.5 19.1962C53.8316 16.6953 59.1684 16.6953 63.5 19.1962L79.2439 28.2859C83.5755 30.7868 86.2439 35.4085 86.2439 40.4103L86.2439 58.5897C86.2439 63.5915 83.5755 68.2132 79.2439 70.7141L63.5 79.8038Z" stroke="#CD1E33" strokeWidth="2"/>
          </g>
          <g filter="url(#filter2_dii_352_1138)">
            <path d="M42.0609 46.6705L53.9426 35.0433C54.5989 34.3478 55.4929 34 56.4774 34C57.4619 34 58.3445 34.3478 59.0121 35.0433L70.9391 46.6705C72.3536 48.05 72.3536 50.299 70.9391 51.6784C69.5246 53.0579 67.2388 53.0579 65.8243 51.6784L60.1211 46.0909L60.1211 60.4527C60.1211 62.4119 58.5029 64 56.5 64C54.4971 64 52.8789 62.4119 52.8789 60.4527L52.8789 46.0909L47.1757 51.6784C45.7612 53.0579 43.4754 53.0579 42.0609 51.6784C40.6464 50.2989 40.6464 48.0616 42.0609 46.6705Z" fill="white"/>
          </g>
        </g>

      </g>
      <defs>
        <filter id="filter0_dii_697_2956" x="0.36377" y="-0.906006" width="112.272" height="119.812" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="10"/>
          <feGaussianBlur stdDeviation="7.5"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_697_2956"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_697_2956" result="shape"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="-6"/>
          <feGaussianBlur stdDeviation="3"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_697_2956"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="-3"/>
          <feGaussianBlur stdDeviation="3"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"/>
          <feBlend mode="normal" in2="effect2_innerShadow_697_2956" result="effect3_innerShadow_697_2956"/>
        </filter>
        <filter id="filter1_ddii_697_2956" x="15.7561" y="10.3206" width="81.4878" height="88.3589" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="6"/>
          <feGaussianBlur stdDeviation="5"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_697_2956"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="3"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="effect1_dropShadow_697_2956" result="effect2_dropShadow_697_2956"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_697_2956" result="shape"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="10"/>
          <feGaussianBlur stdDeviation="5"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.84 0 0 0 0 0 0 0 0 1 0"/>
          <feBlend mode="normal" in2="shape" result="effect3_innerShadow_697_2956"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="-5"/>
          <feGaussianBlur stdDeviation="5"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.827451 0 0 0 0 0.211765 0 0 0 0.5 0"/>
          <feBlend mode="normal" in2="effect3_innerShadow_697_2956" result="effect4_innerShadow_697_2956"/>
        </filter>
        <filter id="filter2_dii_697_2956" x="39" y="32" width="35" height="35" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="1"/>
          <feGaussianBlur stdDeviation="1"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_697_2956"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_697_2956" result="shape"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="-2"/>
          <feGaussianBlur stdDeviation="1"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.452812 0 0 0 0 0.683375 0 0 0 0 0.7875 0 0 0 1 0"/>
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_697_2956"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="-2"/>
          <feGaussianBlur stdDeviation="1.5"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
          <feBlend mode="normal" in2="effect2_innerShadow_697_2956" result="effect3_innerShadow_697_2956"/>
        </filter>
        <linearGradient id="paint0_linear_697_2956" x1="50.3671" y1="85" x2="50.3671" y2="14" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EA6400"/>
          <stop offset="1" stopColor="#FFB800"/>
        </linearGradient>
        {/* Hover */}
        <filter id="filter2_f_352_1136" x="5.7561" y="0.320557" width="101.488" height="106.359" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur_352_1136"/>
        </filter>
        <filter id="filter3_dii_352_1136" x="39" y="36" width="35" height="35" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="1"/>
          <feGaussianBlur stdDeviation="1"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_352_1136"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_352_1136" result="shape"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="-2"/>
          <feGaussianBlur stdDeviation="1"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.452812 0 0 0 0 0.683375 0 0 0 0 0.7875 0 0 0 1 0"/>
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_352_1136"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="-2"/>
          <feGaussianBlur stdDeviation="1.5"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
          <feBlend mode="normal" in2="effect2_innerShadow_352_1136" result="effect3_innerShadow_352_1136"/>
        </filter>
        <linearGradient id="paint0_linear_352_1136" x1="50.3671" y1="89" x2="50.3671" y2="18" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EA6400"/>
          <stop offset="1" stopColor="#FFB800"/>
        </linearGradient>
        {/* Active */}
        <filter id="filter1_ddii_352_1138" x="15.7561" y="10.3206" width="81.4878" height="88.3589" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="6"/>
          <feGaussianBlur stdDeviation="5"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_352_1138"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="3"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="effect1_dropShadow_352_1138" result="effect2_dropShadow_352_1138"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_352_1138" result="shape"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="10"/>
          <feGaussianBlur stdDeviation="5"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.619608 0 0 0 0 0.231373 0 0 0 0 0.0117647 0 0 0 1 0"/>
          <feBlend mode="normal" in2="shape" result="effect3_innerShadow_352_1138"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="-5"/>
          <feGaussianBlur stdDeviation="5"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.827451 0 0 0 0 0.211765 0 0 0 0.5 0"/>
          <feBlend mode="normal" in2="effect3_innerShadow_352_1138" result="effect4_innerShadow_352_1138"/>
        </filter>
        <filter id="filter2_dii_352_1138" x="39" y="32" width="35" height="35" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="1"/>
          <feGaussianBlur stdDeviation="1"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_352_1138"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_352_1138" result="shape"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="-2"/>
          <feGaussianBlur stdDeviation="1"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.452812 0 0 0 0 0.683375 0 0 0 0 0.7875 0 0 0 1 0"/>
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_352_1138"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="-2"/>
          <feGaussianBlur stdDeviation="1.5"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
          <feBlend mode="normal" in2="effect2_innerShadow_352_1138" result="effect3_innerShadow_352_1138"/>
        </filter>
        <linearGradient id="paint0_linear_352_1138" x1="50.3671" y1="85" x2="50.3671" y2="14" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EA6400"/>
          <stop offset="1" stopColor="#CC460B"/>
        </linearGradient>
      </defs>
      <style>
          {`
            #ArrowUpButtonSVG #ArrowUpButtonActive {
              display: none;
            }
            #ArrowUpButtonSVG #ArrowUpButtonHover {
              display: none;
            }

            @media (hover: hover) {
              #ArrowUpButtonSVG:hover {
                cursor: pointer;
              }
              #ArrowUpButtonSVG:hover #ArrowUpButtonHover {
                display: block;
              }
            }

            #ArrowUpButtonSVG:active #ArrowUpButtonActive {
              display: block
            }

            {/* #ArrowUpButtonSVG:active #ArrowUpButtonActive {
              display: block
            }
            ArrowUpButtonActive */}
          `}
      </style>
    </svg>
  )
}

export default ArrowUpButton;