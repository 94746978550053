import _ from 'lodash';
import { BEARS } from 'src/api';
import { stringify } from '@utils/common';
import Logger from '@utils/logger';
import { GameMethodNames, GameObjectNames } from 'src/stores/unity';
import { MILLION } from '@constants/system';
import { StoreInstance } from 'src/stores';
import server from 'src/api/server/explorer';
import settings from 'src/api/server/settings';

import { handleMainError } from '../HandleError';

const stores = StoreInstance;
/* eslint-disable max-len */

const requestLeaderBoard = async ({ type, index }) => {
  const FIRST_QUANTITY_BEARS = 50;
  const NEXT_QUANTITY_BEARS = 25.0;
  const indexNextPages = index - FIRST_QUANTITY_BEARS;

  let page = 1;
  let limit = FIRST_QUANTITY_BEARS;

  if (index === 1 || (index > 50 && (index - FIRST_QUANTITY_BEARS) % NEXT_QUANTITY_BEARS === 1)) {
    if (indexNextPages > 0) {
      const countNextPages = Math.ceil(indexNextPages / NEXT_QUANTITY_BEARS);
      Logger.infoMainWithKey('countNextPages', countNextPages);
      page = 2 + countNextPages;
      limit = NEXT_QUANTITY_BEARS;
    }
    const responseListLeaderBoard = await server.listBearsLeaderBoard({ page, limit, sortType: type });
    if (responseListLeaderBoard.err) {
      handleMainError({ code: 150 });
      return;
    }
    Logger.infoMainWithKey('responseListLeaderBoard requestLeaderBoard', responseListLeaderBoard);
    const addresses = responseListLeaderBoard.map((leaderBoardItem) => leaderBoardItem.address);

    const profiles = await settings.listProfile({ addresses });
    if (profiles.err) {
      handleMainError({ code: 113, useProduction: false });
      return;
    }
    const listLeaderBoardArray = responseListLeaderBoard.map((leaderBoard) => {
      const profile = _.find(profiles, { 'address': leaderBoard.address });
      return {...leaderBoard, avatar: profile.avatar }
    })
    Logger.infoMainWithKey('listLeaderBoardArray', listLeaderBoardArray);

    const listBears = listLeaderBoardArray.map((elementLeaderBoard) => {
      const name = elementLeaderBoard.bearNames && elementLeaderBoard.bearNames.length > 0 ? elementLeaderBoard.bearNames[0] : elementLeaderBoard.address;
      let amount = -1;
      if (type === 'uhoneyBalance') {
        amount = elementLeaderBoard[type] / MILLION;
      } else if (type === 'uhoneyPower') {
        amount = (elementLeaderBoard[type] * stores.unityStore.blocksPerHour) / MILLION;
      }

      return { name, amount, type, avatar: elementLeaderBoard.avatar };
    });
    stores.unityStore.send({
      objectName: GameObjectNames.LobbyReact,
      methodName: GameMethodNames.UpdateLeaderBoard,
      values: stringify(listBears),
    });
  }
  const responseBearRating = await server.playerRatingOnLeaderBoard({ type });
  if (responseBearRating.err || responseBearRating < 0 || responseBearRating.level === 'error') {
    handleMainError({ code: 151 });
    return;
  }
  Logger.infoMainWithKey('responseBearRating requestLeaderBoard', responseBearRating);

  let amount = -1;
  if (responseBearRating.address && type === 'uhoneyBalance') {
    amount = responseBearRating.address[type] / MILLION;
  } else if (responseBearRating.address && type === 'uhoneyPower') {
    amount = (responseBearRating.address[type] * stores.unityStore.blocksPerHour) / MILLION;
  } else if (responseBearRating.address) {
    amount = responseBearRating.address[type];
  }
  if (amount === -1 && type === 'uhoneyBalance') {
    amount = await BEARS.getHoney({ address: stores.profileAvailable.address });
  }

  stores.unityStore.send({
    objectName: GameObjectNames.LobbyReact,
    methodName: GameMethodNames.PlayerRatingOnLeaderBoard,
    values: stringify({ rating: responseBearRating.position || -1, amount, type }),
  });
};

const requestAvatarPlayers = async ({ playersData }) => {
  const profiles = await settings.listProfile({ addresses: JSON.parse(playersData) });
  if (profiles.err) {
    handleMainError({ code: 113, useProduction: false });
  } else {
    const playersDataResponse = profiles.map((profile) => ({ address: profile.address, avatar: profile.avatar }));
    stores.unityStore.send({
      objectName: GameObjectNames.LobbyReact,
      methodName: GameMethodNames.UpdateAvatarPlayers,
      values: stringify(playersDataResponse),
    });
  }
};

export default {
  requestLeaderBoard,
  requestAvatarPlayers,
};
