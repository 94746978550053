import i18n from 'i18n-js';
import { DEFAULT_LANGUAGE } from '@constants/system';
import ru from './ru.json';
import en from './en.json';
import es from './es.json';
import vn from './vn.json';
import ph from './ph.json';

i18n.fallbacks = true;
i18n.translations = { ru, en, es, vn, ph };

i18n.locale = DEFAULT_LANGUAGE;
// i18n.locales = ['ru', 'en', 'es', 'vn', 'ph'];

// i18n.languages = ['ru', 'en', 'es', 'vn', 'ph'];
i18n.languages = ['en']; // MVP, use one language - en

export default i18n;