export const ENUM_MODAL = {
  CONTACT_US: 'CONTACT_US',
  JOIN: 'JOIN',
  SEED_PHRASE: 'SEED_PHRASE',
  LOGIN: 'LOGIN',
  APPROVE_REGISTRATION: 'APPROVE_REGISTRATION',
  VERIFICATION_ACCESS_WALLET: 'VERIFICATION_ACCESS_WALLET',
  VERIFY_YOURSELF: 'VERIFY_YOURSELF',
  PRIVATE_USERNAME: 'PRIVATE_USERNAME',
  SEND_TOKEN: 'SEND_TOKEN',
  CHANGE_NICKNAME: 'CHANGE_NICKNAME',
  CHANGE_AVATAR: 'CHANGE_AVATAR',
  // PAYMENTS: 'payments',

  // UNITY_STACKING: 'unity-stake',
  // UNITY_APPROVE_TRANSACTION: 'UNITY_APPROVE_TRANSACTION',
  // UNITY_VERIFY_YOURSELF: 'UNITY_VERIFY_YOURSELF',
  // UNITY_CHECK_IN_DAILY_BONUS: 'UNITY_CHECK_IN_DAILY_BONUS',
  // UNITY_STACKING: 'UNITY_STACKING',
  // UNITY_UNSTACKING: 'UNITY_UNSTACKING',
}

export default {
  ENUM_MODAL,
}