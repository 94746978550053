import React, { useState } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { sanitize } from 'dompurify';
import { useStores } from '@src/stores';
import { Button, ColorType, SizeButton } from 'src/components_new/Button';
import { gtagEvent } from '@utils/common';
import { GOOGLE_ANALYTICS } from '@src/constants/googleAnalyticsEvents';
import RomanCMobile from '@assets/Mobile/AboutPage/OurTeamSection/RomanCMobile.png';
import AlexBMobile from '@assets/Mobile/AboutPage/OurTeamSection/AlexBMobile.png';
import GeorgeMMobile from '@assets/Mobile/AboutPage/OurTeamSection/GeorgeMMobile.png';
import AndreyBMobile from '@assets/Mobile/AboutPage/OurTeamSection/AndreyBMobile.png';
import KostyaMMobile from '@assets/Mobile/AboutPage/OurTeamSection/KostyaMMobile.png';
import IgorTMobile from '@assets/Mobile/AboutPage/OurTeamSection/IgorTMobile.png';
import OlgaSMobile from '@assets/Mobile/AboutPage/OurTeamSection/OlgaS.png';
import TimNMobile from '@assets/Mobile/AboutPage/OurTeamSection/TimN.png';
import DmitrySMobile from '@assets/Mobile/AboutPage/OurTeamSection/DmitryS.png';
import MichaelBMobile from '@assets/Mobile/AboutPage/OurTeamSection/MichaelB.png';
import GradientBottom from '@assets/Mobile/AboutPage/OurTeamSection/gradient_bottom.svg';
import RepresentativesIcon from '@assets/Mobile/AboutPage/OurTeamSection/representatives_icon.png';
import RichardB from '@assets/Mobile/AboutPage/OurTeamSection/RichardB.png';
import KostyaT from '@assets/Mobile/AboutPage/OurTeamSection/KostyaT.png';
import VladimirU from '@assets/Mobile/AboutPage/OurTeamSection/VladimirU.png';
import IgorT from '@assets/Mobile/AboutPage/OurTeamSection/IgorT.png';
import AntonR from '@assets/Mobile/AboutPage/OurTeamSection/AntonR.png';
import ElenaB from '@assets/Mobile/AboutPage/OurTeamSection/ElenaB.png';
import AntonC from '@assets/Mobile/AboutPage/OurTeamSection/AntonC.png';
import AlexS from '@assets/Mobile/AboutPage/OurTeamSection/AlexS.png';
import TitleSection from '@src/components/TitleSection';
import SocialsPersonal from '@src/components/SocialsPersonal';
import { PERSONAL_SOCIALS_LINK } from '@constants/system';
import Logger from '@utils/logger';
import styles from './mobileTeam.module.scss';

const TeamItem = ({
  title = 'Title',
  textHtml = 'lorem',
  descriptionHtml = '',
  photo = {},
  classes = {},
  socialLinks = [],
  isRealPhoto = false,
}) => {
  const stores = useStores();
  return (
    <div className={styles.TeamCardContainer}>
      {!isRealPhoto && (
        <img
          onLoad={() => stores.increaseDownloadedImageCounter(photo)}
          onError={() => Logger.error('Error with image loading')}
          src={photo}
          alt="person_photo"
          className={styles.TeamItemImage}
        />
      )}
      <div
        className={classnames(
          styles.TeamCard,
          !isRealPhoto && styles.TeamCard__margin_top,
          classes.TeamCard,
        )}
      >
        {isRealPhoto && (
          <img
            onLoad={() => stores.increaseDownloadedImageCounter(photo)}
            onError={() => Logger.error('Error with image loading')}
            src={photo}
            alt="person_photo"
            className={styles.TeamItemImage}
          />
        )}
        <TitleSection title={title} textHtml={textHtml} classNames={classes} />
        <div className={styles.SocialContainer}>
          <SocialsPersonal socialLinks={socialLinks} />
        </div>
        {descriptionHtml && (
          <p
            className={classnames(styles.TeamItemDescription, classes.TeamItemDescription)}
            dangerouslySetInnerHTML={{ __html: sanitize(descriptionHtml) }}
          />
        )}
      </div>
    </div>
  );
};

const RepresentativesItem = ({
  title = 'Title',
  textHtml = 'lorem',
  descriptionHtml = '',
  photo = RepresentativesIcon,
  classes = {},
  socialLinks = [],
}) => {
  const stores = useStores();
  return (
    <div className={styles.RepresentativesCard}>
      <div className={styles.RepresentativesInfoBlock}>
        <img
          onLoad={() => stores.increaseDownloadedImageCounter(photo)}
          onError={() => Logger.error('Error with image loading')}
          src={photo}
          alt="person_photo"
          className={styles.RepresentativeImage}
        />
        <div className={styles.RepresentativesInfo}>
          <TitleSection title={title} textHtml={textHtml} classNames={classes} />
          <SocialsPersonal socialLinks={socialLinks} />
        </div>
      </div>
      {descriptionHtml && (
        <p
          className={styles.RepresentativesItemDescription}
          dangerouslySetInnerHTML={{ __html: sanitize(descriptionHtml) }}
        />
      )}
    </div>
  );
};

const OurTeamSection = observer(() => {
  const stores = useStores();
  const _ = stores.language;
  const OurTeam = stores.translate('our_team');
  const [isShowOtherTeamMembers, setShowOtherTeamMembers] = useState(false);
  return (
    <div className={styles.GreenWrapperContainer}>
      <div className={styles.Container} id="our-team">
        <TitleSection
          title={stores.translate('team')}
          classNames={{
            Container: styles.TitleContainer,
            Title: styles.Title,
          }}
          isH4TitleTag
        />
        <div className={styles.TeamItemsContainer}>
          <TeamItem
            title={OurTeam[0].name}
            textHtml={OurTeam[0].position}
            photo={RomanCMobile}
            classes={{
              Container: styles.TeamNameContainer,
              Title: styles.TeamName,
              Text: styles.TeamPosition,
            }}
            socialLinks={PERSONAL_SOCIALS_LINK.RomanC}
            isRealPhoto
          />
          {/* <TeamItem
            title={OurTeam[1].name}
            textHtml={OurTeam[1].position}
            photo={AlexBMobile}
            classes={{
              Container: styles.TeamNameContainer,
              Title: styles.TeamName,
              Text: styles.TeamPosition,
            }}
            socialLinks={PERSONAL_SOCIALS_LINK.AlexB}
            isRealPhoto
          /> */}
          {/* <TeamItem
            title={OurTeam[2].name}
            textHtml={OurTeam[2].position}
            photo={GeorgeMMobile}
            classes={{
              Container: styles.TeamNameContainer,
              Title: styles.TeamName,
              Text: styles.TeamPosition,
            }}
            socialLinks={PERSONAL_SOCIALS_LINK.GeorgeM}
            isRealPhoto
          /> */}
          <TeamItem
            title={OurTeam[3].name}
            textHtml={OurTeam[3].position}
            photo={AndreyBMobile}
            classes={{
              Container: styles.TeamNameContainer,
              Title: styles.TeamName,
              Text: styles.TeamPosition,
            }}
            socialLinks={PERSONAL_SOCIALS_LINK.AndreyB}
            isRealPhoto
          />
          <TeamItem
            title={OurTeam[16].name}
            textHtml={OurTeam[16].position}
            photo={IgorTMobile}
            classes={{
              Container: styles.TeamNameContainer,
              Title: styles.TeamName,
              Text: styles.TeamPosition,
            }}
            socialLinks={PERSONAL_SOCIALS_LINK.IgorT}
            isRealPhoto
          />
          <TeamItem
            title={OurTeam[17].name}
            textHtml={OurTeam[17].position}
            photo={KostyaMMobile}
            classes={{
              Container: styles.TeamNameContainer,
              Title: styles.TeamName,
              Text: classnames(styles.TeamPosition, styles.TeamPosition__LongName),
            }}
            socialLinks={PERSONAL_SOCIALS_LINK.KostyaM}
            isRealPhoto
          />
          {/* {!isShowOtherTeamMembers && (
            <img
              onLoad={() => stores.increaseDownloadedImageCounter(GradientBottom)}
              onError={() => Logger.error('Error with image loading')}
              src={GradientBottom}
              alt="person_photo"
              className={styles.GradientBottom}
            />
          )} */}
        </div>
        {/* {!isShowOtherTeamMembers && (
          <Button
            colorType={ColorType.ORANGE}
            size={SizeButton.SMALL}
            title={stores.translate('show_more')}
            classNames={{
              Container: styles.ButtonContainer,
              Button: styles.ShowMoreButton,
              Title: classnames(
                styles.TitleButton,
                stores.browserInfo.isAndroid
                  ? styles.TitleOrangeButton__android
                  : styles.TitleOrangeButton,
              ),
            }}
            onClick={() => {
              gtagEvent(GOOGLE_ANALYTICS.CLICK_SHOW_MORE);
              setShowOtherTeamMembers(true);
            }}
          />
        )} */}
        {/* {isShowOtherTeamMembers && (
          <>
            <div
              className={classnames(
                styles.TeamItemsContainer,
                styles.TeamItemsContainer__Max_width_910px,
              )}
            >
              <TeamItem
                title={OurTeam[4].name}
                textHtml={OurTeam[4].position}
                photo={OlgaSMobile}
                classes={{
                  Container: styles.TeamNameContainer,
                  Title: styles.TeamName,
                  Text: styles.TeamPosition,
                }}
                socialLinks={PERSONAL_SOCIALS_LINK.OlgaS}
              />
              <TeamItem
                title={OurTeam[5].name}
                textHtml={OurTeam[5].position}
                photo={TimNMobile}
                classes={{
                  Container: styles.TeamNameContainer,
                  Title: styles.TeamName,
                  Text: styles.TeamPosition,
                }}
                socialLinks={PERSONAL_SOCIALS_LINK.TimN}
              />
              <TeamItem
                title={OurTeam[6].name}
                textHtml={OurTeam[6].position}
                photo={DmitrySMobile}
                classes={{
                  Container: styles.TeamNameContainer,
                  Title: styles.TeamName,
                  Text: styles.TeamPosition,
                }}
                socialLinks={PERSONAL_SOCIALS_LINK.DmitryS}
              />
              <TeamItem
                title={OurTeam[7].name}
                textHtml={OurTeam[7].position}
                photo={MichaelBMobile}
                classes={{
                  Container: styles.TeamNameContainer,
                  Title: styles.TeamName,
                  Text: styles.TeamPosition,
                }}
                socialLinks={PERSONAL_SOCIALS_LINK.MichaelB}
              />
              <TeamItem
                title={OurTeam[8].name}
                textHtml={OurTeam[8].position}
                photo={AntonR}
                classes={{
                  Container: styles.TeamNameContainer,
                  Title: styles.TeamName,
                  Text: styles.TeamPosition,
                }}
                socialLinks={PERSONAL_SOCIALS_LINK.AntonR}
              />
              <TeamItem
                title={OurTeam[9].name}
                textHtml={OurTeam[9].position}
                photo={ElenaB}
                classes={{
                  Container: styles.TeamNameContainer,
                  Title: styles.TeamName,
                  Text: styles.TeamPosition,
                }}
                socialLinks={PERSONAL_SOCIALS_LINK.ElenaB}
              />
              <TeamItem
                title={OurTeam[10].name}
                textHtml={OurTeam[10].position}
                photo={AntonC}
                classes={{
                  Container: styles.TeamNameContainer,
                  Title: styles.TeamName,
                  Text: styles.TeamPosition,
                }}
                socialLinks={PERSONAL_SOCIALS_LINK.AntonC}
              />
              <TeamItem
                title={OurTeam[11].name}
                textHtml={OurTeam[11].position}
                photo={AlexS}
                classes={{
                  Container: styles.TeamNameContainer,
                  Title: styles.TeamName,
                  Text: styles.TeamPosition,
                }}
                socialLinks={PERSONAL_SOCIALS_LINK.AlexanderS}
              />
            </div>
            <Button
              colorType={ColorType.ORANGE}
              size={SizeButton.SMALL}
              title={stores.translate('hide')}
              classNames={{
                Container: styles.ButtonContainer,
                Button: styles.ShowMoreButton,
                Title: classnames(
                  styles.TitleButton,
                  stores.browserInfo.isAndroid
                    ? styles.TitleOrangeButton__android
                    : styles.TitleOrangeButton,
                ),
              }}
              onClick={() => {
                gtagEvent(GOOGLE_ANALYTICS.CLICK_HIDE);
                setShowOtherTeamMembers(false);
              }}
            />
          </>
        )} */}
      </div>
      <div className={styles.RepresentativesContainerWrapper}>
        <div className={styles.RepresentativesContainer}>
          <TitleSection
            title={stores.translate('global_representatives')}
            classNames={{ Container: styles.RepresentativesTitleContainer }}
            isH4TitleTag
          />
          <RepresentativesItem
            title={OurTeam[12].name}
            textHtml={OurTeam[12].position}
            descriptionHtml={OurTeam[12].description}
            photo={KostyaT}
            classes={{
              Container: styles.RepresentativesPosition,
              Title: classnames(styles.TeamName, styles.RepresentativesName),
              Text: classnames(styles.TeamPosition, styles.TeamPosition__Representatives),
            }}
            socialLinks={PERSONAL_SOCIALS_LINK.KostyaT}
          />
          <RepresentativesItem
            title={OurTeam[13].name}
            textHtml={OurTeam[13].position}
            descriptionHtml={OurTeam[13].description}
            photo={VladimirU}
            classes={{
              Container: styles.RepresentativesPosition,
              Title: classnames(styles.TeamName, styles.RepresentativesName),
              Text: classnames(styles.TeamPosition, styles.TeamPosition__Representatives),
            }}
            socialLinks={PERSONAL_SOCIALS_LINK.VladimirU}
          />
          {/* <RepresentativesItem
            title={OurTeam[14].name}
            textHtml={OurTeam[14].position}
            descriptionHtml={OurTeam[14].description}
            photo={IgorT}
            classes={{
              Container: styles.RepresentativesPosition,
              Title: classnames(styles.TeamName, styles.RepresentativesName),
              Text: classnames(styles.TeamPosition, styles.TeamPosition__Representatives),
            }}
            socialLinks={PERSONAL_SOCIALS_LINK.IgorT}
          /> */}
        </div>
      </div>
    </div>
  );
});

export default OurTeamSection;
