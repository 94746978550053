/* eslint-disable */
import {
  CHAIN_ID_HONEYWOOD,
  CONFIG_HONEYWOOD_BLOCKCHAIN,
  DENOM,
  NO_CHAIN_ERR_MSG,
  TYPE_AUTH_CLIENT,
  URL_MATCH3_SEND,
} from '@constants/blockchain';
import {
  DEVELOPMENT,
  ENCRYPT_PROFILE,
  ENCRYPT_WALLET,
  MILLION,
  PRODUCTION,
  RPC_URL,
} from '@constants/system';
import { SigningCosmosClient } from '@cosmjs/launchpad';
import { DirectSecp256k1HdWallet } from '@cosmjs/proto-signing';
import Logger from '@utils/logger';

import { assertIsBroadcastTxSuccess, calculateFee, SigningStargateClient } from '@cosmjs/stargate';

import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx';
import { coins } from '@cosmjs/stargate';
import { toBase64, toHex } from '@cosmjs/encoding';
import { Secp256k1, sha256 } from '@cosmjs/crypto';

import { updateProfileInfo } from '@utils/methodsBears';
import { useEffect, useState } from 'react';
import { StoreInstance, useStores } from 'src/stores';
import { ENUM_POPUP } from 'src/components_new/PopUp/enumPopup';
import match3 from 'src/api/server/match3';
import { BEARS } from '@api';
import { setSecureStorage } from 'src/stores/system';

const stores = StoreInstance;
const stage = process.env.REACT_APP_STAGE === PRODUCTION ? PRODUCTION : DEVELOPMENT;

const chainHoneyWoodConfig = {
  chainId: CHAIN_ID_HONEYWOOD,
  chainName: CONFIG_HONEYWOOD_BLOCKCHAIN[stage].chainName,
  rpc: CONFIG_HONEYWOOD_BLOCKCHAIN[stage].rpc,
  rest: CONFIG_HONEYWOOD_BLOCKCHAIN[stage].rest,
  bip44: {
    coinType: 118,
  },
  bech32Config: {
    bech32PrefixAccAddr: 'bears',
    bech32PrefixAccPub: 'bears' + 'pub',
    bech32PrefixValAddr: 'bears' + 'valoper',
    bech32PrefixValPub: 'bears' + 'valoperpub',
    bech32PrefixConsAddr: 'bears' + 'valcons',
    bech32PrefixConsPub: 'bears' + 'valconspub',
  },
  currencies: [
    {
      coinDenom: 'HONEY',
      coinMinimalDenom: 'uhoney',
      coinDecimals: 6,
      coinGeckoId: 'honey',
    },
    {
      coinDenom: 'CONE',
      coinMinimalDenom: 'cone',
      coinDecimals: 0,
      coinGeckoId: 'cone',
    },
  ],
  feeCurrencies: [
    {
      coinDenom: 'HONEY',
      coinMinimalDenom: 'uhoney',
      coinDecimals: 6,
      coinGeckoId: 'honey',
    },
  ],
  stakeCurrency: {
    coinDenom: 'CONE',
    coinMinimalDenom: 'cone',
    coinDecimals: 0,
    coinGeckoId: 'cone',
  },
  coinType: 118,
  gasPriceStep: {
    low: 0.05,
    average: 0.1,
    high: 0.15,
  },
};

const getAccountKeplr = async () => {
  const offlineSigner = window.getOfflineSigner(chainHoneyWoodConfig.chainId);
  const accounts = await offlineSigner.getAccounts();
  const account = accounts[0];
  return account;
};

export const connectKeplr = async () => {
  if (!window.getOfflineSigner || !window.keplr) {
    stores.setPropsForPopup({ textErrorKeplr: stores.translate('please_install_keplr_extension') });
    stores.setOpenPopup(ENUM_POPUP.KEPLER_ERROR);
  } else {
    // Keplr
    if (window.keplr.experimentalSuggestChain) {
      try {
        const resChain = await window.keplr.experimentalSuggestChain(chainHoneyWoodConfig);
        // console.log(resChain);
        try {
          const stateKeplr = await window.keplr.enable(chainHoneyWoodConfig.chainId);
          try {
            await stores.configAnonymousClient(RPC_URL);
            await stores.configAuthorizedKeplrClient(RPC_URL);
            const account = await getAccountKeplr();
            account.pubkey = account.pubKey; // very important
            // console.log('account >> ', account);
            const profile = {
              address: account.address,
              nickName: account.address,
              email: null,
              account,
              cones: 0,
              honey: 0,
            };
            setSecureStorage(ENCRYPT_PROFILE, profile);
            setSecureStorage(ENCRYPT_WALLET, { wallet: TYPE_AUTH_CLIENT.KEPLR });

            stores.setProfile(profile);
            stores.setProfileAvailable(profile);
            stores.setAuthorized(true);
            stores.setAccountAccess(true);
            stores.setTypeAuthClient(TYPE_AUTH_CLIENT.KEPLR);
            stores.setQueue(BEARS.createQueue());
            return true;
          } catch (err) {
            Logger.error(err);
            stores.setPropsForPopup({
              textErrorKeplr: stores.translate('error_config_client_keplr'),
            });
            stores.setOpenPopup(ENUM_POPUP.KEPLER_ERROR);
          }
         } catch (err) {
          stores.setPropsForPopup({
            textErrorKeplr: stores.translate('error_connect_keplr_wallet'),
          });
          stores.setOpenPopup(ENUM_POPUP.KEPLER_ERROR);
        }
      } catch (err) {
        stores.setPropsForPopup({
          textErrorKeplr: stores.translate('failed_to_suggest_the_chain'),
        });
        stores.setOpenPopup(ENUM_POPUP.KEPLER_ERROR);
      }
    } else {
      stores.setPropsForPopup({
        textErrorKeplr: stores.translate('please_use_recent_keplr_extension'),
      });
      stores.setOpenPopup(ENUM_POPUP.KEPLER_ERROR);
    }
  }
  return false;
};

export const getAccount = async () => {
  const account = await window.keplr.getKey(chainHoneyWoodConfig.chainId);
  return account;
};

export const accessAndInitKeplrClient = async () => {
  try {
    await window.keplr.enable(chainHoneyWoodConfig.chainId);
    const account = await getAccount();
    account.address = account.bech32Address;
    account.pubkey = account.pubKey;
    // console.log('account accessAndInitKeplrClient >> ', account);
    await stores.configAuthorizedKeplrClient(RPC_URL);
    stores.setAccountAccess(true);
    stores.setProfile({ ...stores.profile, account, address: account.address });
    stores.setProfileAvailable({ ...stores.profileAvailable, account, address: account.address });
    return true;
  } catch (err) {
    Logger.error(err);
    if (err.message && err.message === NO_CHAIN_ERR_MSG) {
      StoreInstance.removeAuthorized();
      Logger.infoNotify('removeAuthorized, accessAndInitKeplrClient', { type: stores.typeAuthClient }, true);
    }
    return false;
  }
};

export const checkExistedChain = async () => {
  try {
    await window.keplr.enable(chainHoneyWoodConfig.chainId);
    const account = await getAccount();
  } catch (err) {
    Logger.error(err);
    if (err.message && err.message === NO_CHAIN_ERR_MSG) {
      Logger.infoNotify('removeAuthorized, checkExistedChain', { type: stores.typeAuthClient }, true);
      StoreInstance.removeAuthorized();
    }
  }
};

export const updateInfoAfterChangeAccount = async () => {
  try {
    await window.keplr.enable(chainHoneyWoodConfig.chainId);
    const account = await getAccount();
    account.address = account.bech32Address;
    account.pubkey = account.pubKey;
    stores.setSignatureTransaction(null);
    stores.setProfile({ ...stores.profile, account, address: account.address  });
    stores.setProfileAvailable({ ...stores.profileAvailable, account, address: account.address });
    stores.setAccountAccess(true);
    return true;
  } catch (err) {
    Logger.error(err);
    if (err.message && err.message === NO_CHAIN_ERR_MSG) {
      Logger.infoNotify('removeAuthorized, updateInfoAfterChangeAccount', { type: stores.typeAuthClient }, true);
      StoreInstance.removeAuthorized();
    }
    return false;
  } finally {
    stores.setChangedAccountInWallet(true);
  }
}

export default {
  connectKeplr,
  getAccount,
  accessAndInitKeplrClient,
  checkExistedChain,
  updateInfoAfterChangeAccount,
};
