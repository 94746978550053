import React from 'react';
import classnames from 'classnames';
import Layout from '@layout/Layout';
import { USDT } from '@constants/blockchain';
import { useMediaQuery } from 'src/helpers/media';
import Logger from '@utils/logger';
import WithdrawIconMobile from '@assets/Mobile/control/withdraw_icon.png';
import styles from './mobileTransactions.module.scss';

const MobileTransactionsPage = ({ stores, states, loading, isInitialLoading = true }) => {
  const isLessThan1000px = useMediaQuery('(max-width: 1000px)');
  return (
    <Layout
      isMobile={isLessThan1000px}
      isLeadsBack
      isTransactionsPage
      isInitialLoading={isInitialLoading}
    >
      <div className={styles.TransactionsPage}>
        <div className={styles.WalletContainer}>
          {loading.isLoadingTransactions ? (
            <div className={styles.ProfileInfoIndicatorContainer}>
              <div className={styles.ProfileInfoProgressIndicator} />
            </div>
          ) : (
            states.paidPacks &&
            states.paidPacks.length > 0 && (
              <div className={styles.TransactionsWrapper}>
                <div className={styles.Transactions}>
                  <div className={styles.TransactionsTitleWrapper}>
                    <p className={styles.TransactionsTitleMain}>
                      {stores.translate('all_transactions')}
                    </p>
                  </div>
                  {states.paidPacks.map((paidPack) => {
                    return (
                      <div
                        className={styles.TransactionsItemContainer}
                        key={`transaction_item_${paidPack.updatedAt}`}
                      >
                        <div className={styles.TransactionsLeftBlock}>
                          <img
                            onLoad={() => stores.increaseDownloadedImageCounter(WithdrawIconMobile)}
                            onError={() => Logger.error('Error with image loading')}
                            src={WithdrawIconMobile}
                            alt="withdraw_icon"
                            className={styles.WithdrawIcon}
                          />
                          <div className={styles.TransactionsItemColumnContainer}>
                            <p className={styles.TransactionsTitle}>
                              {stores.translate('buy')}: {paidPack.selling_pack.TITLE}
                            </p>
                            <p className={styles.TransactionsPackStateTitle}>
                              {stores.translate('status')}:{' '}
                              {paidPack.selling_pack_status === 'TRANSFERED'
                                ? 'TRANSFERRED'
                                : paidPack.selling_pack_status}
                            </p>
                            {paidPack.selling_pack_status === 'ERROR' && (
                              <p className={styles.TransactionsPackErrorTitle}>
                                {paidPack.selling_pack_error}
                              </p>
                            )}
                            <p className={styles.TransactionsDateText}>{paidPack.updatedAt}</p>
                          </div>
                        </div>
                        <div
                          className={classnames(
                            styles.TransactionsItemColumnContainer,
                            styles.TransactionsItemRightAlign,
                          )}
                        >
                          <p className={styles.TransactionsTitle}>
                            -&nbsp;{paidPack.selling_pack.PRICE}&nbsp;
                            {USDT}
                          </p>
                          <p className={styles.TransactionsStatusText}>
                            {stores.translate('paid')}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </Layout>
  );
};

export default MobileTransactionsPage;
