import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import classnames from 'classnames';
import TitleSection from '@src/components/TitleSection';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import TextH6 from '@components_new/TextH6';
import { Button, ColorType, SizeButton } from 'src/components_new/Button';
import { GOOGLE_ANALYTICS } from '@src/constants/googleAnalyticsEvents';
import { gtagEvent, numberWithSeparator, floorRounding } from '@utils/common';
import URLS from '@constants/urls';
import WatchTheGameplay from '@assets/Mobile/HomePage/GameConceptSection/watch_the_gameplay.png';
import FreeToPlay from '@assets/Mobile/HomePage/GameConceptSection/free_to_play.jpg';
import HoneyCoinFarming from '@assets/Mobile/HomePage/GameConceptSection/honeyCoin_farming.jpg';
import ExcitingPvpBattles from '@assets/Mobile/HomePage/GameConceptSection/exciting_pvp_battles.jpg';
import UpgradeYourOwnBusiness from '@assets/Mobile/HomePage/GameConceptSection/upgrade_your_own_business.jpg';
import PlayVideoIcon from '@assets/common/play_video_icon.svg';
import GameIcon from '@assets/common/game_icon.svg';
import TotalPlayersIcon from '@assets/common/total_players_icon.svg';
import HowToPlayButtonImage from '@assets/Mobile/HomePage/GameConceptSection/how_to_play_button_image.svg';
import Logger from '@utils/logger';
import styles from './mobileGameConcept.module.scss';

const slides = [
  WatchTheGameplay,
  FreeToPlay,
  HoneyCoinFarming,
  ExcitingPvpBattles,
  UpgradeYourOwnBusiness,
];

const ParametersIndicator = ({
  indicatorIcon = GameIcon,
  title = '',
  firstParameter = '',
  secondParameter = '',
  isStaking = false,
  stakedCones,
  unstakingCones,
  onIndicatorClick = () => {},
  isClickable = false,
}) => {
  const stores = useStores();
  return (
    <div
      className={classnames(
        styles.IndicatorContainer,
        isClickable && styles.IndicatorContainer__Clickable,
      )}
      type="button"
      role="button"
      tabIndex="0"
      onClick={() => {
        onIndicatorClick();
      }}
      onKeyPress={() => {
        onIndicatorClick();
      }}
    >
      <img
        onLoad={() => stores.increaseDownloadedImageCounter(indicatorIcon)}
        onError={() => Logger.error('Error with image loading')}
        src={indicatorIcon}
        alt="indicator_icon"
        className={styles.IndicatorIcon}
      />
      <div className={styles.IndicatorTextInfo}>
        <p className={styles.IndicatorTitle}>{title}</p>
        <p className={styles.IndicatorParameters}>
          {firstParameter
            ? secondParameter
              ? `${numberWithSeparator(firstParameter)}/${numberWithSeparator(secondParameter)}`
              : `${numberWithSeparator(firstParameter)}`
            : '-'}
        </p>
        {isStaking && (
          <div className={styles.Staking}>
            <div className={styles.StakingTitleContainer}>
              <p className={styles.StakingTitleText}>In stake</p>
              <p className={styles.StakingTitleText}>Unstaking</p>
            </div>
            <div className={styles.StakingQuantityContainer}>
              <p className={styles.StakingQuantityText}>
                {stakedCones ? numberWithSeparator(stakedCones) : '-'}
              </p>
              <p className={styles.StakingQuantityText}>
                {unstakingCones ? numberWithSeparator(unstakingCones) : '-'}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const GameConceptSlide = ({ slideTitle = '', slideImage = {} }) => {
  const stores = useStores();
  const _ = stores.language;

  return (
    <div className={styles.GameConceptSlideContainer}>
      <TextH6 classNames={styles.SlideTitle} text={slideTitle} />
      {slideTitle === 'Watch the gameplay' ? (
        <a href={URLS.GAMEPLAY_YOUTUBE} target="_blank" rel="noreferrer">
          <div className={styles.PlayVideoIconWrapper}>
            <img
              onLoad={() => stores.increaseDownloadedImageCounter(PlayVideoIcon)}
              onError={() => Logger.error('Error with image loading')}
              src={PlayVideoIcon}
              alt="play_video_icon"
              className={styles.PlayVideoIcon}
            />
            <img
              onLoad={() => stores.increaseDownloadedImageCounter(slideImage)}
              onError={() => Logger.error('Error with image loading')}
              src={slideImage}
              alt={`${slideImage}_slide_image`}
              className={styles.SlideImage}
            />
          </div>
        </a>
      ) : (
        <img
          onLoad={() => stores.increaseDownloadedImageCounter(slideImage)}
          onError={() => Logger.error('Error with image loading')}
          src={slideImage}
          alt={`${slideImage}_slide_image`}
          className={styles.SlideImage}
        />
      )}
    </div>
  );
};

const GameConceptSection = observer(() => {
  const stores = useStores();
  const _ = stores.language;
  const mockupsTitles = stores.translate('game_concept_titles_mobile');

  const [totalPlayer, setTotalPlayer] = useState(null);
  const [totalGames, setTotalGames] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`https://honeywood.io/api/match3/stats`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        // eslint-disable-next-line camelcase
        const { pve, pvp, total_players } = response.data;
        setTotalGames(floorRounding(Number(pve) + Number(pvp), 0) || '-');
        // eslint-disable-next-line camelcase
        setTotalPlayer(`${floorRounding(Number(total_players) * 0.001, 2)}K` || '-');
      } catch (err) {
        Logger.error(err);
      }
    })();
  }, []);

  // eslint-disable-next-line no-shadow
  const getTitleMockup = (numberSlide) => {
    if (numberSlide > mockupsTitles?.length - 1) {
      return 'Free to Play';
    }
    return mockupsTitles[numberSlide].title;
  };

  return (
    <div className={styles.Container} id="game-concept">
      <div className={styles.TitleContainer}>
        <TitleSection title={stores.translate('game_concept_title')} isH2TitleTag />
      </div>

      <Swiper loop grabCursor centeredSlides slidesPerView="auto" className={styles.SwiperWrapper}>
        {slides.map((slide, index) => (
          <SwiperSlide className={styles.SwiperSlide} key={slide}>
            <GameConceptSlide slideTitle={getTitleMockup(index)} slideImage={slide} />
          </SwiperSlide>
        ))}
      </Swiper>

      <div className={styles.InfoContainer}>
        <div className={styles.HowToPlayButtonContainer}>
          <img
            onLoad={() => stores.increaseDownloadedImageCounter(HowToPlayButtonImage)}
            onError={() => Logger.error('Error with image loading')}
            src={HowToPlayButtonImage}
            alt="how_to_play_button_image"
            className={styles.HowToPlayButtonImage}
          />
          <Button
            colorType={ColorType.ORANGE}
            size={SizeButton.MEDIUM}
            title={stores.translate('how_to_play')}
            classNames={{
              Container: classnames(styles.ButtonContainer),
              Button: classnames(styles.HowToPlayButton),
              Title: classnames(
                styles.TitleButton,
                stores.browserInfo.isAndroid
                  ? styles.TitleOrangeButton__android
                  : styles.TitleOrangeButton,
              ),
            }}
            onClick={() => {
              gtagEvent(GOOGLE_ANALYTICS.CLICK_HOW_TO_PLAY);
              window.open(URLS.HOW_TO_PLAY_MEDIUM, '_blank', 'noopener,noreferrer');
            }}
          />
        </div>

        <div className={styles.ParametersIndicatorContainer}>
          <ParametersIndicator
            indicatorIcon={TotalPlayersIcon}
            title={stores.translate('total_players')}
            firstParameter={totalPlayer}
          />
          <ParametersIndicator
            indicatorIcon={GameIcon}
            title={stores.translate('total_games')}
            firstParameter={totalGames}
          />
        </div>
      </div>
    </div>
  );
});

export default GameConceptSection;
