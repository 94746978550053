import React from 'react';
import { observer } from 'mobx-react';

import Layout from 'src/layout/Layout';
import CapitalSection from '../MobileFaqPage/components/CapitalSection';
import FaqSection from '../MobileFaqPage/components/FaqSection';
import QuestionSection from '../MobileFaqPage/components/QuestionSection';

const DesktopTokenomicsPage = observer(({ isInitialLoading = true }) => {
  return (
    <Layout isInitialLoading={isInitialLoading} isLoadingFinish={!isInitialLoading}>
      <CapitalSection />
      <FaqSection />
      <QuestionSection />
    </Layout>
  );
});

export default DesktopTokenomicsPage;
