import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { BEAR_CREATED_STATE } from '@constants/system';
import { CHRISTMAS_DAY, END_DATE_CHRISTMAS, HALLOWEEN_DAY, START_DATE_CHRISTMAS } from '@constants/blockchain';
import { sanitize } from 'dompurify';
import TextH5 from '@components_new/TextH5';
import { gtagEvent } from '@utils/common';
import { GOOGLE_ANALYTICS } from '@src/constants/googleAnalyticsEvents';
import { useStores } from '@src/stores';
import { ENUM_POPUP } from '@components/PopUp/enumPopup';
import { ENUM_MODAL } from '@components_new/Modal/enumModal';
import TitleSection from '@components/TitleSection';
import BeeSpinner from '@components/BeeSpinner';
import EasyStartImage from '@assets/Mobile/HomePage/SpecialPresaleSection/easy_start_image.png';
import ForcedStartImage from '@assets/Mobile/HomePage/SpecialPresaleSection/forced_start_image.png';
import ProStartImage from '@assets/Mobile/HomePage/SpecialPresaleSection/pro_start_image.png';
import DoubleBeeImage from '@assets/Mobile/HomePage/SpecialPresaleSection/double_bee_image.png';
import HoneyFamilyImage from '@assets/Mobile/HomePage/SpecialPresaleSection/honey_family_image.png';
import SquadBeeImage from '@assets/Mobile/HomePage/SpecialPresaleSection/squad_bee_image.png';
import HalloweenImage from '@assets/Mobile/HomePage/SpecialPresaleSection/halloween_image.png';
import ChristmasImage from '@assets/Mobile/HomePage/SpecialPresaleSection/christmas_image.png';
import BabyStartImage from '@assets/Mobile/HomePage/SpecialPresaleSection/baby_start_image.png';
import LightBackground from '@assets/Mobile/HomePage/SpecialPresaleSection/light.png';
import LightDesktopBackground from '@assets/Mobile/HomePage/SpecialPresaleSection/light_desktop.png';
import DiagonalLineYellow from '@assets/SpecialPresaleSection/diagonal_line_yellow.png';
import NewPackIcon from '@assets/common/new_pack.png';
// winter -
// import LeftSnowIcon from '@assets/Christmas/left_snow.png';
// import RightSnowIcon from '@assets/Christmas/right_snow.png';

import CountDown from '@components/CountDown';

import Logger from '@utils/logger';
import { getPacksAndBlockchainObjects, savePacks } from '@utils/getPacksAndBlockchainObjects';
import styles from './mobileSpecialPresale.module.scss';

const PackItem = ({
  title = '',
  price = '',
  onBuyButtonClick = () => {},
  classNames = {},
  noActive = false,
  isDiscount = false,
  priceOld = '',
  isNewPack = false,
}) => {
  const stores = useStores();
  const getItemImage = (itemTitle) => {
    switch (itemTitle) {
      case 'Baby Start':
         return BabyStartImage;
      case 'Easy Start':
        return EasyStartImage;
      case 'Forced Start':
        return ForcedStartImage;
      case 'Pro Start':
        return ProStartImage;
      case 'Double Bee':
        return DoubleBeeImage;
      case 'Honey Family':
        return HoneyFamilyImage;
      case 'Squad Bee':
        return SquadBeeImage;
      case 'Halloween 22':
        return HalloweenImage;
      case 'Christmas 2023':
        return ChristmasImage;
      default:
        return EasyStartImage;
    }
  };
  return (
    <div
      className={classnames(
        styles.ItemContainer,
        noActive && styles.ItemContainer__noActive,
        !noActive && styles.ItemContainer__enabled,
        classNames.ItemContainer,
      )}
      onClick={!noActive ? onBuyButtonClick : () => {}}
      onKeyDown={!noActive ? onBuyButtonClick : () => {}}
      role="button"
      tabIndex={0}
    >
      <img
        onLoad={() => stores.increaseDownloadedImageCounter(getItemImage(title))}
        onError={() => Logger.error('Error with image loading')}
        src={getItemImage(title)}
        alt="watch_the_gameplay"
        className={classnames(styles.ItemImage, classNames.ItemImage)}
      />
      <div className={classnames(styles.ItemInfoContainer, classNames.ItemInfoContainer)}>
        <div className={classnames(styles.ItemTitleContainer, classNames.ItemTitleContainer)}>
          <TextH5
            classNames={classnames(
              styles.ItemTitle,
              isDiscount && styles.ItemTitle__isDiscount,
              classNames.ItemTitle,
            )}
            text={title}
          />
        </div>
        <div
          className={classnames(
            styles.ItemPriceContainer,
            isDiscount && styles.ItemPriceContainer__isDiscount,
            classNames.ItemPriceContainer,
          )}
        >
          {isDiscount && (
            <div className={styles.ItemDiscountContainer}>
              <p className={classnames(styles.TitlePriceOld, classNames.TitlePriceOld)}>
                &nbsp;{priceOld}&nbsp;USDT
              </p>
              <img
                src={DiagonalLineYellow}
                alt="diagonal_line_yellow"
                className={styles.TitlePriceOldLine}
              />
            </div>
          )}
          <p
            className={classnames(
              styles.ItemPrice,
              isDiscount && styles.ItemPrice__isDiscount,
              classNames.ItemPrice,
            )}
            dangerouslySetInnerHTML={{
              __html: sanitize(price ? `${sanitize(price)} USDT` : 'Free bonus'),
            }}
          />
        </div>
      </div>
      {isNewPack && <img src={NewPackIcon} className={styles.NewPackIcon} alt='new_pack' />}
    </div>
  );
};

const EventPacks = ({ stores, showPayments, packsEvent = [] }) => {
  return (
    <div className={styles.EventContainer}>
      <div className={styles.TitleSectionContainer}>
        <TitleSection
          title={stores.translate('new_year_presale_offer')}
          classNames={{ Title: styles.PacksTitleChristmas }}
        />
        <div className={styles.PacksBlockContainer}>
          <div className={styles.PacksBlockWrapper}>
            {packsEvent.map((presalePack) => {
              const sumPrice = presalePack.ITEMS.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.PRICE;
              }, 0);
              return (
                <div className={styles.PackBlock} key={presalePack.TITLE}>
                  <img src={LightDesktopBackground} className={styles.LightBackgroundEventPack} alt='blur' />
                  <PackItem
                    title={presalePack.TITLE}
                    price={presalePack.PRICE}
                    onBuyButtonClick={() => {
                      gtagEvent(GOOGLE_ANALYTICS.CLICK_BUY, presalePack.TITLE);
                      showPayments({
                        packInfo: presalePack,
                      });
                    }}
                    classNames={{
                      ItemTitleContainer: styles.ItemTitleContainerChristmas,
                      ItemTitle: styles.ItemTitleChristmas,
                      ItemPriceContainer: styles.ItemPriceContainer__isChristmas,
                      ItemPrice: styles.ItemPrice__isChristmas,
                      TitlePriceOld: styles.TitlePriceOld__Christmas,
                    }}
                    noActive={false}
                    isDiscount
                    priceOld={sumPrice}
                    isNewPack
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const SpecialPresaleSection = observer(({ classNames = {}, finishRequestPacks = ()=>{} }) => {
  const stores = useStores();
  const _ = stores.language;
  const [presalePacksInit, setPresalePacksInit] = useState([]);
  const [presalePacksSecond, setPresalePacksSecond] = useState([]);
  const [presalePacksHoney, setPresalePacksHoney] = useState([]);
  const [packsEvent, setPacksEvent] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [updatedStateSellingPack, setUpdatedStateSellingPack] = useState(false);
  const [isAccessHalloween, setAccessHalloween] = useState(false);

  const showPayments = async (packInfo = {}) => {
    // console.log('packInfo tapped >> ', packInfo)
    stores.setStateDisplayedPaymentModal(packInfo);
    if (!stores.isAuthorized) {
      stores.setOpenModal(ENUM_MODAL.JOIN);
    } else if (!stores.isAccountAccess) {
      stores.setOpenModal(ENUM_MODAL.VERIFICATION_ACCESS_WALLET);
    } else if (
      !packInfo.packInfo.INIT_SET &&
      stores.bearCreated === BEAR_CREATED_STATE.NO_CREATED
    ) {
      // console.log('!packInfo.INIT_SET >>> ', packInfo.INIT_SET)
      stores.setStateDisplayedPaymentModal(null);
      stores.setOpenPopup(ENUM_POPUP.SELLING_PACK_NO_PURCHASED_INIT);
    } else {
      // console.log('showPayments >>> ')
      /* Dynamically lock scrolling background */
      document.body.style.overflowY = 'hidden';

      // if (packInfo && packInfo.packInfo.ID.includes('HONEYCOIN_PACK')) {
      //   stores.showPaymentsHoneyUnity(true);
      // } else {
      //   stores.showPayments(true);
      // }
      stores.showPayments(true);
    }
  };

  useEffect(async () => {
    if (
      stores.anonymousClient &&
      stores.anonymousClient.bearsQueries &&
      !stores.anonymousClient.bearsQueries.rpc
    )
      return () => {};
    const controllerObject = { controller: new AbortController() };
    getPacksAndBlockchainObjects({
      controllerObject,
      beforeHandler: () => {
        if (!isLoading) {
          setLoading(true);
        }
      },
      afterHandler: (result) => {
        const packs = savePacks({ result, event: CHRISTMAS_DAY });
        console.log('packs >>> ', packs);
        setPresalePacksInit(packs.packArrayFirstPurchased);
        setPresalePacksSecond(packs.packArraySecondPurchased);
        setPresalePacksHoney(packs.packArrayHoney);
        // Halloween Event
        setPacksEvent(packs.packArrayEvent);
        const localDate = new Date().valueOf();
        setAccessHalloween(localDate >= START_DATE_CHRISTMAS && localDate <= END_DATE_CHRISTMAS);
      },
      finallyHandler: () => {
        setLoading(false);
        finishRequestPacks(true);
      },
    });
    return () => controllerObject.controller?.abort();
  }, [stores.anonymousClient]);

  useEffect(() => {
    if (stores.bearCreated !== BEAR_CREATED_STATE.UNAUTHORIZED) {
      setUpdatedStateSellingPack(true);
      // console.log(' setUpdatedStateSellingPack >>>>>>')
    } else {
      setUpdatedStateSellingPack(false);
    }
  }, [stores.bearCreated]);

  const getActiveSellingPack = (packInfo = {}, updatedAllPacks) => {
    // console.log(' getActiveSellingPack >>> ', updatedAllPacks, ' ...> ', packInfo)
    if (!updatedAllPacks) {
      return true;
    }
    if (packInfo && packInfo.INIT_SET && stores.bearCreated === BEAR_CREATED_STATE.NO_CREATED) {
      return true;
    }
    if (packInfo && packInfo.INIT_SET === false) {
      return true;
    }
    return false;
  };

  return (
    <div className={styles.SpecialPresaleSectionContainer}>
      {/* <EventPacks stores={stores} showPayments={showPayments} packsEvent={packsEvent} /> */}
      <div
        className={classnames(styles.Container, classNames.Container)}
        id="selling-pack"
        name="selling-pack"
      >
        <div className={styles.TitleSectionContainer}>
          <TitleSection title={stores.translate('special_presale_offer')} />
        </div>
        {isLoading ? (
          <BeeSpinner classNames={{ Spinner: styles.LoadingContainer }} />
        ) : (
          <>
            {/* {isAccessHalloween && packsEvent && packsEvent.length > 0 && (
              <div className={styles.PacksTitleContainer}>
                <TextH5
                  classNames={styles.PacksTitleHalloween}
                  text={stores.translate('halloween')}
                />
                <CountDown deadline="06 November 2022 21:00 UTC" isAvailable />
                <div className={styles.PacksBlockContainer}>
                  <div className={styles.PacksBlockWrapper}>
                    {packsEvent.map((presalePack) => {
                      const sumPrice = presalePack.ITEMS.reduce((accumulator, currentValue) => {
                        return accumulator + currentValue.PRICE;
                      }, 0);
                      return (
                        <div className={styles.PackBlock} key={presalePack.TITLE}>
                          <PackItem
                            title={presalePack.TITLE}
                            price={presalePack.PRICE}
                            onBuyButtonClick={() => {
                              gtagEvent(GOOGLE_ANALYTICS.CLICK_BUY, presalePack.TITLE);
                              showPayments({
                                packInfo: presalePack,
                              });
                            }}
                            classNames={{
                              ItemTitleContainer: styles.ItemTitleContainerHalloween,
                              ItemTitle: styles.ItemTitleHalloween,
                            }}
                            noActive={false}
                            isDiscount
                            priceOld={sumPrice}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )} */}
            <div className={styles.PacksBlockContainer}>
              <TextH5 classNames={styles.PacksTitle} text={stores.translate('starter_packs')} />
              <div className={styles.PacksBlockWrapper}>
                {presalePacksInit.length &&
                  presalePacksInit.map((presalePack) => {
                    const sumPrice = presalePack.ITEMS.reduce((accumulator, currentValue) => {
                        return accumulator + currentValue.PRICE;
                      }, 0);
                    return (
                      <div className={styles.PackBlock} key={presalePack.TITLE}>
                        <PackItem
                          title={presalePack.TITLE}
                          price={presalePack.PRICE}
                          onBuyButtonClick={() => {
                            gtagEvent(GOOGLE_ANALYTICS.CLICK_BUY, presalePack.TITLE);
                            showPayments({
                              packInfo: presalePack,
                            });
                          }}
                          noActive={!getActiveSellingPack(presalePack, updatedStateSellingPack)}
                          // isNewPack={presalePack.ID === 'BABY_START'}
                          // isDiscount={presalePack.ID !== 'BABY_START'}
                          priceOld={sumPrice}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className={styles.PacksTitleContainer}>
              <TextH5 classNames={styles.PacksTitle} text={stores.translate('bees_packs')} />
              <div className={styles.PacksBlockContainer}>
                <div className={styles.PacksBlockWrapper}>
                  {presalePacksSecond.length &&
                    presalePacksSecond.map((presalePack) => {
                      const sumPrice = presalePack.ITEMS.reduce((accumulator, currentValue) => {
                        return accumulator + currentValue.PRICE;
                      }, 0);
                      return (
                        <div className={styles.PackBlock} key={presalePack.TITLE}>
                          <PackItem
                            title={presalePack.TITLE}
                            price={presalePack.PRICE}
                            onBuyButtonClick={() => {
                              gtagEvent(GOOGLE_ANALYTICS.CLICK_BUY, presalePack.TITLE);
                              showPayments({
                                packInfo: presalePack,
                              });
                            }}
                            noActive={!getActiveSellingPack(presalePack, updatedStateSellingPack)}
                            // isDiscount
                            priceOld={sumPrice}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            {/* //! Honey packs */}
            {/* <div className={styles.PacksTitleContainer}>
              <TextH5 classNames={styles.PacksTitle} text='Honey packs' />
              <div className={styles.PacksBlockContainer}>
                <div className={styles.PacksBlockWrapper}>
                  {presalePacksHoney.length &&
                    presalePacksHoney.map((presalePack) => {
                      const sumPrice = presalePack.ITEMS.reduce((accumulator, currentValue) => {
                        return accumulator + currentValue.PRICE;
                      }, 0);
                      return (
                        <div className={styles.PackBlock} key={presalePack.TITLE}>
                          <PackItem
                            title={presalePack.TITLE}
                            price={presalePack.PRICE}
                            onBuyButtonClick={() => {
                              gtagEvent(GOOGLE_ANALYTICS.CLICK_BUY, presalePack.TITLE);
                              showPayments({
                                packInfo: presalePack,
                              });
                            }}
                            // noActive={!getActiveSellingPack(presalePack, updatedStateSellingPack)}
                            isDiscount
                            priceOld={sumPrice}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div> */}
          </>
        )}
        {/* winter */}
        {/* <img src={LeftSnowIcon} alt='left_snow' className={styles.LeftSnowIcon} /> */}
        {/* <img src={RightSnowIcon} alt='right_snow' className={styles.RightSnowIcon} /> */}
        {/* //! Temporary */}
        {/* <Button
        colorType={ColorType.ORANGE}
        size={SizeButton.SMALL}
        title={stores.translate('yield_calculator')}
        classNames={{
          Container: classnames(styles.ButtonContainer),
          Title: classnames(
            styles.TitleButton,
            stores.browserInfo.isAndroid
              ? styles.TitleOrangeButton__android
              : styles.TitleOrangeButton,
          ),
        }}
        onClick={() => {
          gtagEvent(GOOGLE_ANALYTICS.CLICK_YIELD_CALCULATOR);
          // window.open(URLS.YIELD_CALCULATOR, '_blank', 'noopener,noreferrer');
        }}
      /> */}
      </div>
    </div>
  );
});

export default SpecialPresaleSection;
