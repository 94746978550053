import React, { useState, forwardRef, useRef, useEffect } from 'react';
import { cn } from '@bem-react/classname';
import classnames from 'classnames';
import { useStores } from 'src/stores';
import Logger from '@utils/logger';
import ShowButtonDefaultIcon from '@assets/common/show_button_default_icon.svg';
import ShowButtonPressedIcon from '@assets/common/show_button_pressed_icon.svg';
import styles from './styles.scss';

const inputCN = cn('Input');

function useCombinedRefs(...refs) {
  const targetRef = useRef();

  useEffect(() => {
    refs.forEach((refCounter, index) => {
      if (!refCounter) return;

      if (typeof refCounter === 'function') {
        // console.log('index ref of function>> ', index);
        refCounter(targetRef.current);
      } else {
        // console.log('index ref >> ', index);
        refCounter.current = targetRef.current;
      }
      // console.log(targetRef);
    });
  }, [refs]);

  return targetRef;
}

const Input = (
  {
    register = () => {},
    name,
    value,
    placeholder = '',
    type = 'text',
    label = '',
    error = '',
    canError = false,
    isButtonSecretShow = false,
    readOnly = false,
    isFewInputsRow = false,
    classNames = {},
    onChange = () => {},
    isFocus = false,
    isTextArea = false,
    ...props
  },
  ref,
) => {
  const stores = useStores();
  const [isSecretText, setSecretText] = useState(isButtonSecretShow);
  let inputRef;
  let registerInput;

  const onShowSecretText = () => {
    setSecretText(!isSecretText);
  };

  const innerRef = useRef(null);

  if (register && name && register(name)) {
    ({ ref: inputRef, ...registerInput } = register(name));
  }
  const combinedRef = useCombinedRefs(ref, inputRef, innerRef);

  const executeScroll = () => {
    // console.log(inputRef);
    // console.log(registerInput);
    // console.log(ref);
    // console.log(combinedRef);
    combinedRef.current.scrollIntoView();
  };

  return (
    <div
      className={classnames(
        inputCN('Container', { canError, isFewInputsRow }),
        classNames.Container,
      )}
    >
      {!!label && <p className={classnames(inputCN('Label'), classNames.Label)}>{label}</p>}
      <div
        className={classnames(
          inputCN('InputContainer', { canError, readOnly }),
          classNames.InputContainer,
        )}
      >
        {isTextArea ? (
          <textarea
            className={classnames(inputCN('Input', { isButtonSecretShow }), classNames.Input)}
            placeholder={placeholder}
            type={isSecretText ? 'password' : type}
            value={value}
            readOnly={readOnly}
            onChange={onChange}
            onFocus={(e) => {
              if (isFocus) {
                // console.log('onFocus >> ');
                executeScroll();
              }
            }}
            {...registerInput}
            {...(!ref && register(name))}
            ref={combinedRef}
            {...props}
          />
        ) : (
          <input
            className={classnames(inputCN('Input', { isButtonSecretShow }), classNames.Input)}
            placeholder={placeholder}
            type={isSecretText ? 'password' : type}
            value={value}
            readOnly={readOnly}
            onChange={onChange}
            onFocus={(e) => {
              if (isFocus) {
                // console.log('onFocus >> ');
                executeScroll();
              }
            }}
            {...registerInput}
            {...(!ref && register(name))}
            ref={combinedRef}
            {...props}
          />
        )}
        {isButtonSecretShow && (
          <button
            type="button"
            className={classnames(inputCN('InputButtonShow'), classNames.InputButtonShow)}
            onClick={onShowSecretText}
          >
            {isSecretText ? (
              <img
                onLoad={() => stores.increaseDownloadedImageCounter(ShowButtonDefaultIcon)}
                onError={() => Logger.error('Error with image loading')}
                src={ShowButtonDefaultIcon}
                alt="show_button_default_icon"
                className={styles.ShowButtonDefaultIcon}
              />
            ) : (
              <img
                onLoad={() => stores.increaseDownloadedImageCounter(ShowButtonPressedIcon)}
                onError={() => Logger.error('Error with image loading')}
                src={ShowButtonPressedIcon}
                alt="show_button_pressed_icon"
                className={styles.ShowButtonDefaultIcon}
              />
            )}
          </button>
        )}
      </div>
      {!!error && (
        <p className={classnames(inputCN('Error'), classNames.Error)}>{error?.message}</p>
      )}
    </div>
  );
};

export default forwardRef(Input);
