import _ from 'lodash';
import { BEARS } from 'src/api';
import { logs } from '@cosmjs/stargate';
import { BEE_TYPE, DECORATION_TYPE, TREE_TYPE, APIARY_TYPE, ITEM_TYPES, FIELD_TYPE, TAX } from '@constants/blockchain';
import common, { findAttributes, stringify, toFixedNumberWithoutRound } from '@utils/common';
import { getPacksAndBlockchainObjects, savePacks } from '@utils/getPacksAndBlockchainObjects';
import Logger from '@utils/logger';
import { GameMethodNames, GameObjectNames } from 'src/stores/unity';
import { StoreInstance } from 'src/stores';

import DynamicStatesMediator from './DynamicStatesMediator';
import { handleMainError } from '../HandleError';
import StackingMediator from './StackingMediator';

const stores = StoreInstance;
/* eslint-disable max-len */

// *********************** //
// *** private methods *** //

const enoughHoneyForTax = () => {
  if (stores.profile && stores.profile.honey < TAX) {
    handleMainError({ code: 110 });
    return false;
  }
  return true;
};

const initializeBlockchainGame = async ({ type, kind = '', rowId = -1, columnId = -1 }) => {
  switch (type) {
    case TREE_TYPE: {
      const responseTree = await BEARS.msgInitGameAndCreateTree({ treeType: kind });
      Logger.infoMainWithKey('responseCreateTree', responseTree);
      if (responseTree.code > 0) {
        // error from blockchain
        handleMainError(responseTree);
        stores.unityStore.send({
          objectName: GameObjectNames.FarmReact,
          methodName: GameMethodNames.ExceptionReplace,
          values: stringify({ code: responseTree.code, id: 0, rowId, columnId }),
        });
      } else {
        const parsedRawLog = logs.parseRawLog(responseTree.rawLog);

        const attributeBear = logs.findAttribute(parsedRawLog, 'message', 'bear_id');
        const attributeField = logs.findAttribute(parsedRawLog, 'message', 'field_id');
        stores.unityStore.setBearId(Number(attributeBear.value));
        stores.unityStore.setFieldId(Number(attributeField.value));

        const coinReceivedTree = findAttributes(parsedRawLog, 'coin_received', 'amount', 'cone');
        if (coinReceivedTree) {
          await StackingMediator.stakeCones({ cones: Number(coinReceivedTree), isUpdatedStatesStacking: false });
        }

        const attributeTree = logs.findAttribute(parsedRawLog, 'message', 'tree_id');
        stores.unityStore.send({
          objectName: GameObjectNames.FarmReact,
          methodName: GameMethodNames.SuccessfullyReplace,
          values: stringify({ id: Number(attributeTree.value), rowId, columnId }),
        });
      }
      break;
    }
    case DECORATION_TYPE: {
      const responseDecor = await BEARS.msgInitGameAndCreateDecoration({ decorationType: kind });
      Logger.infoMainWithKey('responseCreateDecoration', responseDecor);
      if (responseDecor.code > 0) {
        // error from blockchain
        handleMainError(responseDecor);
        stores.unityStore.send({
          objectName: GameObjectNames.FarmReact,
          methodName: GameMethodNames.ExceptionReplace,
          values: stringify({ code: responseDecor.code, id: 0, rowId, columnId }),
        });
      } else {
        const parsedRawLog = logs.parseRawLog(responseDecor.rawLog);
        Logger.infoMainWithKey('parsedRawLog', parsedRawLog);
        const attributeBear = logs.findAttribute(parsedRawLog, 'message', 'bear_id');
        const attributeField = logs.findAttribute(parsedRawLog, 'message', 'field_id');
        stores.unityStore.setBearId(Number(attributeBear.value));
        stores.unityStore.setFieldId(Number(attributeField.value));

        const attributeDecor = logs.findAttribute(parsedRawLog, 'message', 'decoration_id');

        const responseSetDecor = await BEARS.msgSetDecorationPosition({ decorationId: attributeDecor.value, fieldId: attributeField.value, rowId, columnId });
        if (responseSetDecor.code > 0) {
          // error from blockchain
          handleMainError(responseSetDecor);
          stores.unityStore.send({
            objectName: GameObjectNames.FarmReact,
            methodName: GameMethodNames.ExceptionReplace,
            values: stringify({ code: responseSetDecor.code, id: Number(attributeDecor.value), rowId, columnId }),
          });
        } else {
          stores.unityStore.send({
            objectName: GameObjectNames.FarmReact,
            methodName: GameMethodNames.SuccessfullyReplace,
            values: stringify({ id: Number(attributeDecor.value), rowId, columnId }),
          });
        }
      }
      break;
    }
    case APIARY_TYPE: {
      const responseApiary = await BEARS.msgInitGameAndCreateApiary({ apiaryType: kind });
      Logger.infoMainWithKey('responseCreateApiary', responseApiary);
      if (responseApiary.code > 0) {
        handleMainError(responseApiary);
        stores.unityStore.send({
          objectName: GameObjectNames.FarmReact,
          methodName: GameMethodNames.ExceptionReplace,
          values: stringify({ code: responseApiary.code, id: 0, rowId, columnId }),
        });
      } else {
        const parsedRawLog = logs.parseRawLog(responseApiary.rawLog);

        Logger.infoMainWithKey('parsedRawLog responseApiary', parsedRawLog);

        const attributeBear = logs.findAttribute(parsedRawLog, 'message', 'bear_id');
        const attributeField = logs.findAttribute(parsedRawLog, 'message', 'field_id');
        stores.unityStore.setBearId(Number(attributeBear.value));
        stores.unityStore.setFieldId(Number(attributeField.value));

        const attributeApiary = logs.findAttribute(parsedRawLog, 'message', 'apiary_id');
        stores.unityStore.send({
          objectName: GameObjectNames.FarmReact,
          methodName: GameMethodNames.SuccessfullyReplace,
          values: stringify({ id: Number(attributeApiary.value), rowId, columnId }),
        });
      }
      break;
    }
    case BEE_TYPE: {
      const responseBee = await BEARS.msgInitGameAndCreateBee({
        beeType: kind,
        beeName: kind.replace('_', ''),
      });
      Logger.infoMainWithKey('msgInitGameAndCreateBee BuyBee responseBee', responseBee);
      if (responseBee.code > 0) {
        // error from blockchain
        handleMainError(responseBee);
        stores.unityStore.send({
          objectName: GameObjectNames.FarmReact,
          methodName: GameMethodNames.ExceptionCreateBee,
          values: stringify({ code: responseBee.code }),
        });
      } else {
        const parsedRawLog = logs.parseRawLog(responseBee.rawLog);

        const attributeBear = logs.findAttribute(parsedRawLog, 'message', 'bear_id');
        const attributeField = logs.findAttribute(parsedRawLog, 'message', 'field_id');
        stores.unityStore.setBearId(Number(attributeBear.value));
        stores.unityStore.setFieldId(Number(attributeField.value));

        const attributeBee = logs.findAttribute(parsedRawLog, 'message', 'bee_id');
        stores.unityStore.send({
          objectName: GameObjectNames.FarmReact,
          methodName: GameMethodNames.InitialWarehouse,
          values: stringify({ id: Number(attributeBee.value), type: BEE_TYPE, kind }),
        });
      }
      break;
    }
    case FIELD_TYPE: {
      const responseExtend = await BEARS.msgInitGameAndExtendField();
      Logger.infoMainWithKey('msgInitGameAndExtendField ExpandLand response', responseExtend);
      if (responseExtend.code > 0) {
        // error from blockchain
        handleMainError(responseExtend);
        stores.unityStore.send({
          objectName: GameObjectNames.FarmReact,
          methodName: GameMethodNames.ExceptionExpandField,
          values: stringify({ code: responseExtend.code }),
        });
      } else {
        const parsedRawLog = logs.parseRawLog(responseExtend.rawLog);

        const attributeBear = logs.findAttribute(parsedRawLog, 'message', 'bear_id');
        const attributeFieldID = logs.findAttribute(parsedRawLog, 'message', 'field_id');
        stores.unityStore.setBearId(Number(attributeBear.value));
        stores.unityStore.setFieldId(Number(attributeFieldID.value));

        const attributeField = logs.findAttribute(parsedRawLog, 'message', 'count_tiles');
        const lengthGrounds = Math.sqrt(Number(attributeField.value));
        stores.unityStore.send({
          objectName: GameObjectNames.FarmReact,
          methodName: GameMethodNames.InitializeGrounds,
          values: stringify({ length: lengthGrounds }),
        });
      }
      break;
    }
    default:
      handleMainError({ code: 101 });
      break;
  }
};

// ********************** //
// *** public methods *** //

const buyAndSetObject = async ({ type, kind, rowId, columnId }) => {
  const { bearId, fieldId } = stores.unityStore;
  if (bearId === -1) {
    await initializeBlockchainGame({ type, kind, rowId, columnId });
    return;
  }

  switch (type) {
    case TREE_TYPE: {
      const responseTree = await BEARS.msgCreateTree({ bearId, fieldId, rowId, columnId, treeType: kind });
      Logger.infoMainWithKey('responseCreateTree', responseTree);
      if (responseTree.code > 0) {
        // error from blockchain
        handleMainError({ code: responseTree.code });
        stores.unityStore.send({
          objectName: GameObjectNames.FarmReact,
          methodName: GameMethodNames.ExceptionReplace,
          values: stringify({ code: responseTree.code, id: 0, rowId, columnId }),
        });
      } else {
        const parsedRawLog = logs.parseRawLog(responseTree.rawLog);
        // console.log('parsedRawLog >> ', parsedRawLog);
        const attributeTree = logs.findAttribute(parsedRawLog, 'message', 'tree_id');

        // const coinReceivedTree = findAttributes(parsedRawLog, 'coin_received', 'amount', 'cone');
        // // console.log('...... cones >>> ', coinReceivedTree)
        // if (coinReceivedTree) {
        //   await StackingMediator.stakeCones({ cones: Number(coinReceivedTree), isUpdatedStatesStacking: false });
        // }
        stores.unityStore.send({
          objectName: GameObjectNames.FarmReact,
          methodName: GameMethodNames.SuccessfullyReplace,
          values: stringify({ id: Number(attributeTree.value), rowId, columnId }),
        });
      }
      break;
    }
    case DECORATION_TYPE: {
      const responseDecor = await BEARS.msgCreateDecoration({ bearId, decorationType: kind });
      Logger.infoMainWithKey('responseCreateDecoration', responseDecor);
      if (responseDecor.code > 0) {
        // error from blockchain
        handleMainError({ code: responseDecor.code });
      } else {
        const parsedRawLog = logs.parseRawLog(responseDecor.rawLog);
        const attributeDecor = logs.findAttribute(parsedRawLog, 'message', 'decoration_id');

        const responseSetDecor = await BEARS.msgSetDecorationPosition({ decorationId: Number(attributeDecor.value), fieldId, rowId, columnId });
        if (responseSetDecor.code > 0) {
          // error from blockchain
          handleMainError({ code: responseSetDecor.code });
          stores.unityStore.send({
            objectName: GameObjectNames.FarmReact,
            methodName: GameMethodNames.ExceptionReplace,
            values: stringify({ code: responseSetDecor.code, id: Number(attributeDecor.value), rowId, columnId }),
          });
        } else {
          stores.unityStore.send({
            objectName: GameObjectNames.FarmReact,
            methodName: GameMethodNames.SuccessfullyReplace,
            values: stringify({ id: Number(attributeDecor.value), rowId, columnId }),
          });
        }
      }
      break;
    }
    case APIARY_TYPE: {
      const responseApiary = await BEARS.msgCreateApiary({ bearId, fieldId, rowId, columnId, apiaryType: kind });
      Logger.infoMainWithKey('responseCreateApiary', responseApiary);
      if (responseApiary.code > 0) {
        handleMainError({ code: responseApiary.code });
        stores.unityStore.send({
          objectName: GameObjectNames.FarmReact,
          methodName: GameMethodNames.ExceptionReplace,
          values: stringify({ code: responseApiary.code, id: 0, rowId, columnId }),
        });
      } else {
        const parsedRawLog = logs.parseRawLog(responseApiary.rawLog);
        const attributeApiary = logs.findAttribute(parsedRawLog, 'message', 'apiary_id');
        stores.unityStore.send({
          objectName: GameObjectNames.FarmReact,
          methodName: GameMethodNames.SuccessfullyReplace,
          values: stringify({ id: Number(attributeApiary.value), rowId, columnId }),
        });
      }
      break;
    }
    default:
      handleMainError({ code: 101 });
      break;
  }
};

const setBeeInApiary = async ({ apiaryId, beeId }) => {
  if (!enoughHoneyForTax()) {
    return;
  }
  const response = await BEARS.msgSetApiaryHouseForBee({ beeId, apiaryId });
  Logger.infoMainWithKey('msgSetApiaryHouseForBee SetBeeInApiary response', response);
  if (response.code > 0) {
    // error from blockchain
    handleMainError({ code: response.code });
    stores.unityStore.send({
      objectName: GameObjectNames.FarmReact,
      methodName: GameMethodNames.ExceptionSetBeeApiary,
      values: stringify({ code: response.code, apiaryId, beeId }),
    });
  } else {
    stores.unityStore.send({
      objectName: GameObjectNames.FarmReact,
      methodName: GameMethodNames.SuccessfullySetBeeApiary,
      values: stringify({ apiaryId, beeId }),
    });
  }
};

const createAndSetBeeInApiary = async ({ apiaryId, kind }) => {
  const { bearId } = stores.unityStore;
  const responseBee = await BEARS.msgCreateBee({
    bearId,
    beeType: kind,
    beeName: kind.replace('_', ''),
  });
  if (responseBee.code > 0) {
    handleMainError({ code: responseBee.code });
    stores.unityStore.send({
      objectName: GameObjectNames.FarmReact,
      methodName: GameMethodNames.ExceptionSetBeeApiary,
      values: stringify({ code: responseBee.code, apiaryId, beeId: -1 }),
    });
  } else {
    const parsedRawLog = logs.parseRawLog(responseBee.rawLog);
    Logger.infoMainWithKey('parseRawLog for CreateAndSetBeeInApiary', parsedRawLog);
    const attributeBee = logs.findAttribute(parsedRawLog, 'message', 'bee_id');
    Logger.infoMainWithKey('parsedLog log beeId', attributeBee);
    const beeId = Number(attributeBee.value);
    const responseHouseBee = await BEARS.msgSetApiaryHouseForBee({ beeId, apiaryId });
    if (responseHouseBee.code > 0) {
      handleMainError({ code: responseHouseBee.code });
      stores.unityStore.send({
        objectName: GameObjectNames.FarmReact,
        methodName: GameMethodNames.ExceptionSetBeeApiary,
        values: stringify({ code: responseHouseBee.code, apiaryId, beeId }),
      });
      return;
    }
    stores.unityStore.send({
      objectName: GameObjectNames.FarmReact,
      methodName: GameMethodNames.SuccessfullySetBeeApiary,
      values: stringify({ apiaryId, beeId }),
    });
  }
};

const buyBee = async ({ kind }) => {
  const { bearId } = stores.unityStore;
  if (bearId === -1) {
    // INIT Game, bear not exist
    await initializeBlockchainGame({ type: BEE_TYPE, kind });
  } else {
    const responseBee = await BEARS.msgCreateBee({
      bearId,
      beeType: kind,
      beeName: kind.replace('_', ''),
    });
    if (responseBee.code > 0) {
      handleMainError({ code: responseBee.code });
      stores.unityStore.send({
        objectName: GameObjectNames.FarmReact,
        methodName: GameMethodNames.ExceptionCreateBee,
        values: stringify({ code: responseBee.code }),
      });
    } else {
      const parsedRawLog = logs.parseRawLog(responseBee.rawLog);
      const attributeBee = logs.findAttribute(parsedRawLog, 'message', 'bee_id');
      stores.unityStore.send({
        objectName: GameObjectNames.FarmReact,
        methodName: GameMethodNames.InitialWarehouse,
        values: stringify({ id: Number(attributeBee.value), type: BEE_TYPE, kind }),
      });
    }
  }
};

const removeBeeFromApiary = async ({ apiaryId, beeId }) => {
  if (!enoughHoneyForTax()) {
    return;
  }
  const response = await BEARS.msgUnsetApiaryHouseForBee({ beeId });
  Logger.infoMainWithKey('RemoveBeeFromApiary msgUnsetApiaryHouseForBee response', response);
  if (response.code > 0) {
    // error from blockchain
    handleMainError({ code: response.code });
    stores.unityStore.send({
      objectName: GameObjectNames.FarmReact,
      methodName: GameMethodNames.ExceptionRemoveBee,
      values: stringify({ code: response.code, apiaryId, beeId }),
    });
  } else {
    stores.unityStore.send({
      objectName: GameObjectNames.FarmReact,
      methodName: GameMethodNames.SuccessfullyRemoveBee,
      values: stringify({ apiaryId, beeId }),
    });
  }
};

const openApiary = async ({ apiaryId }) => {
  const { bearId, blocksPerHour } = stores.unityStore;
  const response = await Promise.all([
    BEARS.getFarmingHoneyApiary({ apiaryId }),
    BEARS.getCountHoneyApiary({ bearId, apiaryId }),
    BEARS.getApiariesInfoByBearId({ bearId }),
    BEARS.getBeesInfoByBearId({ bearId }),
  ]);

  // const responseFarming = await BEARS.getFarmingHoneyApiary({ apiaryId });
  // const responseHoney = await BEARS.getCountHoneyApiary({ bearId, apiaryId });
  // const responseApiaries = await BEARS.getApiariesInfoByBearId({ bearId });
  // const responseBees = await BEARS.getBeesInfoByBearId({ bearId });
  const responseFarming = response[0];
  const responseHoney = response[1];
  const responseApiaries = response[2];
  const responseBees = response[3];
  Logger.infoMainWithKey('responseBees', responseBees);

  if (responseFarming.code > 0) {
    // error from blockchain
    handleMainError({ code: responseFarming.code });
  } else if (responseHoney.code > 0) {
    // error from blockchain
    handleMainError({ code: responseHoney.code });
  } else {
    if (responseApiaries.result && responseApiaries.result.length > 0 && responseBees.result && responseBees.result.length > 0) {
      const apiaryInfo = responseApiaries.result.find((apiary) => {
        return apiary.id === apiaryId;
      });
      if (apiaryInfo && apiaryInfo.bees.length > 0) {
        await Promise.all(
          responseBees.result.map(async (bee) => {
            if (bee.apiaryHouse && bee.apiaryHouse.id === apiaryInfo.id) {
              const responsePower = await BEARS.getHoneyPowerBeePerBlock({ beeType: bee.params.beeType });
              if (responsePower.code > 0) {
                // error from blockchain
                handleMainError({ code: responsePower.code });
              } else {
                stores.unityStore.send({
                  objectName: GameObjectNames.FarmReact,
                  methodName: GameMethodNames.AddBeeApiary,
                  values: stringify({
                    beeId: bee.id,
                    apiaryId,
                    kind: bee.params.beeType,
                    honeyPerHour: toFixedNumberWithoutRound({ number: responsePower.result * blocksPerHour, countSign: 6 }),
                  }),
                });
              }
            }
          }),
        );
      }
    }
    stores.unityStore.send({
      objectName: GameObjectNames.FarmReact,
      methodName: GameMethodNames.UpdateApiaryInfo,
      values: stringify({
        apiaryId,
        countHoney: responseHoney.result,
        farmingCount: toFixedNumberWithoutRound({ number: responseFarming.result * blocksPerHour, countSign: 6 }),
      }),
    });
  }
};

const openStore = async () => {
  const { bearId, fieldId } = stores.unityStore;
  if (bearId === -1) {
    return;
  }
  const response = await Promise.all([
    BEARS.getBeesInfoByBearId({ bearId }),
    BEARS.getDecorationsInfoByBearId({ bearId }),
    BEARS.getFieldById({ id: fieldId })
  ]);
  // const responseBees = await BEARS.getBeesInfoByBearId({ bearId });
  // Logger.infoMainWithKey('responseBees', responseBees);
  // const responseDecors = await BEARS.getDecorationsInfoByBearId({ bearId });
  // Logger.infoMainWithKey('responseDecors', responseDecors);
  // const field = await BEARS.getFieldById({ id: fieldId });
  const responseBees = response[0];
  Logger.infoMainWithKey('responseBees', responseBees);
  const responseDecors = response[1];
  Logger.infoMainWithKey('responseDecors', responseDecors);
  const field = response[2];

  if (field && field.err) {
    // error from blockchain
    handleMainError({ code: 108 });
  } else {
    if (responseBees.result && responseBees.result.length > 0) {
      responseBees.result.forEach((bee) => {
        if (!bee.apiaryHouse) {
          stores.unityStore.send({
            objectName: GameObjectNames.FarmReact,
            methodName: GameMethodNames.InitialWarehouse,
            values: stringify({ id: bee.id, type: BEE_TYPE, kind: bee.params.beeType }),
          });
        }
      });
    }
    const decorationsIdField = [];
    if (field && field.rows) {
      field.rows.forEach((rowObj, indexRow) => {
        rowObj.columns.forEach((columnObj, indexColumn) => {
          if (columnObj.item && ITEM_TYPES[columnObj.item.itemType] === DECORATION_TYPE) {
            Logger.infoMainWithKey('decor on field', columnObj.item.itemId);
            decorationsIdField.push(columnObj.item.itemId);
          }
        });
      });
    }
    Logger.infoMainWithKey('decorationsIdField', decorationsIdField);
    if (responseDecors.result && responseDecors.result.length > 0) {
      responseDecors.result.forEach((decor) => {
        if (
          !decorationsIdField.some((decorId) => {
            return decorId === decor.id;
          })
        ) {
          // decoration in warehouse
          stores.unityStore.send({
            objectName: GameObjectNames.FarmReact,
            methodName: GameMethodNames.InitialWarehouse,
            values: stringify({ id: decor.id, type: DECORATION_TYPE, kind: decor.params.decorationType }),
          });
        }
      });
    }
  }
};

const expandLand = async () => {
  const { bearId, fieldId } = stores.unityStore;
  if (bearId === -1) {
    // INIT Game, bear not exist
    await initializeBlockchainGame({ type: FIELD_TYPE });
  } else {
    const response = await BEARS.msgExtendField({ fieldId });
    Logger.infoMainWithKey('msgExtendField ExpandLand response', response);
    if (response.code > 0) {
      // error from blockchain
      handleMainError({ code: response.code });
      stores.unityStore.send({
        objectName: GameObjectNames.FarmReact,
        methodName: GameMethodNames.ExceptionExpandField,
        values: stringify({ code: response.code }),
      });
    } else {
      const parsedRawLog = logs.parseRawLog(response.rawLog);

      const attributeField = logs.findAttribute(parsedRawLog, 'message', 'count_tiles');
      const lengthGrounds = Math.sqrt(Number(attributeField.value));
      stores.unityStore.send({
        objectName: GameObjectNames.FarmReact,
        methodName: GameMethodNames.InitializeGrounds,
        values: stringify({ length: lengthGrounds }),
      });
    }
  }
};

const buySellingPack = async ({ packID }) => {
  Logger.infoMainWithKey('buySellingPack with packID', packID);
  await getPacksAndBlockchainObjects({ afterHandler: (result) => {
    const packs = savePacks({ result });
    // console.log('packID >> ', packID);
    // const packID2 = 'HONEYCOIN_PACK_2';
    const packInfo = packs.packArrayHoney.find((pack) => pack.ID === packID);

    if (packInfo && packInfo.ID) {
      stores.setStateDisplayedPaymentModal({ packInfo });
      stores.showPaymentsHoneyUnity(true);
    }
  }});
}

const moveObject = async ({ id, rowId, columnId, newRowId, newColumnId }) => {
  if (!enoughHoneyForTax()) {
    return;
  }
  const { fieldId } = stores.unityStore;
  const response = await BEARS.msgMoveItemOnField({ fieldId, rowId, columnId, newRowId, newColumnId });
  Logger.infoMainWithKey('Delivery_Product response', response);
  if (response.code > 0) {
    // error from blockchain
    handleMainError({ code: response.code });
    stores.unityStore.send({
      objectName: GameObjectNames.FarmReact,
      methodName: GameMethodNames.ExceptionReplace,
      values: stringify({ code: response.code, id, rowId: newRowId, columnId: newColumnId }),
    });
  } else {
    stores.unityStore.send({
      objectName: GameObjectNames.FarmReact,
      methodName: GameMethodNames.SuccessfullyReplace,
      values: stringify({ id, rowId: newRowId, columnId: newColumnId }),
    });
  }
};

const collectHoney = async ({ apiaryId }) => {
  if (!enoughHoneyForTax()) {
    return;
  }
  const response = await BEARS.msgCollectHoneyFromApiary({ apiaryId });
  console.log('[collectHoney] response >> ', response);
  if (response.code > 0) {
    // error from blockchain
    handleMainError({ code: response.code }, undefined, false);
    stores.unityStore.send({
      objectName: GameObjectNames.FarmReact,
      methodName: GameMethodNames.ExceptionCollectHoney,
      values: stringify({ code: response.code, apiaryId }),
    });
  } else {
    stores.unityStore.send({
      objectName: GameObjectNames.FarmReact,
      methodName: GameMethodNames.SuccessfullyCollectHoney,
      values: stringify({ apiaryId }),
    });
  }
};

const deleteFromField = async ({ id, type }) => {
  if (!enoughHoneyForTax()) {
    return;
  }
  const { bearId } = stores.unityStore;
  switch (type) {
    case DECORATION_TYPE: {
      const responseDecor = await BEARS.msgUnsetDecorationPosition({ decorationId: id });
      if (responseDecor.code > 0) {
        handleMainError({ code: responseDecor.code });
        stores.unityStore.send({
          objectName: GameObjectNames.FarmReact,
          methodName: GameMethodNames.ExceptionRemoveObjectFromField,
          values: stringify({ code: responseDecor.code, id, type }),
        });
      } else {
        stores.unityStore.send({
          objectName: GameObjectNames.FarmReact,
          methodName: GameMethodNames.RemoveObjectFromField,
          values: stringify({ id, type }),
        });
      }
      break;
    }
    case APIARY_TYPE: {
      const apiariesInfo = await BEARS.getApiariesInfoByBearId({ bearId });
      let beesInApiary = [];
      let honeyInApiary = 0;
      if (apiariesInfo && apiariesInfo.result.length > 0) {
        apiariesInfo.result.forEach((apiary) => {
          if (apiary.id === id) {
            Logger.infoMainWithKey('DeleteFromField apiariesInfo', apiariesInfo.result);
            beesInApiary = apiary.bees;
            honeyInApiary = apiary.countHoney;
          }
        });
      } else {
        handleMainError({ code: 107 }, new Error(`Error: DeleteFromField apiary no exist with id ${id}`));
        return;
      }

      if (beesInApiary.length > 0) {
        // bees and honey in apiary
        const responseDeleteWithBees = await BEARS.msgDeleteApiaryWithBees({ apiaryId: id });
        Logger.infoMainWithKey('DeleteFromField responseDeleteWithBees', responseDeleteWithBees);
        if (responseDeleteWithBees.code > 0) {
          handleMainError({ code: responseDeleteWithBees.code });
          stores.unityStore.send({
            objectName: GameObjectNames.FarmReact,
            methodName: GameMethodNames.ExceptionRemoveObjectFromField,
            values: stringify({ code: responseDeleteWithBees.code, id, type }),
          });
          return;
        }
      } else if (honeyInApiary > 0) {
        // honey in apiary
        const responseDeleteWithCollect = await BEARS.msgDeleteApiaryWithCollectHoney({ apiaryId: id });
        Logger.infoMainWithKey('DeleteFromField responseDeleteWithCollect', responseDeleteWithCollect);
        if (responseDeleteWithCollect.code > 0) {
          handleMainError({ code: responseDeleteWithCollect.code });
          stores.unityStore.send({
            objectName: GameObjectNames.FarmReact,
            methodName: GameMethodNames.ExceptionRemoveObjectFromField,
            values: stringify({ code: responseDeleteWithCollect.code, id, type }),
          });
          return;
        }
      } else {
        // only apiary
        const responseDeleteApiary = await BEARS.msgDeleteApiary({ apiaryId: id });
        Logger.infoMainWithKey('DeleteFromField responseDeleteApiary', responseDeleteApiary);
        if (responseDeleteApiary.code > 0) {
          handleMainError({ code: responseDeleteApiary.code });
          stores.unityStore.send({
            objectName: GameObjectNames.FarmReact,
            methodName: GameMethodNames.ExceptionRemoveObjectFromField,
            values: stringify({ code: responseDeleteApiary.code, id, type }),
          });
          return;
        }
      }

      stores.unityStore.send({
        objectName: GameObjectNames.FarmReact,
        methodName: GameMethodNames.RemoveObjectFromField,
        values: stringify({ id, type }),
      });
      break;
    }
    default:
      handleMainError({ code: 102 });
      break;
  }
};

const setObjOnField = async ({ id, rowId, columnId }) => {
  if (!enoughHoneyForTax()) {
    return;
  }
  const { fieldId } = stores.unityStore;
  const response = await BEARS.msgSetDecorationPosition({
    decorationId: id,
    fieldId,
    rowId,
    columnId,
  });
  if (response.code > 0) {
    // error from blockchain
    handleMainError({ code: response.code });
    stores.unityStore.send({
      objectName: GameObjectNames.FarmReact,
      methodName: GameMethodNames.ExceptionReplace,
      values: stringify({ code: response.code, id, rowId, columnId }),
    });
  } else {
    stores.unityStore.send({
      objectName: GameObjectNames.FarmReact,
      methodName: GameMethodNames.SuccessfullyReplace,
      values: stringify({ id, rowId, columnId }),
    });
  }
};

export default {
  buyAndSetObject,
  setBeeInApiary,
  createAndSetBeeInApiary,
  buyBee,
  removeBeeFromApiary,
  openApiary,
  openStore,
  expandLand,
  buySellingPack,
  moveObject,
  collectHoney,
  deleteFromField,
  setObjOnField,
};
