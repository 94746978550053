import React from 'react';
import { sanitize } from 'dompurify';

const TextH3 = ({ text = '', classNames = {}, style = {} }) => {
  return (
    <h3 className={classNames} dangerouslySetInnerHTML={{ __html: sanitize(text) }} style={style} />
  );
};

export default TextH3;
