import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { gtagEvent } from '@utils/common';
import { GOOGLE_ANALYTICS } from '@src/constants/googleAnalyticsEvents';
import { useStores } from 'src/stores';
import classnames from 'classnames';

import Telegram from '@assets/socials/telegram.svg';
import TelegramBlack from '@assets/socials/telegram_black.svg';
import TelegramHover from '@assets/socials/telegram_hover.svg';

import Chat from '@assets/socials/chat.svg';
import ChatBlack from '@assets/socials/chat_black.svg';
import ChatHover from '@assets/socials/chat_hover.svg';

import Instagram from '@assets/socials/instagram.svg';
import InstagramBlack from '@assets/socials/instagram_black.svg';
import InstagramHover from '@assets/socials/instagram_hover.svg';

import Facebook from '@assets/socials/facebook.svg';
import FacebookBlack from '@assets/socials/facebook_black.svg';
import FacebookHover from '@assets/socials/facebook_hover.svg';

import Twitter from '@assets/socials/twitter.svg';
import TwitterBlack from '@assets/socials/twitter_black.svg';
import TwitterHover from '@assets/socials/twitter_hover.svg';

import Linkedin from '@assets/socials/linkedin.svg';
import LinkedinBlack from '@assets/socials/linkedin_black.svg';
import LinkedinHover from '@assets/socials/linkedin_hover.svg';

import Youtube from '@assets/socials/youtube.svg';
import YoutubeBlack from '@assets/socials/youtube_black.svg';
import YoutubeHover from '@assets/socials/youtube_hover.svg';

import Discord from '@assets/socials/discord.svg';
import DiscordBlack from '@assets/socials/discord_black.svg';
import DiscordHover from '@assets/socials/discord_hover.svg';

import Medium from '@assets/socials/medium.svg';
import MediumBlack from '@assets/socials/medium_black.svg';
import MediumHover from '@assets/socials/medium_hover.svg';

import Logger from '@utils/logger';
import styles from './social.module.scss';

const fullSocials = [
  { name: 'telegramChannel', link: 'https://t.me/honeywood_official' },
  { name: 'telegramChat', link: 'https://t.me/honeywood_chat' },
  { name: 'instagram', link: 'https://www.instagram.com/honeywoodgame/' },
  { name: 'facebook', link: 'https://www.facebook.com/HoneyWoodGame' },
  { name: 'twitter', link: 'https://twitter.com/honeywood_game' },
  { name: 'linkedin', link: 'https://www.linkedin.com/showcase/honeywood-io/ ' },
  { name: 'youtube', link: 'https://youtube.com/channel/UCNzCssyvJGsSSDO8dwpqraA' },
  { name: 'discord', link: 'https://discord.gg/EscBYjGutS' },
  { name: 'medium', link: 'https://honeywood-official.medium.com' },
];

const threeSocials = [
  { name: 'telegramChannel', link: 'https://t.me/honeywood_official' },
  { name: 'discord', link: 'https://discord.gg/EscBYjGutS' },
  { name: 'medium', link: 'https://honeywood-official.medium.com' },
];

const SocialNetwork = observer(
  ({ classNames = {}, isBlackIcons = false, capitalSection = false }) => {
    const stores = useStores();
    const [hoverSocialImage, setHoverSocialImage] = useState(null);

    const getSocialImage = (socialImage) => {
      switch (socialImage) {
        case 'telegramChannel':
          return hoverSocialImage === 'telegramChannel'
            ? TelegramHover
            : isBlackIcons
            ? TelegramBlack
            : Telegram;
        case 'telegramChat':
          return hoverSocialImage === 'telegramChat' ? ChatHover : isBlackIcons ? ChatBlack : Chat;
        case 'instagram':
          return hoverSocialImage === 'instagram'
            ? InstagramHover
            : isBlackIcons
            ? InstagramBlack
            : Instagram;
        case 'facebook':
          return hoverSocialImage === 'facebook'
            ? FacebookHover
            : isBlackIcons
            ? FacebookBlack
            : Facebook;
        case 'twitter':
          return hoverSocialImage === 'twitter'
            ? TwitterHover
            : isBlackIcons
            ? TwitterBlack
            : Twitter;
        case 'linkedin':
          return hoverSocialImage === 'linkedin'
            ? LinkedinHover
            : isBlackIcons
            ? LinkedinBlack
            : Linkedin;
        case 'youtube':
          return hoverSocialImage === 'youtube'
            ? YoutubeHover
            : isBlackIcons
            ? YoutubeBlack
            : Youtube;
        case 'discord':
          return hoverSocialImage === 'discord'
            ? DiscordHover
            : isBlackIcons
            ? DiscordBlack
            : Discord;
        case 'medium':
          return hoverSocialImage === 'medium' ? MediumHover : isBlackIcons ? MediumBlack : Medium;
        default:
          return Telegram;
      }
    };
    const socialsArray = capitalSection ? threeSocials : fullSocials;

    return (
      <div className={classnames(styles.SocialContainer, classNames.Container)}>
        {socialsArray.map((social) => {
          return (
            <a
              key={`social_${social.name}`}
              target="_blank"
              rel="noreferrer"
              href={social.link}
              className={styles.SocialLink}
              onMouseEnter={() =>
                setHoverSocialImage(
                  !stores.browserInfo.isSensor && !stores.browserInfo.isMobile && social.name,
                )
              }
              onMouseLeave={() => setHoverSocialImage(null)}
              onClick={() => {
                gtagEvent(GOOGLE_ANALYTICS.CLICK_SOCIAL, social.name);
              }}
            >
              <img
                onLoad={() => stores.increaseDownloadedImageCounter(getSocialImage(social.name))}
                onError={() => Logger.error('Error with image loading')}
                src={getSocialImage(social.name)}
                alt={`social_${social.name}`}
                className={styles.MobileSocialIcon}
              />
            </a>
          );
        })}
      </div>
    );
  },
);

export default SocialNetwork;
