import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import TextH4 from '@components_new/TextH4';
import { sanitize } from 'dompurify';
import TitleSection from '@src/components/TitleSection';
import { TOKENOMICS_COINS_PERCENTAGES } from '@constants/system';
import HoneyIcon from '@assets/Mobile/TokenomicsPage/TokenomicsSection/honey_icon.png';
import HoneyBarChart from '@assets/Mobile/TokenomicsPage/TokenomicsSection/honey_barChart.svg';
import ConeIcon from '@assets/Mobile/TokenomicsPage/TokenomicsSection/cone_icon.png';
import ConeBarChart from '@assets/Mobile/TokenomicsPage/TokenomicsSection/cone_barChart.svg';
import Logger from '@utils/logger';
import styles from './mobileTokenomics.module.scss';

const TokenomicsSection = observer(() => {
  const stores = useStores();
  const _ = stores.language;

  return (
    // <div className={styles.TokenomicsSectionContainer}>
    <div className={styles.Container} id="tokenomics-section">
      <div className={styles.TitleContainer}>
        <TitleSection title={stores.translate('coins')} isH2TitleTag />
      </div>
      <div className={styles.CoinsContainer}>
        <div className={styles.CoinWrapper}>
          <img
            onLoad={() => stores.increaseDownloadedImageCounter(HoneyIcon)}
            onError={() => Logger.error('Error with image loading')}
            src={HoneyIcon}
            alt="honey_icon"
            className={styles.CoinIcon}
          />
          <TextH4 classNames={styles.CoinTitle} text={stores.translate('honey_coin_title')} />
          <div className={styles.CoinSubTitleTextWrapper}>
            <p
              className={styles.CoinSubTitle}
              dangerouslySetInnerHTML={{
                __html: sanitize(stores.translate('honey_coin_subtitle')),
              }}
            />
            <p
              className={styles.CoinText}
              dangerouslySetInnerHTML={{ __html: sanitize(stores.translate('honey_coin_text')) }}
            />
          </div>
          <p
            className={styles.TotalSupplyTitle}
            dangerouslySetInnerHTML={{
              __html: sanitize(stores.translate('total_supply_honeyCoin')),
            }}
          />
          <p
            className={styles.TotalSupplyCoins}
            dangerouslySetInnerHTML={{
              __html: sanitize(stores.translate('honeyCoin_count_coins')),
            }}
          />
          <img
            onLoad={() => stores.increaseDownloadedImageCounter(HoneyBarChart)}
            onError={() => Logger.error('Error with image loading')}
            src={HoneyBarChart}
            alt="honey_barChart"
            className={styles.CoinBarChart}
          />
          <div className={styles.CoinsDistributionContainer}>
            <div className={styles.HoneyCoinDistributionWrapper}>
              <div className={styles.PercentagesTextWrapper}>
                <p className={styles.PercentagesText}>
                  <span className={styles.PercentagesText__Liquidity}>
                    {TOKENOMICS_COINS_PERCENTAGES.honeyCoin.gameAssets}%
                  </span>
                  &nbsp;-&nbsp;{stores.translate('game_assets')}
                </p>
              </div>
              <div className={styles.PercentagesTextWrapper}>
                <p className={styles.PercentagesText}>
                  <span className={styles.PercentagesText__Igo}>
                    {TOKENOMICS_COINS_PERCENTAGES.honeyCoin.airdrop}%
                  </span>
                  &nbsp;-&nbsp;{stores.translate('airdrops')}
                </p>
              </div>
              <div className={styles.PercentagesTextWrapper}>
                <p className={styles.PercentagesText}>
                  <span className={styles.PercentagesText__WebSale}>
                    {TOKENOMICS_COINS_PERCENTAGES.honeyCoin.reward}%
                  </span>
                  &nbsp;-&nbsp;{stores.translate('reward')}
                </p>
              </div>
              {/* <div className={styles.PercentagesTextWrapper}>
                <p className={styles.PercentagesText}>
                  <span className={styles.PercentagesText__HoneyWoodTeam}>
                    {TOKENOMICS_COINS_PERCENTAGES.honeyCoin.honeyWoodTeam}%
                  </span>
                  &nbsp;-&nbsp;{stores.translate('honeyWood_team')}
                </p>
              </div> */}
            </div>
            {/* <div className={styles.HoneyCoinDistributionWrapper}>
              <div className={styles.PercentagesTextWrapper}>
                <p className={styles.PercentagesText}>
                  <span className={styles.PercentagesText__Private}>
                    {TOKENOMICS_COINS_PERCENTAGES.honeyCoin.private}%
                  </span>
                  &nbsp;-&nbsp;{stores.translate('private')}
                </p>
              </div>
              <div className={styles.PercentagesTextWrapper}>
                <p className={styles.PercentagesText}>
                  <span className={styles.PercentagesText__Marketing}>
                    {TOKENOMICS_COINS_PERCENTAGES.honeyCoin.marketing}%
                  </span>
                  &nbsp;-&nbsp;{stores.translate('marketing')}
                </p>
              </div>
              <div className={styles.PercentagesTextWrapper}>
                <p className={styles.PercentagesText}>
                  <span className={styles.PercentagesText__Advisory}>
                    {TOKENOMICS_COINS_PERCENTAGES.honeyCoin.advisory}%
                  </span>
                  &nbsp;-&nbsp;{stores.translate('advisory')}
                </p>
              </div>
              <div className={styles.PercentagesTextWrapper}>
                <p className={styles.PercentagesText}>
                  <span className={styles.PercentagesText__Reward}>
                    {TOKENOMICS_COINS_PERCENTAGES.honeyCoin.reward}%
                  </span>
                  &nbsp;-&nbsp;{stores.translate('reward')}
                </p>
              </div>
            </div> */}
          </div>
        </div>
        <div className={styles.CoinWrapper}>
          <img
            onLoad={() => stores.increaseDownloadedImageCounter(ConeIcon)}
            onError={() => Logger.error('Error with image loading')}
            src={ConeIcon}
            alt="honey_icon"
            className={styles.CoinIcon}
          />
          <TextH4 classNames={styles.CoinTitle} text={stores.translate('cone_title')} />
          <div className={styles.CoinSubTitleTextWrapper}>
            <p
              className={styles.CoinSubTitle}
              dangerouslySetInnerHTML={{
                __html: sanitize(stores.translate('cone_subtitle')),
              }}
            />
            <p
              className={styles.CoinText}
              dangerouslySetInnerHTML={{ __html: sanitize(stores.translate('cone_text')) }}
            />
          </div>
          <p
            className={styles.TotalSupplyTitle}
            dangerouslySetInnerHTML={{
              __html: sanitize(stores.translate('total_supply_cone')),
            }}
          />
          <p
            className={styles.TotalSupplyCoins}
            dangerouslySetInnerHTML={{
              __html: sanitize(stores.translate('cone_count_coins')),
            }}
          />
          <img
            onLoad={() => stores.increaseDownloadedImageCounter(ConeBarChart)}
            onError={() => Logger.error('Error with image loading')}
            src={ConeBarChart}
            alt="cone_barChart"
            className={styles.CoinBarChart}
          />
          <div className={styles.CoinsDistributionContainer}>
            <div className={styles.ConeDistributionWrapper}>
              <div className={styles.PercentagesTextWrapper}>
                <p className={styles.PercentagesText}>
                  <span className={styles.PercentagesText__Liquidity}>
                    {TOKENOMICS_COINS_PERCENTAGES.cone.companyStake}%
                  </span>
                  &nbsp;-&nbsp;{stores.translate('company_stake')}
                </p>
              </div>
              <div className={styles.PercentagesTextWrapper}>
                <p className={styles.PercentagesText}>
                  <span className={styles.PercentagesText__Igo}>
                    {TOKENOMICS_COINS_PERCENTAGES.cone.liquidity}%
                  </span>
                  &nbsp;-&nbsp;{stores.translate('liquidity')}
                </p>
              </div>
              <div className={styles.PercentagesTextWrapper}>
                <p className={styles.PercentagesText}>
                  <span className={styles.PercentagesText__Igo}>
                    {TOKENOMICS_COINS_PERCENTAGES.cone.honeyWoodTeam}%
                  </span>
                  &nbsp;-&nbsp;{stores.translate('honeyWood_team')}
                </p>
              </div>
              <div className={styles.PercentagesTextWrapper}>
                <p className={styles.PercentagesText}>
                  <span className={styles.PercentagesText__WebSale}>
                    {TOKENOMICS_COINS_PERCENTAGES.cone.privateRoundOne}%
                  </span>
                  &nbsp;-&nbsp;{stores.translate('private_round')}&nbsp;#1
                </p>
              </div>
              <div className={styles.PercentagesTextWrapper}>
                <p className={styles.PercentagesText}>
                  <span className={styles.PercentagesText__HoneyWoodTeam}>
                    {TOKENOMICS_COINS_PERCENTAGES.cone.privateRoundTwo}%
                  </span>
                  &nbsp;-&nbsp;{stores.translate('private_round')}&nbsp;#2
                </p>
              </div>
            </div>
            <div className={styles.ConeDistributionWrapper}>
              <div className={styles.PercentagesTextWrapper}>
                <p className={styles.PercentagesText}>
                  <span className={styles.PercentagesText__Advisory}>
                    {TOKENOMICS_COINS_PERCENTAGES.cone.marketing}%
                  </span>
                  &nbsp;-&nbsp;{stores.translate('marketing')}
                </p>
              </div>
              <div className={styles.PercentagesTextWrapper}>
                <p className={styles.PercentagesText}>
                  <span className={styles.PercentagesText__Private}>
                    {TOKENOMICS_COINS_PERCENTAGES.cone.partnership}%
                  </span>
                  &nbsp;-&nbsp;{stores.translate('partnerships')}
                </p>
              </div>
              <div className={styles.PercentagesTextWrapper}>
                <p className={styles.PercentagesText}>
                  <span className={styles.PercentagesText__Marketing}>
                    {TOKENOMICS_COINS_PERCENTAGES.cone.advisory}%
                  </span>
                  &nbsp;-&nbsp;{stores.translate('advisory')}
                </p>
              </div>
              <div className={styles.PercentagesTextWrapper}>
                <p className={styles.PercentagesText}>
                  <span className={styles.PercentagesText__Reward}>
                    {TOKENOMICS_COINS_PERCENTAGES.cone.seed}%
                  </span>
                  &nbsp;-&nbsp;{stores.translate('seed')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
});

export default TokenomicsSection;
