import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Layout from 'src/layout/Layout';
import CapitalSection from '../MobileHomePage/components/CapitalSection';
import KeyNewsSection from './components/KeyNewsSection';
import BenefitsSection from '../MobileHomePage/components/BenefitsSection';
import GameConceptSection from '../MobileHomePage/components/GameConceptSection';
import SpecialPresaleSection from '../MobileHomePage/components/SpecialPresaleSection';
import GameInfoSection from '../MobileHomePage/components/GameInfoSection';
import PlayToEarnBanner from './components/PlayToEarnBanner';
import PartnersSection from '../MobileHomePage/components/PartnersSection';
import styles from './home.module.scss';

const DesktopHomePage = observer(({ isInitialLoading = true }) => {
  const [isLoadingFinish, setLoadingFinish] = useState(false);

  return (
    <Layout
      isInitialLoading={isInitialLoading}
      isLoadingFinish={!isInitialLoading && isLoadingFinish}
    >
      <CapitalSection />
      <KeyNewsSection />
      <GameConceptSection />
      <BenefitsSection />
      {/* <SpecialPresaleSection
        finishRequestPacks={() => {
          setTimeout(() => {
            setLoadingFinish(true);
          }, 500);
        }}
      /> */}
      <GameInfoSection />
      <PlayToEarnBanner classNames={{ Container: styles.Container }} />
      <PartnersSection />
    </Layout>
  );
});

export default DesktopHomePage;
