import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { sanitize } from 'dompurify';
import { observer } from 'mobx-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { copyText } from '@utils/common';
import { useStores } from 'src/stores';
import { Button, ColorType, SizeButton } from '@components_new/Button';
import { ENUM_MODAL } from '@components_new/Modal/enumModal';
import InputWord from '@components/InputWord';
import Input from '@components/Input';
import CheckBox from '@components/CheckBox';
import { CRYPTO } from 'src/api/crypto';
import { COUNT_WORD_SEED_PHRASE } from '@constants/system';
import { useCopied } from '@hooks/custom';
import { BEARS } from '@api';
import Logger from '@utils/logger';
import ShowGreenButtonDefaultIcon from '@assets/common/show_green_button_default_icon.png';
import ShowGreenButtonPressedIcon from '@assets/common/show_green_button_pressed_icon.png';
import styles from './createSeedPhraseModalMobile.module.scss';

const CreateSeedPhraseModalMobile = observer(({ close }) => {
  const stores = useStores();
  const [isSecretText, setSecretText] = useState(true);

  const onShowSecretText = () => {
    setSecretText(!isSecretText);
  };

  const [mnemonic, setMnemonic] = useState([
    'word1',
    'word2',
    'word3',
    'word4',
    'word5',
    'word6',
    'word7',
    'word8',
    'word9',
    'word10',
    'word11',
    'word12',
  ]);
  const [address, setAddress] = useState('');
  const [selectedCode, setSelectedCode] = useState(false);

  const schemaNeedFieldLoginModal = yup.object().shape({
    password: yup.string('').min(5, stores.translate('password_input_validation')),
    airdropCode: yup.string(),
  });

  const { register, handleSubmit, watch, formState, setValue } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schemaNeedFieldLoginModal),
  });

  const { password, airdropCode } = watch();

  const inputsRef = new Array(COUNT_WORD_SEED_PHRASE).fill(undefined).map(() => useRef());
  const { copied, setCopied } = useCopied();

  useEffect(() => {
    if (stores.propsForModal && stores.propsForModal.code) {
      setValue('airdropCode', stores.propsForModal.code);
      setSelectedCode(true);
    }
    
    if (stores.propsForModal && stores.propsForModal.mnemonic) {
      setMnemonic(stores.propsForModal.mnemonic);
      setValue('password', stores.propsForModal.password);
    } else {
      const seedPhrase = CRYPTO.generateMnemonic();
      setMnemonic(seedPhrase.split(' '));
      (async () => {
        try {
          const { account } = await BEARS.getAccount({ mnemonic: seedPhrase, isPrivKeys: true });
          setAddress(account.address);
        } catch (err) {
          Logger.error(err);
        }
      })();
    }
  }, []);

  const onCopyClick = async () => {
    await copyText({ text: mnemonic.join(' ') });
    setCopied();
  };

  const onBackClick = () => {
    close();
    stores.setOpenModal(ENUM_MODAL.JOIN);
  };

  const onNeedFieldsClick = () => {
    const countOpened = stores.propsForModal ? stores.propsForModal.countOpened + 1 : 1;
    stores.setPropsForModal({ ...stores.propsForModal, mnemonic, password, countOpened, code: airdropCode });
    stores.setOpenModal(ENUM_MODAL.APPROVE_REGISTRATION);
  };

  const handleKeypress = (e) => {
    if (e.key && e.key.toLowerCase() === 'enter') {
      handleSubmit(onNeedFieldsClick)();
    }
  };

  return (
    <>
      <p className={styles.SubTitle}>{stores.translate('save_your_login')}</p>
      <div className={styles.SeedPraseContainerWrapper}>
        <div className={styles.SeedPraseContainer}>
          {mnemonic.map((word, index) => {
            return (
              <InputWord
                // eslint-disable-next-line react/no-array-index-key
                key={`generate_seed_phrase_${index}`}
                name={`generated_seed_phrase_word${index}`}
                value={word}
                readOnly
                ref={inputsRef[index]}
                classNames={{
                  InputContainer: classnames(
                    styles.WordInput_InputContainer,
                    isSecretText && styles.WordInput_InputContainer_isSecret,
                  ),
                  Container: styles.Input_Container,
                  Input: classnames(
                    styles.WordInput_Input,
                    (index + 1) % 4 === 0 && styles.WordInput_Input__noRightBorder,
                  ),
                }}
              />
            );
          })}
        </div>
      </div>
      <div className={styles.PaddingInnerContainer}>
        <div className={styles.ButtonContainer}>
          <Button
            colorType={ColorType.ORANGE}
            size={SizeButton.SMALL}
            onClick={() => {
              onCopyClick();
            }}
            title={copied ? stores.translate('copied') : stores.translate('copy')}
            classNames={{
              Container: styles.CopyButtonContainer,
              Button: styles.CopyButton,
              Title: classnames(
                styles.ButtonTitle,
                styles.ButtonTitle__Orange,
                styles.ButtonTitle__CopyButton,
              ),
            }}
          />
          <button type="button" className={styles.GreenButtonShow} onClick={onShowSecretText}>
            {isSecretText ? (
              <img
                onLoad={() => stores.increaseDownloadedImageCounter(ShowGreenButtonDefaultIcon)}
                onError={() => Logger.error('Error with image loading')}
                src={ShowGreenButtonDefaultIcon}
                alt="show_green_button_default_icon"
                className={styles.ShowButtonDefaultIcon}
              />
            ) : (
              <img
                onLoad={() => stores.increaseDownloadedImageCounter(ShowGreenButtonPressedIcon)}
                onError={() => Logger.error('Error with image loading')}
                src={ShowGreenButtonPressedIcon}
                alt="show_green_button_pressed_icon"
                className={styles.ShowButtonDefaultIcon}
              />
            )}
          </button>
        </div>
        <p className={styles.ImportantTitle}>
          {stores.translate('important')}&nbsp;
          <span className={styles.ImportantTitle__description}>
            {stores.translate('no_one_must_see_your_login')}
          </span>
        </p>
        <div className={styles.InputContainerWrapper}>
          <Input
            placeholder={stores.translate('password_input_placeholder')}
            canError
            label={stores.translate('password')}
            error={formState.errors?.password}
            name="password"
            register={register}
            autoComplete="no"
            isButtonSecretShow
            onKeyDown={handleKeypress}
            classNames={{
              Label: styles.LabelInput,
              InputContainer: styles.Input_InputContainer,
              Container: classnames(styles.Input_Container, styles.InputPasswordStretch),
              Input: styles.Input_Input,
              InputButtonShow: styles.Input_InputButtonShow,
            }}
          />
        </div>
        <div className={classnames(styles.CheckBoxContainer, selectedCode && styles.CheckBoxContainer__selected)}>
          <CheckBox
            selected={selectedCode}
            onChange={() => {
              setSelectedCode(!selectedCode);
            }}
            title={stores.translate('i_have_airdrop_code')}
            classNames={{
              ContainerImage: styles.CheckBoxImage,
              Container: styles.CheckBox,
              Title: styles.CheckBoxTitle,
            }}
          />
        </div>
        {selectedCode && (
          <div className={styles.ReferralContainer}>
            <p className={styles.TitleEnterReferral}>{stores.translate('enter_airdrop_code')}</p>
            <InputWord
              placeholder={stores.translate('code')}
              name="airdropCode"
              register={register}
              autoComplete="no"
              classNames={{
                Label: styles.CodeLabelInput,
                InputContainer: styles.CodeInput_InputContainer,
                Container: styles.CodeInput_Container,
                Input: styles.CodeInput_Input,
              }}
              maxLength={10}
            />
          </div>
        )}
        <div className={styles.ButtonsContainer}>
          <Button
            colorType={ColorType.ORANGE}
            size={SizeButton.MEDIUM}
            onClick={() => {
              onBackClick();
            }}
            title={stores.translate('back')}
            classNames={{
              Container: styles.NextButton,
              Button: styles.Button,
              Title: classnames(styles.ButtonTitle, styles.ButtonTitle__Orange),
            }}
          />
          <Button
            colorType={ColorType.GREEN}
            size={SizeButton.MEDIUM}
            onClick={() => {
              handleSubmit(onNeedFieldsClick)();
            }}
            title={stores.translate('next')}
            classNames={{
              Container: styles.NextButton,
              Button: styles.Button,
              Title: classnames(styles.ButtonTitle, styles.ButtonTitle__Green),
            }}
          />
        </div>
      </div>
    </>
  );
});

export default CreateSeedPhraseModalMobile;
