import React from 'react';
import Layout from '@layout/Layout';
import CapitalSection from './components/CapitalSection';
// import RoadMapSection from './components/RoadMapSection';
import OurTeamSection from './components/OurTeamSection';

const MobileAboutPage = () => {
  return (
    <Layout isMobile isLoadingFinish>
      <CapitalSection />
      {/* <RoadMapSection /> */}
      <OurTeamSection />
    </Layout>
  );
};

export default MobileAboutPage;
