import React from 'react';
import classnames from 'classnames';

import styles from './radioButton.module.scss';

const RadioButton = ({
  selected = false,
  onChange = () => {},
  title = '',
  value = false,
  classNames = {},
  disabled = false,
  children = <></>,
}) => {
  return (
    <div
      className={classnames(
        styles.RadioButton_Container,
        disabled && styles.RadioButton_Container_disabled,
        classNames.Container,
      )}
      onClick={() => {
        if (!disabled) onChange(value);
      }}
      role="radio"
      aria-checked="false"
      tabIndex="0"
      onKeyPress={() => {
        if (!disabled) onChange(value);
      }}
    >
      <div
        className={classnames(
          styles.RadioButton_OuterCircle,
          value === selected && styles.RadioButton_OuterCircle_selected,
          classNames.OuterCircle,
        )}
      >
        <div
          className={classnames(
            styles.RadioButton_InnerCircle,
            value === selected && styles.RadioButton_InnerCircle_selectedCircle,
            classNames.InnerCircle,
          )}
        />
      </div>
      <div
        className={classnames(
          styles.RadioButton_Title,
          value === selected && styles.RadioButton_Title_selected,
          disabled && styles.RadioButton_Title_disabled,
          classNames.Title,
        )}
      >
        {title}
        {children}
      </div>
    </div>
  );
};

export default RadioButton;
