/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Provider, observer } from 'mobx-react';
import smoothscroll from 'smoothscroll-polyfill';

import HomePage from '@src/pages/HomePage';
import AboutPage from '@src/pages/AboutPage';
import PartnershipPage from '@src/pages/PartnershipPage';
import TokenomicsPage from '@src/pages/TokenomicsPage';
import FaqPage from '@src/pages/FaqPage';
import GamePage from '@src/pages/GamePage';
import TransactionsPage from '@src/pages/TransactionsPage';
import SignUpPage from '@src/pages/SignUpPage';
import AccountPage from '@src/pages/AccountPage';
import ChangelogPage from './pages/ChangelogPage';
import PrivateRoute from '@layout/NavigationStack/PrivateRoute';

import Modal from '@components_new/Modal';
import PopUp from '@components_new/PopUp';
import { useMediaQuery } from 'src/helpers/media';
import { HALLOWEEN_DAY } from '@constants/blockchain';

import { StoreInstance, useStores } from './stores';
import PaymentsModalMobile from './layout/components/mobile/PaymentsModalMobile';
import BeeSpinner from '@components/BeeSpinner';
// import { BEAR_CREATED_STATE } from '@constants/system';
import { isBearCreated } from '@utils/mainBears';
import { getPacksAndBlockchainObjects, savePacks } from '@utils/getPacksAndBlockchainObjects';
import HalloweenModal from '@layout/components/HalloweenModal';
import Logger from '@utils/logger';

import styles from './app.module.scss';
// import { CHAIN_ID_HONEYWOOD } from '@constants/blockchain';
import KeplrWallet from './api/KeplrWallet';
// import PaymentsHoneyUnity from './layout/components/mobile/PaymentsHoneyUnity';
// import { ENUM_MODAL } from '@components_new/Modal/enumModal';

const ModalChildren = ({ children }) => {
  return <>{React.Children.map(children, (child) => React.cloneElement(child))}</>;
};

const App = observer(() => {
  const stores = useStores();
  const isDesktopSize = useMediaQuery('(min-width: 1000px)');

  const [isShowPayments, setShowPayments] = useState(false);
  const [isShowPaymentsUnity, setShowPaymentsUnity] = useState(false);
  const [isShowPaymentsHoneyUnity, setShowPaymentsHoneyUnity] = useState(false);
  // update state of 'game'
  useEffect(() => {
    if (window.location.pathname === '/game') {
      stores.setGame(true);
    } else {
      stores.setGame(false);
    }
  }, [window.location.pathname]);

  // keplr changed account, listener
  useEffect(() => {
    const updateInfoAccount = async () => {
      await KeplrWallet.updateInfoAfterChangeAccount();
    };

    window.addEventListener('keplr_keystorechange', updateInfoAccount);
    return () => {
      window.removeEventListener('keplr_keystorechange', updateInfoAccount);
    };
  }, []);
  // show PaymentsModal
  useEffect(() => {
    if (stores.isShowPayments && stores.stateDisplayedPaymentModal) {
      /* Dynamically unlock scrolling background */
      document.body.style.overflowY = 'hidden';
      setShowPayments(true);
    } else {
      /* Dynamically unlock scrolling background */
      document.body.style.overflowY = 'initial';
      setShowPayments(false);
      stores.setStateDisplayedPaymentModal(null);
    }
  }, [stores.isShowPayments]);
  // show PaymentsModalUnity
  // useEffect(() => {
  //   if (stores.isShowPaymentsUnity && stores.stateDisplayedPaymentModal) {
  //     /* Dynamically unlock scrolling background */
  //     document.body.style.overflowY = 'hidden';
  //     setShowPaymentsUnity(true);
  //   } else {
  //     /* Dynamically unlock scrolling background */
  //     document.body.style.overflowY = 'initial';
  //     setShowPaymentsUnity(false);
  //     stores.setStateDisplayedPaymentModal(null);
  //   }
  // }, [stores.isShowPaymentsUnity]);

  useEffect(async () => {
    if (
      (stores.isAuthorized && stores.anonymousClient) ||
      stores.updateStateBearCreated ||
      stores.isChangedAccountInWallet
    ) {
      const bearCreated = await isBearCreated();
      stores.setBearCreated(bearCreated);
      // console.log('bearCreated >> ', bearCreated);
    }
  }, [
    stores.isAuthorized,
    stores.isChangedAccountInWallet,
    stores.anonymousClient,
    stores.updateStateBearCreated,
  ]);

  /* Smooth Scroll for anchor links */
  useEffect(() => {
    const smoothScrollId = (hash) => {
      if (!hash) return;
      const arrHref = hash.split('anchor_header_');
      if (arrHref.length > 1) {
        const targetId = document.getElementById(arrHref[1]);
        let yOffset = 0;
        const { isMobile } = stores.browserInfo;
        /* Anchors logic */
        if (isDesktopSize) {
          switch (arrHref[1]) {
            case 'explorer':
              yOffset = isMobile ? 0 : -100;
              break;
            case 'benefits':
              yOffset = isMobile ? 0 : -100;
              break;
            case 'tokenomics':
              yOffset = isMobile ? 0 : -100;
              break;
            case 'how-to-play':
              yOffset = isMobile ? 0 : -100;
              break;
            case 'our-team':
              yOffset = isMobile ? 0 : -100;
              break;
            case 'road-map':
              yOffset = isMobile ? 0 : -100;
              break;
            case 'selling-pack':
              yOffset = isMobile ? 0 : -100;
              break;
            case 'bee-day-pack':
              yOffset = isMobile ? 0 : -100;
              break;
            case 'faq':
              yOffset = isMobile ? 0 : -100;
              break;
            case 'game-info':
              yOffset = isMobile ? 0 : -100;
              break;
            case 'our-partners':
              yOffset = isMobile ? 0 : -100;
              break;
            default:
              yOffset = isMobile ? 0 : -100;
          }
        } else {
          yOffset = 0;
        }

        if (targetId) {
          const y = targetId.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }
    };

    smoothscroll.polyfill();

    document.addEventListener('click', (e) => {
      smoothScrollId(e.target.id);
    });

    return () =>
      document.removeEventListener('click', (e) => {
        smoothScrollId(e.target.id);
      });
  }, [isDesktopSize]);

  /* #selling-pack hash logic */
  useEffect(() => {
    if (window.location.hash === '#selling-pack' && stores.paramsForObjectsBlockchain) {
      const { isMobile } = stores.browserInfo;
      const targetId = document.getElementById('selling-pack');
      const yOffset = isMobile ? 0 : -111;
      const y = targetId.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({
        top: y,
        behavior: 'smooth',
      });
    }
  }, [stores.paramsForObjectsBlockchain]);

  // choose Honey_pack_1 and indicate to show this pack
  // useEffect(() => {
  //   (async () => {
  //     if (!stores.isAccountAccess) {
  //       stores.setOpenModal(ENUM_MODAL.VERIFICATION_ACCESS_WALLET);
  //     } else
  //     if (stores.isAnonymousClientConfigured) {
  //       await getPacksAndBlockchainObjects({ afterHandler: (result) => {
  //         const packs = savePacks({ result });
  //         const pack = packs.packArrayHoney[0];

  //         stores.setStateDisplayedPaymentModal({ packInfo: pack });
  //         stores.showPaymentsHoneyUnity(true);
  //       }})
  //     }
  //   })();
  //   }, [stores.isAccountAccess]);
    
  // show PaymentsHoneyUnity
  // useEffect(() => {
  //   if (stores.isShowPaymentsHoneyUnity && stores.stateDisplayedPaymentModal) {
  //     /* Dynamically unlock scrolling background */
  //     document.body.style.overflowY = 'hidden';
  //     setShowPaymentsHoneyUnity(true);
  //   } else {
  //     /* Dynamically unlock scrolling background */
  //     document.body.style.overflowY = 'initial';
  //     setShowPaymentsHoneyUnity(false);
  //     stores.setStateDisplayedPaymentModal(null);
  //   }
  // }, [stores.isShowPaymentsHoneyUnity]);


  const closeModal = (name) => {
    /* Dynamically unlock scrolling background */
    document.body.style.overflowY = 'initial';
    switch (name) {
      case 'payments':
        stores.showPayments(false);
        break;
      case 'payments_honey_payments':
        stores.showPaymentsHoneyUnity(false);
        break;
      case 'payments_unity':
        stores.showPaymentsUnity(false);
        break;
      default:
        break;
    }
  };

  return (
    <Provider store={StoreInstance}>
      {stores.isMaintenanceMode ? (
        <>
          <BeeSpinner classNames={{ Container: styles.ContainerSpinner }} />
          <p className={styles.MaintenanceText}>{stores.translate('maintenance_mode')}</p>
        </>
      ) : (
        <>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<HomePage />} exact />
              <Route path="/changelog" element={<ChangelogPage />} exact />
              <Route path="/about" element={<AboutPage />} exact />
              <Route path="/about:other" element={<Navigate to="/about" />} />
              <Route path="/partnership" element={<PartnershipPage />} exact />
              <Route path="/partnership:other" element={<Navigate to="/partnership" />} />
              <Route path="/tokenomics" element={<TokenomicsPage />} exact />
              <Route path="/tokenomics:other" element={<Navigate to="/tokenomics" />} />
              <Route path="/faq" element={<FaqPage />} exact />
              <Route path="/faq:other" element={<Navigate to="/faq" />} />
              <Route path="/signup" element={<SignUpPage />} />
              <Route path="/signup:other" element={<Navigate to="/signup" />} />
              <Route
                path="/game"
                element={
                  <PrivateRoute>
                    <GamePage />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/account"
                element={
                  <PrivateRoute isShowWalletAccessModal>
                    <AccountPage />
                  </PrivateRoute>
                }
              />
              <Route path="/account:other" element={<Navigate to="/account" />} />
              <Route
                path="/transactions"
                element={
                  <PrivateRoute isShowWalletAccessModal>
                    <TransactionsPage />
                  </PrivateRoute>
                }
                exact
              />
              <Route path="/transactions:other" element={<Navigate to="/transactions" />} />
              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
            <Modal />
            {!stores.isGame && (
              <>
                <PopUp />
              </>
            )}
          </BrowserRouter>
          {/* <KeplerSection /> */}
          {isShowPayments && <PaymentsModalMobile close={() => closeModal('payments')} />}
          {/* {isShowPaymentsHoneyUnity && <PaymentsHoneyUnity close={() => closeModal('payments_honey_payments')} />} */}
          {/* {isShowPaymentsUnity && <HalloweenModal close={() => closeModal('payments_unity')} />} */}
        </>
      )}
      <audio id="music"></audio>
    </Provider>
  );
});

export default App;
