import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { useStores } from 'src/stores';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Button, ColorType, SizeButton } from '@components_new/Button';
import InputWord from '@components/InputWord';
import Input from '@components/Input';
import authorization from '@utils/authorization';
import { COUNT_WORD_SEED_PHRASE } from '@constants/system';
import { ENUM_MODAL } from '@components_new/Modal/enumModal';
import Logger from '@utils/logger';
import styles from './loginModalMobile.module.scss';

const regExMnemonic = /^[a-z]+$/g;

const LoginModalMobile = observer(({ close }) => {
  const navigate = useNavigate();
  const stores = useStores();
  const [isLoading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [isUpdateInput, setUpdateInput] = useState(false);

  const schemaMnemonicWordsModal = yup.object().shape({
    word1: yup
      .string('')
      .transform((value) => value.trim())
      .matches(regExMnemonic, 'Only symbols: a-z')
      .required('Mnemonic Word is required.'),
    word2: yup
      .string('')
      .transform((value) => value.trim())
      .matches(regExMnemonic, 'Only symbols: a-z')
      .required('Mnemonic Word is required.'),
    word3: yup
      .string('')
      .transform((value) => value.trim())
      .matches(regExMnemonic, 'Only symbols: a-z')
      .required('Mnemonic Word is required.'),
    word4: yup
      .string('')
      .transform((value) => value.trim())
      .matches(regExMnemonic, 'Only symbols: a-z')
      .required('Mnemonic Word is required.'),

    word5: yup
      .string('')
      .transform((value) => value.trim())
      .matches(regExMnemonic, 'Only symbols: a-z')
      .required('Mnemonic Word is required.'),
    word6: yup
      .string('')
      .transform((value) => value.trim())
      .matches(regExMnemonic, 'Only symbols: a-z')
      .required('Mnemonic Word is required.'),
    word7: yup
      .string('')
      .transform((value) => value.trim())
      .matches(regExMnemonic, 'Only symbols: a-z')
      .required('Mnemonic Word is required.'),
    word8: yup
      .string('')
      .transform((value) => value.trim())
      .matches(regExMnemonic, 'Only symbols: a-z')
      .required('Mnemonic Word is required.'),

    word9: yup
      .string('')
      .transform((value) => value.trim())
      .matches(regExMnemonic, 'Only symbols: a-z')
      .required('Mnemonic Word is required.'),
    word10: yup
      .string('')
      .transform((value) => value.trim())
      .matches(regExMnemonic, 'Only symbols: a-z')
      .required('Mnemonic Word is required.'),
    word11: yup
      .string('')
      .transform((value) => value.trim())
      .matches(regExMnemonic, 'Only symbols: a-z')
      .required('Mnemonic Word is required.'),
    word12: yup
      .string('')
      .transform((value) => value.trim())
      .matches(regExMnemonic, 'Only symbols: a-z')
      .required('Mnemonic Word is required.'),
  });

  const schemaNeedFieldLoginModal = yup.object().shape({
    password: yup.string('').min(5, stores.translate('password_input_validation')),
  });

  const MnemonicWordsForm = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schemaMnemonicWordsModal),
  });

  const NeedFieldsForm = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schemaNeedFieldLoginModal),
  });

  const words = MnemonicWordsForm.watch();
  const needFields = NeedFieldsForm.watch();

  const arrValues = new Array(COUNT_WORD_SEED_PHRASE).fill(undefined);
  const inputsRef = new Array(COUNT_WORD_SEED_PHRASE).fill(undefined).map(() => useRef());

  const closeModal = () => {
    MnemonicWordsForm.reset();
    NeedFieldsForm.reset();
    close();
  };

  const onNeedFieldsClick = () => {};

  const onLoginClick = async () => {
    if (!_.isEmpty(NeedFieldsForm.formState.errors)) return;
    setLoading(true);
    try {
      const mnemonic = Object.values(words)
        .map((word) => word.trim())
        .join(' ');
      await authorization.login({
        mnemonic,
        password: needFields.password,
        setErrorText,
        closeModal,
        navigate,
      });
    } catch (err) {
      setErrorText(err);
      Logger.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleKeypress = (e) => {
    if (e.key && e.key.toLowerCase() === 'enter') {
      NeedFieldsForm.handleSubmit(onNeedFieldsClick)();
      MnemonicWordsForm.handleSubmit(onLoginClick)();
    }
  };

  const onMnemonicPhraseInput = (phrase) => {
    inputsRef.forEach((inputRef, index) => {
      inputRef.current.value = phrase[index];
      inputRef.current.value = phrase[index];
      MnemonicWordsForm.setValue(`word${index + 1}`, phrase[index]);
    });
    setUpdateInput(true);
  };

  const onPasteClick = async () => {
    const textBuffer = await navigator.clipboard.readText();
    const arrBuffer = textBuffer.trim().split(' ');
    if (arrBuffer && arrBuffer.length === 12) {
      inputsRef.forEach((inputRef, index) => {
        inputRef.current.value = arrBuffer[index];
        inputRef.current.value = arrBuffer[index];
        MnemonicWordsForm.setValue(`word${index + 1}`, arrBuffer[index]);
      });
      setUpdateInput(true);
    } else {
      Logger.infoNotify('not correct seed phrase, phrase must have 12 word');
    }
  };

  const onBackClick = () => {
    close();
    stores.setOpenModal(ENUM_MODAL.JOIN);
  };

  return (
    <>
      <p className={styles.EnterSeedPhraseTitle}>{stores.translate('enter_seed_phrase')}</p>
      <div className={styles.SeedPraseContainerWrapper}>
        <div className={styles.SeedPraseContainer}>
          {arrValues.map((word, index) => {
            return (
              <InputWord
                // eslint-disable-next-line react/no-array-index-key
                key={`seed_phrase_${index}`}
                name={`word${index + 1}`}
                register={MnemonicWordsForm.register}
                isError={MnemonicWordsForm.formState.errors[`word${index + 1}`]}
                ref={inputsRef[index]}
                isUpdateInput={isUpdateInput}
                onMnemonicPhrase={onMnemonicPhraseInput}
                classNames={{
                  InputContainer: styles.WordInput_InputContainer,
                  Input: classnames(
                    styles.WordInput_Input,
                    (index + 1) % 4 === 0 && styles.WordInput_Input__noRightBorder,
                  ),
                }}
              />
            );
          })}
        </div>
      </div>
      <div className={styles.PaddingInnerContainer}>
        {!_.isEmpty(MnemonicWordsForm.formState.errors) && (
          <p className={styles.EnterAllWordsTitle}>
            {stores.translate('enter_all_words_seed_phrase')}
          </p>
        )}
        {!_.isEmpty(errorText) && <p className={styles.EnterAllWordsTitle}>{errorText}</p>}
        <div className={styles.PasteButtonContainer}>
          <Button
            colorType={ColorType.ORANGE}
            size={SizeButton.SMALL}
            onClick={async () => {
              await onPasteClick();
            }}
            title={stores.translate('paste')}
            classNames={{
              Button: styles.Button,
              Title: classnames(
                styles.ButtonTitle,
                styles.ButtonTitle__Small,
                styles.ButtonTitle__Orange,
              ),
            }}
            disabled={isLoading}
          />
        </div>
        <p className={styles.ImportantTitle}>
          {stores.translate('important')}&nbsp;
          <span className={styles.ImportantTitle__description}>
            {stores.translate('wont_login_without_seed_phrase')}
          </span>
        </p>
        <div className={styles.InputContainerWrapper}>
          <Input
            placeholder={stores.translate('password_input_placeholder')}
            canError
            label={stores.translate('password')}
            error={NeedFieldsForm.formState.errors?.password}
            name="password"
            register={NeedFieldsForm.register}
            autoComplete="no"
            isButtonSecretShow
            onKeyDown={handleKeypress}
            classNames={{
              Label: styles.LabelInput,
              InputContainer: styles.Input_InputContainer,
              Container: styles.Input_Container,
              Input: styles.Input_Input,
              InputButtonShow: styles.Input_InputButtonShow,
            }}
          />
        </div>
        <div className={styles.ButtonContainer}>
          <Button
            colorType={ColorType.ORANGE}
            size={SizeButton.MEDIUM}
            onClick={() => {
              onBackClick();
            }}
            title={stores.translate('back')}
            classNames={{
              Container: styles.BackButton,
              Button: styles.Button,
              Title: classnames(styles.ButtonTitle, styles.ButtonTitle__Orange),
            }}
          />
          <Button
            colorType={ColorType.GREEN}
            size={SizeButton.MEDIUM}
            onClick={() => {
              NeedFieldsForm.handleSubmit(onNeedFieldsClick)();
              MnemonicWordsForm.handleSubmit(onLoginClick)();
            }}
            title={stores.translate('login')}
            classNames={{
              Container: styles.LoginButton,
              Button: styles.Button,
              Title: classnames(styles.ButtonTitle, styles.ButtonTitle__Green),
            }}
            isLoading={isLoading}
            disabled={isLoading}
          />
        </div>
      </div>
    </>
  );
});

export default LoginModalMobile;
