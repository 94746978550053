import { GameMethodNames, GameObjectNames } from 'src/stores/unity';
import { StoreInstance } from 'src/stores';

const stores = StoreInstance;

const successfulReceiveBonus = () => {
  stores.unityStore.send({
    objectName: GameObjectNames.DailyBonus,
    methodName: GameMethodNames.SuccessfulReceiveBonus,
  });
};

export default {
  successfulReceiveBonus,
};
