import React, { useEffect, useRef, useState } from 'react';
/* eslint-disable-next-line import/no-named-default */
import { default as classnames } from 'classnames';
// import PropTypes from 'prop-types';

import styles from './informationPopup.module.scss';

/**
 * Information popup with absolute position in bottom: 0. Popup has default style for text:
 *   font-weight: 500;
 *   font-size: 16px;
 *   color: red-brown color;
 *
 * @param info - The string that will show in popup
 * @param classNames - The object consist of style for 'Container' and 'Text'
 */
export const InformationPopup = ({ info, classNames = {} }) => {
  const [width, setWidth] = useState(50);
  const textRef = useRef();

  useEffect(() => {
    if (textRef && textRef.current) {
      setWidth(textRef.current.clientWidth + 10 * 2);
    }
  }, [textRef]);

  return (
    <div className={classnames(styles.Container, classNames.Container)} style={{ width }}>
      <p ref={textRef} className={classnames(styles.Text, classNames.Text)}>
        {info}
      </p>
    </div>
  );
};

// InformationPopup.propTypes = {
//   info: PropTypes.string.isRequired,
//   classNames: PropTypes.object
// }

// InformationPopup.defaultProps = {
//   classNames: {}
// }

export default InformationPopup;
