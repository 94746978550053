/* eslint-disable no-unused-vars */
/* eslint-disable */
import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import TitleModal from '@components/TitleModal';

import Close from '@src/assets/control/close.svg';

import { observer } from 'mobx-react';
import { useStores } from 'src/stores';
import { GameMethodNames, GameObjectNames } from 'src/stores/unity';
import { useMediaQuery } from 'src/helpers/media';
import { BREAK_APPROVE_TRANSACTION } from '@constants/blockchain';

import ContactUsModal from '@layout/components/ContactUsModal';
import JoinModal from '@layout/components/JoinModal';
import CreateSeedPhraseModal from 'src/layout/components/CreateSeedPhraseModal';
import LoginModal from 'src/layout/components/LoginModal';
import VerificationWalletAccessModal from 'src/layout/components/VerificationWalletAccessModal';
import ChangeNicknameModal from 'src/layout/components/ChangeNicknameModal';
import ChangeAvatarModal from 'src/layout/components/ChangeAvatarModal';
import ApproveTransactionModal from 'src/layout/components/ApproveTransactionModal';
import StackingUnityModal from '@layout/components/StackingUnityModal';
import HalloweenModal from '@layout/components/HalloweenModal';

import Logger from '@utils/logger';
import styles from './modal.module.scss';
import { ENUM_MODAL } from './enumModal';
import _ from 'lodash';

const modalComponents = {
  // CONTACT_US: ContactUsModal,
  // JOIN: JoinModal,
  // SEED_PHRASE: CreateSeedPhraseModal,
  // LOGIN: LoginModal,
  // VERIFICATION_ACCESS_WALLET: VerificationWalletAccessModal,
  // CHANGE_NICKNAME: ChangeNicknameModal,
  // CHANGE_AVATAR: ChangeAvatarModal,
  // PAYMENTS: <>'payments'</>,

  // UNITY_STACKING: <>'unity-stake'</>,
  UNITY_APPROVE_TRANSACTION: ApproveTransactionModal,
  UNITY_VERIFY_YOURSELF: ApproveTransactionModal,
  UNITY_CHECK_IN_DAILY_BONUS: ApproveTransactionModal,
  UNITY_STACKING: StackingUnityModal,
  UNITY_UNSTACKING: StackingUnityModal,
  [ENUM_MODAL.UNITY_HALLOWEEN]: HalloweenModal,
};

export const SizeTitleModal = {
  Large: 'Large',
  Medium: 'Medium',
  Small: 'Small',
};

export const Modal = observer(() => {
  const width1000 = useMediaQuery('(max-width: 1000px)');
  const stores = useStores();
  const [isOpen, setOpen] = useState(false);
  const [nameModal, setNameModal] = useState('');
  const [ChildModal, setChildModal] = useState({});
  const [isShowTextOuterDescription, setShowTextOuterDescription] = useState(false);

  const updateTitleModal = (name) => {
    switch (name) {
      case ENUM_MODAL.CONTACT_US:
        return stores.translate('contact_us');
      case ENUM_MODAL.JOIN:
        return stores.translate('welcome_to_honeywood');
      case ENUM_MODAL.SEED_PHRASE:
        return stores.translate('glad_to_see_you');
      case ENUM_MODAL.LOGIN:
        return stores.translate('do_you_remember_seed_phrase');
      case ENUM_MODAL.VERIFICATION_ACCESS_WALLET:
        return stores.translate('verification_wallet_access');
      case ENUM_MODAL.CHANGE_NICKNAME:
        return stores.translate('enter_new_nickname');
      case ENUM_MODAL.CHANGE_AVATAR:
        return stores.translate('choose_your_bear_avatar');
      // case ENUM_MODAL.PAYMENTS: return stores.translate('payments');

      // case ENUM_MODAL.UNITY_STACKING: return stores.translate('payments');
      case ENUM_MODAL.UNITY_APPROVE_TRANSACTION:
        return stores.translate('approve_transaction');
      case ENUM_MODAL.UNITY_VERIFY_YOURSELF:
        return stores.translate('verify_yourself');
      case ENUM_MODAL.UNITY_CHECK_IN_DAILY_BONUS:
        return stores.translate('verify_yourself');
      case ENUM_MODAL.UNITY_STACKING:
        return stores.translate('stake_cones');
      case ENUM_MODAL.UNITY_UNSTACKING:
        return stores.translate('unstake_cones');
      case ENUM_MODAL.UNITY_HALLOWEEN:
        return stores.translate('halloween');
      default:
        return 'default';
    }
  };

  const updateSizeTitleModal = () => {
    switch (nameModal) {
      case ENUM_MODAL.CONTACT_US:
        return width1000 ? SizeTitleModal.Small : SizeTitleModal.Large;
      case ENUM_MODAL.JOIN:
        return width1000 ? SizeTitleModal.Medium : SizeTitleModal.Large;
      case ENUM_MODAL.SEED_PHRASE:
        return width1000 ? SizeTitleModal.Medium : SizeTitleModal.Large;
      case ENUM_MODAL.LOGIN:
        return width1000 ? SizeTitleModal.Large : SizeTitleModal.Medium;
      case ENUM_MODAL.CHANGE_NICKNAME:
        return width1000 ? SizeTitleModal.Medium : SizeTitleModal.Large;
      case ENUM_MODAL.CHANGE_AVATAR:
        return width1000 ? SizeTitleModal.Medium : SizeTitleModal.Large;
      // case ENUM_MODAL.VERIFICATION_ACCESS_WALLET: return stores.translate('verification_wallet_access');
      // case ENUM_MODAL.CHANGE_NICKNAME: return stores.translate('enter_new_nickname');
      // case ENUM_MODAL.PAYMENTS: return stores.translate('payments');

      case ENUM_MODAL.UNITY_APPROVE_TRANSACTION:
        return width1000 ? SizeTitleModal.Medium : SizeTitleModal.Large;
      case ENUM_MODAL.UNITY_VERIFY_YOURSELF:
        return width1000 ? SizeTitleModal.Small : SizeTitleModal.Large;
      case ENUM_MODAL.UNITY_CHECK_IN_DAILY_BONUS:
        return width1000 ? SizeTitleModal.Medium : SizeTitleModal.Large;
      case ENUM_MODAL.UNITY_STACKING:
      case ENUM_MODAL.UNITY_UNSTACKING:
        return width1000 ? SizeTitleModal.Medium : SizeTitleModal.Large;
      default:
        return SizeTitleModal.Large;
    }
  };

    const updateDescriptionOuter = (name) => {
    switch (name) {
      case ENUM_MODAL.UNITY_VERIFY_YOURSELF:
        setShowTextOuterDescription(true);
        break;
      default:
        setShowTextOuterDescription(false);
        break;
    }
  };

  const isUnityGame = () => {
    if (
      nameModal === ENUM_MODAL.UNITY_APPROVE_TRANSACTION ||
      nameModal === ENUM_MODAL.UNITY_VERIFY_YOURSELF ||
      nameModal === ENUM_MODAL.UNITY_CHECK_IN_DAILY_BONUS ||
      nameModal === ENUM_MODAL.UNITY_STACKING ||
      nameModal === ENUM_MODAL.UNITY_UNSTACKING ||
      nameModal === ENUM_MODAL.UNITY_HALLOWEEN
    ) {
      return true;
    }
    // return false;
    //   ? true
    //   : false;
  };
  // nameModal === ENUM_MODAL.UNITY_APPROVE_TRANSACTION ||
  // nameModal === ENUM_MODAL.UNITY_VERIFY_YOURSELF
  //   ? true
  //   : false;

  useEffect(() => {
    if (stores.openModal) {
      if (Object.values(ENUM_MODAL).includes(stores.openModal)) {
        let changedChildModal = modalComponents[stores.openModal];
        setChildModal(changedChildModal);
      } else {
        setChildModal({});
        return;
        // not show Modal if not exist in enum 'ENUM_MODAL'
      }

      setNameModal(stores.openModal);
      updateDescriptionOuter(stores.openModal);
      /* Dynamically lock scrolling background */
      setTimeout(() => {
        document.body.style.overflowY = 'hidden';
      }, 100);
      setOpen(true);
    } else {
      /* Dynamically unlock scrolling background */
      document.body.style.overflowY = 'initial';
      setOpen(false);
    }
  }, [stores.openModal]);

  const close = (stateClose) => {
    // true - tap on button crest, outside modal screen
    if (stateClose) {
      // if closed modal use crest and outside modal for clear state of payments
      stores.setStateDisplayedPaymentModal(null);
      switch (nameModal) {
        case ENUM_MODAL.UNITY_CHECK_IN_DAILY_BONUS:
        case ENUM_MODAL.UNITY_VERIFY_YOURSELF:
        case ENUM_MODAL.UNITY_APPROVE_TRANSACTION:
          stores.unityStore.setApproveTransaction(BREAK_APPROVE_TRANSACTION);
          // console.log('update approve transaction >>')
          break;
        case ENUM_MODAL.UNITY_STACKING:
          stores.unityStore.setConesStacking(0);
          stores.unityStore.send({
            objectName: GameObjectNames.FarmReact,
            methodName: GameMethodNames.FinishTransaction,
          });
          break;
        case ENUM_MODAL.UNITY_UNSTACKING:
          stores.unityStore.setObjectUnstacking({ cone: 0, validatorAddress: ''});
          stores.unityStore.send({
            objectName: GameObjectNames.FarmReact,
            methodName: GameMethodNames.FinishTransaction,
          });
          break;
        default:
          break;
      }
    }

    stores.setPropsForModal(null);
    stores.setOpenModal(null);
  };

  const clickScreenOutside = (e) => {
    if (
      e &&
      e.target &&
      e.target.className &&
      _.isString(e.target.className) &&
      e.target.className.includes('modal_ModalContainer')
    ) {
      if (!isUnityGame()) {
        close(true);
      }
    }
  };

  return (
    isOpen &&
    nameModal && (
      <div
        className={classnames(
          styles.ModalContainer,
          isUnityGame() && stores.browserInfo.isMobile && styles.ModalContainer__isUnityGameMobile,
        )}
        role="button"
        tabIndex={0}
        onClick={(e) => clickScreenOutside(e)}
        onKeyDown={() => {}}
      >
        <div
          className={classnames(
            styles.Modal,
            (nameModal === ENUM_MODAL.UNITY_VERIFY_YOURSELF && styles.Modal__WithDescription),
            (nameModal === ENUM_MODAL.UNITY_STACKING ||
              nameModal === ENUM_MODAL.UNITY_UNSTACKING) &&
              styles.Modal__Stacking,
            isUnityGame() && stores.browserInfo.isMobile && styles.Modal__isUnityGameMobile,
          )}
        >
          <TitleModal
            title={updateTitleModal(nameModal)}
            // classNames={{ Title: !isUnityGame() && styles.Title }}
            isUnityGame={isUnityGame()}
            size={updateSizeTitleModal()}
          />
          <button
            type="button"
            className={styles.ModalClose}
            onClick={() => {
              // gtagEvent(GOOGLE_ANALYTICS.CLOSE_MODAL);
              close(true);
            }}
          >
            <img
              onLoad={() => stores.increaseDownloadedImageCounter(Close)}
              onError={() => Logger.error('Error with image loading')}
              src={Close}
              alt="Close"
              loading="lazy"
            />
          </button>
          <ChildModal close={close} {...stores.propsForModal} />
        </div>
        {isShowTextOuterDescription && (
          <p className={styles.DescriptionText}>
            {stores.translate('description_verify_yourself')}
          </p>
        )}
      </div>
    )
  );
});

export default {
  Modal,
};
