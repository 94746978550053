import React, { useEffect } from 'react';
import HeadTags from '@components_new/HeadTags';
import { useMediaQuery } from '@src/helpers/media';
import MobilePartnershipPage from '@src/pages/PartnershipPage/MobilePartnershipPage';
import DesktopPartnershipPage from '@src/pages/PartnershipPage/DesktopPartnershipPage';
import BeeSpinner from '@components/BeeSpinner';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import styles from './partnership.module.scss';

const PartnershipPage = observer(() => {
  const stores = useStores();
  const isMobileSize = useMediaQuery('(max-width: 999.99px)');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HeadTags description="Partnership | Invest in the future. Put your time to good. HoneyWood is a browser game perfectly adapted for all devices: smartphone, tablet and desktop. Play anywhere! Earn by playing 5 minutes a day." />
      {stores.browserInfo.isMobile || (isMobileSize && !stores.browserInfo.isMobile) ? (
        <MobilePartnershipPage />
      ) : (
        <>
          {stores.isInitialPartnershipPageLoading && (
            <BeeSpinner classNames={{ Container: styles.Container }} />
          )}
          <DesktopPartnershipPage isInitialLoading={stores.isInitialPartnershipPageLoading} />
        </>
      )}
    </>
  );
});

export default PartnershipPage;
