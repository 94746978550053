import _ from 'lodash';
import { BEARS } from 'src/api';
import { stringify } from '@utils/common';
import { getValidatorsAddressWhiteList } from '@utils/mainBears';
import { GameMethodNames, GameObjectNames } from 'src/stores/unity';
import { MILLION } from '@constants/system';
import { DENOM_UHONEY } from '@constants/blockchain';
import { StoreInstance } from 'src/stores';
import Logger from '@utils/logger';
import methodsBears from '@utils/methodsBears';

import DynamicStatesMediator from './DynamicStatesMediator';
import { handleMainError } from '../HandleError';

const stores = StoreInstance;

const openScreenStaking = async () => {
  const { address } = stores.unityStore;
  const { stakedEntriesMax } = stores.unityStore;
  let entriesMax = 0;
  const responseAll = await Promise.all([
    BEARS.getTotalStakingRewards({ address }),
    BEARS.getStakedCones({ address, useSum: false }),
    BEARS.getUnstakingCones({ address }),
    BEARS.getUnstakingEntries({ address }),
    stakedEntriesMax === 0 && BEARS.getStakingParams(),
    DynamicStatesMediator.updateCONE(),
  ]);
  // const responseTotalHoney = await BEARS.getTotalStakingRewards({ address }); // uhoney
  // const responseStaked = await BEARS.getStakedCones({ address }); // cone
  // const responseUnstaked = await BEARS.getUnstakingCones({ address }); // cone
  // const responseUnstakedEntries = await BEARS.getUnstakingEntries({ address });
  const responseTotalHoney = responseAll[0]; // uhoney
  const responseStaked = responseAll[1]; // cone
  const responseUnstaked = responseAll[2]; // cone
  const responseUnstakedEntries = responseAll[3];
  entriesMax = stakedEntriesMax;
  if (stakedEntriesMax === 0) {
    // const responseParams = await BEARS.getStakingParams();
    const responseParams = responseAll[4];
    entriesMax = Number(responseParams.maxEntries);
  }
  Logger.infoMainWithKey('getStakedCones responseStaked', responseStaked);
  Logger.infoMainWithKey('getUnstakingCones responseUnstaked', responseUnstaked);
  Logger.infoMainWithKey('getTotalStakingRewards responseTotalHoney', responseTotalHoney);
  Logger.infoMainWithKey('getUnstakingEntries responseUnstakedProgress', responseUnstakedEntries);

  if (responseUnstakedEntries && responseUnstakedEntries.code > 0) {
    handleMainError(responseUnstakedEntries);
    return { isError: true, countMayUnstakedCones: -1, entriesMax };
  }
  let stackingHoney = 0;
  if (responseTotalHoney && responseTotalHoney.length > 0) {
    responseTotalHoney.forEach(reward => {
      if (reward.denom === DENOM_UHONEY) {
        stackingHoney = Number(reward.amount / MILLION).toFixed(6);
      }
    });
  }

  const coneInUse = JSON.parse(JSON.stringify(responseStaked)).reduce((sum, val) => {
    return sum + Number(val.balance.amount);
  }, 0);

  // const conesTotal = await DynamicStatesMediator.updateCONE();
  const conesTotal = responseAll[5];
  stores.unityStore.setStakedEntriesMax(Number(entriesMax));
  stores.unityStore.setCountConesForStacking(Number(conesTotal));
  stores.unityStore.setCountConesForUnstacking(Number(coneInUse));
  stores.unityStore.setArrayConesForUnstacking(responseStaked);
  
  stores.unityStore.send({
    objectName: GameObjectNames.FarmReact,
    methodName: GameMethodNames.UpdateScreenStacking,
    values: stringify({
      coneInUse,
      stackingHoney,
      unstakingCone: responseUnstaked,
      unstakingEntries: entriesMax - responseUnstakedEntries.entries.length,
    }),
  });
  return { isError: false, countMayUnstakedCones: responseStaked, entriesMax };
};

const collectStakingHoney = async () => {
  const { address } = stores.unityStore;
  const responseAddressValidator = await BEARS.getAddressValidators({ address });
  Logger.infoMainWithKey('getAddressValidators responseAddressValidator', responseAddressValidator);
  const validatorAddress = _.sample(responseAddressValidator.validators).operatorAddress;
  Logger.infoMainWithKey('collectStakingHoney validatorAddress', validatorAddress);
  const responseWithdraw = await BEARS.withdrawRewards({ validatorAddress });
  Logger.infoMainWithKey('withdrawRewards responseWithdraw', responseWithdraw);

  if (responseWithdraw.code > 0) {
    handleMainError(responseWithdraw);
    return { isError: true };
  }
  return openScreenStaking();
};

const stakeCones = async ({ cones, isUpdatedStatesStacking = true }) => {
  const { address } = stores.unityStore;
  const validatorAddress = await methodsBears.getValidatorAddress({ address });
  Logger.infoMainWithKey('stakeCones with address', address, { useInProduction: true });
  const responseStake = await BEARS.stakeCones({ address, validatorAddress, amount: cones });
  Logger.infoMainNotify({ nameNotify: 'stakeCones with address', section: 'DATA', meta: { address, responseStake }, useInProduction: true });
  Logger.infoMainWithKey('stakeCones responseStake', responseStake);
  if (responseStake.code > 0) {
    handleMainError(responseStake);
    return { isError: true };
  }
  if (isUpdatedStatesStacking) {
    return openScreenStaking();
  }
  return undefined;
};

const unstakeCones = async ({ cones, validatorAddress }) => {
  const { address } = stores.unityStore;
  // const responseAddressValidator = await BEARS.getAddressValidators({ address });
  // Logger.infoMainWithKey('getAddressValidators responseAddressValidator', responseAddressValidator);
  // const validatorAddress = _.sample(responseAddressValidator.validators).operatorAddress;
  // Logger.infoMainWithKey('unstakeCones validatorAddress', validatorAddress);
  // console.log('unstakeCones >> ', { cones, validatorAddress });
  const responseUnstake = await BEARS.unstakeCones({ address, validatorAddress, amount: cones });
  Logger.infoMainNotify({ nameNotify: 'unstakeCones with address', section: 'DATA', meta: { address, responseUnstake }, useInProduction: true });
  Logger.infoMainWithKey('unstakeCones responseUnstake', responseUnstake);
  if (responseUnstake.code > 0) {
    handleMainError(responseUnstake);
    return { isError: true };
  }
  return openScreenStaking();
};

export default {
  collectStakingHoney,
  stakeCones,
  unstakeCones,
  openScreenStaking,
};
