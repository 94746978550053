import URLS from "./urls";

export const APP_VERSION = '3.4.7';
export const DEFAULT_LANGUAGE = 'en';
export const DEFAULT_AVATAR = 'bear1';
export const LANGUAGE = 'language';
export const PRODUCTION = 'production';
export const DEVELOPMENT = 'development';
export const BETA = 'beta';
export const PRELIVE = 'prelive';
export const SIZE_INPUT_WORD = 30;
export const COUNT_WORD_SEED_PHRASE = 12;
export const COUNT_MILLISECONDS_LIFE_TIME_PASSWORD = 3 * 60 * 60 * 1000; // duration 3 hour of life time access to wallet
export const COUNT_MILLISECONDS_LIFE_TIME_SIGNATURE = 59 * 60 * 1000; // duration 59 min of life time signature transaction Keplr
export const COUNT_MILLISECONDS_LIFE_TIME_PASSWORD_STORE = 3 * 60 * 1000; // duration 3 min of life time password
export const SEED_PHRASE = 'SEED_PHRASE';
export const PASSWORD = 'PASSWORD';
export const PROFILE = 'PROFILE';
export const HONEY_WOOD = 'HONEY_WOOD';
export const MONETA_HONEY_WOOD = 'MONETA_HONEY_WOOD';
export const SKIP_EVENT_PACK = 'SKIP_EVENT_PACK';

export const ENCRYPT_PROFILE = 'U2FsdGVkX1/Lj58D/jrViw1FJAq0z4uKzG08+jcJhtY=';
export const ENCRYPT_SEED_PHRASE = 'U2FsdGVkX18c4K++M6ljInS1dP5LPSqk+OPDiFPVWCs=';
export const ENCRYPT_WALLET = 'U2FsdGVkX19xjeg6Qy4FYf5aDd2Wm5Zn2Ct/S88g0c8=';
export const APP_STAGE = process.env.REACT_APP_STAGE === PRODUCTION ? PRODUCTION : DEVELOPMENT;
export const STORAGE_NAMESPACE =
  process.env.REACT_APP_STAGE === PRODUCTION ? 'account' : `account.${BETA}`;
export const MILLION = 1000000;
export const COST_CHANGE_NICKNAME = 100;
export const COST_COMMISSION = 0.01;

export const QUANTITY_DOWNLOADED_RESOURCES = {
  HOME_PAGE: 58, // 73-with packs; 85 for full images load(with presale pack's images)
  ABOUT_PAGE: 26, // 69 old - roadmap
  FAQ_PAGE: 40,
  PARTNERSHIP_PAGE: 48,
  TOKENOMICS_PAGE: 27,
  ACCOUNT_PAGE: 19, // ;with packs - 34, // 40 for full images load(with presale pack's images)
  TRANSACTIONS_PAGE: 1,
};

export const INFO_ACCOUNT =
  process.env.REACT_APP_STAGE === PRODUCTION ? 'honeywood.info' : `${BETA}.honeywood.info`;

export const RPC_URL_PROD = 'https://honeywood.io/node';

export const RPC_URL =
  process.env.REACT_APP_STAGE === PRODUCTION
    ? 'https://honeywood.io/node'
    : 'https://dev.honeywood.io/node';
// export const RPC_URL = 'https://honeywood.io/node';
// ? 'https://dev.honeywood.io/node'
// : `${window.location.origin}/node`;

// export const HOST = !process.env.REACT_APP_STAGE
//   ? 'https://dev.honeywood.io/api'
//   : `${window.location.origin}/api`;

export const URL_SERVER = 'https://explorer.honeywood.io/';
// export const URL_SERVER_MAIN = process.env.REACT_APP_STAGE === PRODUCTION ? URLS.LANDING : URLS.DEV_HOST;
//! change URL_SERVER_MAIN for success transaction local server or unity editor
//! @roman, @melekhin
// URL_SERVER_MAIN = '' - local checking || dev site || prod site
// URL_SERVER_MAIN = URLS.DEV_HOST - unity editor

export const URL_SERVER_MAIN = !process.env.REACT_APP_STAGE ? URLS.DEV_HOST : '';
// export const URL_SERVER_MAIN = '';

export const ROADMAP_STEP_IS_DONE = [
  {
    description: 'Development and testing',
    icon: 'Done',
    id: '1',
  },
  {
    description: 'Game Website Launch',
    icon: 'Done',
    id: '2',
  },
  {
    description: 'Pre private round',
    icon: 'Done',
    id: '3',
  },
  {
    description: 'Game release',
    icon: 'Done',
    id: '4',
  },
  {
    description: 'Bee Fest, present new play mechanics',
    icon: 'Done',
    id: '5',
  },
  {
    description: 'Private round #1',
    icon: 'ActiveConeCoins',
    id: '6',
  },
  {
    description: 'Start of the Ambassador Program',
    icon: 'Done',
    id: '7',
  },
  {
    description: 'IGO',
    icon: 'Marketplace',
    id: '8',
  },
  {
    description: 'Private round 2',
    icon: 'Marketplace',
    id: '9',
  },
  {
    description: 'Validation and voting platform',
    icon: 'Voting',
    id: '10',
  },
  {
    description: 'Audit of our game systems',
    icon: 'ConeCoins',
    id: '11',
  },
  {
    description: 'Listing HoneyCoin',
    icon: 'Exchange',
    id: '12',
  },
  {
    description: 'Marketplace and Bee NFTs',
    icon: 'Marketplace',
    id: '13',
  },
  {
    description: 'Listing CONE',
    icon: 'Exchange',
    id: '14',
  },
  {
    description: 'The updated version and adding new features to the gameplay',
    icon: 'Game',
    id: '15',
  },
  {
    description: 'Decentralization of the game',
    icon: 'Decentralization',
    id: '16',
  },
];

export const ROADMAP_MOBILE_STEP_IS_DONE = [
  {
    description: 'Development and testing',
    icon: 'DevelopmentBlack',
    id: '1',
  },
  {
    description: 'Game Website Launch',
    icon: 'WebsiteBlack',
    id: '2',
  },
  {
    description: 'Pre private round',
    icon: 'PrePrivateBlack',
    id: '3',
  },
  {
    description: 'Game release',
    icon: 'GameBlack',
    id: '4',
  },
  {
    description: 'Bee Fest, present new play mechanics',
    icon: 'PrePrivateBlack',
    id: '5',
  },
  {
    description: 'Start of the Ambassador Program',
    icon: 'Ambassador',
    id: '6',
  },
  {
    description: 'Start of the selling of CONES',
    icon: 'Igo',
    id: '7',
  },
  {
    description: 'Bitmart and Opensea NFT sales',
    icon: 'Igo',
    id: '8',
  },
  {
    description: 'Integrate HoneyCoin and CONE in Moneta Today mobile application',
    icon: 'Audit',
    id: '9',
  },
  {
    description: 'Make new game mechanics and new architecture of the game',
    icon: 'Game',
    id: '10',
  },
  {
    description: 'Enable HoneyCoin transfer between users using Moneta Today wallet',
    icon: 'Audit',
    id: '11',
  },
  {
    description: 'Audit of blockchain',
    icon: 'Audit',
    id: '12',
  },
  {
    description: 'Listing CONE',
    icon: 'Igo',
    id: '13',
  },
  {
    description: 'Mobile App publication',
    icon: 'Game',
    id: '14',
  },
  {
    description: 'New levels in match3',
    icon: 'Game',
    id: '15',
  },
  {
    description: 'Voting platform',
    icon: 'Voting',
    id: '16',
  },
  {
    description: 'Marketplace and Bee NFTs',
    icon: 'Marketplace',
    id: '17',
  },
];

export const PERSONAL_SOCIALS_LINK = {
  RomanC: [
    { name: 'telegram', link: 'http://t.me/romachi' },
    // { name: 'facebook', link: 'https://www.facebook.com/rm.chizhikov' },
    { name: 'linkedin', link: 'https://www.linkedin.com/in/r-ch' },
  ],
  AlexB: [
    { name: 'telegram', link: 'https://t.me/avb21' },
    // { name: 'twitter', link: 'https://twitter.com/crypt0_0bear' },
    { name: 'linkedin', link: 'https://www.linkedin.com/in/alex-bulbenov/ ' },
  ],
  GeorgeM: [
    { name: 'telegram', link: 'http://t.me/gvmosk' },
    // { name: 'instagram', link: 'https://www.instagram.com/gvmosk' },
    // { name: 'facebook', link: 'https://www.facebook.com/george.moskalev' },
    { name: 'linkedin', link: 'https://www.linkedin.com/in/george-moskalev-86abab130/' },
  ],
  AndreyB: [
    { name: 'telegram', link: 'https://t.me/busygin1' },
    // { name: 'instagram', link: 'https://www.instagram.com/andrey.busygin/' },
    // { name: 'facebook', link: 'https://www.facebook.com/andreybusy/' },
    { name: 'linkedin', link: 'https://www.linkedin.com/in/andrew-busygin-9a09881b8/' },
  ],
  OlgaS: [
    // { name: 'telegram', link: 'https://t.me/olgasenyut' },
    // { name: 'facebook', link: 'https://www.facebook.com/olga.senyut' },
    // { name: 'twitter', link: 'https://twitter.com/OlgaSenyut' },
    { name: 'linkedin', link: 'https://www.linkedin.com/in/olga-senyut-93351732/' },
  ],
  TimN: [],
  DmitryS: [
    // { name: 'facebook', link: 'https://www.facebook.com/DimmuShu/' },
    { name: 'linkedin', link: 'https://www.linkedin.com/in/dmitry-shubin-0a04b878/' },
  ],
  MichaelB: [
    // { name: 'telegram', link: 'https://t.me/OneMikhail' },
    // { name: 'instagram', link: 'https://www.instagram.com/1nemisha/' },
  ],
  PouloT: [],
  MichaelR: [],
  AntonR: [
    // { name: 'telegram', link: 'https://t.me/Russkin_anton' },
    // { name: 'instagram', link: 'https://www.instagram.com/_russkin13_/' },
  ],
  ElenaB: [
    // { name: 'telegram', link: 'https://t.me/Helen_Blb' },
    // { name: 'facebook', link: 'https://www.facebook.com/profile.php?id=100001758125424' },
    { name: 'linkedin', link: 'https://www.linkedin.com/in/elena-bulbenova-57b91886/' },
  ],
  // MariaC: [{ name: 'linkedin', link: 'https://www.linkedin.com/in/maria-chernoyarova-534b781b2/' }],
  AntonC: [{ name: 'linkedin', link: 'https://www.linkedin.com/in/anton-chepurnenko-488870233/' }],
  AlexanderS: [
    { name: 'linkedin', link: 'https://www.linkedin.com/in/aleksandr-shishkov-11814a220/' },
  ],
  MikhailN: [{ name: 'linkedin', link: 'https://www.linkedin.com/in/mikhailnikitenko/' }],
  RichardB: [{ name: 'linkedin', link: 'https://www.linkedin.com/in/richard-boccius-2a0b92222/' }],
  KostyaT: [{ name: 'instagram', link: 'https://instagram.com/kostyatszyu?igshid=MjEwN2IyYWYwYw==' }],
  VladimirU: [{ name: 'twitter', link: 'https://twitter.com/ponimajushij' }],
  IgorT: [{ name: 'telegram', link: 'https://t.me/monetatoday888' }],
  KostyaM: [{ name: 'telegram', link: 'https://t.me/k_melekhin' }],
};

/* export const ITEMS_DESCRIPTION = {
    BEE_HOUSE_DESCRIPTION: '5 bee slots<br />5 honey storage',
    APIARY_DESCRIPTION: '8 bee slots<br />15 honey storage',
    SKILLED_BEE_DESCRIPTION: '0.71 HONEY per hour',
    FLOWER_BARREL_DESCRIPTION: 'Decoration',
    ALVEARY_DESCRIPTION: '12 bee slots<br />25 honey storage',
    DIAMOND_BEE_DESCRIPTION: '0.9 HONEY per hour',
    LAND_DESCRIPTION: '+3 Extension',
    BEE_DESCRIPTION: '0.61 HONEY per hour',
    SMALL_BEE_DESCRIPTION: '0.54 HONEY per hour',
    HONEY_BEE_DESCRIPTION: '0.65 HONEY per hour',
    HONEY_HUNTER_DESCRIPTION: '0.84 HONEY per hour',
    HONEY_EXPLORER_DESCRIPTION: '0.8 HONEY per hour',
    FOUNTAIN_DESCRIPTION: 'Decoration',
  }; */
export const UNAUTHORIZED = 'UNAUTHORIZED';
export const CREATED = 'CREATED';
export const NO_CREATED = 'NO_CREATED';

export const BEAR_CREATED_STATE = {
  UNAUTHORIZED: 0,
  CREATED: 1,
  NO_CREATED: 2,
};

export const EXPIRATION_BEE_PACKS = '20 June 2022 09:00 UTC';

export const ARTICLES_INFO = [
  {
    publishedIn: 'Benzinga',
    logo: 'BenzingaLogo',
    articleLink:
      'https://www.benzinga.com/markets/cryptocurrency/22/04/26905698/honeywood-a-play-and-earn-game-on-the-blockchain',
    articleQuote:
      'Is a game that promises captivating gameplay. It is a play-and-earn game that is open to all ages and backgrounds.',
  },
  {
    publishedIn: 'CoinCheckup',
    logo: 'CoinCheckupLogo',
    articleLink:
      'https://coincheckup.com/blog/honeywood-a-paradise-of-bears-game-launch-on-13th-may/',
    articleQuote:
      'HoneyWood comes packed with different features that make it stand out from the rest of the competition.',
  },
  {
    publishedIn: 'theCryptoUpdates',
    logo: 'TheCryptoUpdatesLogo',
    articleLink:
      'https://www.thecryptoupdates.com/one-of-the-first-play-and-earn-game-will-launch-on-may-13th/',
    articleQuote: 'People of all ages can enjoy its captivating graphics and fantastic gameplay.',
  },
  {
    publishedIn: 'Crypto Mode',
    logo: 'CryptoModeLogo',
    articleLink: 'https://cryptomode.com/honeywood-the-play-and-earn-blockchain-game/',
    articleQuote:
      'Unlike most blockchain games, HoneyWood is a play-and-earn game that runs on an independent blockchain.',
  },
  {
    publishedIn: 'PublishOX',
    logo: 'PublishOXLogo',
    articleLink:
      'https://www.publish0x.com/navid-ladani/where-earning-and-fun-becomes-one-interview-with-the-ceo-of-xjreowk',
    articleQuote: 'HoneyWood captivates the imagination with its simple yet compelling approach.',
  },
];
export const ARTICLES_SHOW_CARDS_COUNT = 3;

export const TOKENOMICS_COINS_PERCENTAGES = {
  honeyCoin: {
    // liquidity: '64,24',
    // igo: '14,47',
    // webSale: '8,68',
    // honeyWoodTeam: '5,21',
    // private: '5,21',
    // marketing: '1,39',
    // advisory: '0,63',
    // reward: '0,17',
    reward: '25',
    airdrop: '25',
    gameAssets: '50',
  },
  cone: {
    seed: '1,05',
    privateRoundOne: '5,26',
    privateRoundTwo: '10,53',
    companyStake: '47,38',
    partnership: '3,65',
    honeyWoodTeam: '10,53',
    // reward: '4,74',
    advisory: '2,11',
    marketing: '6,32',
    liquidity: '13,16',
    // advisory: '1,05',
  },
};

export default {
  APP_VERSION,
  DEFAULT_LANGUAGE,
  DEFAULT_AVATAR,
  LANGUAGE,
  PRODUCTION,
  DEVELOPMENT,
  BETA,
  PRELIVE,
  RPC_URL,
  URL_SERVER,
  URL_SERVER_MAIN,
  MILLION,
  INFO_ACCOUNT,
  ROADMAP_STEP_IS_DONE,
  QUANTITY_DOWNLOADED_RESOURCES,
  SIZE_INPUT_WORD,
  COUNT_WORD_SEED_PHRASE,
  COUNT_MILLISECONDS_LIFE_TIME_PASSWORD,
  COUNT_MILLISECONDS_LIFE_TIME_SIGNATURE,
  SEED_PHRASE,
  PASSWORD,
  PROFILE,
  HONEY_WOOD,
  MONETA_HONEY_WOOD,
  SKIP_EVENT_PACK,
  ENCRYPT_PROFILE,
  ENCRYPT_SEED_PHRASE,
  ENCRYPT_WALLET,
  PERSONAL_SOCIALS_LINK,
  APP_STAGE,
  STORAGE_NAMESPACE,
  // ITEMS_DESCRIPTION,
  EXPIRATION_BEE_PACKS,
  ARTICLES_INFO,
  TOKENOMICS_COINS_PERCENTAGES,
  COST_CHANGE_NICKNAME,
  COST_COMMISSION,
};
