import { calculateFee, GasPrice } from '@cosmjs/stargate';
import _ from 'lodash';
import { BEARS } from '@api';
import { INFO_ACCOUNT, MILLION } from '@constants/system';
import { GAS_WANTED, GAS_WANTED_CONE, MIN_BALANCE_HONEY } from '@constants/blockchain';
import { toFixedNumberWithoutRound, floorRounding } from '@utils/common';
import { StoreInstance } from 'src/stores';
import Logger from '@utils/logger';
import settings from 'src/api/server/settings';
import { getValidatorsAddressWhiteList } from './mainBears';

export const updateBalancesInfo = async () => {
  if (!StoreInstance.isAccountAccess) {
    StoreInstance.setProfileAvailable({
      ...StoreInstance.profileAvailable,
      cones: '﹡',
      honey: '﹡',
    });
    return;
  }
  const { address } = StoreInstance.profileAvailable;
  let cones = '-';
  let honey = '-';
  try {
    const balances = await BEARS.getBalance({ address });
    cones = -1;
    honey = -1;
    if (!_.isEmpty(balances)) {
      cones = toFixedNumberWithoutRound({ number: Number(balances.cone), countSign: 3 });
      honey = toFixedNumberWithoutRound({ number: Number(balances.honey), countSign: 3 });
    }
  } catch (err) {
    Logger.error(err);
  } finally {
    StoreInstance.setProfileAvailable({ ...StoreInstance.profileAvailable, cones, honey });
  }
};

export const updateHoneyPowerBear = async () => {
  if (!StoreInstance.isAccountAccess) {
    StoreInstance.setProfileAvailable({
      ...StoreInstance.profileAvailable,
      honeyPower: '﹡',
    });
    return;
  }
  const { address } = StoreInstance.profileAvailable;
  let honeyPower = 0;
  try {
    const params = await BEARS.getParams();
    const bearsIds = await BEARS.getBearsId({
      address,
    });
    const honeyPowerBear = bearsIds.bears
      ? await BEARS.getHoneyPowerBear({
          bearId: bearsIds.bears[0],
        })
      : null;
    honeyPower =
      honeyPowerBear && params ? floorRounding(honeyPowerBear.result * params.blocksPerHour) : 0;
  } catch (err) {
    Logger.error(err);
  } finally {
    StoreInstance.setProfileAvailable({ ...StoreInstance.profileAvailable, honeyPower });
  }
};

export const updateNicknameInfo = async () => {
  if (!StoreInstance.isAccountAccess) {
    return;
  }
  const { address } = StoreInstance.profileAvailable;
  let { nickName } = StoreInstance.profileAvailable;
  try {
    nickName = await BEARS.getNickNameByAddress({ address });
    if (localStorage.getItem(INFO_ACCOUNT)) {
      const info = JSON.parse(localStorage.getItem(INFO_ACCOUNT));
      localStorage.setItem(
        INFO_ACCOUNT,
        JSON.stringify({ date: info && info.date, address, nickName }),
      );
    }
  } catch (err) {
    Logger.error(err);
  } finally {
    StoreInstance.setProfileAvailable({ ...StoreInstance.profileAvailable, nickName });
  }
};

export const updateAvatarProfile = async () => {
  if (!StoreInstance.isAccountAccess) {
    return;
  }
  try {
    const { address } = StoreInstance.profileAvailable;
    const profile = await settings.profile({ address });
    if (!profile.err && profile.avatar) {
      StoreInstance.setProfileAvailable({
        ...StoreInstance.profileAvailable,
        avatar: profile.avatar,
      });
    }
  } catch (err) {
    Logger.error(err);
  }
};

export const updateProfileInfo = async () => {
  await updateBalancesInfo();
  await updateNicknameInfo();
  await updateAvatarProfile();
};

export const getCostChangeName = async () => {
  if (!StoreInstance.isAccountAccess) {
    return undefined;
  }
  try {
    const params = await BEARS.getParams();
    if (!_.isEmpty(params)) {
      return params.priceSetName[0].amount / MILLION;
    }
    return undefined;
  } catch (err) {
    Logger.error(err);
    return undefined;
  }
};

export const getFee = ({ coefficient = 1.0, balances = { honey: 0, cone: 0 } }) => {
  // console.log('balances >> ', balances);
  if (balances && balances.honey >= MIN_BALANCE_HONEY) {
    const gasPrice = GasPrice.fromString('0.05uhoney');
    return calculateFee(GAS_WANTED * coefficient, gasPrice);
  } 
  if (balances && balances.cone > 0) {
    Logger.infoMainNotify({ nameNotify: 'getFee', section: 'fee >> ', meta: { balances }, useInProduction: true });
    const gasPrice = GasPrice.fromString('0.000005cone');
    return calculateFee(GAS_WANTED_CONE, gasPrice);
  } 

  const gasPrice = GasPrice.fromString('0.05uhoney');
  return calculateFee(GAS_WANTED * coefficient, gasPrice);
};

export const getTokenForFee = async () => {
  if (!StoreInstance.isAccountAccess) {
    return undefined;
  }
  const { address } = StoreInstance.profileAvailable;
  const balances = await BEARS.getBalance({ address });
  const fee = getFee({ balances });
  return fee;
}

export const getValidatorAddress = async ({ address }) => {
  const validators = await BEARS.getValidators({ status: 'BOND_STATUS_BONDED' });
  const responseStaked = await BEARS.getStakedCones({ address });
  Logger.infoMainWithKey('StakeCones responseStaked', responseStaked);
  let responseAddressValidator;
  if (responseStaked && responseStaked > 0) {
    responseAddressValidator = await BEARS.getAddressValidators({ address });
    Logger.infoMainWithKey('getAddressValidators responseAddressValidator', responseAddressValidator);
  }
  Logger.infoMainWithKey('Bonded validators: ', validators);

  let validatorAddress;
  if (responseAddressValidator) {
    validatorAddress = _.sample(responseAddressValidator.validators).operatorAddress;
  } else {
    const validatorsAddressWhitelist = getValidatorsAddressWhiteList();
    const validatorsWhitelist = validators.validators.filter((prop) => {
      return validatorsAddressWhitelist.includes(prop.operatorAddress);
    });
    Logger.infoMainWithKey('validators whitelist', validatorsWhitelist);
    validatorAddress = _.sample(validatorsWhitelist).operatorAddress;
  }
  Logger.infoMainWithKey('validatorAddress', validatorAddress);
  return validatorAddress;
}

export default {
  updateBalancesInfo,
  updateHoneyPowerBear,
  updateNicknameInfo,
  updateProfileInfo,
  getCostChangeName,
  updateAvatarProfile,
  getFee,
  getTokenForFee,
  getValidatorAddress,
};
