/* eslint-disable */
// import { DirectSecp256k1HdWallet } from "@cosmjs/proto-signing";
import { stringToPath, Secp256k1, sha256 } from "@cosmjs/crypto";
import { toHex } from "@cosmjs/encoding";
import axios from "axios";
// import { EASY_START, USDT, USDT_TRC20 } from "@constants/blockchain";
// import { HOST } from "@constants/system";
import Logger from '@utils/logger';
import { StoreInstance } from "src/stores";

const makeSignature = async () => {
  const body = {}
  const ts = String(Math.floor(Date.now() / 1000));
  let signString = ts + JSON.stringify(body);
 
  const signature = await Secp256k1.createSignature(sha256(signString), StoreInstance.profileAvailable.account.privkey);
  return {
    body,
    timestamp: ts,
    signature,
  }
};

export const targets = async () => {
  try {
    const result = await axios.get('api/event/targets', {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (result.status === 200) {
      return result.data;
    }
    // console.log(result);
    return { err: true, msg: 'targets error' }
  } catch (err) {
    Logger.error(err);
    return { err: true, msg: String(err) }
  }
}

export const achievedTargets = async () => {
  const address = StoreInstance.profileAvailable.address
  try {
    const result = await axios.get(`api/event/address/achieved-targets?address=${address}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (result.status === 200) {
      return result.data;
    }
    // console.log(result);
    return { err: true, msg: 'targets error' }
  } catch (err) {
    Logger.error(err);
    return { err: true, msg: String(err) }
  }
}

export const checkIn = async () => {
  const infoRequest = await makeSignature();
  // console.log('infoRequest >> ', infoRequest)
  try {
    const result = await axios.post('api/event/address/checkin', JSON.stringify(infoRequest.body), {
      headers: {
        'Content-Type': 'application/json',
        'x-honeywood-ts': infoRequest.timestamp,
        'x-honeywood-pk': toHex(StoreInstance.profileAvailable.account.pubkey),
        'x-honeywood-sig': toHex(infoRequest.signature.toFixedLength()),
      },
    })
    if (result.status === 400) {
      const objError = await result.statusText;
      const textError = objError.split(',')[1].split(':')[1];
      return { err: true, msg: textError }
    }
    if (result.status === 200) {
      return result.data;
    }
    // console.log(result);
    return { err: true, msg: 'checkin error' }
  } catch (err) {
    Logger.error(err);
    return { err: true, msg: String(err) }
  }
}

export default {
  targets,
  achievedTargets,
  checkIn
};