import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import lodash from 'lodash';
import { observer } from 'mobx-react';
import HeadTags from '@components_new/HeadTags';
import { useStores } from '@src/stores';
import { copyText, toFixedNumberWithoutRound } from '@utils/common';
import { useCopied } from '@hooks/custom';
import methodsBears, {
  getCostChangeName,
  updateBalancesInfo,
  updateHoneyPowerBear,
  updateNicknameInfo,
  updateAvatarProfile,
} from '@utils/methodsBears';
import payments from 'src/api/server/payments';
import MobileAccountPage from '@src/pages/AccountPage/MobileAccountPage';
import BeeSpinner from '@components/BeeSpinner';
import { ENUM_MODAL } from '@components_new/Modal/enumModal';
import { ENUM_POPUP } from '@components_new/PopUp/enumPopup';
import { MILLION } from '@constants/system';
import KeplrWallet from 'src/api/KeplrWallet';
import { CONE, DENOM_UHONEY, HONEY_CAMEL_CASE, TYPE_AUTH_CLIENT } from '@constants/blockchain';
import Logger from '@utils/logger';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { fromBech32 } from '@cosmjs/encoding';

import styles from './account.module.scss';

let MIN_CONE_POLYGON_WIDTH = 135;
let MIN_HONEY_POLYGON_WIDTH = 135;
const COST_CHANGE_NAME = 100;
const regCONE = /^((0)|([1-9]([0-9]){0,9}))$/g;
const regExBears = /^bears[a-zA-Z0-9]{30,40}$/g;

const AccountPage = observer(() => {
  const stores = useStores();
  MIN_CONE_POLYGON_WIDTH = stores.browserInfo.isMobile ? 97 : 135;
  MIN_HONEY_POLYGON_WIDTH = stores.browserInfo.isMobile ? 97 : 135;

  const [userName, setUserName] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [polygonAddress, setPolygonAddress] = useState('');
  const [cones, setCones] = useState('﹡');
  const [honeyCoins, setHoneyCoins] = useState('﹡');
  const [honeyCoinsPerBlock, setHoneyCoinsPerBlock] = useState('﹡');
  const [maxSendCONE, setMaxSendCONE] = useState(0);
  const [feeCost, setFeeCost] = useState({ amount: 0.1, tokenName: HONEY_CAMEL_CASE });
  const [isLoading, setLoading] = useState(false);
  const [isLoadingNickName, setLoadingNickName] = useState(true);
  const [isLoadingTransactions, setLoadingTransactions] = useState(false);
  const [isSendTokenButton, setSendTokenButton] = useState(false);

  const [conePolygonWidth, setConePolygonWidth] = useState(MIN_CONE_POLYGON_WIDTH);
  const [honeyPolygonWidth, setHoneyPolygonWidth] = useState(MIN_HONEY_POLYGON_WIDTH);
  const [receivedReferralHoneyPolygonWidth, setReceivedReferralHoneyPolygonWidth] = useState(
    MIN_HONEY_POLYGON_WIDTH,
  );
  const [paidPacks, setPaidPacks] = useState([]);
  const [costChangeName, setCostChangeName] = useState(COST_CHANGE_NAME);
  const [referralCode, setReferralCode] = useState('﹡');
  const [totalUsedReferralCode, setTotalUsedReferralCode] = useState('*');
  const [receivedReferralHoney, setReceivedReferralHoney] = useState('﹡');

  // Initial modal for sending token
  const schemaModal = yup.object().shape({
    recipient: yup
      .string()
      .required()
      .test('is-bears', 'Wrong bears address', async (value) => {
        try {
          const resp = await fromBech32(value);
          return value.match(regExBears) && !!resp;
        } catch (err) {
          return false;
        }
      })
      .test('is-different-bears', 'Sending address matches the address of your wallet', (value) => {
        return value.match(regExBears) && value !== walletAddress;
      }),
    amount: yup
      .string()
      .required()
      .test('is-number', 'Amount must be a `number` type and greater than 0', (value) => {
        return value.match(regCONE);
      })
      .test('is-verification-wallet', 'Need access to a wallet', (value) => {
        return stores.isAccountAccess;
      })
      .test('is-exist-amount', `Insufficient funds (max ${maxSendCONE} CONE)`, (value) => {
        if (feeCost.tokenName === CONE) {
          return stores.isAccountAccess && cones > Number(value);
        }
        return stores.isAccountAccess && cones >= Number(value);
      }),
  });

  const ModalForm = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schemaModal),
    defaultValues: {
      recipient: '',
      amount: undefined,
    },
  });
  const modalFields = ModalForm.watch();

  // UseEffect initial
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _ = stores.language;
  const navigate = useNavigate();
  const { copied, setCopied } = useCopied();
  const { copied: copiedReferralCode, setCopied: setCopiedReferralCode } = useCopied();
  const { copied: copiedPolygonAddress, setCopied: setCopiedPolygonAddress } = useCopied();

  const location = useLocation();

  useEffect(async () => {
    if (location && location.state && location.state.code) {
      const { address } = stores.profileAvailable;
      // const result1 = await payments.getAirdropCode();
      // console.log('result1 [getAirdropCode] >>> ', result1);
      // const code = "bga4r4dq";
      // const code = result1.code;
      const { code } = location.state;
      const resultAirdrop = await payments.getAirdrop({ address, code });
      // console.log('result2 [getAirdrop] >>> ', resultAirdrop);
      if (resultAirdrop && !resultAirdrop.err) {
        stores.setOpenPopup(ENUM_POPUP.AIRDROP_SUCCESS);
      } else {
        Logger.infoMainWithKey('Airdrop error', resultAirdrop.msg, { useInProduction: true });
      }
    }
  }, [location]);

  // TODO: hot fix for mobile device
  useEffect(() => {
    if (stores.browserInfo.isMobile) {
      document.body.style.backgroundColor = '#1c2f16'; // winter - #2b91c9; summer - #1c2f16
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (isLoadingNickName && stores.typeAuthClient === TYPE_AUTH_CLIENT.KEPLR) {
        await KeplrWallet.checkExistedChain();
      }
    })();
  }, [isLoadingNickName]);

  const updateMaxSendCone = ({ balances = { honey: 0, cone: 0 }, feeTokenName = HONEY_CAMEL_CASE  }) => {
    if (feeTokenName === CONE) {
      setMaxSendCONE(balances.cone - 1);
    } else {
      setMaxSendCONE(balances.cone);
    }
  }

  const updateFee = async () => {
    const coefficient = 1.0;
    const balances = { honey: stores.profileAvailable.honey, cone: stores.profileAvailable.cones };
    const feeObject = await methodsBears.getFee({ coefficient, balances });
    const tokenName = feeObject.amount[0].denom === DENOM_UHONEY ? HONEY_CAMEL_CASE : CONE;
    const amountFee = tokenName === HONEY_CAMEL_CASE ? feeObject.amount[0].amount / MILLION : feeObject.amount[0].amount;
    setFeeCost({ amount: amountFee, tokenName });
    updateMaxSendCone({ balances, feeTokenName: tokenName });
  }

  const updateBalances = async () => {
    setLoading(true);
    await updateBalancesInfo();
    await updateHoneyPowerBear();
    await updateFee();
    setCones(stores.profileAvailable.cones);
    setHoneyCoins(stores.profileAvailable.honey);
    setHoneyCoinsPerBlock(stores.profileAvailable.honeyPower);
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      if (!lodash.isEmpty(stores.profileAvailable) && stores.profileAvailable.nickName) {
        setWalletAddress(stores.profileAvailable.address);
        const polygonResult = await payments.getPolygonAddress({
          address: stores.profileAvailable.address,
        });
        setPolygonAddress(polygonResult);
      }
      if (stores.isAccountAccess) {
        await updateBalances();
      }
    })();
  }, [stores.isAccountAccess]);

  useEffect(() => {
    (async () => {
      if (stores.isAccountAccess) {
        const costName = await getCostChangeName();
        if (costName) {
          setCostChangeName(costName);
        }
      }
    })();
  }, [stores.isAccountAccess]);

  useEffect(() => {
    const getPacksWithPAID = (listsInvoices) => {
      if (listsInvoices && !listsInvoices.err && listsInvoices.length > 0) {
        const packsPaidLists = listsInvoices.filter((transaction) => {
          return transaction.payment_status === 'PAID' || transaction.payment_status === 'finished';
        });
        // console.log('packsPaidLists >> ', packsPaidLists);
        return packsPaidLists || [];
      }
      return [];
    };

    (async () => {
      if (stores.isAccountAccess) {
        setLoadingTransactions(true);
        // Payments: Referrals
        const referralCodeResult = await payments.getReferralCode({});
        // console.log('referralCodeResult >> ', referralCodeResult);
        const referralRewardResult = await payments.getReferralReward({});
        // console.log('referralRewardResult >> ', referralRewardResult);
        if (referralCodeResult && referralCodeResult.code) {
          setReferralCode(referralCodeResult.code);
        } else {
          setReferralCode('null');
        }
        if (
          referralRewardResult &&
          !referralRewardResult.err &&
          lodash.isNumber(Number(referralRewardResult.total_rewards))
        ) {
          const rewardsHoney =
            referralRewardResult.total_rewards > 0
              ? toFixedNumberWithoutRound({
                  number: referralRewardResult.total_rewards / MILLION,
                  countSign: 7,
                })
              : 0;
          setReceivedReferralHoney(rewardsHoney);
          setTotalUsedReferralCode(referralRewardResult.count_referral);
        }
        const address = stores.profileAvailable && stores.profileAvailable.address;
        // Payments: Paid lists
        const listsBinance = await payments.getBinanceInvoiceList({ address });
        // console.log('listsBinance: ', listsBinance);
        const listsNowPayment = await payments.getNowPaymentInvoiceList({ address });
        // console.log('listsNowPayment: ', listsNowPayment);
        const packsPaidBinance = getPacksWithPAID(listsBinance);
        const packsPaidNowPayment = getPacksWithPAID(listsNowPayment);
        setPaidPacks(packsPaidBinance.concat(packsPaidNowPayment).reverse());
        setLoadingTransactions(false);
      }
    })();
  }, [stores.isAccountAccess]);

  useEffect(() => {
    const AssetsConeQuantity = document.getElementById('AssetsConeQuantity');
    const AssetsHoneyQuantity = document.getElementById('AssetsHoneyQuantity');
    const AssetsReceivedHoneyQuantity = document.getElementById('AssetsReceivedHoneyQuantity');
    setConePolygonWidth(
      AssetsConeQuantity?.scrollWidth ? AssetsConeQuantity.scrollWidth + 70 : 135,
    );
    setHoneyPolygonWidth(
      AssetsHoneyQuantity?.scrollWidth ? AssetsHoneyQuantity?.scrollWidth + 70 : 135,
    );
    setReceivedReferralHoneyPolygonWidth(
      AssetsReceivedHoneyQuantity?.scrollWidth
        ? AssetsReceivedHoneyQuantity?.scrollWidth + 70
        : 135,
    );
  }, [isLoading, receivedReferralHoney]);

  useEffect(async () => {
    (async () => {
      if (!lodash.isEmpty(stores.profileAvailable) && stores.profileAvailable.nickName) {
        setLoadingNickName(true);
        if (stores.isAccountAccess) {
          await updateNicknameInfo();
          await updateAvatarProfile();
        }
        setUserName(stores.profileAvailable.nickName);
        setLoadingNickName(false);
      } else {
        setLoadingNickName(false);
      }
    })();
  }, [stores.isAccountAccess]);

  useEffect(() => {
    (async () => {
      if (
        stores.executeModal &&
        (stores.executeModal.name === ENUM_MODAL.CHANGE_NICKNAME ||
          stores.executeModal.name === ENUM_MODAL.CHANGE_AVATAR ||
          stores.executeModal.name === ENUM_MODAL.SEND_TOKEN) &&
        stores.executeModal.state
      ) {
        if (stores.executeModal.name === ENUM_MODAL.CHANGE_NICKNAME) {
          setUserName(stores.executeModal.state.name);
        }
        await updateBalances();
        stores.setExecuteModal();
      }
    })();
  }, [stores.executeModal]);

  const onBackHoneyWoodClick = () => {
    navigate('/');
  };

  const onSeeAllTransactions = () => {
    navigate('/transactions');
  };

  const onQuitGameClick = () => {
    stores.removeAuthorized();
    Logger.infoNotify('onQuitGameClick, AccountPage', { type: stores.typeAuthClient }, true);
    navigate('/');
  };

  const gameClick = () => {
    navigate('/game');
  };

  const privateUsernameClick = () => {
    stores.setOpenModal(ENUM_MODAL.PRIVATE_USERNAME);
  };

  const onCopyAddress = () => {
    copyText({ text: walletAddress });
    setCopied();
  };

  const onCopyReferralCode = () => {
    copyText({ text: referralCode });
    setCopiedReferralCode();
  };

  const onCopyPolygonAddress = () => {
    copyText({ text: polygonAddress });
    setCopiedPolygonAddress();
  };

  const showChangeNickname = () => {
    if (honeyCoins >= costChangeName + 0.1) {
      stores.setPropsForModal({ costChangeName });
      stores.setOpenModal(ENUM_MODAL.CHANGE_NICKNAME);
    } else if (stores.isAccountAccess) {
      stores.setOpenPopup(ENUM_POPUP.CHANGE_NICKNAME_WARNING);
    } else {
      stores.setOpenPopup(ENUM_POPUP.CHANGE_NAME_NO_ACCESS_ACCOUNT);
    }
  };

  const showChangeAvatar = () => {
    if (stores.isAccountAccess) {
      stores.setOpenModal(ENUM_MODAL.CHANGE_AVATAR);
    } else {
      stores.setOpenPopup(ENUM_POPUP.CHANGE_NAME_NO_ACCESS_ACCOUNT);
    }
  };

  const onLogoutClick = () => {
    stores.removeAuthorized();
    Logger.infoNotify('onLogoutClick, Layout', { type: stores.typeAuthClient }, true);
    navigate('/');
  };

  const sendTokenClick = async () => {
    const { recipient, amount } = modalFields;
    const fee = await methodsBears.getTokenForFee();

    stores.setPropsForModal({ recipient, amount, fee });
    stores.setOpenModal(ENUM_MODAL.SEND_TOKEN);
  };

  return (
    <>
      <HeadTags description="Account | HoneyWood is a browser game perfectly adapted for all devices: smartphone, tablet and desktop. Play anywhere! Creating the world with scruffy paws. Time to build your sweet home here!" />
      {stores.isInitialAccountPagePageLoading && (
        <BeeSpinner classNames={{ Container: styles.Container }} />
      )}
      <MobileAccountPage
        stores={stores}
        states={{
          copied,
          copiedReferralCode,
          copiedPolygonAddress,
          userName,
          walletAddress,
          polygonAddress,
          cones,
          honeyCoins,
          honeyCoinsPerBlock,
          conePolygonWidth,
          honeyPolygonWidth,
          paidPacks,
          referralCode,
          receivedReferralHoney,
          receivedReferralHoneyPolygonWidth,
          totalUsedReferralCode,
          isSendTokenButton,
          ModalForm,
          feeCost
        }}
        loading={{ isLoading, isLoadingNickName, isLoadingTransactions }}
        methods={{
          onBackHoneyWoodClick,
          onQuitGameClick,
          onCopyAddress,
          onCopyPolygonAddress,
          onCopyReferralCode,
          showChangeNickname,
          showChangeAvatar,
          gameClick,
          privateUsernameClick,
          onSeeAllTransactions,
          onLogoutClick,
          sendTokenClick,
          setSendTokenButton,
        }}
        isInitialLoading={stores.isInitialAccountPagePageLoading}
      />
    </>
  );
});

export default AccountPage;
