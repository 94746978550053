/* eslint-disable */
const bip39 = require('bip39');

export const generateMnemonic = () => {
  return bip39.generateMnemonic();
}

export const isValidMnemonic = ({ mnemonic }) => {
  try {
    return bip39.validateMnemonic(mnemonic);
  } catch (error) {
    return false;
  }
}

export const mnemonicToSeed = async (mnemonic) => {
  return await bip39.mnemonicToSeed(mnemonic);
}