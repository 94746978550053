/* eslint-disable */
import { DirectSecp256k1HdWallet } from "@cosmjs/proto-signing";
import { SigningStargateClient, createProtobufRpcClient, setupDistributionExtension } from "@cosmjs/stargate";
import { stringToPath } from "@cosmjs/crypto";
import Logger from '@utils/logger';
import _ from "lodash";

import { QueryClientImpl } from 'honeywood-protobuf-js/types/bears/query';
import { txClient } from 'honeywood-protobuf-js';
import { CHAIN_ID_HONEYWOOD, CONFIG_HONEYWOOD_BLOCKCHAIN } from "@constants/blockchain";

export const createAnonymousClient = async ({ url }) => {
  try {
    const client = await SigningStargateClient.connectWithSigner(url);
    const rpcClient = createProtobufRpcClient(client.queryClient);
    const queryService = new QueryClientImpl(rpcClient);

    client.queryClient.distribution = setupDistributionExtension(client.queryClient).distribution;
    return {
      cosmos: client,
      bearsQueries: queryService,
    }
  } catch (err) {
    Logger.error(err);
    return undefined;
  }
}

export const createAuthorizedClient = async ({ wallet, url }) => {
  try {
    const client = await SigningStargateClient.connectWithSigner(url, wallet)
    const rpcClient = createProtobufRpcClient(client.queryClient);
    const queryService = new QueryClientImpl(rpcClient);

    client.queryClient.distribution = setupDistributionExtension(client.queryClient).distribution;
    return {
      cosmos: client,
      bearsTxs: await txClient(wallet, { addr: url }),
      bearsQueries: queryService,
    }
  } catch (err) {
    Logger.error(err);
    return undefined;
  }
}

export const createAuthorizedKeplrClient = async ({ url }) => {
  try {
    const chainId = CHAIN_ID_HONEYWOOD;
    await window.keplr.enable(chainId);
    const offlineSigner = window.getOfflineSigner(chainId);

    const client = await SigningStargateClient.connectWithSigner(
      url,
      offlineSigner
    )
    const rpcClient = createProtobufRpcClient(client.queryClient);
    const queryService = new QueryClientImpl(rpcClient);

    client.queryClient.distribution = setupDistributionExtension(client.queryClient).distribution;

    return {
      cosmos: client,
      bearsTxs: await txClient(offlineSigner, { addr: url }),
      bearsQueries: queryService,
    }
  } catch (err) {
    Logger.error(err);
    return undefined;
  }
}

export default {
  createAnonymousClient,
  createAuthorizedClient,
  createAuthorizedKeplrClient
}