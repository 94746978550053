import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { sanitize } from 'dompurify';
import { useStores } from 'src/stores';
import classnames from 'classnames';
import TextH3 from '@components_new/TextH3';
import TitleSection from '@src/components/TitleSection';
import BackgroundMobile from '@assets/Mobile/AboutPage/CapitalSection/capital_section_image_mobile.jpg';
import BackgroundTablet from '@assets/Mobile/AboutPage/CapitalSection/capital_section_image_tablet.jpg';
import BackgroundDesktop from '@assets/Mobile/AboutPage/CapitalSection/capital_section_image_desktop.jpg';
import CosmosLogo from '@assets/Mobile/AboutPage/CapitalSection/cosmos_logo_small.png';
import TendermintLogo from '@assets/Mobile/AboutPage/CapitalSection/tendermint_logo_small.png';
import FlagIcon from '@assets/Mobile/AboutPage/CapitalSection/flag_icon.png';
import TreeIcon from '@assets/Mobile/AboutPage/CapitalSection/tree_icon.png';
import ApiaryIcon from '@assets/Mobile/AboutPage/CapitalSection/apiary_icon.png';
import SaviourStatueIcon from '@assets/Mobile/AboutPage/CapitalSection/saviour_statue_icon.png';
import Logger from '@utils/logger';
import styles from './mobileCapital.module.scss';

const infoCardIcons = [FlagIcon, TreeIcon, ApiaryIcon, SaviourStatueIcon];

const InfoCard = ({ card }) => {
  const stores = useStores();
  return (
    <div className={styles.CardBlock}>
      <div className={styles.CardWrapper}>
        <img
          onLoad={() => stores.increaseDownloadedImageCounter(card.icon)}
          onError={() => Logger.error('Error with image loading')}
          src={card.icon}
          alt={card.icon}
          className={classnames(
            styles.CardIcon,
            card.icon === FlagIcon && styles.CardIcon__FlagIcon,
            card.icon === TreeIcon && styles.CardIcon__TreeIcon,
            card.icon === ApiaryIcon && styles.CardIcon__ApiaryIcon,
            card.icon === SaviourStatueIcon && styles.CardIcon__SaviourStatueIcon,
          )}
        />
        <TextH3 classNames={styles.CardTitle} text={card.title} />
        <p
          className={styles.CardSubtitle}
          dangerouslySetInnerHTML={{
            __html: sanitize(card.subtitle),
          }}
        />
      </div>
    </div>
  );
};

const CapitalSection = observer(() => {
  const stores = useStores();
  const _ = stores.language;
  const infoCardsTitlesMobile = stores.translate('info_cards_titles_mobile');

  /* Image display logic */
  const [isHideBackgroundImage, setHideBackgroundImage] = useState(false);
  const HideBackgroundImage = () => {
    setHideBackgroundImage(
      window.scrollY >= document.getElementById('capital-section').clientHeight,
    );
  };

  useEffect(() => {
    window.addEventListener('scroll', HideBackgroundImage);
    return () => {
      window.removeEventListener('scroll', HideBackgroundImage);
    };
  }, []);

  return (
    <div className={styles.Container} id="capital-section">
      <div
        className={classnames(
          styles.BackgroundImagesContainer,
          isHideBackgroundImage && styles.BackgroundImagesContainer__position_absolute,
        )}
      >
        <img
          onLoad={() => stores.increaseDownloadedImageCounter(BackgroundMobile)}
          onError={() => Logger.error('Error with image loading')}
          src={BackgroundMobile}
          alt="capital_section_image_mobile"
          className={styles.BackgroundMobileImage}
        />
        <img
          onLoad={() => stores.increaseDownloadedImageCounter(BackgroundTablet)}
          onError={() => Logger.error('Error with image loading')}
          src={BackgroundTablet}
          alt="capital_section_image_desktop"
          className={styles.BackgroundTabletImage}
        />
        <img
          onLoad={() => stores.increaseDownloadedImageCounter(BackgroundDesktop)}
          onError={() => Logger.error('Error with image loading')}
          src={BackgroundDesktop}
          alt="capital_section_image_tablet"
          className={styles.BackgroundDesktopImage}
        />
      </div>
      <div className={styles.InfoContainer}>
        <div className={styles.TitleContainer}>
          <TitleSection
            title={stores.translate('about_honeyWood_title')}
            textHtml={stores.translate('about_honeyWood_subtitle')}
            classNames={{
              Title: styles.AboutHoneyWoodTitle,
              Text: styles.AboutHoneyWoodSubtitle,
            }}
            isH2TitleTag
          />
          <p
            className={styles.AboutHoneyWoodSecondSubtitle}
            dangerouslySetInnerHTML={{
              __html: sanitize(stores.translate('about_honeyWood_second_subtitle')),
            }}
          />
        </div>
        <div className={styles.LogosContainer}>
          <img
            onLoad={() => stores.increaseDownloadedImageCounter(CosmosLogo)}
            onError={() => Logger.error('Error with image loading')}
            src={CosmosLogo}
            alt="cosmos_logo"
            className={styles.CosmosLogo}
          />
          <img
            onLoad={() => stores.increaseDownloadedImageCounter(TendermintLogo)}
            onError={() => Logger.error('Error with image loading')}
            src={TendermintLogo}
            alt="tendermint_logo"
            className={styles.TendermintLogo}
          />
        </div>
        <div className={styles.SecondTitleContainer}>
          <TitleSection
            title={stores.translate('blockchain_and_ecosystem_title')}
            textHtml={stores.translate('blockchain_and_ecosystem_subtitle')}
            classNames={{
              Title: styles.BlockchainAndEcosystemTitle,
              Text: styles.BlockchainAndEcosystemSubtitle,
            }}
            isH1TitleTag
          />
        </div>
        <div className={styles.CardContainer}>
          {infoCardsTitlesMobile.map((card, index) => (
            <InfoCard
              card={{
                icon: infoCardIcons[index],
                title: card.title,
                subtitle: card.subtitle,
              }}
              key={card.title}
            />
          ))}
        </div>
      </div>
    </div>
  );
});

export default CapitalSection;
