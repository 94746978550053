import React from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import TitleSection from '@src/components/TitleSection';
import URLS from 'src/constants/urls';
import { Button, ColorType, SizeButton } from 'src/components_new/Button';
import { GOOGLE_ANALYTICS } from '@src/constants/googleAnalyticsEvents';
import { gtagEvent } from '@utils/common';
import ParticipateImage from '@assets/Mobile/TokenomicsPage/ParticipateSection/participate_image.svg';
import Logger from '@utils/logger';
import styles from './mobileParticipate.module.scss';

const ParticipateSection = observer(() => {
  const stores = useStores();

  // const scrollToTokenomicsSection = () => {
  //   const { isMobile } = stores.browserInfo;
  //   const targetId = document.getElementById('tokenomics');
  //   const yOffset = isMobile ? 0 : -111;
  //   const y = targetId?.getBoundingClientRect().top + window.pageYOffset + yOffset;
  //   window.scrollTo({ top: y, behavior: 'smooth' });
  // };

  return (
    <div className={styles.ParticipateSectionContainer}>
      <div className={styles.Container}>
        <div className={styles.TitleContainer}>
          <TitleSection
            title={stores.translate('participate_section_title')}
            classNames={{ Title: styles.Title }}
            isH5TitleTag
          />
        </div>
        <img
          onLoad={() => stores.increaseDownloadedImageCounter(ParticipateImage)}
          onError={() => Logger.error('Error with image loading')}
          src={ParticipateImage}
          alt="participate_image"
          className={styles.ParticipateImage}
        />
        <div className={styles.ButtonWrapper}>
          {/* <Button
          colorType={ColorType.GREEN}
          size={SizeButton.MEDIUM}
          title={stores.translate('participate')}
          classNames={{
            Container: classnames(styles.ButtonContainer, styles.ParticipateButtonContainer),
            Button: classnames(styles.Button),
            Title: classnames(
              styles.TitleButton,
              stores.browserInfo.isAndroid
                ? styles.TitleGreenButton__android
                : styles.TitleGreenButton,
            ),
          }}
          onClick={() => {
            gtagEvent(GOOGLE_ANALYTICS.CLICK_PARTICIPATE);
            // scrollToTokenomicsSection();
          }}
        /> */}
          <Button
            colorType={ColorType.ORANGE}
            size={SizeButton.MEDIUM}
            title={stores.translate('white_paper')}
            classNames={{
              Container: classnames(styles.ButtonContainer),
              Button: classnames(styles.Button),
              Title: classnames(
                styles.TitleButton,
                stores.browserInfo.isAndroid
                  ? styles.TitleOrangeButton__android
                  : styles.TitleOrangeButton,
              ),
            }}
            onClick={() => {
              gtagEvent(GOOGLE_ANALYTICS.CLICK_WHITE_PAPER);
              window.open(URLS.WHITE_PAPER, '_blank', 'noopener,noreferrer');
            }}
          />
        </div>
      </div>
    </div>
  );
});
export default ParticipateSection;
