import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { sanitize } from 'dompurify';
import { gtagEvent, copyText } from '@utils/common';
import { useCopied } from '@hooks/custom';
// import { GOOGLE_ANALYTICS } from '@src/constants/googleAnalyticsEvents';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Close from '@src/assets/control/close_new_icon.svg';
import DiagonalLine from '@assets/common/diagonal_line.svg';
import BinanceIcon from '@assets/common/binance_icon.png';
import HoneywoodIcon from '@assets/common/honeywood_icon.png';
import ConeIcon from '@assets/common/cone_icon.png';
import { Button, ColorType, SizeButton } from '@components_new/Button';
import InformationPopup from '@components/InformationPopup';
import KYCScreen from '@components/KYCScreen';
import CheckBox from '@components/CheckBox';
import payments from 'src/api/server/payments';
import { QRCodeSVG } from 'qrcode.react';
import { observer } from 'mobx-react';
import { useStores } from 'src/stores';
import PackItemMobile from '@components_new/PackItemMobile';
import { ENUM_POPUP } from '@components_new/PopUp/enumPopup';
import BeeSpinner from '@components/BeeSpinner';
import URLS from 'src/constants/urls';
import InputWord from '@components/InputWord';
import Logger from '@utils/logger';
import { GOOGLE_ANALYTICS } from 'src/constants/googleAnalyticsEvents';
import { COINS } from '@constants/blockchain';
import { BEARS } from '@api';

import styles from './paymentsHoneyUnity.module.scss';

const SELLING_PACKS_STATUSES = {
  WAITING: 'WAITING',
  TRANSFERRING: 'TRANSFERRING',
  TRANSFERED: 'TRANSFERED',
  ERROR: 'ERROR',
  ERROR_PAYMENT: 'ERROR_PAYMENT',
};

const PREPARE_STEP = 'prepare';
const PROCESS_STEP = 'process';
const FINISH_STEP = 'finish';

const PaymentsHoneyUnity = observer(({ close }) => {
  const stores = useStores();
  const [isErrorPrivacy, setErrorPrivacy] = useState(false);
  const [isLoadingBinance, setLoadingBinance] = useState(false);
  const [isLoadingBurnCone, setLoadingBurnCone] = useState(false);
  const [selectedPack, setSelectedPack] = useState('');
  const [selectedAgree, setSelectedAgree] = useState(false);
  const [packInfo, setPackInfo] = useState();
  const [oldCostSellingPack, setOldCostSellingPack] = useState(0);
  const [packHasBonus, setPackHasBonus] = useState(false);
  const [responseBinance, setResponseBinance] = useState();
  const [statusSellingPack, setStatusSellingPack] = useState(SELLING_PACKS_STATUSES.WAITING);
  const [errorTextSellingPack, setErrorTextSellingPack] = useState('');
  const [responseNowPayment, setResponseNowPayment] = useState();
  const [responseBurnCones, setResponseBurnCones] = useState(false);
  const [isShowKYC, setShowKYC] = useState(false);
  const [processPayment, setProcessPayment] = useState(PREPARE_STEP);
  const { setCopied, copied } = useCopied();

  const schemaModal = yup
    .object()
    .shape({
      referralCode: yup.string(),
    })
    .required();

  const ModalForm = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schemaModal),
    defaultValues: {
      referralCode: '',
    },
  });
  const modalFields = ModalForm.watch();

  useEffect(() => {
    /* Dynamically lock scrolling background */
    document.body.style.overflowY = 'hidden';
    setPackInfo(stores.stateDisplayedPaymentModal.packInfo);
    // console.log(stores.stateDisplayedPaymentModal.packInfo);
    if (
      stores.stateDisplayedPaymentModal.packInfo.ITEMS &&
      stores.stateDisplayedPaymentModal.packInfo.ITEMS.length > 0
    ) {
      let oldTotal = 0;
      stores.stateDisplayedPaymentModal.packInfo.ITEMS.forEach((item) => {
        oldTotal += item.PRICE;
      });
      setOldCostSellingPack(oldTotal);
    } else {
      setOldCostSellingPack(stores.stateDisplayedPaymentModal.packInfo.PRICE);
    }
    setSelectedPack(stores.stateDisplayedPaymentModal.packInfo.ID);
    if (
      stores.stateDisplayedPaymentModal.packInfo.ITEMS &&
      stores.stateDisplayedPaymentModal.packInfo.ITEMS.length === 1
    ) {
      if (
        stores.stateDisplayedPaymentModal.packInfo.PRICE ===
        stores.stateDisplayedPaymentModal.packInfo.ITEMS[0].PRICE
      ) {
        setPackHasBonus(true);
      }
    }
    return () => {
      /* Dynamically unlock scrolling background */
      document.body.style.overflowY = 'initial';
    };
  }, []);

  const finishNowPaymentStep = async () => {
    try {
      const paymentResponse = await payments.paymentNowPayment({
        pack: selectedPack,
        referralCode: modalFields.referralCode,
      });
      // console.log('paymentResponse >> ', paymentResponse);
      if (paymentResponse.err) {
        setStatusSellingPack(SELLING_PACKS_STATUSES.ERROR_PAYMENT);
        setErrorTextSellingPack(paymentResponse.msg);
      } else {
        setResponseNowPayment(paymentResponse);
      }
      setProcessPayment(FINISH_STEP);
    } catch (err) {
      // eslint-disable-next-line no-console
      Logger.error(err);
    }
  };

  useEffect(() => {
    let intervalId;
    (async () => {
      // console.log('isFinishKYCVerified >>>> ', stores.isFinishKYCVerified);
      if (processPayment === FINISH_STEP && responseBinance) {
        intervalId = setInterval(async () => {
          const binanceInfo = await payments.getBinanceInfo({ id: responseBinance._id });
          // console.log('response binanceInfo >> ', binanceInfo);
          if (binanceInfo.selling_pack_status !== SELLING_PACKS_STATUSES.WAITING) {
            setStatusSellingPack(binanceInfo.selling_pack_status);
            if (binanceInfo.selling_pack_status === SELLING_PACKS_STATUSES.ERROR) {
              setErrorTextSellingPack(binanceInfo.selling_pack_error);
            }
          }
          if (binanceInfo.selling_pack_status === SELLING_PACKS_STATUSES.TRANSFERED) {
            stores.setUpdateStateBearCreated(true);
          }
        }, 10000);
      } else if (processPayment === FINISH_STEP && responseBurnCones) {
        intervalId = setInterval(async () => {
          const burnCoinsInfo = await payments.getBurnCoinsInfo({ id: responseBurnCones._id });
          console.log('response burnCoinsInfo >> ', burnCoinsInfo);
          if (burnCoinsInfo.selling_pack_status !== SELLING_PACKS_STATUSES.WAITING) {
            setStatusSellingPack(burnCoinsInfo.selling_pack_status);
            if (burnCoinsInfo.selling_pack_status === SELLING_PACKS_STATUSES.ERROR) {
              setErrorTextSellingPack(burnCoinsInfo.selling_pack_error);
            }
          }
        }, 10000);
      }
    })();
    return () => {
      clearInterval(intervalId);
    };
  }, [processPayment]);

  useEffect(() => {
    if (statusSellingPack === SELLING_PACKS_STATUSES.TRANSFERED) { // пак был отправлен на аккаунт.
      stores.setOpenPopup(ENUM_POPUP.HONEY_PACK_TRANSFERRED);
      if (stores.unityStore.unityContext) {
        stores.unityStore.setSuccessBurnCoins(true);
      }
      close(true);
    }
  }, [statusSellingPack]);

  const clickScreenOutside = (e) => {
    if (
      e &&
      e.target &&
      e.target.className &&
      _.isString(e.target.className) &&
      e.target.className.includes('paymentsHoneyUnity_ModalContainer')
    ) {
      close(true);
    }
  };

  const copyLink = () => {
    if (responseBinance) {
      copyText({ text: responseBinance.payment_links.checkoutUrl });
    } else if (responseNowPayment) {
      copyText({ text: responseNowPayment.pay_address });
    }
    setCopied();
  };

  const binancePaymentClick = async () => {
    if (!processPayment === PREPARE_STEP || isLoadingBurnCone) return;
    setLoadingBinance(true);
    setProcessPayment(PROCESS_STEP);
    const paymentResponse = await payments.paymentBinance({
      pack: selectedPack,
      referralCode: modalFields.referralCode,
    });
    if (paymentResponse.err) {
      setStatusSellingPack(SELLING_PACKS_STATUSES.ERROR_PAYMENT);
      setErrorTextSellingPack(paymentResponse.msg);
    } else {
      setResponseBinance(paymentResponse);
    }

    setProcessPayment(FINISH_STEP);
    setLoadingBinance(false);
  };

  const burnConeClick = async () => {
    setLoadingBurnCone(true);
    if (!processPayment === PREPARE_STEP || isLoadingBinance) return;
    const cones = await BEARS.getCONE({ address: stores.profileAvailable.address });
    if (cones < packInfo.PRICE_CONE) {
      stores.setPropsForPopup({ cones: packInfo.PRICE_CONE });
      stores.setOpenPopup(ENUM_POPUP.NO_ENOUGH_CONES_FOR_BURN);
    } else {
      stores.setPropsForPopup({ cones: packInfo.PRICE_CONE });
      stores.setOpenPopup(ENUM_POPUP.CONFIRM_BURN);
    }
    setLoadingBurnCone(false);
  };

  useEffect(async () => {
    if (stores.executePopup && stores.executePopup.name === ENUM_POPUP.CONFIRM_BURN) {
      setLoadingBurnCone(true);
      setProcessPayment(PROCESS_STEP);
      const paymentResponse = await payments.paymentBurnCoins({ pack: packInfo.ID, amount: packInfo.PRICE_CONE });
      // console.log('paymentResponse burnCoins >> ', paymentResponse);
      if (paymentResponse.err) {
        setStatusSellingPack(SELLING_PACKS_STATUSES.ERROR_PAYMENT);
        setErrorTextSellingPack(paymentResponse.msg);
      } else {
        setResponseBurnCones(paymentResponse);
      }

      setProcessPayment(FINISH_STEP);
      setLoadingBurnCone(false);
      stores.setExecutePopup(undefined);
    }
  }, [stores.executePopup])

  return (
    <div
      className={styles.ModalContainer}
      role="button"
      tabIndex={0}
      onClick={(e) => clickScreenOutside(e)}
      onKeyDown={() => {}}
    >
      <div className={styles.ModalWrapper}>
        <div className={styles.TitleContainer} style={{ display: 'flex' }}>
          <p
            className={styles.Title}
            dangerouslySetInnerHTML={{ __html: sanitize(`Get ${packInfo?.TITLE}`) }}
          />
        </div>
        <div className={styles.Modal} style={{ display: 'block' }}>
          <button
            type="button"
            className={styles.ModalClose}
            onClick={() => {
              close(true);
              gtagEvent(GOOGLE_ANALYTICS.CLICK_CLOSE);
            }}
          >
            <img
              onLoad={() => stores.increaseDownloadedImageCounter(Close)}
              onError={() => Logger.error('Error with image loading')}
              src={Close}
              alt="close_new_icon"
              loading="lazy"
            />
          </button>
          {selectedPack && packInfo && processPayment !== FINISH_STEP && (
            <>
              {/* <p className={styles.TitleText}>
                {stores.translate('chosen_selling_pack')}, «{packInfo.TITLE}»
              </p> */}
              <div className={styles.PackItemsContainer}>
                {packInfo.ITEMS.map((item, index) => {
                  const isCoins = item.KIND === COINS;

                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className={classnames(styles.ItemsBlock)} key={index}>
                      <PackItemMobile
                        packID={packInfo.ID}
                        type={item.KIND}
                        title={item.TITLE}
                        // price={isCoins ? packInfo.PRICE : ''}
                        noPrice
                        itemsDescription={stores.paramsForObjectsBlockchain}
                        params={item.PARAMS}
                        classNames={{
                          ItemContainer: styles.PackItemMobile__ItemContainer,
                          ItemTextContainer: styles.PackItemMobile__ItemTextContainer,
                          ItemTitle: styles.PackItemMobile__ItemTitle,
                          Icon: styles.PackItemMobile__Icon,
                        }}
                      />
                    </div>
                  );
                })}
              </div>
              <div className={styles.CheckBoxContainer}>
                <CheckBox
                  selected={selectedAgree}
                  onChange={() => {
                    setErrorPrivacy(false);
                    setSelectedAgree(!selectedAgree);
                  }}
                  classNames={{
                    ContainerImage: styles.CheckBoxImage,
                    Container: styles.CheckBox,
                    Title: styles.CheckBoxTitle,
                  }}
                  isError={isErrorPrivacy}
                />
                <div className={styles.CheckBoxTextContainer}>
                  <p
                    className={styles.RightsText}
                    dangerouslySetInnerHTML={{
                      __html: sanitize(stores.translate('i_agree_to_the')),
                    }}
                  />
                  &nbsp;
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={URLS.TERMS_OF_USE}
                    className={classnames(styles.RightsText, styles.TermsLink)}
                    onClick={() => {
                      gtagEvent(GOOGLE_ANALYTICS.CLICK_TERMS);
                    }}
                  >
                    {stores.translate('terms_of_use_capital_letter')},
                  </a>
                  &nbsp;
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={URLS.PRIVACY_POLICY}
                    className={classnames(styles.RightsText, styles.PrivacyLink)}
                    onClick={() => {
                      gtagEvent(GOOGLE_ANALYTICS.CLICK_PRIVACY);
                    }}
                  >
                    {stores.translate('privacy_policy')},
                  </a>
                  &nbsp;
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={URLS.COIN_SALE}
                    className={classnames(styles.RightsText, styles.CoinSaleLink)}
                    onClick={() => {
                      gtagEvent(GOOGLE_ANALYTICS.CLICK_PRIVACY);
                    }}
                  >
                    {stores.translate('coin_sale_agreement')}
                  </a>
                </div>
              </div>
              <div className={styles.BitmartExchangeContainer}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={URLS.LISTING_HONEYCOIN}
                  className={classnames(styles.RightsText, styles.CoinSaleLink)}
                  onClick={() => {
                    gtagEvent(GOOGLE_ANALYTICS.CLICK_PRIVACY);
                  }}
                >
                  {stores.translate('if_no_have_cone_go_to_exchange')}
                </a>
              </div>
              <div className={styles.PaymentsButtonContainer}>
                {/* <Button
                  size={SizeButton.MEDIUM}
                  colorType={ColorType.ORANGE}
                  icon={BinanceIcon}
                  onClick={() => {
                    if (selectedAgree) {
                      binancePaymentClick();
                      gtagEvent(GOOGLE_ANALYTICS.CLICK_BINANCE_PAYMENT);
                    } else {
                      setErrorPrivacy(true);
                    }
                  }}
                  title={`${packInfo.PRICE} USDT`}
                  classNames={{
                    Container: styles.ButtonContainer,
                    Button: styles.Button,
                    ProgressIndicator: styles.ProgressIndicator,
                    Title: stores.browserInfo.isAndroid
                      ? classnames(
                          styles.TitleOrangeButton,
                          styles.TitleOrangeButton__android,
                          styles.TitleOrangeButton__Payments,
                        )
                      : classnames(
                          styles.TitleOrangeButton,
                          styles.TitleOrangeButton__Payments,
                          styles.TitleOrangeButton__Payments,
                        ),
                  }}
                  isLoading={isLoadingBinance}
                  disabled={processPayment !== PREPARE_STEP}
                /> */}
                <Button
                  size={SizeButton.MEDIUM}
                  colorType={ColorType.ORANGE}
                  icon={ConeIcon}
                  onClick={() => {
                    if (selectedAgree) {
                      burnConeClick();
                      gtagEvent(GOOGLE_ANALYTICS.CLICK_BURN_CONE);
                    } else {
                      setErrorPrivacy(true);
                    }
                  }}
                  title={`${packInfo.PRICE_CONE} CONE`}
                  classNames={{
                    Container: styles.ButtonContainer,
                    Button: styles.Button,
                    ProgressIndicator: styles.ProgressIndicator,
                    Title: stores.browserInfo.isAndroid
                      ? classnames(
                          styles.TitleOrangeButton,
                          styles.TitleOrangeButton__android,
                          styles.TitleOrangeButton__Payments,
                        )
                      : classnames(styles.TitleOrangeButton, styles.TitleOrangeButton__Payments),
                  }}
                  isLoading={isLoadingBurnCone}
                  disabled={processPayment !== PREPARE_STEP}
                />
              </div>
            </>
          )}


          {processPayment === FINISH_STEP && (
            <>
              {responseBinance && (
                <div className={styles.PaymentInfoContainer}>
                  {statusSellingPack === SELLING_PACKS_STATUSES.WAITING ? (
                    <>
                      <p className={styles.PaymentInfoTitle}>
                        {stores.translate('link_for_paying_package')}, {selectedPack}:
                      </p>
                      {responseBinance.payment_links && responseBinance.payment_links.checkoutUrl && (
                        <a
                          href={
                            stores.browserInfo.isMobile
                              ? responseBinance.payment_links?.deeplink
                              : responseBinance.payment_links?.checkoutUrl
                          }
                          className={styles.PaymentInfoLink}
                        >
                          {stores.browserInfo.isMobile
                            ? responseBinance.payment_links?.deeplink
                            : responseBinance.payment_links?.checkoutUrl}
                        </a>
                      )}
                      <div className={styles.ButtonsContainer}>
                        <Button
                          size={SizeButton.MEDIUM}
                          colorType={ColorType.ORANGE}
                          onClick={() => {
                            copyLink();
                            gtagEvent(
                              `${GOOGLE_ANALYTICS.CLICK_COPY_LINK} ${'Binance'}`,
                            );
                          }}
                          title={stores.translate('copy_link')}
                          classNames={{
                            Container: styles.CopyLinkButtonContainer,
                            Button: styles.Button,
                            Title: stores.browserInfo.isAndroid
                              ? classnames(
                                  styles.TitleOrangeButton,
                                  styles.TitleOrangeButton__android,
                                )
                              : styles.TitleOrangeButton,
                          }}
                          isLoading={isLoadingBinance}
                          valueScaleAnimation={0.95}
                        />
                        {copied && (
                          <InformationPopup
                            info={stores.translate('copied')}
                            classNames={{ Container: styles.InformationPopupCopied }}
                          />
                        )}
                      </div>

                      {responseBinance.payment_links &&
                        responseBinance.payment_links.qrcodeLink && (
                          <img
                            onLoad={() =>
                              stores.increaseDownloadedImageCounter(
                                responseBinance.payment_links.qrcodeLink,
                              )
                            }
                            onError={() => Logger.error('Error with image loading')}
                            src={responseBinance.payment_links.qrcodeLink}
                            alt="binance_qr_code"
                            className={styles.BinanceQrCode}
                          />
                        )}
                      <div className={styles.PaymentInfoAttentionContainer}>
                        <p className={styles.PaymentInfoAttention}>
                          {stores.translate('notes_one_purchase_payment')}
                        </p>
                      </div>
                    </>
                  ) : statusSellingPack === SELLING_PACKS_STATUSES.TRANSFERRING ? (
                    <>
                      <p className={styles.PaymentInfoTitle}>
                        {stores.translate('your_invoice_processed')}
                      </p>
                      <BeeSpinner
                        classNames={{ Spinner: styles.LoadingTransferringPackContainer }}
                      />
                    </>
                  ) : (
                    <>
                      <p className={styles.PaymentInfoTitle}>
                        {stores.translate('error_occurred_receiving_selling_pack')}:
                      </p>
                      <div className={styles.PaymentInfoAttentionContainer}>
                        <p className={styles.PaymentInfoAttention}>{errorTextSellingPack}</p>
                      </div>
                    </>
                  )}
                </div>
              )}
              {responseBurnCones && (
                <div className={styles.PaymentInfoContainer}>
                  {statusSellingPack === SELLING_PACKS_STATUSES.WAITING ? (
                    <>
                      <p className={styles.PaymentInfoTitle}>
                        {stores.translate('your_request_burning')}
                      </p>
                      <BeeSpinner
                        classNames={{ Spinner: styles.LoadingTransferringPackContainer }}
                      />
                    </>
                  ) : statusSellingPack === SELLING_PACKS_STATUSES.TRANSFERRING ? (
                    <>
                      <p className={styles.PaymentInfoTitle}>
                        {stores.translate('your_invoice_processed')}
                      </p>
                      <BeeSpinner
                        classNames={{ Spinner: styles.LoadingTransferringPackContainer }}
                      />
                    </>
                  ) : (
                    <>
                      <p className={styles.PaymentInfoTitle}>
                        {stores.translate('error_occurred_receiving_selling_pack')}:
                      </p>
                      <div className={styles.PaymentInfoAttentionContainer}>
                        <p className={styles.PaymentInfoAttention}>{errorTextSellingPack}</p>
                      </div>
                    </>
                  )}
                </div>
              )}
              {statusSellingPack === SELLING_PACKS_STATUSES.ERROR_PAYMENT && (
                <>
                  <p className={styles.PaymentInfoTitle}>
                    {stores.translate('chosen_selling_pack')}, «{packInfo.TITLE}»:
                  </p>
                  <div className={styles.PaymentInfoAttentionContainer}>
                    <p className={styles.PaymentInfoAttention}>Error: {errorTextSellingPack}</p>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
});

export default PaymentsHoneyUnity;
