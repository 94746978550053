import _ from 'lodash';
import { BEARS } from 'src/api';
import { stringify, toFloatApproximation } from '@utils/common';
import { TYPE_AUTH_CLIENT } from '@constants/blockchain';
import { GameMethodNames, GameObjectNames } from 'src/stores/unity';
import { StoreInstance } from 'src/stores';
import Logger from '@utils/logger';
import methodsBears from '@utils/methodsBears';
import KeplrWallet from 'src/api/KeplrWallet';

import { handleMainError } from '../HandleError';

const stores = StoreInstance;

const updateHoney = async () => {
  // if chain delete then quit game
  if (stores.typeAuthClient === TYPE_AUTH_CLIENT.KEPLR) {
    await KeplrWallet.checkExistedChain();
  }

  const response = await BEARS.getHoney({ address: stores.unityStore.address });
  if (response.code > 0) {
    Logger.infoMainWithKey('error in updateHoney, code', response.code);
    // handleMainError(response);
  } else {
    stores.setProfile({ ...stores.profile, honey: response });
    stores.unityStore.send({
      objectName: GameObjectNames.FarmReact,
      methodName: GameMethodNames.UpdateHoney,
      values: stringify({ honey: response }),
    });
  }
};

const updateHoneyInApiaries = async () => {
  if (stores.unityStore.bearId === -1 || !stores.unityStore.isEnteringFarming) {
    return;
  }
  const bearIdValue = stores.unityStore.bearId;
  const response = await BEARS.getApiariesInfoByBearId({ bearId: bearIdValue });
  if (response.code > 0) {
    Logger.infoMainWithKey('error in updateHoneyInApiaries, code', response.code);
    // handleMainError(response)
  } else if (response.result && response.result.length > 0) {
    response.result.forEach((apiary) => {
      const currentHoney = toFloatApproximation({ value: apiary.countHoney });
      if (apiary.countHoney > 0) {
        stores.unityStore.send({
          objectName: GameObjectNames.FarmReact,
          methodName: GameMethodNames.UpdateHoneyInApiary,
          values: stringify({ id: apiary.id, currentHoney }),
        });
      }
    });
  }
};

const updateHoneyBar = async () => {
  if (stores.unityStore.bearId === -1 || !stores.unityStore.isEnteringFarming) {
    return;
  }
  const { bearId, powerMaxBee, blocksPerHour } = stores.unityStore;
  const response = await Promise.all([
    BEARS.getHoneyPowerBear({ bearId }),
    BEARS.getHoneyMaxPowerBear({ bearId, powerMaxBee })
  ]);
  // const responsePowerBear = await BEARS.getHoneyPowerBear({ bearId });
  // const responsePowerMaxBear = await BEARS.getHoneyMaxPowerBear({ bearId, powerMaxBee });
  const responsePowerBear = response[0];
  const responsePowerMaxBear = response[1];
  if (responsePowerBear.code > 0) {
    Logger.infoMainWithKey('error in getHoneyPowerBear, code', responsePowerBear.code);
    // handleMainError(responsePowerBear);
  } else if (responsePowerMaxBear.code > 0) {
    Logger.infoMainWithKey('error in getHoneyMaxPowerBear, code', responsePowerMaxBear.code);
    // handleMainError(responsePowerMaxBear);
  } else {
    stores.unityStore.send({
      objectName: GameObjectNames.FarmReact,
      methodName: GameMethodNames.UpdateHoneyBar,
      values: stringify({
        currentProductionHoney: responsePowerBear.result * blocksPerHour,
        maxProductionHoney: responsePowerMaxBear.result,
      }),
    });
  }
};

const updateCONE = async () => {
  const { address } = stores.unityStore;
  const responseAll = await Promise.all([
    BEARS.getCONE({ address }),
    BEARS.getStakedCones({ address })
  ]);
  const response = responseAll[0];
  const responseStaked = responseAll[1]; // cone

  stores.unityStore.send({
    objectName: GameObjectNames.FarmReact,
    methodName: GameMethodNames.UpdateCone,
    values: stringify({ freeCone: response, stakingCone: responseStaked }),
  });
  return response;
};

const updateOxygen = async () => {
  if (!stores.unityStore.isEnteringFarming) {
    return;
  }
  const response = await BEARS.getLastAirInfo();
  if (_.isEmpty(response) || response.err || response.consume === 0) {
    // handleMainError({ code: 111 });
    Logger.infoMainWithKey('error in updateOxygen, response', response);
  } else {
    stores.unityStore.send({
      objectName: GameObjectNames.FarmReact,
      methodName: GameMethodNames.UpdateOxy,
      values: stringify({ oxygen: response.count, maxOxygen: response.purity }),
    });
  }
};

const updateStatesTimerInterval = async () => {
  try {
    await Promise.all([
      updateOxygen(),
      updateHoneyBar(),
      updateHoneyInApiaries(),
    ])
    // await updateOxygen();
    // await updateHoneyBar();
    // await updateHoneyInApiaries();
  } catch (err) {
    handleMainError({ code: 111, useProduction: false }, err);
  }
};

const updateHoneyTimerInterval = async () => {
  try {
    await updateHoney();
  } catch (err) {
    handleMainError({ code: 111, useProduction: false }, err);
  }
};

export default {
  updateHoney,
  updateHoneyInApiaries,
  updateHoneyBar,
  updateCONE,
  updateOxygen,
  updateStatesTimerInterval,
  updateHoneyTimerInterval,
};
