import React from 'react';
import { observer } from 'mobx-react';
import { sanitize } from 'dompurify';
import { useNavigate } from 'react-router-dom';
import { useStores } from '@src/stores';
import classnames from 'classnames';
import TextH5 from '@components_new/TextH5';
import { Button, ColorType, SizeButton } from 'src/components_new/Button';
import { GOOGLE_ANALYTICS } from '@src/constants/googleAnalyticsEvents';
import { gtagEvent } from '@utils/common';
import { ENUM_MODAL } from '@components_new/Modal/enumModal';
import TitleSection from '@src/components/TitleSection';
import OxygenImage from '@assets/Mobile/HomePage/GameInfoSection/oxygen_image.png';
import Match3Image from '@assets/Mobile/HomePage/GameInfoSection/match3_image.png';
import ApiaryImage from '@assets/Mobile/HomePage/GameInfoSection/apiary_image.png';
import { useMediaQuery } from 'src/helpers/media';
import Logger from '@utils/logger';
import styles from './mobileGameInfo.module.scss';

const GameInfoItem = ({ title = 'Title', textHtml = 'SubTitle', icon = {}, classNames = {} }) => {
  const stores = useStores();
  return (
    <div className={classnames(styles.GameInfoItem, classNames.GameInfoItem)}>
      <img
        onLoad={() => stores.increaseDownloadedImageCounter(icon)}
        onError={() => Logger.error('Error with image loading')}
        src={icon}
        alt="GameInfo_item_icon"
        className={classnames(styles.GameInfoItemImage, classNames.GameInfoItemImage)}
      />
      <div
        className={classnames(
          styles.GameInfoItemTextContainer,
          classNames.GameInfoItemTextContainer,
        )}
      >
        <TextH5 classNames={styles.GameInfoTitle} text={title} />
        <p
          className={styles.GameInfoText}
          dangerouslySetInnerHTML={{ __html: sanitize(textHtml) }}
        />
      </div>
    </div>
  );
};

const GameInfoSection = observer(() => {
  const stores = useStores();
  const navigate = useNavigate();
  const _ = stores.language;
  const isMoreThan1300px = useMediaQuery('(min-width: 1300px)');

  const gameClick = () => {
    navigate('/game');
  };

  const joinClick = () => {
    stores.setOpenModal(ENUM_MODAL.JOIN);
  };

  return (
    <div className={styles.GameInfoSectionContainer}>
      <div className={styles.GameInfoSection} id="game-info">
        <div className={isMoreThan1300px ? styles.TitleSectionWrapper : undefined}>
          <TitleSection
            title={stores.translate('gameInfo_title_mobile')}
            classNames={{
              Title: isMoreThan1300px && styles.Title,
            }}
          />
          <div
            className={classnames(
              styles.ButtonsContainer,
              !isMoreThan1300px && styles.ButtonsContainer__Display_none,
            )}
          >
            <Button
              colorType={ColorType.GREEN}
              size={SizeButton.MEDIUM}
              title={stores.translate('play')}
              classNames={{
                Container: classnames(styles.PlayButtonContainer),
                Title: classnames(
                  styles.TitleButton,
                  stores.browserInfo.isAndroid
                    ? styles.TitleGreenButton__android
                    : styles.TitleGreenButton,
                ),
                Button: styles.PlayButton,
              }}
              onClick={() => {
                gtagEvent(GOOGLE_ANALYTICS.CLICK_PLAY);
                if (stores.isAuthorized) {
                  gameClick();
                } else {
                  joinClick();
                }
              }}
            />
            {/* <Button
              colorType={ColorType.ORANGE}
              size={SizeButton.MEDIUM}
              title={stores.translate('buy')}
              classNames={{
                Title: classnames(
                  styles.TitleButton,
                  stores.browserInfo.isAndroid
                    ? styles.TitleOrangeButton__android
                    : styles.TitleOrangeButton,
                ),
                Button: styles.BuyButton,
              }}
              onClick={() => {
                gtagEvent(GOOGLE_ANALYTICS.CLICK_BUY);
                const { isMobile } = stores.browserInfo;
                const targetId = document.getElementById('selling-pack');
                const yOffset = isMobile ? 0 : 0;
                const y = targetId.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({
                  top: y,
                  behavior: 'smooth',
                });
              }}
            /> */}
          </div>
        </div>
        <div className={styles.GameInfoItems}>
          <GameInfoItem
            title={stores.translate('title_oxygen_mobile')}
            textHtml={stores.translate('description_oxygen_mobile')}
            icon={OxygenImage}
            classNames={{
              GameInfoItemImage: styles.OxygenImage,
            }}
          />
          <GameInfoItem
            title={stores.translate('title_pvp_match3_mobile')}
            textHtml={stores.translate('description_pvp_match3_mobile')}
            icon={Match3Image}
            classNames={{
              GameInfoItem: styles.FlexRowReverse,
              GameInfoItemImage: styles.Match3Image,
            }}
          />
          <GameInfoItem
            title={stores.translate('title_farming_mobile')}
            textHtml={stores.translate('description_farming_mobile')}
            icon={ApiaryImage}
            classNames={{
              GameInfoItemImage: styles.ApiaryImage,
            }}
          />
        </div>
        <div
          className={classnames(
            styles.ButtonsContainer,
            isMoreThan1300px && styles.ButtonsContainer__Display_none,
          )}
        >
          <Button
            colorType={ColorType.GREEN}
            size={SizeButton.MEDIUM}
            title={stores.translate('play')}
            classNames={{
              Container: classnames(styles.PlayButtonContainer),
              Title: classnames(
                styles.TitleButton,
                stores.browserInfo.isAndroid
                  ? styles.TitleGreenButton__android
                  : styles.TitleGreenButton,
              ),
              Button: styles.PlayButton,
            }}
            onClick={() => {
              gtagEvent(GOOGLE_ANALYTICS.CLICK_PLAY);
              if (stores.isAuthorized) {
                gameClick();
              } else {
                joinClick();
              }
            }}
          />
          {/* <Button
            colorType={ColorType.ORANGE}
            size={SizeButton.MEDIUM}
            title={stores.translate('buy')}
            classNames={{
              Title: classnames(
                styles.TitleButton,
                stores.browserInfo.isAndroid
                  ? styles.TitleOrangeButton__android
                  : styles.TitleOrangeButton,
              ),
              Button: styles.BuyButton,
            }}
            onClick={() => {
              gtagEvent(GOOGLE_ANALYTICS.CLICK_BUY);
              const { isMobile } = stores.browserInfo;
              const targetId = document.getElementById('selling-pack');
              const yOffset = isMobile ? 0 : 0;
              const y = targetId.getBoundingClientRect().top + window.pageYOffset + yOffset;
              window.scrollTo({
                top: y,
                behavior: 'smooth',
              });
            }}
          /> */}
        </div>
      </div>
    </div>
  );
});

export default GameInfoSection;
