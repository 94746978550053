import React from 'react';
import classnames from 'classnames';
import { sanitize } from 'dompurify';
import { useStores } from 'src/stores';
import SmallBeeIcon from '@assets/SpecialPresaleSection/small_bee_icon.svg';
import ApiaryIcon from '@assets/SpecialPresaleSection/apiary_icon.svg';
import FountainIcon from '@assets/SpecialPresaleSection/fountain_icon.svg';
import LandIcon from '@assets/SpecialPresaleSection/land_icon.svg';
import ScaryaryIcon from '@assets/Halloween/Aplary.png';
import PumPumIcon from '@assets/Halloween/pumpkin.png';
import HoneyBatIcon from '@assets/Halloween/bee.png';
import BlessIcon from '@assets/Halloween/bless.png';

import {
  APIARY,
  BEE,
  DECORATION,
  FIELD_EXTENDING,
  HONEY_BAT,
  PUM_PUM,
  SCARYARY,
} from '@constants/blockchain';

import Logger from '@utils/logger';
import styles from './packItem.module.scss';

const PackItem = ({
  type = '',
  title = '',
  itemsDescription = {},
  price = '',
  params = {},
  classNames = {},
}) => {
  const stores = useStores();
  const getInfoByType = (kind, paramsKind) => {
    const beeInfo = (typeItem) => {
      switch (typeItem) {
        case HONEY_BAT:
          return [HoneyBatIcon, itemsDescription?.honeyBatDescription];
        default:
          return [SmallBeeIcon, itemsDescription?.beeDescription];
      }
    };
    const apiaryInfo = (typeItem) => {
      switch (typeItem) {
        case SCARYARY:
          return [ScaryaryIcon, itemsDescription?.scaryaryDescription];
        default:
          return [ApiaryIcon, itemsDescription?.apiaryDescription];
      }
    };
    const decorationInfo = (typeItem) => {
      switch (typeItem) {
        case 'PUM-PUM':
        case PUM_PUM:
          return [PumPumIcon, itemsDescription?.pumPumDescription];
        default:
          return [FountainIcon, itemsDescription?.fountainDescription];
      }
    };
    switch (kind) {
      case BEE:
        return beeInfo(paramsKind.beeType);
      case APIARY:
        return apiaryInfo(paramsKind.apiaryType);
      case DECORATION:
        return decorationInfo(paramsKind.decorationType);
      case FIELD_EXTENDING:
        return [LandIcon, ''];
      default:
        return [SmallBeeIcon, itemsDescription?.beeDescription];
    }
  };

  return (
    <div className={classnames(styles.ItemContainer, classNames.ItemContainer)}>
      <div className={classnames(styles.ItemTitleContainer, classNames.ItemTitleContainer)}>
        <p
          className={classnames(styles.ItemTitle, classNames.ItemTitle)}
          dangerouslySetInnerHTML={{ __html: sanitize(title) }}
        />
      </div>
      <div className={classnames(styles.ItemBlock, classNames.ItemBlock)}>
        <div className={classnames(styles.IconContainer, classNames.IconContainer)}>
          <img
            onLoad={() => stores.increaseDownloadedImageCounter(BlessIcon)}
            onError={() => Logger.error('Error with image loading')}
            src={BlessIcon}
            alt="BlessIcon"
            className={styles.BlessIcon}
          />
          <img
            onLoad={() => stores.increaseDownloadedImageCounter(getInfoByType(type, params)[0])}
            onError={() => Logger.error('Error with image loading')}
            src={getInfoByType(type, params)[0]}
            alt={`${title}_icon`}
            className={classnames(styles.Icon, classNames.Icon)}
          />
        </div>
      </div>
      <div
        className={classnames(
          styles.ItemTextContainer,
          !getInfoByType(type, params)[1] && styles.ItemTextContainer__height,
          classNames.ItemTextContainer,
        )}
      >
        <div
          className={classnames(
            styles.ItemDescriptionContainer,
            !getInfoByType(type, params)[1] && styles.ItemDescriptionContainer__noHeight,
            classNames.ItemDescriptionContainer,
          )}
        >
          <p
            className={classnames(styles.ItemDescription, classNames.ItemDescription)}
            dangerouslySetInnerHTML={{ __html: sanitize(getInfoByType(type, params)[1]) }}
          />
        </div>
      </div>
      <div className={classnames(styles.PriceContainer, classNames.PriceContainer)}>
        {/* <img
          src={TetherUsdtWhiteIcon}
          alt="tether_usdt_white_icon"
          className={classnames(
            price ? styles.TetherUsdtWhiteIcon : styles.TetherUsdtWhiteIcon__Display_none,
            classNames.TetherUsdtWhiteIcon,
          )}
        /> */}
        <p
          className={classnames(
            styles.ItemPrice,
            classNames.ItemPrice,
            !price && styles.ItemPrice__Free,
          )}
        >
          {price ? `${sanitize(price)} USDT` : 'Free bonus'}
        </p>
      </div>
    </div>
  );
};

export default PackItem;
