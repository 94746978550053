import React from 'react';
import { MILLION, PRODUCTION, BETA, DEVELOPMENT } from '@constants/system';
import { Decimal } from '@cosmjs/math';
import _ from 'lodash';
import { StoreInstance } from 'src/stores';

// code get from https://github.com/DonRai/react-image-webp/blob/master/modules/utils/index.js
let support;

/**
 * Check browser webp support
 * @returns {boolean}
 */
export const isWebpSupported = () => {
  if (typeof support !== 'undefined') return support;

  const elem = typeof document === 'object' ? document.createElement('canvas') : {};

  support = elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;

  return support;
};

export const gtagEvent = (event, additional = '', addressFromSeedModal = '') => {
  const address = StoreInstance.isAuthorized
    ? StoreInstance.profileAvailable.address.slice(-35)
    : addressFromSeedModal.slice(-35) || '';
  // console.log('event: ', event, 'additional: ', additional, 'address: ', address);
  if (
    process.env.REACT_APP_STAGE === PRODUCTION ||
    // process.env.REACT_APP_STAGE === PRODUCTION
    // process.env.REACT_APP_STAGE === BETA ||
    process.env.REACT_APP_STAGE === DEVELOPMENT
  ) {
    if (address) {
      return window.dataLayer.push({
        event: 'GAevent',
        eventCategory: event,
        eventAction: additional || 'prepare',
        eventLabel:
          event === 'CLICK_REGISTRATION' ? 'registration' : event === 'CLICK_LOGIN' ? 'login' : '',
        PATHNAME: window.location.pathname,
        userId: address,
      });
    }
    return window.dataLayer.push({
      event: 'GAevent',
      eventCategory: event,
      eventAction: additional || 'prepare',
      eventLabel:
        event === 'CLICK_REGISTRATION' ? 'registration' : event === 'CLICK_LOGIN' ? 'login' : '',
      PATHNAME: window.location.pathname,
    });
  }
  return false;
};

export const copyText = async ({ text }) => {
  // console.log('text >> ', text);
  const textField = document.createElement('textarea');
  textField.innerText = text;
  document.body.appendChild(textField);
  // console.log('document >> ', document);
  // const canvas = document.getElementById('unity-canvas-1');
  // console.log('canvas >> ', canvas);
  // canvas.appendChild(textField)
  textField.select();
  document.execCommand('copy');
  textField.remove();
};

export const toFixedNumberWithoutRound = ({ number, countSign }) => {
  return parseFloat(number.toFixed(countSign + 1).replace(/.$/, '')).toString();
};

export const toFloatApproximation = ({ value, coef = 1.0 }) => {
  return (Decimal.fromAtomics(value, 18).toFloatApproximation() / MILLION) * coef;
};

export const floorRounding = (value, precision = 6) => {
  return value ? _.floor(value, precision) : undefined;
};

export const numberWithSeparator = (
  number,
  separatorForIntegerPart = ',',
  dividerOfNumberParts = '.',
) => {
  const splittedNumberArray = number.toString().split(dividerOfNumberParts);
  const result =
    splittedNumberArray.length > 1
      ? `${splittedNumberArray[0].replace(/\B(?=(\d{3})+(?!\d))/g, separatorForIntegerPart)}.${
          splittedNumberArray[1]
        }`
      : splittedNumberArray[0].replace(/\B(?=(\d{3})+(?!\d))/g, separatorForIntegerPart);
  return result;
};

export const stringify = (object) => {
  return JSON.stringify(object);
};

export const getShortAddress = (address, isMobileMenu = false, isAccountOverview = false) => {
  const points = isMobileMenu ? '................' : '...';
  if (address && address.length > 12) {
    return isAccountOverview
      ? [...address.slice(0, 17), points, address.slice(-7)]
      : [...address.slice(0, 7), points, address.slice(-6)];
  }
  return address;
};

export const findAttributes = (
  logs = [],
  eventType = '',
  attrKey = '',
  attrValueSymbol = undefined,
) => {
  if (logs && logs.length < 0) return undefined;

  /* eslint-disable-next-line no-restricted-syntax */
  for (const log of logs[0].events) {
    if (log.type === eventType) {
      const attributeResult = log.attributes.find((attribute) => {
        return (
          attribute.key === attrKey &&
          (!attrValueSymbol || (attrValueSymbol && attribute.value.includes(attrValueSymbol)))
        );
      });
      if (attributeResult) {
        if (attrValueSymbol) {
          return attributeResult.value.split(attrValueSymbol)[0];
        }
        return attributeResult.value;
      }
    }
  }
  return undefined;
};

export const timeout = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default {
  isWebpSupported,
  gtagEvent,
  copyText,
  toFixedNumberWithoutRound,
  toFloatApproximation,
  stringify,
  getShortAddress,
  floorRounding,
  numberWithSeparator,
  findAttributes,
  timeout,
};
