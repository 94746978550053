import React, { useState } from 'react';
import 'swiper/swiper.min.css';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import { sanitize } from 'dompurify';
import classnames from 'classnames';
import { useMediaQuery } from 'src/helpers/media';

import { Button, ColorType, SizeButton } from 'src/components_new/Button';
import { gtagEvent } from '@utils/common';
import { GOOGLE_ANALYTICS } from '@src/constants/googleAnalyticsEvents';
import GradientBottom from '@assets/Mobile/AboutPage/OurTeamSection/gradient_bottom.svg';
import BackQuotes from '@assets/ArticlesSection/back_quotes.svg';
import DirectQuotes from '@assets/ArticlesSection/direct_quotes.svg';
import BenzingaLogo from '@assets/Mobile/PartnershipPage/ArticlesSection/benzinga_logo_mobile.png';
import CoinCheckupLogo from '@assets/Mobile/PartnershipPage/ArticlesSection/coinCheckup_logo_mobile.png';
import CryptoModeLogo from '@assets/Mobile/PartnershipPage/ArticlesSection/crypto_mode_logo_mobile.png';
import PublishOXLogo from '@assets/Mobile/PartnershipPage/ArticlesSection/publishOX_logo_mobile.png';
import TheCryptoUpdatesLogo from '@assets/Mobile/PartnershipPage/ArticlesSection/the_crypto_updates_logo_mobile.png';
import { ARTICLES_INFO, ARTICLES_SHOW_CARDS_COUNT } from '@constants/system';
import TitleSection from '@components/TitleSection';
import Logger from '@utils/logger';
import styles from './articles.module.scss';

const ArticleItem = ({ logo = {}, articleLink = '', articleQuote = '', lastCard = false }) => {
  const stores = useStores();
  const getLogo = (image) => {
    switch (image) {
      case 'BenzingaLogo':
        return BenzingaLogo;
      case 'CoinCheckupLogo':
        return CoinCheckupLogo;
      case 'TheCryptoUpdatesLogo':
        return TheCryptoUpdatesLogo;
      case 'CryptoModeLogo':
        return CryptoModeLogo;
      case 'PublishOXLogo':
        return PublishOXLogo;
      default:
        return null;
    }
  };
  return (
    <a href={articleLink} target="_blank" rel="noreferrer" className={styles.ArticleWrapperLink}>
      <div className={classnames(styles.ArticleCard, lastCard && styles.ArticleCard__lastCard)}>
        <img
          onLoad={() => stores.increaseDownloadedImageCounter(logo)}
          onError={() => Logger.error('Error with image loading')}
          src={getLogo(logo)}
          alt={`${logo}_logo`}
          className={styles.ArticleItemImage}
        />
        {articleQuote && !lastCard && (
          <div className={styles.ArticleCardInfoContainer}>
            <div className={styles.ArticleCardInfoWrapper}>
              <div className={styles.DirectQuotesImageWrapper}>
                <img
                  onLoad={() => stores.increaseDownloadedImageCounter(DirectQuotes)}
                  onError={() => Logger.error('Error with image loading')}
                  src={DirectQuotes}
                  alt="direct_quotes"
                />
              </div>
              <p
                className={styles.ArticleQuote}
                dangerouslySetInnerHTML={{ __html: sanitize(articleQuote) }}
              />
              <div className={styles.BackQuotesImageWrapper}>
                <img
                  onLoad={() => stores.increaseDownloadedImageCounter(BackQuotes)}
                  onError={() => Logger.error('Error with image loading')}
                  src={BackQuotes}
                  alt="back_quotes"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </a>
  );
};

const ArticlesSection = observer(() => {
  const stores = useStores();
  const isLessThan650px = useMediaQuery('(max-width: 650px)');
  const [isShowOtherTeamMembers, setShowOtherTeamMembers] = useState(false);

  if (isLessThan650px) {
    return (
      <div className={styles.Container} id="articles">
        <TitleSection
          title={stores.translate('featured_by')}
          classNames={{ Title: styles.TitleSectionInnerContainer }}
          isH2TitleTag
        />
        <div className={styles.ArticleCardContainer}>
          {ARTICLES_INFO.map((article, index) => {
            if (index < ARTICLES_SHOW_CARDS_COUNT) {
              return (
                <ArticleItem
                  logo={article.logo}
                  articleLink={article.articleLink}
                  articleQuote={article.articleQuote}
                  key={article.logo}
                  lastCard={index === ARTICLES_SHOW_CARDS_COUNT - 1 && !isShowOtherTeamMembers}
                />
              );
            }
            return false;
          })}
          {!isShowOtherTeamMembers && (
            <img
              onLoad={() => stores.increaseDownloadedImageCounter(GradientBottom)}
              onError={() => Logger.error('Error with image loading')}
              src={GradientBottom}
              alt="person_photo"
              className={styles.GradientBottom}
            />
          )}
        </div>
        {!isShowOtherTeamMembers && (
          <Button
            colorType={ColorType.ORANGE}
            size={SizeButton.SMALL}
            title={stores.translate('show_more')}
            classNames={{
              Container: styles.ShowButtonContainer,
              Button: styles.ShowMoreButton,
              Title: classnames(
                styles.TitleButton,
                stores.browserInfo.isAndroid
                  ? styles.TitleOrangeButton__android
                  : styles.TitleOrangeButton,
              ),
            }}
            onClick={() => {
              gtagEvent(GOOGLE_ANALYTICS.CLICK_SHOW_MORE);
              setShowOtherTeamMembers(true);
            }}
          />
        )}
        {isShowOtherTeamMembers && (
          <>
            <div className={styles.ArticleCardContainer}>
              {ARTICLES_INFO.map((article, index) => {
                if (index >= ARTICLES_SHOW_CARDS_COUNT) {
                  return (
                    <ArticleItem
                      logo={article.logo}
                      articleLink={article.articleLink}
                      articleQuote={article.articleQuote}
                      key={article.logo}
                    />
                  );
                }
                return false;
              })}
            </div>
            <Button
              colorType={ColorType.ORANGE}
              size={SizeButton.SMALL}
              title={stores.translate('hide')}
              classNames={{
                Container: styles.HideButtonContainer,
                Button: styles.ShowMoreButton,
                Title: classnames(
                  styles.TitleButton,
                  stores.browserInfo.isAndroid
                    ? styles.TitleOrangeButton__android
                    : styles.TitleOrangeButton,
                ),
              }}
              onClick={() => {
                gtagEvent(GOOGLE_ANALYTICS.CLICK_HIDE);
                setShowOtherTeamMembers(false);
              }}
            />
          </>
        )}
      </div>
    );
  }
  return (
    <div className={styles.Container} id="articles">
      <TitleSection
        title={stores.translate('featured_by')}
        classNames={{
          Container: styles.TitleSectionOuterContainer,
          Title: styles.TitleSectionInnerContainer,
        }}
      />
      <div className={styles.ArticleCardContainer}>
        {ARTICLES_INFO.map((article) => {
          return (
            <ArticleItem
              logo={article.logo}
              articleLink={article.articleLink}
              articleQuote={article.articleQuote}
              key={article.logo}
            />
          );
        })}
      </div>
    </div>
  );
});

export default ArticlesSection;
