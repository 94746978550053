import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useStores } from '@src/stores';
import { gtagEvent } from '@utils/common';
import { GOOGLE_ANALYTICS } from '@src/constants/googleAnalyticsEvents';
import { APP_STAGE, DEVELOPMENT } from '@constants/system';
import URLS from '@src/constants/urls';
import { Button, ColorType, SizeButton } from 'src/components_new/Button';
import Input from '@components/Input';
import styles from './questionForm.module.scss';

const QuestionForm = observer(({ onSubmit = () => {} }) => {
  const stores = useStores();
  const [isLoading, setLoading] = useState(false);
  const [errorFullNameText, setErrorFullNameText] = useState('');
  const [errorEmailText, setErrorEmailText] = useState('');
  const [errorQuestionText, setErrorQuestionText] = useState('');
  const _ = stores.language;

  const schemaQuestion = yup
    .object()
    .shape({
      fullName: yup
        .string()
        .required(stores.translate('full_name_input_required'))
        .matches(
          /^[a-zA-ZёЁа-яА-Я0-9\s']+$/,
          stores.translate('full_name_input_special_characters_validation'),
        )
        .min(2, stores.translate('full_name_input_validation')),
      email: yup
        .string()
        .email(stores.translate('email_input_validation'))
        .required(stores.translate('email_input_required')),
      question: yup
        .string()
        .required(stores.translate('question_input_required'))
        .min(2, stores.translate('question_input_validation')),
    })
    .required();

  // eslint-disable-next-line no-shadow
  const QuestionForm = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schemaQuestion),
    defaultValues: {
      fullName: '',
      email: '',
      question: '',
    },
  });
  const QuestionFields = QuestionForm.watch();

  useEffect(() => {
    setErrorFullNameText(
      QuestionForm.formState.errors?.fullName ? QuestionForm.formState.errors.fullName.message : '',
    );
    setErrorEmailText(
      QuestionForm.formState.errors?.email ? QuestionForm.formState.errors.email.message : '',
    );
    setErrorQuestionText(
      QuestionForm.formState.errors?.question ? QuestionForm.formState.errors.question.message : '',
    );
  }, [QuestionForm.formState]);

  let host = 'https://moneta.today';
  // eslint-disable-next-line no-restricted-globals
  if (APP_STAGE === DEVELOPMENT) {
    host = 'https://dev.moneta.today';
  }

  const sendRequest = () => {
    setLoading(true);
    axios
      .post(
        host + URLS.SEND_INTENTION_REQUEST,
        JSON.stringify({
          fullName: QuestionFields.fullName.trim(),
          email: QuestionFields.email.trim(),
          question: QuestionFields.question.trim(),
          amount: 0,
          language: stores.language,
          agreed: true,
          meta: 'gameHoneyWood',
        }),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then(() => {
        QuestionForm.reset({
          fullName: '',
          email: '',
          question: '',
        });
        onSubmit();
        gtagEvent(GOOGLE_ANALYTICS.CLICK_FEEDBACK_SEND);
      })
      .catch((error) => {
        alert(error);
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={styles.QuestionFormContainer}>
      <Input
        type="input"
        placeholder={stores.translate('your_name')}
        name="fullName"
        register={QuestionForm.register}
        autoComplete="no"
        classNames={{
          Container: styles.Input_Container,
          InputContainer: styles.Input_InputContainer,
          Input: styles.Input_Input,
        }}
      />
      {errorFullNameText && (
        <div className={styles.ErrorContainer}>
          <p className={styles.ErrorText}>{errorFullNameText}</p>
        </div>
      )}
      <Input
        type="email"
        placeholder={stores.translate('email_input_placeholder')}
        name="email"
        register={QuestionForm.register}
        autoComplete="no"
        classNames={{
          Container: styles.Input_Container,
          InputContainer: styles.Input_InputContainer,
          Input: styles.Input_Input,
        }}
      />
      {errorEmailText && (
        <div className={styles.ErrorContainer}>
          <p className={styles.ErrorText}>{errorEmailText}</p>
        </div>
      )}
      <Input
        type="input"
        placeholder={stores.translate('your_question')}
        name="question"
        register={QuestionForm.register}
        autoComplete="no"
        classNames={{
          Container: styles.Input_Container,
          InputContainer: classnames(
            styles.Input_InputContainer,
            styles.Input_InputContainer__Question,
          ),
          Input: classnames(styles.Input_Input, styles.Input_Input_TextArea),
        }}
        isTextArea
      />
      {errorQuestionText && (
        <div className={styles.ErrorContainer}>
          <p className={styles.ErrorText}>{errorQuestionText}</p>
        </div>
      )}
      <Button
        colorType={ColorType.ORANGE}
        size={SizeButton.SMALL}
        title={stores.translate('send')}
        classNames={{
          Container: classnames(
            styles.SendButtonContainer,
            errorQuestionText && styles.SendButtonContainer__isError,
          ),
          Button: styles.SendButton,
          Title: classnames(
            styles.TitleSendButton,
            stores.browserInfo.isAndroid
              ? styles.TitleOrangeButton__android
              : styles.TitleOrangeButton,
          ),
        }}
        onClick={() => {
          gtagEvent(GOOGLE_ANALYTICS.APPLICATION_SEND);
          QuestionForm.handleSubmit(sendRequest)();
        }}
        isLoading={isLoading}
      />
    </div>
  );
});

export default QuestionForm;
