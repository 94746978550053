import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useStores } from 'src/stores';
import { observer } from 'mobx-react';
import { DEFAULT_AVATAR } from '@constants/system';

import Bear1Icon from '@assets/AccountPage/Avatars/bear_1.png';
import Bear2Icon from '@assets/AccountPage/Avatars/bear_2.png';
import Bear3Icon from '@assets/AccountPage/Avatars/bear_3.png';
import Bear4Icon from '@assets/AccountPage/Avatars/bear_4.png';
import Bear5Icon from '@assets/AccountPage/Avatars/bear_5.png';
import Bear6Icon from '@assets/AccountPage/Avatars/bear_6.png';
import Bear7Icon from '@assets/AccountPage/Avatars/bear_7.png';
import Bear8Icon from '@assets/AccountPage/Avatars/bear_8.png';
import Bear9Icon from '@assets/AccountPage/Avatars/bear_9.png';
import Bear10Icon from '@assets/AccountPage/Avatars/bear_10.png';

import Logger from '@utils/logger';
import styles from './avatar.module.scss';

const getIcon = (bearId) => {
  switch (bearId) {
    case 'bear1':
      return Bear1Icon;
    case 'bear2':
      return Bear2Icon;
    case 'bear3':
      return Bear3Icon;
    case 'bear4':
      return Bear4Icon;
    case 'bear5':
      return Bear5Icon;
    case 'bear6':
      return Bear6Icon;
    case 'bear7':
      return Bear7Icon;
    case 'bear8':
      return Bear8Icon;
    case 'bear9':
      return Bear9Icon;
    case 'bear10':
      return Bear10Icon;
    default:
      return Bear1Icon;
  }
};

const AvatarIcon = observer(
  ({ classNames = { AvatarIconContainer: {}, AvatarIcon: {} }, width = undefined }) => {
    const stores = useStores();
    const [icon, setIcon] = useState(
      stores.profileAvailable && stores.profileAvailable.avatar
        ? stores.profileAvailable.avatar
        : getIcon(DEFAULT_AVATAR),
    );

    useEffect(() => {
      if (stores.profileAvailable && stores.profileAvailable.avatar) {
        const avatar = getIcon(stores.profileAvailable.avatar);
        setIcon(avatar);
      } else {
        const avatar = getIcon(DEFAULT_AVATAR);
        setIcon(avatar);
      }
    }, [stores.profileAvailable]);

    return (
      <div
        className={classnames(styles.AvatarIconContainer, classNames.AvatarIconContainer)}
        style={{ width }}
      >
        <div className={styles.AvatarIconShadow} />
        <img
          src={icon}
          alt="avatar_icon"
          className={classnames(styles.AvatarIcon, classNames.AvatarIcon)}
          onLoad={() => stores.increaseDownloadedImageCounter(icon)}
          onError={() => Logger.error('Error with image loading')}
        />
      </div>
    );
  },
);

export default AvatarIcon;
