import React from 'react';
import { sanitize } from 'dompurify';

const TextH2 = ({ text = '', classNames = {}, style = {} }) => {
  return (
    <h2 className={classNames} dangerouslySetInnerHTML={{ __html: sanitize(text) }} style={style} />
  );
};

export default TextH2;
