import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import Logger from '@utils/logger';
import { useStores } from '@src/stores';
import WebLeft from '@src/assets/Halloween/web_left.png';
import HeaderLight from '@src/assets/Halloween/header_light.png';
import SpiderIcon from '@src/assets/Halloween/spider.png';
import SpiderShadow from '@src/assets/Halloween/spider_shadow.png';
import styles from './titleModal.module.scss';

// size = Large, Medium, Small;
const TitleModal = observer(
  ({ title = 'Title', classNames = {}, size = 'Large', isUnityGame = false }) => {

      const stores = useStores();

      return (
        <div
          className={classnames(
          styles.TitleModalContainer,
          styles[`TitleModalContainer__is${size}`],
          classNames.TitleModalContainer,
          isUnityGame && styles[`TitleModalContainer__isUnityGame__is${size}`],
        )}
        >
          <img
            onLoad={() => stores.increaseDownloadedImageCounter(WebLeft)}
            onError={() => Logger.error('Error with image loading')}
            className={styles.webLeft}
            src={WebLeft}
            alt="WebLeft"
            loading="lazy"
          />
          <img
            onLoad={() => stores.increaseDownloadedImageCounter(HeaderLight)}
            onError={() => Logger.error('Error with image loading')}
            className={styles.headerLight}
            src={HeaderLight}
            alt="HeaderLight"
            loading="lazy"
          />
          <div
            className={classnames(
            styles.TitleModal,
            isUnityGame && styles.TitleModal__isUnityGame,
            classNames.Container,
          )}
          >
            <p
              className={classnames(
              styles.Title,
              styles[`Title__is${size}`],
              classNames.Title,
              isUnityGame && styles[`Title__isUnityGame__is${size}`],
            )}
            >
              {title}
            </p>
          </div>
          <img
            onLoad={() => stores.increaseDownloadedImageCounter(SpiderShadow)}
            onError={() => Logger.error('Error with image loading')}
            className={styles.SpiderShadow}
            src={SpiderShadow}
            alt="WebLeft"
            loading="lazy"
          />
          <img
            onLoad={() => stores.increaseDownloadedImageCounter(SpiderIcon)}
            onError={() => Logger.error('Error with image loading')}
            className={styles.SpiderIcon}
            src={SpiderIcon}
            alt="SpiderIcon"
            loading="lazy"
          />
          <img
            onLoad={() => stores.increaseDownloadedImageCounter(WebLeft)}
            onError={() => Logger.error('Error with image loading')}
            className={styles.webRight}
            src={WebLeft}
            alt="WebRight"
            loading="lazy"
          />
        </div>
    );
  },
);

export default TitleModal;
