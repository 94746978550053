import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import Logger from '@utils/logger';
import { observer } from 'mobx-react';
import { useStores } from 'src/stores';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button, ColorType, SizeButton, UNIQ_ID_BUTTON } from '@components/Button';
import InputWord from '@components/InputWord';
import Input from '@components/Input';
import { ENUM_POPUP } from '@components/PopUp/enumPopup';
import { gtagEvent } from '@utils/common';
import { GOOGLE_ANALYTICS } from '@src/constants/googleAnalyticsEvents';
import { TYPE_AUTH_CLIENT } from '@constants/blockchain';

import { CRYPTO } from 'src/api/crypto';
import { BEARS } from 'src/api';
import {
  BEAR_CREATED_STATE,
  COUNT_WORD_SEED_PHRASE,
  DEFAULT_AVATAR,
  ENCRYPT_PROFILE,
  ENCRYPT_SEED_PHRASE,
  // INFO_ACCOUNT,
  RPC_URL,
} from '@constants/system';
import { setSecureStorage } from 'src/stores/system';
import { isBearCreated } from '@utils/mainBears';

import styles from './modal.module.scss';

const regExMnemonic = /^[a-z]+$/g;

const LoginModal = observer(({ close }) => {
  const navigate = useNavigate();
  const stores = useStores();
  const [isLoading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [isUpdateInput, setUpdateInput] = useState(false);

  const schemaMnemonicWordsModal = yup.object().shape({
    word1: yup
      .string('')
      .transform((value) => value.trim())
      .matches(regExMnemonic, 'Only symbols: a-z')
      .required('Mnemonic Word is required.'),
    word2: yup
      .string('')
      .transform((value) => value.trim())
      .matches(regExMnemonic, 'Only symbols: a-z')
      .required('Mnemonic Word is required.'),
    word3: yup
      .string('')
      .transform((value) => value.trim())
      .matches(regExMnemonic, 'Only symbols: a-z')
      .required('Mnemonic Word is required.'),
    word4: yup
      .string('')
      .transform((value) => value.trim())
      .matches(regExMnemonic, 'Only symbols: a-z')
      .required('Mnemonic Word is required.'),

    word5: yup
      .string('')
      .transform((value) => value.trim())
      .matches(regExMnemonic, 'Only symbols: a-z')
      .required('Mnemonic Word is required.'),
    word6: yup
      .string('')
      .transform((value) => value.trim())
      .matches(regExMnemonic, 'Only symbols: a-z')
      .required('Mnemonic Word is required.'),
    word7: yup
      .string('')
      .transform((value) => value.trim())
      .matches(regExMnemonic, 'Only symbols: a-z')
      .required('Mnemonic Word is required.'),
    word8: yup
      .string('')
      .transform((value) => value.trim())
      .matches(regExMnemonic, 'Only symbols: a-z')
      .required('Mnemonic Word is required.'),
    word9: yup
      .string('')
      .transform((value) => value.trim())
      .matches(regExMnemonic, 'Only symbols: a-z')
      .required('Mnemonic Word is required.'),
    word10: yup
      .string('')
      .transform((value) => value.trim())
      .matches(regExMnemonic, 'Only symbols: a-z')
      .required('Mnemonic Word is required.'),
    word11: yup
      .string('')
      .transform((value) => value.trim())
      .matches(regExMnemonic, 'Only symbols: a-z')
      .required('Mnemonic Word is required.'),
    word12: yup
      .string('')
      .transform((value) => value.trim())
      .matches(regExMnemonic, 'Only symbols: a-z')
      .required('Mnemonic Word is required.'),
  });

  const schemaNeedFieldLoginModal = yup.object().shape({
    password: yup.string('').min(5, stores.translate('password_input_validation')),
  });

  const MnemonicWordsForm = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schemaMnemonicWordsModal),
  });

  const NeedFieldsForm = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schemaNeedFieldLoginModal),
  });

  const words = MnemonicWordsForm.watch();
  const needFields = NeedFieldsForm.watch();

  const arrValues = new Array(COUNT_WORD_SEED_PHRASE).fill(undefined);
  const inputsRef = new Array(COUNT_WORD_SEED_PHRASE).fill(undefined).map(() => useRef());

  const closeModal = () => {
    MnemonicWordsForm.reset();
    NeedFieldsForm.reset();
    close();
  };

  const onNeedFieldsClick = () => {};

  const onLoginClick = async () => {
    if (!_.isEmpty(NeedFieldsForm.formState.errors)) return;
    setLoading(true);
    try {
      // console.log('Object.values(words): ', Object.values(words));
      const mnemonic = Object.values(words)
        .map((word) => word.trim())
        .join(' ');
      const isValidPhrase = CRYPTO.isValidMnemonic({ mnemonic });
      if (!isValidPhrase) {
        setErrorText(stores.translate('not_valid_mnemonic'));
        return;
      }
      const { wallet, account } = await BEARS.getAccount({ mnemonic, isPrivKeys: true });
      if (!_.isEmpty(wallet)) {
        const nickName = account.address;
        try {
          await stores.configAnonymousClient(RPC_URL);
          await stores.configAuthorizedClient(wallet, RPC_URL);
        } catch (err) {
          Logger.error(err);
        } finally {
          const profile = {
            address: account.address,
            nickName,
            email: null,
            wallet,
            account,
            cones: 0,
            honey: 0,
            avatar: DEFAULT_AVATAR,
          };
          setSecureStorage(ENCRYPT_PROFILE, profile, needFields.password);
          setSecureStorage(ENCRYPT_SEED_PHRASE, mnemonic, needFields.password);
          stores.setProfile(profile);
          stores.setProfileAvailable(profile);
          stores.setAuthorized(true);
          stores.setAccountAccess(true);
          stores.setTypeAuthClient(TYPE_AUTH_CLIENT.MNEMONIC);

          if (stores.stateDisplayedPaymentModal) {
            const bearCreated = await isBearCreated();
            if (
              stores.stateDisplayedPaymentModal.packInfo.INIT_SET &&
              bearCreated === BEAR_CREATED_STATE.CREATED
            ) {
              stores.setOpenPopup(ENUM_POPUP.SELLING_PACK_HAS_BEEN_PURCHASED);
            } else if (
              !stores.stateDisplayedPaymentModal.packInfo.INIT_SET &&
              bearCreated === BEAR_CREATED_STATE.NO_CREATED
            ) {
              stores.setOpenPopup(ENUM_POPUP.SELLING_PACK_NO_PURCHASED_INIT);
            } else {
              stores.showPayments(true);
            }

            closeModal();
          } else {
            gtagEvent(GOOGLE_ANALYTICS.CLICK_LOGIN);
            closeModal();
            navigate('/account');
          }
        }
      } else {
        setErrorText('Don`t create wallet');
        return;
      }
    } catch (err) {
      setErrorText(err);
      Logger.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleKeypress = (e) => {
    if (e.key && e.key.toLowerCase() === 'enter') {
      NeedFieldsForm.handleSubmit(onNeedFieldsClick)();
      MnemonicWordsForm.handleSubmit(onLoginClick)();
    }
  };

  

  const onMnemonicPhraseInput = (phrase) => {
    inputsRef.forEach((inputRef, index) => {
      inputRef.current.value = phrase[index];
      inputRef.current.value = phrase[index];
      MnemonicWordsForm.setValue(`word${index + 1}`, phrase[index]);
    });
    setUpdateInput(true);
  };

  return (
    <>
      <p className={styles.EnterSeedPhraseTitle}>{stores.translate('enter_seed_phrase')}</p>
      <div className={styles.SeedPraseContainer}>
        {arrValues.map((word, index) => {
          return (
            <InputWord
              // eslint-disable-next-line react/no-array-index-key
              key={`seed_phrase_${index}`}
              name={`word${index + 1}`}
              register={MnemonicWordsForm.register}
              isError={MnemonicWordsForm.formState.errors[`word${index + 1}`]}
              ref={inputsRef[index]}
              isUpdateInput={isUpdateInput}
              onMnemonicPhrase={onMnemonicPhraseInput}
            />
          );
        })}
      </div>
      {!_.isEmpty(MnemonicWordsForm.formState.errors) && (
        <p className={styles.EnterAllWordsTitle}>
          {stores.translate('enter_all_words_seed_phrase')}
        </p>
      )}
      {!_.isEmpty(errorText) && <p className={styles.EnterAllWordsTitle}>{errorText}</p>}
      <div className={styles.LoginInsertPhraseContainer}>
        <p className={styles.LoginInsertPhrase}>{stores.translate('insert_seed_phrase_login')}</p>
      </div>
      <Input
        placeholder={stores.translate('password_input_placeholder')}
        canError
        label={stores.translate('password')}
        error={NeedFieldsForm.formState.errors?.password}
        name="password"
        register={NeedFieldsForm.register}
        autoComplete="no"
        isButtonSecretShow
        onKeyDown={handleKeypress}
        classNames={{
          Label: styles.LabelInput,
          InputContainer: styles.Input_InputContainer,
          Container: styles.Input_Container,
          Input: styles.Input_Input,
          InputButtonShow: styles.Input_InputButtonShow,
        }}
      />
      <p className={styles.ImportantTitle}>
        {stores.translate('important')}&nbsp;
        <span className={styles.ImportantTitle__description}>
          {stores.translate('wont_login_without_seed_phrase')}
        </span>
      </p>
      <div className={styles.ButtonContainer}>
        <Button
          colorType={ColorType.GREEN}
          uniqID={UNIQ_ID_BUTTON.SeedPhrase.Login}
          onClick={() => {
            NeedFieldsForm.handleSubmit(onNeedFieldsClick)();
            MnemonicWordsForm.handleSubmit(onLoginClick)();
          }}
          title={stores.translate('login')}
          classNames={{
            Container: styles.LoginContainer,
            Title: stores.browserInfo.isAndroid
              ? styles.TitleGreenButton__android
              : styles.TitleGreenButton,
          }}
          isLoading={isLoading}
          valueScaleAnimation={0.95}
        />
      </div>
    </>
  );
});

export default LoginModal;
