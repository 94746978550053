import React from 'react';

export const useCopied = (changedCopy = false) => {
  const [copiedState, setCopiedState] = React.useState(changedCopy);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (copiedState) {
        setCopiedState(false);
      }
    }, 2000);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [copiedState]);

  const setCopied = () => {
    setCopiedState(true);
  };

  return {
    copied: copiedState,
    setCopied,
  };
};

export default {
  useCopied,
}