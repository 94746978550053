import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';

import TitleModal from '@components/TitleModal';

// import Close from '@src/assets/control/close.svg';
import Close from '@src/assets/control/close_new_icon.svg';

import { observer } from 'mobx-react';
import { useStores } from 'src/stores';
import { useMediaQuery } from 'src/helpers/media';
import { BREAK_APPROVE_TRANSACTION } from '@constants/blockchain';
import _ from 'lodash';

// import ContactUsModal from '@layout/components/ContactUsModal';
import JoinModalMobile from 'src/layout/components/mobile/JoinModalMobile';
import CreateSeedPhraseModalMobile from 'src/layout/components/mobile/CreateSeedPhraseModalMobile';
import LoginModalMobile from 'src/layout/components/mobile/LoginModalMobile';
import VerificationWalletAccessModalMobile from 'src/layout/components/mobile/VerificationWalletAccessModalMobile';
import ChangeNicknameModalMobile from 'src/layout/components/mobile/ChangeNicknameModalMobile';
import ChangeAvatarModalMobile from 'src/layout/components/mobile/ChangeAvatarModalMobile';
import ApproveRegistrationModal from 'src/layout/components/mobile/ApproveRegistrationModal';
import PrivateUsernameModal from 'src/layout/components/mobile/PrivateUsernameModal';
import VerifyYourselfModal from 'src/layout/components/mobile/VerifyYourselfModal';
import ApproveSendTokenModal from 'src/layout/components/mobile/ApproveSendTokenModal';
// import ApproveTransactionModal from 'src/layout/components/ApproveTransactionModal';
// import StackingUnityModal from '@layout/components/StackingUnityModal';

import Logger from '@utils/logger';
import styles from './modal.module.scss';
import { ENUM_MODAL } from './enumModal';

const modalComponents = {
  // CONTACT_US: ContactUsModal,
  JOIN: JoinModalMobile,
  SEED_PHRASE: CreateSeedPhraseModalMobile,
  LOGIN: LoginModalMobile,
  VERIFICATION_ACCESS_WALLET: VerificationWalletAccessModalMobile,
  CHANGE_NICKNAME: ChangeNicknameModalMobile,
  CHANGE_AVATAR: ChangeAvatarModalMobile,
  APPROVE_REGISTRATION: ApproveRegistrationModal,
  PRIVATE_USERNAME: PrivateUsernameModal,
  VERIFY_YOURSELF: VerifyYourselfModal,
  SEND_TOKEN: ApproveSendTokenModal,
  // PAYMENTS: <>'payments'</>,

  // UNITY_STACKING: <>'unity-stake'</>,
  // UNITY_APPROVE_TRANSACTION: ApproveTransactionModal,
  // UNITY_VERIFY_YOURSELF: ApproveTransactionModal,
  // UNITY_CHECK_IN_DAILY_BONUS: ApproveTransactionModal,
  // UNITY_STACKING: StackingUnityModal,
  // UNITY_UNSTACKING: StackingUnityModal,
};

export const SizeTitleModal = {
  Large: 'Large',
  Medium: 'Medium',
  Small: 'Small',
};

export const Modal = observer(() => {
  const width1000 = useMediaQuery('(max-width: 1000px)');
  const stores = useStores();
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);
  const [nameModal, setNameModal] = useState('');
  const [ChildModal, setChildModal] = useState({});

  const updateTitleModal = (name) => {
    switch (name) {
      case ENUM_MODAL.CONTACT_US:
        return stores.translate('contact_us');
      case ENUM_MODAL.JOIN:
        return stores.translate('welcome_to_honeywood');
      case ENUM_MODAL.SEED_PHRASE:
        return stores.translate('registration');
      case ENUM_MODAL.APPROVE_REGISTRATION:
        return stores.translate('attention');
      case ENUM_MODAL.LOGIN:
        return stores.translate('log_in');
      case ENUM_MODAL.VERIFICATION_ACCESS_WALLET:
        return stores.translate('verification_wallet_access');
      case ENUM_MODAL.CHANGE_NICKNAME:
        return stores.translate('enter_new_nickname');
      case ENUM_MODAL.CHANGE_AVATAR:
        return stores.translate('choose_your_bear_avatar');
      case ENUM_MODAL.PRIVATE_USERNAME:
        return stores.translate('private_username');
      case ENUM_MODAL.VERIFY_YOURSELF:
        return stores.translate('verify_yourself');
      case ENUM_MODAL.SEND_TOKEN:
        return stores.translate('approve_transform');
      // case ENUM_MODAL.PAYMENTS: return stores.translate('payments');

      // case ENUM_MODAL.UNITY_STACKING: return stores.translate('payments');
      case ENUM_MODAL.UNITY_APPROVE_TRANSACTION:
        return stores.translate('approve_transaction');
      case ENUM_MODAL.UNITY_VERIFY_YOURSELF:
        return stores.translate('verify_yourself_to_join');
      case ENUM_MODAL.UNITY_CHECK_IN_DAILY_BONUS:
        return stores.translate('verify_yourself');
      case ENUM_MODAL.UNITY_STACKING:
        return stores.translate('stake_cones');
      case ENUM_MODAL.UNITY_UNSTACKING:
        return stores.translate('unstake_cones');
      default:
        return 'default';
    }
  };

  const updateSizeTitleModal = () => {
    switch (nameModal) {
      case ENUM_MODAL.CONTACT_US:
        return width1000 ? SizeTitleModal.Small : SizeTitleModal.Large;
      case ENUM_MODAL.JOIN:
      case ENUM_MODAL.SEED_PHRASE:
      case ENUM_MODAL.APPROVE_REGISTRATION:
      case ENUM_MODAL.PRIVATE_USERNAME:
      case ENUM_MODAL.VERIFY_YOURSELF:
      case ENUM_MODAL.SEND_TOKEN:
        return width1000 ? SizeTitleModal.Medium : SizeTitleModal.Large;
      case ENUM_MODAL.LOGIN:
        return width1000 ? SizeTitleModal.Large : SizeTitleModal.Medium;
      case ENUM_MODAL.CHANGE_NICKNAME:
        return width1000 ? SizeTitleModal.Medium : SizeTitleModal.Large;
      case ENUM_MODAL.CHANGE_AVATAR:
        return width1000 ? SizeTitleModal.Medium : SizeTitleModal.Large;
      // case ENUM_MODAL.VERIFICATION_ACCESS_WALLET: return stores.translate('verification_wallet_access');
      // case ENUM_MODAL.CHANGE_NICKNAME: return stores.translate('enter_new_nickname');
      // case ENUM_MODAL.PAYMENTS: return stores.translate('payments');

      case ENUM_MODAL.UNITY_APPROVE_TRANSACTION:
        return width1000 ? SizeTitleModal.Medium : SizeTitleModal.Large;
      case ENUM_MODAL.UNITY_VERIFY_YOURSELF:
        return width1000 ? SizeTitleModal.Small : SizeTitleModal.Large;
      case ENUM_MODAL.UNITY_CHECK_IN_DAILY_BONUS:
        return width1000 ? SizeTitleModal.Medium : SizeTitleModal.Large;
      case ENUM_MODAL.UNITY_STACKING:
      case ENUM_MODAL.UNITY_UNSTACKING:
        return width1000 ? SizeTitleModal.Medium : SizeTitleModal.Large;
      default:
        return SizeTitleModal.Large;
    }
  };

  const isUnityGame = () => {
    if (
      nameModal === ENUM_MODAL.UNITY_APPROVE_TRANSACTION ||
      nameModal === ENUM_MODAL.UNITY_VERIFY_YOURSELF ||
      nameModal === ENUM_MODAL.UNITY_CHECK_IN_DAILY_BONUS ||
      nameModal === ENUM_MODAL.UNITY_STACKING ||
      nameModal === ENUM_MODAL.UNITY_UNSTACKING
    ) {
      return true;
    }
    return false;
    //   ? true
    //   : false;
  };
  // nameModal === ENUM_MODAL.UNITY_APPROVE_TRANSACTION ||
  // nameModal === ENUM_MODAL.UNITY_VERIFY_YOURSELF
  //   ? true
  //   : false;

  useEffect(() => {
    if (stores.openModal) {
      if (Object.values(ENUM_MODAL).includes(stores.openModal)) {
        const changedChildModal = modalComponents[stores.openModal];
        setChildModal(changedChildModal);
      } else {
        // not show Modal if not exist in enum 'ENUM_MODAL'
        setChildModal({});
        return;
      }

      setNameModal(stores.openModal);
      /* Dynamically lock scrolling background */
      setTimeout(() => {
        document.body.style.overflowY = 'hidden';
      }, 100);
      setOpen(true);
    } else {
      /* Dynamically unlock scrolling background */
      document.body.style.overflowY = 'initial';
      setOpen(false);
    }
  }, [stores.openModal]);

  const close = (stateClose) => {
    // true - tap on button crest, outside modal screen
    if (stateClose) {
      // if closed modal use crest and outside modal for clear state of payments
      stores.setStateDisplayedPaymentModal(null);
      switch (nameModal) {
        case ENUM_MODAL.UNITY_CHECK_IN_DAILY_BONUS:
        case ENUM_MODAL.UNITY_VERIFY_YOURSELF:
        case ENUM_MODAL.UNITY_APPROVE_TRANSACTION:
          stores.unityStore.setApproveTransaction(BREAK_APPROVE_TRANSACTION);
          // console.log('update approve transaction >>')
          break;
        case ENUM_MODAL.UNITY_STACKING:
          stores.unityStore.setConesStacking(0);
          break;
        case ENUM_MODAL.UNITY_UNSTACKING:
          stores.unityStore.setObjectUnstacking({ cone: 0, validatorAddress: ''});
          break;
        default:
          break;
      }
    }
    // open modal screen - private username modal after close, password check modal
    if (nameModal === ENUM_MODAL.VERIFY_YOURSELF) {
      stores.setOpenModal(ENUM_MODAL.PRIVATE_USERNAME);
      return;
    }

    stores.setPropsForModal(null);
    stores.setOpenModal(null);
    // if signup opened by link: <host>/signup
    if (nameModal === ENUM_MODAL.SEED_PHRASE) {
      navigate('/');
    }
  };

  const clickScreenOutside = (e) => {
    if (
      e &&
      e.target &&
      e.target.className &&
      _.isString(e.target.className) &&
      e.target.className.includes('modal_ModalContainer')
    ) {
      if (!isUnityGame()) {
        close(true);
      }
    }
  };

  return (
    isOpen &&
    nameModal && (
      <div
        className={classnames(
          styles.ModalContainer,
          isUnityGame() && stores.browserInfo.isMobile && styles.ModalContainer__isUnityGameMobile,
        )}
        role="button"
        tabIndex={0}
        onClick={(e) => clickScreenOutside(e)}
        onKeyDown={() => {}}
      >
        <div
          className={classnames(
            styles.Modal,
            (nameModal === ENUM_MODAL.UNITY_STACKING ||
              nameModal === ENUM_MODAL.UNITY_UNSTACKING) &&
              styles.Modal__Stacking,
            isUnityGame() && stores.browserInfo.isMobile && styles.Modal__isUnityGameMobile,
          )}
        >
          {/* <TitleModal
            title={updateTitleModal(nameModal)}
            // classNames={{ Title: !isUnityGame() && styles.Title }}
            isUnityGame={isUnityGame()}
            size={updateSizeTitleModal()}
          /> */}
          <div className={styles.TitleContainer}>
            <p className={styles.Title}>{updateTitleModal(nameModal)}</p>
          </div>
          <button
            type="button"
            className={styles.ModalClose}
            onClick={() => {
              // gtagEvent(GOOGLE_ANALYTICS.CLOSE_MODAL);
              close(true);
            }}
          >
            <img
              onLoad={() => stores.increaseDownloadedImageCounter(Close)}
              onError={() => Logger.error('Error with image loading')}
              src={Close}
              alt="Close"
              loading="lazy"
            />
          </button>
          <div className={styles.InnerModalContainer}>
            <ChildModal close={close} {...stores.propsForModal} />
          </div>
        </div>
      </div>
    )
  );
});

export default Modal;
