import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useMediaQuery } from 'src/helpers/media';
import { useStores } from '@src/stores';
import { ENUM_MODAL } from '@components_new/Modal/enumModal';
import { TYPE_AUTH_CLIENT } from '@constants/blockchain';
import KeplrWallet from 'src/api/KeplrWallet';
import { DEFAULT_AVATAR, RPC_URL } from '@constants/system';
import { BEARS } from '@api';
import _ from 'lodash';
import Logger from '@utils/logger';
import BeeSpinner from '@components/BeeSpinner';

const PrivateRoute = observer(({ children, isShowWalletAccessModal = false }) => {
  const stores = useStores();
  const isDesktopSize = useMediaQuery('(min-width: 1000px)');
  const [fromMonetaToday, setFromMonetaToday] = useState(window && window.isNativeApp);

  useEffect(async () => {
    if (isShowWalletAccessModal && !stores.isAccountAccess && stores.isAuthorized) {
      // logic if connect with wallet Keplr
      if (stores.typeAuthClient === TYPE_AUTH_CLIENT.KEPLR) {
        await KeplrWallet.accessAndInitKeplrClient();
      } else {
        stores.setOpenModal(ENUM_MODAL.VERIFICATION_ACCESS_WALLET);
      }
    }
  }, [isDesktopSize]);

  // useEffect(() => {
  //   if (!stores.isAuthorized && stores.browserInfo.isMobile) {
  //     const authResult = new URLSearchParams(window.location.search); 
  //     const useMonetaToday = authResult.get('moneta_app');
  //     setFromMonetaToday(useMonetaToday);
  //     // stores.setPropsForModal({ code: calculateAirdropCode() });
  //     // stores.setOpenModal(ENUM_MODAL.SEED_PHRASE);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (window && window.isNativeApp) {

  //   }
  // }, [window.isNativeApp]);

  // NativeApp `MonetaToday`
  useEffect(async () => {
    if (window && window.isNativeApp) {
      // alert('NativeApp!');
      // console.log(window.mnemonic);
      // alert(`window.mnemonic = '${window.mnemonic}'`);
      // if (window.ReactNativeWebView) {
      //   window.ReactNativeWebView.postMessage(`window.mnemonic >> ${  window.mnemonic}`);
      // }

      const { wallet, account } = await BEARS.getAccount({ mnemonic: window.mnemonic, isPrivKeys: true });
      if (!_.isEmpty(wallet)) {
        const nickName = account.address;
        try {
          await stores.configAnonymousClient(RPC_URL);
          await stores.configAuthorizedClient(wallet, RPC_URL);
        } catch (err) {
          Logger.error(err);
          window.open('/', '_self', 'noopener,noreferrer');
        } finally {
          const profile = {
            address: account.address,
            nickName,
            email: null,
            wallet,
            account,
            cones: 0,
            honey: 0,
            avatar: DEFAULT_AVATAR,
          };
          if (window.languageGame) {
            stores.setLanguageGame(window.languageGame);
          }
          stores.setProfile(profile);
          stores.setProfileAvailable(profile);
          stores.setAuthorized(true);
          stores.setAccountAccess(true);
          stores.setTypeAuthClient(TYPE_AUTH_CLIENT.MNEMONIC);
          stores.setQueue(BEARS.createQueue());
          stores.setPassword('password_native_app');
          stores.setNativeApp(true);

          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ message: 'authorized and init stores'}));
          }
          if (window.FlutterWebView) {
            window.FlutterWebView.postMessage(JSON.stringify({ message: 'authorized and init stores'}));
          }
          // window.ReactNativeWebView.postMessage(`stores.password >> ${  stores.password}`);
          // window.ReactNativeWebView.postMessage(`stores.profile >> ${  JSON.stringify(stores.profile)}`);
          // window.ReactNativeWebView.postMessage(`stores.nativeApp >> ${  stores.isNativeApp}`);
          // window.ReactNativeWebView.postMessage('stores >> ' + stores.password);

          setFromMonetaToday(false);
        }
      }
    }
  }, []);


  // useEffect(() => {
  //   if (!stores.isAuthorized && stores.browserInfo.isMobile) {
  //     const authResult = new URLSearchParams(window.location.search); 
  //     const useMonetaToday = authResult.get('moneta_today_app');
  //     setFromMonetaToday(useMonetaToday);
  //     // stores.setPropsForModal({ code: calculateAirdropCode() });
  //     // stores.setOpenModal(ENUM_MODAL.SEED_PHRASE);
  //   }
  //   if (stores.isAuthorized && stores.browserInfo.isMobile && fromMonetaToday) {
  //     setFromMonetaToday(false);
  //   }
  // }, [stores.isAuthorized]);

  if (fromMonetaToday) {
    return (
      <>
        <BeeSpinner classNames={{ Container: { minWidth: '100vw', minHeight: '100vh' } }} />
      </>
    )
  };

  return stores.isAuthorized ? children : <Navigate to="/" />;
});

export default PrivateRoute;