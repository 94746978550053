import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import classnames from 'classnames';
import TextH3 from '@components_new/TextH3';
import TitleSection from '@src/components/TitleSection';
import { sanitize } from 'dompurify';
import GlyphDownBlack from '@assets/Mobile/FaqPage/FaqSection/glyph_down_black_icon.svg';
import GlyphUpGreen from '@assets/Mobile/FaqPage/FaqSection/glyph_up_green_icon.svg';
import Logger from '@utils/logger';
import styles from './mobileFaq.module.scss';

const FaqItem = observer(
  ({ question = 'Question?', answerHtml = {}, isAnswerWithHtml = false }) => {
    const stores = useStores();
    const _ = stores.language;
    const [isOpenAnswer, setOpenAnswer] = useState(false);
    return (
      <div className={styles.FaqItemContainer}>
        <div
          className={styles.QuestionContainer}
          onClick={() => setOpenAnswer(!isOpenAnswer)}
          onKeyDown={() => setOpenAnswer(!isOpenAnswer)}
          role="button"
          tabIndex={0}
        >
          <p
            className={styles.QuestionText}
            dangerouslySetInnerHTML={{ __html: sanitize(question, { ADD_ATTR: ['target'] }) }}
          />
          {isOpenAnswer ? (
            <img
              onLoad={() => stores.increaseDownloadedImageCounter(GlyphUpGreen)}
              onError={() => Logger.error('Error with image loading')}
              src={GlyphUpGreen}
              alt="glyph_up_green_icon"
            />
          ) : (
            <img
              onLoad={() => stores.increaseDownloadedImageCounter(GlyphDownBlack)}
              onError={() => Logger.error('Error with image loading')}
              src={GlyphDownBlack}
              alt="glyph_down_black_icon"
            />
          )}
        </div>
        <div
          className={styles.AnswerContainer}
          style={{
            maxHeight: isOpenAnswer ? '1000px' : '0px',
            padding: isOpenAnswer ? '10px 20px 20px' : '0px 20px 0px',
            opacity: isOpenAnswer ? 1 : 0,
          }}
        >
          {isAnswerWithHtml ? (
            <div>{answerHtml}</div>
          ) : (
            <p
              className={styles.AnswerText}
              dangerouslySetInnerHTML={{ __html: sanitize(answerHtml, { ADD_ATTR: ['target'] }) }}
            />
          )}
        </div>
      </div>
    );
  },
);

const FaqSection = observer(() => {
  const stores = useStores();
  const _ = stores.language;
  const faqTexts = stores.translate('faq_texts');

  const AnswerHtml = ({ subject = 'basics', index = 0 }) => {
    const getFaqTextsSubject = (subjectName) => {
      switch (subjectName) {
        case 'basics':
          return faqTexts?.basics;
        case 'economy':
          return faqTexts?.economy;
        default:
          return faqTexts?.basics;
      }
    };

    return (
      <>
        {getFaqTextsSubject(subject)?.[index]?.answer?.title && (
          <p
            className={classnames(styles.AnswerText, styles.AnswerText__marginBottom_20px)}
            dangerouslySetInnerHTML={{
              __html: sanitize(getFaqTextsSubject(subject)?.[index].answer.title, {
                ADD_ATTR: ['target'],
              }),
            }}
          />
        )}
        <ul className={styles.ListStyle}>
          {getFaqTextsSubject(subject)?.[index].answer.answer_parts.map((answer) => {
            return (
              <li
                className={classnames(styles.AnswerText, styles.AnswerPartText)}
                key={answer.answer_part}
                dangerouslySetInnerHTML={{
                  __html: sanitize(answer.answer_part, { ADD_ATTR: ['target'] }),
                }}
              />
            );
          })}
        </ul>
        {getFaqTextsSubject(subject)?.[index]?.answer?.epilog && (
          <p
            className={classnames(styles.AnswerText, styles.AnswerText__marginBottom_20px)}
            dangerouslySetInnerHTML={{
              __html: sanitize(getFaqTextsSubject(subject)?.[index].answer.epilog, {
                ADD_ATTR: ['target'],
              }),
            }}
          />
        )}
      </>
    );
  };

  return (
    <div className={styles.Container}>
      <TitleSection
        title={stores.translate('faq')}
        isH2TitleTag
        classNames={{ Container: styles.TitleContainer }}
      />
      <TextH3 classNames={styles.Subtitle} text={stores.translate('basics')} />
      <div className={classnames(styles.FaqBlocksContainer, styles.MarginBottom_50px)}>
        {faqTexts?.basics?.length &&
          faqTexts.basics.map((faq, index) => {
            return (
              <FaqItem
                question={faq.question}
                answerHtml={
                  faq.answer?.answer_parts?.length ? (
                    <AnswerHtml subject="basics" index={index} />
                  ) : (
                    faq.answer
                  )
                }
                isAnswerWithHtml={faq.answer?.answer_parts?.length}
                key={faq.question}
              />
            );
          })}
      </div>
      <TextH3 classNames={styles.Subtitle} text={stores.translate('economy')} />
      <div className={styles.FaqBlocksContainer}>
        {faqTexts?.economy?.length &&
          faqTexts.economy.map((faq, index) => {
            return (
              <FaqItem
                question={faq.question}
                answerHtml={
                  faq.answer?.answer_parts?.length ? (
                    <AnswerHtml subject="economy" index={index} />
                  ) : (
                    faq.answer
                  )
                }
                isAnswerWithHtml={faq.answer?.answer_parts?.length}
                key={faq.question}
              />
            );
          })}
      </div>
    </div>
  );
});

export default FaqSection;
