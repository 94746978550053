import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _ from 'lodash';

import { useStores } from 'src/stores';
import { gtagEvent } from '@utils/common';
import authorization from '@utils/authorization';
import Logger from '@utils/logger';
import { GOOGLE_ANALYTICS } from '@src/constants/googleAnalyticsEvents';
import Input from '@components/Input';
import { Button, ColorType, SizeButton } from '@components_new/Button';
import { ENUM_MODAL } from '@components_new/Modal/enumModal';

import styles from './verifyYourselfModal.module.scss';

const VerifyYourselfModal = observer(({ close }) => {
  const navigate = useNavigate();
  const stores = useStores();
  const [isLoading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState('');

  const schemaModal = yup
    .object()
    .shape({
      password: yup.string().min(5, stores.translate('password_input_validation')).required(),
    })
    .required();

  const ModalForm = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schemaModal),
    defaultValues: {
      password: '',
    },
  });
  const modalFields = ModalForm.watch();
  
  const finishPasswordClicked = (successState) => {
    if (!successState) {
      ModalForm.setError('password', {
        type: 'manual',
        message: stores.translate('password_incorrect'),
      });
    } else {
      stores.setExecuteModal({
        name: ENUM_MODAL.VERIFY_YOURSELF,
        state: { isVerify: true },
      });
      close(false);
    }
  };

  const onCheckPasswordClick = async () => {
    setLoading(true);
    await authorization.checkPasswordAuth({
      password: modalFields.password,
      finish: finishPasswordClicked,
    });
    setLoading(false);
  };

  const handleKeypress = (e) => {
    if (e.key && e.key.toLowerCase() === 'enter') {
      ModalForm.handleSubmit(onCheckPasswordClick)();
    }
  };


  return (
    <>
      <div className={styles.InputContainerWrapper}>
        <Input
          placeholder={stores.translate('password_input_placeholder')}
          canError
          label={stores.translate('password')}
          error={ModalForm.formState.errors?.password}
          name="password"
          register={ModalForm.register}
          autoComplete="no"
          isButtonSecretShow
          onKeyDown={handleKeypress}
          classNames={{
            Label: styles.LabelInput,
            InputContainer: styles.Input_InputContainer,
            Container: styles.Input_Container,
            Input: styles.Input_Input,
            InputButtonShow: styles.Input_InputButtonShow,
          }}
          isFocus
        />
      </div>
      <div className={styles.ButtonsContainer}>
        <Button
          colorType={ColorType.GREEN}
          size={SizeButton.SMALL}
          onClick={() => {
            ModalForm.handleSubmit(onCheckPasswordClick)();
          }}
          title={stores.translate('approve')}
          classNames={{
            Container: styles.ButtonContainer,
            Button: styles.Button,
            Title: classnames(styles.ButtonTitle, styles.ButtonTitle__Green),
          }}
          isLoading={isLoading}
          disabled={isLoading}
          valueScaleAnimation={0.95}
        />
      </div>
    </>
  );
});

export default VerifyYourselfModal;
