/* eslint-disable */
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx';
import { coins } from '@cosmjs/stargate';
import { toBase64, toHex } from '@cosmjs/encoding';
import { ExtendedSecp256k1Signature, Secp256k1, Secp256k1Signature, sha256 } from "@cosmjs/crypto";
import { COST_CHANGE_AVAILABLE_AVATAR, DENOM_UHONEY, TYPE_AUTH_CLIENT, URL_MATCH3_SEND } from "@constants/blockchain";
import { COUNT_MILLISECONDS_LIFE_TIME_SIGNATURE, MILLION, URL_SERVER_MAIN } from "@constants/system";
import { getFee } from "@utils/methodsBears";
import axios from "axios";
import { StoreInstance } from "src/stores";
import Logger from '@utils/logger';
import _ from 'lodash';

import { makeSignature } from "./common";
import BEARS from '../BEARS';

let countRequest = 0;
const MAX_COUNT_REQUEST = 15;

const makeSignatureSettings = async ({ isPost = false, bodyInfo = {} }) => {
  let body = bodyInfo;
  const ts = String(Math.floor(Date.now() / 1000) - 5);

  let signString = ts;
  if (isPost) {
    signString = ts + JSON.stringify(body);
  }
  if (!isPost && StoreInstance.signatureTransaction && StoreInstance.typeAuthClient !== TYPE_AUTH_CLIENT.MNEMONIC) {
    const currentDate = new Date();
    const startDate = new Date(StoreInstance.signatureTransaction.dateStart);
    if (currentDate - startDate <= COUNT_MILLISECONDS_LIFE_TIME_SIGNATURE) {
      return StoreInstance.signatureTransaction;
    }
  }

  const signatureTransaction = await makeSignature({ body, ts, nonce: signString });
  signatureTransaction.dateStart = new Date();
  return signatureTransaction;
};

export const getAddressServer = async () => {
  try {
    const result = await axios.get(`${URL_SERVER_MAIN}/api/settings/server/address`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (result.status === 400) {
      countRequest++;
      if (countRequest < MAX_COUNT_REQUEST) {
        return await listAvatars();
      } else {
        countRequest = 0;
        const objError = await result.text();
        const textError = objError.split(',')[1].split(':')[1];
        return { err: true, msg: textError }
      }
    }
    if (result.status === 200) {
      return result.data;
    }
    return { err: true, msg: 'getAddressServer error' }
  } catch (err) {
    Logger.error(err);
    if (err.response && err.response.data && err.response.data.message) {
      return { err: true, msg: err.response.data.message }
    }
    return { err: true, msg: String(err) }
  }
}

export const listAvatars = async () => {
  try {
    const result = await axios.get(`${URL_SERVER_MAIN}/api/settings/profiles/avatars`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (result.status === 400) {
      countRequest++;
      if (countRequest < MAX_COUNT_REQUEST) {
        return await listAvatars();
      } else {
        countRequest = 0;
        const objError = await result.text();
        const textError = objError.split(',')[1].split(':')[1];
        return { err: true, msg: textError }
      }
    }
    if (result.status === 200) {
      return result.data;
    }
    return { err: true, msg: 'listAvatars error' }
  } catch (err) {
    Logger.error(err);
    if (err.response && err.response.data && err.response.data.message) {
      return { err: true, msg: err.response.data.message }
    }
    return { err: true, msg: String(err) }
  }
}

export const profile = async ({ address }) => {
  try {
    const result = await axios.get(`${URL_SERVER_MAIN}/api/settings/profiles/me?address=${address}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (result.status === 400) {
      countRequest++;
      if (countRequest < MAX_COUNT_REQUEST) {
        return await profile({ address });
      } else {
        countRequest = 0;
        const objError = await result.text();
        const textError = objError.split(',')[1].split(':')[1];
        return { err: true, msg: textError }
      }
    }
    return result.data;
  } catch (err) {
    console.log(err);
    return { err: true, msg: String(err) }
  }
}

export const onboarding = async () => {
  const bodyInfo = {};
  const infoRequest = await makeSignatureSettings({ isPost: true, bodyInfo });
  try {
    const result = await axios.post(`${URL_SERVER_MAIN}/api/settings/profiles/onboarding`, JSON.stringify({}), {
      headers: {
        'Content-Type': 'application/json',
        'x-honeywood-ts': infoRequest.timestamp,
        'x-honeywood-pk': toHex(StoreInstance.profileAvailable.account.pubkey),
        'x-honeywood-sig': toHex(infoRequest.signature)
      },
    });
    if (result.status === 400) {
      countRequest++;
      if (countRequest < MAX_COUNT_REQUEST) {
        return await onboarding();
      } else {
        countRequest = 0;
        const objError = await result.text();
        const textError = objError.split(',')[1].split(':')[1];
        return { err: true, msg: textError }
      }
    }
    if (result.status === 200) {
      return result.data;
    }
    return { err: true, msg: 'onboarding error' }
  } catch (err) {
    Logger.error(err);
    if (err.response && err.response.data && err.response.data.message) {
      return { err: true, msg: err.response.data.message }
    }
    return { err: true, msg: String(err) }
  }
}

export const listProfile = async ({ addresses = [] }) => {
  if (!addresses || addresses.length === 0) {
    return { err: true, msg: 'parameter addresses has not array' };
  }
  if (_.isEmpty(addresses[0])) {
    return [];
  }
  try {
    Logger.infoMainWithKey('addresses', addresses);
    let addressesQuery = '';
    addresses.forEach((address, index) => {
      let addressPartQuery = `address=${address}`;
      if (index != addresses.length - 1) {
        addressPartQuery += '&' 
      }
      addressesQuery += addressPartQuery;
    });
    const result = await axios.get(`${URL_SERVER_MAIN}/api/settings/profiles/me?${addressesQuery}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (result.status === 400) {
      countRequest++;
      if (countRequest < MAX_COUNT_REQUEST) {
        return await listProfile({ address });
      } else {
        countRequest = 0;
        const objError = await result.text();
        const textError = objError.split(',')[1].split(':')[1];
        return { err: true, msg: textError }
      }
    }
    if (addresses.length === 1) {
      return [result.data];
    }
    return result.data;

  } catch (err) {
    Logger.error(err);
    if (err.response && err.response.data && err.response.data.message) {
      return { err: true, msg: err.response.data.message }
    }
    return { err: true, msg: String(err) }
  }
}

export const buyAvatar = async ({ bearAvatar, toAddress }) => {
  if (!StoreInstance.isAuthorizedClientConfigured) {
    throw Error('no exist client of blockchain');
  }
  const balances = await BEARS.getBalance({ address: StoreInstance.profileAvailable.address });
  const fee = getFee({ coefficient: 1.0, balances });
  const fromAddress = StoreInstance.profileAvailable.address;
  const amount = bearAvatar.price;

  const msg = {
    typeUrl: URL_MATCH3_SEND,
    value: {
      fromAddress,
      toAddress,
      amount: coins(amount * MILLION, DENOM_UHONEY),
    },
  };
  const tx = await StoreInstance.authorizedClient.cosmos.sign(fromAddress, [msg], fee, '');
  const txEncoded = TxRaw.encode(tx).finish();
  const sendingMessage = {
    tx: toBase64(txEncoded),
    pubkey: toBase64(StoreInstance.profileAvailable.account.pubkey),
    avatar: bearAvatar.name,
  };

  try {
    const result = await axios.post(`${URL_SERVER_MAIN}/api/settings/profiles/buy-avatar`, JSON.stringify(sendingMessage), {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (result.status === 400) {
      countRequest++;
      if (countRequest < MAX_COUNT_REQUEST) {
        return await buyAvatar({ bearAvatar, toAddress });
      } else {
        countRequest = 0;
        const objError = await result.text();
        const textError = objError.split(',')[1].split(':')[1];
        return { err: true, msg: textError }
      }
    }
    if (result.status === 200) {
      return result.data;
    }
    return { err: true, msg: 'buy avatar error' }
  } catch (err) {
    Logger.error(err);
    if (err.response && err.response.data && err.response.data.message) {
      return { err: true, msg: err.response.data.message }
    }
    return { err: true, msg: String(err) }
  }
}

export const changeAvatar = async ({ bearAvatar }) => {
  const bodyInfo = {
    avatar: bearAvatar.name,
  };
  try {
    const infoRequest = await makeSignatureSettings({ isPost: true, bodyInfo });
    const result = await axios.post(`${URL_SERVER_MAIN}/api/settings/profiles/change-avatar`, JSON.stringify(bodyInfo), {
      headers: {
        'Content-Type': 'application/json',
        'x-honeywood-ts': infoRequest.timestamp,
        'x-honeywood-pk': toHex(StoreInstance.profileAvailable.account.pubkey),
        'x-honeywood-sig': toHex(infoRequest.signature)
      },
    });
    if (result.status === 400) {
      countRequest++;
      if (countRequest < MAX_COUNT_REQUEST) {
        return await changeAvatar({ bearAvatar });
      } else {
        countRequest = 0;
        const objError = await result.text();
        const textError = objError.split(',')[1].split(':')[1];
        return { err: true, msg: textError }
      }
    }
    
    return result.data;
  } catch (err) {
    Logger.error(err);
    if (err.response && err.response.data && err.response.data.message) {
      return { err: true, msg: err.response.data.message }
    }
    return { err: true, msg: String(err) }
  }
}

const getHints = async ({ address }) => {
  try {
    const result = await axios.get(`${URL_SERVER_MAIN}/api/settings/prompts?address=${address}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (result.status === 400) {
      countRequest++;
      if (countRequest < MAX_COUNT_REQUEST) {
        return await getHints({ address });
      } else {
        countRequest = 0;
        const objError = await result.text();
        const textError = objError.split(',')[1].split(':')[1];
        return { err: true, msg: textError }
      }
    }
    if (result.status === 200) {
      return result.data;
    }
    return { err: true, msg: 'getHints error' }
  } catch (err) {
    Logger.error(err);
    if (err.response && err.response.data && err.response.data.message) {
      return { err: true, msg: err.response.data.message }
    }
    return { err: true, msg: String(err) }
  }
}

export default {
  getAddressServer,
  listAvatars,
  profile,
  onboarding,
  listProfile,
  buyAvatar,
  changeAvatar,
  getHints,
};