const URLS = {
  WHITE_PAPER: 'https://whitepaper.honeywood.io/',
  SEND_INTENTION_REQUEST: '/api/v1/monetacoin/intentions',
  MONETA_TODAY_PRESENTATION: 'moneta-today-presentation.pdf',
  PRIVACY_POLICY: 'files/privacy-policy.pdf',
  COOKIE_POLICY: 'files/cookie-policy.pdf',
  TERMS_OF_USE: 'files/terms-of-use.pdf',
  COIN_SALE: 'files/purchase-agreement.pdf',
  GAMEPLAY_YOUTUBE: 'https://youtu.be/dUGu-631wc4',
  HOW_TO_PLAY_MEDIUM: 'https://honeywood-official.medium.com/honeywood-faq-54ecc0d0565a',
  MEDIA_KIT: 'https://drive.google.com/drive/folders/1rs0GuDJppFJl9L31eiHxV9JL5R1cGk-d',
  // LISTING_HONEYCOIN: 'https://honeywood-official.medium.com/listing-of-honeycoin-on-bitmart-9a297452ef7a',
  LISTING_HONEYCOIN: 'https://www.bitmart.com/trade/en-US?layout=basic&theme=dark&symbol=CONE_USDT',
  COMMUNITY_COUNTRIES: 'https://link.medium.com/eiFKQ6AGKsb',
  EXPLORER_LANDING: 'https://explorer.honeywood.io/',
  LANDING: 'https://honeywood.io',
  PRELIVE_HOST: 'https://prelive.honeywood.io',
  BETA_HOST: 'https://beta.honeywood.io',
  DEV_HOST: 'https://dev.honeywood.io',
  FAQ: 'https://honeywood-official.medium.com/honeywood-faq-54ecc0d0565a',
  VESTING: 'https://docs.google.com/spreadsheets/d/18chKi8iMQR17whm3pMIlwBnMMCQJCpPG/edit#gid=2106652973',
};

export default URLS;
