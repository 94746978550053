import React from 'react';
import ReactDOM from 'react-dom';
import { PRODUCTION, BETA, DEVELOPMENT } from '@constants/system';
import './styles/global.css';
import App from '@src/App';
import { ErrorBoundary } from '@utils/logger';

// if (process.env.REACT_APP_STAGE === PRODUCTION || process.env.REACT_APP_STAGE === BETA) {
if (process.env.REACT_APP_STAGE === PRODUCTION) {
  /* gtagScript logic */
  const gtagScript = document.createElement('script');
  gtagScript.innerHTML = `(function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-KCBCBBG');`;
  document.head.appendChild(gtagScript);

  const gtagNoscript = document.createElement('noscript');
  const gtagIframe = document.createElement('iframe');
  gtagIframe.setAttribute('src', 'https://www.googletagmanager.com/ns.html?id=GTM-KCBCBBG');
  gtagIframe.setAttribute('height', '0');
  gtagIframe.setAttribute('width', '0');
  gtagIframe.setAttribute('style', 'display:none; visibility:hidden;');
  gtagNoscript.appendChild(gtagIframe);
  document.body.prepend(gtagNoscript);

  /* Hotjar Tracking Code logic */
  const hotjarScript = document.createElement('script');
  hotjarScript.innerHTML = `(function(h,o,t,j,a,r){
    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    h._hjSettings={hjid:3019470,hjsv:6};
    a=o.getElementsByTagName('head')[0];
    r=o.createElement('script');r.async=1;
    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    a.appendChild(r);
})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
  document.head.appendChild(hotjarScript);
}
if (process.env.REACT_APP_STAGE === DEVELOPMENT) {
  const gtagScript = document.createElement('script');
  gtagScript.innerHTML = `(function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-T6ZHJRB');`;
  document.head.appendChild(gtagScript);

  const gtagNoscript = document.createElement('noscript');
  const gtagIframe = document.createElement('iframe');
  gtagIframe.setAttribute('src', 'https://www.googletagmanager.com/ns.html?id=GTM-T6ZHJRB');
  gtagIframe.setAttribute('height', '0');
  gtagIframe.setAttribute('width', '0');
  gtagIframe.setAttribute('style', 'display:none; visibility:hidden;');
  gtagNoscript.appendChild(gtagIframe);
  document.body.prepend(gtagNoscript);
}
// add markdown script module in tag head
if (window.location.pathname === '/changelog') {
  const markdownScript = document.createElement('script');
  markdownScript.setAttribute('type', 'module');
  markdownScript.setAttribute('src', 'https://cdn.jsdelivr.net/gh/zerodevx/zero-md@2/dist/zero-md.min.js');
  document.head.appendChild(markdownScript);
}

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);
