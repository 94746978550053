import React from 'react';
import classnames from 'classnames';
import { getShortAddress, gtagEvent } from '@utils/common';
import { GOOGLE_ANALYTICS } from 'src/constants/googleAnalyticsEvents';
import AvatarIcon from '@components/AvatarIcon';
import { useNavigate } from 'react-router-dom';
import HoneyWoodLogo from '@assets/common/honeywood_moneta_logo.png';
import ArrowLeftIcon from '@assets/Mobile/control/arrow_left_icon.svg';
import Logger from '@utils/logger';
import styles from './header.module.scss';

const MainHeader = ({ withAvatar, stores, states, methods, isAccount = false, isLeadsBack = false }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.MainContainer}>
      <div className={styles.LogoContainer}>
        <a href="/">
          <img
            onLoad={() => stores.increaseDownloadedImageCounter(HoneyWoodLogo)}
            onError={() => Logger.error('Error with image loading')}
            src={HoneyWoodLogo}
            alt="honeywood_moneta_logo"
            className={styles.HoneyWoodLogo}
          />
        </a>
        <div
          className={classnames(
            styles.MainPageLink,
            window.location.pathname === '/' && styles.MainPageLink__hide,
          )}
          onClick={() => {
            stores.reloadAllSpinners();
            isLeadsBack ? navigate(-1) : navigate('/');
          }}
          onKeyDown={() => {
            stores.reloadAllSpinners();
            isLeadsBack ? navigate(-1) : navigate('/');
          }}
          role="button"
          tabIndex="0"
        >
          <img
            onLoad={() => stores.increaseDownloadedImageCounter(ArrowLeftIcon)}
            onError={() => Logger.error('Error with image loading')}
            src={ArrowLeftIcon}
            alt="arrow_left_icon"
            className={styles.ArrowLeftIcon}
          />
          <p className={styles.MainPageText}>
            {isLeadsBack ? stores.translate('back') : stores.translate('main_page')}
          </p>
        </div>
      </div>
      <div className={styles.LanguageContainer}>{/* <LanguageSection /> */}</div>
      <div className={styles.MenuContainer}>
        <div className={styles.ButtonLinks}>
          {states.menu.map((menuItem, index) => {
            return menuItem.isLink ? (
              <a
                href={methods.getHeaderLinkInfo(menuItem.name)[0]}
                target="_blank"
                rel="noreferrer"
                role="button"
                className={styles.MenuContainer__item}
                key={`header_${menuItem.name}`}
                tabIndex={index}
                onClick={() => {
                  gtagEvent(methods.getHeaderLinkInfo(menuItem.name)[1]);
                }}
              >
                <p className={styles.MenuContainer__title} id={`anchor_header_${menuItem.name}`}>
                  {menuItem.translate}
                </p>
              </a>
            ) : (
              <div
                role="button"
                className={classnames(
                  styles.MenuContainer__item,
                  menuItem.name === 'selling-pack' && styles.MenuContainer__item_SellingPack,
                  menuItem.name === 'main-page' && styles.MenuContainer__Display_none,
                )}
                key={`header_${menuItem.name}`}
                onClick={() => {
                  if (window.location.pathname !== methods.getHeaderLinkRoute(menuItem.name)) {
                    stores.reloadAllSpinners();
                    navigate(methods.getHeaderLinkRoute(menuItem.name));
                  } else {
                    window.scrollTo(0, 0);
                  }
                }}
                onKeyDown={() => {
                  if (window.location.pathname !== methods.getHeaderLinkRoute(menuItem.name)) {
                    stores.reloadAllSpinners();
                    navigate(methods.getHeaderLinkRoute(menuItem.name));
                  } else {
                    window.scrollTo(0, 0);
                  }
                }}
                tabIndex={index}
              >
                <p
                  className={classnames(
                    styles.MenuContainer__title,
                    menuItem.name === 'selling-pack' && styles.MenuContainer__item_SellingPack,
                  )}
                  id={`anchor_header_${menuItem.name}`}
                >
                  {menuItem.translate}
                </p>
              </div>
            );
          })}
        </div>
        {withAvatar && (
          <div
            className={classnames(
              styles.PlayerMenuContainerBlock,
              window.location.pathname === '/transactions' && styles.PlayerMenuContainerBlock__hide,
              isAccount && styles.PlayerMenuContainerBlock__Cursor_unset,
            )}
            onClick={
              isAccount
                ? () => {}
                : () => {
                    gtagEvent(GOOGLE_ANALYTICS.CLICK_LOGIN);
                    if (stores.isAuthorized) {
                      methods.accountClick();
                    } else {
                      methods.joinClick();
                    }
                  }
            }
            onMouseLeave={() => {}}
            onKeyDown={
              isAccount
                ? () => {}
                : () => {
                    gtagEvent(GOOGLE_ANALYTICS.CLICK_LOGIN);
                    if (stores.isAuthorized) {
                      methods.accountClick();
                    } else {
                      methods.joinClick();
                    }
                  }
            }
            tabIndex="0"
            role="button"
          >
            <AvatarIcon classNames={{ AvatarIconContainer: styles.PlayerImage }} width={40} />
            <div
              className={classnames(
                styles.PlayerMenuButton,
                isAccount && styles.PlayerMenuButton__hide,
              )}
            >
              <p className={styles.MenuContainer__title}>
                {stores.isAuthorized
                  ? stores.isAccountAccess
                    ? getShortAddress(states.userName) || getShortAddress(states.walletAddress)
                    : 'Login'
                  : 'Login'}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MainHeader;
