import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { useStores } from 'src/stores';
import { gtagEvent } from '@utils/common';
import authorization from '@utils/authorization';
import Logger from '@utils/logger';
import { GOOGLE_ANALYTICS } from '@src/constants/googleAnalyticsEvents';
import { Button, ColorType, SizeButton } from '@components_new/Button';
import { ENUM_MODAL } from '@components_new/Modal/enumModal';
import { connectKeplr } from 'src/api/KeplrWallet';
import styles from './approveRegistrationModal.module.scss';

const ApproveRegistrationModal = observer(({ close }) => {
  const navigate = useNavigate();
  const stores = useStores();
  const [isLoading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [secondsWaiting, setSecondsWaiting] = useState(7);
  const [textWaiting, setTextWaiting] = useState('7');

  useEffect(() => {
    if (errorText) {
      Logger.infoMainWithKey('errorText', errorText);
    }
  }, [errorText]);

  useEffect(() => {
    if (stores.propsForModal.countOpened === 1) {
      setTimeout(() => {
        const newSecondsWaiting = secondsWaiting - 1;
        // if (newSecondsWaiting > 0)
        setTextWaiting(`${newSecondsWaiting}`);
        // switch (newSecondsWaiting) {
        //   case 7:
        //     setTextWaiting('7,6..1');
        //     break;
        //   case 6:
        //     setTextWaiting('6,5..1');
        //     break;
        //   case 5:
        //     setTextWaiting('5,4..1');
        //     break;
        //   case 4:
        //     setTextWaiting('4,3..1');
        //     break;
        //   case 3:
        //     setTextWaiting('3,2,1');
        //     break;
        //   case 2:
        //     setTextWaiting('2,1');
        //     break;
        //   case 1:
        //     setTextWaiting('1');
        //     break;
        //   default:
        //     break;
        // }
        setSecondsWaiting(newSecondsWaiting);
      }, 1000);
    } else {
      setSecondsWaiting(0);
    }
  }, [secondsWaiting]);

  const onLoginClick = async () => {
    setLoading(true);
    try {
      const { mnemonic, password, code } = stores.propsForModal;
      await authorization.login({
        mnemonic: mnemonic.join(' '),
        password,
        code,
        setErrorText,
        closeModal: close,
        navigate,
      });
    } catch (err) {
      setErrorText(err);
      Logger.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onBackClick = () => {
    stores.setPropsForModal({...stores.propsForModal, countOpened: stores.propsForModal.countOpened + 1 });
    stores.setOpenModal(ENUM_MODAL.SEED_PHRASE);
  };

  return (
    <>
      <p className={styles.InfoTitle}>{stores.translate('you_copied_username')}</p>
      <p className={styles.DescriptionModal}>
        {stores.translate('approve_registration_description')}
      </p>
      <div className={styles.ButtonsContainer}>
        <Button
          colorType={ColorType.ORANGE}
          size={SizeButton.MEDIUM}
          onClick={() => {
            onBackClick();
          }}
          title={stores.translate('back')}
          classNames={{
            Container: styles.ButtonContainer,
            Button: styles.Button,
            Title: classnames(styles.ButtonTitle, styles.ButtonTitle__Orange),
          }}
          disabled={isLoading}
        />
        <Button
          colorType={secondsWaiting > 0 ? ColorType.GRAY : ColorType.GREEN}
          size={SizeButton.MEDIUM}
          onClick={async () => {
            await onLoginClick();
          }}
          title={secondsWaiting > 0 ? textWaiting : stores.translate('login')}
          classNames={{
            Container: styles.ButtonContainer,
            Button: styles.Button,
            Title: classnames(
              styles.ButtonTitle,
              secondsWaiting > 0 ? styles.ButtonTitle__Gray : styles.ButtonTitle__Green,
            ),
          }}
          isLoading={isLoading}
          disabled={secondsWaiting > 0 || isLoading}
        />
      </div>
      {!_.isEmpty(errorText) && <p className={styles.EnterAllWordsTitle}>Error: {errorText}</p>}
    </>
  );
});

export default ApproveRegistrationModal;
