import React, { useState } from 'react';
import Layout from '@layout/Layout';
import CapitalSection from './components/CapitalSection';
import KeyNewsSection from './components/KeyNewsSection';
import GameConceptSection from './components/GameConceptSection';
import BenefitsSection from './components/BenefitsSection';
import SpecialPresaleSection from './components/SpecialPresaleSection';
import GameInfoSection from './components/GameInfoSection';
import PlayToEarnBanner from './components/PlayToEarnBanner';
import PartnersSection from './components/PartnersSection';

const MobileHomePage = () => {
  const [isLoadingFinish, setLoadingFinish] = useState(false);
  return (
    <Layout isMobile isLoadingFinish={isLoadingFinish}>
      <CapitalSection />
      <KeyNewsSection />
      <GameConceptSection />
      <BenefitsSection />
      {/* <SpecialPresaleSection finishRequestPacks={setLoadingFinish} /> */}
      <GameInfoSection />
      <PlayToEarnBanner />
      <PartnersSection />
    </Layout>
  );
};

export default MobileHomePage;
