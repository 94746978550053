import { NO_CHAIN_ERR_MSG, TYPE_AUTH_CLIENT } from '@constants/blockchain';
import Logger from '@utils/logger';
import _ from 'lodash';
import { StoreInstance } from 'src/stores';

const stores = StoreInstance;
/* eslint-disable max-len */


const getMessageError = ({ code }) => {
  switch (code) {
    case 2: return 'Mismatched Bear IDs while creating game';
    case 3: return 'The Game already exists';
    case 4: return 'The Name is already taken';
    case 5: return 'The Bear doesn’t exist';
    case 6: return 'The Address has no right to do it';
    case 7: return 'The Bear has no right to perform this action';
    case 8: return 'The Field doesn’t exist';
    case 9: return 'There is no column ID for this field';
    case 10: return 'There is no column ID for this field';
    case 11: return 'The Field type is not defined';
    case 12: return 'The Tile is already occupied by something';
    case 13: return 'The Item doesn’t exist on this item';
    case 14: return 'The Item type is not defined';
    case 15: return 'The Tree doesn’t exist';
    case 16: return 'The Tree is unidentified';
    case 17: return 'The Decoration doesn’t exist';
    case 18: return 'This Decoration has already been placed on the field';
    case 19: return 'This Decoration hasn’t taken a place on the field yet';
    case 20: return 'The Decoration type is not defined';
    case 21: return 'The Apiary type is not defined';
    case 22: return 'The Apiary doesn’t exist';
    case 23: return 'There is not enough space in the Apiary';
    case 24: return 'There are no bees in the apiary';
    case 25: return 'There are Bees in this Apiary';
    case 26: return 'There is uncollected Honey in this Apiary';
    case 27: return 'The Bee type is not defined';
    case 28: return 'The Bee doesn’t exist';
    case 29: return 'The Bee is already in the apiary';
    case 30: return 'The Bee is not in the apiary';
    case 100: return 'An error occurred when starting the game';
    case 101: return 'This item does not exist on the blockchain';
    case 102: return 'Removed a non-standard blockchain item';
    case 103: return 'An error occurred while updating the onboarding state';
    case 105: return 'Blockchain request failed - unstaking entries';
    case 107: return 'Information about apiaries was not received';
    case 108: return 'Data to display the current state of the store was not received';
    case 109: return 'An error occurred during authorization or transaction for match3';
    case 110: return 'Not enough honey';
    case 111: return 'An error occurred while making a call to the blockchain';
    case 112: return 'An error occurred while receiving the bonus';
    case 113: return 'There was a problem with the server (code 113)';
    default: return `An unknown error occurred with the code ${code}`;
  }
}

export const handleMainError = (response, err = undefined, isSendUnity = true) => {
  if (err) {
    Logger.error(err);
  }

  if (response) {
    Logger.info(response);
    if (response.err && response.err.message && response.err.message === NO_CHAIN_ERR_MSG && stores.typeAuthClient === TYPE_AUTH_CLIENT.KEPLR) {
      Logger.infoNotify('removeAuthorized, handleMainError Unity', { type: stores.typeAuthClient, response }, true);
      stores.removeAuthorized();
      window.open('/');
      return;
    }

    const message = (response && response.message) || (err && err.message) || getMessageError({ code: response.code });
    Logger.infoNotify('error with code and message', { code: response.code, response, message }, true);
    if (response && _.isBoolean(response.useProduction) && response.useProduction === false) {
      return;
    }
    if (isSendUnity) {
      stores.unityStore.sendExceptionError({ code: response.code, message });
    }
  }
};

export default {
  handleMainError,
};
