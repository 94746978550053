/* eslint-disable */
import { toHex } from "@cosmjs/encoding";
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx';
import { coins } from '@cosmjs/stargate';

import axios from "axios";
import Logger from '@utils/logger';
import { getFee } from "@utils/methodsBears";
import { EASY_START, URL_BURN_COINS, URL_MATCH3_SEND, USDT, USDT_TRC20 } from "@constants/blockchain";
import { COUNT_MILLISECONDS_LIFE_TIME_SIGNATURE, URL_SERVER_MAIN } from "@constants/system";
import { StoreInstance } from "src/stores";
import { makeSignature } from "./common";
import BEARS from "../BEARS";

let countRequest = 0;
const MAX_COUNT_REQUEST = 15;

const updateSignatureTransaction = (signatureTransaction) => {
  if (
    !StoreInstance.signatureTransaction ||
    signatureTransaction.timestamp !== StoreInstance.signatureTransaction.timestamp
  ) {
    StoreInstance.setSignatureTransaction(signatureTransaction);
  }
};

const makeSignaturePayments = async ({ pack = EASY_START, isPost = false, isBinance = false, sumsubToken = false, bodyInfo = {} }) => {
  let body = bodyInfo;
  if (isPost && !sumsubToken) {
    body.selling_pack = pack;
    body.pay_currency = isBinance ? USDT : USDT_TRC20
  }
  const ts = String(Math.floor(Date.now() / 1000) - 5);

  let signString = ts;
  if (isPost) {
    signString = ts + JSON.stringify(body);
  }
  if (!isPost && StoreInstance.signatureTransaction) {
    const currentDate = new Date();
    const startDate = new Date(StoreInstance.signatureTransaction.dateStart);
    if (currentDate - startDate <= COUNT_MILLISECONDS_LIFE_TIME_SIGNATURE) {
      return StoreInstance.signatureTransaction;
    }
  }

  const signatureTransaction = await makeSignature({ body, ts, nonce: signString });
  signatureTransaction.dateStart = new Date();
  return signatureTransaction;
};

export const paymentBinance = async ({ pack, referralCode }) => {
  try {
    const bodyInfo = { "referral_code": referralCode };
    const infoRequest = await makeSignaturePayments({ pack, isPost: true, isBinance: true, bodyInfo });  
    // const result = await axios.post(`api/payments/binancepayment/invoice`, JSON.stringify(infoRequest.body), {
    const result = await axios.post(`${URL_SERVER_MAIN}/api/payments/binancepayment/invoice`, JSON.stringify(infoRequest.body), {
      headers: {
        'Content-Type': 'application/json',
        'x-honeywood-ts': infoRequest.timestamp,
        'x-honeywood-pk': toHex(StoreInstance.profileAvailable.account.pubkey),
        'x-honeywood-sig': toHex(infoRequest.signature)
      },
    });
    if (result.status === 400) {
      countRequest++;
      if (countRequest < MAX_COUNT_REQUEST) {
        return await paymentBinance({ pack, referralCode });
      } else {
        countRequest = 0;
        const objError = await result.text();
        const textError = objError.split(',')[1].split(':')[1];
        return { err: true, msg: textError }
      }
    }
    if (result.status === 200) {
      return result.data;
    }
    // console.log(result);
    return { err: true, msg: 'Binance payment has error occurred, please to try again' }
  } catch (err) {
    Logger.error(err);
    return { err: true, msg: err.response && err.response.data.message || err.message }
  }
}

export const getBinanceInvoiceList = async ({ address }) => {
  try {
    const infoRequest = await makeSignaturePayments({ isBinance: true });
    const result = await axios.get(`api/payments/binancepayment/list?address=${address}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-honeywood-ts': infoRequest.timestamp,
        'x-honeywood-pk': toHex(StoreInstance.profileAvailable.account.pubkey),
        'x-honeywood-sig': toHex(infoRequest.signature)
      },
    })
    if (result.status === 400) {
      countRequest++;
      if (countRequest < MAX_COUNT_REQUEST) {
        return await getBinanceInvoiceList({ address });
      } else {
        countRequest = 0;
        return { err: true, msg: 'Error with code, 400' }
      }
    }
    updateSignatureTransaction(infoRequest);
    return result.data;
  } catch (err) {
    Logger.error(err);
    return { err: true, msg: err.response && err.response.data.message || err.message }
  }
}

export const getBinanceInfo = async ({ id }) => {
  try {
    const infoRequest = await makeSignaturePayments({ isBinance: true });
    const result = await axios.get(`api/payments/binancepayment/info?_id=${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-honeywood-ts': infoRequest.timestamp,
        'x-honeywood-pk': toHex(StoreInstance.profileAvailable.account.pubkey),
        'x-honeywood-sig': toHex(infoRequest.signature)
      },
    });
    updateSignatureTransaction(infoRequest);
    return result.data;
  } catch (err) {
    Logger.error(err);
    return { err: true, msg: err.response && err.response.data.message || err.message }
  }
}

export const getNowPaymentInfo = async ({ id }) => {
  try {
    const infoRequest = await makeSignaturePayments({});
    const result = await axios.get(`api/payments/nowpayment/info?_id=${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-honeywood-ts': infoRequest.timestamp,
        'x-honeywood-pk': toHex(StoreInstance.profileAvailable.account.pubkey),
        'x-honeywood-sig': toHex(infoRequest.signature)
      },
    })
    updateSignatureTransaction(infoRequest);
    return result.data;
  } catch (err) {
    Logger.error(err);
    return { err: true, msg: err.response && err.response.data.message || err.message }
  }
}

export const getTokenNowPayment = async () => {
  try {
    const infoRequest = await makeSignaturePayments({ isPost: true, sumsubToken: true });
    const result = await axios.post(`api/payments/sumsub/token`, JSON.stringify(infoRequest.body), {
      headers: {
        'Content-Type': 'application/json',
        'x-honeywood-ts': infoRequest.timestamp,
        'x-honeywood-pk': toHex(StoreInstance.profileAvailable.account.pubkey),
        'x-honeywood-sig': toHex(infoRequest.signature)
      },
    });
    return result.data;
  } catch (err) {
    Logger.error(err);
    return { err: true, msg: err.response && err.response.data.message || err.message }
  }
}

export const getVerifiedKYC = async () => {
  try {
    const infoRequest = await makeSignaturePayments({});
    const address = StoreInstance.profileAvailable.address;
    const result = await axios.get(`api/payments/address/verified?address=${address}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-honeywood-ts': infoRequest.timestamp,
        'x-honeywood-pk': toHex(StoreInstance.profileAvailable.account.pubkey),
        'x-honeywood-sig': toHex(infoRequest.signature)
      },
    });
    updateSignatureTransaction(infoRequest);
    return result.data;
  } catch (err) {
    Logger.error(err);
    return { err: true, msg: err.response && err.response.data.message || err.message }
  }
}

export const paymentNowPayment = async ({ pack, referralCode }) => {
  try {
    const bodyInfo = { "referral_code": referralCode }
    const infoRequest = await makeSignaturePayments({ pack, isPost: true, bodyInfo });
    const result = await axios.post(`api/payments/nowpayment/invoice`, JSON.stringify(infoRequest.body), {
      headers: {
        'Content-Type': 'application/json',
        'x-honeywood-ts': infoRequest.timestamp,
        'x-honeywood-pk': toHex(StoreInstance.profileAvailable.account.pubkey),
        'x-honeywood-sig': toHex(infoRequest.signature)
      },
    });
    if (result.status === 400) {
      countRequest++;
      if (countRequest < MAX_COUNT_REQUEST) {
        return await paymentNowPayment({ pack, referralCode });
      } else {
        countRequest = 0;
        const objError = await result.text();
        const textError = objError.split(',')[1].split(':')[1];
        return { err: true, msg: textError }
      }
    }
    if (result.status === 200) {
      return result.data;
    }
    // console.log(result);
    return { err: true, msg: 'Now payment has error occurred, please to try again' }
  } catch (err) {
    Logger.error(err);
    return { err: true, msg: err.response && err.response.data.message || err.message }
  }
}

export const getNowPaymentInvoiceList = async ({ address }) => {
  try {
    const infoRequest = await makeSignaturePayments({});
    const result = await axios.get(`api/payments/nowpayment/list?address=${address}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-honeywood-ts': infoRequest.timestamp,
        'x-honeywood-pk': toHex(StoreInstance.profileAvailable.account.pubkey),
        'x-honeywood-sig': toHex(infoRequest.signature)
      },
    });
    if (result.status === 400) {
      countRequest++;
      if (countRequest < MAX_COUNT_REQUEST) {
        return await getNowPaymentInvoiceList({ address });
      } else {
        countRequest = 0;
        return { err: true, msg: 'Error with code, 400' }
      }
    }
    updateSignatureTransaction(infoRequest);
    return result.data;
  } catch (err) {
    Logger.error(err);
    return { err: true, msg: err.response && err.response.data.message || err.message }
  }
}

export const getSellingPacks = async ({ controller = new AbortController }) => {
  try {
    // const result = await axios.get(`/api/payments/selling_packs`, {
    const result = await axios.get(`${URL_SERVER_MAIN}/api/payments/selling_packs`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      signal: controller.signal
    });
    return result.data;
  } catch (err) {
    Logger.error(err);
    if (err.response && err.response.data && err.response.data.message) {
      return { err: true, msg: err.response.data.message }
    }
    return { err: true, msg: String(err) }
  }
}

export const getReferralCode = async ({ controller = new AbortController }) => {
  try {
    const infoRequest = await makeSignaturePayments({});
    const result = await axios.get(`api/payments/referral/code`, {
      headers: {
        'Content-Type': 'application/json',
        'x-honeywood-ts': infoRequest.timestamp,
        'x-honeywood-pk': toHex(StoreInstance.profileAvailable.account.pubkey),
        'x-honeywood-sig': toHex(infoRequest.signature)
      },
      signal: controller.signal
    });
    if (result.status === 400) {
      countRequest++;
      if (countRequest < MAX_COUNT_REQUEST) {
        return await getReferralCode({ controller });
      } else {
        countRequest = 0;
        return { err: true, msg: 'Error with code, 400' }
      }
    }
    updateSignatureTransaction(infoRequest);
    return result.data;
  } catch (err) {
    Logger.error(err);
    return { err: true, msg: 'Error' }
  }
}

export const getReferralReward = async ({ controller = new AbortController }) => {
  try {
    const infoRequest = await makeSignaturePayments({});
    const result = await axios.get(`api/payments/referral/statistics`, {
      headers: {
        'Content-Type': 'application/json',
        'x-honeywood-ts': infoRequest.timestamp,
        'x-honeywood-pk': toHex(StoreInstance.profileAvailable.account.pubkey),
        'x-honeywood-sig': toHex(infoRequest.signature)
      },
      signal: controller.signal
    });
    if (result.status === 400) {
      countRequest++;
      if (countRequest < MAX_COUNT_REQUEST) {
        return await getReferralReward({ controller });
      } else {
        countRequest = 0;
        return { err: true, msg: 'Error with code, 400' }
      }
    }
    updateSignatureTransaction(infoRequest);
    return result.data;
  } catch (err) {
    Logger.error(err);
    return { err: true, msg: err.response && err.response.data.message || err.message }
  }
}

export const getPolygonAddress = async ({ address, controller = new AbortController }) => {
  try {
    const result = await axios.get(`api/payments/address/polygon_address?address=${address}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      signal: controller.signal
    });
    if (result.status === 400) {
      countRequest++;
      if (countRequest < MAX_COUNT_REQUEST) {
        return await getPolygonAddress({ address, controller });
      } else {
        countRequest = 0;
        return { err: true, msg: 'Error with code, 400' }
      }
    }
    return result.data;
  } catch (err) {
    Logger.error(err);
    return { err: true, msg: err.response && err.response.data.message || err.message }
  }
}

export const getAirdropCode = async () => {
  try {
    // const result = await axios.get(`api/payments/airdrop/code`, {
    const result = await axios.get(`${URL_SERVER_MAIN}/api/payments/airdrop/code`, {
      headers: {
        'x-provider': 'kos',
        'x-key': 'koskey'
      },
    });
    if (result.status === 400) {
      countRequest++;
      if (countRequest < MAX_COUNT_REQUEST) {
        return await getAirdropCode();
      } else {
        countRequest = 0;
        const objError = await result.text();
        const textError = objError.split(',')[1].split(':')[1];
        return { err: true, msg: textError }
      }
    }
    if (result.status === 200) {
      return result.data;
    }
    // console.log(result);
    return { err: true, msg: 'Airdrop has error occurred, please to try again' }
  } catch (err) {
    Logger.error(err);
    return { err: true, msg: err.response && err.response.data.message || err.message }
  }
}

export const getAirdrop = async ({ address, code }) => {
  try {
    const bodyInfo = { "code": code, "address": address }
    // const result = await axios.post(`api/payments/airdrop/code`, JSON.stringify(bodyInfo), {
    const result = await axios.post(`${URL_SERVER_MAIN}/api/payments/airdrop/code`, JSON.stringify(bodyInfo), {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (result.status === 400) {
      countRequest++;
      if (countRequest < MAX_COUNT_REQUEST) {
        return await getAirdrop({ address, code });
      } else {
        countRequest = 0;
        const objError = await result.text();
        const textError = objError.split(',')[1].split(':')[1];
        return { err: true, msg: textError }
      }
    }
    if (result.status === 200) {
      return result.data;
    }
    // console.log(result);
    return { err: true, msg: 'Airdrop has error occurred, please to try again' }
  } catch (err) {
    Logger.error(err);
    return { err: true, msg: err.response && err.response.data.message || err.message }
  }
}

// BURN CONES

const signTransactionBurn = async ({ amount }) => {
  if (!StoreInstance.isAuthorizedClientConfigured) {
    throw Error('no exist client of blockchain');
  }
  const { address } = StoreInstance.profileAvailable;
  let balances = await BEARS.getBalance({ address });
  const fee = getFee({ coefficient: 1.0, balances });

  try {
    const msg = await StoreInstance.authorizedClient.bearsTxs.msgBurnCoins({ creator: address, coins: coins(amount, 'cone') });
    const sendingMessage = await BEARS.customSignOnly({ address, msg: [msg], fee });
    return sendingMessage;
  } catch (err) {
    Logger.error(err);
    return { err, code: 116 }
  }
};

export const paymentBurnCoins = async ({ pack, amount }) => {
  const signingTransaction = await signTransactionBurn({ amount });
  if (signTransactionBurn.err) {
    return signingTransaction;
  }

  try {
    const bodyInfo = { "selling_pack": pack, ...signingTransaction }
    // const result = await axios.post(`api/payments/conepayment/pay`, JSON.stringify(bodyInfo), {
    const result = await axios.post(`${URL_SERVER_MAIN}/api/payments/conepayment/pay`, JSON.stringify(bodyInfo), {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (result.status === 400) {
      countRequest++;
      if (countRequest < MAX_COUNT_REQUEST) {
        return await paymentBurnCoins({ pack, amount });
      } else {
        countRequest = 0;
        const objError = await result.text();
        const textError = objError.split(',')[1].split(':')[1];
        return { err: true, msg: textError }
      }
    }
    if (result.status === 200) {
      return result.data;
    }
    // console.log(result);
    return { err: true, msg: 'Burn coins payment has error occurred, please to try again' }
  } catch (err) {
    Logger.error(err);
    return { err: true, msg: err.response && err.response.data.message || err.message }
  }
}

export const getBurnCoinsInfo = async ({ id }) => {
  try {
    const infoRequest = await makeSignaturePayments({});
    // const result = await axios.get(`api/payments/conepayment/info?_id=${id}`, {
    const result = await axios.get(`${URL_SERVER_MAIN}/api/payments/conepayment/info?_id=${id}`, {
      headers: {
        'x-honeywood-ts': infoRequest.timestamp,
        'x-honeywood-pk': toHex(StoreInstance.profileAvailable.account.pubkey),
        'x-honeywood-sig': toHex(infoRequest.signature)
      },
    })
    updateSignatureTransaction(infoRequest);
    return result.data;
  } catch (err) {
    Logger.error(err);
    return { err: true, msg: err.response && err.response.data.message || err.message }
  }
}

export default {
  paymentBinance,
  getBinanceInfo,
  getBinanceInvoiceList,
  //
  getTokenNowPayment,
  getVerifiedKYC,
  paymentNowPayment,
  getNowPaymentInfo,
  getNowPaymentInvoiceList,
  //
  getSellingPacks,
  //
  getReferralCode,
  getReferralReward,
  getPolygonAddress,
  //
  getAirdropCode,
  getAirdrop,
  //
  paymentBurnCoins,
  getBurnCoinsInfo
};