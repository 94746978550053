export default {
  Header: {
    Play: 'header_play',
    Join: 'header_join',
    WhitePaper: 'header_white_paper',
    SellingPacks: 'header_selling_packs',
  },
  VerificationAccountAccessPopUp: {
    Check: 'verification_wallet_access_popup_check',
    Quit: 'verification_wallet_access_popup_quit',
  },
  ContactUsHeader: {
    Submit: 'contact_us_header_popup_submit',
    Close: 'contact_us_header_popup_close',
  },
  JoinHeader: {
    Login: 'join_header_popup_login',
    SignUp: 'join_header_popup_sign_up',
    Kepler: 'join_header_popup_kepler',
    Close: 'join_header_popup_close',
  },
  SeedPhrase: {
    Copy: 'seed_phrase_popup_copy',
    Next: 'seed_phrase_popup_next',
    Login: 'seed_phrase_popup_login',
    Close: 'seed_phrase_popup_close',
    OK: 'seed_phrase_ok',
    Cancel: 'seed_phrase_cancel',
  },
  CookiesPopUp: { Accept: 'cookies_popup_accept' },
  Footer: { Subscribe: 'footer_subscribe', PopupClose: 'footer_popup_close' },
  Game: { Play: 'game_play' },
  Account: {
    BackHoneyWood: 'account_back_honey_wood',
    Quit: 'account_quit',
    Play: 'account_play',
    Copy: 'account_copy',
    CopyReferralCode: 'account_copy_referral_code',
    Explore: 'account_explore',
    WarningNicknameClose: 'warning_change_nickname_close_popup',
    ConfirmNicknameModal: 'confirm_change_nickname_modal',
    ConfirmNicknamePopup: 'confirm_change_nickname_popup',
    NicknamePopupClose: 'change_nickname_close_popup',
    ApplyAvatar: 'apply_change_avatar',
    AvatarPopupClose: 'change_avatar_close_popup',
    ApplyPopupAvatar: 'change_avatar_apply_popup',
  },

  CheckPassword: {
    QuitAccount: 'check_password_popup_quit_account',
    Check: 'check_password_popup_check',
    Close: 'check_password_popup_close',
  },
  ApproveTransaction: { Approve: 'approve_transaction_popup_approve' },
  GamePage: { Play: 'game_page_play' },
  StackingBearUnity: { Stake: 'stake_stacking_bear_unity', Unstake: 'unstake_stacking_bear_unity' },
  Payment: {
    Binance: 'payment_binance',
    NowPayment: 'payment_now_payment',
    CopyLink: 'payment_copy_link',
    CopyAddress: 'payment_copy_address',
    Close: 'payment_popup_close',
    Confirm: 'payment_popup_confirm',
  },
  Capital: {
    Play: 'capital_play',
  },
  GameConcept: { HowToPlay: 'game_concept_how_to_play' },
  Kepler: {
    Close: 'kepler_popup_close',
  },
  MAIN: {
    Close: 'main_popup_close',
  },
};
